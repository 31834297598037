import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { CsvFieldToSet } from "../../components/csv-mapper/csv-mapper.component";
import { Builder } from "builder-pattern";
import { TranslateService } from "@ngx-translate/core";
import { ApiDataService } from "../../services/api-data.service";

@Component({
  selector: "app-labeling",
  templateUrl: "./labeling.component.html",
  styleUrls: ["./labeling.component.scss"],
})
export class LabelingComponent implements OnInit {
  displayOcrizationLabelsMapper: Subject<void> = new Subject<void>();
  ocrizationLabels: CsvFieldToSet[] = [];

  constructor(
    private translate: TranslateService,
    private apiDataService: ApiDataService
  ) {}

  ngOnInit() {
    this.initTranslation();
  }

  initTranslation() {
    this.translate.get('labeling').toPromise().then(async (labeling) => {
      const alreadyDefinedLabels = await this.apiDataService.getOcrizationLabels();
      this.ocrizationLabels = this.buildOcrizationLabelsFields(labeling.googleDocumentAiLabels, alreadyDefinedLabels);
    });
}

  // prepare the Google Document AI labels to map
  private buildOcrizationLabelsFields(labels: string[], alreadyDefinedLabels: { [key: string]: string }): CsvFieldToSet[] {
    const modelFields: CsvFieldToSet[] = [];
    if (labels && alreadyDefinedLabels) {
      labels.forEach((label) => {
        modelFields.push(
          Builder(CsvFieldToSet)
            .id(label)
            .externalField(alreadyDefinedLabels[label] ? alreadyDefinedLabels[label] : '')
            .isRequired(false)
            .label(label)
            .build()
        );
      });
    }
    return modelFields;
  }

  onSetOcrizationLabelsClicked() {
    this.displayOcrizationLabelsMapper.next();
  }

  // Ocrization labels mapping has been done, send the mapped labels to the backend
  onValidOcrizationLabels(mappedLabels: CsvFieldToSet[]) {
    if (mappedLabels) {
      const requestBody: { [key: string]: string } = {};
      mappedLabels.forEach((mappedLabel) => {
        requestBody[mappedLabel.id] = mappedLabel.externalField;
      });
      this.apiDataService.setOcrzationLabels(requestBody);
      this.ocrizationLabels = this.buildOcrizationLabelsFields(this.ocrizationLabels.map((label) => label.id), requestBody);
    }
  }
}
