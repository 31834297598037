import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { UserPdfUrl } from '../../../models/Managers';
import { MessageService } from '../../components/message/message.service';
import { ApiDataService } from '../../services/api-data.service';
import { User } from '../../../models/User';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';

export enum PDF_TYPES {
    CN22 = 'cn22',
    CN23 = 'cn23',
    LITHIUM = 'lithium',
    EXPORT_INV = 'export_inv',
    RECTIFIED_EXPORT_INV = 'rectified_export_inv',
    CERTIFICATE_OF_ORIGIN = 'certificate_of_origin',
    CREDIT_NOTE = 'credit_note',
    TAXES_INV = 'taxes_inv'
}
export interface CN23Product {
    sku: string;
    value: string;
    hscode?: string;
    origin_country?: string;
    quantity?: number;
    weight?: number; // must be in KG
    priceEur?: number; // must be in EUR
}
export interface OrderProduct {
    order_id: string;
    products: CN23Product[];
}

@Component({
    selector: 'app-export-documents',
    templateUrl: './export-documents.component.html',
    styleUrls: ['./export-documents.component.scss'],
})
export class ExportDocumentsComponent implements OnInit {
    fileType: string;
    user: User;
    countries: { label: string; iso2: string; value: string; }[] = [];
    currencies: { label: string; value: string; }[] = [];
    weightUnits: { label: string, value: string }[] = [];
    private unknown_error: string;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private userService: UserService,
        private translate: TranslateService,
        private _notification: MessageService,
        private route: ActivatedRoute,
        private apiDataService: ApiDataService
    ) {}

    ngOnInit() {
        // get the file type from path
        this.route.paramMap.subscribe(params => {
            this.fileType = params.get('type');
        });
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        this.initUserPrefs();
        this.initTranslation();
    }

    private async initUserPrefs() {
        let user = await this.userService.getUserPromise();
        if (!user) {
            user = await this.authService.reconnect();
        }
        this.user = user;
    }

    async initTranslation() {
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });
        // get currencies
        this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies;
        });
        this.translate.get('unitTypes').toPromise().then(unit_types => {
            this.weightUnits = unit_types.weight;
        });
        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.currencies = event.translations['currency_name'];
            this.weightUnits = event.translations['unitTypes'].weight;
        });
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ?
                            c[`label_${this.translate.currentLang}`] + ' (' + c.value + ')' :
                            c[`label_en`] + ' (' + c.value + ')'
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }

    async onValidPdfForm(formData: any) {
        const fields = Object.keys(formData);
        fields.forEach(field => {
            if (formData[field] === null || formData[field] === '') {
                delete formData[field];
            } else if (field.toLowerCase().includes('date')) {
                formData[field] = (new Date(formData[field])).toISOString().substring(0, 10);
            }
        });
        try {
            const response = (
                await this.http.post(`https://api.${environment.baseUrl}/v1/pdf/generate/${this.fileType}/en`, formData).toPromise()
            ) as UserPdfUrl;
            if (response) {
                window.open(response.signed_url.substring(0, response.signed_url.indexOf('?Expires=')), '_blank');
            }
        } catch (error) {
            console.log(error);
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(error.error, this.unknown_error), 'danger');
        }
    }
}
