import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../models/User';
import {TransportEnum} from '../shipment.component';

@Component({
  selector: 'app-shipment-sender',
  templateUrl: './shipment-sender.component.html',
  styleUrls: ['./shipment-sender.component.scss']
})
export class ShipmentSenderComponent implements OnInit {
  @Output() callNextStep: EventEmitter<FormGroup> = new EventEmitter();
  @Input()
  senderForm: FormGroup;
  _sender: {pro: string, country:  {value: string, label: string}, district:  {value: string, label: string}};
  @Input()
  user: User;
  constructor() { }

  @Input()
  set sender(sender: {pro: string, country:  {value: string, label: string}, district:  {value: string, label: string}}) {
    this._sender = sender;
    if (this.senderForm) {
      this.senderForm.get('country').setValue(this.sender.country.label);
      this.senderForm.get('district').setValue(this.sender.district.label);
      this.updateValueAndValidity(this.senderForm);
    }
  }
  get sender(): {pro: string, country:  {value: string, label: string}, district:  {value: string, label: string}} {
    return this._sender ? this._sender : {pro: 'false', country:  {value: '', label: ''}, district:  {value: '', label: 'string'}};
  }

  get isPro(): boolean {
    return this.sender.pro === 'true';
  }

  ngOnInit() {
    if (!this.senderForm) {
      this.senderForm = new FormGroup({
        name: new FormControl(''),
        first_name: new FormControl(''),
        phone: new FormControl(''),
        company_name: new FormControl(''),
        legal_name: new FormControl(''),
        eori : new FormControl(''),
        city : new FormControl('', [Validators.required]),
        address : new FormControl('', [Validators.required]),
        zipCode : new FormControl('', [Validators.required]),
        pro: new FormControl(this.isPro, [Validators.required]),
        country: new FormControl(this.sender.country.label, [Validators.required]),
        district: new FormControl(this.sender.district.label, [Validators.required]),
        tva: new FormControl(''),
        siret: new FormControl(''),
      });
      this.updateValueAndValidity(this.senderForm);
    }
  }
  updateValueAndValidity(form: FormGroup) {
    const name = form.get('name');
    const first_name = form.get('first_name');
    const phone = form.get('phone');
    const company_name = form.get('company_name');
    const legal_name = form.get('legal_name');
    const eori = form.get('eori');
    const tva = form.get('tva');
    const siret = form.get('siret');
    if (this.isPro) {
      company_name.setValidators([Validators.required]);
      legal_name.setValidators([Validators.required]);
      eori.setValidators([Validators.required]);
      tva.setValidators([Validators.required]);
      siret.setValidators([Validators.required]);
      name.setValidators(null);
      first_name.setValidators(null);
      phone.setValidators(null);
    } else {
      company_name.setValidators(null);
      legal_name.setValidators(null);
      eori.setValidators(null);
      tva.setValidators(null);
      siret.setValidators(null);
      name.setValidators([Validators.required]);
      first_name.setValidators([Validators.required]);
      phone.setValidators([Validators.required]);
    }
    name.updateValueAndValidity();
    first_name.updateValueAndValidity();
    phone.updateValueAndValidity();
    company_name.updateValueAndValidity();
    legal_name.updateValueAndValidity();
    eori.updateValueAndValidity();
    tva.updateValueAndValidity();
    siret.updateValueAndValidity();
  }

  senderFormSubmit() {
    const country = this.sender.country.value;
    this.senderForm.get('country').setValue(country);
    let district = this.sender.district.value;
    district = country === 'USA' ? district + '-' + this.senderForm.get('zipCode').value : district;
    this.senderForm.get('district').setValue(district);
    this.callNextStep.emit(this.senderForm);
  }
}
