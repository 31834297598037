import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AiShippingPricingRequest, AiShippingPricingResult, Parcel, Recipient, Shipper } from '../../../models/Shipping';
import { MessageService } from '../../components/message/message.service';
import { pgTabComponent } from '../../components/tabs/tab.component';
import { environment } from '../../../../environments/environment';
import { ApiDataService } from '../../services/api-data.service';

export enum SHIPPING_STEP_ENUM {
    EXPEDITION = 0,
    PARCELS = 1,
    RESULT = 2
}
@Component({
    selector: 'app-shipping',
    templateUrl: './shipping.component.html',
    styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
    @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;
    SHIPPING_STEP_ENUM = SHIPPING_STEP_ENUM;
    currentStep: number;
    unknown_error: string;

    shippingRequest: AiShippingPricingRequest;
    shippingResponse: AiShippingPricingResult;

    countries: {value: string, label: string, iso2: string}[];
    booleanChoices: {value: string, label: string}[];

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private apiDataService: ApiDataService
    ) {
        this.shippingRequest = new AiShippingPricingRequest();
    }

    async ngOnInit() {
        this.currentStep = SHIPPING_STEP_ENUM.EXPEDITION;
        await this.updateTranslation();
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
    }

    private async updateTranslation() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.booleanChoices = event.translations.boolean_choice;
            this.updateMessagesLanguage(messages);
            this.setCountries();
        });
        await this.translate.get('messages').toPromise().then(messages => {
            this.updateMessagesLanguage(messages);
        });
        await this.translate.get('profile.boolean_choice').toPromise().then((boolChoices) => {
            this.booleanChoices = boolChoices;
        });
    }
    private updateMessagesLanguage(messages) {
        this.unknown_error = messages.notification.unknown_error;
    }

    fromExpedition(expedition: {shipper: Shipper; recipient: Recipient}) {
        this.shippingRequest.quote.shipper = expedition.shipper;
        this.shippingRequest.quote.recipient = expedition.recipient;
        this.setStep(SHIPPING_STEP_ENUM.PARCELS);
    }

    fromParcels(parcels: Parcel[]) {
        this.shippingRequest.quote.parcels = parcels;
        this.getAiShippingPricing()
            .then(response => {
                this.shippingResponse = response as AiShippingPricingResult;
                this.setStep(SHIPPING_STEP_ENUM.RESULT);
            })
            .catch(e => {
                this._notification.displayMessage(e.error.message, 'danger');
            });
    }

    // go back from step PRODUCTS
    goBack() {
        if (this.currentStep === SHIPPING_STEP_ENUM.PARCELS) {
            this.setStep(SHIPPING_STEP_ENUM.EXPEDITION);
        }
    }

    getAiShippingPricing() {
        console.log(this.shippingRequest);
        return this.http.post(`https://api.${environment.baseUrl}/v1/aishippingpricing`, this.shippingRequest).toPromise();
    }

    newSearch() {
        this.shippingRequest = new AiShippingPricingRequest();
        this.shippingResponse = new AiShippingPricingResult();
        this.setStep(SHIPPING_STEP_ENUM.EXPEDITION);
    }

    setStep(step: SHIPPING_STEP_ENUM) {
        if (this.currentStep !== step) {
            this.currentStep = step;
            this.child.setLabel(this.currentStep);
        }
    }

    isRightStep(step: SHIPPING_STEP_ENUM) {
        return step === this.currentStep;
    }

    isDisabledStep(step: SHIPPING_STEP_ENUM) {
        return step !== this.currentStep;
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }
}
