export class DynamoProduct {
    barcode: string;
}

export class MinimProduct extends DynamoProduct {
    category_code: string;
    hscode: DynamoHsCode;
}

export class AllDataProduct extends MinimProduct {
    brand: string;
    bec4: string;
    sitc: Sitc;
    urls_image: Array<URLImage>;
    dimension_si: Dimension;
    dimension_no_si: Dimension;

    price: Prices;
    price_source: PriceSources;
    manufacturer_origin_country_iso3: string;
    custom_attributes_codes: Array<Code>;
    marketing_attributes_codes: Array<Code>;
    lithium_codes: Array<Code>;
    hazardous_codes: Array<Code>;
    languages: Languages;
}

export class LogisticProduct extends DynamoProduct {
    dimension_no_si: Dimension;
    dimension_si: Dimension;
    hscode: DynamoHsCode;
    sitc: Sitc;
    manufacturer_origin_country_iso3: string;
}

export class SalesProduct extends DynamoProduct {
    price: Prices;
    asin: string;
    price_source: PriceSources;
}

export class MarketingProduct extends DynamoProduct {
    marketing_attributes_codes: Array<Code>;
    languages: Languages;
    urls_image: Array<URLImage>;
}


export interface Sitc {
    SITC1: string;
    SITC2: string;
    SITC3: string;
    SITC4: string;
}

export interface URLImage {
    url: string;
}

interface Label {
    label: string;
    value: string;
}

export interface Code {
    code: string;
    value: string
}

export interface PriceSources {
    EUR: string;
    USD: string;
    YEN: string;
    GBP: string;
    CAD: string;
    CHF: string;
    MXN: string;
    CNY: string;
}

export interface Prices {
    EUR: number;
    USD: number;
    YEN: number;
    GBP: number;
    CAD: number;
    CHF: number;
    MXN: number;
    CNY: number;
}

export interface DynamoHsCode {
    HS92: string;
    HS96: string;
    HS02: string;
    HS07: string;
    HS12: string;
    HS17: string;
}

export interface Dimension {
    length: number;
    width: number;
    height: number;
    weight: number;
    volume: number;
    volume_weight: number;
    package_length: number;
    package_width: number;
    package_height: number;
    package_weight: number;
    package_volume: number;
    package_volume_weight: number;
}

export class Languages {
    fr: Lang;
    en: Lang;
    es: Lang;
    cn: Lang;
    ru: Lang;
    hi: Lang;
    ar: Lang;
    pt: Lang;
}

export interface Lang {
    label: string;
    brand: string;
    category_label: string;
    custom_attributes_labels: Array<Label>;
    marketing_attributes_labels: Array<Label>;
    lithium_labels: Array<Label>;
    hazardous_labels: Array<Label>;
    small_description: string;
    long_description: string;
}
