import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../components/message/message.service';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/User';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit {

  disabledLangForm: boolean;
  langForm: FormGroup;
  langs: Array<{}>;
  lang = 'en';
  user: User;
  lang_updated: string;
  constructor(private _notification: MessageService,
              private authService: AuthService,
              private userService: UserService,
              private translate: TranslateService) {
    this.langs = [];
    this.disabledLangForm = true;
    this.langForm = new FormGroup({
      lang: new FormControl({value: this.lang, disabled: this.disabledLangForm}, [Validators.required]),
    });
  }

  async ngOnInit() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
    this.initTranslation();
  }
  initTranslation() {
    this.translate.get('messages').subscribe(messages => {
      this.lang_updated = messages.notification.lang_updated;
      this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
      this.updateLang();
    });
    this.translate.get('app_languages').subscribe(languages => {
      this.langs = languages ? languages : this.langs;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.langs = event.translations.app_languages ? event.translations.app_languages : this.langs;
      this.lang_updated = messages.notification.lang_updated ? messages.notification.lang_updated : this.lang_updated;
      this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
      this.updateLang();
    });
  }
  private updateLang() {
    this.langForm.get('lang').setValue(this.lang);
    this.langForm.get('lang').updateValueAndValidity();
  }

  async updateUser() {
    this.disableLangForm(true);
    this.user.lang = this.langForm.value.lang;
    await this.userService.updateTransiteoUser(this.user).then(user => {
      this._notification.displayMessage(this.lang_updated, 'success');
      this.user = user;
      if (['en','fr','es'].includes(user.getLangIso2())) {
        this.translate.use(user.getLangIso2());
      }
    }).catch(error => {
      this._notification.displayMessage(error.message, 'danger');
    });
  }
  disableLangForm(disableLangForm: boolean) {
    this.disabledLangForm = disableLangForm;
    const state = this.disabledLangForm ? 'disable' : 'enable';
    this.langForm.controls['lang'][state]();
  }
}
