// whole urls suffixes
export enum TRADE_TARIFF_RATES_URL_SUFFIXES {
    DUTIES = 'duties',
    TAXES = 'taxes',
    SALES_TAXES = 'salestaxes',
    SPECIAL_TAXES = 'specialTaxes',
    ECO_TAX = 'ecotax'
}

// used for trade tariff rates ecotax request
export class TradeTariffRatesProductTextRequest {
    product_text: string;
    to_country: string;
}

// used for trade tariff rates taxes, salestaxes, specialTaxes and duties requests
export class TradeTariffRatesRequest {
    hs_code: string;
    sku: string;
    category: string;
    product_text: string;
    from_country: string;
    to_country: string;
    to_district: string;
}

export class TaxContent {
    label: string;
    percentage: number;
}

export class TradeTariffRatesDuties {
    hscode_description: string;
    import_statut: string;
    tariff_regime: string;
    tariff: number;
    tariff_ave: number;
    order_vat_minimis: number;
    order_vat_minimis_currency: string;
    order_duty_minimis: number;
    order_duty_minimis_currency: string;
}

export class TradeTariffRatesTaxes {
    hscode_description: string;
    import_statut: string;
    locals_taxes: TaxContent[];
    threshold_tax: number;
    threshold_tax_currency: string;
    annual_revenue_union_locals_taxes_minimis: number;
    currency_minimis: string;
}

export class TradeTariffRatesSpecialTaxes {
    hscode_description: string;
    import_statut: string;
    other_taxes: TaxContent[];
    threshold_tax: number;
    threshold_tax_currency: string;
    annual_revenue_union_locals_taxes_minimis: number;
    currency_minimis: string;
}

// TO FILL
export enum ECO_CODE_UNIT_TYPES {
    WEIGHT_KG = 'kg',
    WEIGHT_G = 'g',
}
export class TradeTariffRatesEcoTaxResultTarification {
    amount: number; // applied on amount_unit + amount_quantity
    amount_currency: string;
    percentage: number; // 0 - 100, used alone
    amount_unit: ECO_CODE_UNIT_TYPES; // 'kg', 'g', ...
    amount_quantity: number; // amount_unit quantity ex. apply 2€ ecotax per 2kg of goods
    category: string;
}
export class TradeTariffRatesEcoTaxResult {
    rep_code: string;
    designation: string;
    tarifications: TradeTariffRatesEcoTaxResultTarification[];
}
export class TradeTariffRatesEcoTax {
    result: TradeTariffRatesEcoTaxResult[];
}