import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Company, INCOTERM_ENUM, User, USER_STATUT_ENUM } from '../../../models/User';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { UserService } from '../../services/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/utils';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { Subscription } from 'rxjs';
import { ApiDataService } from '../../services/api-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENTATIONS_GUIDES } from '../documentation-guide/documentation-guide.component';
const currency = require('../../../../assets/data/plan_currency.json');
const us_states = require('../../../../assets/data/US_states.json');

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
    @Input()
    fromWelcome: boolean;

    display: string;
    private displayablePages = ['plan', 'user', 'business', 'carriers', 'ecommerce', 'children'];
    private mandatoryFormParts = {
        plan: [],
        user: ['firstname', 'lastname'],
        business: ['social', 'url', 'address', 'zip_code', 'city', 'state', 'from_country'],
        ecommerce: [],
        carriers: []
    };

    user: User;
    error: any;
    userForm: FormGroup;
    formIsInvalid = false;
    //companyForm: FormGroup;
    genders: {value: string, label: string}[];

    countries: Array<{ value: string, label: string, iso2: string }> = [];
    districts: Array<{ value: string, label: string }> = [];
    categories: string[] = [];

    booleanChoices: {value: string, label: string}[];
    incoterms: {value: string, label: string}[];
    allCurrencies: {value: string, label: string, disabled?: boolean}[];
    currency: Array<{value: string, label: string, disabled?: boolean}>;
    updateUserMessage: string;
    updateCompanyMessage: string;
    activityOptions = [];
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates];
    showCompanyForm = true;

    private subscription: Subscription;

    private readonly userInfoUrl: string;
    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private userService: UserService,
        private translate: TranslateService,
        private apiDataService: ApiDataService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.incoterms = Object.keys(INCOTERM_ENUM).map(val => ({label: val, value: val}));
        this.userInfoUrl = `https://api.${environment.baseUrl}/v1/user/info`;
        this.genders = [];
        this.countries = [];
        this.currency = currency;
        this.user = new User();
        this.userForm = new FormGroup({
            lastname: new FormControl('', [Validators.required]),
            firstname: new FormControl('', [Validators.required]),
            email: new FormControl({ value: '', disabled: true }),

            gender: new FormControl('', []),
            age: new FormControl('', []),
            phone: new FormControl('', []),
            pro: new FormControl('', []),
            siret: new FormControl('', []),
            brand_name: new FormControl('', []),
            brand_logo_url: new FormControl('', [Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?')]),
            customer_service_info: new FormControl('', []),
            reverse_logistic_terms: new FormControl('', []),
            air_port_of_lading: new FormControl('', []),
            incoterm: new FormControl('', []),
            term_of_sale: new FormControl('', []),
            other_seller_informations: new FormControl('', []),
            signature: new FormControl('', [Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?')]),
            seller_logo: new FormControl('', [Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?')]),
            
            plan_type: new FormControl({ value: '', disabled: true }),
            payment_type: new FormControl({ value: '', disabled: true }),
            
            url: new FormControl('', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
            integration: new FormControl('', [Validators.required]),
            
            social: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required]),
            zip_code: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required]),
            state: new FormControl('', [Validators.required]),
            from_country: new FormControl('', [Validators.required]),

            category: new FormControl('', []),
            description: new FormControl('', []),
            hscode: new FormControl('', []),
            origin_country: new FormControl('', []),

            extra_fees: new FormControl('', [Validators.min(0), Validators.max(1)]),
            tax_included: new FormControl('', []),

            fedex: new FormGroup({
                account_number: new FormControl('', []),
                counter_number: new FormControl('', []),
                auth_key: new FormControl('', []),
                password: new FormControl('', [])
            }),
            dhl: new FormGroup({
                shop_id: new FormControl('', []),
                password: new FormControl('', []),
                account_number: new FormControl('', [])
            }),
            ups: new FormGroup({
                account_number: new FormControl('', [])
            })

            //phone: new FormControl('', [Validators.required]),
            //products_currency: new FormControl('', [Validators.required]),
            //domain: new FormControl('', [Validators.required]),
            //gender: new FormControl(''),
            //job: new FormControl(''),
            //activity: new FormControl(''),
        });
    }

    get fedex(): FormGroup {
        return this.userForm.get('fedex') as FormGroup;
    }
    get dhl(): FormGroup {
        return this.userForm.get('dhl') as FormGroup;
    }
    get ups(): FormGroup {
        return this.userForm.get('ups') as FormGroup;
    }

    async ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.formIsInvalid = false;
            const page = params.get('page');
            if (this.displayablePages.includes(page)) {
                this.display = page;
            } else {
                this.display = this.displayablePages[0];
            }
        });
        this.user = await this.userService.getUserPromise();
        if ( !this.user.isAdmin() ) {
            this.showCompanyForm = false;
        }
        this.initTranslations();
        this.initForms();
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            try {
                this.subscription.unsubscribe();
            } catch (error) {
                this.subscription = null;
                console.log(error);
            }
        }
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }

    async setDistricts(countryIso3: string) {
        this.userForm.get('state').reset();
        if (countryIso3 === 'USA') {
            this.districts = us_states;
        } else {
            this.districts = (
                await this.http.get(`https://api.${environment.baseUrl}/v2/data/districts?iso3=${countryIso3}`).toPromise()
            ) as { value: string, label: string }[];
        }

        // sort the list
        this.districts.sort((a, b) => {
            return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
        });
    }

    initTranslations() {
        this.translate.get('account.customs_categories').toPromise().then((categories) => {
            this.categories = Object.keys(categories);
        });
        this.translate.get('profile.boolean_choice').toPromise().then((categories) => {
            this.booleanChoices = categories;
        });
        this.translate.get('genders').subscribe((genders) => {
            this.genders = genders;
        });
        this.translate.get('countries').subscribe((countries) => {
            this.countries = countries.sort(Utils.compareByLabel);
        });
        this.translate.get('currency_name').subscribe((currencies) => {
            this.allCurrencies = currencies.sort(Utils.compareByLabel);
        });
        this.translate.get('messages').subscribe((messages) => {
            this.updateMessage(messages);
        });
        this.translate.get('productCategories').subscribe((productCategories) => {
            this.activityOptions = productCategories;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.categories = Object.keys(event.translations.account.customs_categories);
            this.booleanChoices = event.translations.boolean_choice;
            const messages = event.translations['messages'];
            this.updateMessage(messages);
            this.countries = event.translations['countries'] ? event.translations['countries'].sort(Utils.compareByLabel) : this.countries;
            this.allCurrencies = event.translations['currency_name'] ? event.translations['currency_name'].sort(Utils.compareByLabel) : this.allCurrencies;
            this.genders = event.translations['genders'] ? event.translations['genders'] : this.genders;
            this.activityOptions = event.translations['productCategories'] ? event.translations['productCategories'] : this.activityOptions;
            this.setCountries();
        });
    }
    updateMessage(messages) {
        this.updateUserMessage = messages.notification.updateUser;
        this.updateCompanyMessage = messages.notification.updateCompany;
    }
    getNested(nested: string) {
        return this.userForm.get(nested);
    }
    initForms() {
        this.userForm.get('lastname').setValue(this.user.ecommerceProperties.account.lastname);
        this.userForm.get('firstname').setValue(this.user.ecommerceProperties.account.firstname);
        this.userForm.get('email').setValue(this.user.ecommerceProperties.mail);
        this.userForm.get('email').updateValueAndValidity();

        this.userForm.get('gender').setValue(this.user.ecommerceProperties.account.gender);
        this.userForm.get('age').setValue(this.user.ecommerceProperties.account.age);
        this.userForm.get('phone').setValue(this.user.ecommerceProperties.account.phone_number);
        this.userForm.get('pro').setValue(this.user.ecommerceProperties.account.pro);
        this.userForm.get('siret').setValue(this.user.ecommerceProperties.account.siret);
        this.userForm.get('brand_name').setValue(this.user.ecommerceProperties.brand_name);
        this.userForm.get('brand_logo_url').setValue(this.user.ecommerceProperties.brand_logo_url);
        this.userForm.get('customer_service_info').setValue(this.user.ecommerceProperties.customer_service_info);
        this.userForm.get('reverse_logistic_terms').setValue(this.user.ecommerceProperties.reverse_logistic_terms);
        this.userForm.get('air_port_of_lading').setValue(this.user.ecommerceProperties.air_port_of_lading);
        this.userForm.get('incoterm').setValue(this.user.ecommerceProperties.incoterm);
        this.userForm.get('term_of_sale').setValue(this.user.ecommerceProperties.term_of_sale);
        this.userForm.get('other_seller_informations').setValue(this.user.ecommerceProperties.other_seller_informations);
        this.userForm.get('signature').setValue(this.user.ecommerceProperties.signature);
        this.userForm.get('seller_logo').setValue(this.user.ecommerceProperties.seller_logo);

        this.userForm.get('plan_type').setValue(this.user.plan);
        this.userForm.get('payment_type').setValue(this.user.plan_type);

        if (this.user.ecommerceProperties.account) {
            this.userForm.get('url').setValue(this.user.ecommerceProperties.account.domain_name);
            this.userForm.get('integration').setValue(this.user.ecommerceProperties.account.integration_method);
        }

        if (this.user.ecommerceProperties.expedition) {
            this.userForm.get('social').setValue(this.user.ecommerceProperties.account.company_name);
            this.userForm.get('address').setValue(this.user.ecommerceProperties.expedition.address);
            this.userForm.get('zip_code').setValue(this.user.ecommerceProperties.expedition.postal_code);
            this.userForm.get('city').setValue(this.user.ecommerceProperties.expedition.city);
            this.userForm.get('state').setValue(this.user.ecommerceProperties.expedition.state);
            this.userForm.get('from_country').setValue(this.user.ecommerceProperties.expedition.country);

            if (this.user.ecommerceProperties.expedition.country) {
                this.setDistricts(this.user.ecommerceProperties.expedition.country).then(() => {
                    this.userForm.get('state').setValue(this.user.ecommerceProperties.expedition.state);
                });
            }
        }

        if (this.user.ecommerceProperties.customs_classification) {
            this.userForm.get('category').setValue(this.user.ecommerceProperties.customs_classification.default_product_category);
            this.userForm.get('description').setValue(this.user.ecommerceProperties.customs_classification.default_product_description);
            this.userForm.get('hscode').setValue(this.user.ecommerceProperties.customs_classification.default_hs_code);
            this.userForm.get('origin_country').setValue(this.user.ecommerceProperties.customs_classification.default_origin_country);
        }

        if (this.user.ecommerceProperties.duty_calculation) {
            this.userForm.get('extra_fees').setValue(this.user.ecommerceProperties.duty_calculation.extra_fees);
            this.userForm.get('tax_included').setValue(this.user.ecommerceProperties.duty_calculation.tax_included);
        }

        if (this.user.ecommerceProperties.carriers) {
            if (this.user.ecommerceProperties.carriers.fedex) {
                this.fedex.get('account_number').setValue(this.user.ecommerceProperties.carriers.fedex.account_number);
                this.fedex.get('counter_number').setValue(this.user.ecommerceProperties.carriers.fedex.meter_number);
                this.fedex.get('auth_key').setValue(this.user.ecommerceProperties.carriers.fedex.authentication_key);
                this.fedex.get('password').setValue(this.user.ecommerceProperties.carriers.fedex.api_password);
            }
            if (this.user.ecommerceProperties.carriers.dhl) {
                this.dhl.get('shop_id').setValue(this.user.ecommerceProperties.carriers.dhl.online_store_id);
                this.dhl.get('password').setValue(this.user.ecommerceProperties.carriers.dhl.production_password);
                this.dhl.get('account_number').setValue(this.user.ecommerceProperties.carriers.dhl.payment_account_number);
            }
            if (this.user.ecommerceProperties.carriers.ups) {
                this.ups.get('account_number').setValue(this.user.ecommerceProperties.carriers.ups.payment_account_number);
            }
        }

        this.subscription = new Subscription();
        this.subscription.add(
            this.userForm.get('from_country').valueChanges.subscribe(iso3 => {
                this.setDistricts(iso3);
            })
        );
        this.subscription.add(
            this.userForm.get('siret').valueChanges.subscribe(val => this.userForm.get('siret').setValue(val, {emitEvent: false}))
        );
        this.subscription.add(
            this.userForm.get('social').valueChanges.subscribe(val => this.userForm.get('social').setValue(val, {emitEvent: false}))
        );

        /*this.companyForm = new FormGroup({
            company: new FormGroup({
                company_name: new FormControl(company.company_name, [Validators.required]),
                legal_form: new FormControl(company.legal_form),
                siret: new FormControl(company.siret, [Validators.minLength(14), Validators.maxLength(14)]),
                //activity: new FormControl(company.activity),
                tax_number: new FormControl(company.tax_number, [Validators.minLength(16), Validators.maxLength(16)]),
                address: new FormControl(this.user.address, [Validators.required]),
                country: new FormControl(this.user.country, [Validators.required]),
                zip: new FormControl(this.user.zip, [Validators.required]),
                city: new FormControl(this.user.city, [Validators.required]),
            }),
        });*/
    }

    // verify that the entered HS Code is a right HS Code for the specified origin_country
    async checkHscode(origin_country: string, hscode: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/verifyhscode`, {origin_country, hscode}).toPromise()
            .then(async (response: any) => {
                if (!response.isRightHscode) {
                    this._notification.displayMessage(await this.translate.get('account.wrong_SH').toPromise(), 'warning');
                }
                resolve(response.isRightHscode);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }

    async updateUser() {
        if (this.userForm.invalid) {
            // check first which part of the form is invalid
            if (this.mandatoryFormParts[this.display] && this.mandatoryFormParts[this.display].length > 0) {
                for (let i = 0; i < this.mandatoryFormParts[this.display].length; i++) {
                    if (this.userForm.get(this.mandatoryFormParts[this.display][i]).invalid) {
                        this.formIsInvalid = true;
                        return;
                    }
                }
            }
        }
        const userForm = this.userForm.value;

        // check hs code
        if (
            userForm.hscode &&
            userForm.origin_country &&
            (
                this.user.ecommerceProperties.customs_classification.default_hs_code !== userForm.hscode ||
                this.user.ecommerceProperties.customs_classification.default_origin_country !== userForm.origin_country
            )
        ) {
            // we must check that the hs code is a right hs code for the origin country
            if (!(await this.checkHscode(userForm.origin_country, userForm.hscode))) {
                return;
            }
        }

        if (userForm.extra_fees !== null && (userForm.extra_fees < 0 || userForm.extra_fees > 1)) {
            return;
        }

        const fedexContent = this.fedex.value;
        const dhlContent = this.dhl.value;
        const upsContent = this.ups.value;

        const updateUser = {...this.user} as User;

        updateUser.ecommerceProperties.duty_calculation = {
            extra_fees: userForm.extra_fees,
            tax_included: userForm.tax_included
        };

        updateUser.ecommerceProperties.account = {
            firstname: userForm.firstname,
            lastname: userForm.lastname,
            gender: userForm.gender,
            age: userForm.age,
            phone_number: userForm.phone ? userForm.phone.e164Number : '',
            pro: userForm.pro,
            siret: userForm.siret,
            domain_name: userForm.url,
            integration_method: userForm.integration,
            company_name: userForm.social,
            company_id: this.user.ecommerceProperties.account ? this.user.ecommerceProperties.account.company_id : null,
            company_tax_id: this.user.ecommerceProperties.account ? this.user.ecommerceProperties.account.company_tax_id : null
        };

        updateUser.ecommerceProperties.expedition = {
            address: userForm.address,
            postal_code: userForm.zip_code,
            city: userForm.city,
            state: userForm.state,
            country: userForm.from_country
        };

        updateUser.ecommerceProperties.customs_classification = {
            default_product_category: userForm.category,
            default_product_description: userForm.description,
            default_hs_code: userForm.hscode,
            default_origin_country: userForm.origin_country
        };

        updateUser.ecommerceProperties.carriers = {
            fedex: {
                account_number: fedexContent.account_number,
                meter_number: fedexContent.counter_number,
                authentication_key: fedexContent.auth_key,
                api_password: fedexContent.password
            },
            dhl: {
                online_store_id: dhlContent.shop_id,
                production_password: dhlContent.password,
                payment_account_number: dhlContent.account_number
            },
            ups: {
                payment_account_number: upsContent.account_number
            }
        };

        updateUser.ecommerceProperties.brand_name = userForm.brand_name;
        updateUser.ecommerceProperties.brand_logo_url = userForm.brand_logo_url;
        updateUser.ecommerceProperties.customer_service_info = userForm.customer_service_info;
        updateUser.ecommerceProperties.reverse_logistic_terms = userForm.reverse_logistic_terms;
        updateUser.ecommerceProperties.air_port_of_lading = userForm.air_port_of_lading;
        updateUser.ecommerceProperties.incoterm = userForm.incoterm;
        updateUser.ecommerceProperties.term_of_sale = userForm.term_of_sale;
        updateUser.ecommerceProperties.other_seller_informations = userForm.other_seller_informations;
        updateUser.ecommerceProperties.signature = userForm.signature && userForm.signature.length > 0 ? userForm.signature : null;
        updateUser.ecommerceProperties.seller_logo = userForm.seller_logo && userForm.seller_logo.length > 0 ? userForm.seller_logo : null;

        await this.userService
            .updateTransiteoUser(updateUser)
            .then((user) => {
                this._notification.displayMessage(this.updateUserMessage, 'success');
                this.user = user;
            })
            .catch((error) => {
                this._notification.displayMessage(error.error.message, 'danger');
            });
    }

    filterKeyPositiveNumber(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    filterKey(event) {
        const lastValue = this.userForm.get('extra_fees').value;
        return (
            !isNaN(Number(event.key)) &&
            (
                lastValue === '0.' ||
                (
                    lastValue !== null &&
                    (
                        lastValue === '' ||
                        (parseFloat(lastValue) >= 0 && parseFloat(lastValue) < 1))
                    )
            )
            ||
            (lastValue === null && parseInt(event.key, 10) <= 1)
        )
        ||
        (
            event.key === '.' &&
            lastValue !== null &&
            !isNaN(Number(lastValue)) &&
            ! lastValue.toString().includes('.')
        );
    }

    onDisplayDocumentation() {
        this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.API}`]);
    }
    /*async updateCompany() {
        this.user.company = this.companyForm.value.company;
        await this.userService
            .updateTransiteoUser(this.user)
            .then((user) => {
                this._notification.displayMessage(this.updateCompanyMessage, 'success');
                this.user = user;
            })
            .catch((error) => {
                this._notification.displayMessage(error.message, 'danger');
            });
    }*/
}
