import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { environment } from '../../../../environments/environment';
import { ResponseHistory } from '../../../models/ApiHistory';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CsvService } from '../../services/csv.service';
import { RequestProduct, ResponseProduct, Tax, TaxGlobal } from '../../../models/DutyCalculation';
import { Page, PagedData } from '../../../models/page';
import { Utils } from '../../utils/utils';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
const districts = require('../../../../assets/data/districtsGroupByIsoCountry.json');
const transports = require('../../../../assets/data/transports.json');

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
    private isLoading: boolean;

    historyUrl: string;
    apiHistory: ResponseHistory[] = [];
    rows: ResponseHistory[] = [];
    page: Page;
    requestUrl: string;
    selected: [] = [];
    tableRequest = '';
    unknown_error: string;
    countries: Array<{ value: string, label: string }>;

    rowsDetails: { [key: string]: { [key: string]: any }[] } = {};
    detailsColumns: string[] = [];
    detailsColumnsLabels: { [key: string]: string; };
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    columnsData: string[] = [];
    columnsLabels: any;

    // expand row
    expandedRowId: string = null;
    rowDetailHeight = 0;
    historyDetails: {[key: string]: { requestBodyPrettified: string; responseBodyPrettified: string; }};

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private route: ActivatedRoute,
        private csvService: CsvService,
        private translate: TranslateService
    ) {
        this.detailsColumns = ['request_body', 'response_body'];
        this.detailsColumnsLabels = {
            request_body: 'Request Body',
            response_body: 'Response Body'
        };
        this.page = new Page();
        this.countries = [];
        this.columnsData = [
            'request',
            'plan',
            'httpCode',
            'date',
            'fromCountry',
            'toCountry',
            'usedCredits'
        ];
    }

    async ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.requestUrl = params['requestUrl'];
            this.tableRequest = params['tableRequest'];
            // this.historyUrl = `https://api.${environment.baseUrl}/v1/historyapi?request=${this.requestUrl}`;
            this.historyUrl = `https://api.${environment.baseUrl}/v1/historyapi`;
            this.getHistory();
        });
        this.initTranslation();
    }
    initTranslation() {
        this.translate.get('history').toPromise().then((history) => {
            this.columnsLabels = history.mixed;
        });
        this.translate.get('countries').toPromise().then((countries) => {
            this.countries = countries.sort(Utils.compareByLabel);
            this.initFormatColumnsValues();
        });
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            const countries = event.translations['countries'];
            this.columnsLabels = event.translations['history'].mixed;
            this.unknown_error = messages.notification.unknown_error ? messages.notification.unknown_error : this.unknown_error;
            this.countries = countries ? countries.sort(Utils.compareByLabel) : this.countries;
            this.initFormatColumnsValues();
        });
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['from_country', 'to_country'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }

        this.formatColumnsValues = formatValues;
    }

    async getHistory(): Promise<void> {
        this.http.get(this.historyUrl).subscribe((responseHistories: ResponseHistory[]) => {
            console.log(responseHistories);
            this.apiHistory = responseHistories.sort((a, b) => b.timestamp - a.timestamp);
            this.apiHistory = this.apiHistory.map(h => {
                return Builder(ResponseHistory)
                        .fromCountry(h.fromCountry)
                        .toCountry(h.toCountry)
                        .httpCode(h.httpCode)
                        .mail(h.mail)
                        .plan(h.plan)
                        .requestBody(h.requestBody)
                        .requestEndpoint(h.requestEndpoint)
                        .responseBody(h.responseBody)
                        .timestamp(h.timestamp)
                        .used_credits(h.used_credits)
                        .build();
            });
            this.prepareRowsDetails();
            this.setPage();
        }, response => {
            const errorMessage = this._notification.buildTransiteoErrorMessage(response.error, this.unknown_error);
            this._notification.displayMessage(errorMessage, 'danger');
        });
    }

    setPage() {
        this.isLoading = true;
        this.rows = this.apiHistory.map(h => h.toRow());
        const details: { [key: string]: { [key: string]: any }[] } = {};
        this.rows.forEach(row => {
            const prettifiedBodies = this.getDetail(row.timestamp);
            details[row.timestamp] = [{
                request_body: `<pre>${prettifiedBodies.requestBodyPrettified}</pre>`,
                response_body: `<pre>${prettifiedBodies.responseBodyPrettified}</pre>`
            }];
        });
        this.rowsDetails = details;
        this.isLoading = false;
    }

    getApiHistoryPageData(page: Page): PagedData<ResponseHistory> {
        const pagedData = new PagedData<ResponseHistory>();
        page.totalElements = this.apiHistory.length;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = pagedData.data.concat(this.apiHistory.slice(start, end));
        pagedData.page = page;
        return pagedData;
    }

    toDate(timestamp) {
        return moment.unix(timestamp / 1000).format('DD/MM/YYYY');
    }

    generateCsv() {
        const json = this.apiHistory.map(row => this.generateJsonFromHistory(row, this.tableRequest));
        return this.csvService.downloadFile(json, this.tableRequest ? this.tableRequest : 'history_' + (new Date()).getTime());
    }

    getLanguage(languages) {
        try {
            if (languages) {
                return languages[Object.keys(languages)[0]];
            }
        } catch (e) {
            console.error(JSON.stringify(languages));
        }
        return {};
    }

    generateJsonFromHistory(apiHistory: ResponseHistory, tableRequest: string) {
        let csvJson: {};
        csvJson = {
            'requestEndpoint': apiHistory.requestEndpoint,
            'timestamp': apiHistory.timestamp
        };
        switch (tableRequest) {
            case 'productSearch':
                Object.keys(apiHistory.requestBody).forEach(key => {
                    csvJson['request_' + key] = apiHistory.requestBody[key];
                });
                Object.keys(apiHistory.responseBody.result).forEach(key => {
                    csvJson['response_' + key] = apiHistory.responseBody.result[key];
                });
                break;
            case 'hscodefinder':
                Object.keys(apiHistory.requestBody).forEach(key => {
                    csvJson['request_' + key] = apiHistory.requestBody[key];
                });
                Object.keys(apiHistory.responseBody.result).forEach(key => {
                    csvJson['response_' + key] = apiHistory.responseBody.result[key];
                });
                break;
            default:
                Object.keys(apiHistory.requestBody).forEach(key => {
                    csvJson['request_' + key] = apiHistory.requestBody[key];
                });
                csvJson['nb_products'] = apiHistory.requestBody.products ? apiHistory.requestBody.products.length : 0;
                Object.keys(apiHistory.responseBody).forEach(key => {
                    csvJson['response_' + key] = apiHistory.responseBody[key];
                });
        }
        return csvJson;
    }
    findCountryLabel(iso: string) {
        const findCountry = iso && iso.length === 3 ? this.countries.find(country => country.value === iso) : null;
        return findCountry ? findCountry.label : iso;
    }
    findDistrict(isoDistrict: string, isoCountry: string) {
        const findDistrict = districts[isoCountry] ?
            districts[isoCountry].find(district => district.value === isoDistrict) : null;
        return findDistrict ? findDistrict.label : isoDistrict;
    }
    findTransport(code: string) {
        const findTransport = transports.find(transport => transport.value.split('_')[1] === code);
        return findTransport ? findTransport.label : code;
    }
    sumTax(tax?: Tax): number {
        if (tax) {
            return (tax.product_taxes_amount ? tax.product_taxes_amount : 0) +
                (tax.shipping_taxes_amount ? tax.shipping_taxes_amount : 0);
        }
        return 0;
    }

    sumVatTaxesProducts(products: ResponseProduct[], label: string) {
        return products.reduce((a, b) =>
            a + this.sumTax(b.vat.find(spTax => spTax.label === label)), 0);
    }
    getDistinctTaxes(products: ResponseProduct[], tax) {
        const mapTaxes: Map<string, number> = new Map<string, number>();
        products.forEach(product => {
            product[tax].forEach(aTax => {
                let counter = 0;
                if (mapTaxes.has(aTax.label)) {
                    counter = mapTaxes.get(aTax.label);
                }
                counter = counter + Number.parseFloat(this.sumTax(aTax).toFixed(2));
                mapTaxes.set(aTax.label, counter);
            });
        });
        return mapTaxes;
    }

    sumSpecialTaxesProducts(products: ResponseProduct[], label: string) {
        return products.reduce((a, b) =>
            a + this.sumTax(b.special_taxes.find(spTax => spTax.label === label)), 0);
    }
    sumDutyTaxesProducts(products: ResponseProduct[]) {
        return products.map(product => this.sumTax(product.duty)).reduce((a, b) => a + b, 0);
    }
    sumUnitShipPrices(products: RequestProduct[]) {
        return products ? products.reduce((a, b) => a + b.unit_ship_price, 0) : 0;
    }
    sumTransitFees(products: ResponseProduct[]) {
        return products.reduce((a, b) => a + (b.transit_fees ? b.transit_fees.amount : 0), 0);
    }
    getTaxAmount(label: string, taxes?: TaxGlobal[]) {
        if (!taxes) {
            return 0;
        }
        const findTax = taxes.find(tax => tax.label === label);
        return findTax ? findTax.amount : 0;
    }

    onDetailToggleOrder(event) {
        // console.log('Detail Toggled', event);
    }
    maxLines(reqBody: string, resBody: string): number {
        const countLinesReq = reqBody.split('\n').length;
        const countLinesRes = resBody.split('\n').length;
        return countLinesReq > countLinesRes ? countLinesReq : countLinesRes;
    }

    prepareRowsDetails() {
        this.historyDetails = {};
        this.apiHistory.forEach(history => {
            this.historyDetails[history.timestamp.toString()] = {
                requestBodyPrettified: history.requestBody ? JSON.stringify(history.requestBody, null, 2) : '{}',
                responseBodyPrettified: history.responseBody ? JSON.stringify(history.responseBody, null, 2) : '{}'
            };
        });
    }

    getDetail(timestamp: number): { requestBodyPrettified: string; responseBodyPrettified: string; } {
        return this.historyDetails[timestamp.toString()];
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return '';
        }
    }

    getColumnMarginLeft(prop: string): number {
        const propIndex: number = this.columnsData.findIndex(col => col === prop);
        return (-15 + (propIndex / this.columnsData.length) * 35);
    }
}
