import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../../components/message/message.service';
import {User} from '../../../models/User';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-delete',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

    modalRef: BsModalRef;
    user: User;
    successDelete: string;
    subscriptionOnGoing: string;
    unknownError: string;

    constructor(private userService: UserService,
                private authService: AuthService,
                private http: HttpClient,
                private _notification: MessageService,
                private modalService: BsModalService,
                private router: Router,
                private translate: TranslateService) {
    }

    async ngOnInit() {
        await this.translate.get('messages').toPromise().then(messages => {
            this.successDelete = messages.notification.success_delete;
            this.subscriptionOnGoing = messages.notification.subscription_on_going;
            this.unknownError = messages.notification.unknown_error;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.successDelete = event.translations.messages.notification.success_delete;
            this.subscriptionOnGoing = event.translations.messages.notification.subscription_on_going;
            this.unknownError = event.translations.messages.notification.unknown_error;
        });
    }

    openModal(template: TemplateRef<any>) {
        const config = {class: 'modal-lg', animated: false, backdrop: true} as ModalOptions;
        this.modalRef = this.modalService.show(template, config);
    }

    async delete() {
        await this.userService.deleteTransiteoUser()
            .then(() => {
                this.modalRef.hide();
                this.authService.isLoggedIn = false;
                this.authService.redirectUrl = null;
                this.router.navigate(['/login']);
                setTimeout( () => {
                    this.displayMessage(this.successDelete, 'success');
                }, 2000);
            })
            .catch(error => {
                this.modalRef.hide();
                if ( error.error.code === 'SUBSCRIPTION_STILL_IN_PROGRESS') {
                    this.displayMessage(this.subscriptionOnGoing, 'danger');
                } else {
                    this.displayMessage(this.unknownError, 'danger');
                }
            });
    }

    displayMessage(message: string, type: string) {
        if (this._notification && typeof this._notification.create === 'function') {
            this._notification.create(
                type,
                message,
                {
                    Position: 'top',
                    Style: 'bar',
                    Duration: 10000
                }
            );
        }
    }

}
