import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedData } from '../../Common/SharedData';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    title = 'Connexion!';
    fieldTextType: boolean;
    loginForm: FormGroup;
    formIsInvalid = false;
    other_messages = {};
    isWhiteLabel = false;
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private data: SharedData,
        private _notification: MessageService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        // retrieve URL path
        // if url contains '/auth' then it's a white label
        this.isWhiteLabel = this.router.url.includes('/auth');
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        });
    }

    async ngOnInit() {
        if (this.authService.isLoggedIn) {
            if (!this.authService.redirectUrl || this.authService.redirectUrl.includes('login')) {
                if (await this.userHasCompletedItsAccount()) {
                    await this.router.navigate(['dashboard']);
                } else {
                    // go to step settings in register page
                    this.router.navigate(['register']);
                }
            } else {
                await this.router.navigate([this.authService.redirectUrl], { queryParams: this.authService.queryParams });
            }
        }

        await this.translate.get('messages').toPromise().then(messages => {
            this.other_messages = messages;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.other_messages = event.translations.messages;
        });
        this.route.queryParams.subscribe(async params => {
            const confirmSubscription = params['subscription'];
            if (confirmSubscription) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                let type: string;
                let message: string;
                if (confirmSubscription === 'success') {
                    type = 'success';
                    message = this.other_messages['notification'].signUp.confirm.success;
                } else {
                    type = 'danger';
                    message = this.other_messages['notification'].signUp.confirm.failed;
                }
                this._notification.displayMessage(message, type);
            }
        });
    }

    async userHasCompletedItsAccount(): Promise<boolean> {
        return new Promise(resolve => {
            this.userService.getUserPromise().then(user => {
                // check at least one parameter
                if (
                    !user.ecommerceProperties.account
                    ||
                    (
                        (user.ecommerceProperties.account.integration_method !== 'API' && user.ecommerceProperties.account.integration_method !== 'SAAS')
                        &&
                        !user.ecommerceProperties.account.domain_name
                    )
                ) {
                    resolve(false);
                }
                resolve(true);
            });
        });
    }

    async signIn() {
        if (this.loginForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        await this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
            .then(async () => {
                if (await this.userHasCompletedItsAccount()) {
                    await this.router.navigate(['dashboard']);
                } else {
                    // go to step settings in register page
                    this.router.navigate(['register']);
                }
            })
            .catch(e => this._notification.displayMessage(e.message, 'danger'));
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    onGoToTransiteoWebsite() {
        window.open('https://www.transiteo.com', '_blank');
    }
 }
