import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CsvService} from '../../../services/csv.service';
import { AiShippingPricingRequest, AiShippingPricingResult } from '../../../../models/Shipping';

@Component({
    selector: 'app-shipping-step-result',
    templateUrl: './step-result.component.html',
    styleUrls: ['./step-result.component.scss']
})
export class ShippingStepResultComponent implements OnInit {

    @Input()
    shippingResponse: AiShippingPricingResult = {
        carrier_code: '',
        code: '',
        name: '',
        delay: '',
        amount: 0,
        currency: '',
        timestamp: 0
    };
    @Input()
    shippingRequest: AiShippingPricingRequest;
    @Output()
    new: EventEmitter<any> = new EventEmitter();

    constructor(private csvService: CsvService) {}

    ngOnInit() {}

    exportCsv() {
        const dutyResponse = this.buildCsv();
        const dutyFileName = `shippingSimulator_${this.shippingRequest.quote.shipper.country}_${this.shippingRequest.quote.recipient.country}`;
        return this.csvService.downloadFile(dutyResponse, dutyFileName);
    }

    buildCsv() {
        return {
            shipper_country: this.shippingRequest.quote.shipper.country,
            shipper_postal_code: this.shippingRequest.quote.shipper.postal_code,
            shipper_city: this.shippingRequest.quote.shipper.city,
            recipient_country: this.shippingRequest.quote.recipient.country,
            recipient_postal_code: this.shippingRequest.quote.recipient.postal_code,
            recipient_city: this.shippingRequest.quote.recipient.city,
            recipient_is_a_company: this.shippingRequest.quote.recipient.is_a_company,
            parcels: this.shippingRequest.quote.parcels.map(p => JSON.stringify(p)).toString(),
            result_amount: this.shippingResponse.amount,
            result_amount_currency: this.shippingResponse.currency,
            result_carrier: this.shippingResponse.carrier_code,
            result_shipment_code: this.shippingResponse.code,
            result_shipment_type: this.shippingResponse.name,
            result_delay: this.getResultDelay(),
            timestamp: this.shippingResponse.timestamp
        };
    }

    getResultDelay(): string {
        const delaySplitted = this.shippingResponse.delay.split('-');
        return delaySplitted.join('h-') + 'h';
    }

    newSearch() {
        this.new.emit();
    }
}
