import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { Parcel } from '../../../../models/Shipping';

@Component({
    selector: 'app-shipping-step-parcels',
    templateUrl: './step-parcels.component.html',
    styleUrls: ['./step-parcels.component.scss']
})
export class ShippingStepParcelsComponent implements OnInit {

    @Input()
    parcelsList: Parcel[];
    @Output()
    setData: EventEmitter<Parcel[]> = new EventEmitter<Parcel[]>();
    @Output()
    previousStep: EventEmitter<void> = new EventEmitter<void>();

    parcelsForm: FormGroup;
    formIsInvalid = false;

    constructor() {
        this.parcelsForm = new FormGroup({
            parcels: new FormArray([])
        });
    }

    async ngOnInit() {
        if (this.parcelsList && this.parcelsList.length > 0) {
            this.parcelsList.forEach((parcel) => {
                this.addParcel(parcel);
            });
        } else {
            this.addParcel();
        }
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    get parcels(): FormArray {
        return this.parcelsForm.get('parcels') as FormArray;
    }

    addParcel(parcel: Parcel = null) {
        this.parcels.push(
            new FormGroup({
                weight: new FormControl(parcel ? parcel.weight : null, [Validators.required]),
                width: new FormControl(parcel ? parcel.width : null, [Validators.required]),
                height: new FormControl(parcel ? parcel.height : null, [Validators.required]),
                length: new FormControl(parcel ? parcel.length : null, [Validators.required])
            })
        );
    }

    deleteParcel(index: number) {
        this.parcels.removeAt(index);
    }

    nextStep() {
        if (this.parcelsForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const parcels: Parcel[] = this.parcelsForm.value.parcels;
        parcels.forEach(p => {
            console.log(p);
            p.weight = parseFloat(p.weight.toString());
            p.width = parseFloat(p.width.toString());
            p.height = parseFloat(p.height.toString());
            p.length = parseFloat(p.length.toString());
        });
        this.formIsInvalid = false;
        this.setData.emit(parcels);
    }

    goBack() {
        this.previousStep.emit();
    }
}
