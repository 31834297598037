import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ALL_PLAN_TYPE_ENUM, Child, ChildWithConsumptions, PLAN_ENUM, PLAN_V2_ENUM, User} from '../../../models/User';
import {MessageService} from '../../components/message/message.service';
import {UserService} from '../../services/user.service';
import {ResponseManageChild} from './interfaces/response-manage-child';
import {RequestChild, RequestPutChild} from './interfaces/request-child';
import {ModalOptions, BsModalService, BsModalRef} from 'ngx-bootstrap';
import {ManageChildService} from '../../services/manage-child.service';
import { ManagersService } from '../../services/managers.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-multi-users',
    templateUrl: './multi-users.component.html',
    styleUrls: ['./multi-users.component.scss']
})
export class MultiUsersComponent implements OnInit {
    @ViewChild('confirmDeleteRow', { static: false }) confirmDeleteRow: TemplateRef<any>;
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;

    user: User;
    admin: string;
    maxChildren = 0;
    children: Map<string, ChildWithConsumptions> = new Map<string, ChildWithConsumptions>();
    unexpected: string;
    updated: string;
    created: string;
    deleted: string;
    credits: string;
    listCredits: Array<any>;
    modalRef: BsModalRef;
    
    filterForm: FormGroup;
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    columnsToDisplay: string[] = [];
    columnsLabels: { [key: string]: string; };
    // expanded rows contents (display endpoints)
    endpointsDetails: { [key: string]: { endpoint: string; left: number; count: number; count_day: number; count_total: number; total_current_month?: number; periodic?: number; }[] } = {};
    endpointsDetailsColumns: string[] = [];
    endpointsDetailsColumnsLabels: { [key: string]: string } = {};
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    availableEndpoints: { id: string; endpoint: string; availableCreditsParent: number; availableCredits: number; [key: string]: any; }[] = [];
    isEditing = false;
    closingModal = false;
    childToDeleteEmail: string = null;
    rowForm: FormGroup;
    formIsInvalid = false;

    coupledConsumptions: string[][] = [];

    constructor(
        private userService: UserService,
        private _notification: MessageService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private managersService: ManagersService,
        private manageChildService: ManageChildService
    ) {
        this.userService.getUserPromise().then(user => {
            this.user = user;
            this.initTranslations();
            if (this.user.isChild() && !this.user.isGrandChild()) {
                this.maxChildren = 999;
            } else if (this.user.isAdmin()) {
                switch (this.user.getPlan()) {
                    case `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.START}`:
                        this.maxChildren = 1;
                        break;
                    case `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.ROCKET}`:
                        this.maxChildren = 5;
                        break;
                    case `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.COSMOS}`:
                        this.maxChildren = 10;
                        break;
                    case `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.INFINITE}`:
                        this.maxChildren = 30;
                        break;
                    case `${PLAN_V2_ENUM.API}`:
                        this.maxChildren = this.user.getAllowedChildren();
                        break;
                    default:
                        this.maxChildren = 0;
                        break;
                }
            }
            if (this.user && this.user.ecommerceProperties && this.user.ecommerceProperties.account) {
                this.admin = this.user.ecommerceProperties.account.firstname + ' ' + this.user.ecommerceProperties.account.lastname;
            } else {
                this.admin = '';
            }
            this.initChildren();
        });
        this.filterForm = this.formBuilder.group({
            filter: ['']
        });
    }

    reloadUser() {
        if (this.user.consumptions) {
            this.userService.initTransiteoUser( false ).then( user => {
                this.user = user;
                this.listCredits = this.generateListCredits( this.user.consumptions[0].currentPeriod.left );
                this.initChildren();
            });
        }
    }

    async initChildren() {
        await this.getChildren();
        this.managersService.setChildren(this.getChildrenAsArray());
    }

    ngOnInit() {
        
    }

    initTranslations() {
        this.translate.get('messages').subscribe((messages) => {
            this.unexpected = messages.notification.UnexpectedError;
        });
        this.translate.get('multiUsers').subscribe((multiUsers) => {
            this.updateMessagesTranslation(multiUsers);
        });

        this.translate.get('plan').subscribe(plan => {
            this.credits = plan.credits;
        });

        this.coupledConsumptions = this.translate.instant('plan.formula4.coupledConsumptions');

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.unexpected = event.translations['messages'].notification.UnexpectedError;
            this.updateMessagesTranslation(event.translations['multiUsers']);
            this.credits = event.translations.plan.credits;
        });
        this.initFormatColumnsValues();
    }

    updateMessagesTranslation(multiUsers) {
        this.updated = multiUsers.notification.updated;
        this.created = multiUsers.notification.created;
        this.deleted = multiUsers.notification.deleted;

        if (multiUsers.tableColumns) {
            this.columnsLabels = multiUsers.tableColumns;
            if (this.user && this.user.planIsApi() && this.user.planTypeIsPrePayment()) {
                if (this.columnsLabels['allow_credits']) {
                    delete this.columnsLabels['allow_credits'];
                }
                if (this.columnsLabels['total_current_month']) {
                    delete this.columnsLabels['total_current_month'];
                }
            }
            this.columnsToDisplay = Object.keys(multiUsers.tableColumns);
        }
        if (multiUsers.tableLabels) {
            this.endpointsDetailsColumnsLabels = multiUsers.tableLabels;
            this.endpointsDetailsColumns = Object.keys(multiUsers.tableLabels);
        }
    }

    getChildrenAsArray(): Child[] {
        return Array.from(this.children.values());
    }
    getChildrenAsArrayForTable(): Child[] {
        // the children to display in the table must be in the filter
        const children = Array.from(this.children.values()).filter(c => this.childInFilter(c));
        children.forEach(child => {
            child.creationDate = child.creationDate ? new Date(child.creationDate) : undefined;
            child.renewDate = child.renewDate ? new Date(child.renewDate) : undefined;
        });
        return children;
    }

    generateListCredits( left: number )  {
        let creditMax = 1;
        if ( left ) {
            creditMax = Math.floor(left / 100);
        }
        const credits = [];
        for (let index = 1; index <= creditMax; index++) {
            credits.push({ value: index, label: `${index * 100} ${this.credits}` });
        }
        return credits;
    }

    setAvailableEndpoints(): void {
        const endpoints = this.user.getAllowedEndpointsWithRemaining();
        const keys = Object.keys(endpoints);
        let i = 0;
        const emails = this.getChildrenAsArray().map(c => c.email);
        this.availableEndpoints = keys.map(k => {
            i += 1;
            const res = {
                id: `req_${i}`,
                endpoint: k,
                availableCreditsParent: endpoints[k],
                availableCredits: endpoints[k]
            };
            const childrenLeftCredits: { [key: string]: number; } = {};
            emails.forEach(email => {
                const found = this.endpointsDetails[email].find(endpoint => endpoint.endpoint === k);
                childrenLeftCredits[email] = found ? found.left : 0;
                res[`availableCredits_${email}`] = endpoints[k] + childrenLeftCredits[email];
            });
            const totalChildrenLeft = emails.reduce((a, b) => a + childrenLeftCredits[b], 0);
            res.availableCredits += totalChildrenLeft;
            return res;
        });

        i = 0;
        while (i < this.availableEndpoints.length) {
            // merge together coupled requests if there are any
            if (this.requestIsCoupledWithOtherRequests(this.availableEndpoints[i].endpoint)) {
                const coupledConsumptionsItem = this.coupledConsumptions.find(cons => cons.includes(this.availableEndpoints[i].endpoint));
                coupledConsumptionsItem.forEach(cc => {
                    if (cc !== this.availableEndpoints[i].endpoint) {
                        const deleteIndex = this.availableEndpoints.findIndex(ae => ae.endpoint === cc);
                        if (deleteIndex >= 0) {
                            this.availableEndpoints.splice(deleteIndex, 1);
                        }
                    }
                });
                this.availableEndpoints[i].endpoint = coupledConsumptionsItem.join(', ');
            }
            i += 1;
        }
    }

    checkFormIsValid(): boolean {
        if (this.rowForm.valid) {
            const value = this.rowForm.value;
            if (this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
                const endpointsIds = this.availableEndpoints.map(ae => ae.id);
                for (let i = 0; i < endpointsIds.length; i++) {
                    if (
                        (
                            this.isEditing && 
                            value[`allocate_${endpointsIds[i]}`] !== null &&
                            value[`allocate_${endpointsIds[i]}`] > this.getMaxAllocatableCreditsForChild(this.availableEndpoints.find(e => e.id === endpointsIds[i]))
                        )
                        ||
                        (
                            ! this.isEditing && 
                            value[`periodic_${endpointsIds[i]}`] !== null &&
                            value[`periodic_${endpointsIds[i]}`] > this.getMaxAllocatableCreditsForChild(this.availableEndpoints.find(e => e.id === endpointsIds[i]))
                        )
                    ) {
                        return false;
                    }
                }
                return true;
            } else {
                if (! this.isEditing) {
                    if (value.periodic_credits !== null) {
                        return true;
                    }
                } else {
                    if (value.periodic_credits !== null || value.occasionnal_credits !== null) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    limitChildren() {
        /*if (this.user) {
            if ( this.user.getPlan() === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.START}` || this.user.getPlan() === TRIAL_PLAN || this.user.planIsEcommerce()) {
                return true;
            } else if (this.user.getPlan() === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.ROCKET}` && this.user.consumptions[0].relation.children.length >= 4 ) {
                return true;
            } else if (this.user.getPlan() === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.COSMOS}` && this.user.consumptions[0].relation.children.length >= 9 ) {
                return true;
            } else if (this.user.getPlan() === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.INFINITE}` && this.user.consumptions[0].relation.children.length >= 29 ) {
                return true;
            } else if (this.user.planIsApi() && this.user.consumptions[0].relation.children.length >= this.user.getAllowedChildren()) {
                return true;
            }
        }
        return false;*/
        return this.maxChildren - this.children.size <= 0; 
    }

    submit() {
        if (! this.checkFormIsValid()) {
            this.formIsInvalid = true;
            return;
        }
        if (this.isEditing) {
            this.editUser();
        } else {
            this.createUser();
        }
        this.closeModal();
    }

    async createUser() {
        const formParams = this.rowForm.value;
        const child: RequestChild = {
            firstname: formParams.firstname,
            lastname: formParams.lastname,
            email: formParams.email,
            child_credits: formParams.periodic_credits ? formParams.periodic_credits : undefined
        };
        if (this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
            const allowCredits: { [key: string]: number; } = {};
            const allowPeriodicCredits: { [key: string]: number; } = {};
            for (let i = 0; i < this.availableEndpoints.length; i++) {
                if (formParams[`periodic_${this.availableEndpoints[i].id}`] !== null) {
                    const endpoints = this.availableEndpoints[i].endpoint.split(', ');
                    endpoints.forEach(e => {
                        allowCredits[e] = formParams[`periodic_${this.availableEndpoints[i].id}`];
                        allowPeriodicCredits[e] = formParams[`periodic_${this.availableEndpoints[i].id}`];
                    });
                }
            }
            child.allowCredits = allowCredits;
            child.allowPeriodicCredits = allowPeriodicCredits;
        }
        await this.createChild(child);
    }

    async editUser() {
        const formParams = this.rowForm.value;
        const childUpdateBody: RequestPutChild = {
            child_credits: formParams.periodic_credits ? formParams.periodic_credits : undefined,
            child_occasionnal_credits: formParams.occasionnal_credits ? formParams.occasionnal_credits : undefined
        };
        if (this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
            const allowCredits: { [key: string]: number; } = {};
            const allowPeriodicCredits: { [key: string]: number; } = {};
            for (let i = 0; i < this.availableEndpoints.length; i++) {
                if (formParams[`allocate_${this.availableEndpoints[i].id}`] !== null) {
                    const endpoints = this.availableEndpoints[i].endpoint.split(', ');
                    endpoints.forEach(e => {
                        allowCredits[e] = formParams[`allocate_${this.availableEndpoints[i].id}`];
                    });
                }
                if (formParams[`periodic_${this.availableEndpoints[i].id}`] !== null) {
                    const endpoints = this.availableEndpoints[i].endpoint.split(', ');
                    endpoints.forEach(e => {
                        allowPeriodicCredits[e] = formParams[`periodic_${this.availableEndpoints[i].id}`];
                    });
                }
            }
            childUpdateBody.allowCredits = allowCredits;
            childUpdateBody.allowPeriodicCredits = allowPeriodicCredits;
        }
        const child = this.getChildrenAsArray().find(c => c.email === formParams.email);
        if (child) {
            this.updateChild(child.id, formParams.email, childUpdateBody);
        }
    }

    getMaxAllocatableCreditsForChild(endpoint: { id: string; endpoint: string; availableCreditsParent: number; availableCredits: number; [key: string]: any; }): number {
        if (this.isEditing) {
            if (this.rowForm) {
                if (endpoint[`availableCredits_${this.rowForm.get('email').value}`] !== undefined) {
                    return endpoint[`availableCredits_${this.rowForm.get('email').value}`];
                } else {
                    return 0;
                }
            }
        }
        return endpoint.availableCreditsParent;
    }

    getApiPrePaymentPeriodicCreditsAllocatedToChild(endpoint: string): number | undefined {
        if (this.isEditing && this.rowForm) {
            const email = this.rowForm.get('email').value;

        }
        return undefined
    }

    async getChildren(): Promise<void> {
        return this.manageChildService.getChildren()
            .then((response: Array<ChildWithConsumptions>) => {
                if ( response ) {
                    this.children = new Map<string, ChildWithConsumptions>();
                    this.endpointsDetails = {};
                    for (let index = 0; index < response.length; index++) {
                        this.children.set(response[index].id, response[index]);
                        this.endpointsDetails[response[index].email] = Object.keys(response[index].credits_consumption).map(creditCons => {
                            const currentCons = response[index].credits_consumption[creditCons];
                            const overridePlanCredits = response[index].override_plan && response[index].override_plan.requests && response[index].override_plan.requests[creditCons] ? response[index].override_plan.requests[creditCons] : 0;
                            const todayCons = response[index].today_credits_consumption[creditCons];
                            const totalCons = response[index].total_credits_consumption[creditCons];
                            if (currentCons.count || currentCons.left) {
                                return {
                                    endpoint: creditCons,
                                    left: (currentCons.left ? currentCons.left : 0) + overridePlanCredits,
                                    count: currentCons.count,
                                    count_day: todayCons.count,
                                    count_total: totalCons ? totalCons.count : 0,
                                    total_current_month: (this.user.planIsApi() && this.user.planTypeIsPrePayment()) ? currentCons.left + currentCons.count + overridePlanCredits : undefined
                                };
                            }
                            return undefined;
                        })

                        // append periodic credits
                        if (response[index].allow_req_periodic_credits) {
                            Object.keys(response[index].allow_req_periodic_credits).forEach(pc => {
                                const endpoint = this.endpointsDetails[response[index].email].find(e => e && e.endpoint === pc);
                                if (! endpoint) {
                                    this.endpointsDetails[response[index].email].push({
                                        endpoint: pc,
                                        count: 0,
                                        count_day: 0,
                                        count_total: 0,
                                        left: 0,
                                        total_current_month: 0,
                                        periodic: response[index].allow_req_periodic_credits[pc]
                                    });
                                } else {
                                    endpoint.periodic = response[index].allow_req_periodic_credits[pc];
                                }
                            });
                        }
                        // remove empty cons
                        this.endpointsDetails[response[index].email] = this.endpointsDetails[response[index].email].filter(cons => cons && (cons.count || cons.left || cons.periodic));
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return Promise.reject();
            });
    }

    async createChild(child: RequestChild): Promise<void> {
        return this.manageChildService.createChild(child)
            .then((response: ResponseManageChild) => {
                this._notification.displayMessage(this.created, 'success');
                const emptyCreditsConsumptions = {};
                const emptyCreditsConsumptionsCountOnly = {};
                this.endpointsDetails[child.email] = [];
                Object.keys(response.child.allow_req_credits).forEach(creditEndpoint => {
                    if (this.user.planIsApi() && this.user.planTypeIsPrePayment()) {
                        this.endpointsDetails[child.email].push({
                            endpoint: creditEndpoint,
                            left: response.child.allow_req_credits[creditEndpoint],
                            count: 0,
                            count_day: 0,
                            count_total: 0,
                            total_current_month: response.child.allow_req_credits[creditEndpoint]
                        });
                    }
                    emptyCreditsConsumptions[creditEndpoint] = {left: response.child.allow_credits[creditEndpoint], count: 0};
                    emptyCreditsConsumptionsCountOnly[creditEndpoint] = {count: 0};
                });
                this.children.set( response.child.id, {
                    id : response.child.id,
                    email : child.email,
                    creationDate: response.child.creationDate,
                    renewDate: response.child.renewDate,
                    lastname: child.lastname,
                    firstname: child.firstname,
                    allow_credits: child.child_credits,
                    total_current_month: this.user.planIsApi() && this.user.planTypeIsPrePayment() ? undefined : child.child_credits,
                    used: 0,
                    credits_consumption: emptyCreditsConsumptions,
                    today_credits_consumption: emptyCreditsConsumptionsCountOnly,
                    total_credits_consumption: emptyCreditsConsumptionsCountOnly
                });
               this.reloadUser();
            })
            .catch((response) => {
                const errorMessage = this._notification.buildTransiteoErrorMessage(response.error, this.unexpected);
                this._notification.displayMessage(errorMessage, 'danger');
                return Promise.reject();
            });
    }

    async updateChild(id: string, email: string, childUpdate: RequestPutChild): Promise<void> {
        return this.manageChildService.updateChild(id, childUpdate)
            .then((response: ResponseManageChild) => {
                this._notification.displayMessage(this.updated, 'success');
                const child = this.children.get(id);
                if (childUpdate.child_credits) {
                    child.allow_credits = childUpdate.child_credits;
                }
                if (childUpdate.child_occasionnal_credits) {
                    child.total_current_month += childUpdate.child_occasionnal_credits;
                }
                if (response.child.allow_req_credits) {
                    Object.keys(response.child.allow_req_credits).forEach(creditEndpoint => {
                        if (! child.credits_consumption[creditEndpoint]) {
                            child.credits_consumption[creditEndpoint] = {left: 0, count: 0};
                        }
                        child.credits_consumption[creditEndpoint].left = response.child.allow_req_credits[creditEndpoint];
                        const indexEndpoint = this.endpointsDetails[email].findIndex(ed => ed && ed.endpoint === creditEndpoint);
                        if (indexEndpoint >= 0) {
                            if (this.requestIsCoupledWithOtherRequests(creditEndpoint)) {
                                const endpoints = this.coupledConsumptions.find(cc => cc.includes(creditEndpoint));
                                endpoints.forEach(endpoint => {
                                    const i = this.endpointsDetails[email].findIndex(e => e.endpoint === endpoint);
                                    if (i >= 0) {
                                        this.endpointsDetails[email][i].left = response.child.allow_req_credits[creditEndpoint];
                                        this.endpointsDetails[email][i].total_current_month = (this.user.planIsApi() && this.user.planTypeIsPrePayment()) ? this.endpointsDetails[email][i].left + this.endpointsDetails[email][i].count : undefined;
                                    } else {
                                        this.endpointsDetails[email].push({
                                            endpoint: endpoint,
                                            left: response.child.allow_req_credits[creditEndpoint],
                                            count: 0,
                                            count_day: 0,
                                            count_total: 0,
                                            total_current_month: (this.user.planIsApi() && this.user.planTypeIsPrePayment()) ? response.child.allow_req_credits[creditEndpoint] : undefined
                                        });
                                    }
                                });
                            } else {
                                this.endpointsDetails[email][indexEndpoint].left = response.child.allow_req_credits[creditEndpoint];
                                this.endpointsDetails[email][indexEndpoint].total_current_month = (this.user.planIsApi() && this.user.planTypeIsPrePayment()) ? this.endpointsDetails[email][indexEndpoint].left + this.endpointsDetails[email][indexEndpoint].count : undefined;
                            }
                        } else if (this.user.planIsApi() && this.user.planTypeIsPrePayment()) {
                            this.endpointsDetails[email].push({
                                endpoint: creditEndpoint,
                                left: response.child.allow_req_credits[creditEndpoint],
                                count: 0,
                                count_day: 0,
                                count_total: 0,
                                total_current_month: response.child.allow_req_credits[creditEndpoint]
                            });
                        }
                    });
                    this.endpointsDetails[email].filter(ed => ed.count > 0 || ed.left > 0);
                }
                this.children.set(id, child);
                this.reloadUser();
            })
            .catch((error) => {
                console.log(error);
                const errorMessage = this._notification.buildTransiteoErrorMessage(error.error, this.unexpected);
                this._notification.displayMessage(errorMessage, 'danger');
                return Promise.reject();
            });
    }

    openModal(template: TemplateRef<any>, type = 'addrow', id?: string) {
        const rows = this.getChildrenAsArray();
        this.closingModal = false;
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        if (type === 'addrow') {
            if (this.mustSetCreditsByEndpoint()) {
                this.setAvailableEndpoints();
            }
            this.initRowForm(null);
        } else {
            const foundRow = rows.find(r => r.email === id);
            if (foundRow) {
                if (type === 'editrow') {
                    if (this.mustSetCreditsByEndpoint()) {
                        this.setAvailableEndpoints();
                    }
                    this.isEditing = true;
                    this.initRowForm(foundRow);
                } else {
                    this.childToDeleteEmail = id;
                }
            }
        }
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.closingModal = true;
        this.formIsInvalid = false;
        this.isEditing = false;
        this.modalRef.hide();
    }

    addControlInRowForm(controlId: string, value: any = null) {
        this.rowForm.addControl(controlId, new FormControl(value, []));
    }

    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    mustSetCreditsByEndpoint(): boolean {
        return this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT;
    }

    initRowForm(row: any) {
        // edit row
        if (row) {
            const rightChild = this.getChildrenAsArray().find(c => c.email === row.email);
            this.rowForm = this.formBuilder.group({
                email: [rightChild.email],
                firstname: [rightChild.firstname],
                lastname: [rightChild.lastname]
            });
            // insert credits endpoints as fields
            if (this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
                this.availableEndpoints.forEach(endpoint => {
                    const foundEndpoint = this.endpointsDetails[rightChild.email].find(e => e.endpoint === endpoint.endpoint.split(',')[0]);
                    this.addControlInRowForm(`periodic_${endpoint.id}`, foundEndpoint ? foundEndpoint.periodic : undefined);
                    this.addControlInRowForm(`allocate_${endpoint.id}`);
                });
            // insert periodic & occasionnal credits
            } else {
                this.addControlInRowForm('periodic_credits');
                this.addControlInRowForm('occasionnal_credits');
            }

        // new row
        } else {
            this.rowForm = this.formBuilder.group({
                email: ['', [Validators.required, Validators.email]],
                firstname: ['', [Validators.required, Validators.minLength(1)]],
                lastname: ['', [Validators.required, Validators.minLength(1)]]
            });
            // insert credits endpoints as fields
            if (this.user.planIsApi() && this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
                this.availableEndpoints.forEach(endpoint => {
                    this.addControlInRowForm(`periodic_${endpoint.id}`);
                    this.addControlInRowForm(`allocate_${endpoint.id}`);
                });
            // insert periodic credits
            } else {
                this.rowForm.addControl('periodic_credits', new FormControl(null, [Validators.required, Validators.min(0)]));
            }
        }
    }

    async deleteChild(id: string): Promise<void> {
        return this.manageChildService.deleteChild(id)
            .then((response: ResponseManageChild) => {
                this._notification.displayMessage(this.deleted, 'success');
                this.children.delete( id );
                this.childToDeleteEmail = null;
                if (this.user.planIsApi()) {
                    this.maxChildren -= 1;
                }
                this.reloadUser();
            })
            .catch((response) => {
                const errorMessage = this._notification.buildTransiteoErrorMessage(response.error, this.unexpected);
                this._notification.displayMessage(errorMessage, 'danger');
                return Promise.reject();
            });
    }

    onCreateChild() {
        this.openModal(this.editrow);
    }
    onEditChild(rowId: string) {
        this.openModal(this.editrow, 'editrow', rowId);
    }
    onDeleteChild(rowId: string) {
        this.openModal(this.confirmDeleteRow, 'deleterow', rowId);
    }
    onValidRowDeletion() {
        const child = this.getChildrenAsArray().find(c => c.email === this.childToDeleteEmail);
        if (child) {
            this.deleteChild(child.id);
        }
        this.closeModal();
    }

    // is the request coupled with other requests ?
    requestIsCoupledWithOtherRequests(request: string): boolean {
        return this.coupledConsumptions.findIndex(cc => cc.includes(request)) >= 0;
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format dates
        formatValues.push({
            columns: ['creationDate', 'renewDate'],
            type: 'Date'
        });

        this.formatColumnsValues = formatValues;
    }

    get filterChildContent() {
        return this.filterForm.get('filter').value;
    }
    prepareAutocompleteProduct() {
        const control = this.filterForm.get('filter');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this.filterChild(value, this.managersService.getOptionsChildren())),
        );
    }
    // filter used for autocompletion filter form
    private filterChild(value: string, wholeValues: string[]): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
        });
    }
    childInFilter(row: any): boolean {
        if (this.filterChildContent) {
            return this.filterChildContent === '' ||
                row.email.toLowerCase().includes(this.filterChildContent.toLowerCase()) ||
                row.firstname.toLowerCase().includes(this.filterChildContent.toLowerCase()) ||
                row.lastname.toLowerCase().includes(this.filterChildContent.toLowerCase()) ||
                this.filterChildContent.toLowerCase().includes(row.email.toLowerCase()) ||
                this.filterChildContent.toLowerCase().includes(row.firstname.toLowerCase()) ||
                this.filterChildContent.toLowerCase().includes(row.lastname.toLowerCase());
        }
        return true;
    }
}
