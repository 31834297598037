import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { CustomerDocumentsAvailableActions, MY_DOCUMENTS_SELECTED_ROWS_ACTIONS, UserPdfContent, UserPdfContentRow, UserPdfContentWithOcrization, UserPdfOcrizationContent, UserPdfOcrizationContentResponse, UserPdfUrl } from '../../../models/Managers';
import {saveAs} from 'file-saver';
import { CsvService } from '../../services/csv.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ManagersService } from '../../services/managers.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
@Component({
    selector: 'app-my-documents',
    templateUrl: './my-documents.component.html',
    styleUrls: ['./my-documents.component.scss'],
})
export class MyDocumentsComponent implements OnInit, OnDestroy {
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    @ViewChild('myTable', { static: false }) table: any;

    // to request the API in order to get the next data
    nextIndex: any;

    // table rows
    rows: UserPdfContentRow[] = [];
    columnsData: string[] = [];

    // expanded rows contents (display to_countries)
    formattedRowsDetailsForTable: [
        {
            [key: string]: { [key: string]: string }[]
        },
        {
            [key: string]: { [key: string]: string }[]
        }
    ] = [{}, {}];
    rowsDetailsColumns: string[][] = [[], []];
    rowsDetailsColumnsLabels: { [key: string]: string }[] = [{}, {}];

    // form group : select customer to which associate the imported invoice PDF
    customerForm: FormGroup;
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    // display invoice pdf import
    displayImportInvoicePdf = false;
    importedDocumentFilename: string;
    importedInvoiceContent = '';

    // actions
    selectedAction = 0;
    availableActions: string[] = [];
    actionInProgress = false;
    selectedRowsNextAction: MY_DOCUMENTS_SELECTED_ROWS_ACTIONS;

    unknown_error: string;
    columnsLabels: any;

    private isRetrievingData = false;

    requestSelectedRowsSubject: Subject<void> = new Subject<void>();

    private subscription: Subscription;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private csvService: CsvService,
        private managersService: ManagersService,
        private formBuilder: FormBuilder
    ) {
        this.customerForm = this.formBuilder.group({
            company_name: [''], // display it in front
            website_url: [''], // customer-customers SK, used for autocompletion
        });
        this.subscription = this.customerForm.get('company_name').valueChanges.subscribe(() => {
            this.customerForm.get('website_url').setValue('');
        });
    }

    async ngOnInit() {
        this.nextIndex = undefined;
        this.rows = [];

        this.initTranslation();
        this.setColumnsData();
        await this.retrieveData();
        this.managersService.prepareCustomers();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // are rows empty?
    emptyRows(): boolean {
        if (this.rows === null || (this.rows && this.rows.length === 0)) {
            return true;
        }
        return false;
    }

    private addUserPdfOcrization(userPdf: UserPdfContentWithOcrization | UserPdfOcrizationContent) {
        if (userPdf.ocrization) {
            this.formattedRowsDetailsForTable[0][userPdf.pdf_id] = [];
            this.formattedRowsDetailsForTable[1][userPdf.pdf_id] = [];
            // first table regrouping line_items
            if (userPdf.ocrization['line_item'] !== undefined) {
                const ocrizationItems = userPdf.ocrization['line_item'] as { [key: string]: string }[];
                ocrizationItems.forEach((item) => {
                    const currentLineItem: { [key: string]: string } = {};
                    const itemKeys = Object.keys(item);
                    itemKeys.forEach((key) => {
                        if (!this.rowsDetailsColumns[0].includes(key)) {
                            this.rowsDetailsColumns[0].push(key);
                            this.rowsDetailsColumnsLabels[0][key] = key;
                        }
                        currentLineItem[key] = item[key];
                    });
                    this.formattedRowsDetailsForTable[0][userPdf.pdf_id].push(currentLineItem);
                });
            }

            // second table for ocrization keys != line_item
            const ocrizationKeys = Object.keys(userPdf.ocrization).filter(key => key !== 'line_item');
            const currentLineItem: { [key: string]: string } = {};
            ocrizationKeys.forEach((key) => {
                if (!this.rowsDetailsColumns[1].includes(key)) {
                    this.rowsDetailsColumns[1].push(key);
                    this.rowsDetailsColumnsLabels[1][key] = key;
                }
                currentLineItem[key] = userPdf.ocrization[key] as string;
            });
            this.formattedRowsDetailsForTable[1][userPdf.pdf_id].push(currentLineItem);
        }
    }

    async retrieveData() {
        this.isRetrievingData = true;
        const userPdfs: UserPdfOcrizationContentResponse = await this.getData();
        if (userPdfs !== null) {
            this.nextIndex = userPdfs.nextIndex;
            userPdfs.data.forEach((userPdf) => {
                const userPdfContent = this.buildData(userPdf);
                this.rows.push(userPdfContent.toRow());
                this.addUserPdfOcrization(userPdf);
            });
            this.rows = [...this.rows];
        }
        this.isRetrievingData = false;
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });
        this.translate.get('myDocuments').toPromise().then(myDocuments => {
            this.columnsLabels = myDocuments.inputForm;
            this.availableActions = Object.values(myDocuments.actions);
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.columnsLabels = event.translations['myDocuments']['inputForm'];
            this.availableActions = Object.values(event.translations['myDocuments']['actions']);
        });
    }

    onScrollToEnd() {
        if (!this.isRetrievingData && this.nextIndex !== undefined) {
            this.retrieveData();
        }
    }

    // DATA FUNCTIONS
    buildData(data: UserPdfContent): UserPdfContent {
        return Builder(UserPdfContent)
            .mail(data.mail)
            ['pdf_type#pdf_id'](data['pdf_type#pdf_id'])
            .pdf_type(data.pdf_type)
            .pdf_id(data.pdf_id)
            .date(data.date)
            .expireTime(data.expireTime)
            .template_id(data.template_id)
            .lang(data.lang)
            .customer_website(data.customer_website)
            .customer_company_name(data.customer_company_name)
            .order_id(data.order_id)
            .products_ids(data.products_ids)
            .build();
    }
    setColumnsData() {
        this.columnsData = [
            'type',
            'id',
            'customer_company_name',
            'order_id',
            'products_ids'
        ];
    }
    async getData(): Promise<UserPdfOcrizationContentResponse> {
        try {
            if (this.nextIndex && this.nextIndex['pdf_type#pdf_id']) {
                return (<UserPdfOcrizationContentResponse> await this.http.get(
                    `https://api.${environment.baseUrl}/v1/pdf?index=${this.nextIndex['pdf_type#pdf_id']}`
                ).toPromise());
            }
            return (<UserPdfOcrizationContentResponse> await this.http.get(
                `https://api.${environment.baseUrl}/v1/pdf`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    async getPdfUrl(userPdfContent: UserPdfContentRow): Promise<UserPdfUrl> {
        try {
            return (<UserPdfUrl> await this.http.get(
                `https://api.${environment.baseUrl}/v1/pdf/url/${userPdfContent.type}/${userPdfContent.id}`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    async downloadPdf(rowId: string) {
        const userPdfContent = this.rows.find(r => r.id === rowId);
        const pdfUrl = await this.getPdfUrl(userPdfContent);
        if (pdfUrl) {
            saveAs(pdfUrl.signed_url.substring(0, pdfUrl.signed_url.indexOf('?Expires=')), `${userPdfContent.type}_${userPdfContent.id}.pdf`);
        }
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv(rows: UserPdfContentRow[]) {
        if (rows.length > 0) {
            const csvFileName = 'my_documents';
            const rowsWithOcrization = rows.map(row => {
                const ocrization = this.formattedRowsDetailsForTable[row.id];
                if (ocrization && ocrization.length > 0) {
                    const keys = Object.keys(ocrization[0]);
                    const newOcrizationsWithPrefix: any = {};
                    keys.forEach(key => {
                        newOcrizationsWithPrefix[`ocrization_${key}`] = ocrization[0][key];
                    });
                    return { ...row, ...newOcrizationsWithPrefix };
                }
                return row;
            });
            return this.csvService.downloadFile(rowsWithOcrization, csvFileName);
        }
    }

    onDownloadSelectedRowsAsCsv() {
        this.selectedRowsNextAction = MY_DOCUMENTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV;
        this.onClaimSelectedRows();
    }
    onValidAction() {
        this.actionInProgress = true;
        this.selectedRowsNextAction = MY_DOCUMENTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION;
        this.onClaimSelectedRows();
    }
    onClaimSelectedRows() {
        this.requestSelectedRowsSubject.next();
    }
    onReceiveSelectedRows(rowsIds: string[]) {
        const selectedRows = this.rows.filter(r => rowsIds.includes(r.id));
        switch (this.selectedRowsNextAction) {
            case MY_DOCUMENTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV:
                this.downloadCsv(selectedRows);
                this.reinitializeActionVars();
                break;
            case MY_DOCUMENTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION:
                if (selectedRows.length > 0) {
                    this.onExecuteAction(selectedRows);
                } else {
                    this.reinitializeActionVars();
                }
                break;
            default:
                break;
        }
    }

    selectAction(action: number) {
        this.selectedAction = action;
    }
    async onExecuteAction(selectedRows: UserPdfContentRow[]) {
        switch (this.selectedAction) {
            case CustomerDocumentsAvailableActions.TO_AKANEA:
                // TODO
                break;

            case CustomerDocumentsAvailableActions.CLEAR_CUSTOMS:
                // TODO
                break;

            case CustomerDocumentsAvailableActions.TO_IMPORT:
                // TODO
                break;

            case CustomerDocumentsAvailableActions.TO_EXPORT:
                // TODO
                break;

            case CustomerDocumentsAvailableActions.OCRIZE:
                await this.executeActionOcrize(selectedRows);
                break;

            default:
                console.log('Unknown action');
                break;
        }
        this.reinitializeActionVars();
    }
    private async executeActionOcrize(selectedRows: UserPdfContentRow[]) {
        try {
            for (let i = 0; i < selectedRows.length; i++) {
                // import the new invoice and prepend it to the rows list
                const result = await this.http.post(
                    `https://api.${environment.baseUrl}/v1/pdf/action/ocrization`,
                    {
                        pdf_id: selectedRows[i].id,
                        pdf_type: selectedRows[i].type
                    }
                ).toPromise() as UserPdfOcrizationContent;
                // update the detailed row with the new ocrization data
                this.addUserPdfOcrization(result);
                this._notification.displayMessage(this.translate.instant('myDocuments.actionOcrizationDone', { current: i+1, total: selectedRows.length }), 'success');
            }
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(
                this._notification.buildTransiteoErrorMessage(errorResponse.error, errorResponse.statusText),
                'danger',
                10000
            );
        }
    }
    private reinitializeActionVars(): void {
        this.actionInProgress = false;
        this.selectedRowsNextAction = undefined;
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[]): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            return optionSplitted[0].toLowerCase().includes(filterValue)
                || optionSplitted[1].toLowerCase().includes(filterValue)
                || optionSplitted[2].toLowerCase().includes(filterValue);
        });
    }
    prepareAutocompleteCustomer() {
        const control = this.customerForm.get('company_name');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsCustomers())),
        );
    }
    onCustomerClicked(option: string) {
        // website is the sort key
        const website = option.split(', ')[2];
        const rightCustomer = this.managersService.getCustomers().find(c => c.website === website);
        if (rightCustomer) {
            this.customerForm.get('company_name').setValue(rightCustomer.companyName);
            this.customerForm.get('website_url').setValue(rightCustomer.website);
        }
    }

    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
    }
    beforeUploadInvoicePdf = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file as any);
        this.cleanFiles(file);
        reader.onloadend = async (e) => {
            this.importedDocumentFilename = file.name.replace(/\.pdf/g, '');
            this.importedInvoiceContent = reader.result as string;
        };
        return true;
    }
    customerFormIsValid() {
        return this.customerForm.get('company_name').value === '' || this.customerForm.get('website_url').value !== '';
    }
    async onValidImportInvoice() {
        if (this.importedInvoiceContent !== '' && this.customerFormIsValid()) {
            try {
                // import the new invoice and prepend it to the rows list
                const newDocument = await this.http.post(
                    `https://api.${environment.baseUrl}/v1/pdf`,
                    {
                        content: this.importedInvoiceContent,
                        filename: this.importedDocumentFilename,
                        customer_website: this.customerForm.get('website_url').valid ? this.customerForm.get('website_url').value : undefined,
                        customer_company_name: this.customerForm.get('company_name').valid ? this.customerForm.get('company_name').value : undefined
                    }
                ).toPromise() as UserPdfContent;
                this.rows.splice(0, 0, this.buildData(newDocument).toRow());
                this._notification.displayMessage(this.translate.instant('myDocuments.importInvoice.imported'), 'success');
            } catch (errorResponse) {
                console.log('ERROR : ' + JSON.stringify(errorResponse));
                this._notification.displayMessage(
                    this._notification.buildTransiteoErrorMessage(errorResponse.error, errorResponse.statusText),
                    'danger',
                    10000
                );
            }
            this.importedDocumentFilename = undefined;
            this.importedInvoiceContent = '';
        }
    }
}
