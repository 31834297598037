import { Component, OnInit } from '@angular/core';
import { CsvService } from '../../services/csv.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Page } from '../../../models/page';
import { MessageService } from '../../components/message/message.service';
import { environment } from '../../../../environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Country } from './interfaces/country';
import { SelectedCountry } from './interfaces/selected-country';
import { CountryHelper } from './country-helper';
import { UserService } from '../../services/user.service';
import { PLAN_ENUM, TRIAL_PLAN, User } from '../../../models/User';

@Component({
    selector: 'app-card-countries',
    templateUrl: './card-countries.component.html',
    styleUrls: ['./card-countries.component.scss'],
})
export class CardCountriesComponent implements OnInit {
    page: Page;
    apiCountries: Map<string, Country>;
    countriesUrl: string;
    loadedCountries: Array<string>;
    header: Array<any>;
    filterDataCategories: Array<any>;
    selectCategories: Array<string>;
    countries: Array<any>;
    selectedSecondCountry: SelectedCountry;
    secondCountry: Country;
    selectedFirstCountry: SelectedCountry;
    firstCountry: Country;
    cache: any;
    messages;
    countries_card_failed_init: string;
    countries_card_failed_get_country: string;
    countries_card_failed_csv: string;
    unexpected: string;
    user: User;
    canCallAPI: boolean = true;
    planUrl: string;

    constructor(
        private userService: UserService,
        private http: HttpClient,
        private _notification: MessageService,
        private route: ActivatedRoute,
        private csvService: CsvService,
        private translate: TranslateService,
    ) {
        this.user = this.userService.getUser();
        if (this.user) {
            if (this.user.plan === PLAN_ENUM.START || this.user.plan === TRIAL_PLAN) {
                this.canCallAPI = false;
            }
        }
        this.countriesUrl = `https://api.${environment.baseUrl}/v2/data/country`;
        this.planUrl = `https://app.${environment.baseUrl}/#/plans`;
        this.page = new Page();
        this.loadedCountries = [];
        this.apiCountries = new Map<string, Country>();
        this.countries_card_failed_init = 'countries card failed to init';
        this.countries_card_failed_get_country = 'countries card failed to get country';
        this.countries_card_failed_csv = 'countries card failed to load csv';
        this.unexpected = 'Unexpected error';
        this.selectedSecondCountry = null;
        this.selectedFirstCountry = null;
        this.cache = {};
        this.isLoading = false;
        this.selectCategories = ['global', 'market', 'internet', 'commerce', 'logistic'];
    }

    private isLoading: any;

    async ngOnInit() {
        this.translate.get('messages').subscribe((messages) => {
            this.updateMessagesTranslation(messages);
        });
        this.translate.get('countries').subscribe((countries) => {
            this.countries = countries;
        });
        this.translate.get('cardCountries.categories').subscribe((categories) => {
            this.filterDataCategories = categories;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateMessagesTranslation(event.translations['messages']);
            this.countries = event.translations['countries'];
            this.filterDataCategories = event.translations['cardCountries'].categories;
            if (this.selectedFirstCountry !== null) {
                this.selectedFirstCountry = event.translations['countries'].find((e) => e.value === this.selectedFirstCountry.value);
            }
            if (this.selectedSecondCountry !== null) {
                this.selectedSecondCountry = event.translations['countries'].find((e) => e.value === this.selectedSecondCountry.value);
            }
        });
    }

    updateMessagesTranslation(messages) {
        this.countries_card_failed_init = messages.notification.countries_card_failed_init;
        this.countries_card_failed_get_country = messages.notification.countries_card_failed_get_country;
        this.countries_card_failed_csv = messages.notification.countries_card_failed_csv;
        this.unexpected = messages.notification.UnexpectedError;
    }

    updateCategory(event) {
        if (event) {
            this.selectCategories = event;
        } else {
            this.selectCategories = [];
        }
    }

    // Load from the API a country's props and/or just show it in the table
    async loadCountry(isoCountry): Promise<void> {
        return this.http
            .get(this.countriesUrl + '?iso3=' + isoCountry)
            .toPromise()
            .then((responseCountries: any) => {
                let country: Country = CountryHelper.apiResponseToCountry(responseCountries);
                this.apiCountries.set(isoCountry, country);
                this.loadedCountries.push(isoCountry);
            })
            .catch((response) => {
                const errorMessage = this._notification.buildTransiteoErrorMessage(response.error, this.unexpected);
                this._notification.displayMessage(errorMessage, 'danger');
                return Promise.reject();
            });
    }

    async updateCountry(country: SelectedCountry, index: number) {
        if (country) {
            switch (index) {
                case 0:
                    this.selectedFirstCountry = country;
                    this.firstCountry = undefined;
                    break;
                case 1:
                    this.selectedSecondCountry = country;
                    this.secondCountry = undefined;
                    break;
                default:
                    console.warn('Did not recognized country index');
                    return;
            }
            if (this.apiCountries.has(country.value)) {
                switch (index) {
                    case 0:
                        this.firstCountry = this.apiCountries.get(country.value);
                        break;
                    case 1:
                        this.secondCountry = this.apiCountries.get(country.value);
                        break;
                }
            } else {
                await this.loadCountry(country.value)
                    .then(() => {
                        switch (index) {
                            case 0:
                                this.firstCountry = this.apiCountries.get(country.value);
                                break;
                            case 1:
                                this.secondCountry = this.apiCountries.get(country.value);
                                break;
                        }
                    })
                    .catch((reason) => {
                        console.warn(reason);
                        this._notification.displayMessage(this.countries_card_failed_get_country, 'danger');
                    });
            }
        } else {
            switch (index) {
                case 0:
                    this.selectedFirstCountry = null;
                    this.firstCountry = undefined;
                    break;
                case 1:
                    this.selectedSecondCountry = null;
                    this.secondCountry = undefined;
                    break;
            }
        }
    }

    generateCsv() {
        let endFileName = String();
        let startFileName = String();
        if (
            this.selectedFirstCountry &&
            this.selectedSecondCountry &&
            this.selectedFirstCountry['label'] !== undefined &&
            this.selectedSecondCountry['label'] !== undefined
        ) {
            endFileName = '_' + this.selectedFirstCountry['label'] + '_' + this.selectedSecondCountry['label'];
        } else if (this.selectedFirstCountry && this.selectedFirstCountry['label'] !== undefined) {
            endFileName = '_' + this.selectedFirstCountry['label'];
        } else if (this.selectedSecondCountry && this.selectedSecondCountry['label'] !== undefined) {
            endFileName = '_' + this.selectedSecondCountry['label'];
        } else {
            this._notification.displayMessage(this.countries_card_failed_csv, 'danger');
            return false;
        }

        let dataCsv = [];
        if (this.firstCountry !== undefined) {
            let csvFirstCountry = CountryHelper.countryToPlainObject(this.firstCountry);
            dataCsv.push(csvFirstCountry);
        }
        if (this.secondCountry !== undefined) {
            let csvSecondCountry = CountryHelper.countryToPlainObject(this.secondCountry);
            dataCsv.push(csvSecondCountry);
        }
        if (dataCsv.length > 0) {
            this.csvService.downloadFile(dataCsv, 'countries' + startFileName + endFileName);
        }
    }
}
