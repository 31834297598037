import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ALL_PLAN_TYPE_ENUM, PLAN_ENUM, PLAN_V2_ENUM, User } from '../../../models/User';
import { UserService } from '../../services/user.service';
import { StripeService } from '../../services/stripe.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENTATIONS_GUIDES } from '../documentation-guide/documentation-guide.component';
import { Router } from '@angular/router';
const plan_currency = require('../../../../assets/data/plan_currency.json');

interface PlanV2 {
    plans: {
        API: {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
        'E-COMMERCE': {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
        'SAAS-START': {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
        'SAAS-ROCKET': {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
        'SAAS-COSMOS': {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
        'SAAS-INFINITE': {
            'pay-as-you-go'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
            'pre-payment'?: {
                id: string;
                name: string;
                period: string;
                amount: number;
                currency: string;
                withRequests: boolean; // Can add conso?
            };
        };
    };
    requests: {
        [key: string]: {
            billing_scheme: string,
            tiers: { up_to: number, unit_amount: number }[],
            id: string,
            currency: string,
            tiers_mode: string
        }
    };
    children: {
        API: {
            id: string,
            name: string,
            period: string,
            amount: number,
            currency: string,
            withRequests: boolean
        }
    };
    technicalSupport: {
        'E-COMMERCE': {
            id: string,
            name: string,
            period: string,
            amount: number,
            currency: string
        }
    };
}

@Component({
    selector: 'app-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit, OnDestroy {
    user: User;

    // forms
    choosePlanForm: FormGroup;
    // trialCheckout: FormGroup;
    consoForm: FormGroup;
    childrenForm: FormGroup;

    isRequesting = false;

    langs: Array<{ label: string, value: string, disabled: boolean }> = [];
    currencies: Array<{ label: string, value: string, disabled: boolean }> = [];
    plan_currency: Array<{ label: string, value: string }> = [];
    availableExtraCreditsSaas: { label: string, value: string }[] = [];

    allPlans: { label: string; value: string; disabled: boolean; }[] = [];
    selectedPlan: string;
    selectedPlanChosen = false;
    selectedPlanSaas: string;
    selectedConso: {request: string, ecommerce?: string, api?: string, saas?: string, conso: number}[] = [];
    coupledConsumptions: string[][] = [];
    validSelectedConso = false;
    validSelectedChildrenSubscriptions = false;
    planPeriod: string;
    planPrices: PlanV2;

    ecommerceIncludedUnlimited: string[] = [];
    ecommerceOnDemand: string[] = [];
    apiPreLive: string[] = [];
    apiPostLive: string[] = [];

    // endpoints
    getPlanPrices: string;
    generatePlanCheckoutSession: string;
    generateExtraCreditsCheckoutSession: string;
    generateChildSubscriptionCheckoutSession: string;
    generateTechnicalSupportSubscriptionCheckoutSession: string;

    lang: string;
    month: string;
    three_months: string;
    year: string;
    credits = 'credits';
    private extraCreditsValues = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000];

    discount_message: string;
    discount_unknown: string;
    discounts: Array<{ code: string, onlyForPlans?: string[], message: string }> = [];

    private subscriptions: Subscription;

    displayEmbededWebsite = false;
    urlToEmbed: string;

    constructor(
        private userService: UserService,
        private stripeService: StripeService,
        private authService: AuthService,
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private router: Router
    ) {
        this.user = this.userService.getUser();
        this.getPlanPrices = `https://api.${environment.baseUrl}/v1/price/plans`;
        this.generatePlanCheckoutSession = `https://api.${environment.baseUrl}/v1/checkout/plans/generate`;
        this.generateExtraCreditsCheckoutSession = `https://api.${environment.baseUrl}/v1/checkout/extra/generate`;
        this.generateChildSubscriptionCheckoutSession = `https://api.${environment.baseUrl}/v1/checkout/childSubscription/generate`;
        this.generateTechnicalSupportSubscriptionCheckoutSession = `https://api.${environment.baseUrl}/v1/checkout/technicalSupportSubscription/generate`;
        this.plan_currency = plan_currency;
        this.langs = [];
        this.choosePlanForm = new FormGroup({
            planPeriod: new FormControl('month'),
            currency: new FormControl(this.user && this.plan_currency.findIndex(pc => pc.value === this.user.currency.toUpperCase()) >= 0 ? this.user.currency.toLowerCase() : 'eur'),
            planType: new FormControl('pre-payment'),
            conditions: new FormControl('', Validators.required),
            selectedPlan: new FormControl('', Validators.required),
            extraCredits: new FormControl(null),
            discount_code: new FormControl('')
        });
        this.childrenForm = new FormGroup({
            childrenNumber: new FormControl(0, [Validators.required])
        });
    }

    get conditions(): boolean {
        return this.choosePlanForm.get('conditions').value === true;
    }

    async ngOnInit() {
        await this.initUserPrefs(this.user);
        this.initTranslation();
        this.selectedPlan = this.user.planIsNewSaas() ? 'SAAS' : this.user.getPlan();
        if (this.user.planIsNewSaas()) {
            this.onSelectSaasPlan(this.user.getPlan());
        }
        if (! Object.keys(PLAN_V2_ENUM).includes(this.selectedPlan)) {
            this.initializeSelectedPlan();
        }
        this.choosePlanForm.get('selectedPlan').setValue(this.selectedPlan);
        if (this.user.getPlanType() === ALL_PLAN_TYPE_ENUM.PAY_AS_YOU_GO) {
            this.choosePlanForm.get('planType').setValue(ALL_PLAN_TYPE_ENUM.PAY_AS_YOU_GO);
        }
        if (this.user.getPlanType() === this.choosePlanForm.get('planType').value) {
            this.selectedPlanChosen = true;
        }
        /*if (this.user.isTrial()) {
            this.trialCheckout = new FormGroup({
                address: new FormControl('', [Validators.required]),
                zipCode: new FormControl('', [Validators.required]),
                city: new FormControl('', [Validators.required]),
                indicatif: new FormControl('', [Validators.required]),
                phone_number: new FormControl('', [Validators.required]),
                lang: new FormControl(this.lang),
                currency: new FormControl(this.choosePlanForm.get('currency').value),
                siret: new FormControl('', [Validators.required]),
                fiscal_number: new FormControl('', [Validators.required]),
                conditionsTrial: new FormControl('', Validators.required),
                discount_code: new FormControl(''),
            });
        }*/
        this.findPlanPrices();
        this.setupOnChanges();
    }
    ngOnDestroy() {
        if (this.subscriptions) {
            try {
                this.subscriptions.unsubscribe();
            } catch (error) {
                this.subscriptions = null;
                console.log(error);
            }
        }
    }

    initTranslation() {
        this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies.filter(currency => this.plan_currency.findIndex(c => c.value === currency.value.toUpperCase()) >= 0);
        });
        this.translate.get('messages').toPromise().then(messages => {
            this.updateMessagesTranslation(messages);
        });
        this.translate.get('app_languages').toPromise().then(languages => {
            this.langs = languages;
        });
        this.translate.get('plan').toPromise().then(plan => {
            this.credits = plan.credits;
            this.discounts = plan.discounts;
            this.discount_unknown = plan.discount_unknown;
            this.allPlans = plan.plans;
            this.ecommerceIncludedUnlimited = plan.ecommerce.services.includedUnlimited.content;
            this.ecommerceOnDemand = plan.ecommerce.services.onDemand.content;
            this.apiPreLive = plan.api.setup.preLive.content;
            this.apiPostLive = plan.api.setup.postLive.content;
        });
        this.coupledConsumptions = this.translate.instant('plan.formula4.coupledConsumptions');

        const extraCreditsLabel = this.translate.instant('plan.saas.extraCreditsLabel');
        this.availableExtraCreditsSaas = this.extraCreditsValues.map(credit => {
            return {
                label: `${credit} ${extraCreditsLabel}`,
                value: (credit / 100).toFixed(0)
            };
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateMessagesTranslation(event.translations.messages);
            const langs = event.translations.app_languages;
            this.langs = langs ? langs : this.langs;
            this.credits = event.translations.plan.credits;
            this.discounts = event.translations.plan.discounts;
            this.allPlans = event.translations.plan.plans;
            this.discount_unknown = event.translations.plan.discount_unknown;
            this.ecommerceIncludedUnlimited = event.translations.plan.ecommerce.services.includedUnlimited.content;
            this.ecommerceOnDemand = event.translations.plan.ecommerce.services.onDemand.content;
            this.apiPreLive = event.translations.plan.api.setup.preLive.content;
            this.apiPostLive = event.translations.plan.api.setup.postLive.content;
            const extraCreditsLabelUpdated = event.translations.plan.saas.extraCreditsLabel;
            this.availableExtraCreditsSaas = this.extraCreditsValues.map(credit => {
                return {
                    label: `${credit} ${extraCreditsLabelUpdated}`,
                    value: (credit / 100).toFixed(0)
                };
            });
            this.setDiscountMessage();
        });
    }
    updateMessagesTranslation(messages) {
        this.lang = this.user.lang ? this.user.lang.substr(0, 2) : messages.current_lang;
        this.month = messages.month ? messages.month : this.month;
        this.three_months = messages.three_months ? messages.three_months : this.three_months;
        this.year = messages.year ? messages.year : this.year;
    }
    async updatePlan() {
        await this.checkout();
    }
    onOpenAgendaClick() {
        // open Agenda in new tab
        if (! this.user.getApiPlanSetupDone()) {
            window.open(this.translate.instant('plan.api.meetingLink'), '_blank');
        }
    }
    onSelectPlanTypeClick(planType: string) {
        if (this.user.getPlanType() === planType) {
            this.selectedPlanChosen = true;
        } else {
            this.selectedPlanChosen = false;
        }
        this.reinitializeConsos();
    }
    // plan has changed
    onChangePlan(plan: PLAN_V2_ENUM) {
        this.selectedPlan = plan;
        // selectedPlanChosen is used only for plans API & ECOMMERCE
        if (plan === PLAN_V2_ENUM.SAAS) {
            this.selectedPlanChosen = true;
        } else {
            this.selectedPlanChosen = plan === this.user.getPlan() && this.user.getPlanType() === this.choosePlanForm.get('planType').value;
        }
        // only pre-payment type is available for plans SAAS & ECOMMERCE
        if (this.selectedPlan !== PLAN_V2_ENUM.API) {
            this.choosePlanForm.get('planType').setValue('pre-payment');
        }
        if (this.selectedPlan !== PLAN_V2_ENUM.SAAS && this.selectedPlanSaas) {
            this.selectedPlanSaas = this.user.planIsNewSaas() ? this.user.getPlan() : null;
        }
        // get month plans
        if (this.choosePlanForm.get('planPeriod').value === 'year' && plan !== PLAN_V2_ENUM.SAAS) {
            this.choosePlanForm.get('planPeriod').setValue('month');
        } else {
            this.checkIncompatibilitiesAfterChange();
        }
        this.reinitializeConsos();
    }
    onSelectSaasPlan(saasPlan: string) {
        this.selectedPlanSaas = saasPlan;
    }
    onSwitchPlanSelected() {
        this.selectedPlanChosen = ! this.selectedPlanChosen;
    }
    userCanSubscribe(): boolean {
        if (
            ! this.user.getCanChangePlanType() &&
            this.user.getPlanType() !== ALL_PLAN_TYPE_ENUM.FREE &&
            this.user.getPlanType() !== this.choosePlanForm.get('planType').value
        ) {
            return false;
        }
        return true;
    }
    /*private getBodyWithTrialInfos(planInfos) {
        const params = this.trialCheckout.value;
        const trialInfos = {
            address: params.address,
            zipCode: params.zipCode,
            lang: params.lang.substr(0, 2),
            currency: params.currency,
            fiscal_number: params.fiscal_number,
            siret: params.siret,
            city: params.city,
            indicatif: params.indicatif,
            phone_number: params.phone_number,
            discount_code: params.discount_code
        };
        return Object.assign(planInfos, trialInfos);
    }*/
    async fetchCheckoutChildSubscriptionSession(): Promise<string> {
        const body = {
            childrenNumber: this.childrenForm.value.childrenNumber,
            discount_code: this.choosePlanForm.value.discount_code,
            currency: this.choosePlanForm.value.currency.toLowerCase()
        };
        return this.http.post(this.generateChildSubscriptionCheckoutSession, body).toPromise().then((response: any) => {
            return response.sessionId;
        }, (error: any) => {
            if (this._notification && typeof this._notification.create === 'function') {
                this._notification.create(
                    'danger',
                    error.error.message,
                    {
                        Position: 'top',
                        Style: 'bar',
                        Duration: 10000
                    }
                );
            }
            return null;
        });
    }
    async fetchCheckoutTechnicalSupportSubscriptionSession(): Promise<string> {
        const body = {
            currency: this.choosePlanForm.value.currency.toLowerCase()
        };
        return this.http.post(this.generateTechnicalSupportSubscriptionCheckoutSession, body).toPromise().then((response: any) => {
            return response.sessionId;
        }, (error: any) => {
            if (this._notification && typeof this._notification.create === 'function') {
                this._notification.create(
                    'danger',
                    error.error.message,
                    {
                        Position: 'top',
                        Style: 'bar',
                        Duration: 10000
                    }
                );
            }
            return null;
        });
    }
    async fetchCheckoutSession(): Promise<string> {
        const prePaymentRequests = this.getPrePaymentRequests();
        const planInfos: any = {
            plan: this.selectedPlan === PLAN_V2_ENUM.SAAS ? this.selectedPlanSaas : this.selectedPlan,
            payment_type: this.choosePlanForm.value.planType,
            period: this.choosePlanForm.value.planPeriod.toUpperCase(),
            discount_code: this.choosePlanForm.value.discount_code,
            currency: this.choosePlanForm.value.currency.toLowerCase()
        };
        // SAAS plan, add extra credits
        if (this.sameSaasPlan()) {
            planInfos.extraCredits = this.choosePlanForm.value.extraCredits ? parseInt(this.choosePlanForm.value.extraCredits, 10) : 0;
            return this.postExtraCreditsCheckoutSession(planInfos);
        }
        if (prePaymentRequests) {
            planInfos.pre_payment_requests = prePaymentRequests;
        }

        return this.postPlanCheckoutSession(planInfos);
    }
    postPlanCheckoutSession(body: string): Promise<string> {
        return this.http.post(this.generatePlanCheckoutSession, body).toPromise().then((response: any) => {
            return response.sessionId;
        }, (error: any) => {
            if (this._notification && typeof this._notification.create === 'function') {
                this._notification.create(
                    'danger',
                    error.error.message,
                    {
                        Position: 'top',
                        Style: 'bar',
                        Duration: 10000
                    }
                );
            }
            return null;
        });
    }
    postExtraCreditsCheckoutSession(body: string): Promise<string> {
        return this.http.post(this.generateExtraCreditsCheckoutSession, body).toPromise().then((response: any) => {
            return response.sessionId;
        }, (error: any) => {
            if (this._notification && typeof this._notification.create === 'function') {
                this._notification.create(
                    'danger',
                    error.error.message,
                    {
                        Position: 'top',
                        Style: 'bar',
                        Duration: 10000
                    }
                );
            }
            return null;
        });
    }
    getNestedSearch(nested: string) {
        return this.choosePlanForm ? this.choosePlanForm.get(nested) : null;
    }
    async onSubscribeToChildAccount() {
        this.isRequesting = true;
        const sessionId = await this.fetchCheckoutChildSubscriptionSession();
        if (sessionId) {
            const stripe = await this.stripeService.stripePromise;
            // tslint:disable-next-line:no-shadowed-variable
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId
            });
            if (error) {
                console.log(error);
                this._notification.displayMessage(error.message, 'danger');
            }
        }
        this.isRequesting = false;
    }
    async onSubscribeToTechnicalSupport() {
        this.isRequesting = true;
        const sessionId = await this.fetchCheckoutTechnicalSupportSubscriptionSession();
        if (sessionId) {
            const stripe = await this.stripeService.stripePromise;
            // tslint:disable-next-line:no-shadowed-variable
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId
            });
            if (error) {
                console.log(error);
                this._notification.displayMessage(error.message, 'danger');
            }
        }
        this.isRequesting = false;
    }
    async checkout() {
        // API children account subscription
        if (this.validSelectedChildrenSubscriptions) {
            await this.onSubscribeToChildAccount();
            return;
        }
        this.isRequesting = true;
        const sessionId = await this.fetchCheckoutSession();
        if (sessionId) {
            const stripe = await this.stripeService.stripePromise;
            // tslint:disable-next-line:no-shadowed-variable
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionId
            });
            if (error) {
                console.log(error);
                this._notification.displayMessage(error.message, 'danger');
            }
        }
        this.isRequesting = false;
    }
    findPlanPrices(callNumber = 0) {
        this.http.get(`${this.getPlanPrices}?period=${this.choosePlanForm.get('planPeriod').value}&currency=${this.choosePlanForm.get('currency').value}`).toPromise().then(async (response: any) => {
            this.planPrices = response as PlanV2;

            // we must initialize selectedConso and its form
            if (this.selectedConso.length === 0) {
                this.consoForm = new FormGroup({});
                const requests = Object.keys(this.planPrices.requests);
                await requests.forEach(async request => {
                    const plansRequestsNames: string[] = await Promise.all([
                        this.translate.get(`plan.formula4.requests.${request}.ecommerce`).toPromise(),
                        this.translate.get(`plan.formula4.requests.${request}.api`).toPromise(),
                        this.translate.get(`plan.formula4.requests.${request}.saas`).toPromise()
                    ]);
                    this.selectedConso.push({
                        request: request,
                        ecommerce: plansRequestsNames[0] && !plansRequestsNames[0].startsWith('plan.formula4.requests') ? plansRequestsNames[0] : undefined,
                        api: plansRequestsNames[1] && !plansRequestsNames[1].startsWith('plan.formula4.requests') ? plansRequestsNames[1] : undefined,
                        saas: plansRequestsNames[2] && !plansRequestsNames[2].startsWith('plan.formula4.requests') ? plansRequestsNames[2] : undefined,
                        conso: 0
                    });
                    this.consoForm.addControl(request, new FormControl(undefined, [Validators.min(250)]));
                });
            }
        }, (error: any) => {
            // could return an error sometimes, handle the issue by recalling the endpoint 3 times maximum
            if (callNumber < 3) {
                this.findPlanPrices(callNumber + 1);
            } else {
                if (this._notification && typeof this._notification.create === 'function') {
                    this._notification.create('danger', error.error.message, { Position: 'top', Style: 'bar', Duration: 10000 });
                }
            }
        });
    }

    // is the request coupled with other requests, and has its index in the array > 0 ?
    requestIsCoupledWithOtherRequests(request: string, isFirstRequest = false): boolean {
        const rightConsumptions = this.coupledConsumptions.find(cc => cc.includes(request));
        if (rightConsumptions) {
            const requestIndex = rightConsumptions.findIndex(cons => cons === request);
            if (
                (
                    ! isFirstRequest && requestIndex > 0
                )
                ||
                (
                    isFirstRequest && requestIndex === 0
                )
            ) {
                return true;
            }
        }
        return false;
    }

    reinitializeConsos() {
        this.selectedConso.forEach(conso => {
            conso.conso = 0;
        });
    }

    private async initUserPrefs(user) {
        if (!user) {
            this.user = await this.authService.reconnect();
        }
    }

    sameSaasPlan(): boolean {
        return this.selectedPlan === PLAN_V2_ENUM.SAAS && this.selectedPlanSaas === this.user.getPlan();
    }

    isOptionDisabled(plan: PLAN_ENUM) {
        return this.user.getPlan() !== plan;
    }
    isCheckoutDisabled(): boolean {
        return ! this.selectedPlan ||
            ! this.choosePlanForm ||
            ! this.selectedPlanChosen ||
            ! this.choosePlanForm.valid ||
            this.isCheckoutDisabledSaas() ||
            this.isCheckoutDisabledPlan();
    }
    isCheckoutDisabledSaas(): boolean {
        return this.selectedPlan === PLAN_V2_ENUM.SAAS &&
        (
            (
                ! this.selectedPlanSaas ||
                this.selectedPlanSaas === this.user.getPlan()
            )
            &&
            (
                this.sameSaasPlan() &&
                ! this.choosePlanForm.value.extraCredits
            )
        );
    }
    isCheckoutDisabledPlan(): boolean {
        return this.user.getPlan() !== PLAN_V2_ENUM.SAAS &&
            (
                this.selectedPlan === this.user.getPlan() &&
                this.choosePlanForm.get('planType').value === this.user.getPlanType() &&
                (
                    this.getConsoPrice() === 0 ||
                    ! this.validSelectedConso
                )
            );
    }
    setDiscountMessage() {
        const code = this.choosePlanForm.value.discount_code;
        this.discount_message = undefined;
        /*if (this.user.isTrial()) {
            code = this.trialCheckout.value.discount_code;
        } else {
            code = this.choosePlanForm.value.discount_code;
        }*/
        if (code) {
            for (let index = 0; index < this.discounts.length; index++) {
                const element = this.discounts[index];
                if (element.code === code && (! element.onlyForPlans || element.onlyForPlans.includes(this.selectedPlan))) {
                    this.discount_message = element.message;
                }
            }
            if (!this.discount_message) {
                this.discount_message = this.discount_unknown;
            }
        }
    }

    getPlanPrice(plan: string): string {
        const planType = this.choosePlanForm.get('planType').value;
        if (this.planPrices && this.planPrices.plans && this.planPrices.plans[plan] && this.planPrices.plans[plan][planType]) {
            return `${(this.planPrices.plans[plan][planType].amount / 100).toFixed(2)}`;
        }
        return `--`;
    }

    getChildPrice(plan: string): string {
        const childrenNumber = this.childrenForm.value.childrenNumber ? this.childrenForm.value.childrenNumber : 0;
        if (this.planPrices && this.planPrices.children && this.planPrices.children[plan] && this.planPrices.plans[plan]) {
            return `${(this.planPrices.children[plan].amount * childrenNumber / 100).toFixed(2)}`;
        }
        return `--`;
    }

    getTechnicalSupportPrice(plan: string): string {
        if (this.planPrices && this.planPrices.technicalSupport && this.planPrices.technicalSupport[plan] && this.planPrices.plans[plan]) {
            return `${(this.planPrices.technicalSupport[plan].amount / 100).toFixed(2)}`;
        }
        return `--`;
    }

    getCurrencyWithPeriod(onlyCurrency = false, plan = 'API'): string {
        const planType = this.choosePlanForm.get('planType').value;
        if (this.planPrices && this.planPrices.plans && this.planPrices.plans[plan] && this.planPrices.plans[plan][planType]) {
            if (onlyCurrency) {
                return this.planPrices.plans[plan][planType].currency;
            }
            return this.planPrices.plans[plan][planType].currency
                    + ` / ${this.planPrices.plans[plan][planType].period === 'month' ? this.month : this.year}`;
        }
        return '';
    }

    getCurrencyWithPeriodTechnicalSupport(plan = 'E-COMMERCE'): string {
        const period = this.planPrices.technicalSupport[plan].period;
        return `${this.planPrices.technicalSupport[plan].currency} / ${period === '3month' ? this.three_months : (period === 'month' ? this.month : this.year)}`;
    }

    validRequestConso(i: number) {
        if (this.consoForm.get(this.selectedConso[i].request).valid) {
            const conso = this.consoForm.get(this.selectedConso[i].request).value;
            this.selectedConso[i].conso = conso ? conso : 0;
        } else {
            this.selectedConso[i].conso = 0;
        }
        if (this.getConsoPrice() === 0) {
            this.validSelectedConso = false;
        }
    }

    getConsoPrice(): number {
        let total = 0;
        if (
            this.selectedPlan &&
            this.planPrices &&
            this.planPrices.plans &&
            this.planPrices.plans[this.selectedPlan] &&
            this.choosePlanForm.get('planType').value === 'pre-payment' &&
            this.planPrices.plans[this.selectedPlan]['pre-payment'] &&
            this.planPrices.plans[this.selectedPlan]['pre-payment'].withRequests
        ) {
            this.selectedConso.forEach(conso => {
                if (conso.conso > 0) {
                    const tierAmounts = this.planPrices.requests[conso.request].tiers.map(tier => tier.up_to).filter(tier => tier !== null);
                    tierAmounts.sort((a, b) => {
                        return a - b;
                    });

                    let i = 0;
                    while (i < tierAmounts.length && tierAmounts[i] < conso.conso) {
                        i += 1;
                    }
                    if (i < tierAmounts.length) {
                        total += conso.conso * this.planPrices.requests[conso.request].tiers.find(t => t.up_to === tierAmounts[i]).unit_amount;
                    } else {
                        this.planPrices.requests[conso.request].tiers.find(t => t.up_to === null);
                        total += conso.conso * this.planPrices.requests[conso.request].tiers.find(t => t.up_to === null).unit_amount;
                    }
                }
            });
        }

        return parseFloat((Math.ceil(total) / 100).toFixed(2));
    }

    getPrePaymentRequests(): {[key: string]: number} {
        let pre_payment_requests: {[key: string]: number};
        if (
            this.validSelectedConso &&
            this.choosePlanForm.value.planType === 'pre-payment' &&
            this.planPrices.plans[this.selectedPlan] &&
            this.planPrices.plans[this.selectedPlan]['pre-payment'].withRequests
        ) {
            pre_payment_requests = {};
            this.selectedConso.forEach(conso => {
                if (! this.requestIsCoupledWithOtherRequests(conso.request)) {
                    pre_payment_requests[conso.request] = conso.conso;
                    if (this.requestIsCoupledWithOtherRequests(conso.request, true)) {
                        const coupledConsumptionsItem = this.coupledConsumptions.find(cons => cons.includes(conso.request));
                        for (let i = 1; i < coupledConsumptionsItem.length; i++) {
                            pre_payment_requests[coupledConsumptionsItem[i]] = conso.conso;
                        }
                    }
                }
            });
        }
        return pre_payment_requests;
    }

    checkIncompatibilitiesAfterChange() {
        if (
            (
                this.selectedPlan &&
                this.selectedPlan !== PLAN_V2_ENUM.SAAS &&
                this.planPrices.plans[this.selectedPlan] &&
                this.planPrices.plans[this.selectedPlan][this.choosePlanForm.get('planType').value]
            )
            ||
            (
                this.selectedPlan &&
                this.selectedPlan === PLAN_V2_ENUM.SAAS
            )
        ) {
            if (
                this.choosePlanForm.get('planType').value !== 'pre-payment' ||
                ! this.planPrices ||
                ! this.planPrices.plans ||
                ! this.planPrices.plans[this.selectedPlan] ||
                ! this.planPrices.plans[this.selectedPlan]['pre-payment'] ||
                ! this.planPrices.plans[this.selectedPlan]['pre-payment'].withRequests
            ) {
                this.validSelectedConso = false;
            }
        } else {
            this.initializeSelectedPlan();
            this.validSelectedConso = false;
        }
    }

    initializeSelectedPlan() {
        this.selectedPlan = PLAN_V2_ENUM['E-COMMERCE'];
    }

    setupOnChanges() {
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            this.choosePlanForm.get('planPeriod').valueChanges.subscribe(() => {
                this.findPlanPrices();
                // this.checkIncompatibilitiesAfterChange();
            })
        );
        this.subscriptions.add(
            this.choosePlanForm.get('currency').valueChanges.subscribe(() => {
                this.findPlanPrices();
            })
        );
        /*if (this.user.isTrial()) {
            this.subscriptions.add(
                this.trialCheckout.get('currency').valueChanges.subscribe(() => {
                    this.findPlanPrices();
                })
            );
        }*/
        this.subscriptions.add(
            this.choosePlanForm.get('planType').valueChanges.subscribe(() => {
                this.checkIncompatibilitiesAfterChange();
            })
        );
    }

    get selectedPeriod(): string {
        return this.choosePlanForm.get('planPeriod').value;
    }

    consoIsNotValidable(checkConsoPrice = true): boolean {
        return !this.selectedPlan ||
            !this.planPrices &&
            !this.planPrices.plans &&
            !Object.keys(PLAN_V2_ENUM).includes(this.selectedPlan) ||
            !this.planPrices.plans[this.selectedPlan] ||
            !this.planPrices.plans[this.selectedPlan][this.choosePlanForm.value.planType] ||
            !this.planPrices.plans[this.selectedPlan][this.choosePlanForm.value.planType].withRequests ||
            (checkConsoPrice ? this.getConsoPrice() === 0 : false);
    }

    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    onOpenEcommerceGuide() {
        this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.ECOMMERCE}`]);
        /*this.urlToEmbed = 'https://transiteo.gitbook.io/cms-plugins-guide/';
        this.onSwitchDisplayEmbedWebsite();*/
    }
    onOpenApiGuide() {
        this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.API}`]);
        /*this.urlToEmbed = 'https://transiteo.gitbook.io/transiteo/';
        this.onSwitchDisplayEmbedWebsite();*/
    }
    onOpenSaasGuide() {
        this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.SAAS}`]);
        /*this.urlToEmbed = 'https://transiteo.gitbook.io/web-application-guide/';
        this.onSwitchDisplayEmbedWebsite();*/
    }
    /*onSwitchDisplayEmbedWebsite() {
        this.displayEmbededWebsite = ! this.displayEmbededWebsite;
    }*/

}
