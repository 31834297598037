import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {MessageService} from '../../../components/message/message.service';
import {UserService} from '../../../services/user.service';
import {ToolsService, Translate} from '../../../services/tools.service';

@Component({
    selector: 'app-translator',
    templateUrl: './translator.component.html',
    styleUrls: ['./translator.component.scss']
})
export class TranslatorComponent implements OnInit {
    languages: Array<{label: string, value: string, iso2: string}>;

    translateForm: FormGroup;
    inputText: string;
    source: string;
    outputText: string;
    target: string;

    @Input() additionalClasses: string;

    constructor(private _notification: MessageService,
        private userService: UserService,
        private translate: TranslateService,
        private toolsService: ToolsService) {
        this.source = 'fr';
        this.target = 'en';
        this.translateForm = new FormGroup({
            inputSource: new FormControl(this.inputText, [Validators.required]),
            selectSource: new FormControl(this.source, [Validators.required]),
            inputTarget: new FormControl({value: this.outputText, disabled: true}, []),
            selectTarget: new FormControl(this.target, [Validators.required])
        });
    }

    ngOnInit() {
        this.initTranslation();
    }

    async initTranslation() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.languages = event.translations['toolBox'].translator.languages;
        });
        await this.translate.get('toolBox').toPromise().then(toolbox => {
            this.languages = toolbox.translator.languages;
        });
    }

    switch() {
        const target = this.target;
        const source = this.source;
        this.source = target;
        this.target = source;
    }

    async submitTranslate() {
        if (this.translateForm.valid) {
            const formValue = this.translateForm.value;
            const body: Translate = {
                source: formValue.selectSource,
                target: formValue.selectTarget,
                inputText: formValue.inputSource
            };
            const responseTranslate = await this.callTranslateAPI(body);
            this.outputText = responseTranslate.outputText;
        }
    }

    async callTranslateAPI(body: Translate) {
        return this.toolsService.translate(body)
            .then(data => data)
            .catch((response) => {
                return Promise.reject();
            });
    }

}
