import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {pgTabComponent} from '../../components/tabs/tab.component';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../auth/auth.service';
import {MessageService} from '../../components/message/message.service';
import {UserService} from '../../services/user.service';
import {CsvService} from '../../services/csv.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {User} from '../../../models/User';
import {InternationalShipmentRequest, InternationalShipmentResponse} from '../../../models/InternationalShipment';
import * as moment from 'moment';

@Component({
  selector: 'app-shipment',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent implements OnInit {
  @ViewChild(pgTabComponent, {static: false}) child: pgTabComponent;
  STEP_ENUM = {
    INFO: 1,
    SEARCH: 2,
    SENDER: 3,
    RECEIVER: 4,
    RESULTS: 5
  };
  step: number;
  shipmentUrl: string;
  infoForm: FormGroup;
  senderForm: FormGroup;
  receiverForm: FormGroup;
  preferenceForm: FormGroup;
  internationalShipmentResponse: InternationalShipmentResponse | string;
  internationalShipmentRequest: InternationalShipmentRequest;
  user: User;
  unknown_error: string;
  lang: string;
  prefered_currency: string;
  constructor(private authService: AuthService,
              private http: HttpClient,
              private _notification: MessageService,
              private userService: UserService,
              private csvService: CsvService,
              private translate: TranslateService) {
    this.step = this.STEP_ENUM.INFO;
    this.shipmentUrl = `https://api.${environment.baseUrl}/v1/shipsrv/rates/international`;
  }

  async ngOnInit() {
    await this.initUserPrefs();
    await this.translate.get('messages').toPromise().then(messages => {
      this.updateLang(messages);
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.updateLang(messages);
    });
  }
  private updateLang(messages) {
    this.unknown_error = messages['notification']['unknown_error'];
    this.lang = this.user.lang ? this.user.lang.substr(0, 2) : messages.current_lang;
    this.prefered_currency = this.user.currency ? this.user.currency.toUpperCase() : messages.default_currency;
  }
  private async initUserPrefs() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
  }

  isRightStep(step: number) {
    return step <= this.step;
  }
  displaySenderForm(infoForm: FormGroup) {
    this.infoForm = infoForm;
    this.step = this.STEP_ENUM.SENDER;
    this.child.setLabel(1);
  }
  displayReceiverForm(senderForm: FormGroup) {
    this.senderForm = senderForm;
    this.step = this.STEP_ENUM.RECEIVER;
    this.child.setLabel(2);
  }

  async displayResultForm(receiverForm: FormGroup) {
    this.receiverForm = receiverForm;
    await this.submitForm(null);
    this.step = this.STEP_ENUM.RESULTS;
    this.child.setLabel(3);
  }

  getSender(): {} {
    return this.infoForm ? this.infoForm.get('sender').value : null;
  }
  getReceiver() {
    return this.infoForm ? this.infoForm.get('receiver').value : false;
  }

  async submitForm(preferenceForm: FormGroup) {
    this.preferenceForm = preferenceForm;
    const result = await this.callShipmentApi();
    if (result.code === 200) {
      this.internationalShipmentResponse = result.response;
    } else {
      this.displayMessage(result.response as string, 'danger');
    }
  }

  displayMessage(message: string, type: string) {
    if (this._notification && typeof this._notification.create === 'function') {
      this._notification.create(
          type,
          message,
          {
            Position: 'top',
            Style: 'bar',
            Duration: 10000
          }
      );
    }
  }

  async callShipmentApi(): Promise<{code: number, request: {}, response: InternationalShipmentResponse|string }> {
    const infoParams = this.infoForm.value;
    // const prefParams = this.preferenceForm.value;
    const senderParams =  this.senderForm.value;
    const receiverParams = this.receiverForm.value;
    this.internationalShipmentRequest = {
      transports: infoParams.transports,
      lang: this.lang,
      currency: this.prefered_currency,
      sender: senderParams,
      receiver: receiverParams,
      transit: infoParams.transit,
      shipment_option: infoParams.shipment_option,
      delivery_option: infoParams.delivery_option,
      departure_date: moment(infoParams.departure_date).format('YYYY-MM-DD').toString()
      // delivery_option: infoParams.delivery_option
    } as InternationalShipmentRequest;
    console.log(JSON.stringify(this.internationalShipmentRequest));
    return this.http.post(this.shipmentUrl, this.internationalShipmentRequest).toPromise().then((response: InternationalShipmentResponse) => {
      return {code: 200, request : this.internationalShipmentRequest, response: response};
    }).catch( errorResponse => {
      const transiteoErrorMessage = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
      return {code: errorResponse.status, request : this.internationalShipmentRequest, response: transiteoErrorMessage};
    });
  }

}
export enum TransportEnum {
  PARCEL = 'PARCEL',
  PALLET = 'PALLET',
  CONTAINER = 'CONTAINER',
}
