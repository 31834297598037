import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SharedData} from '../SharedData';
import {MessageService} from '../../components/message/message.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'blank-layouts',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlankComponent implements OnInit {
  successMessage: string;
  errorMessage: string;
  @ViewChild('root', { static: false }) root;
  constructor(private data: SharedData,
              private _notification: MessageService,
              private translate: TranslateService) {
  }

  async ngOnInit() {
      if (this.data.errorMessage) {
          this._notification.displayMessage(this.data.errorMessage, 'danger');
      }
      if (this.data.successMessage) {
          this._notification.displayMessage(this.data.successMessage, 'success');
      }
      this.data.errorMessage = '';
      this.data.successMessage = '';
      const userLang = navigator ? navigator.language.substr(0, 2) : 'en';
      await this.initTranslation(userLang);
  }
  async initTranslation(lang: string) {
      console.log(`Init ${lang} translation`);
      if (lang !== this.translate.defaultLang) {
        if (['en','fr','es'].includes(lang)) {
          this.translate.use(lang);
        }
      }
  }

}
