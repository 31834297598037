import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WEB_ORIGIN} from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class OriginInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const interceptedRequest = req.clone( {
      headers: req.headers.append('origin-app', WEB_ORIGIN)
    });
    return  next.handle(interceptedRequest);
  }

}
