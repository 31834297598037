import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { StepTaxmanagerForm } from '../../../../../../models/Register';
import { environment } from '../../../../../../../environments/environment';
import { MessageService } from '../../../../../components/message/message.service';
import { TranslateService } from '@ngx-translate/core';

const countriesPerContinent = require('../../../../../../../assets/data/countriesPerContinent.json');

@Component({
    selector: 'app-register-step-taxmanager',
    templateUrl: './step_taxmanager.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepTaxmanagerComponent implements OnInit {
    @Input()
    formContent: StepTaxmanagerForm;
    @Input()
    countries: { value: string, label: string, iso2: string }[] = [];
    @Input()
    availableCountries: string[] = [];
    @Output()
    nextStepCallback: EventEmitter<StepTaxmanagerForm> = new EventEmitter<StepTaxmanagerForm>();
    @Output()
    modalIsOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('ue', { static: false }) ue: TemplateRef<any>;
    @ViewChild('gbr', { static: false }) gbr: TemplateRef<any>;
    @ViewChild('nor', { static: false }) nor: TemplateRef<any>;
    @ViewChild('nzl', { static: false }) nzl: TemplateRef<any>;
    @ViewChild('aus', { static: false }) aus: TemplateRef<any>;

    places: string[] = [];
    placesDone: string[] = [];

    formContentReturn: StepTaxmanagerForm;

    selectPlaceForm: FormGroup;
    formIsInvalid = false;
    modalForm: FormGroup;
    modalRef: BsModalRef = null;

    ukRules: boolean;
    selectableUeCountries: { value: string, label: string, iso2: string }[] = [];
    selectedType: string;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        // prepare available places
        if (countriesPerContinent.EU.filter(c => c !== 'NOR' && c !== 'GBR' && this.availableCountries.includes(c)).length > 0) {
            this.places.push('UE');
        }
        if (this.availableCountries.includes('NOR')) {
            this.places.push('NOR');
        }
        if (this.availableCountries.includes('GBR')) {
            this.places.push('GBR');
        }
        if (this.availableCountries.includes('NZL')) {
            this.places.push('NZL');
        }
        if (this.availableCountries.includes('AUS')) {
            this.places.push('AUS');
        }

        this.formContentReturn = {...this.formContent};
        const countriesDone = Object.keys(this.formContentReturn.countries);
        this.placesDone = countriesDone.filter(c => this.places.includes(c));
        if (countriesDone.length > this.placesDone.length) {
            this.placesDone.push('UE');
        }

        // prepare selectable EU countries
        this.selectableUeCountries = this.countries.filter(c => {
            return c.value !== 'NOR' && c.value !== 'GBR' && countriesPerContinent.EU.includes(c.value);
        });

        this.selectPlaceForm = new FormGroup({
            place: new FormControl('', [Validators.required])
        });
    }

    getRemainingPlaces(): string[] {
        return this.places.filter(p => !this.placesDone.includes(p));
    }

    onValidPlace(): void {
        if (this.selectPlaceForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        switch (this.selectPlaceForm.value.place) {
            case 'UE':
                this.openModal(this.ue, 'UE');
                break;
            case 'GBR':
                this.openModal(this.gbr, 'GBR');
                break;
            case 'NOR':
                this.openModal(this.nor, 'NOR');
                break;
            case 'NZL':
                this.openModal(this.nzl, 'NZL');
                break;
            case 'AUS':
                this.openModal(this.aus, 'AUS');
                break;
            default:
                break;
        }
    }

    initModalForm(place: string): void {
        this.ukRules = null;
        this.modalForm = new FormGroup({
            country: new FormControl('', [Validators.required]),
            vat_id: new FormControl('', [Validators.required]),
            eori: new FormControl('', [])
        });

        if (place === 'UE') {
            this.modalForm.get('eori').setValidators([Validators.required]);
        } else {
            this.modalForm.get('country').setValue(place);
        }
    }

    openModal(template: TemplateRef<any>, type: string) {
        this.formIsInvalid = false;
        this.selectedType = type;
        this.initModalForm(type);
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        this.modalRef = this.modalService.show(template, config);
        this.modalIsOpened.emit(true);
    }

    closeModal() {
        this.formIsInvalid = false;
        this.selectPlaceForm.get('place').reset();
        this.selectedType = null;
        this.ukRules = null;
        this.modalIsOpened.emit(false);
        if (this.modalRef) {
            this.modalRef.hide();
            this.modalRef = null;
        }
    }

    async onValidModalContent() {
        if (this.modalForm.invalid && (this.selectedType !== 'GBR' || (this.selectedType === 'GBR' && (this.ukRules === null || this.ukRules === true)))) {
            this.formIsInvalid = true;
            return;
        }
        if (this.selectedType === 'GBR' && !this.ukRules) {
            this.formContentReturn.countries['GBR'] = {tax_id: '', eori: '', uk_rules: true};
            this.placesDone.push(this.selectedType);
        } else {
            const formRes = this.modalForm.value;
            if (this.selectedType === 'GBR') {
                if (this.ukRules === true) {
                    this.formContentReturn.countries['GBR'] = {tax_id: formRes.vat_id, eori: '', uk_rules: true};
                    this.placesDone.push(this.selectedType);
                }
            } else {
                const ueCountry = this.selectableUeCountries.find(country => country.value === formRes.country);
                if (ueCountry) {
                    if (await this.checkTaxId(ueCountry.iso2, formRes.vat_id)) {
                        this.formContentReturn.countries[formRes.country] = {tax_id: formRes.vat_id, eori: formRes.eori};
                        this.placesDone.push(this.selectedType);
                    }
                } else {
                    this.formContentReturn.countries[formRes.country] = {tax_id: formRes.vat_id, eori: formRes.eori};
                    this.placesDone.push(this.selectedType);
                }
            }
        }
        this.closeModal();
    }

    onRemovePlace(place: string): void {
        let country = place;
        if (country === 'UE') {
            country = Object.keys(this.formContentReturn.countries).find(c => !['GBR', 'NOR', 'AUS', 'NZL'].includes(c));
        }
        delete this.formContentReturn.countries[country];
        this.placesDone.splice(this.placesDone.findIndex(p => p === place), 1);
    }

    goToPublicDocumentation() {
        window.open('https://www.transiteo.com', '_blank');
    }

    // verify that the entered taxId is a right taxId for the specified origin_country
    async checkTaxId(country_of_origin: string, vat_id: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/verifyVatId`, {country_of_origin, vat_id}).toPromise()
            .then(async (response: any) => {
                if (! response.valid) {
                    this._notification.displayMessage(await this.translate.get('account.wrong_tax_id').toPromise(), 'warning');
                }
                resolve(response.valid);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }

    nextStep() {
        this.nextStepCallback.emit(this.formContentReturn);
    }
}
