import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { InternationalCheckoutAccountInfo, InternationalCheckoutSettings, StripeConnectedAccountInfo, StripeLink } from '../../../models/Managers';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-payment-manager',
    templateUrl: './payment-manager.component.html',
    styleUrls: ['./payment-manager.component.scss'],
})
export class PaymentManagerComponent implements OnInit {
    infoForm: FormGroup;
    formIsInvalid = false;
    internationalCheckoutSettings: InternationalCheckoutSettings;
    unknown_error: string;
    accountCreatedModifiedMessage: string;
    settingsModifiedMessage: string;
    color: string;
    firstCallAccountInfoDone = false;

    @ViewChild('pop_storeID', {static: false}) pop_storeID;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private route: ActivatedRoute,
        private translate: TranslateService,
    ) {}

    async ngOnInit() {
        this.initTranslations();
        this.route.queryParams.subscribe(async params => {
            const checkInfoFromStripe = params['accountFilled'];
            if (checkInfoFromStripe) {
                await this.getAccountInfo(true);
            } else {
                await this.getAccountInfo(false);
            }
            this.firstCallAccountInfoDone = true;
            this.initForm();
        });
    }

    initForm() {
        this.infoForm = new FormGroup({
            logo_url: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.logo_url
                ? this.internationalCheckoutSettings.international_checkout_account_info.logo_url
                : '', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]
            ),
            redirection_url: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.redirection_url
                ? this.internationalCheckoutSettings.international_checkout_account_info.redirection_url
                : '', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]
            ),
            color_theme: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.color_theme
                ? this.internationalCheckoutSettings.international_checkout_account_info.color_theme
                : '#000000', [Validators.required, Validators.pattern('^\#[0-9a-fA-F]{6}$')]
            ),
            api_key_fedex: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.api_key_fedex
                ? this.internationalCheckoutSettings.international_checkout_account_info.api_key_fedex
                : '', []
            ),
            api_key_dhl: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.api_key_dhl
                ? this.internationalCheckoutSettings.international_checkout_account_info.api_key_dhl
                : '', []
            ),
            api_key_ups: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.api_key_ups
                ? this.internationalCheckoutSettings.international_checkout_account_info.api_key_ups
                : '', []
            ),
            api_key_chronopost: new FormControl(
                this.internationalCheckoutSettings && this.internationalCheckoutSettings.international_checkout_account_info.api_key_chronopost
                ? this.internationalCheckoutSettings.international_checkout_account_info.api_key_chronopost
                : '', []
            )
        });
        this.color = this.infoForm.get('color_theme').value;
    }

    initTranslations() {
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            this.accountCreatedModifiedMessage = messages.notification.account_created_message;
            this.settingsModifiedMessage = messages.notification.account_settings_modified_message;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.accountCreatedModifiedMessage = messages.notification.account_created_message;
            this.settingsModifiedMessage = messages.notification.account_settings_modified_message;
        });
    }

    onValidForm() {
        if (this.infoForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formContent = this.infoForm.value as InternationalCheckoutAccountInfo;
        formContent.color_theme = this.color;
        this.putInternationalCheckoutAccountInfo(formContent);
    }

    async copyToClipboard(id) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
          e.clipboardData.setData('text/plain', (id));
          e.preventDefault();
          document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.pop_storeID.show();
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.pop_storeID.hide();
    }

    async putInternationalCheckoutAccountInfo(internationalCheckoutAccountInfo: InternationalCheckoutAccountInfo) {
        try {
            await this.http.put(
                `https://api.${environment.baseUrl}/v1/customer/payments/accountinfo`,
                internationalCheckoutAccountInfo
            ).toPromise();

            this._notification.displayMessage(this.settingsModifiedMessage, 'success');
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }

    async getAccountInfo(checkAccount: boolean) {
        try {
            this.internationalCheckoutSettings = (
                <InternationalCheckoutSettings> await this.http.get(
                    `https://api.${environment.baseUrl}/v1/customer/payments/accountinfo?checkAccount=${checkAccount.toString()}`
                ).toPromise()
            );
            if (checkAccount) {
                this._notification.displayMessage(this.accountCreatedModifiedMessage, 'success');
            }
            console.log(this.internationalCheckoutSettings);
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }

    async onOpenOnboardingFlow() {
        try {
            const onboardingLink = (<StripeLink> await this.http.get(`https://api.${environment.baseUrl}/v1/customer/payments/link`).toPromise());
            if (onboardingLink && onboardingLink.link) {
                document.location.replace(onboardingLink.link);
            }
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }

    async onOpenConnectedAccountDashboard() {
        try {
            const onboardingLink = (<StripeLink> await this.http.get(`https://api.${environment.baseUrl}/v1/customer/payments/dashboardlink`).toPromise());
            if (onboardingLink && onboardingLink.link) {
                window.open(onboardingLink.link, '_blank');
            }
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
    }
}
