import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { environment } from '../../../environments/environment';

interface RetrievePdfContent {
    content: {
        type: string;
        data: number[];
    };
}

@Injectable({
    providedIn: 'root'
})
export class ZipService {

    constructor (
        private http: HttpClient
    ) { }

    public async downloadFile(urls: string[], filenames: string[], zipFilename: string) {
        const zip = new JSZip();
        let currentIndex = 0;
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            const filename = filenames[i];
            // loading a file and add it in a zip file
            try {
                //const data = await JSZipUtils.getBinaryContent(url);
                const data2 = (
                    await (this.http.get(`https://api.${environment.baseUrl}/v1/pdf/retrieve?url=${encodeURIComponent(url)}`).toPromise())
                ) as RetrievePdfContent;
                const arraybuffer = new ArrayBuffer(data2.content.data.length);
                const viewBuffer = new Uint8Array(arraybuffer);
                for (let j = 0; j < data2.content.data.length; j++) {
                    viewBuffer[j] = data2.content.data[j];
                }
                zip.file(filename, arraybuffer, { binary: true });
            } catch (err) {
                console.log('zip service downloadFile ERROR : ');
                console.log(err);
            }
            currentIndex += 1;
            if (currentIndex === urls.length) {
                const zipFile = await zip.generateAsync({ type: 'blob' });
                saveAs(zipFile, zipFilename);
            }
        }
    }
}
