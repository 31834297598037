import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Country } from '../interfaces/country';
import { SelectedCountry } from '../interfaces/selected-country';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EChartOption, graphic } from 'echarts';
import { CountryHelper } from '../country-helper';
import { isNumber, isString } from 'util';

@Component({
    selector: 'app-card-country',
    templateUrl: './card-country.component.html',
    styleUrls: ['./card-country.component.scss'],
})
export class CardCountryComponent implements OnInit {
    chartOptionsLogistic: EChartOption;
    chartOptionsInternetOnceByDayUsersPart: EChartOption;
    chartOptionsVideoStreamingOnceByDayUsersPart: EChartOption;
    chartOptionsInternerUserPart: EChartOption;
    chartOptionsWebTraffic: EChartOption;
    chartOptionsEshopper: EChartOption;
    chartOptionsBankAccount: EChartOption;
    chartOptionsDebitCard: EChartOption;
    chartOptionsDigitalPayment: EChartOption;
    chartOptionsMarketValue: EChartOption;
    chartOptionsMarketGrowth: EChartOption;

    labelComputer: string;
    labelMobile: string;
    labelOther: string;
    labelPopulation: string;
    labelPopulationBankAccount: string;
    labelPopulationDebitCard: string;
    labelSend: string;
    labelReceive: string;
    labelUserDaily: string;
    labelUserActive: string;
    labelUserPayment: string;
    labelGrowth: string;
    labelFashionBeauty: string;
    labelHighTech: string;
    labelFood: string;
    labelHome: string;
    labelToys: string;
    labelTravel: string;
    labelMusic: string;
    labelVideoGames: string;
    labelNoData: string;
    labelInternetUserPart: string;

    private _categories: Array<string>;
    get categories(): Array<string> {
        return this._categories;
    }
    @Input() set categories(value: Array<string>) {
        this._categories = value;
    }

    private _country: Country;
    get country(): Country {
        return this._country;
    }
    @Input() set country(value: Country) {
        this._country = value;
        this.initsCharts();
    }

    private _selectedCountry: SelectedCountry;
    get selectedCountry(): SelectedCountry {
        return this._selectedCountry;
    }
    @Input() set selectedCountry(value: SelectedCountry) {
        this._selectedCountry = value;
    }

    constructor(private translate: TranslateService) {
        this.translate.get('cardCountries').subscribe((cardCountries) => {
            this.labelComputer = cardCountries.labelComputer;
            this.labelMobile = cardCountries.labelMobile;
            this.labelOther = cardCountries.labelOther;
            this.labelPopulation = cardCountries.labelPopulation;
            this.labelSend = cardCountries.labelSend;
            this.labelReceive = cardCountries.labelReceive;
            this.labelUserDaily = cardCountries.labelUserDaily;
            this.labelUserActive = cardCountries.labelUserActive;
            this.labelUserPayment = cardCountries.labelUserPayment;
            this.labelGrowth = cardCountries.growth;
            this.labelFashionBeauty = cardCountries.labelFashionBeauty;
            this.labelHighTech = cardCountries.labelHighTech;
            this.labelFood = cardCountries.labelFood;
            this.labelHome = cardCountries.labelHome;
            this.labelToys = cardCountries.labelToys;
            this.labelTravel = cardCountries.labelTravel;
            this.labelMusic = cardCountries.labelMusic;
            this.labelVideoGames = cardCountries.labelVideoGames;
            this.labelPopulationBankAccount = cardCountries.labelPopulationBankAccount;
            this.labelPopulationDebitCard = cardCountries.labelPopulationDebitCard;
            this.labelNoData = cardCountries.noData;
            this.labelInternetUserPart = cardCountries.labelInternetUserPart;
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.labelComputer = event.translations['cardCountries'].labelComputer;
            this.labelMobile = event.translations['cardCountries'].labelMobile;
            this.labelOther = event.translations['cardCountries'].labelOther;
            this.labelPopulation = event.translations['cardCountries'].labelPopulation;
            this.labelPopulationBankAccount = event.translations['cardCountries'].labelPopulationBankAccount;
            this.labelPopulationDebitCard = event.translations['cardCountries'].labelPopulationDebitCard;
            this.labelSend = event.translations['cardCountries'].labelSend;
            this.labelReceive = event.translations['cardCountries'].labelReceive;
            this.labelUserDaily = event.translations['cardCountries'].labelUserDaily;
            this.labelUserActive = event.translations['cardCountries'].labelUserActive;
            this.labelUserPayment = event.translations['cardCountries'].labelUserPayment;
            this.labelGrowth = event.translations['cardCountries'].growth;
            this.labelFashionBeauty = event.translations['cardCountries'].labelFashionBeauty;
            this.labelHighTech = event.translations['cardCountries'].labelHighTech;
            this.labelFood = event.translations['cardCountries'].labelFood;
            this.labelHome = event.translations['cardCountries'].labelHome;
            this.labelToys = event.translations['cardCountries'].labelToys;
            this.labelTravel = event.translations['cardCountries'].labelTravel;
            this.labelMusic = event.translations['cardCountries'].labelMusic;
            this.labelVideoGames = event.translations['cardCountries'].labelVideoGames;
            this.labelNoData = event.translations['cardCountries'].noData;
            this.labelInternetUserPart = event.translations['cardCountries'].labelInternetUserPart;
            this.initsCharts();
        });
    }

    ngOnInit() {}

    initsCharts() {
        this.chartOptionsLogistic = this.initChartBar(
            [this.labelSend, this.labelReceive],
            [this.country.logistic.parcelExportNumber, this.country.logistic.parcelImportNumber],
            [],
            '{b} : {c}',
            true,
        );
        if (this.country.internet.internetUserPart !== null) {
            let internetUserPart = this.removeCharToCreateNumber(this.country.internet.internetUserPart, '%');
            this.chartOptionsInternerUserPart = this.initChartPie([
                {
                    value: internetUserPart,
                    name: this.labelInternetUserPart,
                },
                {
                    value: 100 - internetUserPart,
                    name: this.labelPopulation,
                },
            ]);
        }
        if (this.country.internet.internetOnceByDayUsersPart !== null) {
            let internetOnceByDayUsersPart = this.removeCharToCreateNumber(this.country.internet.internetOnceByDayUsersPart, '%');
            this.chartOptionsInternetOnceByDayUsersPart = this.initChartPie([
                {
                    value: internetOnceByDayUsersPart,
                    name: this.labelUserDaily,
                },
                {
                    value: 100 - internetOnceByDayUsersPart,
                    name: this.labelPopulation,
                },
            ]);
        }
        if (this.country.internet.videoStreamingOnceByDayUsersPart !== null) {
            let videoStreamingOnceByDayUsersPart = this.removeCharToCreateNumber(this.country.internet.videoStreamingOnceByDayUsersPart, '%');
            this.chartOptionsVideoStreamingOnceByDayUsersPart = this.initChartPie([
                {
                    value: videoStreamingOnceByDayUsersPart,
                    name: this.labelUserDaily,
                },
                {
                    value: 100 - videoStreamingOnceByDayUsersPart,
                    name: this.labelPopulation,
                },
            ]);
        }
        if (this.country.internet.webTraficPartForComputer !== null && this.country.internet.webTraficPartForMobilePhone) {
            let webTraficPartForComputer = this.removeCharToCreateNumber(this.country.internet.webTraficPartForComputer, '%');
            let webTraficPartForMobilePhone = this.removeCharToCreateNumber(this.country.internet.webTraficPartForMobilePhone, '%');
            let data = [
                { value: webTraficPartForComputer, name: this.labelComputer },
                {
                    value: webTraficPartForMobilePhone,
                    name: this.labelMobile,
                },
            ];
            if (webTraficPartForComputer + webTraficPartForMobilePhone < 100) {
                data.push({
                    value: 100 - webTraficPartForComputer - webTraficPartForMobilePhone,
                    name: this.labelOther,
                });
            }
            this.chartOptionsWebTraffic = this.initChartPie(data);
        }
        if (this.country.market.eshopperPart !== null) {
            let eshopperPart = this.removeCharToCreateNumber(this.country.market.eshopperPart, '%');
            this.chartOptionsEshopper = this.initChartPie([
                { value: eshopperPart, name: this.labelUserActive },
                { value: 100 - eshopperPart, name: this.labelPopulation },
            ]);
        }
        if (this.country.market.bankAccountPartInPopulation !== null) {
            let bankAccountPartInPopulation = this.removeCharToCreateNumber(this.country.market.bankAccountPartInPopulation, '%');
            this.chartOptionsBankAccount = this.initChartPie([
                {
                    value: bankAccountPartInPopulation,
                    name: this.labelPopulationBankAccount,
                },
                {
                    value: 100 - bankAccountPartInPopulation,
                    name: this.labelPopulation,
                },
            ]);
        }
        if (this.country.market.debitCardPartInPopulation !== null) {
            let debitCardPartInPopulation = this.removeCharToCreateNumber(this.country.market.debitCardPartInPopulation, '%');
            this.chartOptionsDebitCard = this.initChartPie([
                {
                    value: debitCardPartInPopulation,
                    name: this.labelPopulationDebitCard,
                },
                {
                    value: 100 - debitCardPartInPopulation,
                    name: this.labelPopulation,
                },
            ]);
        }
        if (this.country.market.digitalPaymentPopulationPart !== null) {
            let digitalPaymentPopulationPart = this.removeCharToCreateNumber(this.country.market.digitalPaymentPopulationPart, '%');
            this.chartOptionsDigitalPayment = this.initChartPie([
                {
                    value: digitalPaymentPopulationPart,
                    name: this.labelUserPayment,
                },
                {
                    value: 100 - digitalPaymentPopulationPart,
                    name: this.labelPopulation,
                },
            ]);
        }

        this.chartOptionsMarketValue = this.initChartBar(
            [
                this.labelFashionBeauty,
                this.labelHighTech,
                this.labelFood,
                this.labelHome,
                this.labelToys,
                this.labelTravel,
                this.labelMusic,
                this.labelVideoGames,
            ],
            [
                this.country.market.fashionBeautyEshopsRevenue,
                this.country.market.highTechEshopsRevenue,
                this.country.market.foodEshopsRevenue,
                this.country.market.homeEshopsRevenue,
                this.country.market.toysEshopsRevenue,
                this.country.market.travelEshopsRevenue,
                this.country.market.musicEshopsRevenue,
                this.country.market.videoGamesEshopsRevenue,
            ],
            [],
            '{b} : {c}',
            false,
        );
        this.chartOptionsMarketGrowth = this.initChartBar(
            [
                this.labelFashionBeauty,
                this.labelHighTech,
                this.labelFood,
                this.labelHome,
                this.labelToys,
                this.labelTravel,
                this.labelMusic,
                this.labelVideoGames,
            ],
            [
                this.removeCharToCreateNumber(this.country.market.fashionBeautyEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.highTechEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.foodEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.homeEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.toysEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.travelEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.musicEshopsGrowth, '%'),
                this.removeCharToCreateNumber(this.country.market.videoGamesEshopsGrowth, '%'),
            ],
            [],
            '{b} : {c}%',
            false,
        );
    }

    initChartPie(data: Array<any>): EChartOption {
        return {
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {d}%',
            },
            visualMap: [
                {
                    show: false,
                    pieces: [
                        {
                            gte: 66,
                            color: '#6b58a8',
                        },
                        {
                            lt: 66,
                            gte: 33,
                            color: '#8a78c1',
                        },
                        {
                            lt: 33,
                            gte: 0,
                            color: '#ac98eb',
                        },
                    ],
                },
            ],
            series: [
                {
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '50%'],
                    data: data.sort((a, b) => a.value - b.value),
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(0, 0, 0, 0.5)',
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: '#8a78c1',
                            shadowBlur: 20,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: () => Math.random() * 200,
                },
            ],
        };
    }

    initChartBar(dataAxis: Array<any>, data: Array<any>, dataShadow: Array<any>, formatter: string, showAxisXLabel: boolean): EChartOption {
        return {
            tooltip: {
                trigger: 'axis',
                formatter: formatter,
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                data: dataAxis,
                axisLabel: {
                    inside: false,
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                show: showAxisXLabel,
                z: 10,
            },
            yAxis: {
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    textStyle: {
                        color: '#999',
                    },
                },
            },
            series: [
                {
                    name: this.labelGrowth,
                    type: 'bar',
                    itemStyle: {
                        normal: { color: 'rgba(0,0,0,0.05)' },
                    },
                    barGap: '-100%',
                    barCategoryGap: '40%',
                    animation: false,
                    data: dataShadow,
                },
                {
                    type: 'bar',
                    itemStyle: {
                        normal: {
                            color: new graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#af9aed' },
                                { offset: 0.5, color: '#8a78c1' },
                                { offset: 1, color: '#8a78c1' },
                            ]),
                        },
                        emphasis: {
                            color: new graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#8a78c1' },
                                { offset: 0.7, color: '#8a78c1' },
                                { offset: 1, color: '#af9aed' },
                            ]),
                        },
                    },
                    data,
                },
            ],
        };
    }

    showMarketValueChart(): boolean {
        return (
            this.country.market.fashionBeautyEshopsRevenue !== null &&
            this.country.market.foodEshopsRevenue !== null &&
            this.country.market.highTechEshopsRevenue !== null &&
            this.country.market.homeEshopsRevenue !== null &&
            this.country.market.musicEshopsRevenue !== null &&
            this.country.market.toysEshopsRevenue !== null &&
            this.country.market.travelEshopsRevenue !== null &&
            this.country.market.videoGamesEshopsRevenue !== null
        );
    }

    showMarletGrowthChart(): boolean {
        return (
            this.country.market.fashionBeautyEshopsGrowth !== null &&
            this.country.market.foodEshopsGrowth !== null &&
            this.country.market.highTechEshopsGrowth !== null &&
            this.country.market.homeEshopsGrowth !== null &&
            this.country.market.musicEshopsGrowth !== null &&
            this.country.market.toysEshopsGrowth !== null &&
            this.country.market.travelEshopsGrowth !== null &&
            this.country.market.videoGamesEshopsGrowth !== null
        );
    }

    formatNumber(value: number) {
        if (value === null) {
            return null;
        }
        return String(value).replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
    }

    removeCharToCreateNumber(value: string, char: string): number {
        return value !== null ? Number(value.replace(char, '')) : null;
    }

    dataNull(data: any, canBeEmpty: boolean): string | any {
        if (data === null) {
            return this.labelNoData;
        } else {
            if (!canBeEmpty) {
                if (!isNaN(data)) {
                    if (data === 0) {
                        return this.labelNoData;
                    }
                } else {
                    if (data.length === 0) {
                        return this.labelNoData;
                    }
                }
            }
        }
        return data;
    }
}
