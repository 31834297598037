import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ECOMMERCE_REQUEST_TYPE_ENUM, RequestProductAllCurrencies, TaxRequest, TaxRequestReceiver, TaxRequestSender} from '../../../../models/DutyCalculation';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Builder} from 'builder-pattern';

@Component({
    selector: 'app-step-sender',
    templateUrl: './step-sender.component.html',
    styleUrls: ['./step-sender.component.scss']
})
export class StepSenderComponent implements OnInit {

    @Input()
    taxRequest: TaxRequest;
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    setData: EventEmitter<TaxRequest> = new EventEmitter<TaxRequest>();
    @Output()
    previousStep: EventEmitter<void> = new EventEmitter<void>();

    senderForm: FormGroup;
    formIsInvalid = false;
    currencies: Array<{}>;
    activities: Array<{}>;

    isCtoC = false;
    isCtoB = false;
    isBtoB = true;
    isBtoC = false;

    constructor(private translate: TranslateService) {
        this.senderForm = new FormGroup({
            extra_fees: new FormControl(null),
            senderMarketplace: new FormArray([]),
            receiver: new FormGroup({
                pro: new FormControl(true, Validators.required),
                activityId: new FormControl('', Validators.required)
            })
        });
    }

    async ngOnInit() {
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        this.senderForm.get('extra_fees').setValue(this.taxRequest && this.taxRequest.extra_fees !== undefined ? this.taxRequest.extra_fees : null);
        if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE) {
            const products: RequestProductAllCurrencies[][] = [];
            this.taxRequest.products.forEach(product => {
                let iProducts = products.findIndex(p => {
                    return p[0].to_country === product.to_country &&
                        p[0].to_district === product.to_district &&
                        p[0].from_country === product.from_country;
                });
                if (iProducts === -1) {
                    iProducts = products.length;
                    products.push([]);
                }
                products[iProducts].push(product);
            });
            for (let i = 0; i < products.length; i++) {
                this.senders.push(this.initSender(products[i][0].from_country, products[i][0].to_country, products[i][0].to_district));
            }
        } else {
            this.senders.push(this.initSender(this.taxRequest.from_country, this.taxRequest.to_country, this.taxRequest.to_district));
        }
        await this.initTranslation();
    }

    get senders(): FormArray {
        return this.senderForm.get('senderMarketplace') as FormArray;
    }

    initSender(fromCountry: string, toCountry: string, toDistrict: string): FormGroup {
        return new FormGroup({
            fromCountry: new FormControl(fromCountry),
            toCountry: new FormControl(toCountry),
            toDistrict: new FormControl(toDistrict),
            pro: new FormControl(true, Validators.required),
            revenueCountryAnnual: new FormControl('', [Validators.min(0.01), Validators.required]),
            currencyRevenueCountryAnnual: new FormControl('', Validators.required)
        });
    }

    async initTranslation() {
        await this.translate.get('duty_activities').subscribe((activities) => {
            this.activities = activities;
        });
        await this.translate.get('currency_name').subscribe(currencies => {
            this.currencies = currencies;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currencies = event.translations['currency_name'];
            this.activities = event.translations['duty_activities'];
        });
    }

    get receiver(): FormGroup {
        return this.senderForm.controls['receiver'] as FormGroup;
    }

    onChangeCtoC(event) {
        if (event) {
            this.isCtoC = true;
            this.isCtoB = false;
            this.isBtoB = false;
            this.isBtoC = false;
            for (let i = 0; i < this.senders.length; i++) {
                this.senders.at(i).get('pro').setValue(false);
                this.senders.at(i).get('revenueCountryAnnual').setValue('');
                this.senders.at(i).get('revenueCountryAnnual').clearValidators();
                this.senders.at(i).get('revenueCountryAnnual').updateValueAndValidity();
                this.senders.at(i).get('currencyRevenueCountryAnnual').setValue('');
                this.senders.at(i).get('currencyRevenueCountryAnnual').clearValidators();
                this.senders.at(i).get('currencyRevenueCountryAnnual').updateValueAndValidity();
            }
            this.receiver.get('pro').setValue(false);
            this.receiver.get('activityId').setValue('');
            this.receiver.get('activityId').clearValidators();
            this.receiver.get('activityId').updateValueAndValidity();
        }
    }

    onChangeCtoB(event) {
        if (event) {
            this.isCtoC = false;
            this.isCtoB = true;
            this.isBtoB = false;
            this.isBtoC = false;
            for (let i = 0; i < this.senders.length; i++) {
                this.senders.at(i).get('pro').setValue(false);
                this.senders.at(i).get('revenueCountryAnnual').setValue('');
                this.senders.at(i).get('revenueCountryAnnual').clearValidators();
                this.senders.at(i).get('revenueCountryAnnual').updateValueAndValidity();
                this.senders.at(i).get('currencyRevenueCountryAnnual').setValue('');
                this.senders.at(i).get('currencyRevenueCountryAnnual').clearValidators();
                this.senders.at(i).get('currencyRevenueCountryAnnual').updateValueAndValidity();
            }
            this.receiver.get('pro').setValue(true);
            this.receiver.get('activityId').setValidators([Validators.required]);
            this.receiver.get('activityId').updateValueAndValidity();
        }
    }

    onChangeBtoB(event) {
        if (event) {
            this.isCtoC = false;
            this.isCtoB = false;
            this.isBtoB = true;
            this.isBtoC = false;
            for (let i = 0; i < this.senders.length; i++) {
                this.senders.at(i).get('pro').setValue(true);
                this.senders.at(i).get('revenueCountryAnnual').setValidators([Validators.min(0.01), Validators.required]);
                this.senders.at(i).get('revenueCountryAnnual').updateValueAndValidity();
                this.senders.at(i).get('currencyRevenueCountryAnnual').setValidators([Validators.min(0.01), Validators.required]);
                this.senders.at(i).get('currencyRevenueCountryAnnual').updateValueAndValidity();
            }
            this.receiver.get('pro').setValue(true);
            this.receiver.get('activityId').setValidators([Validators.required]);
            this.receiver.get('activityId').updateValueAndValidity();
        }
    }

    onChangeBtoC(event) {
        if (event) {
            this.isCtoC = false;
            this.isCtoB = false;
            this.isBtoB = false;
            this.isBtoC = true;
            for (let i = 0; i < this.senders.length; i++) {
                this.senders.at(i).get('pro').setValue(true);
                this.senders.at(i).get('revenueCountryAnnual').setValidators([Validators.min(0.01), Validators.required]);
                this.senders.at(i).get('revenueCountryAnnual').updateValueAndValidity();
                this.senders.at(i).get('currencyRevenueCountryAnnual').setValidators([Validators.min(0.01), Validators.required]);
                this.senders.at(i).get('currencyRevenueCountryAnnual').updateValueAndValidity();
            }
            this.receiver.get('pro').setValue(false);
            this.receiver.get('activityId').setValue('');
            this.receiver.get('activityId').clearValidators();
            this.receiver.get('activityId').updateValueAndValidity();
        }
    }

    filterKeyExtraFees(event) {
        const lastValue = this.senderForm.get('extra_fees').value;
        return (
            !isNaN(Number(event.key)) &&
            (
                lastValue === '0.' ||
                (
                    lastValue !== null &&
                    (
                        lastValue === '' ||
                        (parseFloat(lastValue) >= 0 && parseFloat(lastValue) < 1))
                    )
            )
            ||
            (lastValue === null && parseInt(event.key, 10) <= 1)
        )
        ||
        (
            event.key === '.' &&
            lastValue !== null &&
            !isNaN(Number(lastValue)) &&
            ! lastValue.toString().includes('.')
        );
    }

    nextStep() {
        if (this.senderForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        const values = this.senderForm.value;

        const receiver = Builder(TaxRequestReceiver)
                        .pro(this.senderForm.value.receiver.pro)
                        .activity_id(this.senderForm.value.receiver.activityId)
                        .build();
        this.taxRequest.receiver = receiver;

        if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE) {
            values.senderMarketplace.forEach(sender => {
                const taxRequestProducts = this.taxRequest.products.filter(p => {
                    return p.to_country === sender.toCountry &&
                        p.to_district === sender.toDistrict &&
                        p.from_country === sender.fromCountry;
                });
                const productSender = Builder(TaxRequestSender)
                                    .pro(sender.pro)
                                    .revenue_country_annual(sender.revenueCountryAnnual)
                                    .currency_revenue_country_annual(sender.currencyRevenueCountryAnnual)
                                    .build();
                taxRequestProducts.forEach(p => {
                    p.sender = productSender;
                });
            });
        } else {
            const sender = Builder(TaxRequestSender)
                        .pro(values.senderMarketplace[0].pro)
                        .revenue_country_annual(values.senderMarketplace[0].revenueCountryAnnual)
                        .currency_revenue_country_annual(values.senderMarketplace[0].currencyRevenueCountryAnnual)
                        .build();
            this.taxRequest.sender = sender;
        }
        this.taxRequest.extra_fees = values.extra_fees !== null &&
                                    values.extra_fees !== '' &&
                                    !isNaN(values.extra_fees)
                                    ?
                                    parseFloat(values.extra_fees)
                                    :
                                    undefined;

        this.formIsInvalid = false;
        this.setData.emit(this.taxRequest);
    }

    goBack() {
        this.previousStep.emit();
    }

    getCountry(index: number, formName: string): string {
        const value = this.senders.at(index).get(formName).value;
        const country = this.countries.find(c => c.value === value);
        return country ? country.label : value;
    }
}
