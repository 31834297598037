import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { UserService } from "../../services/user.service";
import { PLAN_ENUM, PLAN_V2_ENUM, User } from "../../../models/User";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENTATIONS_GUIDES } from "../documentation-guide/documentation-guide.component";

@Component({
  selector: "app-api-key",
  templateUrl: "./api-key.component.html",
  styleUrls: ["./api-key.component.scss"],
})
export class ApiKeyComponent implements OnInit {
  @Input()
  fromWelcome: boolean;
  //@Output()
  //displayDocumentation: EventEmitter<void> = new EventEmitter<void>();

  tokenType: string;

  refresh_token: string;
  display_refresh_token: string;
  id_token: string;
  client_id: string;
  api_key: string;
  display_id_token: string;
  display_client_id: string;
  display_api_key: string;
  user: User;
  lang: string;
  allow_token: boolean;
  isLoading: boolean;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.client_id = environment.userPoolWebClientId;
    this.display_client_id = this.client_id.substr(0, 20) + "...";
  }

  @ViewChild("pop_refresh_token", { static: false }) pop_refresh_token;
  @ViewChild("pop_id_token", { static: false }) pop_id_token;
  @ViewChild("pop_client_id", { static: false }) pop_client_id;
  @ViewChild("pop_api_key", { static: false }) pop_api_key;
  async ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
      this.tokenType = params.get("token");

      await this.initUserPrefs();
      await this.refreshTokens();
      
      this.api_key = this.user && this.user.ecommerceProperties && this.user.ecommerceProperties.apiKey ? this.user.ecommerceProperties.apiKey : "";
      this.display_api_key = this.api_key.substr(0, 16) + "...";

      this.translate.get("messages").toPromise().then(messages => {
        this.lang = this.user.lang
          ? this.user.lang.substr(0, 2)
          : messages.current_lang;
      });
    });
  }
  async refreshTokens() {
    if (this.allow_token) {
      this.isLoading = true;
      await this.authService.getCognitoUser().then((user) => {
        this.refresh_token = user.getRefreshToken().getToken();
        this.id_token = user.getIdToken().getJwtToken();
      });
      this.display_refresh_token = this.refresh_token.substr(0, 20) + "...";
      this.display_id_token =
        this.id_token.substr(this.id_token.length - 20, this.id_token.length) +
        "...";
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }
  private async initUserPrefs() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
    this.allow_token =
      user.plan === PLAN_V2_ENUM["E-COMMERCE"] ||
      user.plan === PLAN_V2_ENUM.API;
  }

  isEcommerceUser(): boolean {
    return this.user.plan === PLAN_V2_ENUM["E-COMMERCE"];
  }

  async copyToClipboard(token, type: string) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", token);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    if (type === "refresh_token") {
      this.pop_refresh_token.show();
    } else if (type === "id_token") {
      this.pop_id_token.show();
    } else if (type === "api_key") {
      this.pop_api_key.show();
    } else {
      this.pop_client_id.show();
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.pop_id_token.hide();
    this.pop_refresh_token.hide();
    this.pop_client_id.hide();
    this.pop_api_key.hide();
  }

  onDisplayDocumentation() {
    this.router.navigate([`documentation-guide/${DOCUMENTATIONS_GUIDES.API}`]);
    //this.displayDocumentation.emit();
  }
}
