// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  userPoolId: 'eu-west-1_FpBgrnB0i',
  webappUrl: 'https://www.app.dev.transiteo.io',
  i18n: 'https://www.app.dev.transiteo.io/assets/i18n',
  baseUrl: 'dev.transiteo.io',
  stripeKey: 'pk_test_0In3v3rawq2c1c72cVJd83x300Rs75eJ5j',
  userPoolWebClientId: '24l8ltdhvqrd57ggg4n34mrsnt',
  gTag: 'UA-144266130-4'
};
