import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { User } from '../../../models/User';
import { pgTabComponent } from '../../components/tabs/tab.component';
import { Router } from '@angular/router';
import { SharedData } from '../../Common/SharedData';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
const currency = require('../../../../assets/data/plan_currency.json');
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { HttpClient } from '@angular/common/http';
import { REGISTER_PART, REGISTER_STEP } from '../../../models/Register';
/*import { ApiAccount, WebAccount, CmsAccount } from '../../../models/Register';
import { USING_STATUT_ENUM } from '../../../models/User';*/




@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;
    user: User;
    unknown_error: string;

    // @ViewChild('pop_usage_type', { static: false }) pop_usage_type;
    // @ViewChild('pop_need', { static: false }) pop_need;
    @ViewChild('pop_product_settings', { static: false }) pop_product_settings;
    @ViewChild('pop_hscode', { static: false }) pop_hscode;
    @ViewChild('pop_export_country', { static: false }) pop_export_country;
    @ViewChild('pop_origin_country', { static: false }) pop_origin_country;

    registerForm: FormGroup;
    formIsInvalid = false;
    activityOptions: Array<{}>;
    currency: Array<{}>;
    allCurrencies: { value: string, label: string, disabled?: boolean }[];
    usageTypes: { value: string, label: string, disabled?: boolean }[];
    // countries: Array<{ value: string, label: string, iso2: string }> = [];
    realtimeSpot: { value: string, label: string }[];
    apisServices: { value: string, label: string }[];
    currentStep: number;
    currentStepOption: number;
    REGISTER_STEP = REGISTER_STEP;

    selectedUsageType = '';

    readonly DEFAULT_CURRENCY = 'EUR';
    readonly DEFAULT_LANG = 'EN';
    errorRegister = '';
    registerMessage = '';
    fieldTextType: boolean;
    signUpSuccess: string;
    signUpFailed: string;
    special_chars = '^$*.[]{}()?"!@#%&/ \\,><\':;|_~`';

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates];
    showCompanyForm = true;
    lang: string;
    constructor(private authService: AuthService,
        private router: Router,
        private data: SharedData,
        private _notification: MessageService,
        private translate: TranslateService) {
        this.currency = currency;
        this.registerForm = new FormGroup({
            lastname: new FormControl('', [Validators.required]),
            firstname: new FormControl('', [Validators.required]),
            // phone: new FormControl('', [Validators.required]),
            // currency: new FormControl(this.DEFAULT_CURRENCY),
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8),
            Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'), Validators.pattern(/[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/)]),
            integration_method: new FormControl('', [Validators.required]),
            conditions: new FormControl(false, [Validators.pattern('true')]),
            // usageType: new FormArray([])
        });
        this.lang = 'en';
        // this.selectedUsageType = 'cms';
        // this.addUsageTypeLine();
    }
    async ngOnInit() {
        if (this.authService.isLoggedIn) {
            this.currentStep = REGISTER_PART.REGISTER_STEP;
            this.currentStepOption = 0;
        } else {
            this.currentStep = REGISTER_PART.REGISTER_MAIL;
            this.currentStepOption = 0;
            this.initTranslations();
        }
        this.lang = navigator ? navigator.language.substr(0, 2) : this.lang;
    }


    initTranslations() {
        this.translate.get('activities').subscribe(activities => {
            this.activityOptions = activities;
        });
        this.translate.get('messages').subscribe(messages => {
            this.updateLangMessages(messages);
        });
        this.translate.get('register.usage_types.types').subscribe(types => {
            this.usageTypes = types;
        });
        /*this.translate.get('countries').subscribe(countries => {
            this.countries = countries;
        });*/
        this.translate.get('currency_name').subscribe(currencies => {
            this.allCurrencies = currencies;
        });
        this.translate.get('register.usage_types.api.realtime_spot').subscribe(realSpot => {
            this.realtimeSpot = realSpot;
        });
        this.translate.get('register.usage_types.apis_services').subscribe(apisServices => {
            this.apisServices = apisServices;
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateLangMessages(event.translations['messages']);
            // this.countries = event.translations['countries'];
            this.allCurrencies = event.translations['currency_name'];
            this.activityOptions = event.translations.activities ? event.translations.activities : this.activityOptions;
            if (event.translations.register && event.translations.register.usage_types && event.translations.register.usage_types.types) {
                this.usageTypes = event.translations.register.usage_types.types;
                this.apisServices = event.translations.register.usage_types.apis_services;
                this.realtimeSpot = event.translations.register.usage_types.api.realtime_spot;
            }
        });
    }

    updateLangMessages(messages) {
        this.signUpSuccess = messages.notification.signUp.success;
        this.signUpFailed = messages.notification.signUp.fail;
        // this.registerForm.get('currency').setValue(messages.default_currency);
        // this.registerForm.get('currency').updateValueAndValidity();
    }

    // is the form valid?
    /*formIsValid(): boolean {
        if (this.selectedUsageType === 'cms') {
            if (this.unknown_htscode) {
                return this.registerForm.valid;
            } else {
                if (this.usageTypeForm.at(0).get('htscode').value && this.usageTypeForm.at(0).get('htscode').value.length > 0) {
                    return this.registerForm.valid;
                } else {
                    return false;
                }
            }
        }
        return this.registerForm.valid;
    }*/




    signUp = async () => {
        if (this.registerForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const user = this.registerForm.value;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.authService.isLoggedIn = false;
        /*let usingType: USING_STATUT_ENUM;
        let usageType: CmsAccount; // | ApiAccount[] | WebAccount[];
        if (this.selectedUsageType === 'cms') {
            usingType = USING_STATUT_ENUM.ECOMMERCE;
            usageType = {
                default_htscode: user.usageType[0].unknown_htscode ? '' : user.usageType[0].htscode,
                origin_country: user.usageType[0].origin_country,
                from_country: user.usageType[0].from_country,
                products_currency: user.usageType[0].products_currency,
                domain: user.usageType[0].domain
            };
        } else if (this.selectedUsageType === 'api') {
            usingType = USING_STATUT_ENUM.API;
            usageType = <ApiAccount[]>user.usageType;
            usageType = usageType.filter(elem => elem.api_service && elem.api_service.length > 0);
        } else {
            usingType = USING_STATUT_ENUM.WEBPLATFORM;
            usageType = <WebAccount[]>user.usageType;
            usageType = usageType.filter(elem => elem.api_service && elem.api_service.length > 0);
        }*/

        await this.authService.signUp(
            user.firstname,
            user.lastname,
            user.email,
            this.lang,
            // user.currency,
            user.password,
            user.integration_method
            // user.phone.e164Number,
            // usingType,
            // usageType
        )
            .then(() => {
                this.data.successMessage = this.signUpSuccess;
                this.router.navigate(['/login']);
            })
            .catch(error => {
                if (error.name === 'UsernameExistsException') {
                    this._notification.displayMessage(this.translate.instant('messages.notification.signUp.alreadyRegistered'), 'warning');
                } else {
                    this._notification.displayMessage(this.signUpFailed, 'danger');
                }
            });
    }
    get f() { return this.registerForm.controls; }

    enoughChar(password: string) {
        return password.length >= 8;
    }
    anyUpperLetter(password: string) {
        const regex = RegExp('(?=.*[A-Z])');
        return regex.test(password);
    }
    anyNumber(password: string) {
        const regex = RegExp('(?=.*[0-9])');
        return regex.test(password);
    }
    anySpecialChar(password: string) {
        const regex_symbols = /[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/;
        const regex = RegExp(regex_symbols);
        return regex.test(password);
    }
    anyLowerLetter(password: string) {
        const regex = RegExp('(?=.*[a-z])');
        return regex.test(password);
    }
    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    /*get usageTypeForm(): FormArray {
        return this.registerForm.get('usageType') as FormArray;
    }

    get unknown_htscode(): boolean {
        return this.usageTypeForm.at(0).get('unknown_htscode').value;
    }

    remainingApisServices(): {value: string, label: string}[] {
        const usedApisServices: string[] = [];
        let i = 0;
        while (i < this.usageTypeForm.length) {
            usedApisServices.push(this.usageTypeForm.at(i).get('api_service').value);
            i += 1;
        }

        const remaining: {value: string, label: string}[] = [];
        this.apisServices.forEach(elem => {
            if (!usedApisServices.includes(elem.value)) {
                remaining.push(elem);
            }
        });
        return remaining;
    }*/

    buildUsageTypeForm(): FormGroup {
        let formGrp: FormGroup = null;

        // API
        if (this.selectedUsageType === 'api') {
            formGrp = new FormGroup({
                api_service: new FormControl(''),
                realtime_spot: new FormControl(''),
                call_number: new FormControl('', [Validators.min(1)]),
            });

            // WEB APP
        } else if (this.selectedUsageType === 'webapp') {
            formGrp = new FormGroup({
                api_service: new FormControl(''),
                users_number: new FormControl(''),
                call_number: new FormControl('', [Validators.min(1)]),
            });

            // CMS
        } else {
            formGrp = new FormGroup({
                htscode: new FormControl(''),
                origin_country: new FormControl('', [Validators.required]),
                from_country: new FormControl('', [Validators.required]),
                products_currency: new FormControl('', [Validators.required]),
                domain: new FormControl('', [
                    Validators.required,
                    Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
                ]),
                unknown_htscode: new FormControl(false, [Validators.pattern('true|false')]),
            });
        }

        return formGrp;
    }

    /*onUsageTypeChange() {
        this.addUsageTypeLine();
    }

    addUsageTypeLine() {
        this.usageTypeForm.push(this.buildUsageTypeForm());
    }

    deleteUsageTypeLine(index: number) {
        this.usageTypeForm.removeAt(index);
    }*/

    async displayTooltip(tooltip: string) {

        switch (tooltip) {
            // case 'usage_type':
            //     this.pop_usage_type.show();
            //     await new Promise((resolve) => setTimeout(resolve, 5000));
            //     this.pop_usage_type.hide();
            //     break;
            // case 'need':
            //     this.pop_need.show();
            //     await new Promise((resolve) => setTimeout(resolve, 5000));
            //     this.pop_need.hide();
            //     break;
            case 'product_settings':
                this.pop_product_settings.show();
                await new Promise((resolve) => setTimeout(resolve, 5000));
                this.pop_product_settings.hide();
                break;
            case 'hscode':
                this.pop_hscode.show();
                await new Promise((resolve) => setTimeout(resolve, 5000));
                this.pop_hscode.hide();
                break;
            case 'export_country':
                this.pop_export_country.show();
                await new Promise((resolve) => setTimeout(resolve, 5000));
                this.pop_export_country.hide();
                break;
            case 'origin_country':
                this.pop_origin_country.show();
                await new Promise((resolve) => setTimeout(resolve, 5000));
                this.pop_origin_country.hide();
                break;
            default:
                break;
        }
    }



    isRegisterMail(): boolean {
        return this.currentStep === REGISTER_PART.REGISTER_MAIL;
    }

    isRegisterStep(): boolean {
        return this.currentStep === REGISTER_PART.REGISTER_STEP;
    }

    isRightStep(step: REGISTER_STEP) {
        if (step >= 0) {
            return step === this.currentStep;
        }
        return false;
    }

    isRightStepOption(stepOption) {
        if (this.currentStepOption >= 0) {
            return true;
        }
        return false;
    }

    nextStep() {
        this.currentStep += 1;
    }

    nextStepOption() {
        if (this.isRightStepOption(this.currentStepOption)) {
            this.currentStepOption += 1;
        }
    }
}
