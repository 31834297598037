import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepCarriersForm } from '../../../../../../models/Register';

@Component({
    selector: 'app-register-step-transportation',
    templateUrl: './step_transportation.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepTransportationComponent implements OnInit {
    @Input()
    formContent: StepCarriersForm;
    @Output()
    nextStepCallback: EventEmitter<StepCarriersForm> = new EventEmitter<StepCarriersForm>();
    @Output()
    modalIsOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

    registerFormFedex: FormGroup;
    registerFormDHL: FormGroup;
    registerFormUPS: FormGroup;
    formIsInvalid = false;
    formContentReturn: StepCarriersForm;
    modalRef: BsModalRef = null;

    constructor(
        private modalService: BsModalService
    ) {}

    ngOnInit() {
        this.formContentReturn = {...this.formContent};
        this.registerFormUPS = new FormGroup({
            ups_payment_account_number: new FormControl(
                this.formContent.ups_payment_account_number ? this.formContent.ups_payment_account_number : '',
                [
                    Validators.required
                ]
            )
        });
        this.registerFormDHL = new FormGroup({
            dhl_online_store_id: new FormControl(
                this.formContent.dhl_online_store_id ? this.formContent.dhl_online_store_id : '',
                [
                    Validators.required
                ]
            ),
            dhl_payment_account_number: new FormControl(
                this.formContent.dhl_payment_account_number ? this.formContent.dhl_payment_account_number : '',
                [
                    Validators.required
                ]
            ),
            dhl_production_password: new FormControl(
                this.formContent.dhl_production_password ? this.formContent.dhl_production_password : '',
                [
                    Validators.required
                ]
            )
        });
        this.registerFormFedex = new FormGroup({
            fedex_account_number: new FormControl(
                this.formContent.fedex_account_number ? this.formContent.fedex_account_number : '',
                [
                    Validators.required
                ]
            ),
            fedex_api_password: new FormControl(
                this.formContent.fedex_api_password ? this.formContent.fedex_api_password : '',
                [
                    Validators.required
                ]
            ),
            fedex_authentication_key: new FormControl(
                this.formContent.fedex_authentication_key ? this.formContent.fedex_authentication_key : '',
                [
                    Validators.required
                ]
            ),
            fedex_meter_number: new FormControl(
                this.formContent.fedex_meter_number ? this.formContent.fedex_meter_number : '',
                [
                    Validators.required
                ]
            )
        });
    }

    openModal(template: TemplateRef<any>) {
        this.formIsInvalid = false;
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        this.modalRef = this.modalService.show(template, config);
        this.modalIsOpened.emit(true);
    }

    closeModal() {
        this.modalIsOpened.emit(false);
        if (this.modalRef) {
            this.modalRef.hide();
            this.modalRef = null;
        }
    }

    fedexDefined(): boolean {
        return this.formContentReturn.fedex_account_number && this.formContentReturn.fedex_account_number.length > 0;
    }

    validFedex(validData = true) {
        if (validData) {
            if (this.registerFormFedex.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const value = this.registerFormFedex.value;
            this.formContentReturn.fedex_account_number = value.fedex_account_number;
            this.formContentReturn.fedex_api_password = value.fedex_api_password;
            this.formContentReturn.fedex_authentication_key = value.fedex_authentication_key;
            this.formContentReturn.fedex_meter_number = value.fedex_meter_number;
            this.closeModal();
        } else {
            this.closeModal();
        }
    }

    dhlDefined(): boolean {
        return this.formContentReturn.dhl_online_store_id && this.formContentReturn.dhl_online_store_id.length > 0;
    }

    validDHL(validData = true) {
        if (validData) {
            if (this.registerFormDHL.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const value = this.registerFormDHL.value;
            this.formContentReturn.dhl_online_store_id = value.dhl_online_store_id;
            this.formContentReturn.dhl_payment_account_number = value.dhl_payment_account_number;
            this.formContentReturn.dhl_production_password = value.dhl_production_password;
            this.closeModal();
        } else {
            this.closeModal();
        }
    }

    upsDefined(): boolean {
        return this.formContentReturn.ups_payment_account_number && this.formContentReturn.ups_payment_account_number.length > 0;
    }

    validUPS(validData = true) {
        if (validData) {
            if (this.registerFormUPS.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const value = this.registerFormUPS.value;
            this.formContentReturn.ups_payment_account_number = value.ups_payment_account_number;
            this.closeModal();
        } else {
            this.closeModal();
        }
    }

    goToPublicDocumentation() {
        window.open('https://www.transiteo.com/', '_blank');
    }

    nextStep() {
        this.nextStepCallback.emit(this.formContentReturn);
    }
}
