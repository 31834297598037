export class ApiHistory {
    mail: string;
    httpCode: number;
    requestEndpoint: string;
    timestamp: number;
    plan: string;
    fromCountry: string;
    toCountry: string;
    used_credits: number;
}

export class RequestHistory extends ApiHistory {
    requestBody: any;
}

export class ResponseHistory extends RequestHistory {
    responseBody: any;

    toRow(): any {
        return {
            httpCode: this.httpCode,
            request: this.requestEndpoint,
            timestamp: this.timestamp,
            date: (new Date(this.timestamp)),
            fromCountry: this.fromCountry,
            toCountry: this.toCountry,
            plan: this.plan,
            usedCredits: this.used_credits
        };
    }
}
