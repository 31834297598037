import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Builder } from 'builder-pattern';
import { StepAccountForm } from '../../../../../../models/Register';
@Component({
    selector: 'app-register-step-account',
    templateUrl: './step_account.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepAccountComponent implements OnInit {
    @Input()
    formContent: StepAccountForm;
    @Output()
    nextStepCallback: EventEmitter<StepAccountForm> = new EventEmitter<StepAccountForm>();

    registerForm: FormGroup;
    formIsInvalid = false;

    constructor() {}

    ngOnInit() {
        this.registerForm = new FormGroup({
            company_name: new FormControl(
                this.formContent.company_name ? this.formContent.company_name : '',
                [
                    Validators.required
                ]
            ),
            domain_name: new FormControl(
                this.formContent.domain_name ? this.formContent.domain_name : '',
                [
                    Validators.required,
                    Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
                ]
            ),
        });
    }

    nextStep() {
        if (this.registerForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        this.nextStepCallback.emit(this.registerForm.value as StepAccountForm);
    }
}
