import { CustomerOrder, CUSTOMER_PRODUCT_TYPE_ENUM } from './Managers';
import { TaxContent } from './TradeTariffRates';
import { ItemsCounts, UserSettings } from './User';

// API ConsumptionCreditEnum + otherCreditsCount
export enum ConsumptionCreditEnum {
    DUTY_CALCULATION='/v1/taxsrv/dutyCalculation',
    REVERSE_LANDED_COST='/v1/reverseLandedCost',
    HSCODE_FINDER='/v1/taxsrv/hscodefinder',
    HSCODE_FINDER_V4='/v4/taxsrv/hscodefinder',
    HSCODE_FINDER_V4_DOT_1='/v4.1/taxsrv/hscodefinder',
    HSCODE_FINDER_IMPORT_EXPORT='/v1/taxsrv/hscodefinderimportexport',
    GREEN_SERVICE_PRODUCT='/v1/greensrv/product',
    GREEN_SERVICE_SHIPPING='/v1/greensrv/shipping',
    IMPORT_FILE_IMAGE='/v1/import/image',
    IMPORT_FILE_INVOICE_PDF='/v1/import/invoicePdf',
    IMPORT_FILE_AUDIO='/v1/import/audio',
    AI_CLASSIFY='/v1/taxsrv/aiclassify',
    PRODUCT_PRICING = '/v1/aiproductpricing',
    SHIPPING_PRICING = '/v1/aishippingpricing',
    PRODUCT_SEARCH='/v1/pimsrv/productSearch',
    TAX_CALCULATION_HSCODE='/v1/taxsrv/taxCalculationHscode',
    TAX_CALCULATION='/v1/taxsrv/taxCalculation',
    DUTIES='/v1/data/duties',
    TAXES='/v1/data/taxes',
    SALESTAXES='/v1/data/salestaxes',
    SPECIAL_TAXES='/v1/data/specialTaxes',
    PROCESS_DUTY='/v1/customer/products/process/dutyCalculation',
    PROCESS_HSCODE='/v1/customer/products/process/hscodefinder',
    CURRENCY='/v1/data/currency',
    ECO_TAX='/v1/data/ecoTax',
    logistic='/v1/data/logistic',
    PDF_GENERATE='/v1/pdf/generate/{type}/{lang}',
    DUTY_HSCODE = '/v1/taxsrv/dutyCalculationHscode',
    DUTY_CALCULATION_HSCODE_SIMPLIFIED='/v1/taxsrv/dutyCalculationHscodeSimplified',
    DUTY_CALCULATION_WITH_HSCODE='/v1/taxsrv/dutyCalculationWithHscode',
    DUTY_CALCULATION_DUTY_SIMPLIFIED='/v1/taxsrv/dutyCalculationDutySimplified',
    PRODUCT_SEARCH_TAXSRV='/v1/taxsrv/productSearch',
    PROCESS_CATEGORY_ECO_TAX='/v1/customer/categories/process/ecotax',
    PROCESS_CATEGORY_CO2='/v1/customer/categories/process/co2',
    PROCESS_CATEGORY_UN_CODE='/v1/customer/categories/process/uncode',
    PROCESS_LOCAL_TAXES_RATES = '/v1/customer/categories/process/localTaxesRates',
    PROCESS_CODIFICATION_CATEGORIES='/v1/customer/codifications/process/categories',
    PROCESS_PRODUCT_ECO_TAX='/v1/customer/products/process/ecotax',
    PROCESS_PRODUCT_MARGIN_CALCULATION='/v1/customer/products/process/marginCalculation',
    PROCESS_PRODUCT_CO2='/v1/customer/products/process/co2',
    PROCESS_PRODUCT_UN_CODE='/v1/customer/products/process/uncode',
    CUSTOMER_ORDER_ADD_DOCUMENT='/v1/customer/orders/{orderId}/document',
    PROCESS_ORDER_LANDED_COST='/v1/customer/orders/process/landedCost',
    PROCESS_ORDER_GENERATE_DOCS='/v1/customer/orders/process/generateDocs',
    PROCESS_ORDER_REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE='/v1/customer/orders/process/removeProducts',
    PROCESS_ORDER_DELETE_ORDERS_GENERATE_CREDIT_NOTE='/v1/customer/orders/process/deleteOrders',
    PROCESS_ORDER_GENERATE_TAXES_INVOICE='/v1/customer/orders/process/generateTaxesInvoice',
    HARMONIZED_SYSTEM_CODES='/v1/taxsrv/harmonizedSystemCodes',
    IMPORT_FILES_PDF_SERVICE_LANDED_COST='/v1/importFileService/invoicePdf/landedCost',
    IMPORT_FILES_PDF_SERVICE_EXPORT_INVOICE='/v1/importFileService/invoicePdf/exportInvoice',
    IMPORT_FILES_PDF_SERVICE_TAXES_INVOICE='/v1/importFileService/invoicePdf/taxesInvoice',
}

export enum MONITORING_DATA_TYPE {
    ORDERS = 'orders',
    PRODUCTS = 'products',
    CUSTOMERS = 'customers'
}

export class AllUserOrders {
    data: MonitoringCustomerOrder[];
    nextIndex: any;
}

export class MonitoringCustomerOrder extends CustomerOrder {
    mail: string;
    company_name: string;

    toRow() {
        return {
            mail: this.mail,
            company_name: this.company_name,
            order_id: this.order_id,
            invoice_id: this.invoice_id,
            url: this.url,
            order_date_hour: this.order_date_hour,
            customer_firstname: this.customer_firstname,
            customer_lastname: this.customer_lastname,
            customer_address: this.customer_address,
            customer_zipcode: this.customer_zipcode,
            customer_city: this.customer_city,
            customer_country: this.customer_country,
            buyer_firstname: this.buyer_firstname,
            buyer_lastname: this.buyer_lastname,
            buyer_address: this.buyer_address,
            buyer_zipcode: this.buyer_zipcode,
            buyer_city: this.buyer_city,
            buyer_country: this.buyer_country,
            agent_firstname: this.agent_firstname,
            agent_lastname: this.agent_lastname,
            agent_address: this.agent_address,
            agent_zipcode: this.agent_zipcode,
            agent_city: this.agent_city,
            agent_country: this.agent_country,
            departure_country: this.departure_country,
            arrival_country: this.arrival_country,
            exporting_carrier: this.exporting_carrier,
            shipping_carrier: this.shipping_carrier,
            amount_products: this.amount_products,
            amount_shipping: this.amount_shipping,
            amount_shipping_duty: this.amount_shipping_duty,
            amount_shipping_vat: this.amount_shipping_vat,
            amount_shipping_specialtaxes: this.amount_shipping_specialtaxes,
            amount_duty: this.amount_duty,
            amount_vat: this.amount_vat,
            amount_specialtaxes: this.amount_specialtaxes,
            currency: this.currency,
            order_statut: this.order_statut,
            order_update_statut: this.order_update_statut,
            order_payment_type: this.order_payment_type,
            order_payment_date: this.order_payment_date,
            category_of_item: this.category_of_item,
            payment_type: this.payment_type,
            incoterm: this.incoterm,
            incoterm_delivery: this.incoterm_delivery
        };
    }
}

export class AllUserProducts {
    data: MonitoringCustomerProduct[];
    nextIndex: any;
}

export class MonitoringCustomerProduct {
    mail: string;
    company_name: string;
    id: string;
    type: CUSTOMER_PRODUCT_TYPE_ENUM;
    sku?: string;
    value: string;
    weight: number;
    weight_unit: string;
    [key: string]: any;

    toRow() {
        const row = {
            mail: this.mail,
            company_name: this.company_name,
            id: this.id,
            type: this.type,
            sku: this.sku,
            productID: this.value,
            weight: this.weight,
            weight_unit: this.weight_unit
        };

        const keys = Object.getOwnPropertyNames(this);
        const productKeys = [
            'mail',
            'company_name',
            'id',
            'type',
            'productID',
            'value',
            'weight',
            'weight_unit',
            'creationDate',
            'hscodefinder',
            'dutyCalculation',
            'locals_taxes',
            'other_taxes'
        ];

        keys.forEach(k => {
            if (!productKeys.includes(k)) {
                row[k] = this[k];
            }
            if (k === 'locals_taxes' || k === 'other_taxes') {
                const arr = (this[k] as TaxContent[]);
                for (let i = 0; i < arr.length; i++) {
                    row[`${k}_label_${i + 1}`] = arr[i].label;
                    row[`${k}_percentage_${i + 1}`] = arr[i].percentage;
                }
            }
        });

        return row;
    }
}

export class OrderAmountsData {
    orderId: string;
    orderDutyAmount: number;
    orderVatAmount: number;
    orderSpecialTaxesAmount: number;
    orderTotalAmount: number;
    orderCurrency: string;
}

export class ProductQuantityData {
    productId: string;
    productQuantity: number;
    productPrice: number;
    productCurrency: string;
}

export class AllUserCustomers {
    data: MonitoringCustomerCustomer[];
    nextIndex: any;
}

export class MonitoringCustomerCustomer {
    mail: string;
    user_company_name: string;
    customerMail: string;
    website: string;
    firstname: string;
    lastname: string;
    gender: string;
    age: number;
    phoneNumber: string;
    zipCode: string;
    address: string;
    city: string;
    state: string;
    country: string;
    companyName: string;
    companyId: string;
    companyTaxId: string;
    pro: boolean;
    paidOrders: OrderAmountsData[];
    awaitingOrders: OrderAmountsData[];
    canceledOrders: OrderAmountsData[];
    buyedProducts: ProductQuantityData[];

    toRow() {
        return {
            mail: this.mail,
            user_company_name: this.user_company_name,
            customerMail: this.customerMail,
            website: this.website,
            firstname: this.firstname,
            lastname: this.lastname,
            gender: this.gender,
            age: this.age,
            phoneNumber: this.phoneNumber,
            zipCode: this.zipCode,
            address: this.address,
            city: this.city,
            state: this.state,
            country: this.country,
            companyName: this.companyName,
            companyId: this.companyId,
            companyTaxId: this.companyTaxId,
            pro: this.pro,
            paidOrders: this.paidOrders.length,
            awaitingOrders: this.awaitingOrders.length,
            canceledOrders: this.canceledOrders.length,
            buyedProducts: this.buyedProducts.length
        };
    }
}

export class UserDailyRequestsContent {
    mail: string;
    date: number; // timestamp representing a date as "2021-12-22"
    requests_done: { [key: string]: { count: number; } };
}

export class UserMonitoringContent extends ItemsCounts {
    mail: string;
}

export class CreatedUsersBetweenDates {
    date_start: number;
    date_end: number;
    created_users_number: number;
    users: { [key: string]: number; };
}

export class MonitoringUsersWholeData {
    userContent: UserSettings;
    plan: string;
    plan_type: string;
    technical_support: number; // ECOMMERCE / API remaining technical support hours
    extra_children_allowed_api: number; // API extra children allowed, set only by a super admin
    user_status: string;
    credits: {
        saas: {
            remainingCredits: number;
            maxCredits: number;
            extraCredits: number;
        },
        api_pre_payment: {
            credits: { [key: string]: { remaining: number; max: number; } };
        }
    }; // credits info for SAAS and API PP plans
    orders: { count: number; turnover: number; currency: string; }; // orders received between 2 timestamps
    dailyRequests: UserDailyRequestsContent[]; // daily requests between 2 timestamps
    requestsPeriodCountGroupedByHttpCode: RequestsOnePeriodGroupedByHttpCode; // sums of daily requests count grouped by httpCode
    monitoringContent: UserMonitoringContent;
    active_countries: number;
    inactive_countries: number;
    registration_date: number;

    toRow() {
        return {
            mail: this.userContent ? this.userContent.mail : '',
            firstname: this.userContent && this.userContent.account ? this.userContent.account.firstname : '',
            lastname: this.userContent && this.userContent.account ? this.userContent.account.lastname : '',
            plan: this.plan,
            plan_type: this.plan_type,
            technical_support: this.technical_support,
            extra_children_allowed_api: this.extra_children_allowed_api,
            remaining_credits_saas: this.credits && this.credits.saas ? this.credits.saas.remainingCredits : null,
            max_credits_saas: this.credits && this.credits.saas ? this.credits.saas.remainingCredits : null,
            extra_credits: this.credits && this.credits.saas ? this.credits.saas.extraCredits : null,
            api_setup_plan_done: this.userContent ? this.userContent.api_setup_plan_done : false,
            can_change_plan_type: this.userContent ? this.userContent.can_change_plan_type : false,
            stripe_currency: this.userContent ? this.userContent.stripe_currency : '',
            user_status: this.user_status,
            registration_date: this.registration_date ? new Date(this.registration_date).toISOString().substring(0, 10) : '',
            apiKey: this.userContent ? this.userContent.apiKey : '',
            storeID: this.userContent ? this.userContent.storeID : '',
            phone_number: this.userContent && this.userContent.account ? this.userContent.account.phone_number : '',
            siret: this.userContent && this.userContent.account ? this.userContent.account.siret : '',
            company_id: this.userContent && this.userContent.account ? this.userContent.account.company_id : '',
            company_tax_id: this.userContent && this.userContent.account ? this.userContent.account.company_tax_id : '',
            company_name: this.userContent && this.userContent.account ? this.userContent.account.company_name : '',
            domain_name: this.userContent && this.userContent.account ? this.userContent.account.domain_name : '',
            integration_method: this.userContent && this.userContent.account ? this.userContent.account.integration_method : '',
            pro: this.userContent && this.userContent.account ? this.userContent.account.pro : null,
            age: this.userContent && this.userContent.account ? this.userContent.account.age : null,
            gender: this.userContent && this.userContent.account ? this.userContent.account.gender : '',
            expedition_country: this.userContent && this.userContent.expedition ? this.userContent.expedition.country : '',
            expedition_state: this.userContent && this.userContent.expedition ? this.userContent.expedition.state : '',
            expedition_city: this.userContent && this.userContent.expedition ? this.userContent.expedition.city : '',
            expedition_postal_code: this.userContent && this.userContent.expedition ? this.userContent.expedition.postal_code : '',
            expedition_address: this.userContent && this.userContent.expedition ? this.userContent.expedition.address : '',
            default_product_category: this.userContent && this.userContent.customs_classification ? this.userContent.customs_classification.default_product_category : '',
            default_hs_code: this.userContent && this.userContent.customs_classification ? this.userContent.customs_classification.default_hs_code : '',
            default_product_description: this.userContent && this.userContent.customs_classification ? this.userContent.customs_classification.default_product_description : '',
            default_origin_country: this.userContent && this.userContent.customs_classification ? this.userContent.customs_classification.default_origin_country : '',
            tax_included: this.userContent && this.userContent.duty_calculation ? this.userContent.duty_calculation.tax_included : null,
            extra_fees: this.userContent && this.userContent.duty_calculation ? this.userContent.duty_calculation.extra_fees : null,
            signature: this.userContent ? this.userContent.signature : '',
            seller_logo: this.userContent ? this.userContent.seller_logo : '',
            customer_service_info: this.userContent ? this.userContent.customer_service_info : '',
            reverse_logistic_terms: this.userContent ? this.userContent.reverse_logistic_terms : '',
            other_seller_informations: this.userContent ? this.userContent.other_seller_informations : '',
            brand_name: this.userContent ? this.userContent.brand_name : '',
            air_port_of_lading: this.userContent ? this.userContent.air_port_of_lading : '',
            incoterm: this.userContent ? this.userContent.incoterm : '',
            term_of_sale: this.userContent ? this.userContent.term_of_sale : '',
            orders_count: this.orders.count,
            orders_turnover: this.orders.turnover,
            orders_currency: this.orders.currency,
            active_countries: this.active_countries,
            inactive_countries: this.inactive_countries,
            monitoring_customers_count: this.monitoringContent.customers_count,
            monitoring_products_count: this.monitoringContent.products_count,
            monitoring_orders_count: this.monitoringContent.orders_count,
            monitoring_categories_count: this.monitoringContent.categories_count,
            fedex_account_number: this.userContent && this.userContent.carriers ? this.userContent.carriers.fedex.account_number : '',
            fedex_counter_number: this.userContent && this.userContent.carriers ? this.userContent.carriers.fedex.meter_number : '',
            fedex_auth_key: this.userContent && this.userContent.carriers ? this.userContent.carriers.fedex.authentication_key : '',
            fedex_password: this.userContent && this.userContent.carriers ? this.userContent.carriers.fedex.api_password : '',
            dhl_shop_id: this.userContent && this.userContent.carriers ? this.userContent.carriers.dhl.online_store_id : '',
            dhl_password: this.userContent && this.userContent.carriers ? this.userContent.carriers.dhl.production_password : '',
            dhl_account_number: this.userContent && this.userContent.carriers ? this.userContent.carriers.dhl.payment_account_number : '',
            ups_account_number: this.userContent && this.userContent.carriers ? this.userContent.carriers.ups.payment_account_number : ''
        };
    }
}

export class RequestsPeriodCountValue {
    count: number;
    requestCount: { [key: string]: number }; // each endpoint associated to the number of call
}
export class RequestsPerPeriodResponse {
    httpCode: number;
    day: RequestsPeriodCountValue;
    month: RequestsPeriodCountValue;
    year: RequestsPeriodCountValue;
}
export class RequestsOnePeriodGroupedByHttpCode {
    '200': RequestsPeriodCountValue;
    '300': RequestsPeriodCountValue;
    '400': RequestsPeriodCountValue;
    '500': RequestsPeriodCountValue;
}
// each key is an email
export class RequestsNumberPerUser {
    [key: string]: number;
}
// daily requests done per user, each key is an endpoint
export class DailyRequestsPerUserGroupedByHttpCode {
    '200'?: { [key: string]: RequestsNumberPerUser };
    '300'?: { [key: string]: RequestsNumberPerUser };
    '400'?: { [key: string]: RequestsNumberPerUser };
    '500'?: { [key: string]: RequestsNumberPerUser };
}
export class DailyRequestsPerUserAndOrdersResponse {
    requests: DailyRequestsPerUserGroupedByHttpCode;
    periodOrders: number;
}

export class UserLastConsecutiveRequestErrorContent {
    endpoint: string;
    status: number;
    timestamp: number;
}
export class UserLastConsecutiveRequestsError {
    mail: string;
    lastConsecutiveErrorCount: number // up to 10
    lastConsecutiveRequestsWithErrors: UserLastConsecutiveRequestErrorContent[];
}
