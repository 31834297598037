import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../components/message/message.service';
import { User } from '../../../../models/User';
import { UserService } from '../../../services/user.service';
import { Utils } from '../../../utils/utils';
import { CsvService } from '../../../services/csv.service';
import { pgTabComponent } from '../../../components/tabs/tab.component';
import { UploadFile } from '../../../components/upload/interface';
import { pgUploadComponent } from '../../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AiClassifyEnum, AiClassifyIdentification, AiClassifyRequest, AiClassifyRequests, WeightDimensionsResult } from '../../../../models/HsCodeFinder';
import { Builder } from 'builder-pattern';
import { ActivatedRoute } from '@angular/router';
import { FormatCsv } from '../../../../models/Managers';
import { ApiDataService } from '../../../services/api-data.service';
import { Subject } from 'rxjs';
import { CsvFieldToSet } from '../../../components/csv-mapper/csv-mapper.component';

enum HS_STEP_ENUM {
    SEARCH = 1,
    RESULTS = 2,
}

@Component({
    selector: 'app-weight-dimensions-finder',
    templateUrl: './weight-dimensions.component.html',
    styleUrls: ['./weight-dimensions.component.scss'],
})
export class WeightDimensionsComponent implements OnInit {
    HS_STEP_ENUM = HS_STEP_ENUM;
    @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;
    @ViewChild('pop_hs_code', { static: false }) pop_hs_code;
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    countries: Array<{ value: string, label: string, iso2: string }> = [];
    form: FormGroup;
    formIsInvalid = false;
    current_step: number;
    lang: string;
    user: User;
    request: AiClassifyRequest;
    result: WeightDimensionsResult;
    expectedCsvContent: FormatCsv;
    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    types = [];

    // notifications messages
    messages: any;

    to = null;
    from = null;

    displayCsvMapper: Subject<void> = new Subject<void>();
    csvModelFields: CsvFieldToSet[] = [];
    externalCsvFields: string[] = [];

    private readonly requestUrl: string;
    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private _notification: MessageService,
        private userService: UserService,
        private csvService: CsvService,
        private translate: TranslateService,
        private apiDataService: ApiDataService
    ) {
        this.requestUrl = `https://api.${environment.baseUrl}/v3/taxsrv/aiclassify`;
        this.current_step = HS_STEP_ENUM.SEARCH;
        this.initForm();
    }
    async ngOnInit() {
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        await this.initUserPrefs();
        this.initTranslation();
    }

    initForm() {
        this.form = new FormGroup({
            product: new FormGroup({
                identification: new FormGroup({
                    value: new FormControl('', [Validators.required]),
                    type: new FormControl('TEXT', [Validators.required]),
                }),
            }),
            from_country: new FormControl(this.from, [Validators.required]),
            to_country: new FormControl(this.to, [Validators.required]),
            searchType: new FormControl('TEXTUEL'),
        });
    }

    formIsValid(): boolean {
        const type = ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value;
        const value = ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['value'].value;
        if (
            type && value.length > 0 && (
                (type === 'HSCODE' && this.from && this.to) ||
                (type !== 'HSCODE' && this.to)
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    initTranslation() {
        this.translate.get('messages').toPromise().then((messages) => {
            this.updateLangMessages(messages);
        });
        this.translate.get('aiClassify').toPromise().then((aiClassify) => {
            this.types = aiClassify.types.filter(type => type.value === 'TEXT');
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.updateLangMessages(messages);
            this.form.get('to_country').updateValueAndValidity();
            this.types = event.translations['aiClassify'].types.filter(type => type.value === 'TEXT');
            this.setCountries();
        });
    }
    updateLangMessages(messages) {
        this.unknown_error = messages.notification.unknown_error;
        this.emptyCSV = messages.notification.unknown_error;
        this.wrongCSV = messages.notification.unknown_error;
        this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
        this.messages = messages.notification.aiClassify;
    }
    private async initUserPrefs() {
        let user = this.userService.getUser();
        if (!user) {
            user = await this.authService.reconnect();
        }
        this.user = user;
    }

    async copyToClipboard() {
        const data = `${JSON.stringify(this.result.result)}`;
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', data);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.pop_hs_code.show();
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.pop_hs_code.hide();
    }

    changeType(event) {
        if (event) {
            const type = ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value;
        }
    }

    getValueLabelTranslation(): string {
        if (this.isText()) {
            return 'textLabel';
        }
        if (this.isHsCode()) {
            return 'hsCodeLabel';
        }
        if (this.isSku()) {
            return 'skuLabel';
        }
        if (this.isCategory()) {
            return 'categoryLabel';
        }
        return 'textLabel';
    }
    getRightTranslationPlaceholder(): string {
        if (this.isText()) {
            return 'typeDescriptionPlaceHolder';
        }
        if (this.isHsCode()) {
            return 'typeHscodePlaceHolder';
        }
        if (this.isSku()) {
            return 'typeSkuPlaceHolder';
        }
        if (this.isCategory()) {
            return 'typeCategoryPlaceHolder';
        }
        return 'typeDescriptionPlaceHolder';
    }
    isText() {
        return ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value === AiClassifyEnum.TEXT;
    }
    isHsCode() {
        return ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value === AiClassifyEnum.HSCODE;
    }
    isSku() {
        return ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value === AiClassifyEnum.SKU;
    }
    isCategory() {
        return ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value === AiClassifyEnum.CATEGORY;
    }

    switch() {
        const tempFrom = this.from;
        const tempTo = this.to;
        this.from = tempTo;
        this.to = tempFrom;
    }

    getNested(nested: string) {
        return this.form.get(nested);
    }

    async launchRequest(): Promise<void> {
        if (! this.formIsValid()) {
            this.formIsInvalid = true;
            return;
        }
        this.request = this.form.value as AiClassifyRequest;
        this.request.lang = this.lang;
        this.result = await this.callRequest(this.request) as WeightDimensionsResult;
        if (this.result) {
            if (this.child) {
                this.child.setLabel(1);
            }
            this.current_step = HS_STEP_ENUM.RESULTS;
        }
    }

    async callRequest(request: AiClassifyRequest | AiClassifyRequests): Promise<WeightDimensionsResult | WeightDimensionsResult[]> {
        try {
            const res = await this.http.post(this.requestUrl, request).toPromise();
            if (Array.isArray(res)) {
                return res as WeightDimensionsResult[];
            } else {
                return res as WeightDimensionsResult;
            }
        } catch (error) {
            console.error(error);
            const transiteoError = this._notification.buildTransiteoErrorMessage(error.error, this.unknown_error);
            this._notification.displayMessage(transiteoError, 'danger');
            return null;
        }
    }

    get f() {
        return this.form.controls;
    }

    generateCsv() {
        const request = this.form.value as AiClassifyRequest;
        const csvResponse = this.buildCsv(request, this.result);
        const csvFileName = `weightDimensions_${request.product.identification.value}_${request.to_country}`;
        return this.csvService.downloadFile(csvResponse, csvFileName);
    }
    buildCsv(request: AiClassifyRequest, result: WeightDimensionsResult) {
        const mappedUserCsv = this.expectedCsvContent ? this.expectedCsvContent.mappedFields : null;
        if (mappedUserCsv) {
            let headerProduct = 'name';
            let headerToCountry = 'to_country';
            let found = mappedUserCsv.find(m => m.id === 'product' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerProduct = found.externalField;
            }
            found = mappedUserCsv.find(m => m.id === 'to_country' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerToCountry = found.externalField;
            }
            return {
                [headerProduct]: request.product.identification.value,
                [headerToCountry]: request.to_country,
                weight_min: result.result.weight.min,
                weight_max: result.result.weight.max,
                weight_median: result.result.weight.median,
                weight_unit: result.result.weight.unit,
                width_min: result.result.width.min,
                width_max: result.result.width.max,
                width_median: result.result.width.median,
                width_unit: result.result.width.unit,
                height_min: result.result.height.min,
                height_max: result.result.height.max,
                height_median: result.result.height.median,
                height_unit: result.result.height.unit,
                length_min: result.result.length.min,
                length_max: result.result.length.max,
                length_median: result.result.length.median,
                length_unit: result.result.length.unit,
                timestamp: result.timestamp
            };
        }
        return {
            name: request.product.identification.value,
            country: request.to_country,
            weight_min: result.result.weight.min,
            weight_max: result.result.weight.max,
            weight_median: result.result.weight.median,
            weight_unit: result.result.weight.unit,
            width_min: result.result.width.min,
            width_max: result.result.width.max,
            width_median: result.result.width.median,
            width_unit: result.result.width.unit,
            height_min: result.result.height.min,
            height_max: result.result.height.max,
            height_median: result.result.height.median,
            height_unit: result.result.height.unit,
            length_min: result.result.length.min,
            length_max: result.result.length.max,
            length_median: result.result.length.median,
            length_unit: result.result.length.unit,
            timestamp: result.timestamp
        };
    }
    findCountryLabel(iso: string) {
        const findCountry = iso && iso.length === 3 ? this.countries.find((country) => country.value === iso) : null;
        return findCountry ? findCountry.label : iso;
    }

    newSearch() {
        this.current_step = HS_STEP_ENUM.SEARCH;
        if (this.child) {
            this.child.setLabel(0);
        }
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            // get expected csv Format
            this.expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = this.expectedCsvContent.toExpectedCsvContent();
            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
                // remove first line csv;
                const headerLine = csvRecordsArray.shift();
                const headerLineLength = headerLine.split(';').length;

                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    // not same columns number
                    if (headerLineLength !== line.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.messages.country} : "${line}"`, 'danger');
                        return true;
                    }

                    return false;
                });
                if (wrongLines.length > 0) {
                    this.removeFile(file);
                    return;
                }

                // not right columns names
                if (
                    headerLineLength < 2 ||
                    headerLineLength > 3 ||
                    headerLineLength === 2 && ! this.expectedCsvContent.headerContains(['product', 'to_country']) ||
                    headerLineLength === 3 && ! this.expectedCsvContent.headerContains(['product', 'to_country', 'type'])
                ) {
                    this.externalCsvFields = this.expectedCsvContent.getHeaders();
                    this.csvModelFields = this.buildCsvModelFields();
                    this.onDisplayCsvMapper();
                    /*this._notification.displayMessage(`Line 0 : ${this.wrongCSV} : "${headerLine}"`, 'danger');
                    this.removeFile(file);*/
                    return;
                }
            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }
    buildCsvModelFields(): CsvFieldToSet[] {
        const modelFields: CsvFieldToSet[] = [];
        const expectedCsvFields = {
            type: {
                isRequired: false
            },
            product: {
                isRequired: true
            },
            to_country: {
                isRequired: true
            }
        };
        const keys = Object.keys(expectedCsvFields);

        keys.forEach(f => {
            modelFields.push(
                Builder(CsvFieldToSet)
                .id(f)
                .externalField('')
                .isRequired(expectedCsvFields[f].isRequired)
                .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                .build()
            );
        });
        return modelFields;
    }
    onDisplayCsvMapper() {
        this.displayCsvMapper.next();
    }
    onCsvExternalMapped(mappedFields: CsvFieldToSet[]) {
        if (! mappedFields) {
            this.expectedCsvContent = null;
        } else {
            this.expectedCsvContent.modifyHeadersWithMappedFields(mappedFields);
        }
    }
    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.expectedCsvContent = null;
        return false;
    }
    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.expectedCsvContent = null;
    }
    async searchFromCSV() {
        const availableTypes = this.types.map(t => t.value);
        const multipleRequests = Builder(AiClassifyRequests).requests([]).build();
        const rows = this.expectedCsvContent.getContent();
        const headers = rows.shift().split(';');
        let lineNumber = 2;
        rows.forEach((record) => {
            const line = record.split(';');
            let rowIsValid = true;
            // if the country is wrong, the line is not valid then
            const type = headers.findIndex(h => h === 'type') >= 0 ? line[headers.findIndex(h => h === 'type')] : null;
            const product = line[headers.findIndex(h => h === 'product')];
            const toCountry = line[headers.findIndex(h => h === 'to_country')];
            // check type if defined
            if (type && ! availableTypes.includes(type)) {
                rowIsValid = false;
                this._notification.displayMessage(`Line ${lineNumber} : type ${this.messages.mustBeOneOf}: ${availableTypes.toString()}`, 'danger');
            }
            // check to_country
            if (this.countries.findIndex(c => c.value === toCountry || c.iso2 === toCountry) < 0) {
                rowIsValid = false;
                this._notification.displayMessage(`Line ${lineNumber} : to_country ${this.messages.country}`, 'danger');
            }
            // check product
            if (product.length === 0) {
                rowIsValid = false;
                this._notification.displayMessage(`Line ${lineNumber} : product ${this.messages.isMandatory}`, 'danger');
            }

            if (rowIsValid) {
                const identification = Builder(AiClassifyIdentification)
                                    .type(AiClassifyEnum.TEXT)
                                    .value(product)
                                    .build();
                const request = Builder(AiClassifyRequest)
                                .lang(this.lang)
                                .product({ identification: identification })
                                .to_country(toCountry)
                                .build();
                multipleRequests.requests.push(request);
            }
            lineNumber += 1;
        });
        const results = await this.callRequest(multipleRequests) as WeightDimensionsResult[];

        if (results) {
            let csvBuilder = [];
            for (let i = 0; i < multipleRequests.requests.length; i++) {
                csvBuilder.push(this.buildCsv(multipleRequests.requests[i], results[i]));
            }
            csvBuilder = this.expectedCsvContent.mergeOriginalCsvWithResponse(csvBuilder);
            return this.csvService.downloadFile(csvBuilder, `weightDimensions_search_${new Date().getTime()}`);
        }
    }

    isActualStep(step: number) {
        return step === this.current_step;
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }
}
