import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { ALL_PLAN_TYPE_ENUM, INCOTERM_ENUM, PLAN_ENUM, PLAN_V2_ENUM, User, UserSettings, USER_STATUT_ENUM } from '../../../../models/User';
import { UserService } from '../../../services/user.service';
import { CreatedUsersBetweenDates, MonitoringUsersWholeData, RequestsOnePeriodGroupedByHttpCode, RequestsPeriodCountValue, UserDailyRequestsContent } from '../../../../models/Monitoring';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { Utils } from '../../../utils/utils';
const us_states = require('../../../../../assets/data/US_states.json');

@Component({
    selector: 'app-monitoring-users',
    templateUrl: './monitoring-users.component.html',
    styleUrls: ['./monitoring-users.component.scss'],
})
export class MonitoringUsersComponent implements OnInit, OnDestroy {
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;

    unknown_error: string;
    updateUserMessage: string;

    genders: {value: string, label: string}[];

    countries: Array<{ value: string, label: string, iso2: string }> = [];
    districts: Array<{ value: string, label: string }> = [];
    categories: Array<{ value: string, label: string }> = [];

    booleanChoices: {value: string, label: string}[];
    incoterms: {value: string, label: string}[];
    allCurrencies: {value: string, label: string, disabled?: boolean}[];
    currency: Array<{value: string, label: string, disabled?: boolean}>;
    activityOptions = [];
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates];

    userForm: FormGroup;
    formIsInvalid = false;
    modalRef: BsModalRef;
    closingModal = true;
    private subscription: Subscription;

    // table rows
    rows: any[] = [];

    selectedRows: any[] = [];
    columnsData: string[] = [];

    filter = '';
    // dates fields -- filter orders & daily requests
    selectedStartDate: Date;
    selectedEndDate: Date;
    // dates fields -- filter users creation dates & mention "there are X users created between dates"
    selectedStartDateFilterUsers: Date;
    selectedEndDateFilterUsers: Date;
    filterUsersByDate = false;

    usedStartDateLastUpdate: Date;
    usedEndDateLastUpdate: Date;
    usedStartDateLastUpdateDailyRequests: Date;
    usedEndDateLastUpdateDailyRequests: Date;
    createdUsersBetweenDates: CreatedUsersBetweenDates;

    // expand row
    rowsDetailsDailyRequests: UserDailyRequestsContent[][];
    rowsDetailsApiPrePaymentCredits: { [key: string]: { [key: string]: { remaining: number; max: number; } } };
    rowsDetailsRequestsPeriodCount: { [key: string]: RequestsOnePeriodGroupedByHttpCode };
    // expanded rows contents
    rowsDetailsContent: { [key: string]: { [key: string]: any }[] }[];
    rowsDetailsColumns: string[][];
    rowsDetailsColumnsLabels: { [key: string]: string }[];

    // api PP available endpoints
    availableEndpoints: { id: string; endpoint: string; }[] = [];
    coupledConsumptions: string[][] = [];

    columnsLabels: any;

    private user: User;
    isRetrievingData = false;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private userService: UserService,
        private modalService: BsModalService
    ) {
        this.incoterms = Object.keys(INCOTERM_ENUM).map(val => ({label: val, value: val}));
        this.createdUsersBetweenDates = Builder(CreatedUsersBetweenDates)
                                        .date_start(0)
                                        .date_end(0)
                                        .created_users_number(0)
                                        .users({})
                                        .build();
        // requests and sales details
        this.rowsDetailsDailyRequests = [];
        this.rowsDetailsApiPrePaymentCredits = {};
        this.rowsDetailsRequestsPeriodCount = {};
        this.rowsDetailsContent = [{}, {}, {}];
        this.rowsDetailsColumns = [[], [], []];
        this.rowsDetailsColumnsLabels = [{}, {}, {}];
    }

    async ngOnInit() {
        this.user = await this.userService.getUserPromise();
        this.setColumnsOrder();
        this.initRowsDetailsColumns();
        this.initTranslation();
        this.initDates();
        this.createdUsersBetweenDates = (await this.getCreatedUsersBetweenDates()).accounts_created;
        this.retrieveData();
    }

    ngOnDestroy() {
        this.unsubscribeSubscription();
    }

    initRowsDetailsColumns() {
        this.rowsDetailsColumns[0] = [
            'date_start',
            'date_end',
            'endpoint',
            'count',
            'remaining',
            'max'
        ];
        this.rowsDetailsColumns[1] = [
            'orders_count',
            'orders_turnover',
            'orders_currency'
        ];
        this.rowsDetailsColumns[2] = [
            'requests_done',
            'requests_done_status_200',
            'requests_done_status_300',
            'requests_done_status_400',
            'requests_done_status_500',
            'total_status_200',
            'total_status_300',
            'total_status_400',
            'total_status_500'
        ];
    }

    get fedex(): FormGroup {
        return this.userForm.get('fedex') as FormGroup;
    }
    get dhl(): FormGroup {
        return this.userForm.get('dhl') as FormGroup;
    }
    get ups(): FormGroup {
        return this.userForm.get('ups') as FormGroup;
    }

    initDates() {
        this.selectedStartDate = new Date();

        this.selectedStartDate.setHours(0);
        this.selectedStartDate.setMinutes(0);
        this.selectedStartDate.setSeconds(0);
        this.selectedStartDate.setMilliseconds(0);

        this.selectedEndDate = new Date(this.selectedStartDate.getTime());
        this.selectedStartDateFilterUsers = new Date(this.selectedStartDate.getTime());
        this.selectedEndDateFilterUsers = new Date(this.selectedStartDate.getTime());
        this.usedStartDateLastUpdateDailyRequests = new Date(this.selectedStartDate.getTime());
        this.usedEndDateLastUpdateDailyRequests = new Date(this.selectedStartDate.getTime());

        this.selectedStartDateFilterUsers.setDate(1);
        this.usedStartDateLastUpdate = new Date(this.selectedStartDateFilterUsers.getTime());
        this.usedEndDateLastUpdate = new Date(this.selectedEndDateFilterUsers.getTime());
    }

    // shall we display the table?
    emptyRows(): boolean {
        if (this.rows === null || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    async retrieveData() {
        if (! this.user || ! this.user.superAdminUsersMinimalData || this.rows.length === this.user.superAdminUsersMinimalData.length) {
            return;
        }
        this.isRetrievingData = true;
        const users: MonitoringUsersWholeData[] = await this.getUsersWholeData();
        if (users !== null) {
            users.forEach((d) => {
                const wholeData = this.buildData(d);
                this.rowsDetailsDailyRequests.push(d.dailyRequests);
                if (wholeData.userContent && wholeData.userContent.mail) {
                    this.rowsDetailsApiPrePaymentCredits[wholeData.userContent.mail] = wholeData.credits && wholeData.credits.api_pre_payment
                                                                                    ? wholeData.credits.api_pre_payment.credits
                                                                                    : null;
                    this.rowsDetailsRequestsPeriodCount[wholeData.userContent.mail] = wholeData.requestsPeriodCountGroupedByHttpCode;
                }
                this.rows.push(wholeData.toRow());
                if (d.userContent) {
                    this.setRowsDetailsDailyRequests(d.userContent.mail);
                    this.setRowsDetailsSalesData(d.userContent.mail);
                    this.setRowsDetailsRequestsPeriodCountByHttpCode(d.userContent.mail);
                }
            });
            this.rows = [...this.rows];
        }
        this.isRetrievingData = false;
    }

    async onValidClick() {
        this.rows = [];
        this.rowsDetailsDailyRequests = [];
        this.rowsDetailsApiPrePaymentCredits = {};
        this.rowsDetailsRequestsPeriodCount = {};
        this.rowsDetailsContent = [{}, {}, {}];
        this.createdUsersBetweenDates = (await this.getCreatedUsersBetweenDates()).accounts_created;
        this.retrieveData();
        this.usedStartDateLastUpdateDailyRequests = new Date(this.selectedStartDate.getTime());
        this.usedEndDateLastUpdateDailyRequests = new Date(this.selectedEndDate.getTime());
        this.usedStartDateLastUpdate = new Date(this.selectedStartDateFilterUsers.getTime());
        this.usedEndDateLastUpdate = new Date(this.selectedEndDateFilterUsers.getTime());
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.updateUserMessage = messages.notification.updateUser;
            this.unknown_error = messages.notification.unknown_error;
            this.translate.get('monitoring').toPromise().then(monitoring => {
                this.columnsLabels = monitoring.users.tableColumns;

                this.rowsDetailsColumnsLabels[0].date_start = monitoring.users.form.date_start;
                this.rowsDetailsColumnsLabels[0].date_end = monitoring.users.form.date_end;
                this.rowsDetailsColumnsLabels[0].endpoint = monitoring.users.endpoint;
                this.rowsDetailsColumnsLabels[0].count = monitoring.users.count;
                this.rowsDetailsColumnsLabels[0].remaining = monitoring.users.remaining;
                this.rowsDetailsColumnsLabels[0].max = monitoring.users.max;

                this.rowsDetailsColumnsLabels[1].orders_count = monitoring.users.tableColumns.orders_count;
                this.rowsDetailsColumnsLabels[1].orders_turnover = monitoring.users.tableColumns.orders_turnover;
                this.rowsDetailsColumnsLabels[1].orders_currency = monitoring.users.tableColumns.orders_currency;

                this.rowsDetailsColumnsLabels[2].requests_done = monitoring.users.requestsPeriodCount.requests_done;
                this.rowsDetailsColumnsLabels[2].requests_done_status_200 = monitoring.users.requestsPeriodCount.requests_done_status_200;
                this.rowsDetailsColumnsLabels[2].requests_done_status_300 = monitoring.users.requestsPeriodCount.requests_done_status_300;
                this.rowsDetailsColumnsLabels[2].requests_done_status_400 = monitoring.users.requestsPeriodCount.requests_done_status_400;
                this.rowsDetailsColumnsLabels[2].requests_done_status_500 = monitoring.users.requestsPeriodCount.requests_done_status_500;
                this.rowsDetailsColumnsLabels[2].total_status_200 = monitoring.users.requestsPeriodCount.total_status_200;
                this.rowsDetailsColumnsLabels[2].total_status_300 = monitoring.users.requestsPeriodCount.total_status_300;
                this.rowsDetailsColumnsLabels[2].total_status_400 = monitoring.users.requestsPeriodCount.total_status_400;
                this.rowsDetailsColumnsLabels[2].total_status_500 = monitoring.users.requestsPeriodCount.total_status_500;
            });
        });
        this.translate.get('account.customs_categories').toPromise().then((categories) => {
            const keys = Object.keys(categories);
            this.categories = keys.map(catKey => {
                return {
                    value: catKey,
                    label: categories[catKey]
                };
            });
        });
        this.translate.get('profile.boolean_choice').toPromise().then((boolChoice) => {
            this.booleanChoices = boolChoice;
        });
        this.translate.get('genders').toPromise().then((genders) => {
            this.genders = genders;
        });
        this.translate.get('countries').toPromise().then((countries) => {
            this.countries = countries.sort(Utils.compareByLabel);
        });
        this.translate.get('currency_name').toPromise().then((currencies) => {
            this.allCurrencies = currencies.sort(Utils.compareByLabel);
        });
        this.translate.get('productCategories').toPromise().then((productCategories) => {
            this.activityOptions = productCategories;
        });
        this.coupledConsumptions = this.translate.instant('plan.formula4.coupledConsumptions');
        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.updateUserMessage = messages.notification.updateUser;
            this.columnsLabels = event.translations['monitoring'].users.tableColumns;
            const keys = Object.keys(event.translations.account.customs_categories);
            this.categories = keys.map(catKey => {
                return {
                    value: catKey,
                    label: event.translations.account.customs_categories[catKey]
                };
            });
            this.booleanChoices = event.translations.boolean_choice;
            this.countries = event.translations['countries'] ? event.translations['countries'].sort(Utils.compareByLabel) : this.countries;
            this.allCurrencies = event.translations['currency_name'] ? event.translations['currency_name'].sort(Utils.compareByLabel) : this.allCurrencies;
            this.genders = event.translations['genders'] ? event.translations['genders'] : this.genders;
            this.activityOptions = event.translations['productCategories'] ? event.translations['productCategories'] : this.activityOptions;

            this.columnsLabels = event.translations['monitoring'].users.tableColumns;

            this.rowsDetailsColumnsLabels[0].date_start = event.translations['monitoring'].users.date_start;
            this.rowsDetailsColumnsLabels[0].date_end = event.translations['monitoring'].users.date_end;
            this.rowsDetailsColumnsLabels[0].endpoint = event.translations['monitoring'].users.endpoint;
            this.rowsDetailsColumnsLabels[0].count = event.translations['monitoring'].users.count;
            this.rowsDetailsColumnsLabels[0].remaining = event.translations['monitoring'].users.remaining;
            this.rowsDetailsColumnsLabels[0].max = event.translations['monitoring'].users.max;

            this.rowsDetailsColumnsLabels[1].orders_count = event.translations['monitoring'].users.tableColumns.orders_count;
            this.rowsDetailsColumnsLabels[1].orders_turnover = event.translations['monitoring'].users.tableColumns.orders_turnover;
            this.rowsDetailsColumnsLabels[1].orders_currency = event.translations['monitoring'].users.tableColumns.orders_currency;

            this.rowsDetailsColumnsLabels[2].requests_done = event.translations['monitoring'].users.requestsPeriodCount.requests_done;
            this.rowsDetailsColumnsLabels[2].requests_done_status_200 = event.translations['monitoring'].users.requestsPeriodCount.requests_done_status_200;
            this.rowsDetailsColumnsLabels[2].requests_done_status_300 = event.translations['monitoring'].users.requestsPeriodCount.requests_done_status_300;
            this.rowsDetailsColumnsLabels[2].requests_done_status_400 = event.translations['monitoring'].users.requestsPeriodCount.requests_done_status_400;
            this.rowsDetailsColumnsLabels[2].requests_done_status_500 = event.translations['monitoring'].users.requestsPeriodCount.requests_done_status_500;
            this.rowsDetailsColumnsLabels[2].total_status_200 = event.translations['monitoring'].users.requestsPeriodCount.total_status_200;
            this.rowsDetailsColumnsLabels[2].total_status_300 = event.translations['monitoring'].users.requestsPeriodCount.total_status_300;
            this.rowsDetailsColumnsLabels[2].total_status_400 = event.translations['monitoring'].users.requestsPeriodCount.total_status_400;
            this.rowsDetailsColumnsLabels[2].total_status_500 = event.translations['monitoring'].users.requestsPeriodCount.total_status_500;
        });
    }

    filterKeyPositiveNumber(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    filterKey(event) {
        const lastValue = this.userForm.get('extra_fees').value;
        return (!isNaN(Number(event.key)) && ((lastValue !== null && lastValue >= 0 && lastValue < 1) || (lastValue === null && parseInt(event.key) <= 1))) ||
                (event.key === '.' && lastValue !== null && !isNaN(Number(lastValue)) && !(lastValue).toString().includes('.'));
    }

    filterKeyNumber(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return '';
        }
    }

    onScrollToEnd() {
        if (!this.isRetrievingData && this.user && this.user.superAdminUsersMinimalData && this.rows.length !== this.getElligibleMails().length) {
            this.retrieveData();
        }
    }

    async setDistricts(countryIso3: string) {
        this.userForm.get('state').reset();
        if (countryIso3 === 'USA') {
            this.districts = us_states;
        } else {
            this.districts = (
                await this.http.get(`https://api.${environment.baseUrl}/v2/data/districts?iso3=${countryIso3}`).toPromise()
            ) as { value: string, label: string }[];
        }

        // sort the list
        this.districts.sort((a, b) => {
            return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
        });
    }

    buildData(user: MonitoringUsersWholeData): MonitoringUsersWholeData {
        return Builder(MonitoringUsersWholeData)
            .userContent(user.userContent)
            .plan(user.plan)
            .plan_type(user.plan_type)
            .technical_support(user.technical_support)
            .extra_children_allowed_api(user.extra_children_allowed_api)
            .credits(user.credits)
            .registration_date(user.registration_date)
            .user_status(user.user_status)
            .orders(user.orders)
            .dailyRequests(user.dailyRequests)
            .requestsPeriodCountGroupedByHttpCode(user.requestsPeriodCountGroupedByHttpCode)
            .monitoringContent(user.monitoringContent)
            .active_countries(user.active_countries)
            .inactive_countries(user.inactive_countries)
            .build();
    }
    setColumnsOrder() {
        this.columnsData = [
            'mail',
            'firstname',
            'lastname',
            'plan',
            'plan_type',
            'technical_support',
            'extra_children_allowed_api',
            'stripe_currency',
            'remaining_credits_saas',
            'max_credits_saas',
            'extra_credits',
            'registration_date',
            'user_status',
            'apiKey',
            'storeID',
            'phone_number',
            'siret',
            'company_id',
            'company_tax_id',
            'company_name',
            'domain_name',
            'integration_method',
            'pro',
            'age',
            'gender',
            'api_setup_plan_done',
            'can_change_plan_type',
            'expedition_country',
            'expedition_state',
            'expedition_city',
            'expedition_postal_code',
            'expedition_address',
            'default_product_category',
            'default_hs_code',
            'default_product_description',
            'default_origin_country',
            'tax_included',
            'extra_fees',
            'signature',
            'seller_logo',
            'customer_service_info',
            'reverse_logistic_terms',
            'other_seller_informations',
            'brand_name',
            'air_port_of_lading',
            'incoterm',
            'term_of_sale',
            /*'orders_count',
            'orders_turnover',
            'orders_currency',*/
            'active_countries',
            'inactive_countries',
            'monitoring_customers_count',
            'monitoring_products_count',
            'monitoring_orders_count',
            'monitoring_categories_count',
            'fedex_account_number',
            'fedex_counter_number',
            'fedex_auth_key',
            'fedex_password',
            'dhl_shop_id',
            'dhl_password',
            'dhl_account_number',
            'ups_account_number'
        ];
    }

    getRowsDetailsDailyRequests(mail: string): UserDailyRequestsContent[] {
        const found = this.rowsDetailsDailyRequests.find(r => r.length > 0 && r[0].mail === mail);
        const sumDailyRequests: UserDailyRequestsContent = Builder(UserDailyRequestsContent).mail(mail).requests_done({}).build();
        if (found && found.length > 0) {
            // we must handle the fact that some endpoint may exists one day, but not another day
            found.forEach(day => {
                const endpoints = this.getEndpoints(day.requests_done);
                endpoints.forEach(endpoint => {
                    if (sumDailyRequests.requests_done[endpoint] === undefined) {
                        sumDailyRequests.requests_done[endpoint] = day.requests_done[endpoint] ? { count: day.requests_done[endpoint].count } : { count: 0 };
                    } else {
                        sumDailyRequests.requests_done[endpoint].count += day.requests_done[endpoint] ? day.requests_done[endpoint].count : 0;
                    }
                });
            });
        }
        return [sumDailyRequests];
    }
    setRowsDetailsDailyRequests(mail: string) {
        const tempRowsDetailsContent: { [key: string]: any }[] = [];
        const userRowDetail = this.getRowsDetailsDailyRequests(mail);
        const apiPrePaymentCredits = this.rowsDetailsApiPrePaymentCredits[mail];
        userRowDetail.forEach(rd => {
            const endpoints = this.getEndpoints(rd.requests_done);
            for (let i = 0; i < endpoints.length; i++) {
                tempRowsDetailsContent.push({
                    date_start: i === 0 ? this.getStringDate(this.usedStartDateLastUpdateDailyRequests) : '',
                    date_end: i === 0 ? this.getStringDate(this.usedEndDateLastUpdateDailyRequests) : '',
                    endpoint: endpoints[i],
                    count: rd.requests_done[endpoints[i]].count,
                    remaining: apiPrePaymentCredits && apiPrePaymentCredits[endpoints[i]] ? apiPrePaymentCredits[endpoints[i]].remaining : '',
                    max: apiPrePaymentCredits && apiPrePaymentCredits[endpoints[i]] ? apiPrePaymentCredits[endpoints[i]].max : '',
                });
            }
        });
        this.rowsDetailsContent[0][mail] = tempRowsDetailsContent;
    }
    setRowsDetailsSalesData(mail: string) {
        const row = this.rows.find(r => r.mail === mail);
        if (row) {
            const tempRowsDetailsContent: { [key: string]: any }[] = [];
            tempRowsDetailsContent.push({
                orders_count: row.orders_count,
                orders_turnover: row.orders_turnover,
                orders_currency: row.orders_currency
            });
            this.rowsDetailsContent[1][mail] = tempRowsDetailsContent;
        }
    }
    setRowsDetailsRequestsPeriodCountByHttpCode(mail: string) {
        const tempRowsDetailsContent: { [key: string]: any }[] = [];
        const foundDetails = this.rowsDetailsRequestsPeriodCount[mail];
        if (foundDetails) {
            const httpCodes = ['200', '300', '400', '500'];
            // get whole requests first
            const wholeRequests: string[] = [];
            httpCodes.forEach(httpCode => {
                const endpoints = Object.keys((foundDetails[httpCode] as RequestsPeriodCountValue).requestCount);
                endpoints.forEach(endpoint => {
                    if (! wholeRequests.includes(endpoint)) {
                        wholeRequests.push(endpoint);
                    }
                });
            });
            wholeRequests.sort((a, b) => {
                const splitted_a = a.split('/');
                const splitted_b = b.split('/');
                return (splitted_a[splitted_a.length - 1] < splitted_b[splitted_b.length - 1]) ? -1 : 1;
            });

            // fill the row details then
            for (let i = 0; i < wholeRequests.length; i++) {
                tempRowsDetailsContent.push({
                    requests_done: wholeRequests[i],
                    requests_done_status_200: foundDetails[200].requestCount[wholeRequests[i]] ? foundDetails[200].requestCount[wholeRequests[i]] : 0,
                    requests_done_status_300: foundDetails[300].requestCount[wholeRequests[i]] ? foundDetails[300].requestCount[wholeRequests[i]] : 0,
                    requests_done_status_400: foundDetails[400].requestCount[wholeRequests[i]] ? foundDetails[400].requestCount[wholeRequests[i]] : 0,
                    requests_done_status_500: foundDetails[500].requestCount[wholeRequests[i]] ? foundDetails[500].requestCount[wholeRequests[i]] : 0,
                    total_status_200: i === 0 ? foundDetails[200].count : '',
                    total_status_300: i === 0 ? foundDetails[300].count : '',
                    total_status_400: i === 0 ? foundDetails[400].count : '',
                    total_status_500: i === 0 ? foundDetails[500].count : ''
                });
            }

            // no request done? push 1 item
            if (tempRowsDetailsContent.length === 0) {
                tempRowsDetailsContent.push({
                    requests_done: '',
                    requests_done_status_200: '',
                    requests_done_status_300: '',
                    requests_done_status_400: '',
                    requests_done_status_500: '',
                    total_status_200: foundDetails[200].count,
                    total_status_300: foundDetails[300].count,
                    total_status_400: foundDetails[400].count,
                    total_status_500: foundDetails[500].count
                });
            }
        }
        this.rowsDetailsContent[2][mail] = tempRowsDetailsContent;
    }
    getEndpoints(obj: any): string[] {
        return Object.keys(obj);
    }
    getStringDate(d: number | Date): string {
        const date = new Date(d);
        return `${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}/${(date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : (date.getMonth()+1)}/${date.getFullYear()}`;
    }
    getElligibleMails(): string[] {
        const betweenDatesMails = Object.keys(this.createdUsersBetweenDates.users);
        return this.user.superAdminUsersMinimalData
                    .filter(u => {
                        return  (
                                    ! this.filterUsersByDate
                                    ||
                                    betweenDatesMails.includes(u.email)
                                )
                                &&
                                (
                                    this.strIncludes(u.email, this.filter) ||
                                    this.strIncludes(u.domain_name, this.filter) ||
                                    this.strIncludes(u.company_name, this.filter)
                                );
                    })
                    .map(u => u.email);
    }
    getMailsToRequestWith(): string[] {
        const maxMails = this.rows.length + 20;
        const allElligibleMails = this.getElligibleMails();
        return allElligibleMails.slice(this.rows.length, maxMails);
    }
    strIncludes(str: string, includesStr: string): boolean {
        if (str === null || str === undefined) {
            return false;
        }
        if (includesStr === null || includesStr === undefined || includesStr === '') {
            return true;
        }
        const minifiedStr = str.toLowerCase();
        const minifiedIncludesStr = includesStr.toLowerCase();
        return minifiedStr.includes(minifiedIncludesStr);
    }

    async getUsersWholeData(): Promise<MonitoringUsersWholeData[]> {
        const emails = this.getMailsToRequestWith();
        const usedStartDate = this.getRightStartDate();
        const usedEndDate = this.getRightEndDate();
        try {
            return (<MonitoringUsersWholeData[]> await this.http.post(
                `https://api.${environment.baseUrl}/v1/monitoring/users/wholeData`,
                {
                    users: emails,
                    order_date_start: usedStartDate.getTime(),
                    order_date_end: usedEndDate.getTime(),
                    daily_requests_date_start: usedStartDate.getTime(),
                    daily_requests_date_end: usedEndDate.getTime()
                }
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    async getCreatedUsersBetweenDates(): Promise<{accounts_created: CreatedUsersBetweenDates}> {
        const usedStartDate = this.getRightStartFilterUsersDate();
        const usedEndDate = this.getRightEndFilterUsersDate();
        try {
            return (<{accounts_created: CreatedUsersBetweenDates}> await this.http.get(
                `https://api.${environment.baseUrl}/v1/monitoring/users/created?dateStart=${usedStartDate.getTime()}&dateEnd=${usedEndDate.getTime()}`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return {
                accounts_created: Builder(CreatedUsersBetweenDates)
                                .date_start(usedStartDate.getTime())
                                .date_end(usedEndDate.getTime())
                                .created_users_number(0)
                                .users({})
                                .build()
            };
        }
    }

    // return UTC Date
    getRightStartDate(): Date {
        const usedDate = new Date();
        usedDate.setUTCFullYear(this.selectedStartDate.getFullYear());
        usedDate.setUTCMonth(this.selectedStartDate.getMonth());
        usedDate.setUTCDate(this.selectedStartDate.getDate());
        usedDate.setUTCHours(0);
        usedDate.setUTCMinutes(0);
        usedDate.setUTCSeconds(0);
        usedDate.setUTCMilliseconds(0);

        return usedDate;
    }
    // return UTC Date
    getRightEndDate(): Date {
        const usedDate = new Date();
        usedDate.setUTCFullYear(this.selectedEndDate.getFullYear());
        usedDate.setUTCMonth(this.selectedEndDate.getMonth());
        usedDate.setUTCDate(this.selectedEndDate.getDate());
        usedDate.setUTCHours(23);
        usedDate.setUTCMinutes(59);
        usedDate.setUTCSeconds(59);
        usedDate.setUTCMilliseconds(999);

        return usedDate;
    }
    // return UTC Date
    getRightStartFilterUsersDate(): Date {
        const usedDate = new Date();
        usedDate.setUTCFullYear(this.selectedStartDateFilterUsers.getFullYear());
        usedDate.setUTCMonth(this.selectedStartDateFilterUsers.getMonth());
        usedDate.setUTCDate(this.selectedStartDateFilterUsers.getDate());
        usedDate.setUTCHours(0);
        usedDate.setUTCMinutes(0);
        usedDate.setUTCSeconds(0);
        usedDate.setUTCMilliseconds(0);

        return usedDate;
    }
    // return UTC Date
    getRightEndFilterUsersDate(): Date {
        const usedDate = new Date();
        usedDate.setUTCFullYear(this.selectedEndDateFilterUsers.getFullYear());
        usedDate.setUTCMonth(this.selectedEndDateFilterUsers.getMonth());
        usedDate.setUTCDate(this.selectedEndDateFilterUsers.getDate());
        usedDate.setUTCHours(23);
        usedDate.setUTCMinutes(59);
        usedDate.setUTCSeconds(59);
        usedDate.setUTCMilliseconds(999);

        return usedDate;
    }

    openModal(template: TemplateRef<any>, type: string, row: any = null) {
        this.closingModal = false;
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        if (type === 'editRow') {
            this.initRowForm(row);
        }
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.formIsInvalid = false;
        this.closingModal = true;
        this.modalRef.hide();
        this.unsubscribeSubscription();
    }

    onEditRow(rowId: string) {
        const row = this.rows.find(r => r.mail === rowId);
        if (row) {
            this.openModal(this.editrow, 'editRow', row);
        }
    }

    unsubscribeSubscription() {
        if (this.subscription) {
            try {
                this.subscription.unsubscribe();
            } catch (error) {
                this.subscription = null;
                console.log(error);
            }
        }
    }

    initRowForm(row: any) {
        this.userForm = new FormGroup({
            lastname: new FormControl(row.lastname, [Validators.required]),
            firstname: new FormControl(row.firstname, [Validators.required]),
            email: new FormControl(row.mail, [Validators.required]),

            gender: new FormControl(row.gender, []),
            age: new FormControl(row.age, []),
            phone: new FormControl(row.phone_number, []),
            pro: new FormControl(row.pro, []),
            siret: new FormControl(row.siret, []),
            brand_name: new FormControl(row.brand_name, []),
            customer_service_info: new FormControl(row.customer_service_info, []),
            reverse_logistic_terms: new FormControl(row.reverse_logistic_terms, []),
            air_port_of_lading: new FormControl(row.air_port_of_lading, []),
            incoterm: new FormControl(row.incoterm, []),
            term_of_sale: new FormControl(row.term_of_sale, []),
            other_seller_informations: new FormControl(row.other_seller_informations, []),
            signature: new FormControl(row.signature, [Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?')]),
            seller_logo: new FormControl(row.seller_logo, [Validators.pattern('((https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)?')]),
            api_setup_plan_done: new FormControl(row.api_setup_plan_done, []),
            can_change_plan_type: new FormControl(row.can_change_plan_type, []),
            stripe_currency: new FormControl(row.stripe_currency, []),
            remaining_credits_saas: new FormControl(row.remaining_credits_saas, []),
            extra_credits: new FormControl(row.extra_credits, []),

            plan_type: new FormControl({ value: row.plan, disabled: true }),
            payment_type: new FormControl({ value: row.plan_type, disabled: true }),

            url: new FormControl(row.domain_name, [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
            integration: new FormControl(row.integration_method, [Validators.required]),

            social: new FormControl(row.company_name, [Validators.required]),
            address: new FormControl(row.expedition_address, [Validators.required]),
            zip_code: new FormControl(row.expedition_postal_code, [Validators.required]),
            city: new FormControl(row.expedition_city, [Validators.required]),
            state: new FormControl(row.expedition_state, [Validators.required]),
            from_country: new FormControl(row.expedition_country, [Validators.required]),

            category: new FormControl(row.default_product_category, []),
            description: new FormControl(row.default_product_description, []),
            hscode: new FormControl(row.default_hs_code, []),
            origin_country: new FormControl(row.default_origin_country, []),

            extra_fees: new FormControl(row.extra_fees, [Validators.min(0), Validators.max(1)]),
            tax_included: new FormControl(row.tax_included, []),

            fedex: new FormGroup({
                account_number: new FormControl(row.fedex_account_number, []),
                counter_number: new FormControl(row.fedex_counter_number, []),
                auth_key: new FormControl(row.fedex_auth_key, []),
                password: new FormControl(row.fedex_password, [])
            }),
            dhl: new FormGroup({
                shop_id: new FormControl(row.dhl_shop_id, []),
                password: new FormControl(row.dhl_password, []),
                account_number: new FormControl(row.dhl_account_number, [])
            }),
            ups: new FormGroup({
                account_number: new FormControl(row.ups_account_number, [])
            })
        });

        this.subscription = this.userForm.get('from_country').valueChanges.subscribe(iso3 => {
            this.setDistricts(iso3);
        });

        if (row.expedition_country) {
            this.setDistricts(row.expedition_country).then(() => {
                this.userForm.get('state').setValue(row.expedition_state);
            });
        }

        if (row.plan === PLAN_V2_ENUM.API && row.plan_type === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT) {
            this.setAvailableEndpoints(row.mail);
            this.availableEndpoints.forEach(endpoint => {
                this.addControlInRowForm(endpoint.id);
            });
        }

        if (row.plan === PLAN_V2_ENUM['E-COMMERCE'] || row.plan === PLAN_V2_ENUM.API) {
            this.addControlInRowForm('technical_support', row.technical_support);
        }
        if (row.plan === PLAN_V2_ENUM.API) {
            this.addControlInRowForm('extra_children_allowed_api', row.extra_children_allowed_api);
        }
    }

    addControlInRowForm(controlId: string, defaultValue = null) {
        this.userForm.addControl(controlId, new FormControl(defaultValue, []));
    }

    editUserPlanIsEcommerce(): boolean {
        return this.userForm && this.userForm.get('plan_type').value === PLAN_V2_ENUM['E-COMMERCE'];
    }

    editUserPlanIsSaas(): boolean {
        if (this.userForm) {
            const plan = this.userForm.get('plan_type').value;
            if (
                plan &&
                (
                    plan === PLAN_V2_ENUM.SAAS ||
                    plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.START}` ||
                    plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.ROCKET}` ||
                    plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.COSMOS}` ||
                    plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.INFINITE}`
                )
            ) {
                return true;
            }
        }
        return false;
    }

    editUserTypeIsNotChild(): boolean {
        if (this.userForm) {
            const mail = this.userForm.get('email').value;
            const foundRow = this.rows.find(r => r.mail === mail);
            if (foundRow) {
                return foundRow.user_status !== USER_STATUT_ENUM.CHILD;
            }
        }
        return true;
    }
    editUserPlanIsApi(): boolean {
        if (this.userForm) {
            const plan = this.userForm.get('plan_type').value;
            return plan && plan === PLAN_V2_ENUM.API;
        }
        return false;
    }
    editUserPlanIsApiPrePayment(): boolean {
        if (this.userForm) {
            const plan = this.userForm.get('plan_type').value;
            const type = this.userForm.get('payment_type').value;
            return plan && type && plan === PLAN_V2_ENUM.API && type === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT;
        }
        return false;
    }

    // is the request coupled with other requests ?
    requestIsCoupledWithOtherRequests(request: string): boolean {
        return this.coupledConsumptions.findIndex(cc => cc.includes(request)) >= 0;
    }
    setAvailableEndpoints(mail: string): void {
        if (this.rowsDetailsContent[0] && this.rowsDetailsContent[0][mail]) {
            let i = 0;
            const keys = this.rowsDetailsContent[0][mail].map(content => content.endpoint);
            this.availableEndpoints = keys.map(k => {
                i += 1;
                return {
                    id: `req_${i}`,
                    endpoint: k
                };
            });

            i = 0;
            while (i < this.availableEndpoints.length) {
                // merge together coupled requests if there are any
                if (this.requestIsCoupledWithOtherRequests(this.availableEndpoints[i].endpoint)) {
                    const coupledConsumptionsItem = this.coupledConsumptions.find(cons => cons.includes(this.availableEndpoints[i].endpoint));
                    coupledConsumptionsItem.forEach(cc => {
                        if (cc !== this.availableEndpoints[i].endpoint) {
                            const deleteIndex = this.availableEndpoints.findIndex(ae => ae.endpoint === cc);
                            if (deleteIndex >= 0) {
                                this.availableEndpoints.splice(deleteIndex, 1);
                            }
                        }
                    });
                    this.availableEndpoints[i].endpoint = coupledConsumptionsItem.join(', ');
                }
                i += 1;
            }
        }
    }

    // verify that the entered HS Code is a right HS Code for the specified origin_country
    async checkHscode(origin_country: string, hscode: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/verifyhscode`, {origin_country, hscode}).toPromise()
            .then(async (response: any) => {
                if (!response.isRightHscode) {
                    this._notification.displayMessage(await this.translate.get('account.wrong_SH').toPromise(), 'warning');
                }
                resolve(response.isRightHscode);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }

    async updateUser() {
        if (this.userForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const userForm = this.userForm.value;

        // check hs code
        if (userForm.hscode || userForm.origin_country) {
            // we must check that the hs code is a right hs code for the origin country
            if (!(await this.checkHscode(userForm.origin_country, userForm.hscode))) {
                return;
            }
        }

        if (userForm.extra_fees !== null && (userForm.extra_fees < 0 || userForm.extra_fees > 1)) {
            return;
        }

        const fedexContent = this.fedex.value;
        const dhlContent = this.dhl.value;
        const upsContent = this.ups.value;

        const updateUser = new UserSettings(userForm.email);

        updateUser.account.firstname = userForm.firstname;
        updateUser.account.lastname = userForm.lastname;

        updateUser.api_setup_plan_done = userForm.api_setup_plan_done;
        updateUser.can_change_plan_type = userForm.can_change_plan_type;
        // updateUser.stripe_currency = userForm.stripe_currency;

        updateUser.account.gender = userForm.gender;
        updateUser.account.age = userForm.age;
        updateUser.account.phone_number = userForm.phone && userForm.phone.e164Number ? userForm.phone.e164Number : undefined;
        updateUser.account.pro = userForm.pro;
        updateUser.account.siret = userForm.siret;
        updateUser.brand_name = userForm.brand_name;
        updateUser.customer_service_info = userForm.customer_service_info;
        updateUser.reverse_logistic_terms = userForm.reverse_logistic_terms;
        updateUser.air_port_of_lading = userForm.air_port_of_lading;
        updateUser.incoterm = userForm.incoterm;
        updateUser.term_of_sale = userForm.term_of_sale;
        updateUser.other_seller_informations = userForm.other_seller_informations;
        updateUser.signature = userForm.signature && userForm.signature.length > 0 ? userForm.signature : null;
        updateUser.seller_logo = userForm.seller_logo && userForm.seller_logo.length > 0 ? userForm.seller_logo : null;

        updateUser.duty_calculation.extra_fees = userForm.extra_fees;
        updateUser.duty_calculation.tax_included = userForm.tax_included;

        updateUser.account.domain_name = userForm.url;
        updateUser.account.integration_method = userForm.integration;
        updateUser.account.company_name = userForm.social;

        updateUser.expedition.address = userForm.address;
        updateUser.expedition.postal_code = userForm.zip_code;
        updateUser.expedition.city = userForm.city;
        updateUser.expedition.state = userForm.state;
        updateUser.expedition.country = userForm.from_country;

        updateUser.customs_classification.default_product_category = userForm.category;
        updateUser.customs_classification.default_product_description = userForm.description;
        updateUser.customs_classification.default_hs_code = userForm.hscode;
        updateUser.customs_classification.default_origin_country = userForm.origin_country;

        updateUser.carriers.fedex.account_number = fedexContent.account_number;
        updateUser.carriers.fedex.meter_number = fedexContent.counter_number;
        updateUser.carriers.fedex.authentication_key = fedexContent.auth_key;
        updateUser.carriers.fedex.api_password = fedexContent.password;

        updateUser.carriers.dhl.online_store_id = dhlContent.shop_id;
        updateUser.carriers.dhl.production_password = dhlContent.password;
        updateUser.carriers.dhl.payment_account_number = dhlContent.account_number;

        updateUser.carriers.ups.payment_account_number = upsContent.account_number;

        const override_plan: { [key: string]: number; } = {};
        for (let i = 0; i < this.availableEndpoints.length; i++) {
            if (userForm[this.availableEndpoints[i].id] !== null && userForm[this.availableEndpoints[i].id] > 0) {
                const endpoints = this.availableEndpoints[i].endpoint.split(', ');
                endpoints.forEach(e => {
                    override_plan[e] = userForm[this.availableEndpoints[i].id];
                });
            }
        }

        this.closeModal();

        const row = this.rows.find(r => r.mail === userForm.email);
        const body = {
            mail: userForm.email,
            update_user: updateUser,
            extra_credits: row && userForm.extra_credits !== row.extra_credits ? userForm.extra_credits : undefined,
            override_plan,
            technical_support: row && userForm.technical_support !== row.technical_support ? userForm.technical_support : undefined,
            extra_children_allowed_api: row && userForm.extra_children_allowed_api !== row.extra_children_allowed_api ? userForm.extra_children_allowed_api : undefined
        };

        this.http.post(`https://api.${environment.baseUrl}/v1/user/updateFromSuperAdmin`, body).toPromise()
        .then((user) => {
            this.updateRowWithNewData(updateUser, userForm.extra_credits, override_plan, userForm.technical_support, userForm.extra_children_allowed_api);
            this._notification.displayMessage(this.updateUserMessage, 'success');
        })
        .catch((error) => {
            this._notification.displayMessage(error.error.message, 'danger');
        });
    }

    updateRowWithNewData(updatedUser: UserSettings, extra_credits: number, override_plan: { [key: string]: number; }, technical_support: number, extra_children_allowed_api: number) {
        const row = this.rows.find(r => r.mail === updatedUser.mail);
        if (row) {
            row.lastname = updatedUser.account.lastname;
            row.firstname = updatedUser.account.firstname;
            row.gender = updatedUser.account.gender;
            row.age = updatedUser.account.age;
            row.phone_number = updatedUser.account.phone_number;
            row.pro = updatedUser.account.pro;
            row.siret = updatedUser.account.siret;
            row.brand_name = updatedUser.brand_name;
            row.customer_service_info = updatedUser.customer_service_info;
            row.reverse_logistic_terms = updatedUser.reverse_logistic_terms;
            row.air_port_of_lading = updatedUser.air_port_of_lading;
            row.incoterm = updatedUser.incoterm;
            row.term_of_sale = updatedUser.term_of_sale;
            row.other_seller_informations = updatedUser.other_seller_informations;
            row.signature = updatedUser.signature;
            row.seller_logo = updatedUser.seller_logo;
            row.domain_name = updatedUser.account.domain_name;
            row.integration_method = updatedUser.account.integration_method;
            row.company_name = updatedUser.account.company_name;

            row.api_setup_plan_done = updatedUser.api_setup_plan_done;
            row.can_change_plan_type = updatedUser.can_change_plan_type;
            row.extra_credits = extra_credits;
            row.technical_support = technical_support;
            row.extra_children_allowed_api = extra_children_allowed_api;

            row.expedition_address = updatedUser.expedition.address;
            row.expedition_postal_code = updatedUser.expedition.postal_code;
            row.expedition_city = updatedUser.expedition.city;
            row.expedition_state = updatedUser.expedition.state;
            row.expedition_country = updatedUser.expedition.country;

            row.default_product_category = updatedUser.customs_classification.default_product_category;
            row.default_product_description = updatedUser.customs_classification.default_product_description;
            row.default_hs_code = updatedUser.customs_classification.default_hs_code;
            row.default_origin_country = updatedUser.customs_classification.default_origin_country;

            row.extra_fees = updatedUser.duty_calculation.extra_fees;
            row.tax_included = updatedUser.duty_calculation.tax_included;

            row.fedex_account_number = updatedUser.carriers.fedex.account_number;
            row.fedex_counter_number = updatedUser.carriers.fedex.meter_number;
            row.fedex_auth_key = updatedUser.carriers.fedex.authentication_key;
            row.fedex_password = updatedUser.carriers.fedex.api_password;

            row.dhl_shop_id = updatedUser.carriers.dhl.online_store_id;
            row.dhl_password = updatedUser.carriers.dhl.production_password;
            row.dhl_account_number = updatedUser.carriers.dhl.payment_account_number;

            row.ups_account_number = updatedUser.carriers.ups.payment_account_number;
            if (override_plan) {
                this.rowsDetailsContent[0][row.mail] = this.rowsDetailsContent[0][row.mail].map(endpointValues => {
                    if (override_plan[endpointValues.endpoint]) {
                        endpointValues.remaining += override_plan[endpointValues.endpoint];
                        endpointValues.max += override_plan[endpointValues.endpoint];
                    }
                    return endpointValues;
                });
            }

            this.rows = [...this.rows];
        }

    }
}
