import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {BlankComponent} from '../Common/blank/blank.component';
import {RegisterComponent} from './register/register.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ConfirmPasswordComponent} from './confirm-password/confirm-password.component';


const authRoutes: Routes = [
  {
    path: 'login',
    component: BlankComponent,
    children: [{
      path: '',
      component: LoginComponent
    }
    ],
  },
  {
    path: 'auth',
    component: BlankComponent,
    children: [{
      path: '',
      component: LoginComponent
    }
    ],
  },
  {
    path: 'register',
    component: BlankComponent,
    children: [{
      path: '',
      component: RegisterComponent
    }
    ],
  },
  {
    path: 'reset',
    component: BlankComponent,
    children: [{
      path: '',
      component: ForgotPasswordComponent
    }
    ],
  },
  {
    path: 'passwordForgotten',
    component: BlankComponent,
    children: [{
      path: '',
      component: ForgotPasswordComponent
    }
    ],
  },
  {
    path: 'resetConfirm',
    component: BlankComponent,
    children: [{
      path: '',
      component: ResetPasswordComponent
    }
    ],
  },
  {
    path: 'resetPassword',
    component: BlankComponent,
    children: [{
      path: '',
      component: ResetPasswordComponent
    }
    ],
  },
  {
    path: 'passwordConfirm',
    component: BlankComponent,
    children: [{
      path: '',
      component: ConfirmPasswordComponent
    }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
