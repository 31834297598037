import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {AuthService} from '../auth/auth.service';
import {from, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {CognitoUserSession} from 'amazon-cognito-identity-js';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    private readonly baseUrlAPI: string;
    private userAPIUrls: Array<string> = [];

    constructor(private authService: AuthService) {
        this.baseUrlAPI = `https://api.${environment.baseUrl}`;
        const userInfosUrl = this.baseUrlAPI + '/v1/user/info';
        const userInfosWithCacheUrl = this.baseUrlAPI + '/v1/user/info?fromCache=true';
        const userUpdateUrl = this.baseUrlAPI + '/v1/user/update';
        const userPostUrl = this.baseUrlAPI + '/v1/user/registrationSettings';
        const userDeleteUrl = this.baseUrlAPI + '/v1/user/delete';
        this.userAPIUrls.push(userInfosUrl, userInfosWithCacheUrl, userUpdateUrl, userDeleteUrl, userPostUrl);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(this.baseUrlAPI)) {
            return from(this.authService.getCognitoUser())
                .pipe(
                    switchMap((cognitoUser: CognitoUserSession) => {
                        let interceptedRequest = req.clone({
                            headers: req.headers
                                .append('Content-Type', 'application/json')
                                .append('Authorization', cognitoUser.getIdToken().getJwtToken())
                        });
                        if (this.userAPIUrls.indexOf(req.url) > -1) {
                            interceptedRequest = interceptedRequest.clone({
                                headers: interceptedRequest.headers.append('access_token', cognitoUser.getAccessToken().getJwtToken())
                            });
                        }
                        return next.handle(interceptedRequest);
                    })
                );
        } else {
            return next.handle(req);
        }
    }
}
