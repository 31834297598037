import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StepExpeditionForm } from '../../../../../../models/Register';
import { environment } from '../../../../../../../environments/environment';
import { Subscription } from 'rxjs';
const us_states = require('../../../../../../../assets/data/US_states.json');

@Component({
    selector: 'app-register-step-expedition',
    templateUrl: './step_expedition.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepExpeditionComponent implements OnInit, OnDestroy {
    @Input()
    formContent: StepExpeditionForm;
    @Input()
    countries: { value: string, label: string, iso2: string }[] = [];
    @Output()
    nextStepCallback: EventEmitter<StepExpeditionForm> = new EventEmitter<StepExpeditionForm>();

    districts: { value: string, label: string }[] = [];
    registerForm: FormGroup;
    formIsInvalid = false;

    private subscription: Subscription;

    constructor (
        private http: HttpClient,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        console.log(this.registerForm);
        this.registerForm = new FormGroup({
            address: new FormControl(
                this.formContent.address ? this.formContent.address : '',
                [
                    Validators.required
                ]
            ),
            city: new FormControl(
                this.formContent.city ? this.formContent.city : '',
                [
                    Validators.required
                ]
            ),
            postal_code: new FormControl(
                this.formContent.postal_code ? this.formContent.postal_code : '',
                [
                    Validators.required
                ]
            ),
            state: new FormControl(
                this.formContent.state ? this.formContent.state : '',
                [
                    Validators.required
                ]
            ),
            country: new FormControl(
                this.formContent.country ? this.formContent.country : '',
                [
                    Validators.required
                ]
            )
        });

        this.subscription = this.registerForm.get('country').valueChanges.subscribe(iso3 => {
            this.setDistricts(iso3);
        });

        if (this.formContent.country) {
            this.setDistricts(this.formContent.country).then(() => {
                this.registerForm.get('state').setValue(this.formContent.state);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async setDistricts(countryIso3: string) {
        this.registerForm.get('state').reset();
        if (countryIso3 === 'USA') {
            this.districts = us_states;
        } else {
            this.districts = (
                await this.http.get(`https://api.${environment.baseUrl}/v2/data/districts?iso3=${countryIso3}`).toPromise()
            ) as { value: string, label: string }[];
        }

        // sort the list
        this.districts.sort((a, b) => {
            return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
        });
    }

    nextStep() {
        if (this.registerForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        this.nextStepCallback.emit(this.registerForm.value as StepExpeditionForm);
    }
}
