import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Type } from '@angular/core';
import { MessageContainerComponent } from './message-container.component';
import { MessageData, MessageDataFilled, MessageDataOptions } from './message.definitions';
import {TranslateService} from '@ngx-translate/core';

// TODO: remove MessageData generic type as it has no contributon in typing
export class MessageBaseService<ContainerClass extends MessageContainerComponent, MessageData> {
  protected _counter = 0; // Id counter for messages
  protected _container: ContainerClass;

  constructor(overlay: Overlay, containerClass: Type<ContainerClass>, private _idPrefix: string = '') {
    // Wait till wrapper gets init parameters
    setTimeout(() => {
      this._container = overlay.create().attach(new ComponentPortal(containerClass)).instance;
    }, 100);
  }

  remove(messageId?: string): void {
    if (messageId) {
      this._container.removeMessage(messageId);
    } else {
      this._container.removeMessageAll();
    }
  }

  createMessage(message: object, options?: MessageDataOptions): MessageDataFilled {
    // TODO: spread on literal has been disallow on latest proposal
    const resultMessage: MessageDataFilled = { ...message, ...{
      messageId: this._generateMessageId(),
      options,
      createdAt: new Date()
    }};
    this._container.createMessage(resultMessage);

    return resultMessage;
  }

  protected _generateMessageId(): string {
    return this._idPrefix + this._counter++;
  }
}

@Injectable()
export class MessageService extends MessageBaseService<MessageContainerComponent, MessageData> {
  constructor(overlay: Overlay) {
    super(overlay, MessageContainerComponent, 'message-');
  }

  // Shortcut methods
  success(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: 'success', content }, options);
  }

  error(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: 'error', content }, options);
  }

  info(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: 'info', content }, options);
  }

  warning(content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type: 'warning', content }, options);
  }

  displayMessage(message: string, type: string, duration = 10000) {
    this.create(
        type,
        message,
        {
          Position: 'top',
          Style: 'bar',
          Duration: duration
        }
    );
  }

  create(type: string, content: string, options?: MessageDataOptions): MessageDataFilled {
    return this.createMessage({ type, content }, options);
  }
  buildTransiteoErrorMessage(errorResponse, defaultMessage: string): string {
    if (errorResponse.name === 'BusinessError' || errorResponse.name === 'ClientError') {
      let details = '';
      const detailsArray = (errorResponse.details as any[]);
      if (detailsArray.length > 0) {
        details = `Details : ${detailsArray.reduce((detailA: string, detailB) =>
            `${this.getDetailMsg(detailA, detailB)} ${this.getCausesMsg(detailB.causes)}`, ' ')}`;
      }
      defaultMessage = `${errorResponse.code} : ${errorResponse.message}. ${details}`;
    } else {
      console.error(errorResponse);
    }
    return defaultMessage;
  }
  getDetailMsg(detailA, detailB) {
    let detail = detailA + ' ';
    if (detailB.parameter) {
      const detailValue = detailB.parameter.value;
      const detailName = detailB.parameter.name;
      if (detailValue) {
        detail += detailValue;
      } else if (detailName) {
        detail += detailName;
      }
    } else {
      detail += detailB;
    }
    return detail;
  }
  private getCausesMsg(causes) {
    if (causes) {
      return (causes as any[]).length === 0 ? ''
          : ': ' + (causes as any[]).reduce((a, b) => a + ' ' + b, ' ');
    }
    return '';
  }
}
