import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {SharedData} from '../../Common/SharedData';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetFormConfirm: FormGroup;
  special_chars = '^$*.[]{}()?"!@#%&/ \\,><\':;|_~`';
  fieldTextType: boolean;
  formIsInvalid = false;
  isWhiteLabel = false;

  // tslint:disable-next-line:max-line-length
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private data: SharedData,
    private translate: TranslateService
  ) {
    // retrieve URL path
    // if url contains '/passwordForgotten' then it's a white label
    this.isWhiteLabel = this.router.url.includes('/resetPassword');
  }

  password_reset_success: string;
  async ngOnInit() {
    if (this.authService.isLoggedIn) {
      await this.router.navigate(['/dashboard']);
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.resetFormConfirm = new FormGroup({
        email: new FormControl(params['email'], [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'), Validators.pattern(/[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/)]),
        code: new FormControl('', [Validators.required])
      });
    });
    this.translate.get('messages').subscribe(messages => {
      this.password_reset_success = messages.notification.password_reset_success;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.password_reset_success = messages.notification.password_reset_success;
    });
  }

  get f() { return this.resetFormConfirm.controls; }

  async resetConfirm() {
    if (this.resetFormConfirm.invalid) {
        this.formIsInvalid = true;
        return;
    }
    const formParams = this.resetFormConfirm.value;
    await this.authService.confirmResetPassword(formParams.email, formParams.password, formParams.code).then(user => {
      // tslint:disable-next-line:max-line-length
      return this.router.navigate([this.isWhiteLabel ? '/auth' : '/login']).then(() => {
        this.data.successMessage = this.password_reset_success;
      });
    }).catch(error => {
      const paramsExtra: NavigationExtras = {queryParams: {email : this.resetFormConfirm.value.email}};
      return this.router.navigate([this.isWhiteLabel ? '/resetPassword' : '/resetConfirm'], paramsExtra).then(() => {
        this.data.errorMessage = error.message;
      });
    });
  }

  enoughChar(password: string) {
    return password.length >= 8 ;
  }

  anyUpperLetter(password: string) {
    const regex = RegExp('(?=.*[A-Z])');
    return regex.test(password) ;
  }

  anyNumber(password: string) {
    const regex = RegExp('(?=.*[0-9])');
    return regex.test(password) ;
  }

  anySpecialChar(password: string) {
    const regex_symbols = /[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/;
    const regex = RegExp(regex_symbols);
    return regex.test(password) ;
  }

  anyLowerLetter(password: string) {
    const regex = RegExp('(?=.*[a-z])');
    return regex.test(password) ;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
