import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-transiteo-footer',
  templateUrl: './transiteo-footer.component.html',
  styleUrls: ['./transiteo-footer.component.scss']
})
export class TransiteoFooterComponent implements OnInit {
  @Input()
  text: string;
  @Input()
  classes: string;
  constructor() { }

  ngOnInit() {
    if (!this.classes) {
      this.classes = 'col-md-12';
    }
  }

}
