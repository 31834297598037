import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '../../components/message/message.service';
import {DynamoProduct, LogisticProduct} from '../../../models/DynamoProduct';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/User';
import {CsvService} from '../../services/csv.service';
import {pgTabComponent} from '../../components/tabs/tab.component';
import {UploadFile} from '../../components/upload/interface';
import {pgUploadComponent} from '../../components/upload/upload.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {
  STEP_ENUM = {
    SEARCH: 1,
    RESULTS: 2
  };
  requestsType: Array<{value: string, label: string}>;
  productSearchForm: FormGroup;
  error: any;
  product: DynamoProduct;
  current_step: number;
  not_available: string;
  unknown_error: string;
  emptyCSV: string;
  wrongCSV: string;
  user: User;
  lang: string;
  requestType: string;
  file: UploadFile;
  csvArray: string[] = [];
  private readonly productSearchBaseUrl: string;
  constructor(private authService: AuthService,
              private http: HttpClient,
              private _notification: MessageService,
              private userService: UserService,
              private csvService: CsvService,
              private translate: TranslateService) {
    this.productSearchBaseUrl = `https://api.${environment.baseUrl}/v1/pimsrv/productSearch/`;
    this.current_step = this.STEP_ENUM.SEARCH;
    this.productSearchForm = new FormGroup({
      identification: new FormGroup({
        barcode: new FormControl('', [Validators.required])
      }),
      productSearchType: new FormControl('TEXTUEL'),
      requestType: new FormControl(''),
    });
  }
  @ViewChild(pgTabComponent, {static: false}) child: pgTabComponent;
  @ViewChild(pgUploadComponent, {static: false}) pgUploadComponent: pgUploadComponent;
  async ngOnInit() {
    await this.initUserPrefs();
    this.initTranslations();
  }
  initTranslations() {
    this.translate.get('productSearch.inputForm.dataTypes').subscribe((dataTypes) => {
      this.requestsType = dataTypes;
      this.productSearchForm.get('requestType').setValue(dataTypes[0].value);
      this.productSearchForm.get('requestType').updateValueAndValidity();
    });
    this.translate.get('messages').subscribe(messages => {
      this.updateLang(messages);
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateLang(event.translations['messages']);
      this.requestsType = event.translations.productSearch.inputForm.dataTypes;
      this.productSearchForm.get('requestType').updateValueAndValidity();
    });
  }
  private updateLang(messages) {
    this.not_available = messages.not_available;
    this.unknown_error = messages.notification.unknown_error;
    this.emptyCSV = messages.notification.emptyCSV;
    this.wrongCSV = messages.notification.wrongCSV;
    this.lang = this.user.lang ? this.user.lang.substr(0, 2) : messages.current_lang;
  }
  private async initUserPrefs() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
  }

  getNested(nested: string) {return this.productSearchForm.get(nested); }

  async searchProduct(): Promise<void> {
    const params = this.productSearchForm.value;
    this.requestType = params.requestType;
    await this.searchProductApi(params.identification.barcode, params.requestType).then(productSearchResponse => {
      const response = productSearchResponse.response;
      if (productSearchResponse.code !== 200) {
        const transiteoErrorMessage = this._notification.buildTransiteoErrorMessage(productSearchResponse.response, this.unknown_error);
        this._notification.displayMessage(transiteoErrorMessage, 'danger');
        return ;
      }
      this.product = response;
      this.current_step = this.STEP_ENUM.RESULTS;
      this.child.setLabel(1);
    });
  }
  async searchProductApi(barcode: string, requestType: string) {
    const body = {product: {identification: {type: 'BARCODE', value: barcode}}, lang: this.lang};
    return this.http.post(this.productSearchBaseUrl + this.findProductUrl(requestType), body).toPromise().then((response: any) => {
      return {code: 200, request : body, response: response.result};
    }, errorResponse => {
      return {code: errorResponse.status, request : body, response: errorResponse.error};
    });
  }

  private findProductUrl(requestType: string): string {
    switch (requestType.toLowerCase()) {
      case 'sales':
        return 'sales';
      case 'marketing':
        return 'marketing';
      case 'logistic':
        return 'logistic';
      default:
        return 'all';
    }
  }
  get f() { return this.productSearchForm.controls; }

  generateCsv() {
    const params = this.productSearchForm.value;
    const requestTypeLabel = this.requestsType.find(type => type.value === params.requestType);
    const requestType = requestTypeLabel ? requestTypeLabel.label : params.requestType;
    const barcode = params.identification.barcode;
    const csv_data = this.buildCsv(barcode, requestType, this.product);
    const csv_name = `productSearch_${params.identification.barcode}_${this.lang}`;
    return this.csvService.downloadFile(csv_data, csv_name);
  }
  buildCsv(barcode: string, requestType: string, dynamoProduct: DynamoProduct) {
    const allDataProduct = dynamoProduct as LogisticProduct;
    const requestInfo = {
      barcode: barcode,
      type: requestType,
    };
    const dimension_si = allDataProduct.dimension_si ? {
      width_si: allDataProduct.dimension_si.width,
      height_si : allDataProduct.dimension_si.height,
      length_si : allDataProduct.dimension_si.length,
      weight_si : allDataProduct.dimension_si.weight,
      volume_si : allDataProduct.dimension_si.volume,
      volume_weight_si : allDataProduct.dimension_si.volume_weight,
      package_width_si : allDataProduct.dimension_si.package_width,
      package_height_si : allDataProduct.dimension_si.package_height,
      package_length_si : allDataProduct.dimension_si.package_length,
      package_weight_si : allDataProduct.dimension_si.package_weight,
      package_volume_si : allDataProduct.dimension_si.package_volume,
      package_volume_weight_si : allDataProduct.dimension_si.package_volume_weight
    } : {};
    const dimension_no_si = allDataProduct.dimension_no_si ? {
      width_no_si :  allDataProduct.dimension_no_si.width,
      height_no_si : allDataProduct.dimension_no_si.height,
      length_no_si : allDataProduct.dimension_no_si.length,
      weight_no_si : allDataProduct.dimension_no_si.weight,
      volume_no_si : allDataProduct.dimension_no_si.volume,
      volume_weight_no_si : allDataProduct.dimension_no_si.volume_weight,
      package_width_no_si : allDataProduct.dimension_no_si.package_width,
      package_height_no_si : allDataProduct.dimension_no_si.package_height,
      package_length_no_si : allDataProduct.dimension_no_si.package_length,
      package_weight_no_si : allDataProduct.dimension_no_si.package_weight,
      package_volume_no_si : allDataProduct.dimension_no_si.package_volume,
      package_volume_weight_no_si : allDataProduct.dimension_no_si.volume_weight
    } : {};
    return Object.assign(requestInfo, dimension_si, dimension_no_si);
  }
  newSearch() {
    this.current_step = this.STEP_ENUM.SEARCH;
    this.child.setLabel(0);
  }
  display(value: number, metric: string) {
    if (value && value > 0) {
      return `${value} ${metric}`;
    }
    return this.not_available;
  }
  displayChildMetric(child: {value: number, metric: string}) {
    return this.display(child.value, child.metric);
  }

  beforeUpload = (file: UploadFile, _: UploadFile[]) => {
    const reader: FileReader = new FileReader();
    reader.readAsText(file as any);
    this.file = file;
    this.cleanFiles(file);
    reader.onload = async (_e) => {
      const csv: string = reader.result as string;
      const csvRecordsArray = (<string>csv).split(/\r\n|\n/);
      if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
        csvRecordsArray.shift();
        const wrongLines = csvRecordsArray.filter((record, index) => {
          const line = record.split(';');
          if (!line[0] || !line[1]) {
            this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
            return true;
          } else if (line[1] && line[1] !== 'logistic') {
            this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line[1]} not supported"`, 'danger');
            return true;
          }
          return false;
        });
        if (wrongLines.length === 0) {
          this.csvArray = csvRecordsArray;
        } else {
          this.removeFile(file);
        }
      } else {
        this._notification.displayMessage(this.emptyCSV, 'warning');
        this.removeFile(file);
      }
    };
    return true;
  }
  removeFile(file: UploadFile) {
    this.pgUploadComponent.onRemove(file);
    this.csvArray = [];
    return false;
  }
  cleanFiles(except: UploadFile) {
    this.pgUploadComponent.FileList.forEach(file => {
      if (except && except.uid !== file.uid) {
        this.pgUploadComponent.onRemove(file);
      }
    });
    this.csvArray = [];
  }

  async searchFromCSV() {
    const promises = [];
    this.csvArray.forEach(record => {
      const line = record.split(';');
      const productSearchPromise = this.searchProductApi(line[0], line[1])
          .then(searchProductApi => this.buildCsv(line[0], line[1], searchProductApi.response));
      promises.push(productSearchPromise);
    });
    const productSearch = await Promise.all(promises);
    this.pgUploadComponent.onRemove(this.file);
    this.csvArray = [];
    return this.csvService.downloadFile(productSearch, `product_search_${new Date().getTime()}`);
  }

  isRightStep(step: number) {
    return step === this.current_step;
  }

  isDisabledStep(step: number) {
    return step !== this.current_step;
  }

}
