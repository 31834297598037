import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { pgTabComponent } from "../../components/tabs/tab.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "../../components/message/message.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ManagersService } from "../../services/managers.service";
import { ApiDataService } from "../../services/api-data.service";
import { CsvService } from "../../services/csv.service";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import {
  AiClassifyEnum,
  AiClassifyIdentification,
  AiClassifyV4Request,
  AiClassifyV4Response,
} from "../../../models/HsCodeFinder";
import { Builder } from "builder-pattern";

enum REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM {
  SEARCH = 1,
  RESULTS = 2,
}

@Component({
  selector: "app-import-export-requirements",
  templateUrl: "./import-export-requirements.component.html",
  styleUrls: ["./import-export-requirements.component.scss"],
})
export class ImportExportRequirementsComponent implements OnInit {
  REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM = REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM;
  @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;

  countries: { value: string; label: string; iso2: string }[] = [];
  current_step: number;
  searchTypes: { value: string; label: string }[] = [];
  selectedDataTypeUrlSuffix: string;
  searchClickable = true;

  aiClassifyV4Form: FormGroup;
  formIsInvalid = false;
  aiClassifyV4Request: AiClassifyV4Request;
  aiClassifyV4Result: AiClassifyV4Response;

  aiClassifySingleResponseKeys: string[] = [];

  filteredOptions: Observable<string[]> = new Observable<string[]>();

  private unknown_error: string;
  private readonly aiClassifyV4BaseUrl: string;

  constructor(
    private http: HttpClient,
    private _notification: MessageService,
    private translate: TranslateService,
    private managersService: ManagersService,
    private apiDataService: ApiDataService,
    private csvService: CsvService,
    private domSanitizer: DomSanitizer
  ) {
    this.aiClassifyV4BaseUrl = `https://api.${environment.baseUrl}/v4/taxsrv/aiclassify`;
    this.current_step = REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM.SEARCH;
    this.initForm();
  }
  async ngOnInit() {
    this.initTranslation();
    this.apiDataService.getCountries().then(() => {
      this.setCountries();
    });
    this.managersService.prepareProducts();
    this.managersService.prepareCategories();
  }

  private initForm() {
    this.aiClassifyV4Form = new FormGroup({
      type: new FormControl(AiClassifyEnum.TEXT, [Validators.required]),
      product: new FormControl(null, [Validators.required]), // product_text, hscode, sku or category
      from_country: new FormControl(null, [Validators.required]),
      to_country: new FormControl("FRA", [Validators.required]),
    });
  }

  private initTranslation() {
    this.translate
      .get("messages")
      .toPromise()
      .then((messages) => {
        this.unknown_error = messages.notification.unknown_error;
      });

    this.translate
      .get("importExportRequirements")
      .toPromise()
      .then((ttr) => {
        this.searchTypes = ttr.searchTypes;
        this.aiClassifySingleResponseKeys = Object.keys(ttr.results.responseContent);
        console.log('this.aiClassifySingleResponseKeys', this.aiClassifySingleResponseKeys);
      });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setCountries();
      this.unknown_error = event.translations.messages.notification.unknown_error;
      this.searchTypes = event.translations.importExportRequirements.searchTypes;
      this.aiClassifySingleResponseKeys = Object.keys(event.translations.importExportRequirements.results.responseContent);
      console.log('this.aiClassifySingleResponseKeys', this.aiClassifySingleResponseKeys);
    });
  }

  async searchAiClassifyV4(): Promise<void> {
    if (this.aiClassifyV4Form.invalid) {
      this.formIsInvalid = true;
      return;
    }
    // set search button not clickable
    this.searchClickable = false;
    // get form values
    const formValues = this.aiClassifyV4Form.value;
    // build the body request depending on the type (duties, taxes, ...)
    this.aiClassifyV4Request = Builder(AiClassifyV4Request)
      .product({
        identification: Builder(AiClassifyIdentification)
          .type(formValues.type)
          .value(formValues.product)
          .build(),
      })
      .from_country(formValues.from_country)
      .to_country(formValues.to_country)
      .lang(this.translate.currentLang)
      .build();
    // call the API
    await this.callAiClassifyV4();
    // display results tab on success
    this.displayResultsTab();
    this.formIsInvalid = false;
    // set search button clickable
    this.searchClickable = true;
  }
  private async callAiClassifyV4(): Promise<void> {
    try {
      this.aiClassifyV4Result = (await this.http
        .post(this.aiClassifyV4BaseUrl, this.aiClassifyV4Request)
        .toPromise()) as AiClassifyV4Response;
    } catch (errorResponse) {
      const transiteoError = this._notification.buildTransiteoErrorMessage(
        errorResponse.error,
        this.unknown_error
      );
      this._notification.displayMessage(transiteoError, "danger");
      this.aiClassifyV4Result = null;
    }
  }

  // display results tab if we have any results to display
  private displayResultsTab(): void {
    if (this.aiClassifyV4Result) {
      if (this.child) {
        this.child.setLabel(1);
      }
      this.current_step = REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM.RESULTS;
    }
  }
  newSearch() {
    this.current_step = REQUIREMENTS_IMPORT_EXPORT_STEP_ENUM.SEARCH;
    if (this.child) {
      this.child.setLabel(0);
    }
  }

  isActualStep(step: number): boolean {
    return step === this.current_step;
  }

  get toCountries(): { value: string; label: string; iso2: string }[] {
    return this.countries.filter((c) => c.value === "FRA");
  }

  private async setCountries() {
    try {
      // France only for now
      this.countries = this.apiDataService.getCountriesInstant().map((c) => {
        return {
          value: c.value,
          iso2: c.iso2,
          label: c[`label_${this.translate.currentLang}`]
            ? c[`label_${this.translate.currentLang}`] + " (" + c.value + ")"
            : c[`label_en`] + " (" + c.value + ")",
        };
      });
      this.countries.sort((a, b) => {
        return a.label.localeCompare(b.label, this.translate.currentLang, {
          ignorePunctuation: true,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  prepareAutocompleteProduct() {
    const control = this.aiClassifyV4Form.controls["product"];
    this.filteredOptions = control.valueChanges.pipe(
      startWith(""),
      map((value) =>
        this._filter(value, this.managersService.getOptionsProducts(), "sku")
      )
    );
  }
  prepareAutocompleteCategory() {
    const control = this.aiClassifyV4Form.controls["product"];
    this.filteredOptions = control.valueChanges.pipe(
      startWith(""),
      map((value) =>
        this._filter(
          value,
          this.managersService.getOptionsCategories(),
          "category_id"
        )
      )
    );
  }

  // filter used for autocompletion
  private _filter(
    value: string,
    wholeValues: string[],
    filterField: string
  ): string[] {
    if (value !== null && value !== undefined) {
      const filterValue = value.toString().toLowerCase();
      return wholeValues.filter((option) => {
        const optionSplitted = option.split(", ");
        if (filterField === "sku") {
          return (
            optionSplitted[0].toLowerCase().includes(filterValue) ||
            optionSplitted[1].toLowerCase().includes(filterValue)
          );
        }
        let i = 0;
        if (filterField === "email") {
          i = 1;
        } else if (filterField === "website") {
          i = 2;
        }
        return optionSplitted[i].toLowerCase().includes(filterValue);
      });
    }
  }

  onProductClicked(option: string) {
    const sku = option.split(", ")[0];
    const rightProduct = this.managersService
      .getProducts()
      .find((p) => p.sku === sku);
    if (rightProduct) {
      this.aiClassifyV4Form.get("product").setValue(rightProduct.sku);
    }
  }
  onCategoryClicked(option: string) {
    const categoryId = option.split(", ")[0];
    const rightCategory = this.managersService
      .getCategories()
      .find((c) => c.category_id === categoryId);
    if (rightCategory) {
      this.aiClassifyV4Form.get("product").setValue(rightCategory.category_id);
    }
  }

  switchCountries() {
    const tempCountry = this.aiClassifyV4Form.get("from_country").value;
    this.aiClassifyV4Form
      .get("from_country")
      .setValue(this.aiClassifyV4Form.get("to_country").value);
    this.aiClassifyV4Form.get("to_country").setValue(tempCountry);
  }
  isText(): boolean {
    return this.aiClassifyV4Form.get("type").value === AiClassifyEnum.TEXT;
  }
  isHsCode(): boolean {
    return this.aiClassifyV4Form.get("type").value === AiClassifyEnum.HSCODE;
  }
  isSku(): boolean {
    return this.aiClassifyV4Form.get("type").value === AiClassifyEnum.SKU;
  }
  isCategory(): boolean {
    return this.aiClassifyV4Form.get("type").value === AiClassifyEnum.CATEGORY;
  }

  getPgSelectCustomStyle(width: number, marginLeft: number): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(
      `width: ${width}px; margin-left: ${marginLeft}px;`
    );
  }
  exportCsv() {
    const flattenRequest = this.csvService.flattenObjectToBuildCsv(
      "request_",
      this.aiClassifyV4Request
    );
    const flattenResponse = this.csvService.flattenObjectToBuildCsv(
      "response_",
      this.aiClassifyV4Result
    );
    const wholeData = flattenRequest;
    const keys = Object.keys(flattenResponse);
    keys.forEach((key) => {
      wholeData[key] = flattenResponse[key];
    });
    this.csvService.downloadFile(
      wholeData,
      `importExportRequirements_${new Date().getTime()}`
    );
  }
}
