import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkLogin(url, next.queryParams);
  }

  checkLogin(url: string, queryParams): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url.split('?')[0];
      this.authService.queryParams = queryParams;

      // Navigate to the login page with extras
      this.router.navigate(['/login'], { queryParams: queryParams });
      return false;
    }
  }
}
