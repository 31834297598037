import {
    NationalShipment,
    ShipmentRequest, ShipmentResponse,
    ShipmentResponseWithShipper,
    ShipmentsWithShipper,
    ShipmentTypeEnum,
    TransitEnum,
    Transport
} from './Shipment';
import {InternationalShipmentRequestProduct} from './Product';

export class InternationalLabels {
    tracking_number: string;
}

export class InternationalShipmentResponse extends ShipmentResponse {
    shipments: Array<InternationalShipment>;
    taxes_amount: number;
    currency_taxes_amount: string;
    transit_fees_amount: number;
    currency_transit_fees_amount: string;
    global_price: number;
    currency_global_price: string;
}

export class InternationalShipment extends NationalShipment {
    type: ShipmentTypeEnum;
    option_label: string;
    taxes_amount: number;
    currency_taxes_amount: string;
    transit_fees_amount: number;
    currency_transit_fees_amount: string;
}

export class InternationalShipmentRequest extends ShipmentRequest {
    transports: Array<TransportWithProducts>;
    transit: TransitEnum;
    ddp: boolean;
    ddu: boolean;
}

export class TransportWithProducts extends Transport {
    products: Array<InternationalShipmentRequestProduct>;
}

export class InternationalShipmentContainer {
    model: number;
    option: string;
    products: Array<InternationalShipmentRequestProduct>;
}


