import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {DynamoProduct, MarketingProduct, URLImage} from '../../../../models/DynamoProduct';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap';

@Component({
  selector: 'app-marketing-product',
  templateUrl: './marketing-product.component.html',
  styleUrls: ['./marketing-product.component.scss']
})
export class MarketingProductComponent implements OnInit {

  @Input()
  product: DynamoProduct;
  marketingProduct: MarketingProduct;
  @Input()
  lang: string;
  image: URLImage;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.marketingProduct = this.product as MarketingProduct;
  }

  display(p_image) {
    this.image = p_image;
  }
  openModal(template: TemplateRef<any>, url_image) {
    this.image = url_image;
    const config = {class: 'modal-lg', animated: false, backdrop: true} as ModalOptions;
    this.modalRef = this.modalService.show(template, config);
  }
}
