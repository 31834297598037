import { Injectable } from '@angular/core';
import {User} from '../../models/User';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {AuthService} from '../auth/auth.service';
import {Auth} from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private cognitoUser = null;
  private user: User;
  private hubspotDisplayed = false;
  userInfosUrl: string;
  userUpdateUrl: string;
  userDeleteUrl: string;
  constructor(private http: HttpClient) {
    this.userInfosUrl = `https://api.${environment.baseUrl}/v1/user/info`;
    this.userUpdateUrl = `https://api.${environment.baseUrl}/v1/user/update`;
    this.userDeleteUrl = `https://api.${environment.baseUrl}/v1/user/delete`;
  }
  public isConnected = () => {
    return this.cognitoUser != null;
  }
  public setCognitoUser = (user) => {
    this.cognitoUser = user;
  }
  public getCognitoUser = (): CognitoUser => {
    return this.cognitoUser;
  }

  private displayHubspotChat(): void {
    if (
      this.user
      &&
      (
        this.user.planIsSaas()
        ||
        (
          (
            this.user.planIsApi()
            ||
            this.user.planIsEcommerce()
          )
          &&
          this.user.consumptions
          &&
          this.user.consumptions[0].technicalSupport
          &&
          this.user.consumptions[0].technicalSupport.remaining_hours > 0
        )
      )
    ) {
      console.log('HUBSPOT CHAT is now displayed');
      (window as any).HubSpotConversations.widget.load();
      this.hubspotDisplayed = true;
    }
  }

  async initTransiteoUser(fromCache = false): Promise<User> {
    const withCacheUrl = fromCache ? `${this.userInfosUrl}?fromCache=true` : this.userInfosUrl;
    return this.http.get(withCacheUrl).toPromise().then((user: User) => {
      this.user = Object.assign(new User(), user);
      if (! this.hubspotDisplayed) {
        this.displayHubspotChat();
      }
      return this.user;
    });
  }
  async updateTransiteoUser(updateUser: User): Promise<User> {
    return this.http.post(this.userUpdateUrl, updateUser).toPromise().then((user: User) => {
      this.user = Object.assign(new User(), user);
      return this.user;
    });
  }
  async deleteTransiteoUser() {
    return this.http.delete(this.userDeleteUrl).toPromise().then(() => {
      Auth.signOut()
          .then(() => {
            this.setCognitoUser(null);
            this.setUser(null);
          });
    });
  }

  // the user is an admin?
  isUserAnAdmin(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.user) {
        if ( this.user.isAdmin() ) {
          resolve(true);
        }
      } else {
        this.initTransiteoUser(true).then(user => {
          if (user) {
            if ( user.isAdmin() ) {
              resolve(true);
            }
          }
          resolve(false);
        })
        .catch(error => {
          resolve(false);
        });
      }
    });
  }

  getUser(): User {
    return this.user;
  }
  getUserPromise(): Promise<User> {
    return new Promise(resolve => {
      if (this.user) {
        resolve(this.user);
      }
      const interval = setInterval(() => {
        if (this.user) {
          clearInterval(interval);
          resolve(this.user);
        }
      }, 100);
    });
  }
  setUser(user: User) {
    this.user = user;
  }
}
