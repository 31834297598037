import {Component, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

export enum DOCUMENTATIONS_GUIDES {
    API = 'api',
    SAAS = 'saas',
    ECOMMERCE = 'ecommerce',
}
enum DOCUMENTATIONS_GUIDES_URLS {
    api = 'https://transiteo.gitbook.io/transiteo/',
    saas = 'https://transiteo.gitbook.io/web-application-guide/',
    ecommerce = 'https://transiteo.gitbook.io/cms-plugins-guide/',
    ecommerce_prestashop = 'https://transiteo.gitbook.io/prestashop-guide-english/',
    ecommerce_magento = 'https://transiteo.gitbook.io/magento-2-guide/',
    ecommerce_woocommerce = 'https://transiteo.gitbook.io/woocommerce-guide/',
    ecommerce_shopify = 'https://transiteo.gitbook.io/shopify/',
    ecommerce_bigcommerce = 'https://transiteo.gitbook.io/bigcommerce-guide/',
}
@Component({
    selector: 'app-documentation-guide',
    templateUrl: './documentation-guide.component.html',
    styleUrls: ['./documentation-guide.component.scss']
})
export class DocumentationGuideComponent implements OnInit {
    url: SafeResourceUrl;

    constructor(
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        // get the type (import / export) and reinitialize all
        this.route.paramMap.subscribe(async params => {
            const planTypeDocType: DOCUMENTATIONS_GUIDES = params.get('urlType') as DOCUMENTATIONS_GUIDES;
            let urlGuide = DOCUMENTATIONS_GUIDES_URLS[planTypeDocType] ? DOCUMENTATIONS_GUIDES_URLS[planTypeDocType] : DOCUMENTATIONS_GUIDES_URLS.saas
            if (planTypeDocType === DOCUMENTATIONS_GUIDES.ECOMMERCE) {
                const user = await this.userService.getUserPromise();
                if (user && user.ecommerceProperties && user.ecommerceProperties.account) {
                    const integrationMethod = user.ecommerceProperties.account.integration_method;
                    switch (integrationMethod) {
                        case 'Prestashop 1.7.X':
                            urlGuide = DOCUMENTATIONS_GUIDES_URLS.ecommerce_prestashop;
                            break;
                        case 'Magento 2.X':
                            urlGuide = DOCUMENTATIONS_GUIDES_URLS.ecommerce_magento;
                            break;
                        case 'Woocommerce':
                            urlGuide = DOCUMENTATIONS_GUIDES_URLS.ecommerce_woocommerce;
                            break;
                        case 'Shopify':
                            urlGuide = DOCUMENTATIONS_GUIDES_URLS.ecommerce_shopify;
                            break;
                        case 'BigCommerce':
                            urlGuide = DOCUMENTATIONS_GUIDES_URLS.ecommerce_bigcommerce;
                            break;
                    
                        default:
                            break;
                    }
                }
            }
            this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(urlGuide);
        });
    }
}
