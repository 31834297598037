import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { User } from '../../../models/User';
import { UserService } from '../../services/user.service';
import { Utils } from '../../utils/utils';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HsCodeFinderRequest, HsCodeFinderResult } from '../../../models/HsCodeFinder';
import { TaxResponse } from '../../../models/DutyCalculation';
import { ToolsService } from '../../services/tools.service';
import { plainToClass } from 'class-transformer';
import { normalize } from 'normalize-diacritics';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Builder } from 'builder-pattern';
import { CustomerProduct, CustomerProductsProcessRequest, CUSTOMER_PRODUCT_TYPE_ENUM, FormatCsv, PRODUCTS_SELECTED_ROWS_ACTIONS, ProductHscodeContent } from '../../../models/Managers';
import { CsvService } from '../../services/csv.service';
import { TaxContent } from '../../../models/TradeTariffRates';
import { ManagersService } from '../../services/managers.service';
import { CsvFieldToSet } from '../../components/csv-mapper/csv-mapper.component';
import { ApiDataService } from '../../services/api-data.service';

const districts = require('../../../../assets/data/districtsGroupByIsoCountry.json');
const us_states = require('../../../../assets/data/US_states.json');
const countriesPerContinent = require('../../../../assets/data/countriesPerContinent.json');

@Component({
    selector: 'app-product-manager',
    templateUrl: './product-manager.component.html',
    styleUrls: ['./product-manager.component.scss'],
})
export class ProductManagerComponent implements OnInit {
    @ViewChild('fillrequiredfields', { static: false }) fillRequiredFieldsModal: TemplateRef<any>;
    @ViewChild('pop_hs_code', { static: false }) pop_hs_code;
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    @ViewChild('confirmDeleteRow', { static: false }) confirmDeleteRow: TemplateRef<any>;
    @ViewChild('confirmDeleteRows', { static: false }) confirmDeleteRows: TemplateRef<any>;
    @ViewChild('addrow', { static: false }) editrow: TemplateRef<any>;
    @ViewChild('editmultiplerows', { static: false }) editmultiplerows: TemplateRef<any>;

    displayStateSelection = -1;
    chooseUSAStateForm: FormGroup;
    rowForm: FormGroup;
    actionRequiredFieldsForm: FormGroup;
    formIsInvalid = false;

    filterForm: FormGroup;
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    rowToEditId: string;
    toCountrySubscription: Subscription;
    toStateSubscription: Subscription;

    closingModal = true;

    filteredWholePossibleColumns: Observable<string[]>[];
    currentColumnsToFill: string[] = [];

    // used to know if we must delete calculations already done or not
    editRowInitialState: any = null;
    columnsChanged: string[] = [];

    // display the pg-upload component
    displayUploadCsvComponent = false;

    products: CustomerProduct[] = [];
    rows: any[];
    selectedRows: any[] = [];
    predefinedColumns: any;
    predefinedPrivateColumns: any;
    undisplayedColumns: {label: string; value: string;}[];
    columnsToDisplay: {label: string; value: string;}[];
    keysToNotDisplay = ['id', 'to_countries', 'hscodefinder_designation', 'hscodefinder_hs_version', 'product_statut'];
    columnsToDisplayPerPlan: { [key: string]: string[]; } = {};
    // list of selectable columns
    wholePossibleColumns: string[] = [];
    privateColumns: any;
    // expanded rows contents (display to_countries)
    // as {sku_001: {FR: {...}, ES: {...}, ...}, sku_002: {...}}
    toCountriesDetails: { [key: string]: { [key: string]: ProductHscodeContent } } = {};
    formattedToCountriesDetailsForTable: { [key: string]: { [key: string]: any }[] } = {};
    toCountriesDetailsColumns: string[] = [];
    toCountriesDetailsColumnsLabels: { [key: string]: string } = {};

    modalErrorMessage = '';

    filterCountry = 'ALL_COUNTRIES';
    countries: Array<any> = [];
    us_states: Array<{}>;
    unit_weight_types: Array<any>;
    currencies: Array<any>;
    districts: Array<any>;
    customer_products_types: Array<CUSTOMER_PRODUCT_TYPE_ENUM> = [];

    modalRef: BsModalRef;

    selectedAction = 0;
    availableActions: string[] = [];
    actionInProgress = false;
    mandatoryFieldsForActions: any;
    // as {action:"hsCodeFinder|dutyCalculation|marginCalculation", ids:["azertyuiop123","qwerty456","other_id",...]}
    rowsToFillBeforeAction: {action: string; ids: string[];} = {action: '', ids: []};
    skusToProcess: string[];

    lang: string;
    user: User;

    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    messages: any;
    warningDeletionSuffixes: any;

    // custom table necessary vars
    displayRowOnly = false;
    rowsToDelete: string[] = [];
    productToDeleteSku: string;
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];
    requestSelectedRowsSubject: Subject<void> = new Subject<void>();
    selectedRowsNextAction: PRODUCTS_SELECTED_ROWS_ACTIONS;

    continentsWithCountries: {
        AS: string[];
        EU: string[];
        AF: string[];
        NA: string[];
        SA: string[];
        OC: string[];
        AN: string[];
    } = {AS: [], EU: [], AF: [], NA: [], SA: [], OC: [], AN: []};

    expectedCsvContent: FormatCsv;
    displayCsvMapper: Subject<void> = new Subject<void>();
    csvModelFields: CsvFieldToSet[] = [];
    externalCsvFields: string[] = [];

    constructor(
        private apiDataService: ApiDataService,
        private csvService: CsvService,
        private authService: AuthService,
        private http: HttpClient,
        private _notification: MessageService,
        private userService: UserService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private formBuilder: FormBuilder,
        private managersService: ManagersService,
        private toolsService: ToolsService
    ) {
        this.us_states = us_states;
        // only SKU available
        this.customer_products_types = [CUSTOMER_PRODUCT_TYPE_ENUM.SKU/*, CUSTOMER_PRODUCT_TYPE_ENUM.TEXT*/];
        this.continentsWithCountries = {...countriesPerContinent} as {
            AS: string[];
            EU: string[];
            AF: string[];
            NA: string[];
            SA: string[];
            OC: string[];
            AN: string[];
        };

        this.chooseUSAStateForm = this.formBuilder.group({
            selectState: [null, Validators.required]
        });
        this.rowForm = this.formBuilder.group({
            type: [CUSTOMER_PRODUCT_TYPE_ENUM.TEXT, Validators.required],
            productID: ['', Validators.required],
            to_countries: this.formBuilder.array([]),
            categories_id: this.formBuilder.array([]),
            weight: ['', Validators.required],
            weight_unit: ['', Validators.required],
            columnsToFill: this.formBuilder.array([])
        });
        this.actionRequiredFieldsForm = this.formBuilder.group({
            id: ['', Validators.required],
            productID: ['', Validators.required],
            weight: ['', Validators.required],
            weight_unit: ['', Validators.required]
        });
        this.filterForm = this.formBuilder.group({
            filter: ['']
        });

        this.mandatoryFieldsForActions = {
            dutyCalculation: [
                'quantity',
                'unit_price',
                'unit_ship_price',
                'currency_unit_price',
                'from_country',
                'to_country',
                'revenue_country_annual',
                'currency_revenue_country_annual'
            ],
            hsCodeFinder: [
                'to_country'
            ],
            marginCalculation: [
                [
                    'sale_unit_price',
                    'sale_currency_unit_price'
                ],
                [
                    'purchase_unit_price',
                    'purchase_currency_unit_price'
                ]
            ],
            costPrice: [
                [
                    'quantity',
                    'unit_price',
                    'currency_unit_price',
                    'currency_ship_price',
                    'unit_ship_price'
                ],
                [
                    'quantity',
                    'unit_price',
                    'currency_unit_price',
                    'currency_ship_price',
                    'global_ship_price'
                ]
            ],
            tradeTariffRatesDuties: [
                'from_country',
                'hscodefinder_hs_code',
                'to_country'
            ],
            tradeTariffRatesTaxes: [
                'hscodefinder_hs_code',
                'to_country'
            ],
            tradeTariffRatesSpecialTaxes: [
                'hscodefinder_hs_code',
                'to_country'
            ],
            ecoTax: [
                'to_country'
            ],
            unCodes: []
        };

        this.columnsToDisplay = [];
        this.undisplayedColumns = [];
        this.rows = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [];
        if (this.rows.length > 0) {
            this.initRowsDetails();
        }
    }
    async ngOnInit() {
        await this.initUserPrefs();
        this.initTranslation();
        this.retrieveProducts();
        this.managersService.prepareCategories();
    }

    // show / hide pg-upload component
    switchDisplayUploadCsvComponent() {
        this.displayUploadCsvComponent = !this.displayUploadCsvComponent;
    }

    // is the table empty?
    emptyRows(): boolean {
        if (! this.rows || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    get filterProductContent() {
        return this.filterForm.get('filter').value;
    }

    prepareAutocompleteProduct() {
        const control = this.filterForm.get('filter');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this.filterProduct(value, this.managersService.getOptionsProducts())),
        );
    }
    // filter used for autocompletion filter form
    private filterProduct(value: string, wholeValues: string[]): string[] {
        const filterValue = value.toLowerCase();
        this.selectedRows = [];
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
        });
    }

    prepareAutocompleteCategory(index: number) {
        const control = this.categoriesIdsForm.at(index).get('value');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this.filterCategory(value, this.managersService.getOptionsCategories(), 'sku')),
        );
    }
    // filter used for autocompletion
    private filterCategory(value: string, wholeValues: string[], filterField: string): string[] {
        if (value !== null && value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            return wholeValues.filter(option => {
                const optionSplitted = option.split(', ');
                if (filterField === 'sku') {
                    return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
                }
                let i = 0;
                if (filterField === 'email') {
                    i = 1;
                } else if (filterField === 'website') {
                    i = 2;
                }
                return optionSplitted[i].toLowerCase().includes(filterValue);
            });
        }
    }
    onCategoryClicked(index: number, option: string) {
        // category_id is the sort key
        const category_id = option.split(', ')[0];
        const rightCategory = this.managersService.getCategories().find(c => c.category_id === category_id);
        if (rightCategory) {
            this.categoriesIdsForm.at(index).get('value').setValue(rightCategory.category_id);
        }
    }
    get categoriesIdsForm(): FormArray {
        return this.rowForm.get('categories_id') as FormArray;
    }
    onDeleteProductCategoryId(i: number, editMultipleRows = false) {
        if (editMultipleRows && this.categoriesIdsForm && this.categoriesIdsForm.length === 1 && i === 0) {
            this.onRemoveCategoriesIdControl();
            return;
        }
        this.categoriesIdsForm.removeAt(i);
    }
    onAddProductCategoryId(id: string = '') {
        this.categoriesIdsForm.push(this.formBuilder.group({
            value: [id, Validators.required]
        }));
    }

    get toCountriesForm(): FormArray {
        return this.rowForm.get('to_countries') as FormArray;
    }
    onDeleteProductToCountries(i: number, editMultipleRows = false) {
        if (editMultipleRows && this.toCountriesForm && this.toCountriesForm.length === 1 && i === 0) {
            this.onRemoveToCountriesControl();
            return;
        }
        this.toCountriesForm.removeAt(i);
    }
    onAddProductToCountries(countryIso?: string, hscode?: string) {
        this.toCountriesForm.push(this.formBuilder.group({
            country: [countryIso, Validators.required],
            hscode: [hscode, Validators.required]
        }));
    }
    // add to_countries control, used when editing multiple rows
    onAddToCountriesControl() {
        this.rowForm.addControl(
            'to_countries',
            this.formBuilder.array([])
        );
        this.onAddProductToCountries();
    }
    onRemoveToCountriesControl() {
        this.rowForm.removeControl('to_countries');
    }

    async retrieveProducts(prods: CustomerProduct[] = null) {
        this.products = prods ? prods : await this.getProducts();
        // used to handle new to_countries field
        this.products.forEach(product => {
            if (product.hscodefinder_hs_code && product.to_country && (! product.to_countries || ! product.to_countries[product.to_country])) {
                if (! product.to_countries) {
                    product.to_countries = {};
                }
                product.to_countries[product.to_country] = Builder(ProductHscodeContent)
                                                        .hscodefinder_hs_code(product.hscodefinder_hs_code)
                                                        .hscodefinder_designation(product.hscodefinder_designation)
                                                        .hscodefinder_hs_version(product.hscodefinder_hs_version)
                                                        .product_statut(product.product_statut)
                                                        .build()
            }
        });
        this.managersService.setProducts(this.products);
        if (! prods) {
            this.rows = [];
            this.toCountriesDetails = {};
            this.formattedToCountriesDetailsForTable = {};
        }
        this.products.forEach((product) => {
            if (product.value) {
                const row = {
                    id: product.id, // id to use for API requests
                    type: product.type,
                    productID: product.value,
                    sku: product.sku,
                    to_countries: product.to_countries,
                    categories_id: product.categories_id,
                    weight: product.weight,
                    weight_unit: product.weight_unit
                };
                const pKeys = Object.keys(product);
                pKeys.forEach(k => {
                    if (!['id', 'type', 'value', 'sku', 'to_countries', 'categories_id', 'weight', 'weight_unit', 'creationDate', 'hscodefinder', 'dutyCalculation', 'locals_taxes', 'other_taxes'].includes(k)) {
                        if ((k === 'unit' || k === 'unit_type')) {
                            if (product[k]) {
                                row[k] = product[k];
                            }
                        } else {
                            row[k] = product[k];
                        }
                        this.appendColInColumnsToDisplay(k);
                    } else if (k === 'locals_taxes' || k === 'other_taxes') {
                        const arr = (product[k] as TaxContent[]);
                        for (let i = 0; i < arr.length; i++) {
                            row[`${k}_label_${i + 1}`] = arr[i].label;
                            row[`${k}_percentage_${i + 1}`] = arr[i].percentage;
                            this.appendColInColumnsToDisplay(`${k}_label_${i + 1}`);
                            this.appendColInColumnsToDisplay(`${k}_percentage_${i + 1}`);
                        }
                    }
                });

                /*const rowIndex: number = this.rows.findIndex(r => r.id === row.id);
                // row exists, compare the distant and local rows here
                if (rowIndex >= 0) {
                    this.rows[rowIndex] = row;
                } else {
                    this.rows.push(row);
                }*/

                const index = this.rows.findIndex(r => r.sku === row.sku);
                if (index >= 0) {
                    this.rows[index] = row;
                } else {
                    this.rows.push(row);
                }
            }
        });
        this.rowsHaveChanged();
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
            this.messages = messages.notification.productsManager;
        });

        this.translate.get('unitTypes').toPromise().then(unit_types => {
          this.unit_weight_types = unit_types.weight;
        });

        // get currencies
        this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies;
            // get countries
            this.translate.get('dutyCalculation.to_country').toPromise().then((all_countries) => {
                const countries = all_countries.sort(Utils.compareByLabel);
                this.countries = countries;
                this.initFormatColumnsValues();
                this.countries.unshift({
                    label: this.translate.instant('productsManager.allCountries'),
                    value: 'ALL_COUNTRIES',
                    Iso2: 'ALL_COUNTRIES'
                });
            });
        });

        this.translate.get('productsManager').toPromise().then((productsManager) => {
            this.initializeColumnsLanguages(productsManager);
            this.columnsToDisplayPerPlan = productsManager.columnsToDisplayPerPlan

            this.columnsToDisplay =
                (typeof localStorage.getItem('columnsToDisplay') === 'string' && localStorage.getItem('columnsToDisplay').length > 0) ?
                JSON.parse(localStorage.getItem('columnsToDisplay')) :
                [
                    {label: this.privateColumns.type, value: 'type'},
                    {label: this.privateColumns.sku, value: 'sku'},
                    {label: this.privateColumns.productID, value: 'productID'},
                    {label: this.privateColumns.weight, value: 'weight'},
                    {label: this.privateColumns.weight_unit, value: 'weight_unit'},
                    {label: this.privateColumns.categories_id, value: 'categories_id'}
                ];
            const keys: string[] = Object.keys(this.privateColumns);
            let i = 0;
            while (i < this.columnsToDisplay.length) {
                if (keys.includes(this.columnsToDisplay[i].value)) {
                    this.columnsToDisplay[i].label = this.privateColumns[this.columnsToDisplay[i].value];
                }
                i += 1;
            }
            this.undisplayedColumns =
                (typeof localStorage.getItem('undisplayedColumns') === 'string' && localStorage.getItem('undisplayedColumns').length > 0) ?
                JSON.parse(localStorage.getItem('undisplayedColumns')) :
                [];

            // append every missing private column in columnsToDisplay
            Object.keys(this.privateColumns).forEach(key => {
                if (! this.keysToNotDisplay.includes(key) && ! this.columnsToDisplay.findIndex(col => col.value === key) && ! this.undisplayedColumns.findIndex(col => col.value === key)) {
                    this.appendColInColumnsToDisplay(key);
                }
            });
            // remove keys to not display if already in columnToDisplay
            this.keysToNotDisplay.forEach(key => {
                let index = this.columnsToDisplay.findIndex(col => col.value === key);
                if (index >= 0) {
                    this.columnsToDisplay.splice(index, 1);
                }
                index = this.undisplayedColumns.findIndex(col => col.value === key);
                if (index >= 0) {
                    this.undisplayedColumns.splice(index, 1);
                }
            });

            this.toCountriesDetailsColumnsLabels = productsManager.toCountriesRowDetails;
            this.toCountriesDetailsColumns = Object.keys(this.toCountriesDetailsColumnsLabels);
        });

        // local storage has been wiped, get back the keys from rows
        if (this.columnsToDisplay.length === 6 && this.undisplayedColumns.length === 0 && this.rows.length > 0) {
            const keysPredefinedColumns: string[] = Object.keys(this.predefinedColumns);
            const keysPrivateColumns: string[] = Object.keys(this.privateColumns);
            this.rows.forEach(row => {
                const keys = Object.keys(row);
                keys.forEach(key => {
                    if (
                        ! this.keysToNotDisplay.includes(key) &&
                        ! ['type', 'sku', 'productID', 'weight', 'weight_unit', 'categories_id'].includes(key) &&
                        this.undisplayedColumns.findIndex(col => col.value === key) === -1
                    ) {
                        if (keysPredefinedColumns.includes(key)) {
                            this.undisplayedColumns.push({label: this.predefinedColumns[key].label, value: key});
                        } else if (keysPrivateColumns.includes(key)) {
                            this.undisplayedColumns.push({label: this.privateColumns[key], value: key});
                        } else {
                            this.undisplayedColumns.push({label: key, value: key});
                        }
                    }
                });
            });
        }

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
            this.messages = messages.notification.productsManager;

            this.unit_weight_types = event.translations['unitTypes']['weight'];

            this.currencies = event.translations['currency_name'];

            const countries = event.translations['dutyCalculation']['to_country'].sort(Utils.compareByLabel);
            this.countries = countries;
            this.initFormatColumnsValues();
            this.countries.unshift({
                label: this.translate.instant('productsManager.allCountries'),
                value: 'ALL_COUNTRIES',
                Iso2: 'ALL_COUNTRIES'
            });

            this.initializeColumnsLanguages(event.translations.productsManager);

            const keys: string[] = Object.keys(this.privateColumns);
            let i = 0;
            while (i < this.columnsToDisplay.length) {
                if (keys.includes(this.columnsToDisplay[i].value)) {
                    this.columnsToDisplay[i].label = this.privateColumns[this.columnsToDisplay[i].value];
                }
                i += 1;
            }

            i = 0;
            while (i < this.undisplayedColumns.length) {
                if (keys.includes(this.undisplayedColumns[i].value)) {
                    this.undisplayedColumns[i].label = this.privateColumns[this.undisplayedColumns[i].value];
                }
                i += 1;
            }

            this.toCountriesDetailsColumnsLabels = event.translations.productsManager.toCountriesRowDetails;
            this.toCountriesDetailsColumns = Object.keys(this.toCountriesDetailsColumnsLabels);
        });
    }

    initializeColumnsLanguages(productsManager: any) {
        this.availableActions = [
            productsManager.actions.select,
            productsManager.actions.hsCodeFinder,
            productsManager.actions.dutyCalculation,
            productsManager.actions.marginCalculation,
            productsManager.actions.tradeTariffRatesDuties,
            productsManager.actions.tradeTariffRatesTaxes,
            productsManager.actions.tradeTariffRatesSpecialTaxes,
            productsManager.actions.ecoTax,
            productsManager.actions.unCodes,
            productsManager.actions.costPrice
        ];

        this.warningDeletionSuffixes = productsManager.warningDeletionSuffixes;

        this.predefinedColumns = {
            'quantity': {
                label: productsManager.inputForm.quantity,
                type: 'number'
            },
            'unit_price': {
                label: productsManager.inputForm.unitPrice,
                type: 'textWithTwoDigits'
            },
            'currency_unit_price': {
                label: productsManager.inputForm.currencyUnitPrice,
                type: 'selectCurrency'
            },
            'unit_ship_price': {
                label: productsManager.inputForm.unitShipPrice,
                type: 'textWithTwoDigits'
            },
            'global_ship_price': {
                label: productsManager.inputForm.globalShipPrice,
                type: 'textWithTwoDigits'
            },
            'currency_ship_price': {
                label: productsManager.inputForm.currencyShipPrice,
                type: 'selectCurrency'
            },
            'sale_unit_price': {
                label: productsManager.inputForm.saleUnitPrice,
                type: 'textWithTwoDigits'
            },
            'sale_currency_unit_price': {
                label: productsManager.inputForm.saleCurrencyUnitPrice,
                type: 'selectCurrency'
            },
            'purchase_unit_price': {
                label: productsManager.inputForm.purchaseUnitPrice,
                type: 'textWithTwoDigits'
            },
            'purchase_currency_unit_price': {
                label: productsManager.inputForm.purchaseCurrencyUnitPrice,
                type: 'selectCurrency'
            },
            'origin_country': {
                label: productsManager.inputForm.originCountry,
                type: 'selectCountry'
            },
            'from_country': {
                label: productsManager.inputForm.fromCountry,
                type: 'selectCountry'
            },
            'to_country': {
                label: productsManager.inputForm.toCountry,
                type: 'selectCountry'
            },
            'to_district': {
                label: productsManager.inputForm.toDistrict,
                type: 'selectDistrict',
            },
            'revenue_country_annual': {
                label: productsManager.inputForm.revenueCountryAnnual,
                type: 'textWithTwoDigits'
            },
            'currency_revenue_country_annual': {
                label: productsManager.inputForm.currencyRevenueCountryAnnual,
                type: 'selectCurrency'
            },
            'hscodefinder_hs_code': {
                label: productsManager.inputForm.hscodefinder_hs_code,
                type: 'text'
            }
        };

        this.predefinedPrivateColumns = {
            'weight': {
                label: productsManager.inputForm.weight,
                type: 'number'
            },
            'weight_unit': {
                label: productsManager.inputForm.weightUnit,
                type: 'selectWeightUnit'
            }
        };

        const keys = Object.keys(this.predefinedColumns);
        // remove only predefined columns if already defined
        this.wholePossibleColumns.splice(0, keys.length);

        // add translated possible columns
        let i = 0;
        while (i < keys.length) {
            this.wholePossibleColumns.splice(i, 0, this.predefinedColumns[keys[i]].label);
            i += 1;
        }

        // set private columns
        if (productsManager.inputForm) {
            this.privateColumns = productsManager.calculationResults;
            this.privateColumns.id = 'id';
            this.privateColumns.weight = productsManager.inputForm.weight;
            this.privateColumns.weight_unit = productsManager.inputForm.weightUnit;
            this.privateColumns.productID = productsManager.inputForm.productID;
            this.privateColumns.type = productsManager.inputForm.type;
            this.privateColumns.sku = productsManager.inputForm.sku;
            this.privateColumns.unit = productsManager.inputForm.unit;
            this.privateColumns.unit_type = productsManager.inputForm.unit_type;
            this.privateColumns.to_countries = productsManager.inputForm.to_countries;
            this.privateColumns.categories_id = productsManager.inputForm.categories_id;
        }
    }

    selectAction(type: number) {
        this.selectedAction = type;
    }

    async onValidAction() {
        this.actionInProgress = true;
        // get ids first as selectedRows could be changed while action is in progress
        const ids: string[] = [];
        let rowsParsed = 0;
        this.selectedRows.forEach((row) => {
            ids.push(row.id);
        });

        switch (this.selectedAction) {
            // HS CODE FINDER
            case 1:
                // OLD WAY WHEN to_countries FIELD DID NOT EXIST
                // check first which rows needs some fields to be fill ONLY for non E-COMMERCE plans for HS CODE FINDER
                /*if (! this.user.planIsEcommerce()) {
                    ids.forEach((id) => {
                        const row: any = this.rows.find(r => r.id === id);
                        if (row) {
                            const cols: string[] = Object.keys(row);
                            let i = 0;
                            while (
                                i < this.mandatoryFieldsForActions.hsCodeFinder.length &&
                                cols.includes(this.mandatoryFieldsForActions.hsCodeFinder[i]) &&
                                row[this.mandatoryFieldsForActions.hsCodeFinder[i]]
                            ) {
                                i += 1;
                            }

                            // at least 1 mandatory field is missing, the user must fill all missing required fields
                            if (i < this.mandatoryFieldsForActions.hsCodeFinder.length) {
                                if ( this.rowsToFillBeforeAction.action === '') {
                                    this.rowsToFillBeforeAction = {action: 'hsCodeFinder', ids: []};
                                }
                                this.rowsToFillBeforeAction.ids.push(id);
                            }
                        }
                        if (this.rowsToFillBeforeAction.action === '') {
                            this.rowsToFillBeforeAction = {action: 'hsCodeFinder', ids: []};
                        }
                        this.rowsToFillBeforeAction.ids.push(id);
                    });
                }

                ids.forEach(async (id) => {
                    // no required columns to fill to execute the action
                    if (!this.rowsToFillBeforeAction.ids.includes(id)) {
                        await this.searchHsCodeFinderAndStoreIt(this.rows.find(row => row.id === id));
                    }
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 0;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'hsCodeFinder' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(this.fillRequiredFieldsModal, 'hsCodeFinder', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]));
                        }
                    }
                });*/
                this.rowsToFillBeforeAction = {action: 'hsCodeFinder', ids};
                this.openModal(this.fillRequiredFieldsModal, 'hsCodeFinder');

                break;

            // DUTY CALCULATION
            case 2:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < this.mandatoryFieldsForActions.dutyCalculation.length && cols.includes(this.mandatoryFieldsForActions.dutyCalculation[i])) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.dutyCalculation.length || (['USA', 'BRA', 'CAN'].includes(row.to_country) && !row.to_district)) {
                            if (this.rowsToFillBeforeAction.action === '') {
                                this.rowsToFillBeforeAction = {action: 'dutyCalculation', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                ids.forEach(async (id) => {
                    // no required columns to fill to execute the action
                    if (!this.rowsToFillBeforeAction.ids.includes(id)) {
                        await this.calcDutyCalculationAndStoreIt(this.rows.find(row => row.id === id));
                    }
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 0;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'dutyCalculation' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(this.fillRequiredFieldsModal, 'dutyCalculation', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]));
                        }
                    }
                });

                break;

            // MARGIN CALCULATION
            case 3:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);

                        // mandatory fields are missing
                        /*if (
                            (
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[0][0]) ||
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[0][1])
                            )
                            &&
                            (
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[1][0]) ||
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[1][1])
                            )
                        ) {
                            if (this.rowsToFillBeforeAction.action === '') {
                                this.rowsToFillBeforeAction = {action: 'marginCalculation', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }*/
                        if (
                            (
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[0][0]) ||
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[0][1]) ||
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[1][0]) ||
                                !cols.includes(this.mandatoryFieldsForActions.marginCalculation[1][1])
                            )
                        ) {
                            if (this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'marginCalculation', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    const rightSkus = this.selectedRows.filter(r => {
                        if (!this.rowsToFillBeforeAction.ids.includes(r.id)) {
                            const product = this.products.find(p => p.sku === r.sku);
                            if (! product || (! product.tariff && ! product.order_duty_minimis) || ! product.locals_taxes || ! product.other_taxes) {
                                this._notification.displayMessage(r.productID + ' ===> ' + this.messages.marginCalculationRequirements, 'danger', 10000);
                                return false;
                            }
                            return true;
                        }
                        return false;
                    }).map(r => r.sku);

                    if (rightSkus.length > 0) {
                        // no required columns to fill to execute the action
                        await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                            rightSkus,
                            'marginCalculation'
                        );
                    }
                }

                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 0;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'marginCalculation' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(this.fillRequiredFieldsModal, 'marginCalculation', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]));
                        }
                    }
                });

                break;

            // TRADE TARIFF RATES DUTIES
            case 4:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < cols.length) {
                            if (row[cols[i]] === undefined || row[cols[i]] === null || row[cols[i]].toString().length === 0) {
                                cols.splice(i, 1);
                            } else {
                                i += 1;
                            }
                        }
                        i = 0;
                        while (
                            i < this.mandatoryFieldsForActions.tradeTariffRatesDuties.length &&
                            cols.includes(this.mandatoryFieldsForActions.tradeTariffRatesDuties[i])
                        ) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.tradeTariffRatesDuties.length) {
                            if ( this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'tradeTariffRatesDuties', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    // no required columns to fill to execute the action
                    await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                        this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).map(r => r.sku),
                        'duties'
                    );
                }

                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 4;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesDuties' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(
                                this.fillRequiredFieldsModal,
                                'tradeTariffRatesDuties',
                                this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0])
                            );
                        }
                    }
                });
                break;

            // TRADE TARIFF RATES TAXES
            case 5:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < cols.length) {
                            if (row[cols[i]] === undefined || row[cols[i]] === null || row[cols[i]].toString().length === 0) {
                                cols.splice(i, 1);
                            } else {
                                i += 1;
                            }
                        }
                        i = 0;
                        while (
                            i < this.mandatoryFieldsForActions.tradeTariffRatesTaxes.length &&
                            cols.includes(this.mandatoryFieldsForActions.tradeTariffRatesTaxes[i])
                        ) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.tradeTariffRatesTaxes.length) {
                            if (this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'tradeTariffRatesTaxes', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    // no required columns to fill to execute the action
                    await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                        this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).map(r => r.sku),
                        'taxes'
                    );
                }

                ids.forEach((id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 5;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesTaxes' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(
                                this.fillRequiredFieldsModal,
                                'tradeTariffRatesTaxes',
                                this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0])
                            );
                        }
                    }
                });
                break;

            // TRADE TARIFF RATES SPECIAL TAXES
            case 6:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < cols.length) {
                            if (row[cols[i]] === undefined || row[cols[i]] === null || row[cols[i]].toString().length === 0) {
                                cols.splice(i, 1);
                            } else {
                                i += 1;
                            }
                        }
                        i = 0;
                        while (
                            i < this.mandatoryFieldsForActions.tradeTariffRatesSpecialTaxes.length &&
                            cols.includes(this.mandatoryFieldsForActions.tradeTariffRatesSpecialTaxes[i])
                        ) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.tradeTariffRatesSpecialTaxes.length) {
                            if ( this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'tradeTariffRatesSpecialTaxes', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    // no required columns to fill to execute the action
                    await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                        this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).map(r => r.sku),
                        'specialtaxes'
                    );
                }

                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 6;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesSpecialTaxes' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(
                                this.fillRequiredFieldsModal,
                                'tradeTariffRatesSpecialTaxes',
                                this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0])
                            );
                        }
                    }
                });
                break;

            // ECO TAX
            case 7:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < cols.length) {
                            if (row[cols[i]] === undefined || row[cols[i]] === null || row[cols[i]].toString().length === 0) {
                                cols.splice(i, 1);
                            } else {
                                i += 1;
                            }
                        }
                        i = 0;
                        while (
                            i < this.mandatoryFieldsForActions.ecoTax.length &&
                            cols.includes(this.mandatoryFieldsForActions.ecoTax[i])
                        ) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.ecoTax.length) {
                            if ( this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'ecoTax', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    // no required columns to fill to execute the action
                    await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                        this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).map(r => r.sku),
                        'ecotax'
                    );
                }

                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 7;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'ecoTax' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(
                                this.fillRequiredFieldsModal,
                                'ecoTax',
                                this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0])
                            );
                        }
                    }
                });
                break;

            // UN CODES
            case 8:
                // check first which rows needs some fields to be fill
                // no required fields for un codes
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        let i = 0;
                        while (i < cols.length) {
                            if (row[cols[i]] === undefined || row[cols[i]] === null || row[cols[i]].toString().length === 0) {
                                cols.splice(i, 1);
                            } else {
                                i += 1;
                            }
                        }
                        i = 0;
                        while (
                            i < this.mandatoryFieldsForActions.unCodes.length &&
                            cols.includes(this.mandatoryFieldsForActions.unCodes[i])
                        ) {
                            i += 1;
                        }

                        // at least 1 mandatory field is missing, the user must fill all missing required fields
                        if (i < this.mandatoryFieldsForActions.unCodes.length) {
                            if ( this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'unCodes', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    // no required columns to fill to execute the action
                    await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                        this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).map(r => r.sku),
                        'uncode'
                    );
                }

                // no required fields for un codes, we shall never go here
                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 7;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'unCodes' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(
                                this.fillRequiredFieldsModal,
                                'unCodes',
                                this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0])
                            );
                        }
                    }
                });
                break;

            // COST PRICE
            case 9:
                // check first which rows needs some fields to be fill
                ids.forEach((id) => {
                    const row: any = this.rows.find(r => r.id === id);
                    if (row) {
                        const cols: string[] = Object.keys(row);
                        if (
                            !cols.includes(this.mandatoryFieldsForActions.costPrice[0][0]) ||
                            !cols.includes(this.mandatoryFieldsForActions.costPrice[0][1]) ||
                            !cols.includes(this.mandatoryFieldsForActions.costPrice[0][2]) ||
                            !cols.includes(this.mandatoryFieldsForActions.costPrice[0][3]) ||
                            (
                                !cols.includes(this.mandatoryFieldsForActions.costPrice[0][4]) &&
                                !cols.includes(this.mandatoryFieldsForActions.costPrice[1][4])
                            )
                        ) {
                            if (this.rowsToFillBeforeAction.action === '') {
                                this.skusToProcess = [];
                                this.rowsToFillBeforeAction = {action: 'costPrice', ids: []};
                            }
                            this.rowsToFillBeforeAction.ids.push(id);
                        }
                    }
                });

                // do process for elligible rows
                if (this.selectedRows.filter(r => !this.rowsToFillBeforeAction.ids.includes(r.id)).length > 0) {
                    const rightSkus = this.selectedRows.filter(r => {
                        if (!this.rowsToFillBeforeAction.ids.includes(r.id)) {
                            const product = this.products.find(p => p.sku === r.sku);
                            if (! product || (! product.tariff && ! product.order_duty_minimis) || ! product.locals_taxes || ! product.other_taxes) {
                                this._notification.displayMessage(r.productID + ' ===> ' + this.messages.marginCalculationRequirements, 'danger', 10000);
                                return false;
                            }
                            return true;
                        }
                        return false;
                    }).map(r => r.sku);

                    if (rightSkus.length > 0) {
                        // no required columns to fill to execute the action
                        await this.searchTradeTariffRatessEcoTaxAndStoreIt(
                            rightSkus,
                            'costPrice'
                        );
                    }
                }

                ids.forEach(async (id) => {
                    rowsParsed += 1;
                    if (rowsParsed === ids.length) {
                        this.selectedAction = 0;
                        this.actionInProgress = false;
                        // there are rows for which we must fill required fields? open modals to fill the fields
                        if (this.rowsToFillBeforeAction.action === 'costPrice' && this.rowsToFillBeforeAction.ids.length > 0) {
                            this.openModal(this.fillRequiredFieldsModal, 'costPrice', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]));
                        }
                    }
                });

                break;
            default:
                // nothing to do here, we shall never go here
        }
    }

    marginCalculationMandatoryFieldsAreFilled(): boolean {
        // mandatory fields for margin calculation are missing
        if (
            (
                this.actionRequiredFieldsForm.controls.sale_unit_price.value.length === 0
                ||
                (
                    isNaN(this.actionRequiredFieldsForm.controls.sale_unit_price.value as any)
                    ||
                    parseFloat(this.actionRequiredFieldsForm.controls.sale_unit_price.value) <= 0
                )
                ||
                (
                    this.actionRequiredFieldsForm.controls.sale_currency_unit_price.value === null
                    ||
                    this.actionRequiredFieldsForm.controls.sale_currency_unit_price.value.length === 0
                )
            )
            &&
            (
                this.actionRequiredFieldsForm.controls.purchase_unit_price.value.length === 0
                ||
                (
                    isNaN(this.actionRequiredFieldsForm.controls.purchase_unit_price.value as any)
                    ||
                    parseFloat(this.actionRequiredFieldsForm.controls.purchase_unit_price.value) <= 0
                )
                ||
                (
                    this.actionRequiredFieldsForm.controls.purchase_currency_unit_price.value === null
                    ||
                    this.actionRequiredFieldsForm.controls.purchase_currency_unit_price.value.length === 0
                )
            )
        ) {
            return false;
        } else {
            return true;
        }
    }

    appendColInColumnsToDisplay(key: string) {
        // key is not defined in undisplayedColumns and in columnsToDisplay
        if (! this.keysToNotDisplay.includes(key) && (this.undisplayedColumns.findIndex((ctd) => ctd.value === key) === -1) && this.columnsToDisplay.findIndex((ctd) => ctd.value === key) === -1) {
            // it is a predefined column
            if ((Object.keys(this.predefinedColumns)).includes(key)) {
                this.columnsToDisplay.push({
                    label: this.predefinedColumns[key].label,
                    value: key
                });

            // it is a private column
            } else if ((Object.keys(this.privateColumns)).includes(key)) {
                this.columnsToDisplay.push({
                    label: this.privateColumns[key],
                    value: key
                });

            // it is a custom column
            } else {
                this.columnsToDisplay.push({
                    label: key,
                    value: key
                });
                // append col in wholePossibleColumns array
                if (!this.wholePossibleColumns.includes(key)) {
                    this.wholePossibleColumns.push(key);
                }
            }

            localStorage.setItem('columnsToDisplay', JSON.stringify(this.columnsToDisplay));
        }
    }

    rowsHaveChanged() {
        localStorage.setItem('products', JSON.stringify(this.rows));
        // use this in order to update the datatable
        this.rows = [...this.rows];
        if (this.rows.length > 0) {
            this.initRowsDetails();
        }
    }

    private async initUserPrefs() {
        let user = await this.userService.getUserPromise();
        if (!user) {
            user = await this.authService.reconnect();
        }
        this.user = user;
    }

    // HS CODE FINDER PART
    async searchHsCodeFinderAndStoreIt(row: any, country: string, isEuCountry?: boolean): Promise<void> {
        if (row) {
            const rowIndex: number = this.rows.findIndex(r => r.id === row.id);
            if (! this.rows[rowIndex].to_countries) {
                this.rows[rowIndex].to_countries = {};
            }
            // ecommerce users shall have eu_hscode set
            if (this.user.planIsEcommerce()) {
                // if hscode is not set yet, we must set a hscode EU
                if (! row.hscodefinder_hs_code && ! isEuCountry) {
                    const hsCodeFinderEuRequest: any = {
                        product: {
                            identification: {
                                type: 'TEXT',
                                value: row.productID
                            }
                        },
                        to_country: 'FRA',
                        lang: this.lang
                    };
                    await this.callHsCodeFinder(hsCodeFinderEuRequest).then(async (hsCodeFinderResponse) => {
                        const response = hsCodeFinderResponse.response;
                        if (hsCodeFinderResponse.code !== 200) {
                            this._notification.displayMessage(response, 'danger');
                            return;
                        }
        
                        const hsCodeFinderResult: HsCodeFinderResult = response as HsCodeFinderResult;
                        // store the result locally
                        this.rows[rowIndex].to_country = 'FRA';
                        this.rows[rowIndex].hscodefinder_hs_code = hsCodeFinderResult.hs_code;
                        this.rows[rowIndex].to_countries['FRA'] = Builder(ProductHscodeContent)
                                                                .hscodefinder_hs_code(hsCodeFinderResult.hs_code)
                                                                .hscodefinder_hs_version(hsCodeFinderResult.hs_version)
                                                                .hscodefinder_designation(hsCodeFinderResult.designation)
                                                                .product_statut(hsCodeFinderResult.product_statut)
                                                                .build();
                    });
                }
            }
            const hsCodeFinderRequest: any = {
                product: {
                    identification: {
                        type: 'TEXT',
                        value: row.productID
                    }
                },
                to_country: country,
                lang: this.lang
            };
            await this.callHsCodeFinder(hsCodeFinderRequest).then(async (hsCodeFinderResponse) => {
                const response = hsCodeFinderResponse.response;
                if (hsCodeFinderResponse.code !== 200) {
                    this._notification.displayMessage(response, 'danger');
                    return;
                }

                const hsCodeFinderResult: HsCodeFinderResult = response as HsCodeFinderResult;

                // store the result locally
                const rowIndex: number = this.rows.findIndex(r => r.id === row.id);
                // OLD WAY WHEN to_countries FIELD DID NOT EXIST
                /*this.rows[rowIndex].hscodefinder_hs_code = hsCodeFinderResult.hs_code;
                this.appendColInColumnsToDisplay('hscodefinder_hs_code');
                this.rows[rowIndex].hscodefinder_hs_version = hsCodeFinderResult.hs_version;
                this.appendColInColumnsToDisplay('hscodefinder_hs_version');
                this.rows[rowIndex].hscodefinder_designation = hsCodeFinderResult.designation;
                this.appendColInColumnsToDisplay('hscodefinder_designation');
                this.rows[rowIndex].product_statut = hsCodeFinderResult.product_statut;
                this.appendColInColumnsToDisplay('product_statut');*/

                if (! this.rows[rowIndex].hscodefinder_hs_code) {
                    this.rows[rowIndex].to_country = country;
                    this.rows[rowIndex].hscodefinder_hs_code = hsCodeFinderResult.hs_code;
                }
                if (! this.rows[rowIndex].to_countries) {
                    this.rows[rowIndex].to_countries = {};
                }
                this.rows[rowIndex].to_countries[country] = Builder(ProductHscodeContent)
                                                        .hscodefinder_hs_code(hsCodeFinderResult.hs_code)
                                                        .hscodefinder_hs_version(hsCodeFinderResult.hs_version)
                                                        .hscodefinder_designation(hsCodeFinderResult.designation)
                                                        .product_statut(hsCodeFinderResult.product_statut)
                                                        .build();
                const productToUpdate = {...this.rows[rowIndex]};
                delete productToUpdate.id;

                // update product
                await this.putProduct(productToUpdate, this.rows[rowIndex].sku);
                this.rowsHaveChanged();

                /*this._notification.displayMessage(
                    this.translate.instant('messages.notification.productsManager.actionsDone'),
                    'success',
                    4000
                );*/

            });
        } else {
            this._notification.displayMessage(this.unknown_error, 'danger', 2000);
        }
    }
    async callHsCodeFinder(hsCodeFinderRequest: HsCodeFinderRequest): Promise<any> {
        return this.http
            .post(`https://api.${environment.baseUrl}/v4.1/taxsrv/hscodefinder`, hsCodeFinderRequest)
            .toPromise()
            .then((response: any) => {
                return { code: 200, request: hsCodeFinderRequest, response: response.result };
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                return { code: errorResponse.status, request: hsCodeFinderRequest, response: transiteoError };
            });
    }

    // DUTY CALCULATION PART
    async calcDutyCalculationAndStoreIt(row: any) {
        const taxRequest = {
            shipment_type: 'ARTICLE',
            products: [
                {
                    identification: {
                        value: row.productID,
                        type: 'TEXT'
                    },
                    weight: Number(row.weight),
                    weight_unit: row.weight_unit,
                    quantity: Number(row.quantity),
                    unit_price: Number(row.unit_price),
                    currency_unit_price: row.currency_unit_price,
                    unit_ship_price: Number(row.unit_ship_price),
                    currency_unit_ship_price: row.currency_unit_price,
                    origin_country: row.origin_country // could be defined or not
                }
            ],
            lang: this.lang,
            from_country: row.from_country,
            to_country: row.to_country,
            to_district: (['BRA', 'USA', 'CAN'].includes(row.to_country) ? row.to_district : null),
            sender: {
                pro: true,
                revenue_country_annual: Number(row.revenue_country_annual),
                currency_revenue_country_annual: row.currency_revenue_country_annual
            },
            receiver: {
                pro: false,
                activity_id: ''
            }
        };

        await this.callDutyApi(taxRequest).then(async taxResponse => {
            if (taxResponse.code !== 200 ) {
                this._notification.displayMessage(taxResponse.response as string, 'danger');
                return;
            }

            // store the result locally
            const response: TaxResponse = <TaxResponse> taxResponse.response;
            const rowIndex: number = this.rows.findIndex(r => r.id === row.id);

            this.rows[rowIndex].dutycalculation_global_amount = response.global.amount;
            this.appendColInColumnsToDisplay('dutycalculation_global_amount');

            this.rows[rowIndex].dutycalculation_duty_agreement = response.products[0].duty.agreement;
            this.appendColInColumnsToDisplay('dutycalculation_duty_agreement');
            this.rows[rowIndex].dutycalculation_duty_label = response.products[0].duty.label;
            this.appendColInColumnsToDisplay('dutycalculation_duty_label');
            this.rows[rowIndex].dutycalculation_duty_message = response.products[0].duty.message;
            this.appendColInColumnsToDisplay('dutycalculation_duty_message');
            this.rows[rowIndex].dutycalculation_duty_percentage = response.products[0].duty.percentage;
            this.appendColInColumnsToDisplay('dutycalculation_duty_percentage');
            this.rows[rowIndex].dutycalculation_duty_product_taxes_amount = response.products[0].duty.product_taxes_amount;
            this.appendColInColumnsToDisplay('dutycalculation_duty_product_taxes_amount');
            this.rows[rowIndex].dutycalculation_duty_shipping_taxes_amount = response.products[0].duty.shipping_taxes_amount;
            this.appendColInColumnsToDisplay('dutycalculation_duty_shipping_taxes_amount');
            this.rows[rowIndex].dutycalculation_duty_vat_taxes_amount = response.products[0].duty.vat_taxes_amount;
            this.appendColInColumnsToDisplay('dutycalculation_duty_vat_taxes_amount');

            this.rows[rowIndex].dutycalculation_transit_fees = response.products[0].transit_fees;
            this.appendColInColumnsToDisplay('dutycalculation_transit_fees');
            this.rows[rowIndex].product_statut = response.products[0].product_statut;
            this.appendColInColumnsToDisplay('product_statut');

            let i = 0;
            // 3 special taxes maximum
            while (i < response.products[0].special_taxes.length && i < 3) {
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_agreement`] = response.products[0].special_taxes[i].agreement;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_agreement`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_label`] = response.products[0].special_taxes[i].label;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_label`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_message`] = response.products[0].special_taxes[i].message;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_message`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_percentage`] = response.products[0].special_taxes[i].percentage;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_percentage`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_product_taxes_amount`] = response.products[0].special_taxes[i].product_taxes_amount;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_product_taxes_amount`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_shipping_taxes_amount`] = response.products[0].special_taxes[i].shipping_taxes_amount;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_shipping_taxes_amount`);
                this.rows[rowIndex][`dutycalculation_specialtax_${this.getStringNumber(i)}_vat_taxes_amount`] = response.products[0].special_taxes[i].vat_taxes_amount;
                this.appendColInColumnsToDisplay(`dutycalculation_specialtax_${this.getStringNumber(i)}_vat_taxes_amount`);

                i += 1;
            }

            i = 0;
            // 2 VAT maximum
            while (i < response.products[0].vat.length && i < 2) {
                this.rows[rowIndex][`dutycalculation_vat_${this.getStringNumber(i)}_label`] = response.products[0].vat[i].label;
                this.appendColInColumnsToDisplay(`dutycalculation_vat_${this.getStringNumber(i)}_label`);
                this.rows[rowIndex][`dutycalculation_vat_${this.getStringNumber(i)}_message`] = response.products[0].vat[i].message;
                this.appendColInColumnsToDisplay(`dutycalculation_vat_${this.getStringNumber(i)}_message`);
                this.rows[rowIndex][`dutycalculation_vat_${this.getStringNumber(i)}_percentage`] = response.products[0].vat[i].percentage;
                this.appendColInColumnsToDisplay(`dutycalculation_vat_${this.getStringNumber(i)}_percentage`);
                this.rows[rowIndex][`dutycalculation_vat_${this.getStringNumber(i)}_product_taxes_amount`] = response.products[0].vat[i].product_taxes_amount;
                this.appendColInColumnsToDisplay(`dutycalculation_vat_${this.getStringNumber(i)}_product_taxes_amount`);
                this.rows[rowIndex][`dutycalculation_vat_${this.getStringNumber(i)}_shipping_taxes_amount`] = response.products[0].vat[i].shipping_taxes_amount;
                this.appendColInColumnsToDisplay(`dutycalculation_vat_${this.getStringNumber(i)}_shipping_taxes_amount`);

                i += 1;
            }

            this.rows[rowIndex].dutycalculation_datetime = (new Date((<any>response).timestamp)).toISOString();
            this.appendColInColumnsToDisplay('dutycalculation_datetime');

            const productToUpdate = {...this.rows[rowIndex]};
            delete productToUpdate.id;

            // update product
            await this.putProduct(productToUpdate, this.rows[rowIndex].sku);
            this.rowsHaveChanged();

            this._notification.displayMessage(
                this.translate.instant('messages.notification.productsManager.actionsDone'),
                'success',
                4000
            );
        });
    }

    async callDutyApi(taxRequest: any) {
        return this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/dutyCalculation`, taxRequest).toPromise().then((response: any) => {
            const taxResponse = plainToClass(TaxResponse, response);
            return {code: 200, request : taxRequest, response: taxResponse};
        }).catch( errorResponse => {
            const transiteoErrorMessage = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
            return {code: errorResponse.status, request : taxRequest, response: transiteoErrorMessage};
        });
    }

    // MARGIN CALCULATION PART -- NOT USED ANYMORE
    /*async calculateMarginAndStoreIt(row: any) {
        if (isNaN(row.dutycalculation_global_amount as any)) {
            this._notification.displayMessage(row.productID + ' ===> ' + this.messages.dutyCalculationRequired, 'danger', 4000);
            return;
        }

        let margin = 0;
        let margin_percentage = 0;
        let margin_amount = 0;
        let margin_currency = '';

        // use of sale unit price
        if (row.sale_unit_price && row.sale_currency_unit_price) {
            const convertedUnitShipPrice: number = (
                row.currency_unit_price === row.sale_currency_unit_price ?
                row.unit_ship_price :
                await this.getAmountConverted(row.unit_ship_price, row.currency_unit_price, row.sale_currency_unit_price)
            );
            const convertedUnitPrice: number = (row.currency_unit_price === row.sale_currency_unit_price ?
                row.unit_price :
                await this.getAmountConverted(row.unit_price, row.currency_unit_price, row.sale_currency_unit_price)
            );
            const convertedGlobalAmount: number = (row.currency_unit_price === row.sale_currency_unit_price ?
                row.dutycalculation_global_amount :
                await this.getAmountConverted(row.dutycalculation_global_amount, row.currency_unit_price, row.sale_currency_unit_price)
            );

            if (convertedUnitShipPrice === null || convertedUnitPrice === null || convertedGlobalAmount === null) {
                this._notification.displayMessage(row.productID + ' ===> ' + this.messages.cannotCalculateMargin, 'danger', 4000);
                return;
            }

            margin = parseFloat(((row.sale_unit_price * row.quantity + convertedUnitShipPrice * row.quantity)
                / (convertedUnitPrice * row.quantity + convertedGlobalAmount)).toFixed(2));
            margin_percentage = parseInt((margin * 100).toFixed(0), 10);
            margin_amount = parseFloat(
                (row.sale_unit_price * row.quantity + convertedUnitShipPrice * row.quantity - convertedUnitPrice * row.quantity + convertedGlobalAmount)
                .toFixed(2)
            );
            margin_currency = row.sale_currency_unit_price;

        // use of purchase unit price
        } else if (row.purchase_unit_price && row.purchase_currency_unit_price) {
            const convertedUnitShipPrice: number = (row.currency_unit_price === row.purchase_currency_unit_price ?
                row.unit_ship_price :
                await this.getAmountConverted(row.unit_ship_price, row.currency_unit_price, row.purchase_currency_unit_price)
            );
            const convertedUnitPrice: number = (row.currency_unit_price === row.purchase_currency_unit_price ?
                row.unit_price :
                await this.getAmountConverted(row.unit_price, row.currency_unit_price, row.purchase_currency_unit_price)
            );
            const convertedGlobalAmount: number = (row.currency_unit_price === row.purchase_currency_unit_price ?
                row.dutycalculation_global_amount :
                await this.getAmountConverted(row.dutycalculation_global_amount, row.currency_unit_price, row.purchase_currency_unit_price)
            );

            if (convertedUnitShipPrice === null || convertedUnitPrice === null || convertedGlobalAmount === null) {
                this._notification.displayMessage(row.productID + ' ===> ' + this.messages.cannotCalculateMargin, 'danger', 4000);
                return;
            }

            margin = parseFloat(((convertedUnitPrice * row.quantity + convertedUnitShipPrice * row.quantity)
                / (row.purchase_unit_price * row.quantity + convertedGlobalAmount)).toFixed(2));
            margin_percentage = parseInt((margin * 100 - 100).toFixed(0), 10);
            margin_amount = parseFloat(
                (convertedUnitPrice * row.quantity + convertedUnitShipPrice * row.quantity - row.purchase_unit_price * row.quantity + convertedGlobalAmount)
                .toFixed(2)
            );
            margin_currency = row.purchase_currency_unit_price;
        }

        // store the result locally
        const rowIndex: number = this.rows.findIndex(r => r.id === row.id);

        this.rows[rowIndex].margin = margin;
        this.appendColInColumnsToDisplay('margin');
        this.rows[rowIndex].margin_percentage = margin_percentage;
        this.appendColInColumnsToDisplay('margin_percentage');
        this.rows[rowIndex].margin_amount = margin_amount;
        this.appendColInColumnsToDisplay('margin_amount');
        this.rows[rowIndex].margin_currency = margin_currency;
        this.appendColInColumnsToDisplay('margin_currency');

        const productToUpdate = {...this.rows[rowIndex]};
        delete productToUpdate.id;

        // update product
        await this.putProduct(productToUpdate, this.rows[rowIndex].sku);
        this.rowsHaveChanged();

        this._notification.displayMessage(
            this.translate.instant('messages.notification.productsManager.actionsDone'),
            'success',
            4000
        );
    }*/

    // Trade Tariff Rates Duties / Taxes/ Special Taxes
    async searchTradeTariffRatessEcoTaxAndStoreIt(skus: string[], processType: string): Promise<void> {
        await this.callTradeTariffRatesEcoTaxProcess(Builder(CustomerProductsProcessRequest).sku(skus).build(), processType).then(async (response) => {
            if (response) {
                const resp = response as CustomerProduct[];
                resp.forEach(res => {
                    const product = plainToClass(CustomerProduct, res);
                    this.products[this.products.findIndex(r => r.sku === res.sku)] = product;
                    const rowIndex = this.getRows().findIndex(r => r.sku === res.sku);
                    this.rows[this.rows.findIndex(r => r.sku === res.sku)] = product.toRow();
                    const selectedRowsIndex = this.selectedRows.findIndex(r => r.sku === res.sku);
                    if (selectedRowsIndex >= 0) {
                        this.selectedRows.splice(selectedRowsIndex, 1);
                        const element = document.getElementById(`check_${rowIndex}`) as HTMLElement;
                        setTimeout(() => {
                            element.click();
                        }, 150);
                    }
                });
                this.retrieveProducts(this.products.slice());

                this._notification.displayMessage(
                    this.translate.instant('messages.notification.productsManager.actionsDone'),
                    'success',
                    4000
                );
            }
        });
    }
    async callTradeTariffRatesEcoTaxProcess(request: CustomerProductsProcessRequest, process: string): Promise<CustomerProduct[]> {
        return this.http
            .post(`https://api.${environment.baseUrl}/v1/customer/products/process/${process}`, request)
            .toPromise()
            .then((response: any) => {
                return response;
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                this._notification.displayMessage(transiteoError, 'danger', 4000);
                return null;
            });
    }

    getStringNumber(i: number): string {
        if (i === 0) {
            return 'one';
        } else if (i === 1) {
            return 'two';
        } else {
            return 'three';
        }
    }

    getDistricts(country: string) {
        return country && country !== 'USA' && districts[country]
            ? districts[country].length < 500
                ? districts[country].sort(Utils.compareByLabel)
                : districts[country].slice(0, 500).sort(Utils.compareByLabel)
            : [];
    }

    csvFileChanged(event) {
        if (event.file.status === 'removed') {
            this.expectedCsvContent = null;
        }
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            // get expected CSV content
            this.expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = this.expectedCsvContent.toExpectedProductCsvContent();
            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
                const headerLine = csvRecordsArray.shift();
                const firstLine: string[] = headerLine.split(';');
                const headerLineLength = firstLine.length;

                // check for wrong lines
                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    // not same columns number
                    if (headerLineLength !== line.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
                        return true;
                    }
                    return false;
                });
                if (wrongLines.length > 0) {
                    this.removeFile(file);
                    return;
                }

                /*if (!firstLine.includes('type')) {
                    this._notification.displayMessage('Line 1 : type ' + this.messages.isMandatory, 'danger');
                    return true;
                } else */
                /*if (!firstLine.includes('productID')) {
                    this._notification.displayMessage('Line 1 : productID ' + this.messages.isMandatory, 'danger');
                    return true;
                } else if (!firstLine.includes('weight')) {
                    this._notification.displayMessage('Line 1 : weight ' + this.messages.isMandatory, 'danger');
                    return true;
                } else if (!firstLine.includes('weight_unit')) {
                    this._notification.displayMessage('Line 1 : weight_unit ' + this.messages.isMandatory, 'danger');
                    return true;
                }*/
                if (!firstLine.includes('productID') || !firstLine.includes('weight') || !firstLine.includes('weight_unit')) {
                    this.externalCsvFields = this.expectedCsvContent.getHeaders();
                    this.csvModelFields = this.buildCsvModelFields();
                    this.onDisplayCsvMapper();
                }
            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }
    buildCsvModelFields(): CsvFieldToSet[] {
        const modelFields: CsvFieldToSet[] = [];
        const expectedCsvFields = {
            sku: {
                isRequired: false
            },
            productID: {
                isRequired: true
            },
            categories_id: {
                isRequired: false
            },
            weight: {
                isRequired: true
            },
            weight_unit: {
                isRequired: true
            },
            quantity: {
                isRequired: false
            },
            unit_price: {
                isRequired: false
            },
            unit_ship_price: {
                isRequired: false
            },
            currency_unit_price: {
                isRequired: false
            },
            sale_unit_price: {
                isRequired: false
            },
            sale_currency_unit_price: {
                isRequired: false
            },
            purchase_unit_price: {
                isRequired: false
            },
            purchase_currency_unit_price: {
                isRequired: false
            },
            from_country: {
                isRequired: false
            },
            to_country: {
                isRequired: false
            },
            to_district: {
                isRequired: false
            },
            revenue_country_annual: {
                isRequired: false
            },
            currency_revenue_country_annual: {
                isRequired: false
            },
            hscodefinder_hs_code: {
                isRequired: false
            },
        };
        const keys = Object.keys(expectedCsvFields);

        keys.forEach(f => {
            modelFields.push(
                Builder(CsvFieldToSet)
                .id(f)
                .externalField('')
                .isRequired(expectedCsvFields[f].isRequired)
                .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                .build()
            );
        });
        return modelFields;
    }
    onDisplayCsvMapper() {
        this.displayCsvMapper.next();
    }
    onCsvExternalMapped(mappedFields: CsvFieldToSet[]) {
        if (! mappedFields) {
            this.expectedCsvContent = null;
        } else {
            this.expectedCsvContent.modifyHeadersWithMappedFields(mappedFields);
        }
    }

    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.expectedCsvContent = null;
        return false;
    }

    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.expectedCsvContent = null;
    }

    async validateCSVImport() {
        const rows = this.expectedCsvContent.getContent();
        // hide the pg-upload component now
        this.switchDisplayUploadCsvComponent();
        const labels: string[] = rows.shift().split(';');
        const rowsWithErrors = [];
        let rowIsValid: boolean;
        let line = 2;
        rows.forEach(async record => {
            // parse each column and check if there are predefined cols
            const cols: string[] = record.split(';');
            let i = 0;
            const row = {};
            rowIsValid = true;
            labels.forEach((label) => {
                if (rowIsValid) {
                    switch (label) {
                        /*case 'type':
                            if (cols[i] !== CUSTOMER_PRODUCT_TYPE_ENUM.SKU && cols[i] !== CUSTOMER_PRODUCT_TYPE_ENUM.TEXT) {
                                this._notification.displayMessage(
                                    `Line ${line} : type ${this.messages.mustBeOneOf} [${CUSTOMER_PRODUCT_TYPE_ENUM.SKU}, ${CUSTOMER_PRODUCT_TYPE_ENUM.TEXT}]`,
                                    'danger'
                                );
                                rowIsValid = false;
                            }
                            break;*/
                        case 'sku':
                            // nothing to do as it is not mandatory anymore
                            /*if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : sku ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }*/
                            break;
                        case 'productID':
                            if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : productID ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'quantity':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseInt(cols[i], 10) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseInt(cols[i], 10).toFixed(0);
                            }
                            break;
                        case 'unit_price':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'unit_ship_price':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'currency_unit_price':
                            // if the currency is wrong, set it to "null"
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'sale_unit_price':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'sale_currency_unit_price':
                            // if the currency is wrong, set it to "null"
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'purchase_unit_price':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'purchase_currency_unit_price':
                            // if the currency is wrong, set it to "null"
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'weight':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                this._notification.displayMessage(`Line ${line} : weight ${this.messages.mustBeNumber}`, 'danger');
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'weight_unit':
                            // if the unit is wrong, set it to "null"
                            if (this.unit_weight_types.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(`Line ${line} : weight_unit ${this.messages.notValid}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'from_country':
                            // if the country is wrong, set it to "null"
                            if (this.countries.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'to_country':
                            // if the country is wrong, set it to "null"
                            if (this.countries.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'to_district':
                            // check the district depending on to_country. If not correct, set it to null
                            const iCountry: number = labels.findIndex((lab) => lab === 'to_country');
                            if (iCountry >= 0 && !this.districtIsRight(cols[iCountry], cols[i])) {
                                cols[i] = null;
                            }
                            break;
                        case 'revenue_country_annual':
                            // wrong number, set it to null
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                cols[i] = null;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'currency_revenue_country_annual':
                            // if the currency is wrong, set it to "null"
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                cols[i] = null;
                            }
                            break;
                        case 'hscodefinder_hs_code':
                            // nothing to do as it is not mandatory
                            break;
                        default:
                            // change nothing as it is not a predefined column
                    }
                }
                // append label and value in row ONLY IF value is not null
                if (cols[i] !== null) {
                    row[label] = cols[i];
                }
                i += 1;
            });
            if (rowIsValid) {
                // valid the row
                await this.onValidRow(row);
            } else {
                // append row in rowsWithErrors
                const rowWithErrors = {};
                for (let j = 0; j < labels.length; j++) {
                    rowWithErrors[labels[j]] = cols[j];
                }
                rowsWithErrors.push(rowWithErrors);
            }
            line += 1;
        });

        if (rowsWithErrors.length > 0) {
            this.csvService.downloadFile(rowsWithErrors, 'products-to-rectify');
        }
    }

    districtIsRight(country: string, district: string): boolean {
        return ((country === 'USA' && district.startsWith('US-')) || (this.getDistricts(country).findIndex(dist => dist.value === district) >= 0));
    }

    openModalFromTable(template: TemplateRef<any>, type: string, id: string) {
        if (this.rows) {
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            if (type === 'addrow') {
                this.rowToEditId = null;
                this.initRowForm(null);
            } else {
                let foundRow = this.getRows().find(p => p.sku === id);
                if (foundRow) {
                    if (type === 'editrow') {
                        this.rowToEditId = id;
                        this.initRowForm(foundRow);
                    } else if (type === 'displayrow') {
                        this.rowToEditId = null;
                        this.initRowForm(foundRow);
                    } else {
                        this.productToDeleteSku = id;
                    }
                }
            }
            this.modalRef = this.modalService.show(template, config);
        }
    }

    openModal(template: TemplateRef<any>, type: string, row: any = null, alreadyOpened: boolean = false) {
        this.closingModal = false;
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;

        if (type === 'newrow') {
            this.rowToEditId = null;
            this.initRowForm();

        } else if (type === 'editrow') {
            this.rowToEditId = row.sku;
            this.initRowForm(row);

        } else if (type === 'editmultiplerows') {
            this.initRowForm(null, true);

        } else if (type === 'hsCodeFinder') {
            // OLD WAY WHEN to_countries FIELD DID NOT EXIST
            /*this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                to_country: [null, Validators.required]
            });*/
            this.actionRequiredFieldsForm = this.formBuilder.group({
                to_country: [null, Validators.required]
            });

        } else if (type === 'dutyCalculation') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                quantity: [row.quantity ? row.quantity : '', Validators.required],
                unit_price: [row.unit_price ? row.unit_price : null, Validators.required],
                unit_ship_price: [row.unit_ship_price ? row.unit_ship_price : '', Validators.required],
                currency_unit_price: [row.currency_unit_price ? row.currency_unit_price : null, Validators.required],
                from_country: [row.from_country ? row.from_country : null, Validators.required],
                to_country: [row.to_country ? row.to_country : null, Validators.required],
                to_district: [row.to_district ? row.to_district : 'no_district', Validators.required],
                revenue_country_annual: [row.revenue_country_annual ? row.revenue_country_annual : '', Validators.required],
                currency_revenue_country_annual: [row.currency_revenue_country_annual ? row.currency_revenue_country_annual : null, Validators.required]
            });
            this.subscribeToCountryChanges();
            if (row.to_country && row.to_country === 'USA') {
                this.subscribeToStateChanges(-1);
            }

        } else if (type === 'marginCalculation') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                sale_unit_price: [row.sale_unit_price ? row.sale_unit_price : null, Validators.required],
                sale_currency_unit_price: [row.sale_currency_unit_price ? row.sale_currency_unit_price : null, Validators.required],
                purchase_unit_price: [row.purchase_unit_price ? row.purchase_unit_price : null, Validators.required],
                purchase_currency_unit_price: [row.purchase_currency_unit_price ? row.purchase_currency_unit_price : null, Validators.required]
            });
        } else if (type === 'tradeTariffRatesDuties') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                from_country: [row.from_country ? row.from_country : null, Validators.required],
                to_country: [row.to_country ? row.to_country : null, Validators.required],
                hscodefinder_hs_code: [row.hscodefinder_hs_code ? row.hscodefinder_hs_code : null, Validators.required]
            });
        } else if (type === 'tradeTariffRatesTaxes' || type === 'tradeTariffRatesSpecialTaxes') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                to_country: [row.to_country ? row.to_country : null, Validators.required],
                hscodefinder_hs_code: [row.hscodefinder_hs_code ? row.hscodefinder_hs_code : null, Validators.required]
            });
        } else if (type === 'ecoTax') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                to_country: [row.to_country ? row.to_country : null, Validators.required]
            });
        } else if (type === 'costPrice') {
            this.modalErrorMessage = row.productID + ' ===> ' + this.messages.fieldsMandatory;
            this.actionRequiredFieldsForm = this.formBuilder.group({
                id: [row.id, Validators.required],
                productID: [row.productID, Validators.required],
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                quantity: [row.quantity ? row.quantity : '', Validators.required],
                unit_price: [row.unit_price ? row.unit_price : '', Validators.required],
                currency_unit_price: [row.currency_unit_price ? row.currency_unit_price : null, Validators.required],
                currency_ship_price: [row.currency_ship_price ? row.currency_ship_price : null, Validators.required],
                unit_ship_price: [row.unit_ship_price ? row.unit_ship_price : ''],
                global_ship_price: [row.global_ship_price ? row.global_ship_price : ''],
            });
        }

        if (!alreadyOpened) {
            this.modalRef = this.modalService.show(template, config);
        }
    }

    closeModal(cancelAction = false) {
        this.formIsInvalid = false;
        this.displayRowOnly = false;
        this.closingModal = true;
        this.modalRef.hide();
        this.editRowInitialState = null;
        this.columnsChanged = [];
        this.rowsToFillBeforeAction = {action: '', ids: []};
        this.modalErrorMessage = '';
        this.currentColumnsToFill = [];
        this.unsubscribeToCountryChangesAndReinitializeToDistrictOptions();
        if (cancelAction) {
            this.actionInProgress = false;
        }
    }

    // save the required fields, call the calculation function and reopen the modal for the next row if there are any
    async onValidRequiredFields() {
        if (this.requiredFieldsInvalid()) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.actionRequiredFieldsForm.value;
        const keys = Object.keys(formValue);

        if (this.rowsToFillBeforeAction.action === 'hsCodeFinder') {
            const country = formValue.to_country;
            const rowsToUpdate = this.rowsToFillBeforeAction.ids.map(id => this.rows.find(r => r.id === id)).filter(r => r); // remove null rows
            this.closeModal();
            // get country v2 for ecommerce user to know if the selected country is an EU country or not
            let isEuCountry = false;
            if (this.user.planIsEcommerce()) {
                const countryV2 = await this.apiDataService.getCountry(country);
                isEuCountry = countryV2 && (countryV2.eu || countryV2.eu_nomenclature);
            }
            for (let i = 0; i < rowsToUpdate.length; i++) {
                await this.searchHsCodeFinderAndStoreIt(rowsToUpdate[i], country, isEuCountry);
            }
            this._notification.displayMessage(
                this.translate.instant('messages.notification.productsManager.actionsDone'),
                'success',
                4000
            );
            // action is not in progress anymore
            this.actionInProgress = false;
            return;
        }
        const rowIndex = this.rows.findIndex(r => r.id === formValue.id);

        // save required fields
        keys.forEach(key => {
            if (!['id', 'productID'].includes(key)) {
                // handle margin fields cases
                /*if (
                    this.rowsToFillBeforeAction.action === 'marginCalculation' &&
                    formValue[key] !== null &&
                    formValue[key].length > 0 &&
                    formValue[key] !== '0' &&
                    formValue[key] !== '-' &&
                    formValue[key] !== ''
                ) {
                    this.rows[rowIndex][key] = (!isNaN(formValue[key] as any) ? parseFloat(formValue[key]) : formValue[key]);

                } else if (
                    this.rowsToFillBeforeAction.action !== 'marginCalculation' &&
                    (
                        key !== 'to_district' ||
                        formValue.to_district !== 'no_district'
                    )
                ) {*/
                if (formValue[key] === null || formValue[key] === undefined || formValue[key] === '') {
                    if (this.rows[rowIndex][key] === null || this.rows[rowIndex][key]) {
                        delete this.rows[rowIndex][key];
                    }
                } else {
                    this.rows[rowIndex][key] = (key !== 'hscodefinder_hs_code' && !isNaN(formValue[key] as any) ? parseFloat(formValue[key]) : formValue[key]);
                }
                // }
            }
        });

        // do the calculation
        // OLD WAY WHEN to_countries FIELD DID NOT EXIST
        /*if (this.rowsToFillBeforeAction.action === 'hsCodeFinder') {
            this.searchHsCodeFinderAndStoreIt(this.rows[rowIndex]);
        } else */if (this.rowsToFillBeforeAction.action === 'dutyCalculation') {
            this.calcDutyCalculationAndStoreIt(this.rows[rowIndex]);
        } /*else if (this.rowsToFillBeforeAction.action === 'marginCalculation') {
            this.calculateMarginAndStoreIt(this.rows[rowIndex]);
        }*/ else {
            const productToUpdate = {...this.rows[rowIndex]};
            delete productToUpdate.id;
            await this.putProduct(productToUpdate, productToUpdate.sku);
            this.skusToProcess.push(productToUpdate.sku);
        }

        // let the modal opened and change fields if there are more rows
        this.rowsToFillBeforeAction.ids.shift();
        if (this.rowsToFillBeforeAction.ids.length > 0) {
            if (this.rowsToFillBeforeAction.action === 'hsCodeFinder') {
                this.openModal(this.fillRequiredFieldsModal, 'hsCodeFinder', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]), true);
            } else if (this.rowsToFillBeforeAction.action === 'dutyCalculation') {
                this.openModal(this.fillRequiredFieldsModal, 'dutyCalculation', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]), true);
            } else if (this.rowsToFillBeforeAction.action === 'marginCalculation') {
                this.openModal(this.fillRequiredFieldsModal, 'marginCalculation', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]), true);
            } else if (this.rowsToFillBeforeAction.action === 'costPrice') {
                this.openModal(this.fillRequiredFieldsModal, 'costPrice', this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]), true);
            } else if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesDuties') {
                this.openModal(
                    this.fillRequiredFieldsModal,
                    'tradeTariffRatesDuties',
                    this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]),
                    true
                );
            } else if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesTaxes') {
                this.openModal(
                    this.fillRequiredFieldsModal,
                    'tradeTariffRatesTaxes',
                    this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]),
                    true
                );
            } else if (this.rowsToFillBeforeAction.action === 'tradeTariffRatesSpecialTaxes') {
                this.openModal(
                    this.fillRequiredFieldsModal,
                    'tradeTariffRatesSpecialTaxes',
                    this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]),
                    true
                );
            } else if (this.rowsToFillBeforeAction.action === 'ecoTax') {
                this.openModal(
                    this.fillRequiredFieldsModal,
                    'ecoTax',
                    this.rows.find(r => r.id === this.rowsToFillBeforeAction.ids[0]),
                    true
                );
            }
        } else {
            if (this.tradeTariffRatesEcoTaxProcessRequired() && this.skusToProcess.length > 0) {
                // check that all required process are done
                if (this.getProcessName() === 'marginCalculation' || this.getProcessName() === 'costPrice') {
                    let i = 0;
                    while (i < this.skusToProcess.length) {
                        const product = this.products.find(p => p.sku === this.skusToProcess[i]);
                        if (! product || (! product.tariff && ! product.order_duty_minimis) || ! product.locals_taxes || ! product.other_taxes) {
                            this._notification.displayMessage(product.value + ' ===> ' + this.messages.marginCalculationRequirements, 'danger', 10000);
                            this.skusToProcess.splice(i, 1);
                        } else {
                            i += 1;
                        }
                    }
                }
                if (this.skusToProcess.length > 0) {
                    this.searchTradeTariffRatessEcoTaxAndStoreIt(this.skusToProcess, this.getProcessName()).then(() => this.skusToProcess = []);
                }
            }
            this.closeModal();
        }
    }

    tradeTariffRatesEcoTaxProcessRequired(): boolean {
        return this.rowsToFillBeforeAction.action.startsWith('tradeTariffRates')
                ||
                this.rowsToFillBeforeAction.action === 'ecoTax'
                ||
                this.rowsToFillBeforeAction.action === 'marginCalculation'
                ||
                this.rowsToFillBeforeAction.action === 'costPrice';
    }

    getProcessName(): string {
        switch (this.rowsToFillBeforeAction.action) {
            case 'tradeTariffRatesDuties':
                return 'duties';
            case 'tradeTariffRatesTaxes':
                return 'taxes';
            case 'tradeTariffRatesSpecialTaxes':
                return 'specialtaxes';
            case 'ecoTax':
                return 'ecotax';
            case 'marginCalculation':
                return 'marginCalculation';
            case 'costPrice':
                return 'costPrice';
            default:
                return 'duties';
        }
    }

    requiredFieldsInvalid(): boolean {
        if (this.getProcessName() === 'costPrice') {
            if (this.actionRequiredFieldsForm.valid) {
                const formValues = this.actionRequiredFieldsForm.value;
                return isNaN(formValues.unit_ship_price) && isNaN(formValues.global_ship_price);
            }
        } else if (this.actionRequiredFieldsForm) {
            return this.actionRequiredFieldsForm.invalid;
        }
        return true;
    }

    getColumnsToFill(): FormArray {
        return this.rowForm.get('columnsToFill') as FormArray;
    }

    addSkuControl(value) {
        this.rowForm.addControl(
            'sku',
            new FormControl(value, [])
        );
    }
    // add categories_id control, used when editing multiple rows
    onAddCategoriesIdControl() {
        this.rowForm.addControl(
            'categories_id',
            this.formBuilder.array([])
        );
        this.onAddProductCategoryId();
    }
    onRemoveCategoriesIdControl() {
        this.rowForm.removeControl('categories_id');
    }

    getRowsToEditLabels(): string {
        return this.selectedRows.map(r => {
            return this.managersService.getOptionsProducts().find(productOption => productOption.startsWith(r.sku));
        }).join(' | ');
    }

    initRowForm(row: any = null, editMultipleRows = false) {
        this.filteredWholePossibleColumns = [];
        this.currentColumnsToFill = [];
        if (row) {
            // row to compare with values which will be changed, used to prevent the user that calculations will be erased
            this.editRowInitialState = row;
            // Only SKU available
            if (row.type !== CUSTOMER_PRODUCT_TYPE_ENUM.SKU) {
                row.type = CUSTOMER_PRODUCT_TYPE_ENUM.SKU;
            }
            this.rowForm = this.formBuilder.group({
                type: [row.type ? row.type : CUSTOMER_PRODUCT_TYPE_ENUM.SKU, Validators.required],
                productID: [row.productID, Validators.required],
                to_countries: this.formBuilder.array([]),
                categories_id: this.formBuilder.array([]),
                weight: [row.weight, Validators.required],
                weight_unit: [row.weight_unit, Validators.required],
                columnsToFill: this.formBuilder.array([])
            });
            if (this.toCountriesDetails[row.sku]) {
                const countries = Object.keys(this.toCountriesDetails[row.sku]);
                countries.forEach(country => {
                    this.onAddProductToCountries(country, this.toCountriesDetails[row.sku][country].hscodefinder_hs_code);
                });
            }
            if (row.categories_id) {
                row.categories_id.forEach(id => {
                    this.onAddProductCategoryId(id);
                });
            }
            if (row.type === CUSTOMER_PRODUCT_TYPE_ENUM.SKU) {
                this.addSkuControl(row.sku ? row.sku : '');
            }
            const keys = Object.keys(row);
            const privateCols = Object.keys(this.privateColumns);
            if (privateCols.includes('hscodefinder_hs_code')) {
                privateCols.splice(privateCols.findIndex(pc => pc === 'hscodefinder_hs_code'), 1);
            }
            keys.forEach((key) => {
                if (!privateCols.includes(key)) {
                    this.onAddColumn(key, row[key], editMultipleRows);
                }
            });
        } else if (editMultipleRows) {
            this.rowForm = this.formBuilder.group({
                columnsToFill: this.formBuilder.array([])
            });
            this.onAddColumn('', '', editMultipleRows);
        } else {
            this.rowForm = this.formBuilder.group({
                type: [CUSTOMER_PRODUCT_TYPE_ENUM.SKU, Validators.required],
                productID: ['', Validators.required],
                to_countries: this.formBuilder.array([]),
                categories_id: this.formBuilder.array([]),
                weight: ['', Validators.required],
                weight_unit: ['', Validators.required],
                columnsToFill: this.formBuilder.array([])
            });
            this.addSkuControl('');
        }
    }

    getKeyLabel(key: string): string {
        const keys: string[] = Object.keys(this.predefinedColumns);
        if (keys.includes(key)) {
            return this.predefinedColumns[key].label;
        } else if (Object.keys(this.predefinedPrivateColumns).includes(key)) {
            return this.predefinedPrivateColumns[key].label;
        }
        return key;
    }

    onAddColumn(key: string = '', value: string = '', editingMultipleLines = false) {
        const label = new FormControl(this.getKeyLabel(key), Validators.required);
        this.getColumnsToFill().push(this.formBuilder.group({
            label: label,
            value: new FormControl(value, Validators.required)
        }));

        // for autocompletion field
        this.filteredWholePossibleColumns.push(label.valueChanges.pipe(
            startWith(''),
            map(val => this._filter(val, editingMultipleLines))
        ));

        if (key !== '') {
            const allPredefinedColumns = editingMultipleLines ? {...this.predefinedColumns, ...this.predefinedPrivateColumns} : this.predefinedColumns;
            const col_keys = Object.keys(allPredefinedColumns);
            let i = 0;
            while (i < col_keys.length && col_keys[i] !== key) {
                i += 1;
            }

            if (i < col_keys.length) {
                this.currentColumnsToFill.push(col_keys[i]);
                if (col_keys[i] === 'to_country') {
                    this.subscribeToCountryChanges();
                } else if (col_keys[i] === 'to_district') {
                    const iCountry = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
                    // USA? we must display the state selection field
                    if ((this.getColumnsToFill().at(iCountry) as FormGroup).controls['value'].value === 'USA') {
                        const splittedValue = value.split('-');
                        this.chooseUSAStateForm.patchValue({selectState: splittedValue[0] + '-' + splittedValue[1]});
                        this.subscribeToStateChanges(iCountry);
                        const stateDistricts = districts['USA'].filter((district) =>
                            String(district.value).startsWith(splittedValue[0] + '-' + splittedValue[1])
                        );
                        this.districts = stateDistricts
                            ? stateDistricts.length < 800
                                ? stateDistricts.sort(Utils.compareByLabel)
                                : stateDistricts.slice(0, 800).sort(Utils.compareByLabel)
                            : [];
                    }
                }
            } else {
                this.currentColumnsToFill.push(key);
            }
        } else {
            this.currentColumnsToFill.push(key);
        }
    }

    // type has changed
    onChangeType(type: CUSTOMER_PRODUCT_TYPE_ENUM) {
        if (type === CUSTOMER_PRODUCT_TYPE_ENUM.SKU) {
            this.addSkuControl('');
        } else {
            this.rowForm.removeControl('sku');
        }
    }

    // weight has changed
    onChangeWeight(weight: string) {
        if (this.editRowInitialState !== null) {
            if (this.editRowInitialState.weight !== weight) {
                if (!this.columnsChanged.includes('weight')) {
                    this.columnsChanged.push('weight');
                }
            } else if (this.columnsChanged.includes('weight')) {
                this.columnsChanged.splice(this.columnsChanged.findIndex(key => key === 'weight'), 1);
            }
        }
    }

    // weight unit has changed
    onChangeWeightUnit(weightUnit: string) {
        if (this.editRowInitialState !== null) {
            if (this.editRowInitialState.weight_unit !== weightUnit) {
                if (!this.columnsChanged.includes('weight_unit')) {
                    this.columnsChanged.push('weight_unit');
                }
            } else if (this.columnsChanged.includes('weight_unit')) {
                this.columnsChanged.splice(this.columnsChanged.findIndex(key => key === 'weight_unit'), 1);
            }
        }
    }

    // column value has changed
    onChangeColumnValue(value, index, editMultipleRows = false) {
        if (editMultipleRows || this.editRowInitialState !== null) {
            // a value has changed, control its label
            const label: string = (this.getColumnsToFill().at(index) as FormGroup).controls.label.value;
            const keys: string[] = Object.keys(this.predefinedColumns);
            let i = 0;
            while (i < keys.length && this.predefinedColumns[keys[i]].label !== label) {
                i += 1;
            }

            // key found
            if (i < keys.length) {
                if (
                    editMultipleRows ||
                    (
                        this.editRowInitialState[keys[i]] !== undefined &&
                        this.editRowInitialState[keys[i]] !== value
                    )
                ) {
                    if (!this.columnsChanged.includes(keys[i])) {
                        this.columnsChanged.push(keys[i]);
                    }
                } else if (this.columnsChanged.includes(keys[i])) {
                    this.columnsChanged.splice(this.columnsChanged.findIndex(key => key === keys[i]), 1);
                }
            }
        }
    }

    // return the readable calculation to delete suffix
    getCalculationToDeleteSuffix(calcToDelete: string): string {
        return this.warningDeletionSuffixes[calcToDelete];
    }

    // calculations to delete
    getCalculationsToDelete(): string[] {
        if (this.editRowInitialState !== null) {
            const calculations: string[] = [];
            let i: number;

            // hsCodeFinder
            if (this.editRowInitialState.hscodefinder_hs_code !== undefined) {
                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('hsCodeFinder')) {
                    if (this.mandatoryFieldsForActions.hsCodeFinder.includes(this.columnsChanged[i])) {
                        calculations.push('hsCodeFinder');
                    }

                    i += 1;
                }
            }

            // dutyCalculation
            if (this.editRowInitialState.dutycalculation_global_amount !== undefined) {
                // check first to_country : if Swiss, check if weight or weight_unit has changed
                if (
                    this.editRowInitialState.to_country &&
                    this.editRowInitialState.to_country === 'CHE'
                ) {
                    if (this.columnsChanged.includes('weight') || this.columnsChanged.includes('weight_unit')) {
                        calculations.push('dutyCalculation');
                    }
                }
                // check now if to_country is USA, Brasil or Canada, check if to_district has changed
                if (
                    !calculations.includes('dutyCalculation') &&
                    this.editRowInitialState.to_country &&
                    ['USA', 'BRA', 'CAN'].includes(this.editRowInitialState.to_country)
                ) {
                    if (this.columnsChanged.includes('to_district')) {
                        calculations.push('dutyCalculation');
                    }
                }

                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('dutyCalculation')) {
                    if (this.mandatoryFieldsForActions.dutyCalculation.includes(this.columnsChanged[i])) {
                        calculations.push('dutyCalculation');
                    }

                    i += 1;
                }
            }

            // marginCalculation
            if (this.editRowInitialState.margin !== undefined) {
                if (calculations.includes('dutyCalculation')) {
                    calculations.push('marginCalculation');
                } else {
                    let totalPossibleChanges = 0;
                    let totalChanges = 0;
                    if (
                        this.editRowInitialState.sale_unit_price !== undefined &&
                        this.editRowInitialState.sale_unit_price > 0 &&
                        this.editRowInitialState.sale_currency_unit_price &&
                        this.editRowInitialState.sale_currency_unit_price.length > 0
                    ) {
                        totalPossibleChanges += 1;
                        if (this.columnsChanged.includes('sale_unit_price') || this.columnsChanged.includes('sale_currency_unit_price')) {
                            totalChanges += 1;
                        }
                    }
                    if (
                        this.editRowInitialState.purchase_unit_price !== undefined &&
                        this.editRowInitialState.purchase_unit_price > 0 &&
                        this.editRowInitialState.purchase_currency_unit_price &&
                        this.editRowInitialState.purchase_currency_unit_price.length > 0
                    ) {
                        totalPossibleChanges += 1;
                        if (this.columnsChanged.includes('purchase_unit_price') || this.columnsChanged.includes('purchase_currency_unit_price')) {
                            totalChanges += 1;
                        }
                    }

                    if (totalChanges === 2 || (totalChanges === 1 && totalPossibleChanges === 1)) {
                        calculations.push('marginCalculation');
                    }
                }
            }

            // costPrice
            if (this.editRowInitialState.cost_price !== undefined) {
                if (
                    this.columnsChanged.includes('unit_price') ||
                    this.columnsChanged.includes('currency_unit_price') ||
                    this.columnsChanged.includes('quantity') ||
                    this.columnsChanged.includes('currency_ship_price') ||
                    (
                        (
                            (this.editRowInitialState.unit_ship_price && this.columnsChanged.includes('unit_ship_price'))
                            ||
                            ! this.editRowInitialState.unit_ship_price
                        )
                        &&
                        (
                            (this.editRowInitialState.global_ship_price && this.columnsChanged.includes('global_ship_price'))
                            ||
                            ! this.editRowInitialState.global_ship_price
                        )
                    )
                ) {
                    calculations.push('costPrice');
                }
            }

            // trade tariff rates duties
            if (this.editRowInitialState.order_duty_minimis !== undefined) {
                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('tradeTariffRatesDuties')) {
                    if (this.mandatoryFieldsForActions.tradeTariffRatesDuties.includes(this.columnsChanged[i])) {
                        calculations.push('tradeTariffRatesDuties');
                    }

                    i += 1;
                }
            }

            // trade tariff rates taxes
            if (this.editRowInitialState.locals_taxes_label_1 !== undefined) {
                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('tradeTariffRatesTaxes')) {
                    if (
                        this.mandatoryFieldsForActions.tradeTariffRatesTaxes.includes(this.columnsChanged[i])
                        ||
                        (
                            this.editRowInitialState.to_country === 'USA'
                            &&
                            this.columnsChanged.includes('to_district')
                        )
                    ) {
                        calculations.push('tradeTariffRatesTaxes');
                    }

                    i += 1;
                }
            }

            // trade tariff rates special taxes
            if (this.editRowInitialState.other_taxes_label_1 !== undefined) {
                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('tradeTariffRatesSpecialTaxes')) {
                    if (this.mandatoryFieldsForActions.tradeTariffRatesSpecialTaxes.includes(this.columnsChanged[i])) {
                        calculations.push('tradeTariffRatesSpecialTaxes');
                    }

                    i += 1;
                }
            }

            // eco tax
            if (this.editRowInitialState.ecoTax_code_personal !== undefined) {
                i = 0;
                while (i < this.columnsChanged.length && !calculations.includes('ecoTax')) {
                    if (
                        this.mandatoryFieldsForActions.ecoTax.includes(this.columnsChanged[i])
                        &&
                        (
                            ! this.rowForm.value.columnsToFill.includes('to_country')
                            ||
                            (
                                this.rowForm.value.columnsToFill.includes('to_country')
                                &&
                                ! this.countryIsEu(this.rowForm.value.columnsToFill.to_country)
                            )
                        )
                    ) {
                        calculations.push('ecoTax');
                    }

                    i += 1;
                }
            }

            return calculations;
        } else {
            return [];
        }
    }

    // name is the written text
    onChangeColumnName(name: string, i: number, editingMultipleLines = false) {
        const allPredefinedColumns = editingMultipleLines ? {...this.predefinedColumns, ...this.predefinedPrivateColumns} : this.predefinedColumns;
        const keys = Object.keys(allPredefinedColumns);
        let j = 0;
        while (j < keys.length && allPredefinedColumns[keys[j]].label !== name) {
            j += 1;
        }

        // reset the value as the label has changed
        this.getColumnsToFill().at(i).patchValue({value: null});

        if (j < keys.length) {
            this.currentColumnsToFill[i] = keys[j];
            // to_district selected? need to set to_country first if not set
            if (keys[j] === 'to_district') {
                if (!this.currentColumnsToFill.includes('to_country')) {
                    const label = new FormControl(allPredefinedColumns.to_country.label, Validators.required);
                    // for autocompletion
                    this.filteredWholePossibleColumns.splice(i, 0, label.valueChanges.pipe(
                        startWith(''),
                        map(value => this._filter(value, editingMultipleLines))
                    ));

                    this.getColumnsToFill().insert(i, this.formBuilder.group({
                        label: label,
                        value: new FormControl('', Validators.required)
                    }));
                    this.currentColumnsToFill.splice(i, 0, 'to_country');

                    this.subscribeToCountryChanges();
                } else {
                    const iCountry = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
                    // USA? we must display the state selection field
                    if ((this.getColumnsToFill().at(iCountry) as FormGroup).controls['value'].value === 'USA') {
                        this.subscribeToStateChanges(iCountry);
                    }
                }
            } else if (keys[j] === 'to_country') {
                this.subscribeToCountryChanges();

            // to_country must be filled if we set a hscode
            } else if (keys[j] === 'hscodefinder_hs_code' && !this.currentColumnsToFill.includes('to_country')) {
                this.onAddColumn('to_country', '', editingMultipleLines);
            }
            this.resetLabelsOfAllExtraSameLabels(keys[j]);
        } else {
            this.currentColumnsToFill[i] = name;
            if (name !== '') {
                this.resetLabelsOfAllExtraSameLabels(name);
            }
        }
    }

    getToCountry(): string {
        const iCountry = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
        if (iCountry >= 0) {
            return (this.getColumnsToFill().at(iCountry) as FormGroup).controls['value'].value;
        } else {
            return '';
        }
    }

    // is the label the first set with that name?
    isColumnLabelTheFirstSetOrWrongKey(index: number, editingMultipleLines = false): boolean {
        return (
            (
                this.currentColumnsToFill.indexOf(this.currentColumnsToFill[index]) === index
                &&
                !(Object.keys(this.privateColumns)).includes((this.getColumnsToFill().at(index) as FormGroup).controls['label'].value)
                &&
                !(Object.keys(this.predefinedColumns)).includes((this.getColumnsToFill().at(index) as FormGroup).controls['label'].value)
            )
            ||
            (editingMultipleLines && ['weight', 'weight_unit'].includes((this.getColumnsToFill().at(index) as FormGroup).controls['label'].value))
        );
    }

    resetLabelsOfAllExtraSameLabels(label: string) {
        const indexes = [];
        let i = -1;
        while ((i = this.currentColumnsToFill.indexOf(label, i + 1)) !== -1) {
            indexes.push(i);
        }

        // remove the first element as it is not concerned
        indexes.shift();

        i = 0;
        while (i < indexes.length) {
            this.getColumnsToFill().at(indexes[i]).patchValue({value: null});
            i += 1;
        }
    }

    subscribeToCountryChanges() {
        if (this.currentColumnsToFill && this.currentColumnsToFill.length > 0) {
            // we must "listen" to to_country value changes to change to_district available values, and we must initialize to_district if to_country is set
            const iCountry = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
            if (iCountry >= 0) {
                this.districts = this.getDistricts((this.getColumnsToFill().at(iCountry) as FormGroup).controls['value'].value);
                this.toCountrySubscription = (this.getColumnsToFill().at(iCountry) as FormGroup).controls.value.valueChanges.subscribe((toCountry) => {

                    const iDistrict = this.currentColumnsToFill.findIndex((col) => col === 'to_district');
                    if (iDistrict >= 0) {
                        // if country is USA, we must display the states first
                        if (toCountry === 'USA') {
                            this.subscribeToStateChanges(iCountry);
                        } else {
                            this.unsubscribeToStateChangesAndReinitializeStatePlace();
                        }
                        this.getColumnsToFill().at(iDistrict).patchValue({value: null});
                    }
                    this.districts = this.getDistricts(toCountry);
                });
            }

        // called from fillrequiredfields
        } else {
            if (
                this.actionRequiredFieldsForm.controls.to_country.value === 'USA' &&
                this.actionRequiredFieldsForm.controls.to_district.value.startsWith('US-')
            ) {
                const splittedValue = this.actionRequiredFieldsForm.controls.to_district.value.split('-');
                this.chooseUSAStateForm.patchValue({selectState: splittedValue[0] + '-' + splittedValue[1]});
                const stateDistricts = districts['USA'].filter((district) => String(district.value).startsWith(splittedValue[0] + '-' + splittedValue[1]));
                this.districts = stateDistricts
                    ? stateDistricts.length < 800
                        ? stateDistricts.sort(Utils.compareByLabel)
                        : stateDistricts.slice(0, 800).sort(Utils.compareByLabel)
                    : [];
            } else {
                this.districts = this.getDistricts(this.actionRequiredFieldsForm.controls.to_country.value);
            }
            this.toCountrySubscription = this.actionRequiredFieldsForm.controls.to_country.valueChanges.subscribe((toCountry) => {

                // if country is USA, we must display the states first
                if (toCountry === 'USA') {
                    this.subscribeToStateChanges(-1);
                } else {
                    this.unsubscribeToStateChangesAndReinitializeStatePlace();
                }
                if (!['USA', 'CAN', 'BRA'].includes(toCountry)) {
                    this.actionRequiredFieldsForm.patchValue({to_district: 'no_district'});
                } else {
                    this.actionRequiredFieldsForm.patchValue({to_district: null});
                }
                this.districts = this.getDistricts(toCountry);
            });
        }
    }

    subscribeToStateChanges(iCountry: number) {
        this.displayStateSelection = iCountry;
        this.toStateSubscription = this.chooseUSAStateForm.controls.selectState.valueChanges.subscribe((state) => {

            if (this.currentColumnsToFill && this.currentColumnsToFill.length > 0) {
                const iDistrict = this.currentColumnsToFill.findIndex((col) => col === 'to_district');
                this.getColumnsToFill().at(iDistrict).patchValue({value: null});

            // called from fillrequiredfields
            } else {
                this.actionRequiredFieldsForm.patchValue({to_district: null});
            }

            const stateDistricts = districts['USA'].filter((district) => String(district.value).startsWith(state));
            this.districts = stateDistricts
                ? stateDistricts.length < 800
                    ? stateDistricts.sort(Utils.compareByLabel)
                    : stateDistricts.slice(0, 800).sort(Utils.compareByLabel)
                : [];
        });
    }

    unsubscribeToCountryChangesAndReinitializeToDistrictOptions() {
        this.unsubscribeToStateChangesAndReinitializeStatePlace();
        // unsubscribe to_country changes
        if (this.toCountrySubscription) {
            try {
                this.toCountrySubscription.unsubscribe();
                this.toCountrySubscription = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
        }
        this.districts = [];
    }

    unsubscribeToStateChangesAndReinitializeStatePlace() {
        // unsubscribe to_state changes
        if (this.toStateSubscription) {
            try {
                this.toStateSubscription.unsubscribe();
                this.toStateSubscription = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
            this.displayStateSelection = -1;
            this.chooseUSAStateForm.patchValue({selectState: null});
        }
    }

    onRemoveColumn(i: number) {
        // if to_country field is removed, we must remove to_district too
        if (this.currentColumnsToFill[i] === 'to_country') {
            let j = this.currentColumnsToFill.findIndex((col) => col === 'to_district');
            // to district set and found
            if (j >= 0) {
                if (!this.columnsChanged.includes(this.currentColumnsToFill[j])) {
                    this.columnsChanged.push(this.currentColumnsToFill[j]);
                }
                this.getColumnsToFill().removeAt(j);
                this.filteredWholePossibleColumns.splice(j, 1);
                this.currentColumnsToFill.splice(j, 1);

                j = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
                if (j >= 0) {
                    if (!this.columnsChanged.includes(this.currentColumnsToFill[j])) {
                        this.columnsChanged.push(this.currentColumnsToFill[j]);
                    }
                    this.getColumnsToFill().removeAt(j);
                    this.filteredWholePossibleColumns.splice(j, 1);
                    this.currentColumnsToFill.splice(j, 1);
                }
            } else {
                if (!this.columnsChanged.includes(this.currentColumnsToFill[i])) {
                    this.columnsChanged.push(this.currentColumnsToFill[i]);
                }
                this.getColumnsToFill().removeAt(i);
                this.filteredWholePossibleColumns.splice(i, 1);
                this.currentColumnsToFill.splice(i, 1);
            }

            j = this.currentColumnsToFill.findIndex((col) => col === 'hscodefinder_hs_code');
            // hscode set and found
            if (j >= 0) {
                if (!this.columnsChanged.includes(this.currentColumnsToFill[j])) {
                    this.columnsChanged.push(this.currentColumnsToFill[j]);
                }
                this.getColumnsToFill().removeAt(j);
                this.filteredWholePossibleColumns.splice(j, 1);
                this.currentColumnsToFill.splice(j, 1);

                j = this.currentColumnsToFill.findIndex((col) => col === 'to_country');
                if (j >= 0) {
                    if (!this.columnsChanged.includes(this.currentColumnsToFill[j])) {
                        this.columnsChanged.push(this.currentColumnsToFill[j]);
                    }
                    this.getColumnsToFill().removeAt(j);
                    this.filteredWholePossibleColumns.splice(j, 1);
                    this.currentColumnsToFill.splice(j, 1);
                }
            }

            this.unsubscribeToCountryChangesAndReinitializeToDistrictOptions();
        } else if (this.currentColumnsToFill[i] === 'to_district') {
            if (!this.columnsChanged.includes(this.currentColumnsToFill[i])) {
                this.columnsChanged.push(this.currentColumnsToFill[i]);
            }
            this.getColumnsToFill().removeAt(i);
            this.filteredWholePossibleColumns.splice(i, 1);
            this.currentColumnsToFill.splice(i, 1);

            // in case the selected to_country is USA
            this.unsubscribeToStateChangesAndReinitializeStatePlace();
        } else {
            if (!this.columnsChanged.includes(this.currentColumnsToFill[i])) {
                this.columnsChanged.push(this.currentColumnsToFill[i]);
            }
            this.getColumnsToFill().removeAt(i);
            this.filteredWholePossibleColumns.splice(i, 1);
            this.currentColumnsToFill.splice(i, 1);
        }
    }

    async onValidRow(csvRow: any = null) {
        // predefined columns keys
        const keys = Object.keys(this.predefinedColumns);
        // row to add from CSV
        // csvRow is as {'productID': 'sac à main', ...}
        if (csvRow !== null) {
            const productIndex: number = this.rows.findIndex(row => row.sku === csvRow.sku);
            if (productIndex >= 0) {
                this._notification.displayMessage(`SKU ${csvRow.sku} ${this.messages.alreadyExists}`, 'danger');
            } else {
                // row labels
                const labels: string[] = Object.keys(csvRow);
                const newRow: any = {};
                let parsedCols = 0;
                labels.forEach(async (label) => {
                    // remove accents as it does issues when there is at least 1 in header
                    const colName: string = await normalize(label);
                    if (colName === 'categories_id') {
                        newRow[colName] = csvRow[label] ? csvRow[label].split('||') : [];
                    } else {
                        if (colName !== 'to_countries') {
                            newRow[colName] = (!isNaN(csvRow[label] as any) ? parseFloat(csvRow[label]) : csvRow[label]);
                        }
                    }

                    this.appendColInColumnsToDisplay(label);

                    // if all columns have been parsed, send data to the server
                    parsedCols += 1;
                    if (parsedCols === labels.length) {
                        // check that type column is not missing
                        if (!labels.includes('type')) {
                            newRow.type = CUSTOMER_PRODUCT_TYPE_ENUM.SKU;
                        }
                        const rowToSend = {...newRow};
                        rowToSend.value = rowToSend.productID;
                        delete rowToSend.productID;
                        // post new product
                        const newProduct = await this.postProduct(rowToSend);
                        if (newProduct) {
                            this.rows.push(newProduct.toRow());
                            this.rowsHaveChanged();
                        }
                    }
                });
            }

        // row to add from form
        } else {
            if (this.rowForm.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const formValue = this.rowForm.value;
            const productIndex: number = this.rowToEditId ? this.rows.findIndex(row => row.sku === this.rowToEditId) : -1;
            const predefinedColumnsLabels: string[] = [];

            keys.forEach(key => {
                predefinedColumnsLabels.push(this.predefinedColumns[key].label);
            });

            // product does not exist and new row
            if (productIndex < 0 && !this.rowToEditId) {
                let parsedCols = 0;
                const toCountries: { [key: string]: ProductHscodeContent } = {};
                formValue.to_countries.forEach(formData => {
                    toCountries[formData.country] = Builder(ProductHscodeContent).hscodefinder_hs_code(formData.hscode).build();
                });
                const product = Builder(CustomerProduct)
                                .type(formValue.type)
                                .sku(formValue.sku ? formValue.sku : undefined)
                                .to_countries(Object.keys(toCountries).length > 0 ? toCountries : undefined)
                                .categories_id(formValue.categories_id.map(item => item.value))
                                .value(formValue.productID)
                                .weight(parseFloat(formValue.weight))
                                .weight_unit(formValue.weight_unit)
                                .build();
                if (formValue['columnsToFill'].length === 0) {
                    // post new product
                    const newProduct = await this.postProduct(product);
                    if (newProduct) {
                        this.rows.push(newProduct.toRow());
                        this.rowsHaveChanged();
                    }
                } else {
                    for (let parseForm = 0; parseForm < formValue['columnsToFill'].length; parseForm++) {
                        const col = formValue['columnsToFill'][parseForm];
                        // remove accents as it does issues when there is at least 1 in header
                        const colName: string = await normalize(col.label);
                        // check if selected label is a predefined column label
                        if (predefinedColumnsLabels.includes(col.label)) {
                            const key: string = keys[predefinedColumnsLabels.findIndex(pcl => pcl === col.label)];
                            product[key] = (typeof col.value === 'string' && !isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                            // append col in columnsToDisplay
                            this.appendColInColumnsToDisplay(key);
                        } else {
                            product[colName] = (!isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                            // append col in columnsToDisplay
                            this.appendColInColumnsToDisplay(colName);
                        }

                        // if all columns have been parsed, send data to the server
                        parsedCols += 1;
                        if (parsedCols === formValue['columnsToFill'].length) {
                            // post new product
                            const newProduct = await this.postProduct(product);
                            if (newProduct) {
                                this.rows.push(newProduct.toRow());
                                this.rowsHaveChanged();
                            }
                        }
                    }
                }
                this.closeModal();

            // product exists and edit row
            } else if (productIndex >= 0 && this.rowToEditId) {
                const toCountries: { [key: string]: ProductHscodeContent } = {};
                formValue.to_countries.forEach(formData => {
                    if (this.toCountriesDetails[formValue.sku] && this.toCountriesDetails[formValue.sku][formData.country] && this.toCountriesDetails[formValue.sku][formData.country].hscodefinder_hs_code === formData.hscode) {
                        toCountries[formData.country] = this.toCountriesDetails[formValue.sku][formData.country];
                    } else {
                        toCountries[formData.country] = Builder(ProductHscodeContent).hscodefinder_hs_code(formData.hscode).build();
                    }
                });
                let product = Builder(CustomerProduct)
                            .type(formValue.type)
                            .sku(formValue.sku ? formValue.sku : undefined)
                            .to_countries(Object.keys(toCountries).length > 0 ? toCountries : undefined)
                            .categories_id(formValue.categories_id.map(item => item.value))
                            .value(formValue.productID)
                            .weight(typeof formValue.weight === 'string' ? parseFloat(formValue.weight) : formValue.weight)
                            .weight_unit(formValue.weight_unit)
                            .build();

                if (formValue['columnsToFill'].length === 0) {
                    // sku and productID could be changed
                    const body = {
                        sku: product.sku,
                        productID: product.value,
                        weight: product.weight,
                        weight_unit: product.weight_unit,
                        categories_id: product.categories_id,
                        to_countries: product.to_countries
                    };

                    // update product
                    product = await this.putProduct(body, this.rowToEditId);
                    if (product) {
                        const row = {
                            id: product.id, // id to use for API requests
                            type: product.type,
                            productID: product.value,
                            sku: product.sku,
                            weight: product.weight,
                            weight_unit: product.weight_unit
                        };
                        const pKeys = Object.keys(product);
                        const keysToIgnore = ['id', 'type', 'value', 'sku', 'weight', 'weight_unit', 'attribut', 'creationDate', 'hscodefinder', 'dutyCalculation'];
                        pKeys.forEach(k => {
                            if (!keysToIgnore.includes(k)) {
                                if ((k === 'unit' || k === 'unit_type')) {
                                    if (product[k]) {
                                        row[k] = product[k];
                                    }
                                } else {
                                    row[k] = product[k];
                                }
                            }
                        });
                        this.rows[productIndex] = row;
                        this.rowsHaveChanged();
                    }
                } else {
                    const allProductKeys: string[] = Object.keys(this.rows[productIndex]);
                    const privateColumnsKeys: string[] = Object.keys(this.privateColumns);
                    const keysToGuard: string[] = allProductKeys.filter(key =>
                        !['type', 'sku', 'productID', 'weight', 'weight_unit'].includes(key) && privateColumnsKeys.includes(key)
                    );

                    let parsedCols = 0;
                    const calcToDelete: string[] = this.getCalculationsToDelete();
                    for (let parseForm = 0; parseForm < formValue['columnsToFill'].length; parseForm++) {
                        const col = formValue['columnsToFill'][parseForm];

                        // remove accents as it does issues when there is at least 1 in header
                        const colName: string = await normalize(col.label);
                        // check if selected label is a predefined column label then we must replace the label with the key
                        if (predefinedColumnsLabels.includes(col.label)) {
                            const key: string = keys[predefinedColumnsLabels.findIndex(pcl => pcl === col.label)];
                            product[key] = (typeof col.value === 'string' && !isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                            // append col in columnsToDisplay
                            this.appendColInColumnsToDisplay(key);
                        } else {
                            product[colName] = (!isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                            // append col in columnsToDisplay
                            this.appendColInColumnsToDisplay(colName);
                        }

                        // if all columns have been parsed, send data to the server
                        parsedCols += 1;
                        if (parsedCols === formValue['columnsToFill'].length) {
                            let i = 0;
                            while (i < calcToDelete.length) {
                                if (calcToDelete[i] === 'hsCodeFinder') {
                                    let j = 0;
                                    while (j < keysToGuard.length) {
                                        if (keysToGuard[j].startsWith('hscodefinder')) {
                                            keysToGuard.splice(j, 1);
                                        } else {
                                            j += 1;
                                        }
                                    }
                                } else if (calcToDelete[i] === 'dutyCalculation') {
                                    let j = 0;
                                    while (j < keysToGuard.length) {
                                        if (keysToGuard[j].startsWith('dutycalculation')) {
                                            keysToGuard.splice(j, 1);
                                        } else {
                                            j += 1;
                                        }
                                    }
                                } else if (calcToDelete[i] === 'marginCalculation') {
                                    let j = 0;
                                    while (j < keysToGuard.length) {
                                        if (keysToGuard[j].startsWith('margin')) {
                                            keysToGuard.splice(j, 1);
                                        } else {
                                            j += 1;
                                        }
                                    }
                                } else if (calcToDelete[i] === 'costPrice') {
                                    let j = 0;
                                    while (j < keysToGuard.length) {
                                        if (keysToGuard[j] === 'cost_price' || keysToGuard[j] === 'currency_cost_price') {
                                            keysToGuard.splice(j, 1);
                                        } else {
                                            j += 1;
                                        }
                                    }
                                }
                                i += 1;
                            }

                            // must delete product_statut too
                            if (calcToDelete.includes('hsCodeFinder') && calcToDelete.includes('dutyCalculation')) {
                                keysToGuard.splice(keysToGuard.findIndex(key => key === 'product_statut'), 1);
                            }

                            // get a copy of editedRow without private keys added in it
                            const editedRowWithoutCalculations = {...product};

                            // append all keys to guard in edited row
                            /*keysToGuard.forEach(key => {
                                product[key] = this.rows[productIndex][key];
                            });*/

                            // put product
                            product = await this.putProduct(editedRowWithoutCalculations, this.rowToEditId);
                            if (product) {
                                const row = {
                                    id: product.id, // id to use for API requests
                                    type: product.type,
                                    productID: product.value,
                                    sku: product.sku,
                                    weight: product.weight,
                                    weight_unit: product.weight_unit
                                };
                                const pKeys = Object.keys(product);
                                const keysToIgnore = ['id', 'type', 'value', 'sku', 'weight', 'weight_unit', 'attribut', 'creationDate', 'hscodefinder', 'dutyCalculation'];
                                pKeys.forEach(k => {
                                    if (!keysToIgnore.includes(k)) {
                                        if ((k === 'unit' || k === 'unit_type')) {
                                            if (product[k]) {
                                                row[k] = product[k];
                                            }
                                        } else {
                                            row[k] = product[k];
                                        }
                                    }
                                });
                                this.rows[productIndex] = row;
                                this.rowsHaveChanged();
                            }
                        }
                    }
                }

                this.closeModal();

            // product exists and new row => prevent the user he cannot do that
            } else {
                this.modalErrorMessage = 'productID ' + this.messages.alreadyExists;
            }
        }

        this.selectedRows = [];
    }

    async onValidMultipleRowsEdit() {
        if (this.rowForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        // predefined columns keys
        const allPredefinedColumns = {...this.predefinedColumns, ...this.predefinedPrivateColumns};
        const keys = Object.keys(allPredefinedColumns);

        const formValue = this.rowForm.value;
        const predefinedColumnsLabels: string[] = [];

        keys.forEach(key => {
            predefinedColumnsLabels.push(allPredefinedColumns[key].label);
        });

        const toCountries: { [key: string]: ProductHscodeContent } = {};
        formValue.to_countries.forEach(formData => {
            if (this.toCountriesDetails[formValue.sku] && this.toCountriesDetails[formValue.sku][formData.country] && this.toCountriesDetails[formValue.sku][formData.country].hscodefinder_hs_code === formData.hscode) {
                toCountries[formData.country] = this.toCountriesDetails[formValue.sku][formData.country];
            } else {
                toCountries[formData.country] = Builder(ProductHscodeContent).hscodefinder_hs_code(formData.hscode).build();
            }
        });
        const categories_id = formValue.categories_id ? formValue.categories_id.map(item => item.value) : undefined;

        const updatesToDo: CustomerProduct[] = [];
        for (let parseRows = 0; parseRows < this.selectedRows.length; parseRows++) {
            const row = this.selectedRows[parseRows];
            const product = plainToClass(CustomerProduct, row);
            product.value = product.productID;
            delete product.productID;

            if (Object.keys(toCountries).length > 0) {
                product.to_countries = toCountries;
            }
            if (categories_id) {
                product.categories_id = categories_id;
            }

            const allProductKeys: string[] = Object.keys(row);
            const privateColumnsKeys: string[] = Object.keys(this.privateColumns).concat(Object.keys(this.predefinedColumns));
            const keysToGuard: string[] = allProductKeys.filter(key =>
                !['type', 'sku', 'productID', 'weight', 'weight_unit', 'to_countries', 'categories_id'].includes(key) && privateColumnsKeys.includes(key)
            );
            const formCols: string[] = [];

            this.editRowInitialState = row;
            const calcToDelete: string[] = this.getCalculationsToDelete();
            this.editRowInitialState = null;
            for (let parseForm = 0; parseForm < formValue.columnsToFill.length; parseForm++) {
                const col = formValue.columnsToFill[parseForm];

                // remove accents as it does issues when there is at least 1 in header
                const colName: string = await normalize(col.label);
                // check if selected label is a predefined column label then we must replace the label with the key
                if (predefinedColumnsLabels.includes(col.label)) {
                    const key: string = keys[predefinedColumnsLabels.findIndex(pcl => pcl === col.label)];
                    product[key] = (typeof col.value === 'string' && !isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                    // append col in columnsToDisplay
                    this.appendColInColumnsToDisplay(key);
                    if (! formCols.includes(key)) {
                        formCols.push(key);
                    }
                } else {
                    product[colName] = (!isNaN(col.value as any) ? parseFloat(col.value) : col.value);
                    // append col in columnsToDisplay
                    this.appendColInColumnsToDisplay(colName);
                    if (! formCols.includes(colName)) {
                        formCols.push(colName);
                    }
                }
            }

            let i = 0;
            while (i < calcToDelete.length) {
                if (calcToDelete[i] === 'hsCodeFinder') {
                    let j = 0;
                    while (j < keysToGuard.length) {
                        if (keysToGuard[j].startsWith('hscodefinder')) {
                            keysToGuard.splice(j, 1);
                        } else {
                            j += 1;
                        }
                    }
                } else if (calcToDelete[i] === 'dutyCalculation') {
                    let j = 0;
                    while (j < keysToGuard.length) {
                        if (keysToGuard[j].startsWith('dutycalculation')) {
                            keysToGuard.splice(j, 1);
                        } else {
                            j += 1;
                        }
                    }
                } else if (calcToDelete[i] === 'marginCalculation') {
                    let j = 0;
                    while (j < keysToGuard.length) {
                        if (keysToGuard[j].startsWith('margin')) {
                            keysToGuard.splice(j, 1);
                        } else {
                            j += 1;
                        }
                    }
                } else if (calcToDelete[i] === 'costPrice') {
                    let j = 0;
                    while (j < keysToGuard.length) {
                        if (keysToGuard[j] === 'cost_price' || keysToGuard[j] === 'currency_cost_price') {
                            keysToGuard.splice(j, 1);
                        } else {
                            j += 1;
                        }
                    }
                }
                i += 1;
            }

            // must delete product_statut too
            if (calcToDelete.includes('hsCodeFinder') && calcToDelete.includes('dutyCalculation')) {
                keysToGuard.splice(keysToGuard.findIndex(key => key === 'product_statut'), 1);
            }

            // get a copy of editedRow without private keys added in it
            const productKeys = Object.keys(product);
            productKeys.forEach(key => {
                if (! formCols.includes(key) && ! keysToGuard.includes(key) && ! ['type', 'sku', 'value', 'weight', 'weight_unit', 'to_countries', 'categories_id'].includes(key)) {
                    delete product[key];
                }
            });

            updatesToDo.push(product);
        }

        await Promise.all(updatesToDo.map(p => this.preparePromisePutMultipleProducts(p)));
        this._notification.displayMessage(this.translate.instant('messages.notification.productsManager.rowsModified'), 'success');
        this.rowsHaveChanged();
        this.closeModal();
        this.selectedRows = [];
    }

    async preparePromisePutMultipleProducts(editedRowWithoutCalculations: CustomerProduct): Promise<void> {
        // put product
        const product = await this.putProduct(editedRowWithoutCalculations, editedRowWithoutCalculations.sku);
        if (product) {
            const row = {
                id: product.id, // id to use for API requests
                type: product.type,
                productID: product.value,
                sku: product.sku,
                weight: product.weight,
                weight_unit: product.weight_unit
            };
            const pKeys = Object.keys(product);
            const keysToIgnore = ['id', 'type', 'value', 'sku', 'weight', 'weight_unit', 'attribut', 'creationDate', 'hscodefinder', 'dutyCalculation'];
            pKeys.forEach(k => {
                if (!keysToIgnore.includes(k)) {
                    if ((k === 'unit' || k === 'unit_type')) {
                        if (product[k]) {
                            row[k] = product[k];
                        }
                    } else {
                        row[k] = product[k];
                    }
                }
            });
            this.rows[this.rows.findIndex(r => r.sku === editedRowWithoutCalculations.sku)] = row;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
        }
        localStorage.setItem('undisplayedColumns', JSON.stringify(this.undisplayedColumns));
        localStorage.setItem('columnsToDisplay', JSON.stringify(this.columnsToDisplay));
    }

    onSelect({ selected }) {
        this.selectedRows.splice(0, this.selectedRows.length);
        this.selectedRows.push(...selected);
    }

    // filter used for autocompletion
    private _filter(value: string, editingMultipleLines = false): string[] {
        const filterValue = value.toLowerCase();

        if (! editingMultipleLines) {
            return this.wholePossibleColumns.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
        } else {
            return this.wholePossibleColumns.concat(
                Object.keys(this.predefinedPrivateColumns)
                .map(key => this.predefinedPrivateColumns[key].label)
            ).filter(option => option.toLowerCase().indexOf(filterValue) === 0);
        }
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    // post products, returns true on success, false otherwise
    async postProduct(product: CustomerProduct): Promise<CustomerProduct> {
        // if SKU is not set, we must set 1
        if (!product.sku || product.sku.length <= 1) {
            // 10 first value capitalized characters + 10 first timestamp value (until seconds)
            product.sku = (await normalize(product.value.replace(/\s/g, ''))).substring(0, 10).toUpperCase()
                        +
                        (new Date()).getTime().toString().substring(0, 10);
        }
        if (product.hscodefinder_hs_code) {
            product.hscodefinder_hs_code = product.hscodefinder_hs_code.toString();
        }

        if (typeof product.sku === 'number') {
            product.sku = (product.sku as number).toString();
        }
        try {
            const result: any = await this.http.post(`https://api.${environment.baseUrl}/v1/customer/products/`, product).toPromise();
            const newProduct = plainToClass(CustomerProduct, result);
            this.products.push(newProduct);
            this.managersService.addProduct(newProduct);
            return newProduct;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(
                product.value + ' : ' +
                this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error),
                'danger'
            );
            return null;
        }
    }

    // put product, returns true on success, false otherwise
    async putProduct(body: any, id: string): Promise<CustomerProduct> {
        this.changeTaxesToArrays(body);
        try {
            if (!body.type) {
                body.type = CUSTOMER_PRODUCT_TYPE_ENUM.SKU;
            }
            if (body.productID) {
                body.value = body.productID;
                delete body.productID;
            }
            // if SKU is not set, we must set 1
            if (!body.sku || body.sku.length === 0) {
                // 10 first value capitalized characters + 10 first timestamp value (until seconds)
                body.sku = (await normalize(body.value.replace(/\s/g, ''))).substring(0, 10).toUpperCase()
                            +
                            (new Date()).getTime().toString().substring(0, 10);
            }
            if (body.hscodefinder_hs_code) {
                body.hscodefinder_hs_code = body.hscodefinder_hs_code.toString();
            }
            // tslint:disable-next-line: max-line-length
            const response: CustomerProduct = (await this.http.put(`https://api.${environment.baseUrl}/v1/customer/products?sku=${encodeURIComponent(id)}`, body).toPromise()) as CustomerProduct;
            const productAsClass = plainToClass(CustomerProduct, response);
            const index = this.products.findIndex(p => p.sku === productAsClass.sku);
            if (index >= 0) {
                this.products[index] = productAsClass;
            } else {
                this.products.push(productAsClass);
            }
            return response;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    changeTaxesToArrays(row: any) {
        const keys = Object.getOwnPropertyNames(row);
        // re-add locals taxes & special taxes as arrays
        const localsTaxesCount = parseInt((keys.filter(k => k.startsWith('locals_taxes_')).length / 2).toFixed(0), 10);
        if (localsTaxesCount > 0 || this.products.findIndex(p => p.sku === row.sku && p.locals_taxes) >= 0) {
            row.locals_taxes = [];
        }
        for (let i = 0; i < localsTaxesCount; i++) {
            row.locals_taxes.push({
                label: row[`locals_taxes_label_${i + 1}`],
                percentage: row[`locals_taxes_percentage_${i + 1}`]
            });
        }
        keys.filter(k => k.startsWith('locals_taxes_')).forEach(k => {
            delete row[k];
        });
        const specialTaxesCount = parseInt((keys.filter(k => k.startsWith('other_taxes_')).length / 2).toFixed(0), 10);
        if (specialTaxesCount > 0 || this.products.findIndex(p => p.sku === row.sku && p.other_taxes) >= 0) {
            row.other_taxes = [];
        }
        for (let i = 0; i < specialTaxesCount; i++) {
            row.other_taxes.push({
                label: row[`other_taxes_label_${i + 1}`],
                percentage: row[`other_taxes_percentage_${i + 1}`]
            });
        }
        keys.filter(k => k.startsWith('other_taxes_')).forEach(k => {
            delete row[k];
        });
    }

    // get products, returns true on success, false otherwise
    async getProducts(): Promise<CustomerProduct[]> {
        try {
            return (<CustomerProduct[]> await this.http.get(`https://api.${environment.baseUrl}/v1/customer/products/`).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return [];
        }
    }

    // delete products, returns true on success, false otherwise
    async deleteProduct(productID: string): Promise<boolean> {
        // shall never happen, handle it anyway
        if (productID === undefined) {
            return true;
        }
        try {
            await this.http.delete(`https://api.${environment.baseUrl}/v1/customer/products?sku=${productID}`).toPromise();
            const index = this.rows.findIndex(r => r.sku === productID);
            const indexProduct = this.products.findIndex(p => p.sku === productID);
            this.rows.splice(index, 1);
            this.products.splice(indexProduct, 1);
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    async getAmountConverted(amount: number, fromCurrency: string, toCurrency: string): Promise<number> {
        try {
            const params = new HttpParams().set('fromCurrency', fromCurrency).set('toCurrency', toCurrency).set('amount', amount.toString());
            const result = await this.toolsService.convert(params);
            return result.result;
        } catch (errorResponse) {
            console.log('ERROR in getAmountConverted : ' + JSON.stringify(errorResponse));
            return null;
        }
    }

    countryIsEu(iso3: string): boolean {
        return this.continentsWithCountries.EU.includes(iso3);
    }

    skuOrLabelInFilter(row: any): boolean {
        if (this.filterProductContent) {
            return this.filterProductContent === '' ||
                row.sku.toLowerCase().includes(this.filterProductContent.toLowerCase()) ||
                row.productID.toLowerCase().includes(this.filterProductContent.toLowerCase()) ||
                this.filterProductContent.toLowerCase().includes(row.sku.toLowerCase()) ||
                this.filterProductContent.toLowerCase().includes(row.productID.toLowerCase());
        }
        return true;
    }

    getRows(): any[] {
        const rightCountry = this.countries.find(c => c.value === this.filterCountry);
        return this.rows.filter(r => (rightCountry.value === 'ALL_COUNTRIES' || r.to_country === rightCountry.iso2 || r.to_country === rightCountry.value) && this.skuOrLabelInFilter(r));
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['from_country', 'to_country', 'origin_country', 'country'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }

        // prepare format currencies
        if (this.currencies) {
            formatValues.push({
                columns: [
                    'margin_currency',
                    'currency_cost_price',
                    'threshold_tax_currency',
                    'currency_minimis',
                    'ecoTax_price_currency',
                    'currency_ship_price',
                    'currency_unit_price',
                    'currency_revenue_country_annual',
                    'currency_cost_price',
                    'purchase_currency_unit_price',
                    'sale_currency_unit_price'
                ],
                type: this.currencies as { value: string; label: string; }[]
            });
        }

        if (this.user.planIsEcommerce()) {
            formatValues.push({
                columns: ['hscodefinder_hs_code'],
                type: 'boolToCheck'
            });
        }

        this.formatColumnsValues = formatValues;
    }

    onDisplayRow(rowId: string) {
        this.displayRowOnly = true;
        this.openModalFromTable(this.editrow, 'displayrow', rowId);
    }
    onEditRow(rowId: string) {
        this.displayRowOnly = false;
        this.openModalFromTable(this.editrow, 'editrow', rowId);
    }
    onDeleteRow(rowId: string) {
        this.openModalFromTable(this.confirmDeleteRow, 'deleterow', rowId);
    }
    onDeleteRows(selectedRows: string[]) {
        if (this.rowsToDelete.length === 0) {
            this.rowsToDelete = selectedRows;
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            this.modalRef = this.modalService.show(this.confirmDeleteRows, config);
        }
    }
    onCancelDeleteRows() {
        this.rowsToDelete = [];
        this.closeModal();
    }
    async onValidRowDeletion() {
        this.closeModal();
        await this.deleteProduct(this.productToDeleteSku);
        this.productToDeleteSku = null;
    }
    // delete rows 10 by 10
    async onValidRowsDeletion() {
        this.closeModal();

        let allSelectedOrdersDeleted = true;
        const rowsIds: string[][] = [];
        rowsIds.push([]);
        this.rowsToDelete.forEach(orderId => {
            if (rowsIds[rowsIds.length - 1].length === 10) {
                rowsIds.push([]);
            }
            rowsIds[rowsIds.length - 1].push(orderId);
        });

        for (let i = 0; i < rowsIds.length; i++) {
            const promises = await Promise.all(rowsIds[i].map(r => this.deleteProduct(r)));
            if (promises.includes(false)) {
                allSelectedOrdersDeleted = false;
            }
        }

        if (! allSelectedOrdersDeleted) {
            this._notification.displayMessage(this.translate.instant('messages.notification.someRowsNotDeleted'), 'warning');
        } else {
            this._notification.displayMessage(this.translate.instant('messages.notification.rowsDeleted'), 'success');
        }
    }

    onClaimSelectedRows() {
        this.requestSelectedRowsSubject.next();
    }
    onReceiveSelectedRows(rowsIds: string[]) {
        this.selectedRows = this.rows.filter(r => rowsIds.includes(r.sku));
        switch (this.selectedRowsNextAction) {
            case PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV:
                if (this.selectedRows.length > 0) {
                    this.downloadCsv();
                }
                break;
            case PRODUCTS_SELECTED_ROWS_ACTIONS.EDIT_MULTIPLE:
                if (! this.actionInProgress && this.selectedRows.length > 0) {
                    this.openModal(this.editmultiplerows, 'editmultiplerows');
                }
                break;
            case PRODUCTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION:
                if (this.selectedRows.length > 0) {
                    this.onValidAction();
                }
                break;
            default:
                break;
        }
    }

    onDownloadSelectedRowsAsCsv() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV;
        this.onClaimSelectedRows();
    }
    onEditMultipleRows() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.EDIT_MULTIPLE;
        this.onClaimSelectedRows();
    }
    onExecuteAction() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION;
        this.onClaimSelectedRows();
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv() {
        const csvResponse = this.selectedRows.slice();
        csvResponse.forEach(row => {
            delete row.id;
        });
        const csvFileName = 'my_catalog';
        return this.csvService.downloadFile(csvResponse, csvFileName);
    }

    getColsToDisplayForPlan(): any[] {
        if (! this.columnsToDisplayPerPlan[this.user.getPlan()]) {
            return this.columnsToDisplay;
        }
        return this.columnsToDisplay.filter(col => this.columnsToDisplayPerPlan[this.user.getPlan()].includes(col.value));
    }

    getColsToUndisplayForPlan(): any[] {
        if (! this.columnsToDisplayPerPlan[this.user.getPlan()]) {
            return this.undisplayedColumns;
        }
        return this.undisplayedColumns.filter(col => this.columnsToDisplayPerPlan[this.user.getPlan()].includes(col.value));
    }

    getColumnsToDisplayList(): string[] {
        const cols = this.getColsToDisplayForPlan();
        return cols.map(col => col.value);
    }

    getColumnsToDisplayLabels(): { [key: string]: string; } {
        const labels: { [key: string]: string; } = {};
        const cols = this.getColsToDisplayForPlan();
        cols.forEach(col => {
            labels[col.value] = col.label;
        });
        return labels;
    }

    // Rows details
    private initRowsDetails(): void {
        const tempToCountriesDetails: { [key: string]: { [key: string]: ProductHscodeContent } } = {};
        this.products.forEach(product => {
            tempToCountriesDetails[product.sku] = product.to_countries ? product.to_countries : {};
        });
        this.toCountriesDetails = tempToCountriesDetails;
        this.setToCountriesDetailsAsArray();
    }
    private setToCountriesDetailsAsArray(): void {
        const toCountries = Object.keys(this.toCountriesDetails);
        const formattedToCountriesDetails: { [key: string]: { [key: string]: any }[] } = {};
        toCountries.forEach(id => {
            const countriesIsos = Object.keys(this.toCountriesDetails[id]);
            formattedToCountriesDetails[id] = countriesIsos.map(c => {
                const tableRow = {country: c, ...this.toCountriesDetails[id][c]};
                return tableRow;
            });
        });
        this.formattedToCountriesDetailsForTable = formattedToCountriesDetails;
    }

    async onCheckAutoUpdateClicked(): Promise<void> {
        if (this.user && this.user.planIsEcommerce()) {
            const updateUser = {...this.user} as User;
            updateUser.ecommerceProperties.products_auto_update = ! updateUser.ecommerceProperties.products_auto_update;
            await this.userService
                .updateTransiteoUser(updateUser)
                .then((user) => {
                    this.user = user;
                    console.log(`auto update is now ${user.ecommerceProperties.products_auto_update ? 'activated' : 'disabled'}`);
                })
                .catch((error) => {
                    this._notification.displayMessage(error.error.message, 'danger');
                });
        }
    }
}
