import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'embed-website',
    templateUrl: './embed-website.component.html',
    styleUrls: ['./embed-website.component.scss']
})
export class EmbedWebsiteComponent implements OnInit {
    @Input()
    url: string;
    @Input()
    isClosable = false;
    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    sanitizedUrl: SafeUrl;

    constructor(private domSanitizer: DomSanitizer) { }

    ngOnInit() {
        console.log(this.url);
        this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    onClose() {
        this.close.emit();
    }
}
