import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamoProduct, LogisticProduct} from '../../../../models/DynamoProduct';

@Component({
  selector: 'app-logistic-product',
  templateUrl: './logistic-product.component.html',
  styleUrls: ['./logistic-product.component.scss']
})
export class LogisticProductComponent implements OnInit {

  @Input()
  product: DynamoProduct;
  logisticProduct: LogisticProduct;
  @Input() displayMetric: (value, metric) => string;
  constructor() {}

  ngOnInit() {
    this.logisticProduct = this.product as LogisticProduct;
  }

  display(value: number, metric) {
    return this.displayMetric(value, metric);
  }
}
