import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AiClassifyEnum } from '../../../models/HsCodeFinder';
import { TradeTariffRatesDuties, TradeTariffRatesEcoTax, TradeTariffRatesProductTextRequest, TradeTariffRatesRequest, TradeTariffRatesSpecialTaxes, TradeTariffRatesTaxes, TRADE_TARIFF_RATES_URL_SUFFIXES } from '../../../models/TradeTariffRates';
import { MessageService } from '../../components/message/message.service';
import { pgTabComponent } from '../../components/tabs/tab.component';
import { ApiDataService } from '../../services/api-data.service';
import { CsvService } from '../../services/csv.service';
import { ManagersService } from '../../services/managers.service';
import { Utils } from '../../utils/utils';
const districts = require('../../../../assets/data/districtsGroupByIsoCountry.json');
const us_states = require('../../../../assets/data/US_states.json');

enum TRADE_TARIFF_RATES_STEP_ENUM {
    SEARCH = 1,
    RESULTS = 2,
}

@Component({
    selector: 'app-trade-tariff-rates',
    templateUrl: './trade-tariff-rates.component.html',
    styleUrls: ['./trade-tariff-rates.component.scss']
})
export class TradeTariffRatesComponent implements OnInit, OnDestroy {
    TRADE_TARIFF_RATES_STEP_ENUM = TRADE_TARIFF_RATES_STEP_ENUM;
    @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;

    countries: { value: string; label: string; iso2: string; }[] = [];
    districts: { value: string; label: string; }[] = [];
    us_states: { value: string; label: string; }[] = [];
    stateWithDistrict = ['USA', 'CAN', 'BRA'];
    current_step: number;
    types: { urlSuffix: string; label: string; }[] = [];
    searchTypes: { value: string; label: string; }[] = [];
    searchTypesEcotax: { value: string; label: string; }[] = [];
    selectedDataTypeUrlSuffix: string;
    searchClickable = true;

    tradeTariffRatesForm: FormGroup;
    formIsInvalid = false;
    tradeTariffRatesRequest: TradeTariffRatesRequest | TradeTariffRatesProductTextRequest;
    tradeTariffRatesResult: TradeTariffRatesTaxes | TradeTariffRatesDuties | TradeTariffRatesSpecialTaxes | TradeTariffRatesEcoTax;
    // content to display as result, depends on selected data type, filled with translations files
    resultsContentDisplay: { [key: string]: any } = {};

    filteredOptions: Observable<string[]> = new Observable<string[]>();

    private unknown_error: string;
    private toCountrySubscription: Subscription;
    private toStateSubscription: Subscription;
    private readonly tradeTariffRatesBaseUrl: string;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private managersService: ManagersService,
        private apiDataService: ApiDataService,
        private csvService: CsvService,
        private domSanitizer: DomSanitizer
    ) {
        this.tradeTariffRatesBaseUrl = `https://api.${environment.baseUrl}/v1/data`;
        this.current_step = TRADE_TARIFF_RATES_STEP_ENUM.SEARCH;
        this.selectedDataTypeUrlSuffix = TRADE_TARIFF_RATES_URL_SUFFIXES.DUTIES;
        this.us_states = us_states;
        this.initForm();
    }
    async ngOnInit() {
        this.initTranslation();
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        this.managersService.prepareProducts();
        this.managersService.prepareCategories();
    }

    ngOnDestroy() {
        this.unsubscribeToCountryChanges();
    }

    private initForm(newSelectedType?: string) {
        // type has been changed, we must modify form required values if necessary
        if (newSelectedType) {
            this.unsubscribeToCountryChanges();
            const oldValues = this.tradeTariffRatesForm.value;
            switch (newSelectedType) {
                case TRADE_TARIFF_RATES_URL_SUFFIXES.ECO_TAX:
                    this.tradeTariffRatesForm = new FormGroup({
                        type: new FormControl(AiClassifyEnum.TEXT, [Validators.required]),
                        product: new FormControl(oldValues.product, [Validators.required]), // product_text, hscode, sku or category
                        from_country: new FormControl(oldValues.from_country),
                        to_country: new FormControl(oldValues.to_country, [Validators.required]),
                    });
                    break;
                case TRADE_TARIFF_RATES_URL_SUFFIXES.SALES_TAXES:
                    this.tradeTariffRatesForm = new FormGroup({
                        type: new FormControl(oldValues.type, [Validators.required]),
                        product: new FormControl(oldValues.product, [Validators.required]), // product_text, hscode, sku or category
                        from_country: new FormControl(oldValues.from_country, [Validators.required]),
                        to_country: new FormControl('USA', [Validators.required]),
                        to_state: new FormControl(oldValues.to_state), // USA only
                        to_district: new FormControl(oldValues.to_district, [Validators.required]) // USA
                    });
                    this.subscribeToStateChanges();
                    break;

                default:
                    this.tradeTariffRatesForm = new FormGroup({
                        type: new FormControl(oldValues.type, [Validators.required]),
                        product: new FormControl(oldValues.product, [Validators.required]), // product_text, hscode, sku or category
                        from_country: new FormControl(oldValues.from_country, [Validators.required]),
                        to_country: new FormControl(oldValues.to_country, [Validators.required]),
                        to_state: new FormControl(oldValues.to_state), // USA only
                        to_district: new FormControl(oldValues.to_district) // USA, CAN or BRA
                    });
                    this.subscribeToCountryChanges();
                    if (oldValues.to_country === 'USA') {
                        this.subscribeToStateChanges();
                    }
                    this.setFormDistrictValidators();
                    break;
            }

        // reinitialize
        } else {
            this.tradeTariffRatesForm = new FormGroup({
                type: new FormControl(AiClassifyEnum.TEXT, [Validators.required]),
                product: new FormControl(null, [Validators.required]), // product_text, hscode, sku or category
                from_country: new FormControl(null, [Validators.required]),
                to_country: new FormControl(null, [Validators.required]),
                to_state: new FormControl(null), // USA only
                to_district: new FormControl(null) // USA, CAN or BRA
            });
            this.subscribeToCountryChanges();
        }
    }

    private initTranslation() {
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });

        this.translate.get('tradeTariffRates').toPromise().then((ttr) => {
            this.types = ttr.types;
            this.searchTypes = ttr.searchTypes;
            this.searchTypesEcotax = this.searchTypes.filter(st => st.value === AiClassifyEnum.TEXT);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.setCountries();
            this.unknown_error = event.translations.messages.notification.unknown_error;
            this.types = event.translations.tradeTariffRates.types;
            this.searchTypes = event.translations.tradeTariffRates.searchTypes;
            this.searchTypesEcotax = this.searchTypes.filter(st => st.value === AiClassifyEnum.TEXT);

            // we must change results tab translations
            if (this.current_step === TRADE_TARIFF_RATES_STEP_ENUM.RESULTS) {
                this.setResultsContentDisplay();
            }
        });
    }
    private setResultsContentDisplay(): void {
        this.resultsContentDisplay = this.translate.instant(`tradeTariffRates.results.${this.selectedDataTypeUrlSuffix}`);
    }

    async searchTradeTariffRates(): Promise<void> {
        if (this.tradeTariffRatesForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        // set search button not clickable
        this.searchClickable = false;
        // get form values
        const formValues = this.tradeTariffRatesForm.value;
        // build the body request depending on the type (duties, taxes, ...)
        switch (this.selectedDataTypeUrlSuffix) {
            case TRADE_TARIFF_RATES_URL_SUFFIXES.ECO_TAX:
                this.tradeTariffRatesRequest = Builder(TradeTariffRatesProductTextRequest)
                                            .product_text(formValues.product)
                                            .to_country(formValues.to_country)
                                            .build();
                break;
            default:
                this.tradeTariffRatesRequest = Builder(TradeTariffRatesRequest)
                                            .product_text(this.isText() ? formValues.product : undefined)
                                            .hs_code(this.isHsCode() ? formValues.product : undefined)
                                            .sku(this.isSku() ? formValues.product : undefined)
                                            .category(this.isCategory() ? formValues.product : undefined)
                                            .from_country(formValues.from_country)
                                            .to_country(this.selectedDataTypeUrlSuffix !== TRADE_TARIFF_RATES_URL_SUFFIXES.SALES_TAXES ? formValues.to_country : undefined)
                                            .to_district(formValues.to_district ? formValues.to_district : undefined)
                                            .build();
                break;
        }
        // call the API
        await this.callTradeTariffRates();
        // display results tab on success
        this.displayResultsTab();
        this.formIsInvalid = false;
        // set search button clickable
        this.searchClickable = true;
    }
    private async callTradeTariffRates(): Promise<void> {
        try {
            this.tradeTariffRatesResult = (
                await this.http.post(`${this.tradeTariffRatesBaseUrl}/${this.selectedDataTypeUrlSuffix}`, this.tradeTariffRatesRequest).toPromise()
            ) as TradeTariffRatesTaxes | TradeTariffRatesDuties | TradeTariffRatesSpecialTaxes | TradeTariffRatesEcoTax;
        } catch (errorResponse) {
            const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
            this._notification.displayMessage(transiteoError, 'danger');
            this.tradeTariffRatesResult = null;
        }
    }

    // display results tab if we have any results to display
    private displayResultsTab(): void {
        if (this.tradeTariffRatesResult) {
            this.setResultsContentDisplay();
            if (this.child) {
                this.child.setLabel(1);
            }
            this.current_step = TRADE_TARIFF_RATES_STEP_ENUM.RESULTS;
            this.unsubscribeToCountryChanges();
        }
    }
    newSearch() {
        this.selectedDataTypeUrlSuffix = TRADE_TARIFF_RATES_URL_SUFFIXES.DUTIES;
        this.resultsContentDisplay = {};
        this.initForm();
        this.current_step = TRADE_TARIFF_RATES_STEP_ENUM.SEARCH;
        if (this.child) {
            this.child.setLabel(0);
        }
    }

    isActualStep(step: number): boolean {
        return step === this.current_step;
    }

    private async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ?
                            c[`label_${this.translate.currentLang}`] + ' (' + c.value + ')' :
                            c[`label_en`] + ' (' + c.value + ')'
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }

    prepareAutocompleteProduct() {
        const control = this.tradeTariffRatesForm.controls['product'];
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), 'sku')),
        );
    }
    prepareAutocompleteCategory() {
        const control = this.tradeTariffRatesForm.controls['product'];
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsCategories(), 'category_id')),
        );
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        if (value !== null && value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            return wholeValues.filter(option => {
                const optionSplitted = option.split(', ');
                if (filterField === 'sku') {
                    return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
                }
                let i = 0;
                if (filterField === 'email') {
                    i = 1;
                } else if (filterField === 'website') {
                    i = 2;
                }
                return optionSplitted[i].toLowerCase().includes(filterValue);
            });
        }
    }

    onProductClicked(option: string) {
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            this.tradeTariffRatesForm.get('product').setValue(rightProduct.sku);
        }
    }
    onCategoryClicked(option: string) {
        const categoryId = option.split(', ')[0];
        const rightCategory = this.managersService.getCategories().find(c => c.category_id === categoryId);
        if (rightCategory) {
            this.tradeTariffRatesForm.get('product').setValue(rightCategory.category_id);
        }
    }

    // type has been changed, we must modify form required values if necessary
    onTypeChange(e): void {
        this.initForm(this.selectedDataTypeUrlSuffix);
    }
    switchCountries() {
        const tempCountry = this.tradeTariffRatesForm.get('from_country').value;
        this.tradeTariffRatesForm.get('from_country').setValue(this.tradeTariffRatesForm.get('to_country').value);
        this.tradeTariffRatesForm.get('to_country').setValue(tempCountry);
    }
    needDistrict() {
        if (this.tradeTariffRatesForm.get('to_country').value) {
            return this.stateWithDistrict.includes(this.tradeTariffRatesForm.get('to_country').value);
        } else {
            return false;
        }
    }
    private getDistricts(toCountry) {
        return toCountry && toCountry !== 'USA' && districts[toCountry]
            ? districts[toCountry].length < 500
                ? districts[toCountry].sort(Utils.compareByLabel)
                : districts[toCountry].slice(0, 500).sort(Utils.compareByLabel)
            : [];
    }
    isText(): boolean {
        return this.tradeTariffRatesForm.get('type').value === AiClassifyEnum.TEXT;
    }
    isHsCode(): boolean {
        return this.tradeTariffRatesForm.get('type').value === AiClassifyEnum.HSCODE;
    }
    isSku(): boolean {
        return this.tradeTariffRatesForm.get('type').value === AiClassifyEnum.SKU;
    }
    isCategory(): boolean {
        return this.tradeTariffRatesForm.get('type').value === AiClassifyEnum.CATEGORY;
    }
    isDutiesSearch(): boolean {
        return this.selectedDataTypeUrlSuffix === TRADE_TARIFF_RATES_URL_SUFFIXES.DUTIES;
    }
    isTaxesSearch(): boolean {
        return this.selectedDataTypeUrlSuffix === TRADE_TARIFF_RATES_URL_SUFFIXES.TAXES;
    }
    isSalesTaxesSearch(): boolean {
        return this.selectedDataTypeUrlSuffix === TRADE_TARIFF_RATES_URL_SUFFIXES.SALES_TAXES;
    }
    isSpecialTaxesSearch(): boolean {
        return this.selectedDataTypeUrlSuffix === TRADE_TARIFF_RATES_URL_SUFFIXES.SPECIAL_TAXES;
    }
    isEcoTaxSearch(): boolean {
        return this.selectedDataTypeUrlSuffix === TRADE_TARIFF_RATES_URL_SUFFIXES.ECO_TAX;
    }
    typeIsEcoTax(type: string): boolean {
        return type === TRADE_TARIFF_RATES_URL_SUFFIXES.ECO_TAX;
    }
    private setFormDistrictValidators() {
        if (this.stateWithDistrict.includes(this.tradeTariffRatesForm.get('to_country').value)) {
            this.tradeTariffRatesForm.get('to_district').setValidators([Validators.required]);
        } else {
            this.tradeTariffRatesForm.get('to_district').setValidators([]);
        }
    }
    private subscribeToCountryChanges() {
        this.toCountrySubscription = this.tradeTariffRatesForm.controls.to_country.valueChanges.subscribe((toCountry) => {
            // if country is USA, we must display the states first
            if (toCountry === 'USA') {
                this.subscribeToStateChanges();
            } else {
                this.unsubscribeToStateChanges();
            }
            this.tradeTariffRatesForm.patchValue({to_district: null});
            this.districts = this.getDistricts(toCountry);
            this.setFormDistrictValidators();
        });
    }
    private subscribeToStateChanges() {
        this.toStateSubscription = this.tradeTariffRatesForm.controls.to_state.valueChanges.subscribe((state) => {
            this.tradeTariffRatesForm.patchValue({to_district: null});

            const stateDistricts = districts['USA'].filter((district) => String(district.value).startsWith(state));
            this.districts = stateDistricts
                ? stateDistricts.length < 800
                    ? stateDistricts.sort(Utils.compareByLabel)
                    : stateDistricts.slice(0, 800).sort(Utils.compareByLabel)
                : [];
        });
    }
    private unsubscribeToCountryChanges() {
        this.unsubscribeToStateChanges();
        // unsubscribe to_country changes
        if (this.toCountrySubscription) {
            try {
                this.toCountrySubscription.unsubscribe();
                this.toCountrySubscription = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
        }
        this.districts = [];
    }
    private unsubscribeToStateChanges() {
        // unsubscribe to_state changes
        if (this.toStateSubscription) {
            try {
                this.toStateSubscription.unsubscribe();
                this.toStateSubscription = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
        }
    }

    getPgSelectCustomStyle(width: number, marginLeft: number): SafeStyle {
        return this.domSanitizer.bypassSecurityTrustStyle(`width: ${width}px; margin-left: ${marginLeft}px;`);
    }
    exportCsv() {
        const flattenRequest = this.csvService.flattenObjectToBuildCsv('request_', this.tradeTariffRatesRequest);
        const flattenResponse = this.csvService.flattenObjectToBuildCsv('response_', this.tradeTariffRatesResult);
        const wholeData = flattenRequest;
        const keys = Object.keys(flattenResponse);
        keys.forEach(key => {
            wholeData[key] = flattenResponse[key];
        });
        this.csvService.downloadFile(wholeData, `${this.selectedDataTypeUrlSuffix}_${(new Date()).getTime()}`);
    }
}
