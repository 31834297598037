import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequiredFields} from '../interfaces/required-fields.interface';
import {Options, StreamsOptions} from '../interfaces/options.interface';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ProductsRequiredFieldsWithFromToCountries} from '../interfaces/products-required-fields.interface';
import {Option} from '../interfaces/option.interface';
import {TaxRequest} from '../../../../models/DutyCalculation';

@Component({
    selector: 'app-step-options',
    templateUrl: './step-options.component.html',
    styleUrls: ['./step-options.component.scss']
})
export class StepOptionsComponent implements OnInit {

    @Input()
    requiredFields: ProductsRequiredFieldsWithFromToCountries[] = [];
    @Input()
    taxRequest: TaxRequest;
    @Input()
    weightMandatory: boolean;
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    setData: EventEmitter<TaxRequest> = new EventEmitter<TaxRequest>();
    @Output()
    previousStep: EventEmitter<void> = new EventEmitter<void>();

    options: StreamsOptions;
    optionsForm: FormGroup;
    formIsInvalid = false;

    weightUnits: Array<{ label: string, value: string }>;
    otherUnits: Array<{ label: string, value: string }>;

    constructor(private translate: TranslateService) {
        this.options = {
            streams: []
        };
        this.optionsForm = new FormGroup({
            streams: new FormArray([])
        });
    }

    async ngOnInit() {
        await this.initTranslation();
        if (this.requiredFields) {
            for (let i = 0; i < this.requiredFields.length; i++) {
                this.options.streams.push({products: []});
                this.setOptionsFromRequiredField(i, this.requiredFields[i].products);
                this.buildForm(this.options.streams[i], i);
            }
        }
    }

    async initTranslation() {
        await this.translate.get('dutyCalculation').subscribe(dutyCalculation => {
            this.updateDutyTranslation(dutyCalculation);
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateDutyTranslation(event.translations['dutyCalculation']);
        });
    }
    private updateDutyTranslation(dutyCalculation) {
        const steps = dutyCalculation.steps;
        if (steps && steps.options && steps.options.units) {
            this.weightUnits = steps.options.units.weight;
            this.otherUnits = steps.options.units.other;
        }
    }

    get streams(): FormArray {
        return this.optionsForm.get('streams') as FormArray;
    }

    getStreamProducts(i: number): FormArray {
        return this.streams.at(i).get('products') as FormArray;
    }

    buildStreamForm(stream: ProductsRequiredFieldsWithFromToCountries): FormGroup {
        return new FormGroup({
            fromCountry: new FormControl(stream.from_country),
            toCountry: new FormControl(stream.to_country),
            toDistrict: new FormControl(stream.to_district),
            products: new FormArray([])
        });
    }

    buildForm(options: Options, streamIndex: number) {
        this.streams.push(this.buildStreamForm(this.requiredFields[streamIndex]));
        if (options.products) {
            for (let i = 0; i < options.products.length; i++) {
                this.getStreamProducts(streamIndex).push(this.buildProductForm(options.products[i], i, streamIndex));
            }
            if (! this.displayStream(this.streams.length - 1)) {
                const productsForm = this.getStreamProducts(this.streams.length - 1);
                for (let i = 0; i < productsForm.length; i++) {
                    productsForm.at(i).get('weight').setValue('1');
                    productsForm.at(i).get('weight_unit').setValue('kg');
                }
            }
        }
    }

    buildProductForm(product: Option, index: number, streamIndex: number): FormGroup {
        const productForm = new FormGroup({
            id: new FormControl(product.identification.value, Validators.required),
            weight: new FormControl(
                product.weight ? product.weight : (this.weightForProductIsMandatory(index, streamIndex) ? '' : '1'),
                [Validators.min(0.01), Validators.required]
            ),
            weight_unit: new FormControl(
                product.weight_unit ? product.weight_unit : (this.weightForProductIsMandatory(index, streamIndex) ? '' : 'kg'),
                Validators.required
            ),
            units: new FormArray([])
        });
        if (product.requiredUnits && product.requiredUnits.length > 0) {
            for (let i = 0; i < product.requiredUnits.length; i++) {
                this.weightUnits.forEach(element => {
                    if (element.value === product.requiredUnits[i]) {
                        productForm.controls['weight_unit'].setValue(product.requiredUnits[i]);
                    }
                });
                this.units(productForm).push(new FormGroup( {
                    unit: new FormControl('', Validators.required),
                    unit_type: new FormControl(product.requiredUnits[i], Validators.required)
                }));
            }
        }
        return productForm;
    }

    /**
     * Return the FormArray units in a productForm
     * @param productForm
     */
    units(productForm: FormGroup): FormArray {
        return productForm.get('units') as FormArray;
    }

    setOptionsFromRequiredField(streamIndex: number, productsRequiredFields: RequiredFields[]) {
        if (productsRequiredFields) {
            for (let i = 0; i < productsRequiredFields.length; i++) {
                const element = productsRequiredFields[i];
                const product = this.taxRequest.products.find(prod => prod.identification.value === element.identification.value);
                const option: Option = {
                    identification: {type: element.identification.type, value: element.identification.value},
                    unit: product && product.unit ? product.unit : 0,
                    unit_type: product && product.unit_type ? product.unit_type : '',
                    weight: product && product.weight ? product.weight : null,
                    weight_unit: product && product.weight_unit ? product.weight_unit : '',
                    requiredUnits: element.requiredFields.units
                };
                this.options.streams[streamIndex].products.push(option);
            }
        }
    }

    /**
     * Test if required units have a weight unit
     * Return false if it have a weight unit, true otherwise
     * @param unit
     */
    unitsHaveWeightUnit(units: Array<FormGroup>): boolean {
        let value = false;
        if (units) {
            for (let i = 0; i < units.length; i++) {
                const unit: FormGroup = units[i];
                this.weightUnits.forEach(element => {
                    if (element.value === unit.controls['unit_type'].value) {
                        value = true;
                    }
                });
            }
        }
        return value;
    }

    /**
     * Test if required unit is a weight unit
     * Return true if it is a weight unit, false otherwise
     * @param unit
     */
    unitIsNotAWeightUnit(unit: FormGroup | string): boolean {
        let value = true;
        if (typeof unit === 'string') {
            this.weightUnits.forEach(element => {
                if (element.value === unit) {
                    value = false;
                }
            });
        } else {
            this.weightUnits.forEach(element => {
                if (element.value === unit.controls['unit_type'].value) {
                    value = false;
                }
            });
        }
        return value;
    }

    setUnit(event, product: FormGroup) {
        if (this.unitsHaveWeightUnit(this.units(product).controls as Array<FormGroup>)) {
            if (event) {
                (this.units(product).controls as Array<FormGroup>).forEach( element => {
                    if ( !this.unitIsNotAWeightUnit(element) ) {
                        element.controls['unit'].setValue(product.controls['weight'].value);
                    }
                });
            }
        }
    }

    nextStep() {
        if (this.optionsForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const values = this.optionsForm.getRawValue();
        values.streams.forEach( stream => {
            stream.products.forEach( product => {
                this.taxRequest.products.forEach( taxProduct => {
                    if (
                        taxProduct.identification.value === product.id &&
                        taxProduct.from_country === stream.fromCountry &&
                        taxProduct.to_country === stream.toCountry
                    ) {
                        taxProduct.weight = product.weight;
                        taxProduct.weight_unit = product.weight_unit;
                        if (product.units[0] && this.unitIsNotAWeightUnit(product.units[0].unit_type)) {
                            taxProduct.unit = product.units[0].unit;
                            taxProduct.unit_type = product.units[0].unit_type;
                        }
                    }
                });
            });
        });
        this.formIsInvalid = false;
        this.setData.emit(this.taxRequest);
    }

    goBack() {
        this.previousStep.emit();
    }

    weightForProductIsMandatory(index: number, streamIndex: number): boolean {
        if (this.weightMandatory) {
            return true;
        }
        const product = this.options.streams[streamIndex].products[index];
        if (product.requiredUnits && this.weightUnits && product.requiredUnits.length > 0) {
            let i = 0;
            while (i < product.requiredUnits.length) {
                let j = 0;
                while (j < this.weightUnits.length) {
                    if (this.weightUnits[j].value === product.requiredUnits[i]) {
                        return true;
                    }
                    j += 1;
                }
                i += 1;
            }
        }
        return false;
    }

    getCountry(streamIndex: number, formName: string): string {
        const value = this.streams.at(streamIndex).get(formName).value;
        const country = this.countries.find(c => c.value === value);
        return country ? country.label : value;
    }

    displayStream(streamIndex: number): boolean {
        if (
            this.requiredFields[streamIndex].products &&
            (
                this.requiredFields[streamIndex].products.length > 1 ||
                this.requiredFields[streamIndex].products[0].requiredFields.units.length > 0 ||
                this.requiredFields[streamIndex].to_country === 'CHE'
            )
        ) {
            return true;
        }
        return false;
    }

}
