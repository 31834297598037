import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ECOMMERCE_REQUEST_TYPE_ENUM, RequestProductAllCurrencies, ResponseProduct, ShipmentTypeEnum, SHIPMENT_REQUEST_TYPE_ENUM, Tax, TaxGlobal, TaxRequest, TaxResponse} from '../../../../models/DutyCalculation';
import {CsvService} from '../../../services/csv.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

const districts = require('../../../../../assets/data/districts.json');
const transports = require( '../../../../../assets/data/transports.json');

export enum OTHER_VAT_TYPE {
    ALL = 'ALL',
    PRODUCT = 'PRODUCT',
    SHIPPING = 'SHIPPING',
    PACKAGING = 'PACKAGING',
    INSURANCE = 'INSURANCE'
}

@Component({
    selector: 'app-step-result',
    templateUrl: './step-result.component.html',
    styleUrls: ['./step-result.component.scss']
})
export class StepResultComponent implements OnInit {

    @Input()
    taxResponse: TaxResponse[];
    @Input()
    taxRequest: TaxRequest;
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    new: EventEmitter<any> = new EventEmitter();

    receiverActivity: string;
    transports: Array<{value: string, label: string}>;

    translates: any = null;
    wholeDescription: string;

    requestProductsStreams: RequestProductAllCurrencies[][] = [];

    initDone = false;

    constructor(
        private csvService: CsvService,
        private translate: TranslateService
    ) {
        this.transports = transports;
    }

    async ngOnInit() {
        this.initRequestProductsStreams();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translates = event.translations['dutyCalculation'];
            if (this.taxRequest.receiver && this.taxRequest.receiver.activity_id) {
                this.receiverActivity = event.translations['duty_activities'].find(activity => activity.value === this.taxRequest.receiver.activity_id).label;
            }
        });
        await this.translate.get('dutyCalculation').toPromise().then(dc => {
            this.translates = dc;
        });
        if (this.taxRequest && this.taxRequest.receiver && this.taxRequest.receiver.activity_id) {
            await this.translate.get('duty_activities').subscribe((activities) => {
                this.receiverActivity = activities.find(activity => activity.value === this.taxRequest.receiver.activity_id).label;
            });
        }

        this.initDone = true;
    }

    initRequestProductsStreams() {
        this.requestProductsStreams = [];
        if (this.taxRequest && this.taxRequest.products) {
            this.taxRequest.products.forEach(product => {
                let iProducts = this.requestProductsStreams.findIndex(p => {
                    return p[0].to_country === product.to_country &&
                        p[0].to_district === product.to_district &&
                        p[0].from_country === product.from_country
                });
                if (iProducts === -1) {
                    iProducts = this.requestProductsStreams.length;
                    this.requestProductsStreams.push([]);
                }
                this.requestProductsStreams[iProducts].push(product);
            });
        }
    }

    getStreamProducts(streamIndex: number): ResponseProduct[] {
        if (this.taxResponse.length > streamIndex) {
            return this.taxResponse[streamIndex].products;
        } else {
            return [];
        }
    }

    /*get products(): ResponseProduct[] {
        if (this.taxResponse) {
            return this.taxResponse.products;
        } else {
            return [];
        }
    }*/

    private getPercentageTotalVat(vat: Tax[] | TaxGlobal[], vatIndex: number): number {
        let currentVatPercentage = 0;
        let otherVatspercentage = 0;
        for (let i = 0; i < vat.length; i++) {
            if (i === vatIndex) {
                currentVatPercentage = vat[i].percentage;
            } else {
                otherVatspercentage += vat[i].percentage;
            }
        }
        return currentVatPercentage / (currentVatPercentage + otherVatspercentage);
    }

    getProductOtherVATs(product: ResponseProduct, vatIndex: number, otherVatType: OTHER_VAT_TYPE = OTHER_VAT_TYPE.ALL): number {
        let sumVATs = 0;
        const percentageTotalVat = this.getPercentageTotalVat(product.vat && product.vat.length > 0 ? product.vat : product.salestax, vatIndex);
        
        if (product.duty) {
            switch (otherVatType) {
                case OTHER_VAT_TYPE.ALL:
                    sumVATs += product.duty.vat_product_taxes_amount ? product.duty.vat_product_taxes_amount * percentageTotalVat : 0;
                    sumVATs += product.duty.vat_shipping_taxes_amount ? product.duty.vat_shipping_taxes_amount * percentageTotalVat : 0;
                    sumVATs += product.duty.vat_packaging_taxes_amount ? product.duty.vat_packaging_taxes_amount * percentageTotalVat : 0;
                    sumVATs += product.duty.vat_insurance_taxes_amount ? product.duty.vat_insurance_taxes_amount * percentageTotalVat : 0;
                    break;
                case OTHER_VAT_TYPE.PRODUCT:
                    sumVATs += product.duty.vat_product_taxes_amount ? product.duty.vat_product_taxes_amount * percentageTotalVat : 0;
                    break;
                case OTHER_VAT_TYPE.SHIPPING:
                    sumVATs += product.duty.vat_shipping_taxes_amount ? product.duty.vat_shipping_taxes_amount * percentageTotalVat : 0;
                    break;
                case OTHER_VAT_TYPE.PACKAGING:
                    sumVATs += product.duty.vat_packaging_taxes_amount ? product.duty.vat_packaging_taxes_amount * percentageTotalVat : 0;
                    break;
                case OTHER_VAT_TYPE.INSURANCE:
                    sumVATs += product.duty.vat_insurance_taxes_amount ? product.duty.vat_insurance_taxes_amount * percentageTotalVat : 0;
                    break;
                default:
                    break;
            }
        }
        if (product.special_taxes) {
            product.special_taxes.forEach(specialTax => {
                if (specialTax.vat_taxes_amount) {
                    sumVATs += specialTax.vat_taxes_amount ? specialTax.vat_taxes_amount * percentageTotalVat : 0;
                }
            });
        }
        return sumVATs;
    }

    getGlobalShippingOtherVATs(streamIndex: number, vatIndex: number): number {
        let sumVATs = 0;
        const percentageTotalVat = this.getPercentageTotalVat(this.taxResponse[streamIndex].shipping_global.vat && this.taxResponse[streamIndex].shipping_global.vat.length > 0 ? this.taxResponse[streamIndex].shipping_global.vat : this.taxResponse[streamIndex].shipping_global.salestax, vatIndex);

        if (this.taxResponse[streamIndex].shipping_global.duty && this.taxResponse[streamIndex].shipping_global.duty.vat_amount) {
            sumVATs += this.taxResponse[streamIndex].shipping_global.duty.vat_amount * percentageTotalVat;
        }
        if (this.taxResponse[streamIndex].shipping_global.special_taxes) {
            this.taxResponse[streamIndex].shipping_global.special_taxes.forEach(specialTax => {
                if (specialTax.vat_amount) {
                    sumVATs += specialTax.vat_amount * percentageTotalVat;
                }
            });
        }
        return sumVATs;
    }
    getGlobalPackagingOtherVATs(streamIndex: number, vatIndex: number): number {
        let sumVATs = 0;
        const percentageTotalVat = this.getPercentageTotalVat(this.taxResponse[streamIndex].packaging_global.vat && this.taxResponse[streamIndex].packaging_global.vat.length > 0 ? this.taxResponse[streamIndex].packaging_global.vat : this.taxResponse[streamIndex].packaging_global.salestax, vatIndex);

        if (this.taxResponse[streamIndex].packaging_global.duty && this.taxResponse[streamIndex].packaging_global.duty.vat_amount) {
            sumVATs += this.taxResponse[streamIndex].packaging_global.duty.vat_amount * percentageTotalVat;
        }
        if (this.taxResponse[streamIndex].packaging_global.special_taxes) {
            this.taxResponse[streamIndex].packaging_global.special_taxes.forEach(specialTax => {
                if (specialTax.vat_amount) {
                    sumVATs += specialTax.vat_amount * percentageTotalVat;
                }
            });
        }
        return sumVATs;
    }
    getGlobalInsuranceOtherVATs(streamIndex: number, vatIndex: number): number {
        let sumVATs = 0;
        const percentageTotalVat = this.getPercentageTotalVat(this.taxResponse[streamIndex].insurance_global.vat && this.taxResponse[streamIndex].insurance_global.vat.length > 0 ? this.taxResponse[streamIndex].insurance_global.vat : this.taxResponse[streamIndex].insurance_global.salestax, vatIndex);

        if (this.taxResponse[streamIndex].insurance_global.duty && this.taxResponse[streamIndex].insurance_global.duty.vat_amount) {
            sumVATs += this.taxResponse[streamIndex].insurance_global.duty.vat_amount * percentageTotalVat;
        }
        if (this.taxResponse[streamIndex].insurance_global.special_taxes) {
            this.taxResponse[streamIndex].insurance_global.special_taxes.forEach(specialTax => {
                if (specialTax.vat_amount) {
                    sumVATs += specialTax.vat_amount * percentageTotalVat;
                }
            });
        }
        return sumVATs;
    }

    // tax & duty are Tax | TaxGlobal
    getVatLabel(vat: any, duty: any): string {
        // vat applied only on Duties
        if (
            duty &&
            ! isNaN(duty.percentage) &&
            duty.percentage > 0 &&
            (
                this.alwaysANumber(duty.vat_product_taxes_amount)
                + this.alwaysANumber(duty.vat_shipping_taxes_amount)
                + this.alwaysANumber(duty.vat_packaging_taxes_amount)
                + this.alwaysANumber(duty.vat_insurance_taxes_amount)
                + this.alwaysANumber(duty.vat_amount)
            ) > 0 &&
            (
                this.alwaysANumber(vat.product_taxes_amount)
                + this.alwaysANumber(vat.shipping_taxes_amount)
                + this.alwaysANumber(vat.packaging_taxes_amount)
                + this.alwaysANumber(vat.insurance_taxes_amount)
                + this.alwaysANumber(vat.amount)
            ) === 0
        ) {
            return `${vat.label ? vat.label : 'VAT'} (${this.translate.instant('dutyCalculation.steps.result.vatAppliedOnDutiesOnly')})`;
        }
        return vat.label ? vat.label : 'VAT';
    }
    getVatPercentage(vat: Tax, duty: Tax): number {
        // vat applied only on Duties, get right percentage
        if ((isNaN(vat.percentage) || vat.percentage === 0) && duty && ! isNaN(duty.percentage) && duty.percentage > 0) {
            const totalDuties = this.alwaysANumber(duty.product_taxes_amount) + this.alwaysANumber(duty.shipping_taxes_amount) + this.alwaysANumber(duty.packaging_taxes_amount) + this.alwaysANumber(duty.insurance_taxes_amount);
            const totalVatDuties = this.alwaysANumber(duty.vat_product_taxes_amount) + this.alwaysANumber(duty.vat_shipping_taxes_amount) + this.alwaysANumber(duty.vat_packaging_taxes_amount) + this.alwaysANumber(duty.vat_insurance_taxes_amount);
            return totalVatDuties * 100 / totalDuties;
        }
        return this.alwaysANumber(vat.percentage);
    }

    isGlobal() {
        if (this.taxRequest) {
            if (this.taxRequest.shipment_type === 'GLOBAL') {
                return true;
            }
        }
        return false;
    }

    alwaysANumber(value): number {
        if (value) {
            return value;
        } else {
            return 0.00;
        }
    }

    alwaysANumberTwoDecimals(value: number): number {
        if (value) {
            try {
                // return parseFloat((parseInt((value * 100).toFixed(0), 10) / 100).toFixed(2));
                const strValue = value.toString().split('.');
                // there are decimals, we must set 2 decimals max
                if (strValue.length === 2) {
                    // there are more than 2 decimals
                    if (strValue[1].length > 2) {
                        strValue[1] = strValue[1].substring(0, 2);
                    }
                    const decimals = parseInt(strValue[1], 10);
                    if (decimals === 0) {
                        return parseInt(strValue[0], 10);
                    }

                    // last decimal is a 0
                    if (decimals % 10 === 0) {
                        return parseFloat(strValue[0] + '.' + strValue[1].substring(0, 1));
                    } else {
                        return parseFloat(strValue[0] + '.' + strValue[1]);
                    }
                } else {
                    return value;
                }
            } catch (error) {
                console.log(error);
                // not a right number, it shall never happens
                return value;
            }
        } else {
            return 0.00;
        }
    }

    specialTaxes(streamIndex: number): TaxGlobal[]  {
        if (this.taxResponse[streamIndex].shipping_global.special_taxes) {
            return this.taxResponse[streamIndex].shipping_global.special_taxes;
        } else {
            return [];
        }
    }

    vatFor(product: ResponseProduct): Tax[] {
        if (product.vat) {
            return product.vat;
        } else {
            return [];
        }
    }

    salestaxFor(product: ResponseProduct): Tax[] {
        if (product.salestax) {
            return product.salestax;
        } else {
            return [];
        }
    }

    specialTaxesFor(product: ResponseProduct): Tax[] {
        if (product.special_taxes) {
            return product.special_taxes;
        } else {
            return [];
        }
    }

    messages(streamIndex: number): Array<string> {
        const messages = [];
        if (this.taxResponse[streamIndex].shipping_global.duty.message) {
            messages.push(this.taxResponse[streamIndex].shipping_global.duty.message);
        }
        if (this.taxResponse[streamIndex].shipping_global.vat.length > 0) {
            this.taxResponse[streamIndex].shipping_global.vat.forEach(element => {
                messages.push( element.message );
            });
        }
        if (this.taxResponse[streamIndex].shipping_global.special_taxes.length > 0) {
            this.taxResponse[streamIndex].shipping_global.special_taxes.forEach(element => {
                messages.push( element.message );
            });
        }
        return messages;
    }

    exportCsv() {
        this.buildExportCsv();
        // const dutyResponse = TaxResponse.buildDutyCsvResponse(this.taxRequest, this.taxResponse);
        /*const dutyResponse = this.csvService.convertToCSV(this.taxResponse);
        const dutyFileName = `dutyCalculation_${(new Date()).getTime()}`;
        return this.csvService.downloadFile(dutyResponse, dutyFileName);*/
    }


    newSearch() {
        this.new.emit();
    }

    sum(value: number, value2: number, value3 = 0, value4 = 0, value5 = 0): number {
        if (value === undefined || value === null) {
            value = 0;
        }
        if (value2 === undefined || value2 === null) {
            value2 = 0;
        }
        if (value3 === undefined || value3 === null) {
            value3 = 0;
        }
        if (value4 === undefined || value4 === null) {
            value4 = 0;
        }
        if (value5 === undefined || value5 === null) {
            value5 = 0;
        }
        return value + value2 + value3 + value4 + value5;
    }

    // here we build the whole request description as a reminder
    getWholeRequestDescription(): string {
        if (!this.translates || !this.translates.steps || ! this.taxRequest) {
            return '';
        }
        const reqDesc: string[] = [];

        // ESHOP
        if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP) {
            // Append origin / destination
            let country = this.countries.find(c => c.value === this.taxRequest.from_country);
            reqDesc.push(this.translates.steps.origin.fromCountry + ': ' + (country ? country.label : this.taxRequest.from_country));
            country = this.countries.find(c => c.value === this.taxRequest.to_country);
            reqDesc.push(this.translates.steps.origin.toCountry + ': ' + (country ? country.label : this.taxRequest.to_country));
            const toDistrict = this.taxRequest.to_district ?districts.find(district => district.value === this.taxRequest.to_district).label : null;
            if (toDistrict) {
                reqDesc.push(this.translates.steps.origin.toDistrict + ': ' + toDistrict);
            }
            if (this.taxRequest.extra_fees) {
                reqDesc.push(this.translates.steps.result.extraFees + ': ' + this.taxRequest.extra_fees);
            }
            if (this.taxRequest.included_tax !== undefined) {
                reqDesc.push(this.translates.steps.product.included_tax + ': ' + this.taxRequest.included_tax);
            }
            if (this.taxRequest.incoterm !== undefined) {
                reqDesc.push(this.translates.steps.product.incoterm + ': ' + this.taxRequest.incoterm);
            }

            // Append now the items
            this.taxRequest.products.forEach((product) => {
                reqDesc.push(this.translates.steps.product.product + ': ' + product.identification.value);
                reqDesc.push(this.translates.steps.product.quantity + ': ' + product.quantity);
                if (this.taxRequest.to_country !== 'CHE') {
                    reqDesc.push(this.translates.steps.product.unit_price + ': ' + product.unit_price + ' ' + product.currency_unit_price);
                }
                if (this.taxRequest.products.length > 1 || this.taxRequest.to_country === 'CHE') {
                    reqDesc.push(this.translates.steps.options.weight + ': ' + product.weight + product.weight_unit);
                }
                if (product.origin_country) {

                    country = this.countries.find(c => c.value === product.origin_country);
                    reqDesc.push(this.translates.steps.product.originCountry + ': ' + (country ? country.label : product.origin_country));
                }
                if (product.unit_type) {
                    reqDesc.push(this.translates.steps.options.unit_type + ': ' + product.unit + product.unit_type);
                }
                if (product.unit_ship_price) {
                    reqDesc.push(
                        this.translates.steps.transport.shipPrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                        product.unit_ship_price + ' ' +
                        product.currency_unit_ship_price
                    );
                }
                if (product.unit_packaging_price) {
                    reqDesc.push(
                        this.translates.steps.transport.packagingPrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                        product.unit_packaging_price + ' ' +
                        product.currency_unit_packaging_price
                    );
                }
                if (product.unit_insurance_price) {
                    reqDesc.push(
                        this.translates.steps.transport.insurancePrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                        product.unit_insurance_price + ' ' +
                        product.currency_unit_insurance_price
                    );
                }
                if (product.group_shipping_price) {
                    reqDesc.push(
                        this.translates.steps.transport.shipPrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                        product.group_shipping_price + ' ' +
                        product.currency_unit_ship_price
                    );
                }
                if (product.group_packaging_price) {
                    reqDesc.push(
                        this.translates.steps.transport.packagingPrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                        product.group_packaging_price + ' ' +
                        product.currency_unit_packaging_price
                    );
                }
                if (product.group_insurance_price) {
                    reqDesc.push(
                        this.translates.steps.transport.insurancePrice + ' ' +
                        this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                        product.group_insurance_price + ' ' +
                        product.currency_unit_insurance_price
                    );
                }
                if (product.transport) {
                    reqDesc.push(this.translates.steps.transport.transporter + ': ' + this.getTransporterFromId(product.transport));
                }
                if (product.transit_fees) {
                    reqDesc.push(this.translates.steps.transport.fees + ': ' +
                        (
                            product.transit_fees.amount ?
                            product.transit_fees.amount + ' ' + product.transit_fees.currency :
                            product.transit_fees.percentage + '%'
                        )
                        + ' ' +
                        (
                            product.transit_fees.on_global ?
                            this.translates.steps.transport.onGlobal :
                            (
                                product.transit_fees.on_products_price ?
                                this.translates.steps.transport.onProductsPrice :
                                this.translates.steps.transport.onShippingProductsPrice
                            )
                        )
                    );
                }
            });

            // Append global shipping
            if (this.taxRequest.global_ship_price) {
                reqDesc.push(this.translates.steps.transport.shipPrice + ': ' +
                    this.taxRequest.global_ship_price + ' ' +
                    this.taxRequest.currency_global_ship_price
                );
            }
            // Append global packaging
            if (this.taxRequest.global_packaging_price) {
                reqDesc.push(this.translates.steps.transport.packagingPrice + ': ' +
                    this.taxRequest.global_packaging_price + ' ' +
                    this.taxRequest.currency_global_packaging_price
                );
            }
            // Append global insurance
            if (this.taxRequest.global_insurance_price) {
                reqDesc.push(this.translates.steps.transport.insurancePrice + ': ' +
                    this.taxRequest.global_insurance_price + ' ' +
                    this.taxRequest.currency_global_insurance_price
                );
            }
            if (this.taxRequest.transport) {
                reqDesc.push(this.translates.steps.transport.transporter + ': ' + this.getTransporterFromId(this.taxRequest.transport));
            }
            if (this.taxRequest.transit_fees) {
                reqDesc.push(this.translates.steps.transport.fees + ': ' +
                    (
                        this.taxRequest.transit_fees.amount ?
                        this.taxRequest.transit_fees.amount + ' ' + this.taxRequest.transit_fees.currency :
                        this.taxRequest.transit_fees.percentage + '%'
                    )
                    + ' ' +
                    (
                        this.taxRequest.transit_fees.on_global ?
                        this.translates.steps.transport.onGlobal :
                        (
                            this.taxRequest.transit_fees.on_products_price ?
                            this.translates.steps.transport.onProductsPrice :
                            this.translates.steps.transport.onShippingProductsPrice
                        )
                    )
                );
            }

            // Append sender / receiver infos
            if (this.taxRequest.sender && this.taxRequest.sender.revenue_country_annual) {
                reqDesc.push(this.translates.steps.sender.revenueCountryAnnual + ': ' +
                    this.taxRequest.sender.revenue_country_annual + ' ' +
                    this.taxRequest.sender.currency_revenue_country_annual
                );
            }

        // ecommerce_type = MARKETPLACE
        } else {
            this.requestProductsStreams.forEach(stream => {
                // Append origin / destination
                let country = this.countries.find(c => c.value === stream[0].from_country);
                reqDesc.push(this.translates.steps.origin.fromCountry + ': ' + (country ? country.label : stream[0].from_country));
                country = this.countries.find(c => c.value === stream[0].to_country);
                reqDesc.push(this.translates.steps.origin.toCountry + ': ' + (country ? country.label : stream[0].to_country));
                const toDistrict = stream[0].to_district ? districts.find(district => district.value === stream[0].to_district).label : null;
                if (toDistrict) {
                    reqDesc.push(this.translates.steps.origin.toDistrict + ': ' + toDistrict);
                }
                if (this.taxRequest.extra_fees) {
                    reqDesc.push(this.translates.steps.result.extraFees + ': ' + this.taxRequest.extra_fees);
                }
                if (stream[0].included_tax !== undefined) {
                    reqDesc.push(this.translates.steps.product.included_tax + ': ' + stream[0].included_tax);
                }
                if (stream[0].incoterm !== undefined) {
                    reqDesc.push(this.translates.steps.product.incoterm + ': ' + stream[0].incoterm);
                }

                // Append now the items
                stream.forEach((product) => {
                    reqDesc.push(this.translates.steps.product.product + ': ' + product.identification.value);
                    reqDesc.push(this.translates.steps.product.quantity + ': ' + product.quantity);
                    if (this.taxRequest.to_country !== 'CHE') {
                        reqDesc.push(this.translates.steps.product.unit_price + ': ' + product.unit_price + ' ' + product.currency_unit_price);
                    }
                    if (this.taxRequest.products.length > 1 || this.taxRequest.to_country === 'CHE') {
                        reqDesc.push(this.translates.steps.options.weight + ': ' + product.weight + product.weight_unit);
                    }
                    if (product.origin_country) {

                        country = this.countries.find(c => c.value === product.origin_country);
                        reqDesc.push(this.translates.steps.product.originCountry + ': ' + (country ? country.label : product.origin_country));
                    }
                    if (product.unit_type) {
                        reqDesc.push(this.translates.steps.options.unit_type + ': ' + product.unit + product.unit_type);
                    }
                    if (product.unit_ship_price) {
                        reqDesc.push(
                            this.translates.steps.transport.shipPrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                            product.unit_ship_price + ' ' +
                            product.currency_unit_ship_price
                        );
                    }
                    if (product.unit_packaging_price) {
                        reqDesc.push(
                            this.translates.steps.transport.packagingPrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                            product.unit_packaging_price + ' ' +
                            product.currency_unit_packaging_price
                        );
                    }
                    if (product.unit_insurance_price) {
                        reqDesc.push(
                            this.translates.steps.transport.insurancePrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE).label + ': ' +
                            product.unit_insurance_price + ' ' +
                            product.currency_unit_insurance_price
                        );
                    }
                    if (product.group_shipping_price) {
                        reqDesc.push(
                            this.translates.steps.transport.shipPrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                            product.group_shipping_price + ' ' +
                            product.currency_unit_ship_price
                        );
                    }
                    if (product.group_packaging_price) {
                        reqDesc.push(
                            this.translates.steps.transport.packagingPrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                            product.group_packaging_price + ' ' +
                            product.currency_unit_packaging_price
                        );
                    }
                    if (product.group_insurance_price) {
                        reqDesc.push(
                            this.translates.steps.transport.insurancePrice + ' ' +
                            this.translates.steps.transport.shipmentType.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GROUP).label + ': ' +
                            product.group_insurance_price + ' ' +
                            product.currency_unit_insurance_price
                        );
                    }
                    if (product.transport) {
                        reqDesc.push(this.translates.steps.transport.transporter + ': ' + this.getTransporterFromId(product.transport));
                    }
                    if (product.transit_fees) {
                        reqDesc.push(this.translates.steps.transport.fees + ': ' +
                            (
                                product.transit_fees.amount ?
                                product.transit_fees.amount + ' ' + product.transit_fees.currency :
                                product.transit_fees.percentage + '%'
                            )
                            + ' ' +
                            (
                                product.transit_fees.on_global ?
                                this.translates.steps.transport.onGlobal :
                                (
                                    product.transit_fees.on_products_price ?
                                    this.translates.steps.transport.onProductsPrice :
                                    this.translates.steps.transport.onShippingProductsPrice
                                )
                            )
                        );
                    }

                    // Append sender / receiver infos
                    if (product.sender && product.sender.revenue_country_annual) {
                        reqDesc.push(
                            this.translates.steps.sender.revenueCountryAnnual + ': ' +
                            product.sender.revenue_country_annual + ' ' +
                            product.sender.currency_revenue_country_annual
                        );
                    }
                    reqDesc.push(' --- ');
                });
            });
        }

        if (this.receiverActivity) {
            reqDesc.push(this.translates.steps.sender.activityId + ': ' + this.receiverActivity);
        }

        // Return the request description
        return reqDesc.join(' / ');
    }

    buildExportCsv() {
        const flattenRequest = this.csvService.flattenObjectToBuildCsv('request_', this.taxRequest);
        const flattenResponse = this.csvService.flattenObjectToBuildCsv('response_', this.taxResponse);
        const wholeData = flattenRequest;
        const keys = Object.keys(flattenResponse);
        keys.forEach(key => {
            wholeData[key] = flattenResponse[key];
        });
        this.csvService.downloadFile(wholeData, 'dutyCalculation_' + (new Date()).getTime());
    }

    getTransporterFromId(transport): string {
        if (transport.type === ShipmentTypeEnum.FREIGHT_FORWARDER) {
            return this.transports.find(transp => transp.value === 'f_' + transport.id).label;
        } else if (transport.type === ShipmentTypeEnum.CARRIER) {
            return this.transports.find(transp => transp.value === 'c_' + transport.id).label;
        }
    }

    getCountry(index: number, fromToCountry: string): string {
        const value = this.requestProductsStreams[index][0][fromToCountry];
        const country = this.countries.find(c => c.value === value);
        return country ? country.label : value;
    }
}
