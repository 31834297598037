import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  url: string;
  constructor() {
    this.url = `https://www.app.${environment.baseUrl}`;
  }

  ngOnInit() {
  }

}
