import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export interface ConvertResult {
  result: number;
  currency: string;
}

export interface Translate {
  source: string;
  target: string;
  inputText: string;
  outputText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  translateUrl: string;
  convertUrl: string;

  constructor(private http: HttpClient) {
    this.translateUrl = `https://api.${environment.baseUrl}/v1/translate`;
    this.convertUrl = `https://api.${environment.baseUrl}/v1/data/currency`;
  }

  convert(params: HttpParams): Promise<ConvertResult> {
    const httpOptions = {
      params: params
    };
    return this.http
        .get<ConvertResult>(this.convertUrl, httpOptions)
        .toPromise();
  }

  translate(body: Translate): Promise<Translate> {
    return this.http
        .post<Translate>(this.translateUrl, body)
        .toPromise();
  }
}
