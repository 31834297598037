import { Injectable } from '@angular/core';
import {loadStripe} from '@stripe/stripe-js/pure';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  stripePromise;
  constructor() {
    this.stripePromise = loadStripe(environment.stripeKey);
  }
}
