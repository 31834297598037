import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from '../../../../models/User';
import { ManagersService } from '../../../services/managers.service';
import { OrderProduct } from '../export-documents.component';

@Component({
    selector: 'app-export-invoice',
    templateUrl: './export-invoice.component.html',
    styleUrls: ['./export-invoice.component.scss']
})
export class ExportInvoiceComponent implements OnInit, OnDestroy {
    @Input()
    countries: { label: string; iso2: string; value: string; disabled: boolean }[] = [];
    @Input()
    user: User;
    @Input()
    weightUnits: { label: string; value: string; disabled: boolean }[] = [];
    @Input()
    currencies: { label: string; value: string; disabled: boolean }[] = [];
    @Output()
    setData: EventEmitter<any> = new EventEmitter<any>();
    pdfForm: FormGroup;
    formIsInvalid = false;

    ordersProductsMix: OrderProduct[] = [];
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    receiverCountryObservable: Observable<string> = new Observable<string>();

    private requiredFields: string[] = [];
    private subscriptions: Subscription;

    constructor(
        private managersService: ManagersService,
        private formBuilder: FormBuilder,
        private domSanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        this.prepareOrderProducts();
        this.pdfForm = this.formBuilder.group({
            additional_fees_amount_currency: [null, [Validators.required]],
            additional_fees_amount_global: [null, [Validators.required, Validators.min(0)]],
            air_port_of_lading: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.air_port_of_lading : null],
            //buyer_zipcode: [null, [Validators.required]],
            //buyer_city: [null, [Validators.required]],
            //buyer_country: [null, [Validators.required]],
            //buyer_address: [null, [Validators.required]],
            //buyer_name: [null],
            company_id: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.account.company_id : null, [Validators.required]],
            cubic_meters: [null],
            customer_service_info: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.customer_service_info : null],
            Date: [new Date(), [Validators.required]],
            discount: [null],
            discount_currency: [null],
            exporting_carrier: [null],
            incoterm: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.incoterm : null],
            invoice_id: [null, [Validators.required]],
            marks_numbers: [null],
            named_point: [null],
            order_date: [new Date(), [Validators.required]],
            order_id: [null, [Validators.required]],
            order_payment_date: [new Date(), [Validators.required]],
            order_payment_type: [null, [Validators.required]],
            origin_country_product_1: [null, [Validators.required]],
            origin_country_product_2: [null],
            origin_country_product_3: [null],
            origin_country_product_4: [null],
            origin_country_product_5: [null],
            origin_country_product_6: [null],
            other_seller_informations: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.other_seller_informations : null],
            packages_quantity: [null],
            product_global_currency_1: [null, [Validators.required]],
            product_global_currency_2: [null],
            product_global_currency_3: [null],
            product_global_currency_4: [null],
            product_global_currency_5: [null],
            product_global_currency_6: [null],
            product_global_price_1: [null, [Validators.required, Validators.min(0)]],
            product_global_price_2: [null],
            product_global_price_3: [null],
            product_global_price_4: [null],
            product_global_price_5: [null],
            product_global_price_6: [null],
            product_hscode_1: [null, [Validators.required]],
            product_hscode_2: [null],
            product_hscode_3: [null],
            product_hscode_4: [null],
            product_hscode_5: [null],
            product_hscode_6: [null],
            product_quantity_1: [null, [Validators.required, Validators.min(0)]],
            product_quantity_2: [null],
            product_quantity_3: [null],
            product_quantity_4: [null],
            product_quantity_5: [null],
            product_quantity_6: [null],
            product_unit_currency_1: [null, [Validators.required]],
            product_unit_currency_2: [null],
            product_unit_currency_3: [null],
            product_unit_currency_4: [null],
            product_unit_currency_5: [null],
            product_unit_currency_6: [null],
            product_unit_price_1: [null, [Validators.required, Validators.min(0)]],
            product_unit_price_2: [null],
            product_unit_price_3: [null],
            product_unit_price_4: [null],
            product_unit_price_5: [null],
            product_unit_price_6: [null],
            product_value_1: [null, [Validators.required]],
            product_value_2: [null],
            product_value_3: [null],
            product_value_4: [null],
            product_value_5: [null],
            product_value_6: [null],
            products_amount_ex_taxes: [null, [Validators.required, Validators.min(0)]],
            products_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_amount_global_currency: [null],
            products_delivery_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_delivery_amount_global_currency: [null, [Validators.required]],
            products_duty_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_duty_amount_global_currency: [null, [Validators.required]],
            products_insurance_amount_currency: [null, [Validators.required]],
            products_insurance_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_packing_amount_currency: [null, [Validators.required]],
            products_packing_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_special_taxes_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_special_taxes_amount_global_currency: [null, [Validators.required]],
            products_vat_amount_global: [null, [Validators.required, Validators.min(0)]],
            products_vat_amount_global_currency: [null, [Validators.required]],
            receiver_address: [null, [Validators.required]],
            receiver_city: [null, [Validators.required]],
            receiver_country: [null],
            receiver_name: [null, [Validators.required]],
            receiver_zipcode: [null, [Validators.required]],
            mkt_address: [null, [Validators.required]],
            mkt_city: [null, [Validators.required]],
            mkt_country: [null],
            mkt_name: [null, [Validators.required]],
            mkt_zipcode: [null, [Validators.required]],
            refund_amount: [null, [Validators.required]],
            reverse_logistic_terms: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.reverse_logistic_terms : null],
            seller_zipcode: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.postal_code : null, [Validators.required]],
            seller_city: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.city : null, [Validators.required]],
            seller_country: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.country : null, [Validators.required]],
            seller_address: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.address : null, [Validators.required]],
            seller_address_1: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.state : null],
            seller_brand: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.brand_name : null, [Validators.required]],
            seller_eori: [null, [Validators.required]],
            seller_logo: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.seller_logo : null, [Validators.required]],
            seller_name: [this.user && this.user.ecommerceProperties ? `${this.user.ecommerceProperties.account.firstname} ${this.user.ecommerceProperties.account.lastname}` : null, [Validators.required]],
            signature_url: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.signature : null, [Validators.required]],
            terms_of_sale: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.term_of_sale : null],
            vat_id: [null, [Validators.required]],
            vat_rates: [null],
            weight_global_kg: [null],
            product_measure_1: [null, [Validators.required, Validators.min(0)]],
            product_measure_2: [null],
            product_measure_3: [null],
            product_measure_4: [null],
            product_measure_5: [null],
            product_measure_6: [null],
            weight_unit_product_1: [null, [Validators.required]],
            weight_unit_product_2: [null],
            weight_unit_product_3: [null],
            weight_unit_product_4: [null],
            weight_unit_product_5: [null],
            weight_unit_product_6: [null],
        });
        this.subscriptions = new Subscription();
        // those fields are used by multiple fields, we must update the value
        this.subscriptions.add(
            this.pdfForm.get('Date').valueChanges.subscribe(val => this.pdfForm.get('Date').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('seller_name').valueChanges.subscribe(val => this.pdfForm.get('seller_name').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_amount_global_currency').valueChanges.subscribe(val => this.pdfForm.get('products_amount_global_currency').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_delivery_amount_global').valueChanges.subscribe(val => this.pdfForm.get('products_delivery_amount_global').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_delivery_amount_global_currency').valueChanges.subscribe(val => this.pdfForm.get('products_delivery_amount_global_currency').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_amount_global').valueChanges.subscribe(val => this.pdfForm.get('products_amount_global').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_vat_amount_global').valueChanges.subscribe(val => this.pdfForm.get('products_vat_amount_global').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('discount').valueChanges.subscribe(val => this.pdfForm.get('discount').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('discount_currency').valueChanges.subscribe(val => this.pdfForm.get('discount_currency').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('products_vat_amount_global_currency').valueChanges.subscribe(val => this.pdfForm.get('products_vat_amount_global_currency').setValue(val, {emitEvent: false}))
        );

        this.receiverCountryObservable = this.pdfForm.get('receiver_country').valueChanges;

        this.requiredFields = [
            'additional_fees_amount_currency',
            'additional_fees_amount_global',
            //'buyer_zipcode',
            //'buyer_city',
            //'buyer_country',
            //'buyer_address',
            'company_id',
            //'customer_service_info',
            'Date',
            'invoice_id',
            'order_date',
            'order_id',
            'order_payment_date',
            'order_payment_type',
            'origin_country_product_1',
            //'other_seller_informations',
            'product_global_currency_1',
            'product_global_price_1',
            'product_hscode_1',
            'product_quantity_1',
            'product_unit_currency_1',
            'product_unit_price_1',
            'product_value_1',
            'products_amount_ex_taxes',
            'products_amount_global',
            'products_delivery_amount_global',
            'products_delivery_amount_global_currency',
            'products_duty_amount_global',
            'products_duty_amount_global_currency',
            'products_insurance_amount_currency',
            'products_insurance_amount_global',
            'products_packing_amount_currency',
            'products_packing_amount_global',
            'products_special_taxes_amount_global',
            'products_special_taxes_amount_global_currency',
            'products_vat_amount_global',
            'products_vat_amount_global_currency',
            'receiver_address',
            'receiver_city',
            'receiver_name',
            'receiver_zipcode',
            'mkt_address',
            'mkt_city',
            'mkt_name',
            'mkt_zipcode',
            'refund_amount',
            //'reverse_logistic_terms',
            'seller_zipcode',
            'seller_city',
            'seller_country',
            'seller_address',
            //'seller_address_1',
            'seller_brand',
            'seller_eori',
            'seller_logo',
            'seller_name',
            'signature_url',
            'vat_id',
            //'vat_rates',
            'product_measure_1',
            'weight_unit_product_1'
        ];
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    async prepareOrderProducts() {
        await Promise.all([
            this.managersService.prepareOrders(),
            this.managersService.prepareProducts()
        ]);
        this.ordersProductsMix = this.managersService.getOrders().map(o => {
            return {
                order_id: o.order_id,
                products: o.products.map(p => {
                    const foundProduct = this.managersService.getProducts().find(prod => prod.sku === p.sku);
                    return {
                        sku: p.sku,
                        value: foundProduct && foundProduct.value ? foundProduct.value : ''
                    };
                })
            };
        });
    }

    isRequired(field: string): boolean {
        return this.requiredFields.includes(field);
    }

    filterKey(event) {
        return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    prepareAutocompleteOrder(controlName: string, fieldName: string) {
        const control = this.pdfForm.get(controlName);
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsOrders(), fieldName)),
        );
    }
    prepareAutocompleteProduct(controlName: string) {
        const control = this.pdfForm.get(controlName);
        const ordersProductsStringified: string[] = [];
        this.ordersProductsMix.forEach(op => {
            op.products.forEach(p => {
                ordersProductsStringified.push(`${op.order_id}, ${p.sku}, ${p.value}`);
            });
        });
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filterWholeData(value, ordersProductsStringified)),
        );
    }
    // filter used for autocompletion
    private _filterWholeData(value: string, wholeValues: string[]): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            return optionSplitted[0].toLowerCase().includes(filterValue) ||
                optionSplitted[1].toLowerCase().includes(filterValue) ||
                optionSplitted[2].toLowerCase().includes(filterValue);
        });
    }
    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            let i = 0;
            if (filterField === 'invoice_id') {
                i = 1;
            }
            return optionSplitted[i].toLowerCase().includes(filterValue);
        });
    }
    onOrderClicked(option: string) {
        const orderId = option.split(', ')[0];
        const rightOrder = this.managersService.getOrders().find(c => c.order_id === orderId);
        if (rightOrder) {
            this.pdfForm.get('invoice_id').setValue(rightOrder.invoice_id);
            this.pdfForm.get('order_id').setValue(rightOrder.order_id);
            this.pdfForm.get('exporting_carrier').setValue(rightOrder.exporting_carrier);
            this.pdfForm.get('incoterm').setValue(rightOrder.incoterm);
            this.pdfForm.get('order_date').setValue(rightOrder.order_date_hour ? (new Date(rightOrder.order_date_hour)).toISOString().substring(0, 10) : '');
            this.pdfForm.get('order_payment_date').setValue(rightOrder.order_payment_date ? (new Date(rightOrder.order_payment_date)).toISOString().substring(0, 10) : '');
            this.pdfForm.get('order_payment_type').setValue(rightOrder.order_payment_type);
            this.pdfForm.get('products_amount_ex_taxes').setValue(rightOrder.amount_products);
            this.pdfForm.get('products_amount_global').setValue(rightOrder.amount_duty + rightOrder.amount_products + rightOrder.amount_shipping + rightOrder.amount_specialtaxes + rightOrder.amount_vat);
            this.pdfForm.get('products_amount_ex_taxes').setValue(rightOrder.amount_products);
            this.pdfForm.get('products_amount_global_currency').setValue(rightOrder.currency);
            this.pdfForm.get('products_delivery_amount_global').setValue(rightOrder.amount_shipping);
            this.pdfForm.get('products_delivery_amount_global_currency').setValue(rightOrder.currency);
            this.pdfForm.get('products_duty_amount_global').setValue(rightOrder.amount_duty);
            this.pdfForm.get('products_duty_amount_global_currency').setValue(rightOrder.currency);
            this.pdfForm.get('products_special_taxes_amount_global').setValue(rightOrder.amount_specialtaxes);
            this.pdfForm.get('products_special_taxes_amount_global_currency').setValue(rightOrder.currency);
            this.pdfForm.get('products_vat_amount_global').setValue(rightOrder.amount_vat);
            this.pdfForm.get('products_vat_amount_global_currency').setValue(rightOrder.currency);
            //this.pdfForm.get('buyer_zipcode').setValue(rightOrder.buyer_zipcode);
            //this.pdfForm.get('buyer_city').setValue(rightOrder.buyer_city);
            //this.pdfForm.get('buyer_country').setValue(rightOrder.buyer_country);
            //this.pdfForm.get('buyer_address').setValue(rightOrder.buyer_address);
            //this.pdfForm.get('buyer_name').setValue(`${rightOrder.buyer_firstname ? rightOrder.buyer_firstname : ''} ${rightOrder.buyer_lastname ? rightOrder.buyer_lastname : ''}`);
            this.pdfForm.get('receiver_zipcode').setValue(rightOrder.customer_zipcode);
            this.pdfForm.get('receiver_city').setValue(rightOrder.customer_city);
            this.pdfForm.get('receiver_country').setValue(rightOrder.customer_country);
            this.pdfForm.get('receiver_address').setValue(rightOrder.customer_address);
            this.pdfForm.get('receiver_name').setValue(`${rightOrder.customer_firstname ? rightOrder.customer_firstname : ''} ${rightOrder.customer_lastname ? rightOrder.customer_lastname : ''}`);
            this.pdfForm.get('mkt_zipcode').setValue(rightOrder.agent_zipcode);
            this.pdfForm.get('mkt_city').setValue(rightOrder.agent_city);
            this.pdfForm.get('mkt_country').setValue(rightOrder.agent_country);
            this.pdfForm.get('mkt_address').setValue(rightOrder.agent_address);
            this.pdfForm.get('mkt_name').setValue(`${rightOrder.agent_firstname ? rightOrder.agent_firstname : ''} ${rightOrder.agent_lastname ? rightOrder.agent_lastname : ''}`);
            for (let i = 0; i < rightOrder.products.length; i++) {
                if (i < 6) {
                    console.log(rightOrder.products[i]);
                    const customerProduct = this.managersService.getProducts().find(p => p.sku === rightOrder.products[i].sku);
                    if (customerProduct) {
                        this.pdfForm.get(`origin_country_product_${i + 1}`).setValue(customerProduct.from_country);
                        this.pdfForm.get(`product_value_${i + 1}`).setValue(customerProduct.value);
                        this.pdfForm.get(`product_hscode_${i + 1}`).setValue(customerProduct.hscodefinder_hs_code);
                        this.pdfForm.get(`product_measure_${i + 1}`).setValue(customerProduct.weight);
                        this.pdfForm.get(`weight_unit_product_${i + 1}`).setValue(customerProduct.weight_unit);
                    }
                    this.pdfForm.get(`product_global_currency_${i + 1}`).setValue(rightOrder.products[i].unit_price_currency);
                    this.pdfForm.get(`product_global_price_${i + 1}`).setValue(rightOrder.products[i].unit_price * rightOrder.products[i].quantity);
                    this.pdfForm.get(`product_quantity_${i + 1}`).setValue(rightOrder.products[i].quantity);
                    this.pdfForm.get(`product_unit_price_${i + 1}`).setValue(rightOrder.products[i].unit_price);
                    this.pdfForm.get(`product_unit_currency_${i + 1}`).setValue(rightOrder.products[i].unit_price_currency);
                }
            }
        }
    }
    onProductClicked(option: string, index: number) {
        // sku is the sort key
        const splittedOptions = option.split(', ');
        const orderId = splittedOptions[0];
        const sku = splittedOptions[1];
        const customerProduct = this.managersService.getProducts().find(p => p.sku === sku);
        const customerOrder = this.managersService.getOrders().find(o => o.order_id === orderId);
        if (customerProduct) {
            this.pdfForm.get(`origin_country_product_${index}`).setValue(customerProduct.from_country);
            this.pdfForm.get(`product_value_${index}`).setValue(customerProduct.value);
            this.pdfForm.get(`product_hscode_${index}`).setValue(customerProduct.hscodefinder_hs_code);
            this.pdfForm.get(`product_measure_${index}`).setValue(customerProduct.weight);
            this.pdfForm.get(`weight_unit_product_${index}`).setValue(customerProduct.weight_unit);
        }
        if (customerOrder) {
            const foundProduct = customerOrder.products.find(p => p.sku === sku);
            if (foundProduct) {
                this.pdfForm.get(`product_global_currency_${index}`).setValue(foundProduct.unit_price_currency);
                this.pdfForm.get(`product_global_price_${index}`).setValue(foundProduct.unit_price * foundProduct.quantity);
                this.pdfForm.get(`product_quantity_${index}`).setValue(foundProduct.quantity);
                this.pdfForm.get(`product_unit_price_${index}`).setValue(foundProduct.unit_price);
                this.pdfForm.get(`product_unit_currency_${index}`).setValue(foundProduct.unit_price_currency);
            }
        }
    }

    getPgSelectCustomStyle(width: number, marginLeft: number): SafeStyle {
        return this.domSanitizer.bypassSecurityTrustStyle(`width: ${width}px; margin-left: ${marginLeft}px;`);
    }

    async onValidPdfForm() {
        if (this.pdfForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.pdfForm.value;
        this.setData.emit(formValue);
    }
}
