import Amplify from 'aws-amplify';
import {environment} from './environments/environment';

export const awsconfig = Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: environment.userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata: { origin: 'web' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: `app.${environment.baseUrl}`,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `https://app.${environment.baseUrl}/login`,
            redirectSignOut: `https://app.${environment.baseUrl}/login`,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});
