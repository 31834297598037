
export class Shipper {
    country: string; // iso2 country
    postal_code: string;
    city: string;

    customer_reference?: string;
    street?: string;
    state?: string;
    company?: string;
    name?: string;
}

export class Recipient extends Shipper {
    is_a_company: boolean | string; // true or "true"
}

export class Parcel {
    weight: number;
    length: number;
    width: number;
    height: number;

    id?: string;
    value?: number;
    currency?: string;
    description?: string;
    insured_value?: number;
    insured_currency?: string;
    type?: string;
}

export class Quote {
    shipper: Shipper;
    recipient: Recipient;
    parcels: Parcel[];
}

// REQUEST
export class AiShippingPricingRequest {
    quote: Quote;

    constructor() {
        this.quote = {
            shipper: null,
            recipient: null,
            parcels: []
        } as Quote;
    }
}

// RESPONSE
export class AiShippingPricingResult {
    carrier_code: string;
    code: string;
    name: string;
    delay: string;
    amount: number;
    currency: string;
    timestamp: number;
}
