import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AllCustomerCategory, CategoryTariffRates, CustomerCategory, FormatCsv, PRODUCTS_SELECTED_ROWS_ACTIONS } from '../../../models/Managers';
import { Builder } from 'builder-pattern';
import { CsvService } from '../../services/csv.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ManagersService } from '../../services/managers.service';
import { plainToClass } from 'class-transformer';
import { ApiDataService } from '../../services/api-data.service';
import { CsvFieldToSet } from '../../components/csv-mapper/csv-mapper.component';
@Component({
    selector: 'app-categories-manager',
    templateUrl: './categories-manager.component.html',
    styleUrls: ['./categories-manager.component.scss'],
})
export class CategoriesManagerComponent implements OnInit {
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    @ViewChild('confirmDeleteRow', { static: false }) confirmDeleteRow: TemplateRef<any>;
    @ViewChild('confirmDeleteRows', { static: false }) confirmDeleteRows: TemplateRef<any>;
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;
    @ViewChild('editmultiplerows', { static: false }) editmultiplerows: TemplateRef<any>;

    // to request the API in order to get the next data
    nextIndex: any;

    rowForm: FormGroup;
    formIsInvalid = false;
    isEditing = false;
    editingCategoryId: string;
    multipleRowsColumnsToEdit: string[] = [];
    multipleRowsWholeEditableColumns: string[] = [];
    addingColumns = false;

    closingModal = true;

    // display the pg-upload component
    displayUploadCsvComponent = false;

    // table rows
    rows: any[];
    displayedRows: any[];
    // expanded rows contents (display tariff rates)
    // as {cat_id: {FR: {...}, ES: {...}, ...}, other_cat_id: {...}}
    tariffRatesDetails: { [key: string]: { [key: string]: CategoryTariffRates } };
    formattedTariffRatesDetailsForTable: { [key: string]: { [key: string]: any }[] } = {};
    tariffRatesDetailsColumns: string[] = [];
    tariffRatesDetailsColumnsLabels: { [key: string]: string } = {};

    selectedRows: any[] = [];
    columnsCategory: string[] = [];
    undisplayedColumns: string[] = [];
    columnsToDisplay: string[] = [];
    booleanChoices: {value: string, label: string}[];

    // category to delete
    categoryToDeleteId: string = null;

    // expand row
    expandedRowId: string = null;
    rowDetailHeight = 0;

    countries: Array<{ value: string, label: string, iso2: string }> = [];
    currencies: Array<{value: string, label: string, disabled?: boolean}>;

    modalRef: BsModalRef;

    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    messages: any;
    columnsLabels: any;

    filterForm: FormGroup;
    filteredCategoriesOptions: Observable<string[]> = new Observable<string[]>();
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    private isRetrievingData = false;

    // custom table necessary vars
    displayRowOnly = false;
    rowsToDelete: string[] = [];
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];
    requestSelectedRowsSubject: Subject<void> = new Subject<void>();
    selectedRowsNextAction: PRODUCTS_SELECTED_ROWS_ACTIONS;

    expectedCsvContent: FormatCsv;
    displayCsvMapper: Subject<void> = new Subject<void>();
    csvModelFields: CsvFieldToSet[] = [];
    externalCsvFields: string[] = [];

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private csvService: CsvService,
        private managersService: ManagersService,
        private formBuilder: FormBuilder,
        private apiDataService: ApiDataService
    ) {
        this.rowForm = null;
        this.rows = [];
        this.displayedRows = [];
        this.tariffRatesDetails = {};
        this.filterForm = this.formBuilder.group({
            filter: ['']
        });
        this.multipleRowsWholeEditableColumns = [
            'products_id',
            'tariff_rates',
            'average_weight_kg',
            'ecoTax_code_pro',
            'ecoTax_subCode_pro',
            'ecoTax_code_personal',
            'ecoTax_price_pro',
            'ecoTax_price_personal',
            'ecoTax_price_default_pro',
            'ecoTax_price_default_personal',
            'ecoTax_price_currency',
            'ecoTax_default',
            'co2_product_kg',
            'un_code'
        ];
    }

    async ngOnInit() {
        this.initTranslation();
        await this.retrieveCategories();
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        this.managersService.prepareProducts();
    }

    // show / hide pg-upload component
    switchDisplayUploadCsvComponent() {
        this.displayUploadCsvComponent = !this.displayUploadCsvComponent;
    }

    // is the table empty?
    emptyRows(): boolean {
        if (this.rows === null || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    async retrieveCategories() {
        this.isRetrievingData = true;
        const categories: AllCustomerCategory = await this.getCategories();
        if (categories !== null) {
            this.nextIndex = categories.nextIndex;
            this.managersService.setCategories(this.rows.length === 0 ? categories.data : this.managersService.getCategories().concat(categories.data));
            categories.data.forEach((category) => {
                const customerCategory = plainToClass(CustomerCategory, category);
                this.rows.push(customerCategory.toRow());
                this.tariffRatesDetails[customerCategory.category_id] = customerCategory.tariff_rates;
            });
            this.rows = [...this.rows];
        }
        this.isRetrievingData = false;
        this.setDisplayedRows();
    }

    initTranslation() {
        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.categoriesManager;
            this.booleanChoices = event.translations.profile.boolean_choice;
            this.currencies = event.translations['currency_name'];
            this.initFormatColumnsValues();

            this.columnsLabels = event.translations.categoriesManager ? event.translations.categoriesManager.inputForm : undefined;
            this.tariffRatesDetailsColumnsLabels = event.translations.categoriesManager ? event.translations.categoriesManager.inputForm.tariff_rates : undefined;
            delete this.tariffRatesDetailsColumnsLabels.hscode;
            delete this.tariffRatesDetailsColumnsLabels.hscode_designation;
            delete this.tariffRatesDetailsColumnsLabels.hscode_version;
            delete this.tariffRatesDetailsColumnsLabels.status;
            delete this.tariffRatesDetailsColumnsLabels.update_timestamp;
            this.setColumnsCategory();
            this.setCountries();
        });

        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.categoriesManager;
        });

        this.translate.get('profile.boolean_choice').toPromise().then((categories) => {
            this.booleanChoices = categories;
        });
        this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies;
            this.initFormatColumnsValues();
        });

        // get columns labels
        this.translate.get('categoriesManager').toPromise().then(categoriesManager => {
            this.columnsLabels = categoriesManager.inputForm;
            this.tariffRatesDetailsColumnsLabels = categoriesManager.inputForm.tariff_rates;
            // remove useless columns
            delete this.tariffRatesDetailsColumnsLabels.hscode;
            delete this.tariffRatesDetailsColumnsLabels.hscode_designation;
            delete this.tariffRatesDetailsColumnsLabels.hscode_version;
            delete this.tariffRatesDetailsColumnsLabels.status;
            delete this.tariffRatesDetailsColumnsLabels.update_timestamp;
            this.tariffRatesDetailsColumns = Object.keys(this.tariffRatesDetailsColumnsLabels);
            this.setColumnsCategory();
        });
    }

    setColumnsToDisplay() {
        const colsToDisplay = localStorage.getItem('categories_columnsToDisplay');
        if (colsToDisplay) {
            this.columnsToDisplay = JSON.parse(colsToDisplay);
            this.undisplayedColumns = this.columnsCategory.filter(c => ! this.columnsToDisplay.includes(c));
        } else {
            this.columnsToDisplay = [...this.columnsCategory];
        }
    }

    setColumnsCategory() {
        this.columnsCategory = Object.keys(this.columnsLabels);
        let index = this.columnsCategory.findIndex(c => c === 'tariff_rates');
        if (index >= 0) {
            this.columnsCategory.splice(index, 1);
        }
        index = this.columnsCategory.findIndex(c => c === 'arrays');
        if (index >= 0) {
            this.columnsCategory.splice(index, 1);
        }
        this.setColumnsToDisplay();
    }
    
    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return '';
        }
    }

    csvFileChanged(event) {
        if (event.file.status === 'removed') {
            this.expectedCsvContent = null;
        }
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            this.expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = this.expectedCsvContent.toExpectedCsvContent();
            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
                const headerLine = csvRecordsArray.shift();
                const headerLineLength = headerLine.split(';').length;

                // check for wrong lines
                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    // not same columns number
                    if (headerLineLength !== line.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
                        return true;
                    }
                    return false;
                });
                if (wrongLines.length > 0) {
                    this.removeFile(file);
                    return;
                }

                const firstLine: string[] = csvRecordsArray[0].split(';');
                const arrayOfMandatoryFields = [
                    'category_id',
                    'category_tree'
                ];
                for (let i = 0; i < arrayOfMandatoryFields.length; i++) {
                    if (!firstLine.includes(arrayOfMandatoryFields[i])) {
                        this.externalCsvFields = this.expectedCsvContent.getHeaders();
                        this.csvModelFields = this.buildCsvModelFields();
                        this.onDisplayCsvMapper();
                        /*this._notification.displayMessage(`Line 1 : ${arrayOfMandatoryFields[i]} ${this.messages.isMandatory}`, 'danger');*/
                        return true;
                    }
                }

            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }
    buildCsvModelFields(): CsvFieldToSet[] {
        const modelFields: CsvFieldToSet[] = [];
        const expectedCsvFields = {
            category_id: {
                isRequired: true
            },
            category_tree: {
                isRequired: true
            },
            products_id: {
                isRequired: false
            },
            average_weight_kg: {
                isRequired: false
            },
            ecoTax_price_default_pro: {
                isRequired: false
            },
            ecoTax_price_default_personal: {
                isRequired: false
            },
            ecoTax_code_pro: {
                isRequired: false
            },
            ecoTax_subCode_pro: {
                isRequired: false
            },
            ecoTax_code_personal: {
                isRequired: false
            },
            ecoTax_price_pro: {
                isRequired: false
            },
            ecoTax_price_personal: {
                isRequired: false
            },
            ecoTax_price_currency: {
                isRequired: false
            },
            co2_product_kg: {
                isRequired: false
            },
            un_code: {
                isRequired: false
            },
        };
        const keys = Object.keys(expectedCsvFields);

        keys.forEach(f => {
            modelFields.push(
                Builder(CsvFieldToSet)
                .id(f)
                .externalField('')
                .isRequired(expectedCsvFields[f].isRequired)
                .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                .build()
            );
        });
        return modelFields;
    }
    onDisplayCsvMapper() {
        this.displayCsvMapper.next();
    }
    onCsvExternalMapped(mappedFields: CsvFieldToSet[]) {
        if (! mappedFields) {
            this.expectedCsvContent = null;
        } else {
            this.expectedCsvContent.modifyHeadersWithMappedFields(mappedFields);
        }
    }

    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.expectedCsvContent = null;
        return false;
    }

    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.expectedCsvContent = null;
    }

    async validateCSVImport() {
        const rows = this.expectedCsvContent.getContent();
        // hide the pg-upload component now
        this.switchDisplayUploadCsvComponent();
        const csvArrayLength = rows.length;
        const labels: string[] = rows.shift().split(';');
        let rowIsValid: boolean;
        let line = 2;
        rows.forEach(async record => {
            // parse each column and check if the values are valid
            const cols: string[] = record.split(';');
            let i = 0;
            const row = {};
            rowIsValid = true;
            labels.forEach((label) => {
                if (rowIsValid) {
                    switch (label) {
                        case 'category_id':
                            if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : category_id ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'category_tree':
                            if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : category_tree ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'products_id':
                            // nothing to check as it is not mandatory
                            break;
                        case 'average_weight_kg':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(3);
                            }
                            break;
                        case 'ecoTax_price_default_pro':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'ecoTax_price_default_personal':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'ecoTax_code_pro':
                            // nothing to do as it is not a mandatory field
                            break;
                        case 'ecoTax_subCode_pro':
                            // nothing to do as it is not a mandatory field
                            break;
                        case 'ecoTax_code_personal':
                            // nothing to do as it is not a mandatory field
                            break;
                        case 'ecoTax_price_pro':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'ecoTax_price_personal':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'ecoTax_price_currency':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                // the currency is wrong
                                this._notification.displayMessage(
                                    `Line ${line} : currency ${this.messages.mustBeISO4217}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            }
                            break;
                        case 'co2_product_kg':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(3);
                            }
                            break;
                        case 'un_code':
                            // nothing to check as it is not mandatory
                            break;
                        default:
                            break;
                    }
                }
                // append label and value in row ONLY IF value is not null
                if (cols[i] !== null) {
                    row[label] = cols[i];
                }
                i += 1;
            });
            if (rowIsValid) {
                // valid the row
                await this.onValidRow(row);
            }
            if (line === csvArrayLength) {
                this.expectedCsvContent = null;
            }
            line += 1;
        });
    }

    openModal(template: TemplateRef<any>, type: string, id: string) {
        if (this.rows) {
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            if (type === 'addrow') {
                this.initRowForm(null);
            } else if (type === 'editmultiplerows') {
                this.initRowForm(null, true);
            } else {
                const foundRow = this.rows.find(r => r.category_id === id);
                if (foundRow) {
                    if (type === 'editrow' || type === 'displayrow') {
                        this.initRowForm(foundRow);
                    } else {
                        this.categoryToDeleteId = id;
                    }
                }
            }
            this.modalRef = this.modalService.show(template, config);
        }
    }

    closeModal() {
        this.formIsInvalid = false;
        this.addingColumns = false;
        this.closingModal = true;
        this.isEditing = false;
        this.displayRowOnly = false;
        this.editingCategoryId = null;
        this.modalRef.hide();
    }

    getRowsToEditLabels(): string {
        return this.selectedRows.map(r => {
            return this.managersService.getOptionsCategories().find(categoryOption => categoryOption.startsWith(r.category_id));
        }).join(' | ');
    }

    initRowForm(row: any, editMultipleRows = false) {
        // edit row
        if (row) {
            this.isEditing = true;
            this.editingCategoryId = row.category_id;
            this.rowForm = this.formBuilder.group({
                category_id: [row.category_id, Validators.required],
                category_tree: this.formBuilder.array([]),
                products_id: this.formBuilder.array([]),
                tariff_rates: this.formBuilder.array([]),
                average_weight_kg: [row.average_weight_kg],
                ecoTax_price_default_pro: [row.ecoTax_price_default_pro, Validators.min(0)],
                ecoTax_price_default_personal: [row.ecoTax_price_default_personal, Validators.min(0)],
                ecoTax_code_pro: [row.ecoTax_code_pro],
                ecoTax_subCode_pro: [row.ecoTax_subCode_pro],
                ecoTax_code_personal: [row.ecoTax_code_personal],
                ecoTax_price_pro: [row.ecoTax_price_pro, Validators.min(0)],
                ecoTax_price_personal: [row.ecoTax_price_personal, Validators.min(0)],
                ecoTax_price_currency: [row.ecoTax_price_currency],
                ecoTax_default: [row.ecoTax_default],
                co2_product_kg: [row.co2_product_kg, Validators.min(0)],
                un_codes: this.formBuilder.array([])
            });
            const coutryIsos = Object.keys(this.tariffRatesDetails[row.category_id]);
            if (coutryIsos) {
                coutryIsos.forEach(countryIso => {
                    this.onAddCategoryTariffRates(<CategoryTariffRates> this.tariffRatesDetails[row.category_id][countryIso], countryIso);
                });
            }
            if (row.category_tree) {
                row.category_tree.forEach(tree => {
                    this.onAddCategoryTree(tree);
                });
            }
            if (row.products_id) {
                row.products_id.forEach(id => {
                    this.onAddCategoryProductId(id);
                });
            }
            if (row.un_code) {
                row.un_code.forEach(code => {
                    this.onAddCategoryUnCode(code);
                });
            }

        // new row
        } else if (editMultipleRows) {
            this.rowForm = this.formBuilder.group({
                products_id: this.formBuilder.array([]),
                tariff_rates: this.formBuilder.array([]),
                average_weight_kg: [0, Validators.min(0)],
                ecoTax_price_default_pro: [0, Validators.min(0)],
                ecoTax_price_default_personal: [0, Validators.min(0)],
                ecoTax_code_pro: [''],
                ecoTax_subCode_pro: [''],
                ecoTax_code_personal: [''],
                ecoTax_price_pro: [0, Validators.min(0)],
                ecoTax_price_personal: [0, Validators.min(0)],
                ecoTax_price_currency: ['EUR'],
                ecoTax_default: [null],
                co2_product_kg: [null, Validators.min(0)],
                un_codes: this.formBuilder.array([]),
                new_column: [null]
            });
            this.multipleRowsColumnsToEdit = [];
        } else {
            this.rowForm = this.formBuilder.group({
                category_id: ['', Validators.required],
                category_tree: this.formBuilder.array([]),
                products_id: this.formBuilder.array([]),
                tariff_rates: this.formBuilder.array([]),
                average_weight_kg: [null, Validators.min(0)],
                ecoTax_price_default_pro: [null, Validators.min(0)],
                ecoTax_price_default_personal: [null, Validators.min(0)],
                ecoTax_code_pro: [''],
                ecoTax_subCode_pro: [''],
                ecoTax_code_personal: [''],
                ecoTax_price_pro: [null, Validators.min(0)],
                ecoTax_price_personal: [null, Validators.min(0)],
                ecoTax_price_currency: ['EUR'],
                ecoTax_default: [null],
                co2_product_kg: [null, Validators.min(0)],
                un_codes: this.formBuilder.array([])
            });
            this.onAddCategoryTree();
        }
    }
    columnIsEditable(col: string): boolean {
        return this.multipleRowsColumnsToEdit.includes(col);
    }
    onAddColumnToEdit() {
        const column = this.rowForm.get('new_column').value;
        if (column && column.length > 0) {
            this.multipleRowsColumnsToEdit.push(column);
            this.rowForm.get('new_column').setValue(null);
        }
    }
    onRemoveColumnToEdit(column: string) {
        this.multipleRowsColumnsToEdit.splice(this.multipleRowsColumnsToEdit.findIndex(col => col === column), 1);
    }
    getRemainingColumnsToAdd(): string[] {
        return this.multipleRowsWholeEditableColumns.filter(col => ! this.multipleRowsColumnsToEdit.includes(col));
    }

    get categoryTariffRatesForm(): FormArray {
        return this.rowForm.get('tariff_rates') as FormArray;
    }
    onDeleteCategoryTariffRates(i: number) {
        this.categoryTariffRatesForm.removeAt(i);
    }
    onAddCategoryTariffRates(ctr: CategoryTariffRates, countryIso: string) {
        this.categoryTariffRatesForm.push(this.formBuilder.group({
            country_iso: [countryIso ? countryIso : '', Validators.required],
            duties_rates: [ctr ? ctr.duties_rates : null],
            localsTax_rates: [ctr ? ctr.localsTax_rates : null],
            specialsTax_rates: [ctr ? ctr.specialsTax_rates : null],
            salesTax_rates: [ctr ? ctr.salesTax_rates : null],
            transco_code: [ctr ? ctr.transco_code : null],
        }));
    }

    get categoryTreeForm(): FormArray {
        return this.rowForm.get('category_tree') as FormArray;
    }
    onDeleteCategoryTree(i: number) {
        this.categoryTreeForm.removeAt(i);
    }
    onAddCategoryTree(tree: string = null) {
        this.categoryTreeForm.push(this.formBuilder.group({
            value: [tree, Validators.required]
        }));
    }

    get productsIdsForm(): FormArray {
        return this.rowForm.get('products_id') as FormArray;
    }
    onDeleteCategoryProductId(i: number) {
        this.productsIdsForm.removeAt(i);
    }
    onAddCategoryProductId(id: string = '') {
        this.productsIdsForm.push(this.formBuilder.group({
            value: [id, Validators.required]
        }));
    }

    get unCodesForm(): FormArray {
        return this.rowForm.get('un_codes') as FormArray;
    }
    onDeleteCategoryUnCode(i: number) {
        this.unCodesForm.removeAt(i);
    }
    onAddCategoryUnCode(unCode: string = null) {
        this.unCodesForm.push(this.formBuilder.group({
            value: [unCode, Validators.required]
        }));
    }

    async onValidRow(csvRow: {[key: string]: string} = null) {
        // row to add from CSV
        // csvRow is as {'category_id': '12345', ...}
        if (csvRow !== null) {
            const customerCategory = Builder(CustomerCategory)
                                    .category_id(csvRow.category_id)
                                    .category_tree(csvRow.category_tree.split('||'))
                                    .products_id(csvRow.products_id ? csvRow.products_id.split('||') : undefined)
                                    .tariff_rates({})
                                    .average_weight_kg(parseFloat(csvRow.average_weight_kg))
                                    .ecoTax_price_default_pro(parseFloat(csvRow.ecoTax_price_default_pro))
                                    .ecoTax_price_default_personal(parseFloat(csvRow.ecoTax_price_default_personal))
                                    .ecoTax_code_pro(csvRow.ecoTax_code_pro)
                                    .ecoTax_subCode_pro(csvRow.ecoTax_subCode_pro)
                                    .ecoTax_code_personal(csvRow.ecoTax_code_personal)
                                    .ecoTax_price_pro(parseFloat(csvRow.ecoTax_price_pro))
                                    .ecoTax_price_personal(parseFloat(csvRow.ecoTax_price_personal))
                                    .ecoTax_price_currency(csvRow.ecoTax_price_currency)
                                    .co2_product_kg(parseFloat(csvRow.co2_product_kg))
                                    .un_code(csvRow.un_code ? csvRow.un_code.split('||') : undefined)
                                    .build();

            const rowIndex = this.rows.findIndex(r => r.category_id === customerCategory.category_id);
            // PUT values
            if (rowIndex >= 0) {
                customerCategory.tariff_rates = this.tariffRatesDetails[customerCategory.category_id];
                await this.putCategory(customerCategory, rowIndex, customerCategory.category_id);

            // POST values
            } else {
                await this.postCategory(customerCategory);
                this.tariffRatesDetails[customerCategory.category_id] = customerCategory.tariff_rates;
            }

        // row to add from form
        } else {
            if (this.rowForm.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const formValue = this.rowForm.value;
            const tariffRates: { [key: string]: CategoryTariffRates } = {};

            formValue.tariff_rates.forEach(tr => {
                const previousTariffRates = this.editingCategoryId ? this.tariffRatesDetails[this.editingCategoryId][tr.country_iso] : null;
                tariffRates[tr.country_iso] = Builder(CategoryTariffRates)
                                            .hscode(previousTariffRates ? previousTariffRates.hscode : undefined)
                                            .hscode_designation(previousTariffRates ? previousTariffRates.hscode_designation : undefined)
                                            .hscode_version(previousTariffRates ? previousTariffRates.hscode_version : undefined)
                                            .duties_rates(this.getNumberValueOrUndefined(tr.duties_rates))
                                            .localsTax_rates(this.getNumberValueOrUndefined(tr.localsTax_rates))
                                            .specialsTax_rates(this.getNumberValueOrUndefined(tr.specialsTax_rates))
                                            .salesTax_rates(this.getNumberValueOrUndefined(tr.salesTax_rates))
                                            .transco_code(tr.transco_code)
                                            .status(previousTariffRates ? previousTariffRates.status : undefined)
                                            .build();
            });

            const customerCategory = Builder(CustomerCategory)
                                .category_id(formValue.category_id)
                                .category_tree(formValue.category_tree.map(item => item.value))
                                .products_id(formValue.products_id.map(item => item.value))
                                .tariff_rates(tariffRates)
                                .average_weight_kg(this.getNumberValueOrUndefined(formValue.average_weight_kg))
                                .ecoTax_price_default_pro(this.getNumberValueOrUndefined(formValue.ecoTax_price_default_pro))
                                .ecoTax_price_default_personal(this.getNumberValueOrUndefined(formValue.ecoTax_price_default_personal))
                                .ecoTax_code_pro(formValue.ecoTax_code_pro)
                                .ecoTax_subCode_pro(formValue.ecoTax_subCode_pro)
                                .ecoTax_code_personal(formValue.ecoTax_code_personal)
                                .ecoTax_price_pro(this.getNumberValueOrUndefined(formValue.ecoTax_price_pro))
                                .ecoTax_price_personal(this.getNumberValueOrUndefined(formValue.ecoTax_price_personal))
                                .ecoTax_price_currency(formValue.ecoTax_price_currency)
                                .co2_product_kg(parseFloat(formValue.co2_product_kg))
                                .un_code(formValue.un_codes.map(item => item.value))
                                .build();

            const rowIndex = this.rows.findIndex(r => r.category_id === this.editingCategoryId);
            if (rowIndex >= 0) {
                await this.putCategory(customerCategory, rowIndex);
            } else {
                await this.postCategory(customerCategory);
            }
            this.tariffRatesDetails[customerCategory.category_id] = {};
            const coutryIsos = Object.keys(tariffRates);
            coutryIsos.forEach(countryIso => {
                this.tariffRatesDetails[customerCategory.category_id][countryIso] = (<CategoryTariffRates> tariffRates[countryIso]);
            });

            this.closeModal();
        }
        this.setDisplayedRows();
    }

    async onValidMultipleRowsEdit() {
        if (this.rowForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.rowForm.value;

        const updatesToDo: CustomerCategory[] = [];
        this.selectedRows.forEach(row => {
            const tariffRates: { [key: string]: CategoryTariffRates } = {};

            if (this.multipleRowsColumnsToEdit.includes('tariff_rates')) {
                formValue.tariff_rates.forEach(tr => {
                    const previousTariffRates = this.tariffRatesDetails[row.category_id][tr.country_iso];
                    tariffRates[tr.country_iso] = Builder(CategoryTariffRates)
                                                .hscode(previousTariffRates ? previousTariffRates.hscode : undefined)
                                                .hscode_designation(previousTariffRates ? previousTariffRates.hscode_designation : undefined)
                                                .hscode_version(previousTariffRates ? previousTariffRates.hscode_version : undefined)
                                                .duties_rates(this.getNumberValueOrUndefined(tr.duties_rates))
                                                .localsTax_rates(this.getNumberValueOrUndefined(tr.localsTax_rates))
                                                .specialsTax_rates(this.getNumberValueOrUndefined(tr.specialsTax_rates))
                                                .salesTax_rates(this.getNumberValueOrUndefined(tr.salesTax_rates))
                                                .transco_code(tr.transco_code)
                                                .status(previousTariffRates ? previousTariffRates.status : undefined)
                                                .build();
                });
            } else {
                const countriesIsos = Object.keys(this.tariffRatesDetails[row.category_id]);
                countriesIsos.forEach(iso => {
                    tariffRates[iso] = Builder(CategoryTariffRates)
                                    .hscode(this.tariffRatesDetails[row.category_id][iso].hscode)
                                    .hscode_designation(this.tariffRatesDetails[row.category_id][iso].hscode_designation)
                                    .hscode_version(this.tariffRatesDetails[row.category_id][iso].hscode_version)
                                    .duties_rates(this.tariffRatesDetails[row.category_id][iso].duties_rates)
                                    .localsTax_rates(this.tariffRatesDetails[row.category_id][iso].localsTax_rates)
                                    .specialsTax_rates(this.tariffRatesDetails[row.category_id][iso].specialsTax_rates)
                                    .salesTax_rates(this.tariffRatesDetails[row.category_id][iso].salesTax_rates)
                                    .transco_code(this.tariffRatesDetails[row.category_id][iso].transco_code)
                                    .status(this.tariffRatesDetails[row.category_id][iso].status)
                                    .build();
                });
            }

            const customerCategory = Builder(CustomerCategory)
                                    .category_id(row.category_id)
                                    .category_tree(row.category_tree)
                                    .products_id(
                                        this.multipleRowsColumnsToEdit.includes('products_id') ?
                                        formValue.products_id.map(item => item.value) :
                                        row.products_id
                                    )
                                    .tariff_rates(tariffRates)
                                    .average_weight_kg(
                                        this.multipleRowsColumnsToEdit.includes('average_weight_kg') ?
                                        this.getNumberValueOrUndefined(formValue.average_weight_kg) :
                                        row.average_weight_kg
                                    )
                                    .ecoTax_price_default_pro(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_price_default_pro') ?
                                        this.getNumberValueOrUndefined(formValue.ecoTax_price_default_pro) :
                                        row.ecoTax_price_default_pro
                                    )
                                    .ecoTax_price_default_personal(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_price_default_personal') ?
                                        this.getNumberValueOrUndefined(formValue.ecoTax_price_default_personal) :
                                        row.ecoTax_price_default_personal
                                    )
                                    .ecoTax_code_pro(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_code_pro') ?
                                        formValue.ecoTax_code_pro :
                                        row.ecoTax_code_pro
                                    )
                                    .ecoTax_subCode_pro(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_subCode_pro') ?
                                        formValue.ecoTax_subCode_pro :
                                        row.ecoTax_subCode_pro
                                    )
                                    .ecoTax_code_personal(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_code_personal') ?
                                        formValue.ecoTax_code_personal :
                                        row.ecoTax_code_personal
                                    )
                                    .ecoTax_price_pro(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_price_pro') ?
                                        this.getNumberValueOrUndefined(formValue.ecoTax_price_pro) :
                                        row.ecoTax_price_pro
                                    )
                                    .ecoTax_price_personal(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_price_personal') ?
                                        this.getNumberValueOrUndefined(formValue.ecoTax_price_personal) :
                                        row.ecoTax_price_personal
                                    )
                                    .ecoTax_price_currency(
                                        this.multipleRowsColumnsToEdit.includes('ecoTax_price_currency') ?
                                        formValue.ecoTax_price_currency :
                                        row.ecoTax_price_currency
                                    )
                                    .co2_product_kg(
                                        this.multipleRowsColumnsToEdit.includes('co2_product_kg') ?
                                        parseFloat(formValue.co2_product_kg) :
                                        row.co2_product_kg
                                    )
                                    .un_code(
                                        this.multipleRowsColumnsToEdit.includes('un_code') ?
                                        formValue.un_codes.map(item => item.value) :
                                        row.un_codes
                                    )
                                    .build();

            updatesToDo.push(customerCategory);
        });

        await Promise.all(updatesToDo.map(c => this.preparePromisePutMultipleCategories(c)));

        // refresh the table
        this.rows = [...this.rows];
        this.setDisplayedRows();
        this.closeModal();
        this.multipleRowsColumnsToEdit = [];
        this.selectedRows = [];
    }

    async preparePromisePutMultipleCategories(category: CustomerCategory): Promise<void> {
        const rowIndex = this.rows.findIndex(r => r.category_id === category.category_id);
        await this.putCategory(category, rowIndex, category.category_id);
        this.tariffRatesDetails[category.category_id] = {};
        const coutryIsos = Object.keys(category.tariff_rates);
        coutryIsos.forEach(countryIso => {
            this.tariffRatesDetails[category.category_id][countryIso] = (<CategoryTariffRates> category.tariff_rates[countryIso]);
        });
    }

    getNumberValueOrUndefined(value: string) {
        if (value === null || value === undefined || value === '') {
            return undefined;
        }
        return !isNaN(Number(value)) ? parseFloat(value) : undefined;
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    // rate between 0 and 1
    filterKeyRate(event, index: number, rate: string) {
        const lastValue = this.categoryTariffRatesForm.at(index).get(rate).value;
        return (
            !isNaN(Number(event.key)) &&
            (
                lastValue === '0.' ||
                (
                    lastValue !== null &&
                    (
                        lastValue === '' ||
                        (parseFloat(lastValue) >= 0 && parseFloat(lastValue) < 1))
                    )
            )
            ||
            (lastValue === null && parseInt(event.key, 10) <= 1)
        )
        ||
        (
            event.key === '.' &&
            lastValue !== null &&
            !isNaN(Number(lastValue)) &&
            ! lastValue.toString().includes('.')
        );
    }

    // post categories, returns true on success, false otherwise
    async postCategory(customerCategory: CustomerCategory): Promise<boolean> {
        try {
            const addedCategory = (
                await this.http.post(`https://api.${environment.baseUrl}/v1/customer/categories`, customerCategory).toPromise()
            ) as CustomerCategory;
            customerCategory = plainToClass(CustomerCategory, addedCategory);
            this.rows.push(customerCategory.toRow());
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // put category, returns true on success, false otherwise
    async putCategory(customerCategory: CustomerCategory, rowIndex: number, categoryId: string = null): Promise<boolean> {
        try {
            const body = {...customerCategory};
            const updatedCategory = (await this.http.put(
                `https://api.${environment.baseUrl}/v1/customer/categories?category_id=${this.editingCategoryId ? this.editingCategoryId : categoryId}`,
                body
            ).toPromise()) as CustomerCategory;
            customerCategory.last_update_timestamp = updatedCategory.last_update_timestamp;
            this.rows[rowIndex] = customerCategory.toRow();
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // get categories, returns true on success, false otherwise
    async getCategories(): Promise<AllCustomerCategory> {
        try {
            if (this.nextIndex && this.nextIndex.category_id) {
                return (<AllCustomerCategory> await this.http.get(
                    `https://api.${environment.baseUrl}/v1/pdf?index=${this.nextIndex.category_id}`
                ).toPromise());
            }
            return (<AllCustomerCategory> await this.http.get(`https://api.${environment.baseUrl}/v1/customer/categories`).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    onSelect({ selected }) {
        this.selectedRows.splice(0, this.selectedRows.length);
        this.selectedRows.push(...selected);
    }

    // delete category, returns true on success, false otherwise
    async deleteCategory(category_id: string): Promise<boolean> {
        try {
            const response = await this.http.delete(`https://api.${environment.baseUrl}/v1/customer/categories?category_id=${category_id}`).toPromise();
            const index = this.rows.findIndex(r => r.category_id === category_id);
            this.rows.splice(index, 1);
            delete this.tariffRatesDetails[category_id];
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    getCategoryTariffRatesDetails(categoryId: string): {countryIso: string, tariffRates: CategoryTariffRates}[] {
        const countriesIsos = Object.keys(this.tariffRatesDetails[categoryId]);
        return countriesIsos.map(iso => {
            return {
                countryIso: iso,
                tariffRates: this.tariffRatesDetails[categoryId][iso]
            };
        });
    }

    onDetailToggle(event) {
        // nothing to do
    }

    prepareAutocompleteProduct(index: number) {
        const control = this.productsIdsForm.at(index).get('value');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), 'sku')),
        );
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        if (value !== null && value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            return wholeValues.filter(option => {
                const optionSplitted = option.split(', ');
                if (filterField === 'sku') {
                    return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
                }
                let i = 0;
                if (filterField === 'email') {
                    i = 1;
                } else if (filterField === 'website') {
                    i = 2;
                }
                return optionSplitted[i].toLowerCase().includes(filterValue);
            });
        }
    }

    onProductClicked(index: number, option: string) {
        // sku is the sort key
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            this.productsIdsForm.at(index).get('value').setValue(rightProduct.sku);
        }
    }

    getCountryLabel(iso: string): string {
        const country = this.countries.find(c => c.value === iso || c.iso2 === iso);
        return country ? country.label : iso;
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
            this.initFormatColumnsValues();
        } catch (error) {
            console.error(error);
        }
    }

    onScrollToEnd() {
        if (!this.isRetrievingData && this.nextIndex !== undefined) {
            this.retrieveCategories();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                            event.container.data,
                            event.previousIndex,
                            event.currentIndex);
        }
        localStorage.setItem('categories_columnsToDisplay', JSON.stringify(this.columnsToDisplay));
    }

    get filterCategoryContent() {
        return this.filterForm.get('filter').value;
    }

    prepareAutocompleteCategory() {
        const control = this.filterForm.get('filter');
        this.filteredCategoriesOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this.filterCategory(value, this.managersService.getOptionsCategories())),
        );
    }

    // filter used for autocompletion filter form
    private filterCategory(value: string, wholeValues: string[]): string[] {
        const filterValue = value.toLowerCase();
        this.setDisplayedRows();
        this.selectedRows = [];
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
        });
    }

    idOrTreeInFilter(row: any): boolean {
        if (this.filterCategoryContent) {
            return this.filterCategoryContent === '' ||
                row.category_id.toLowerCase().includes(this.filterCategoryContent.toLowerCase()) ||
                row.category_tree.join(' - ').toLowerCase().includes(this.filterCategoryContent.toLowerCase()) ||
                this.filterCategoryContent.toLowerCase().includes(row.category_id.toLowerCase()) ||
                this.filterCategoryContent.toLowerCase().includes(row.category_tree.join(' - ').toLowerCase());
        }
        return true;
    }

    setDisplayedRows(): void {
        this.displayedRows = this.rows.filter(r => this.idOrTreeInFilter(r));
        this.setTariffRatesDetailsAsArray();
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['country'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }

        // prepare format currencies
        if (this.currencies) {
            formatValues.push({
                columns: ['ecoTax_price_currency'],
                type: this.currencies as { value: string; label: string; }[]
            });
        }

        this.formatColumnsValues = formatValues;
    }

    onDisplayRow(rowId: string) {
        this.displayRowOnly = true;
        this.openModal(this.editrow, 'displayrow', rowId);
    }
    onEditRow(rowId: string) {
        this.displayRowOnly = false;
        this.openModal(this.editrow, 'editrow', rowId);
    }
    onDeleteRow(rowId: string) {
        this.openModal(this.confirmDeleteRow, 'deleterow', rowId);
    }
    onDeleteRows(selectedRows: string[]) {
        if (this.rowsToDelete.length === 0) {
            this.rowsToDelete = selectedRows;
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            this.modalRef = this.modalService.show(this.confirmDeleteRows, config);
        }
    }
    onCancelDeleteRows() {
        this.rowsToDelete = [];
        this.closeModal();
    }
    async onValidRowDeletion() {
        this.closeModal();
        await this.deleteCategory(this.categoryToDeleteId);
        this.categoryToDeleteId = null;
        this.setDisplayedRows();
    }
    // delete rows 10 by 10
    async onValidRowsDeletion() {
        this.closeModal();

        let allSelectedOrdersDeleted = true;
        const rowsIds: string[][] = [];
        rowsIds.push([]);
        this.rowsToDelete.forEach(orderId => {
            if (rowsIds[rowsIds.length - 1].length === 10) {
                rowsIds.push([]);
            }
            rowsIds[rowsIds.length - 1].push(orderId);
        });

        for (let i = 0; i < rowsIds.length; i++) {
            const promises = await Promise.all(rowsIds[i].map(r => this.deleteCategory(r)));
            if (promises.includes(false)) {
                allSelectedOrdersDeleted = false;
            }
        }
        this.setDisplayedRows();

        if (! allSelectedOrdersDeleted) {
            this._notification.displayMessage(this.translate.instant('messages.notification.someRowsNotDeleted'), 'warning');
        } else {
            this._notification.displayMessage(this.translate.instant('messages.notification.rowsDeleted'), 'success');
        }
    }

    onClaimSelectedRows() {
        this.requestSelectedRowsSubject.next();
    }
    onReceiveSelectedRows(rowsIds: string[]) {
        this.selectedRows = this.rows.filter(r => rowsIds.includes(r.category_id));
        switch (this.selectedRowsNextAction) {
            case PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV:
                if (this.selectedRows.length > 0) {
                    this.downloadCsv();
                }
                break;
            case PRODUCTS_SELECTED_ROWS_ACTIONS.EDIT_MULTIPLE:
                if (this.selectedRows.length > 0) {
                    this.openModal(this.editmultiplerows, 'editmultiplerows', null);
                }
                break;
            default:
                break;
        }
    }

    onDownloadSelectedRowsAsCsv() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV;
        this.onClaimSelectedRows();
    }
    onEditMultipleRows() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.EDIT_MULTIPLE;
        this.onClaimSelectedRows();
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv() {
        const csvResponse = this.selectedRows.slice();
        csvResponse.forEach(row => {
            if (this.tariffRatesDetails && this.tariffRatesDetails[row.category_id]) {
                const tariffRates = this.getCategoryTariffRatesDetails(row.category_id);
                tariffRates.forEach(tr => {
                    row[`tariff_rates_${tr.countryIso}`] = JSON.stringify(tr.tariffRates);
                });
            }
        });
        const csvFileName = 'my_categories';
        return this.csvService.downloadFile(csvResponse, csvFileName);
    }

    setTariffRatesDetailsAsArray() {
        const categoriesIds = Object.keys(this.tariffRatesDetails);
        const formattedTariffRatesDetails: { [key: string]: { [key: string]: any }[] } = {};
        categoriesIds.forEach(id => {
            const countriesIsos = Object.keys(this.tariffRatesDetails[id]);
            formattedTariffRatesDetails[id] = countriesIsos.map(c => {
                const tableRow = {country: c, ...this.tariffRatesDetails[id][c]};
                return tableRow;
            });
        });
        this.formattedTariffRatesDetailsForTable = formattedTariffRatesDetails;
    }
}
