import {Component, HostListener, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef} from '@angular/core';
import {RootLayout} from '../root/root.component';
import {pagesToggleService} from '../../services/toggler.service';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {environment} from '../../../../environments/environment';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/User';
import {pgTabComponent} from '../../components/tabs/tab.component';
import {MenuComponent} from '../../components/menu/menu.component';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { DOCUMENTATIONS_GUIDES } from '../documentation-guide/documentation-guide.component';

declare var pg: any;

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayoutComponent extends RootLayout implements OnInit, OnDestroy {
  menusTranslations: {
    choosePremiumTitle: string,
    choosePremiumContent: string,
    becomePremium: string,
    disconnect: string
  } = {
    choosePremiumTitle: null,
    choosePremiumContent: null,
    becomePremium: null,
    disconnect: null
  };
  modalRef: BsModalRef;
  menuLinks: any;
  profileLinks: any;
  frontUrl: string;
  user: User;
  menuOpen: boolean;
  selectorMenuButton: string;
  dashboard_sidebar = [];
  profile_sidebar = [];
  was_dashboard;
  direction: string;
  displayedChild: string;
  displayedMenuSubject: Subject<string> = new Subject<string>();
  private dashboardMenu;

  @ViewChild(MenuComponent, {static: false}) child: MenuComponent;
  @ViewChild('disconnect', { static: false }) disconnectModal: TemplateRef<any>;
  constructor(toggler: pagesToggleService,
              router: Router,
              private authService: AuthService,
              private modalService: BsModalService,
              private translate: TranslateService,
              private userService: UserService) {
    super(toggler, router);
    this.frontUrl = `https://app.${environment.baseUrl}/`;
    this.menuLinks = [];
    this.direction = 'ltr';
    this.toggleMenuPin(true);
  }
  async ngOnInit() {
    this.changeLayout('menu-pin');
    this.selectorMenuButton = 'menuButton';
    this.menuOpen = false;
    this.user = await this.loadUser();
    await this.initTranslation(this.user.getLangIso2());
    this.translate.get('sidebar').subscribe(sidebar => {
      this.updateSidebar(sidebar);
    });
    this.translate.get('dashboard.menu').toPromise().then(menu => {
      this.menusTranslations = {
        choosePremiumTitle: this.user.isTrial() ? menu.choosePremiumTitle : null,
        choosePremiumContent: this.user.isTrial() ? menu.choosePremiumContent : null,
        becomePremium: this.user.isTrial() ? menu.becomePremium : null,
        disconnect: menu.logout
      };
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateSidebar(event.translations['sidebar']);
    });
    // Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.menuOpen = false;
    this._menuDrawerOpen = false;
    this._menuDrawerOpen = false;
    this._secondarySideBar = false;
    this._mobileSidebar = false;
    this._mobileHorizontalMenu = false;
    if (document.body.getElementsByClassName('pgn-wrapper ng-star-inserted')[0]) {
      pg.addClass(document.body.getElementsByClassName('pgn-wrapper ng-star-inserted')[0], 'mt-0');
    }
    pg.removeClass(document.body, 'menu-pin');
  }
  updateSidebar(sidebar) {
    if ( this.user.isSuperAdmin() ) {
      this.dashboard_sidebar = sidebar['newDashboard']['superAdmin'];
    } else if (this.user.isGrandChild()) {
      this.dashboard_sidebar = sidebar['newDashboard']['grandchild'];
    } else if (this.user.isChild()) {
      this.dashboard_sidebar = sidebar['newDashboard']['child'];
    } else {
      this.dashboard_sidebar = sidebar['newDashboard']['user'];
    }
    if ( this.user.isAdmin() ) {
      this.profile_sidebar = sidebar['profile']['admin'];
    } else {
      this.profile_sidebar = sidebar['profile']['child'];
    }
    this.profileLinks = this.profile_sidebar;
    this.setSideBar();
  }
  setSideBar() {
    
    /*if (this.router.url === '/dashboard' ||
        this.router.url === '/' ||
        this.dashboard_sidebar.find(menu => menu.routerLink ? menu.routerLink === this.router.url : false) ||
        this.was_dashboard
    ) {*/
      this.menuLinks = this.dashboard_sidebar.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
      this.dashboard_sidebar.forEach(ml => {
        if (ml.submenu) {
          ml.submenu = ml.submenu.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
        }
      });
      setTimeout(() => {
        const currentRoute = this.router.url;
        const activeMenu = this.menuLinks.find(ml => {
          return ml.routerLink === currentRoute || (ml.submenu ? ml.submenu.findIndex(s => s.routerLink === currentRoute) >= 0 : false);
        });
        this.dashboardMenu = this.menuLinks.find(ml => ml.routerLink === '/dashboard');
        if (activeMenu) {
          if (activeMenu.label) {
            this.emitToMenu(activeMenu.label);
            if (activeMenu.submenu) {
              this.displayedChild = activeMenu.submenu.find(s => s.routerLink === currentRoute).label;
            }
          } else {
            this.emitToMenu('');
          }
        } else {
          if (currentRoute === '/' && this.dashboardMenu.label) {
            this.emitToMenu(this.dashboardMenu.label);
          } else {
            this.emitToMenu('');
          }
        }
      }, 500);

      this.was_dashboard = true;
    /*} else {
      this.menuLinks = this.profile_sidebar;
      this.was_dashboard = false;
    }*/
  }
  isAdmin() {
      if ( this.user  ) {
        if ( this.user.isAdmin() ) {
            return true;
        }
      }
      return false;
  }
  isSuperAdmin() {
    if ( this.user  ) {
      if ( this.user.isSuperAdmin() ) {
        return true;
      }
    }
    return false;
  }
  handleToggleMobileSidebar = () => {
    if (!this.menuOpen) {
      this.menuLinks = this.dashboard_sidebar.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
      this.dashboard_sidebar.forEach(ml => {
        if (ml.submenu) {
          ml.submenu = ml.submenu.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
        }
      });
    }
    this.toggleMenuIcon();
    this.toggleMobileSidebar();
  }

  handleToggleProfileSideBar = () => {
    this.toggleMenuIcon();
    this.menuLinks = this.dashboard_sidebar.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
    this.toggleMobileSidebar();
  }

  openModal(template: TemplateRef<any>) {
    const config = {class: 'modal-lg', animated: false, backdrop: true} as ModalOptions;
    this.modalRef = this.modalService.show(template, config);
}

  signOut = async () => {
    await this.authService.logout();
    this.modalRef.hide();
    this.router.navigate([this.user.parent_brand_logo_url ? '/auth' : '/login']);
    // used to avoid a display bug 
    location.reload();
  }

  onLogout() {
    this.openModal(this.disconnectModal);
  }
  async goToPlansPage() {
    await this.router.navigate(['/plans']);
  }
  async onBackToDashboard() {
    await this.router.navigate(['/dashboard']);
    if (this.dashboardMenu.label) {
      this.emitToMenu(this.dashboardMenu.label);
    } else {
      this.emitToMenu('');
    }
  }
  async onMyProfileClick() {
    await this.router.navigate(['/settings/plan']);
  }
  async onMyHistoryClick() {
    await this.router.navigate(['/history']);
  }
  async onBillingClick() {
    await this.router.navigate(['/subscription']);
  }
  async onSettingsClick() {
    await this.router.navigate(['/settings/plan']);
  }
  async onSupportClick() {
    await this.router.navigate(['/dashboard']);
  }
  onOpenDocumentation(urlType: string) {
    if (Object.keys(DOCUMENTATIONS_GUIDES).includes(urlType)) {
      this.router.navigate(
        [`documentation-guide/${Object.keys(DOCUMENTATIONS_GUIDES).includes(urlType) ? DOCUMENTATIONS_GUIDES[urlType] : DOCUMENTATIONS_GUIDES.SAAS}`]
      );
    }
  }


  setDefaultMenu() {
    this.menuLinks = this.dashboard_sidebar.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
    this.dashboard_sidebar.forEach(ml => {
      if (ml.submenu) {
        ml.submenu = ml.submenu.filter(menu => !menu.onlyForPlan || menu.onlyForPlan.split(',').includes(this.user.plan));
      }
    });
  }
  useLang(lang: string) {
    if (lang === 'ar') {
      this.direction = 'rtl';
    } else {
      this.direction = 'ltr';
    }
    if (['en','fr','es'].includes(lang)) {
      this.translate.use(lang);
    }
  }
  async loadUser() {
    return await this.userService.initTransiteoUser();
  }
  async initTranslation(lang: string) {
    console.log(`Init ${lang} translation`);
    if (lang !== this.translate.defaultLang) {
      if (['en','fr','es'].includes(lang)) {
        this.translate.use(lang);
      }
    }
  }
  toggleMenuIcon() {
    if (this.menuOpen) {
      this.changeIcon(this.selectorMenuButton, 'pg-close', 'pg-menu');
    } else {
      this.changeIcon(this.selectorMenuButton, 'pg-menu', 'pg-close');
    }
    this.menuOpen = !this.menuOpen;
  }
  changeIcon(idSelector, sourceIconClass, targetIconClass) {
    document.getElementById(idSelector).classList.remove(sourceIconClass);
    document.getElementById(idSelector).classList.add(targetIconClass);
  }

  emitToMenu(value: string) {
    this.displayedMenuSubject.next(value);
  }

  logoUrl(): string {
    if (this.user && this.user.parent_brand_logo_url && this.user.parent_brand_logo_url.length > 0) {
      return this.user.parent_brand_logo_url;
    }
    return '../../../../assets/img/logo-transiteo-horizontal.png';
  }
}
