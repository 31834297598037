import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Builder } from 'builder-pattern';

export enum FILTER_TABLE_FIELD_TYPES {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    POSITIVE_INTEGER = 'POSITIVE_INTEGER',
    FLOAT = 'FLOAT',
    FLOAT_TWO_DECIMALS = 'FLOAT_TWO_DECIMALS',
    FLOAT_THREE_DECIMALS = 'FLOAT_THREE_DECIMALS',
    DATE = 'DATE',
    TIME = 'TIME',
    DATETIME = 'DATETIME',
    SELECT = 'SELECT',
    SELECT_MULTIPLE = 'SELECT_MULTIPLE'
}
export class FilterTableField {
    id: string;
    value: any;
    label: string;
    placeholder: string;
    placeholderDate?: string;
    placeholderTime?: string;
    type: FILTER_TABLE_FIELD_TYPES;
    selectOptions?: {label: string; value: string; iso2?: string; disabled?: string}[];
}

@Component({
    selector: 'filter-table',
    templateUrl: './filter-table.component.html',
    styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit {
    @Input()
    filterFields: FilterTableField[][] = [];
    @Output()
    validateFilter: EventEmitter<FilterTableField[]> = new EventEmitter<FilterTableField[]>();

    filterForm: FormGroup;
    formIsInvalid = false;

    constructor(
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.filterForm = this.formBuilder.group({});
        this.filterFields.forEach(fields => {
            fields.forEach(field => {
                this.filterForm.addControl(
                    field.id,
                    new FormControl(field.value, [])
                );
            });
        });
    }

    // control positive integer
    filterKeyPositiveInteger(event) {
        return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    // control integer
    filterKeyInteger(event, id) {
        const previousValue = this.filterForm.get(id).value;
        return [8, 46].includes(event.keyCode) ||
            (! previousValue && previousValue !== 0 && event.keyCode === 189) ? true : !isNaN(Number(event.key));
    }

    onEraseFilters() {
        this.filterFields.forEach(fields => {
            fields.forEach(field => {
                this.filterForm.get(field.id).setValue(null);
            });
        });
    }

    onValidFilter() {
        if (this.filterForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const filters = this.filterForm.value;
        const filterTableFields: FilterTableField[] = [];
        const filtersIds = Object.keys(filters);
        filtersIds.forEach(id => {
            filterTableFields.push(
                Builder(FilterTableField)
                .id(id)
                .value(filters[id])
                .build()
            );
        });
        this.formIsInvalid = false;
        this.validateFilter.emit(filterTableFields);
    }
}
