import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../../../models/User';
import {MessageService} from '../../components/message/message.service';
import {AuthService} from '../../auth/auth.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Invoice} from '../../../models/invoice';
import * as moment from 'moment';
import {Page, PagedData} from '../../../models/page';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {StripeService} from '../../services/stripe.service';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  user: User;
  billingUrl: string;
  invoices: Array<Invoice>;
  rows: Array<Invoice>;
  columns: string[] = [];
  columnsLabels: { [key: string]: string; };
  page: Page;
  selected: [] = [];
  invoice_error_message: string;
  invoice = {};
  cancel_subscription_message: string;
  generateCardCheckoutSession: string;
  subscriptions: TransiteoSubscription[] = [];
  planSubscription: TransiteoSubscription;
  subscriptionToDelete: number;
  interval: {
    month: 'month',
    year: 'year'
  };
  private isLoading: boolean;
  modalRef: BsModalRef;
  dateFormat = 'MM/DD/YYYY';

  formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

  constructor(private userService: UserService,
              private _notification: MessageService,
              private authService: AuthService,
              private http: HttpClient,
              private translate: TranslateService,
              private stripeService: StripeService,
              private modalService: BsModalService) {
    this.billingUrl = `https://api.${environment.baseUrl}/v1/billing/`;
    this.generateCardCheckoutSession = `https://api.${environment.baseUrl}/v1/checkout/card/generate`;
    this.rows = [];
    this.columns = [
      'date',
      'invoice_number',
      'amount',
      'currency',
      'status',
      'invoice_url',
      'invoice_pdf'
    ];
  }

  @ViewChild('myTable', {static: false}) table;
  async ngOnInit() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
    this.initTranslation();
    await this.getBillingInfo();
  }
  initTranslation() {
    this.translate.get('subscription').toPromise().then((sub) => {
      this.columnsLabels = sub.table;
    });
    this.translate.get('messages').toPromise().then(messages => {
      this.updateLang(messages);
    });
    this.translate.get('dateFormat').toPromise().then((dateFormat) => {
      this.dateFormat = dateFormat;
    });
    // get currencies
    this.translate.get('currency_name').toPromise().then(currencies => {
      this.initFormatColumnsValues(currencies);
    });
    this.translate.onLangChange.toPromise().then((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.dateFormat = event.translations['dateFormat'];
      this.initFormatColumnsValues(event.translations['currency_name']);
      this.updateLang(messages);
    });

  }
  updateLang(messages) {
    this.invoice_error_message = messages.notification['invoice_error_message'];
    this.cancel_subscription_message = messages.notification['cancel_subscription'];
    this.invoice = messages.invoice;
    this.interval = messages.interval;
  }

  async getBillingInfo() {
    this.http.get(this.billingUrl).toPromise().then((response: any) => {
      this.invoices = response.invoices;
      this.rows = (response.invoices as Invoice[]).map(i => {
        const row: Invoice = {
          date: new Date(i.date),
          invoice_number: i.invoice_number,
          amount: i.amount,
          currency: i.currency,
          status: i.status,
          invoice_url: `<a href="${i.invoice_url}" target="_blank"> ${this.translate.instant('subscription.table.invoice_url')}</a>`,
          invoice_pdf: `<a href="${i.invoice_pdf}" target="_blank"> ${this.translate.instant('subscription.table.download')}</a>`
        };
        return row;
      });
      this.subscriptions = response.subscriptions;
      this.planSubscription = this.subscriptions.find(sub => sub.type === SUBSCRIPTION_TYPES.PLAN);
    }, (error: any) => {
      this.displayMessage(this.invoice_error_message, 'warning');
    });
  }
  getInterval(interval) {
    return this.interval[interval];
  }

  initFormatColumnsValues(currencies: { value: string; label: string; }[]) {
    const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    // prepare format dates
    formatValues.push({
        columns: ['date'],
        type: 'Date'
    });

    // prepare format currencies
    if (currencies) {
      formatValues.push({
          columns: ['currency'],
          type: currencies as { value: string; label: string; }[]
      });
  }

    this.formatColumnsValues = formatValues;
}

  displayMessage(message: string, type: string) {
    if (this._notification && typeof this._notification.create === 'function') {
      this._notification.create(
          type,
          message,
          {
            Position: 'top',
            Style: 'bar',
            Duration: 10000
          }
      );
    }
  }

  async fetchCheckoutSession(i: number) {
    if (i < this.subscriptions.length) {
      return this.http.post(this.generateCardCheckoutSession, {subscription_id: this.subscriptions[i].id}).toPromise().then((response: any) => {
        return response.sessionId;
        // tslint:disable-next-line:no-shadowed-variable
      }, (error: any) => {
        this.displayMessage(error.message, 'danger');
        return null;
      });
    }
  }


  toDate(timestamp) {
    return moment(timestamp).utc().format(this.dateFormat);
  }
  toDateSubs(timestamp) {
    return moment.unix(timestamp).format(this.dateFormat);
  }
  getPlanName(): string {
    return this.invoice[this.user.plan] ? this.invoice[this.user.plan] : this.user.plan;
  }
  getSubscriptionType(i: number): string {
    if (i < this.subscriptions.length) {
      switch (this.subscriptions[i].type) {
        case SUBSCRIPTION_TYPES.PLAN:
          return this.getPlanName();
        
        case SUBSCRIPTION_TYPES.TECHNICAL_SUPPORT:
          return this.translate.instant('plan.ecommerce.technical_support.label');
        
        case SUBSCRIPTION_TYPES.CHILD:
          return this.translate.instant('plan.api.children.label');
      
        default:
          return this.getPlanName();
      }
    }
    return '';
  }
  async cancelSubscription() {
    if (! isNaN(this.subscriptionToDelete) && this.subscriptionToDelete < this.subscriptions.length) {
      const cancel_url = this.billingUrl + 'subscription/' + this.subscriptions[this.subscriptionToDelete].id;
      await this.http.delete(cancel_url).subscribe((response: any) => {
        this.modalRef.hide();
        this.displayMessage(this.cancel_subscription_message, 'success');
      }, (error: any) => {
        this.modalRef.hide();
        this.displayMessage(this.invoice_error_message, 'warning');
      });
      await this.getBillingInfo();
    }
  }
  async updatePayment(i: number) {
    const sessionId = await this.fetchCheckoutSession(i);
    if (sessionId) {
      const stripe = await this.stripeService.stripePromise;
      // tslint:disable-next-line:no-shadowed-variable
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId
      });
      if (error) {
        this.displayMessage(error.message, 'danger');
      }
    }
  }

  onDisableAutoRenew(i: number, template: TemplateRef<any>): void {
    this.subscriptionToDelete = i;
    this.openModal(template);
  }
  openModal(template: TemplateRef<any>) {
    const config = {class: 'modal-lg', animated: false, backdrop: true} as ModalOptions;
    this.modalRef = this.modalService.show(template, config);
  }

}

enum SUBSCRIPTION_TYPES {
  PLAN = 'PLAN',
  CHILD = 'CHILD',
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT'
}
class TransiteoSubscription {
  id: string;
  type: SUBSCRIPTION_TYPES;
  current_period_start: number;
  current_period_end: number;
  interval: string;
  amount: number;
  currency: string;
  payment_method: TransiteoPaymentMethod;
}
class TransiteoPaymentMethod {
  name: string;
  card: {
    exp_month: number;
    exp_year: number;
    last4: string;
  };
}
