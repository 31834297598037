import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Origin} from '../interfaces/origin.interface';
import { Utils } from '../../../utils/utils';
import { Subscription } from 'rxjs';
import { RequestRequiredFields } from '../interfaces/request-required-fields.interface';
const districts = require('../../../../../assets/data/districtsGroupByIsoCountry.json');
const us_states = require('../../../../../assets/data/US_states.json');

@Component({
  selector: 'app-step-origin',
  templateUrl: './step-origin.component.html',
  styleUrls: ['./step-origin.component.scss']
})
export class StepOriginComponent implements OnInit {

    @Input()
    requestsRequiredFields: RequestRequiredFields[] = [];
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    setData: EventEmitter<Origin[]> = new EventEmitter<Origin[]>();

    form: FormGroup;
    formIsInvalid = false;
    districts: {value: string, label: string}[][] = [];
    us_states: Array<{value: string, label: string}> = [];

    toCountrySubscription: Subscription[] = [];
    toStateSubscription: Subscription[] = [];

    stateWithDistrict = ['USA', 'CAN', 'BRA'];

    constructor(
        private translate: TranslateService,
        private formBuilder: FormBuilder
    ) {
        this.us_states = us_states;
        this.form = this.formBuilder.group({
            origins: this.formBuilder.array([])
        });

        // first initialization to avoid 'undefined' issues
        this.addOriginForm();
    }

    async ngOnInit() {
        this.initializeOriginForms();
    }

    initializeOriginForms() {
        for (let i = 0; i < this.requestsRequiredFields.length; i++) {
            if (this.originForms.length === i) {
                this.addOriginForm();
            }
            this.originForms.at(i).patchValue({fromCountry: this.requestsRequiredFields[i].from_country});
            this.originForms.at(i).patchValue({toCountry: this.requestsRequiredFields[i].to_country});

            if (this.requestsRequiredFields[i].to_district) {
                this.districts[i] = this.getDistricts(this.requestsRequiredFields[i].to_country);
                if (this.requestsRequiredFields[i].to_district.length > 5) {
                    this.originForms.at(i).patchValue({toState: this.requestsRequiredFields[i].to_district.substring(0, 5)});
                }
                this.originForms.at(i).patchValue({toDistrict: this.requestsRequiredFields[i].to_district});
            }
        }
    }

    get originForms(): FormArray {
        return this.form.get('origins') as FormArray;
    }

    addOriginForm() {
        this.originForms.push(
            this.formBuilder.group({
                fromCountry: new FormControl('', [Validators.required]),
                toCountry: new FormControl('', [Validators.required]),
                toState: new FormControl(''),
                toDistrict: new FormControl('')
            })
        );
        this.districts.push([]);
        this.toCountrySubscription.push(null);
        this.toStateSubscription.push(null);
        this.subscribeToCountryChanges(this.toCountrySubscription.length - 1);
    }

    removeOriginForm(i: number) {
        this.originForms.removeAt(i);
        this.districts.splice(i, 1);
        this.unsubscribeToCountryChanges(i);
        this.toCountrySubscription.splice(i, 1);
        this.toStateSubscription.splice(i, 1);
    }

    valid() {
        let isValid = false;
        if ( this.form.valid ) {
            for (let i = 0; i < this.originForms.length; i++) {
                if ( this.needDistrict(i) ) {
                    if ( (this.originForms.at(i) as FormGroup).controls['toDistrict'].value ) {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }
            }
        }
        return isValid;
    }

    nextStep() {
        if (! this.valid()) {
            this.formIsInvalid = true;
            return;
        }
        const origins: Origin[] = [];
        for (let i = 0; i < this.originForms.length; i++) {
            const originForm = this.originForms.at(i) as FormGroup;
            const values = originForm.value;
            const origin: Origin = {
                from_country: values.fromCountry,
                to_country: values.toCountry,
                to_district: values.toDistrict
            };
            origins.push(origin);
        }
        this.formIsInvalid = false;
        this.setData.emit(origins);

        for (let i = 0; i < this.toCountrySubscription.length; i++) {
            this.unsubscribeToCountryChanges(i);
        }
    }

    switch(i: number) {
        const tempCountry = this.originForms.at(i).get('fromCountry').value;
        this.originForms.at(i).get('fromCountry').setValue(this.originForms.at(i).get('toCountry').value);
        this.originForms.at(i).get('toCountry').setValue(tempCountry);
    }

    needDistrict(i: number) {
        if (this.originForms.at(i) && this.originForms.at(i).get('toCountry').value) {
            return this.stateWithDistrict.indexOf(this.originForms.at(i).get('toCountry').value) > -1;
        } else {
            return false;
        }
    }

    getDistricts(toCountry) {
        return toCountry && toCountry !== 'USA' && districts[toCountry]
            ? districts[toCountry].length < 500
                ? districts[toCountry].sort(Utils.compareByLabel)
                : districts[toCountry].slice(0, 500).sort(Utils.compareByLabel)
            : [];
    }

    subscribeToCountryChanges(i: number) {
        this.toCountrySubscription[i] = (this.originForms.at(i) as FormGroup).controls.toCountry.valueChanges.subscribe((toCountry) => {
            // if country is USA, we must display the states first
            if (toCountry === 'USA') {
                this.subscribeToStateChanges(i);
            } else {
                this.unsubscribeToStateChanges(i);
            }
            this.originForms.at(i).patchValue({toDistrict: null});

            this.districts[i] = this.getDistricts(toCountry);
        });
    }

    subscribeToStateChanges(i: number) {
        this.toStateSubscription[i] = (this.originForms.at(i) as FormGroup).controls.toState.valueChanges.subscribe((state) => {
            this.originForms.at(i).patchValue({toDistrict: null});

            const stateDistricts = districts['USA'].filter((district) => String(district.value).startsWith(state));
            this.districts[i] = stateDistricts
                ? stateDistricts.length < 800
                    ? stateDistricts.sort(Utils.compareByLabel)
                    : stateDistricts.slice(0, 800).sort(Utils.compareByLabel)
                : [];
        });
    }

    unsubscribeToCountryChanges(i: number) {
        this.unsubscribeToStateChanges(i);
        // unsubscribe to_country changes
        if (this.toCountrySubscription[i]) {
            try {
                this.toCountrySubscription[i].unsubscribe();
                this.toCountrySubscription[i] = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
        }
        this.districts[i] = [];
    }

    unsubscribeToStateChanges(i: number) {
        // unsubscribe to_state changes
        if (this.toStateSubscription[i]) {
            try {
                this.toStateSubscription[i].unsubscribe();
                this.toStateSubscription[i] = null;
            } catch (error) {
                console.log('unable to unsubscribe : ' + error);
            }
        }
    }

}
