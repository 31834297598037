import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamoProduct, SalesProduct} from '../../../../models/DynamoProduct';

@Component({
  selector: 'app-sales-product',
  templateUrl: './sales-product.component.html',
  styleUrls: ['./sales-product.component.scss']
})
export class SalesProductComponent implements OnInit {

  @Input()
  product: DynamoProduct;
  salesProduct: SalesProduct;
  @Input() displayMetric: (value, metric) => string;
  constructor() {}

  ngOnInit() {
    this.salesProduct = this.product as SalesProduct;
  }

  display(value: number, metric) {
    return this.displayMetric(value, metric);
  }

  displayAllCurrencies() {
    return Object.keys(this.salesProduct.price_source).length > 1;
  }
  getFirstCurrencyKey() {
    return Object.keys(this.salesProduct.price_source)[0];
  }

}
