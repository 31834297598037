import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { Utils } from '../../utils/utils';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CustomerOrder, CustomerOrderAvailableActions, CustomerOrderProcessErrorResponse, CustomerOrderProcessResponse, CustomerOrdersProcessProductsAssociated, CustomerOrdersProcessRequest, CustomerOrdersServiceEnum, FormatCsv, GeneratedDocContentWithOrderID, GeneratedDocsResponse, GeneratedDocsWithOrderID, OrderProduct, ORDERS_DATES_INTERVAL, PRODUCTS_SELECTED_ROWS_ACTIONS, UserPdfUrl } from '../../../models/Managers';
import { Builder } from 'builder-pattern';
const transports = require( '../../../../assets/data/transports.json');
import { CsvService } from '../../services/csv.service';
import { ZipService } from '../../services/zip.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ManagersService } from '../../services/managers.service';
import { FilterTableField, FILTER_TABLE_FIELD_TYPES } from '../../components/filter-table/filter-table.component';
import { CsvFieldToSet } from '../../components/csv-mapper/csv-mapper.component';
import { Router } from '@angular/router';
import {saveAs} from 'file-saver';
import { plainToClass } from 'class-transformer';

@Component({
    selector: 'app-order-manager',
    templateUrl: './order-manager.component.html',
    styleUrls: ['./order-manager.component.scss'],
})
export class OrderManagerComponent implements OnInit, OnDestroy {
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    @ViewChild('myTable', { static: false }) table: any;
    @ViewChild('confirmDeleteRow', { static: false }) confirmDeleteRow: TemplateRef<any>;
    @ViewChild('confirmDeleteRows', { static: false }) confirmDeleteRows: TemplateRef<any>;
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;
    @ViewChild('userFieldsMissing', { static: false }) userFieldsMissing: TemplateRef<any>;
    @ViewChild('removeproducts', { static: false }) removeproducts: TemplateRef<any>;
    @ViewChild('landedCostMissing', { static: false }) landedCostMissing: TemplateRef<any>;

    rowForm: FormGroup;
    formIsInvalid = false;
    isEditing = false;
    missingOrderFieldsForAction = false;

    closingModal = true;

    // display the pg-upload component for CSV import
    displayUploadCsvComponent = false;
    // display the pg-upload component for Document upload
    uploadingDocumentForOrder = false;
    orderToWhichAssociateDocuments: string;
    importedDocumentFilename: string;
    importedDocumentForOrderContent = '';

    selectedAction = 0;
    availableActions: string[] = [];
    actionInProgress = false;
    processProductsToRemove: CustomerOrdersProcessProductsAssociated;
    docsToDownload: GeneratedDocsWithOrderID = {};
    selectedRowsNextAction: PRODUCTS_SELECTED_ROWS_ACTIONS;
    ordersWithErrors: CustomerOrderProcessErrorResponse;
    initialProductsNumber: number;

    // full orders
    orders: CustomerOrder[] = [];
    // canceled orders ids
    canceledOrdersIds: string[] = [];
    // table rows
    rows: any[] = [];
    // expanded rows contents (display products and documents pdf)
    rowsDetails: [
        {
            [key: string]: OrderProduct[]
        },
        {
            [key: string]: {
                document_link: string;
                is_deletable: string;
            }[]
        }
    ] = [{}, {}];
    rowsDetailsColumns: string[][] = [];
    rowsDetailsColumnsLabels: { [key: string]: string }[] = [];
    rowsDetailsClickableColumns: { columnName: string; columnId: string; }[] = [];

    selectedRows: any[] = [];
    columnsOrder: string[] = [];
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    filters: FilterTableField[][] = [];
    arrayFilters: FilterTableField[] = [];
    displayFilters = false;

    // order to delete
    orderToDeleteId: string = null;

    // display or edit row ?
    displayRowOnly = false;

    // expand row
    expandedRowId: string = null;
    rowDetailHeight = 0;

    carriers: Array<any> = [];
    countries: Array<any> = [];
    orderStatus: Array<any> = [];
    currencies: Array<any> = [];
    datesIntervalChoice: {value: string; label: string}[] = [];
    incoterms: {value: string; label: string}[] = [];
    deliveryIncoterms: {value: string; label: string}[] = [];

    modalRef: BsModalRef;

    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    messages: any;
    columnsLabels: any;

    tempFrozenColumn = '';
    frozenColumn = '';

    categoryOfItemsTypes: string[] = [];

    ordersToDelete: string[] = [];
    displayedRows: string[] = [];
    selectedDateInterval: string;
    turnover = 0;
    turnoverCurrency = 'EUR';
    sentOrders = 0;
    finishedOrders = 0;
    selectingDateInterval = false;
    titleWidth = 0;

    filteredOptions: Observable<string[]> = new Observable<string[]>();

    deletionSubject: Subject<void> = new Subject<void>();
    requestSelectedRowsSubject: Subject<void> = new Subject<void>();

    expectedCsvContent: FormatCsv;
    displayCsvMapper: Subject<void> = new Subject<void>();
    csvModelFields: CsvFieldToSet[] = [];
    externalCsvFields: string[] = [];

    private subscription: Subscription;

    constructor(
        private zipService: ZipService,
        private http: HttpClient,
        private router: Router,
        private _notification: MessageService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private csvService: CsvService,
        private managersService: ManagersService,
        private formBuilder: FormBuilder
    ) {
        this.selectedDateInterval = ORDERS_DATES_INTERVAL.ALL;
        this.rowForm = null;
        this.carriers = transports;
        this.rowsDetailsClickableColumns = [
            {
                columnId: 'document_link', // sub row col ID
                columnName: 'document_link'
            },
            {
                columnId: 'document_link', // sub row col ID
                columnName: 'is_deletable'
            }
        ];
    }

    async ngOnInit() {
        this.setColumnsOrder();
        this.initTranslation();
        await this.retrieveOrders();
        this.managersService.prepareCustomersAndProducts();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }
    private unsubscribe(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    getTitleWidth(): number {
        if (this.titleWidth === 0) {
            const titleHtmlElement = document.getElementById('page-title');
            if (! titleHtmlElement) {
                return 0;
            }
            // width + 5% (padding left is 5%)
            return titleHtmlElement.offsetWidth + window.innerWidth * 0.045;
        }
        return this.titleWidth;
    }

    // show / hide pg-upload component for CSV import
    switchDisplayUploadCsvComponent() {
        this.displayUploadCsvComponent = !this.displayUploadCsvComponent;
    }
    // show / hide pg-upload component for document PDF import
    switchDisplayUploadDocumentComponent(order_id?: string) {
        this.uploadingDocumentForOrder = !this.uploadingDocumentForOrder;
        this.orderToWhichAssociateDocuments = order_id ? order_id : undefined;
        this.importedDocumentFilename = undefined;
        this.importedDocumentForOrderContent = '';
    }

    // shall we display the table?
    emptyRows(): boolean {
        if (this.rows === null || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    async retrieveOrders() {
        this.orders = await this.getOrders();
        if (this.orders) {
            let currencies = this.orders.filter(o => {
                return o.currency &&
                    o.currency.length === 3 &&
                    this.currencies.findIndex(c => c.value.toUpperCase() === o.currency.toUpperCase());
            }).map(o => o.currency.toUpperCase());
            currencies = currencies.filter((c, index) => {
                return currencies.indexOf(c) === index;
            });
            await this.managersService.getMissingCurrencies(currencies);
        }
        if (this.orders !== null) {
            this.rows = [];
            this.orders.forEach((order) => {
                const customerOrder = Builder(CustomerOrder)
                                    .order_id(order.order_id)
                                    .invoice_id(order.invoice_id)
                                    .url(order.url)
                                    .order_date_hour(new Date(order.order_date_hour))
                                    .customer_firstname(order.customer_firstname)
                                    .customer_lastname(order.customer_lastname)
                                    .customer_address(order.customer_address)
                                    .customer_zipcode(order.customer_zipcode)
                                    .customer_city(order.customer_city)
                                    .customer_country(order.customer_country)
                                    .customer_documents(order.customer_documents)
                                    .buyer_firstname(order.buyer_firstname)
                                    .buyer_lastname(order.buyer_lastname)
                                    .buyer_address(order.buyer_address)
                                    .buyer_zipcode(order.buyer_zipcode)
                                    .buyer_city(order.buyer_city)
                                    .buyer_country(order.buyer_country)
                                    .agent_firstname(order.agent_firstname)
                                    .agent_lastname(order.agent_lastname)
                                    .agent_address(order.agent_address)
                                    .agent_zipcode(order.agent_zipcode)
                                    .agent_city(order.agent_city)
                                    .agent_country(order.agent_country)
                                    .departure_country(order.departure_country)
                                    .arrival_country(order.arrival_country)
                                    .products(order.products)
                                    .exporting_carrier(order.exporting_carrier)
                                    .shipping_carrier(order.shipping_carrier)
                                    .amount_products(order.amount_products)
                                    .amount_shipping(order.amount_shipping)
                                    .amount_shipping_duty(order.amount_shipping_duty)
                                    .amount_shipping_vat(order.amount_shipping_vat)
                                    .amount_shipping_specialtaxes(order.amount_shipping_specialtaxes)
                                    .amount_duty(order.amount_duty)
                                    .amount_vat(order.amount_vat)
                                    .amount_specialtaxes(order.amount_specialtaxes)
                                    .currency(order.currency)
                                    .order_statut(order.order_statut)
                                    .order_update_statut(new Date(order.order_update_statut))
                                    .order_payment_type(order.order_payment_type)
                                    .order_payment_date(new Date(order.order_payment_date))
                                    .category_of_item(order.category_of_item)
                                    .payment_type(order.payment_type)
                                    .incoterm(order.incoterm)
                                    .incoterm_delivery(order.incoterm_delivery)
                                    .build();

                this.rows.push(customerOrder);
                this.rowsDetails[0][customerOrder.order_id] = customerOrder.products;
                this.rowsDetails[1][customerOrder.order_id] = this.buildDocumentPdfRowDetail(customerOrder.customer_documents);
            });
            this.canceledOrdersIds = this.orders.filter(o => o.canceled).map(o => o.order_id);
        }
    }

    buildDocumentPdfRowDetail(documents: string[]): { document_link: string; is_deletable: string; }[] {
        if (documents && documents.length > 0) {
            return documents.map(doc => {
                return {
                    document_link: doc,
                    is_deletable: '<i class="fa fa-trash clickable" style="font-size: medium;"></i>'
                };
            });
        }
        return [];
    }

    async downloadPdf(documentType: string, documentId: string) {
        const pdfUrl = await this.getPdfUrl(documentType, documentId);
        if (pdfUrl) {
            saveAs(pdfUrl.signed_url.substring(0, pdfUrl.signed_url.indexOf('?Expires=')), `${documentType}_${documentId}.pdf`);
        }
    }

    async getPdfUrl(documentType: string, documentId: string): Promise<UserPdfUrl> {
        try {
            return (<UserPdfUrl> await this.http.get(
                `https://api.${environment.baseUrl}/v1/pdf/url/${documentType}/${documentId}`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format dates
        formatValues.push({
            columns: ['order_date_hour', 'order_payment_date', 'order_update_statut'],
            type: 'Date'
        });

        // prepare format currencies
        if (this.currencies) {
            formatValues.push({
                columns: ['currency'],
                type: this.currencies as { value: string; label: string; }[]
            });
        }

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['customer_country', 'buyer_country', 'agent_country', 'arrival_country', 'departure_country'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }

        // prepare format order status
        if (this.orderStatus) {
            formatValues.push({
                columns: ['order_statut'],
                type: this.orderStatus as { value: string; label: string; }[]
            });
        }

        this.formatColumnsValues = formatValues;
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.ordersManager;
        });

        // get currencies
        this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies;
            this.initFormatColumnsValues();
        });

        // get countries
        this.translate.get('countries').toPromise().then((all_countries) => {
            this.countries = all_countries.sort(Utils.compareByLabel);
            this.initFormatColumnsValues();
        });

        // get columns labels
        this.translate.get('ordersManager').toPromise().then(ordersManager => {
            this.columnsLabels = ordersManager.inputForm;
            this.rowsDetailsColumnsLabels = [ordersManager.inputForm.product, ordersManager.documents];
            // add necessary 'is_deletable' with empty label
            this.rowsDetailsColumnsLabels[1].is_deletable = '';

            this.rowsDetailsColumns = [Object.keys(this.rowsDetailsColumnsLabels[0]), Object.keys(this.rowsDetailsColumnsLabels[1])];
            this.categoryOfItemsTypes = ordersManager.category_of_item_types;
            this.orderStatus = ordersManager.order_status;
            this.datesIntervalChoice = ordersManager.dateSelection.dates;
            this.incoterms = ordersManager.incoterm;
            this.deliveryIncoterms = ordersManager.incoterm_delivery;

            this.availableActions = Object.values(ordersManager.actions);

            this.initFormatColumnsValues();
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.ordersManager;

            this.countries = event.translations['countries'].sort(Utils.compareByLabel);
            this.currencies = event.translations['currency_name'];
            this.columnsLabels = event.translations['ordersManager']['inputForm'];
            this.rowsDetailsColumnsLabels = [
                event.translations.ordersManager.inputForm.product,
                event.translations.ordersManager.documents
            ];
            // add necessary 'is_deletable' with empty label
            this.rowsDetailsColumnsLabels[1].is_deletable = '';

            this.datesIntervalChoice = event.translations.ordersManager.dateSelection.dates;
            this.orderStatus = event.translations.ordersManager.order_status;
            this.categoryOfItemsTypes = event.translations['ordersManager']['category_of_item_types'];
            this.availableActions = Object.values(event.translations['ordersManager'].actions);
            this.initFormatColumnsValues();
        });
    }

    getSelectedDateInterval(): string {
        const dateIntervalFound = this.datesIntervalChoice.find(d => d.value === this.selectedDateInterval);
        if (dateIntervalFound) {
            return dateIntervalFound.label;
        }
        return '';
    }

    getRemainingDatesInterval(): {label: string; value: string}[] {
        if (this.datesIntervalChoice) {
            return this.datesIntervalChoice.filter(d => d.value !== this.selectedDateInterval);
        }
        return [];
    }

    onSelectDateIntervalClick(value: string) {
        this.selectingDateInterval = false;
        this.selectedDateInterval = value;
        this.retrieveOrders();
    }

    onChangeDateIntervalClick() {
        this.selectingDateInterval = ! this.selectingDateInterval;
    }

    onDownloadSelectedRowsAsCsv() {
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV;
        this.onClaimSelectedRows();
    }
    onValidAction() {
        this.actionInProgress = true;
        this.selectedRowsNextAction = PRODUCTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION;
        this.onClaimSelectedRows();
    }
    async onExecuteAction() {
        switch (this.selectedAction) {
            case CustomerOrderAvailableActions.LANDED_COST:
                await this.processLandedCost();
                break;
            // generate docs for each order :
            // - export invoice
            // - CN23 if shipping_carrier is 'Laposte'
            // - Taxes invoice if incoterm_delivery is 'DAP'
            // - certificate of origin
            case CustomerOrderAvailableActions.GENERATE_DOCS:
                await this.generateOrdersDocuments();
                break;

            // generate taxes invoice for each order
            case CustomerOrderAvailableActions.GENERATE_TAXES_INVOICE:
                await this.generateOrdersTaxesInvoice();
                break;

            // remove some products, generate credit note & rectified export invoice for each order
            case CustomerOrderAvailableActions.REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE:
                await this.removeProductsAndGenerateCreditNote();
                break;

            // cancel orders, generate credit note for each order
            case CustomerOrderAvailableActions.DELETE_ORDERS_GENERATE_CREDIT_NOTE:
                await this.deleteOrdersAndGenerateCreditNote();
                break;

            default:
                console.log('Unknown action');
                break;
        }
    }

    private buildProcessRequests(orderIds: string[], maxPerRequest = 10): CustomerOrdersProcessRequest[] {
        const requests: CustomerOrdersProcessRequest[] = [];
        // all process except remove products and generate credit note process
        if (orderIds) {
            orderIds.forEach(id => {
                // max {maxPerRequest} orders by request
                if (requests.length === 0 || requests[requests.length - 1].orders_ids.length >= maxPerRequest) {
                    requests.push(
                        Builder(CustomerOrdersProcessRequest)
                        .orders_ids([])
                        .build()
                    );
                }
                requests[requests.length - 1].orders_ids.push(id);
            });

        // remove products and generate credit note process
        } else {
            const orderIdsRemoveProducts = Object.keys(this.processProductsToRemove);
            orderIdsRemoveProducts.forEach(id => {
                // max {maxPerRequest} orders by request
                if (requests.length === 0 || Object.keys(requests[requests.length - 1].orders_products).length >= maxPerRequest) {
                    requests.push(
                        Builder(CustomerOrdersProcessRequest)
                        .orders_products({})
                        .build()
                    );
                }
                requests[requests.length - 1].orders_products[id] = this.processProductsToRemove[id];
            });
        }
        return requests;
    }
    // the user has accepted to execute Landed Cost process before the process he wanted to execute
    async onExecuteLandedCostProcessForFailedOrders() {
        this.closeModal();
        const orderIds = [...this.ordersWithErrors.ordersWithMissingLandedCost];
        let requests = this.buildProcessRequests(orderIds, 10);
        await this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.LANDED_COST, 10, true);
        switch (this.selectedAction) {
            case CustomerOrderAvailableActions.GENERATE_DOCS:
                requests = this.buildProcessRequests(orderIds, 1);
                await this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.GENERATE_DOCS, 1);
                break;
            case CustomerOrderAvailableActions.GENERATE_TAXES_INVOICE:
                requests = this.buildProcessRequests(orderIds, 5);
                await this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.GENERATE_TAXES_INVOICE, 5);
                break;
            case CustomerOrderAvailableActions.REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE:
                requests = this.buildProcessRequests(orderIds, 5);
                await this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE, 5);
                break;
            case CustomerOrderAvailableActions.DELETE_ORDERS_GENERATE_CREDIT_NOTE:
                requests = this.buildProcessRequests(orderIds, 5);
                await this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.DELETE_ORDERS_GENERATE_CREDIT_NOTE, 5);
                break;
            default:
                break;
        }
    }
    processLandedCost() {
        const orderIds = this.ordersWithErrors && this.ordersWithErrors.ordersWithMissingRequiredFields.length > 0
                        ? [...this.ordersWithErrors.ordersWithMissingRequiredFields] // call after completing missing fields
                        : this.selectedRows.map(r => r.order_id); // first call
        const requests = this.buildProcessRequests(orderIds, 10);
        this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.LANDED_COST, 10);
    }
    generateOrdersDocuments() {
        const orderIds = this.ordersWithErrors && this.ordersWithErrors.ordersWithMissingRequiredFields.length > 0
                        ? [...this.ordersWithErrors.ordersWithMissingRequiredFields] // call after completing missing fields
                        : this.selectedRows.map(r => r.order_id); // first call
        const requests = this.buildProcessRequests(orderIds, 1);
        this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.GENERATE_DOCS, 1);
    }
    generateOrdersTaxesInvoice() {
        const orderIds = this.ordersWithErrors && this.ordersWithErrors.ordersWithMissingRequiredFields.length > 0
                        ? [...this.ordersWithErrors.ordersWithMissingRequiredFields] // call after completing missing fields
                        : this.selectedRows.map(r => r.order_id); // first call
        const requests = this.buildProcessRequests(orderIds, 5);
        this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.GENERATE_TAXES_INVOICE, 5);
    }
    deleteOrdersAndGenerateCreditNote() {
        const orderIds = this.ordersWithErrors && this.ordersWithErrors.ordersWithMissingRequiredFields.length > 0
                        ? [...this.ordersWithErrors.ordersWithMissingRequiredFields] // call after completing missing fields
                        : this.selectedRows.map(r => r.order_id); // first call
        const requests = this.buildProcessRequests(orderIds, 5);
        this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.DELETE_ORDERS_GENERATE_CREDIT_NOTE, 5);
    }
    removeProductsAndGenerateCreditNote() {
        this.processProductsToRemove = {};
        // display modal with products to remove only, start with the first order
        this.openModal(this.removeproducts, 'removeproducts', this.selectedRows[0].order_id);
    }
    // called once the user has selected all the products to remove
    executeRemoveProductsAndGenerateCreditNoteOnceSet() {
        const requests = this.buildProcessRequests(null, 5);
        this.callProcessAndHandleErrors(requests, CustomerOrdersServiceEnum.REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE, 5);
    }

    private async handleProcessRightResponse(processResponse: CustomerOrderProcessResponse[]) {
        for (let i = 0; i < processResponse.length; i++) {
            const index = this.rows.findIndex(r => r.order_id === processResponse[i].orderId);
            const orderIndex = this.orders.findIndex(r => r.order_id === processResponse[i].orderId);

            // get currency if missing
            if (
                processResponse[i].customerOrder.currency &&
                processResponse[i].customerOrder.currency.length === 3 &&
                this.currencies.findIndex(c => c.value.toUpperCase() === processResponse[i].customerOrder.currency.toUpperCase())
            ) {
                await this.managersService.getMissingCurrencies([processResponse[i].customerOrder.currency]);
            }

            const customerOrder = plainToClass(CustomerOrder, processResponse[i].customerOrder);
            if (index >= 0) {
                this.rows[index] = customerOrder.toRow();
                this.rowsDetails[0][customerOrder.order_id] = customerOrder.products;
                this.rowsDetails[1][customerOrder.order_id] = this.buildDocumentPdfRowDetail(customerOrder.customer_documents);
                // refresh the table
                this.rows = [...this.rows];
            }
            if (orderIndex >= 0) {
                this.orders[orderIndex] = customerOrder;
                if (customerOrder.canceled && ! this.canceledOrdersIds.includes(customerOrder.order_id)) {
                    this.canceledOrdersIds.push(customerOrder.order_id);
                }
            }

            // landed cost returns no doc to download
            if (this.selectedAction !== CustomerOrderAvailableActions.LANDED_COST) {
                this.prepareDocsToDownload(processResponse[i]);
            }
        }

    }
    private prepareDocsToDownload(processResponse: CustomerOrderProcessResponse) {
        // fill docsToDownload
        const docsTypes = Object.keys(processResponse);
        docsTypes.forEach(docType => {
            if (docType !== 'orderId' && docType !== 'customerOrder') {
                if (! this.docsToDownload[docType]) {
                    this.docsToDownload[docType] = [] as GeneratedDocContentWithOrderID[];
                }
                const docs = processResponse[docType] as GeneratedDocContentWithOrderID[];
                docs.forEach(doc => {
                    doc.order_id = processResponse.orderId;
                });
                this.docsToDownload[docType] = this.docsToDownload[docType].concat(docs);
            }
        });
    }
    // call the process and handle encountered errors (fill missing fields and call Landed Cost if necessary)
    private async callProcessAndHandleErrors(
        requests: CustomerOrdersProcessRequest[],
        processType: CustomerOrdersServiceEnum,
        maxPerRequest: number,
        doLandedCostProcessBeforeRetryingWantedProcess = false
    ) {
        this.ordersWithErrors = Builder(CustomerOrderProcessErrorResponse)
                                // if this.ordersWithErrors it means that we came back here after having filled the missing fields of each order
                                // so we must reuse the array of orders with missing landed cost
                                .ordersWithMissingLandedCost(
                                    this.ordersWithErrors && ! doLandedCostProcessBeforeRetryingWantedProcess
                                    ? this.ordersWithErrors.ordersWithMissingLandedCost
                                    : []
                                )
                                .ordersWithMissingRequiredFields([])
                                .rightOrdersAwaitingToDoTheProcess([])
                                .build();
        const total = requests.map(r => r.orders_ids ? r.orders_ids.length : Object.keys(r.orders_products).length).reduce((a, b) => a + b, 0);
        for (let i = 0; i < requests.length; i++) {
            const res = await this.callProcess(requests[i], processType);
            // unexpected error or user fields are missing
            if (! res) {
                // download generated docs if there are any
                await this.downloadGeneratedDocs();
                // reinitialize action vars then
                this.reinitializeActionVars();
                return;
            }
            // there are missing fields OR landed cost action is required
            if (res instanceof CustomerOrderProcessErrorResponse) {
                this.ordersWithErrors.ordersWithMissingLandedCost = this.ordersWithErrors.ordersWithMissingLandedCost
                                                                    .concat(res.ordersWithMissingLandedCost);
                this.ordersWithErrors.ordersWithMissingRequiredFields = this.ordersWithErrors.ordersWithMissingRequiredFields
                                                                    .concat(res.ordersWithMissingRequiredFields);
                this.ordersWithErrors.rightOrdersAwaitingToDoTheProcess = this.ordersWithErrors.rightOrdersAwaitingToDoTheProcess
                                                                    .concat(res.rightOrdersAwaitingToDoTheProcess);
            // right process response, handle it
            } else {
                // update order and prepare docs to download if there are any
                await this.handleProcessRightResponse(res);
                // tslint:disable-next-line: max-line-length
                const count = (i * maxPerRequest) + (requests[i].orders_ids ? requests[i].orders_ids.length : Object.keys(requests[i].orders_products).length);
                this._notification.displayMessage(this.translate.instant('ordersManager.processDoneCount', { count, total }), 'success', 3000);
            }
        }
        // call the process for right orders if there are any
        if (
            ! doLandedCostProcessBeforeRetryingWantedProcess &&
            (
                this.ordersWithErrors &&
                this.ordersWithErrors.rightOrdersAwaitingToDoTheProcess.length > 0
            )
        ) {
            const requestsRightOrders = this.buildProcessRequests(this.ordersWithErrors.rightOrdersAwaitingToDoTheProcess, maxPerRequest);
            for (let i = 0; i < requests.length; i++) {
                const res = await this.callProcess(requestsRightOrders[i], processType);
                // unexpected error or not enough credits or user fields are missing
                if (! res) {
                    // download generated docs if there are any
                    await this.downloadGeneratedDocs();
                    // reinitialize action vars then
                    this.reinitializeActionVars();
                    return;
                }
                if (Array.isArray(res)) {
                    // update order and prepare docs to download if there are any
                    await this.handleProcessRightResponse(res);
                }
            }
            this.ordersWithErrors.rightOrdersAwaitingToDoTheProcess = [];
        }

        // handle process errors
        this.handleProcessErrors();
    }
    // returns process result, or orders ids with errors if there is any error
    private async callProcess(
        body: CustomerOrdersProcessRequest,
        processType: CustomerOrdersServiceEnum,
        handleErrors = true
    ): Promise<CustomerOrderProcessResponse[] | CustomerOrderProcessErrorResponse> {
        try {
            const res = (
                await this.http.post(`https://api.${environment.baseUrl}/v1/customer/orders/process/${processType}`, body).toPromise()
            ) as CustomerOrderProcessResponse[];
            console.log('RESPONSE = ');
            console.log(res);
            return res;
        } catch (errorResponse) {
            console.log('ERROR RESPONSE = ');
            console.log(errorResponse);
            if (handleErrors) {
                if (errorResponse.error && errorResponse.error && errorResponse.error.code === 'MISSING_FIELDS') {
                    // get error details which are string arrays of the missing fields (concerning user account and/or order content)
                    const details = errorResponse.error.details;
                    if (details.findIndex(error => error.parameter.name === 'user account') >= 0) {
                        this.openModal(this.userFieldsMissing, 'userFieldsMissing');
                    } else {
                        const errorRes = Builder(CustomerOrderProcessErrorResponse)
                                        .ordersWithMissingLandedCost([])
                                        .ordersWithMissingRequiredFields([])
                                        .rightOrdersAwaitingToDoTheProcess([])
                                        .build();

                        details.forEach(detail => {
                            if (detail.causes.findIndex(cause => cause !== 'landed_cost_timestamp') >= 0) {
                                errorRes.ordersWithMissingRequiredFields.push(detail.parameter.value);
                            } else if (detail.causes.includes('landed_cost_timestamp')) {
                                errorRes.ordersWithMissingLandedCost.push(detail.parameter.value);
                            }
                        });

                        // define right orders with no errors, we must call the process for all these orders before handling errors
                        if (body.orders_ids) {
                            errorRes.rightOrdersAwaitingToDoTheProcess = body.orders_ids.filter(o => {
                                return ! errorRes.ordersWithMissingLandedCost.includes(o) &&
                                        ! errorRes.ordersWithMissingRequiredFields.includes(o);
                            });
                        } else {
                            errorRes.rightOrdersAwaitingToDoTheProcess = Object.keys(body.orders_products).filter(o => {
                                return ! errorRes.ordersWithMissingLandedCost.includes(o) &&
                                        ! errorRes.ordersWithMissingRequiredFields.includes(o);
                            });
                        }
                        return errorRes;
                    }
                } else {
                    console.log('ERROR : ' + JSON.stringify(errorResponse));
                    this._notification.displayMessage(
                        this._notification.buildTransiteoErrorMessage(errorResponse.error, errorResponse.statusText),
                        'danger',
                        10000
                    );
                }
            }
            return null;
        }
    }
    // handle process errors : display modals to the user in order to complete its orders or to execute Landed Cost process
    private async handleProcessErrors() {
        // first, download the generated docs
        this.downloadGeneratedDocs();
        // then handle errors if there are any
        if (this.ordersWithErrors.ordersWithMissingRequiredFields.length > 0) {
            this.openModal(this.editrow, 'orderFieldsMissing', this.ordersWithErrors.ordersWithMissingRequiredFields[0]);
        } else if (this.ordersWithErrors.ordersWithMissingLandedCost.length > 0) {
            this.openModal(this.landedCostMissing, 'landedCostMissing');
        } else {
            // no more error to handle, reinitialize action vars now
            this.reinitializeActionVars();
        }
    }

    selectAction(type: number) {
        this.selectedAction = type;
    }

    onDisplayFilters() {
        if (this.displayFilters) {
            this.displayFilters = false;
            return;
        }
        this.filters = [];

        // customers
        const customers = this.managersService.getCustomers();
        const customersOptions = this.managersService.getOptionsCustomers();
        const selectOptions: {label: string, value: string}[] = [];
        for (let i = 0; i < customers.length; i++) {
            selectOptions.push({
                value: customers[i].website,
                label: customersOptions[i]
            });
        }
        this.filters.push([]);
        this.filters[0].push(
            Builder(FilterTableField)
            .id('url')
            .label('ordersManager.filters.customer')
            .placeholder('ordersManager.filters.customer_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT_MULTIPLE)
            .selectOptions(selectOptions.slice())
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'url').value : null)
            .build()
        );

        // order id
        this.filters.push([]);
        this.filters[1].push(
            Builder(FilterTableField)
            .id('order_id')
            .label('ordersManager.filters.order_id')
            .placeholder('ordersManager.filters.order_id_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT_MULTIPLE)
            .selectOptions(this.rows.map(r => {
                return {
                    value: r.order_id,
                    label: r.order_id
                };
            }))
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'order_id').value : null)
            .build()
        );

        // date start / end
        this.filters.push([]);
        this.filters[2].push(
            Builder(FilterTableField)
            .id('order_date_hour__min')
            .label('ordersManager.filters.date_start')
            .placeholderDate('ordersManager.filters.date_start_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.DATE)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'order_date_hour__min').value : null)
            .build()
        );
        this.filters[2].push(
            Builder(FilterTableField)
            .id('order_date_hour__max')
            .label('ordersManager.filters.date_end')
            .placeholderDate('ordersManager.filters.date_end_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.DATE)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'order_date_hour__max').value : null)
            .build()
        );

        // Status
        this.filters.push([]);
        selectOptions.splice(0, selectOptions.length);
        let uniqueStatus = this.rows.map(r => r.order_statut);
        uniqueStatus = uniqueStatus.filter((c, index) => {
            return uniqueStatus.indexOf(c) === index;
        });
        this.filters[3].push(
            Builder(FilterTableField)
            .id('order_statut')
            .label('ordersManager.filters.status')
            .placeholder('ordersManager.filters.status_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT_MULTIPLE)
            .selectOptions(uniqueStatus.map(s => {
                return {
                    value: s,
                    label: s
                };
            }))
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'order_statut').value : null)
            .build()
        );

        // Amount
        this.filters.push([]);
        this.filters[4].push(
            Builder(FilterTableField)
            .id('amount_products__min')
            .label('ordersManager.filters.amount_min')
            .placeholder('ordersManager.filters.amount_min_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.FLOAT_TWO_DECIMALS)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'amount_products__min').value : null)
            .build()
        );
        this.filters[4].push(
            Builder(FilterTableField)
            .id('amount_products__max')
            .label('ordersManager.filters.amount_max')
            .placeholder('ordersManager.filters.amount_max_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.FLOAT_TWO_DECIMALS)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'amount_products__max').value : null)
            .build()
        );

        // Carriers
        this.filters.push([]);
        this.filters[5].push(
            Builder(FilterTableField)
            .id('shipping_carrier')
            .label('ordersManager.filters.carrier')
            .placeholder('ordersManager.filters.carrier_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT)
            .selectOptions(this.carriers)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'shipping_carrier').value : null)
            .build()
        );

        // Departure Country
        this.filters.push([]);
        this.filters[6].push(
            Builder(FilterTableField)
            .id('departure_country')
            .label('ordersManager.filters.country_departure')
            .placeholder('ordersManager.filters.country_departure_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT)
            .selectOptions(this.countries)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'departure_country').value : null)
            .build()
        );

        // Arrival Country
        this.filters.push([]);
        this.filters[7].push(
            Builder(FilterTableField)
            .id('arrival_country')
            .label('ordersManager.filters.country_arrival')
            .placeholder('ordersManager.filters.country_arrival_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT)
            .selectOptions(this.countries)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'arrival_country').value : null)
            .build()
        );

        // Currency
        this.filters.push([]);
        this.filters[8].push(
            Builder(FilterTableField)
            .id('currency')
            .label('ordersManager.filters.currency')
            .placeholder('ordersManager.filters.currency_placeholder')
            .type(FILTER_TABLE_FIELD_TYPES.SELECT)
            .selectOptions(this.currencies)
            .value(this.arrayFilters.length > 0 ? this.arrayFilters.find(f => f.id === 'currency').value : null)
            .build()
        );

        this.displayFilters = true;
    }

    validateFilter(filtersValidated: FilterTableField[]) {
        this.arrayFilters = filtersValidated;
        this.displayFilters = false;
    }

    setColumnsOrder() {
        this.columnsOrder = [
            'order_id',
            'invoice_id',
            'amount_products',
            'amount_shipping',
            'amount_shipping_duty',
            'amount_shipping_vat',
            'amount_shipping_specialtaxes',
            'amount_duty',
            'amount_vat',
            'amount_specialtaxes',
            'currency',
            'url',
            'order_date_hour',
            'customer_firstname',
            'customer_lastname',
            'customer_address',
            'customer_zipcode',
            'customer_city',
            'customer_country',
            'buyer_firstname',
            'buyer_lastname',
            'buyer_address',
            'buyer_zipcode',
            'buyer_city',
            'buyer_country',
            'agent_firstname',
            'agent_lastname',
            'agent_address',
            'agent_zipcode',
            'agent_city',
            'agent_country',
            'order_payment_type',
            'order_payment_date',
            'departure_country',
            'arrival_country',
            'exporting_carrier',
            'shipping_carrier',
            'incoterm',
            'incoterm_delivery',
            'category_of_item',
            'payment_type',
            'order_statut',
            'order_update_statut'
        ];
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return '';
        }
    }

    getColumnMarginLeft(prop: string): number {
        if (prop === this.frozenColumn) {
            return 0;
        }
        const propIndex: number = this.columnsOrder.findIndex(col => col === prop);
        return (-15 + (propIndex / this.columnsOrder.length) * 35);
    }

    csvFileChanged(event) {
        if (event.file.status === 'removed') {
            this.expectedCsvContent = null;
        }
    }

    beforeUploadDocumentForOrder = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file as any);
        this.cleanFiles(file);
        reader.onloadend = async (e) => {
            this.importedDocumentFilename = file.name.replace(/\.pdf/g, '');
            let i = 0;
            let index = this.rowsDetails[1][this.orderToWhichAssociateDocuments].findIndex(doc => doc.document_link === this.importedDocumentFilename);
            while (index >= 0) {
                i += 1;
                index = this.rowsDetails[1][this.orderToWhichAssociateDocuments]
                        .findIndex(doc => doc.document_link === `${this.importedDocumentFilename}_${i}`);
            }
            if (i > 0) {
                this.importedDocumentFilename += `_${i}`;
            }
            this.importedDocumentForOrderContent = reader.result as string;
        };
        return true;
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            this.expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = this.expectedCsvContent.toExpectedCsvContent();

            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {// remove first line csv;
                const headerLine = csvRecordsArray.shift();
                const headerLineLength = headerLine.split(';').length;

                // check for wrong lines
                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    // not same columns number
                    if (headerLineLength !== line.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
                        return true;
                    }
                    return false;
                });
                if (wrongLines.length > 0) {
                    this.removeFile(file);
                    return;
                }

                const firstLine: string[] = csvRecordsArray[0].split(';');
                const arrayOfMandatoryFields = [
                    'product_1_sku',
                    'product_1_quantity',
                    'product_1_unit_price',
                    'product_1_unit_price_currency',
                    'order_id',
                    'invoice_id',
                    'url',
                    'order_date_hour',
                    'customer_firstname',
                    'customer_lastname',
                    'customer_address',
                    'customer_zipcode',
                    'customer_city',
                    'customer_country',
                    'buyer_firstname',
                    'buyer_lastname',
                    'buyer_address',
                    'buyer_zipcode',
                    'buyer_city',
                    'buyer_country',
                    'agent_firstname',
                    'agent_lastname',
                    'agent_address',
                    'agent_zipcode',
                    'agent_city',
                    'agent_country',
                    'order_payment_type',
                    'order_payment_date',
                    'departure_country',
                    'arrival_country',
                    'exporting_carrier',
                    'shipping_carrier',
                    'category_of_item',
                    'payment_type',
                    'amount_products',
                    'amount_shipping',
                    /*'amount_duty',
                    'amount_vat',
                    'amount_specialtaxes',*/
                    'currency',
                    'order_statut'
                ];
                for (let i = 0; i < arrayOfMandatoryFields.length; i++) {
                    if (!firstLine.includes(arrayOfMandatoryFields[i])) {
                        this.externalCsvFields = this.expectedCsvContent.getHeaders();
                        this.csvModelFields = this.buildCsvModelFields();
                        this.onDisplayCsvMapper();
                        /*this._notification.displayMessage(`Line 1 : ${arrayOfMandatoryFields[i]} ${this.messages.isMandatory}`, 'danger');*/
                        return true;
                    }
                }
            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }
    buildCsvModelFields(): CsvFieldToSet[] {
        const modelFields: CsvFieldToSet[] = [];
        const expectedCsvFields = {
            order_id: {
                isRequired: true
            },
            invoice_id: {
                isRequired: false
            },
            product: {
                fields: {
                    sku: {
                        isRequired: true
                    },
                    quantity: {
                        isRequired: true
                    },
                    unit_price: {
                        isRequired: true
                    },
                    unit_price_currency: {
                        isRequired: true
                    }
                }
            },
            url: {
                isRequired: false
            },
            order_date_hour: {
                isRequired: true
            },
            customer_firstname: {
                isRequired: false
            },
            customer_lastname: {
                isRequired: false
            },
            customer_address: {
                isRequired: false
            },
            customer_zipcode: {
                isRequired: false
            },
            customer_city: {
                isRequired: false
            },
            customer_country: {
                isRequired: false
            },
            buyer_firstname: {
                isRequired: false
            },
            buyer_lastname: {
                isRequired: false
            },
            buyer_address: {
                isRequired: false
            },
            buyer_zipcode: {
                isRequired: false
            },
            buyer_city: {
                isRequired: false
            },
            buyer_country: {
                isRequired: false
            },
            agent_firstname: {
                isRequired: false
            },
            agent_lastname: {
                isRequired: false
            },
            agent_address: {
                isRequired: false
            },
            agent_zipcode: {
                isRequired: false
            },
            agent_city: {
                isRequired: false
            },
            agent_country: {
                isRequired: false
            },
            order_payment_type: {
                isRequired: false
            },
            order_payment_date: {
                isRequired: true
            },
            departure_country: {
                isRequired: true
            },
            arrival_country: {
                isRequired: true
            },
            exporting_carrier: {
                isRequired: false
            },
            shipping_carrier: {
                isRequired: false
            },
            category_of_item: {
                isRequired: false
            },
            payment_type: {
                isRequired: false
            },
            amount_products: {
                isRequired: true
            },
            amount_shipping: {
                isRequired: false
            },
            /*amount_duty: {
                isRequired: false
            },
            amount_vat: {
                isRequired: false
            },
            amount_specialtaxes: {
                isRequired: false
            },*/
            currency: {
                isRequired: true
            },
            order_statut: {
                isRequired: true
            },
            incoterm: {
                isRequired: false
            },
        };
        const keys = Object.keys(expectedCsvFields);

        keys.forEach(f => {
            // array
            if (expectedCsvFields[f].fields) {
                const arrKeys = Object.keys(expectedCsvFields[f].fields);
                const arrFields: CsvFieldToSet[][] = [];
                arrFields.push([]);
                arrKeys.forEach(arrField => {
                    arrFields[0].push(
                        Builder(CsvFieldToSet)
                        .id(arrField)
                        .externalField('')
                        .isRequired(expectedCsvFields[f].fields[arrField].isRequired)
                        .label(this.translate.instant(`csvMapper.fieldsLabels.${arrField}`))
                        .build()
                    );
                });
                modelFields.push(
                    Builder(CsvFieldToSet)
                    .id(f)
                    .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                    .fields(arrFields)
                    .build()
                );
            // field
            } else {
                modelFields.push(
                    Builder(CsvFieldToSet)
                    .id(f)
                    .externalField('')
                    .isRequired(expectedCsvFields[f].isRequired)
                    .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                    .build()
                );
            }
        });
        return modelFields;
    }
    onDisplayCsvMapper() {
        this.displayCsvMapper.next();
    }
    onCsvExternalMapped(mappedFields: CsvFieldToSet[]) {
        if (! mappedFields) {
            this.expectedCsvContent = null;
        } else {
            this.expectedCsvContent.modifyHeadersWithMappedFields(mappedFields);
        }
    }

    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.expectedCsvContent = null;
        return false;
    }

    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.expectedCsvContent = null;
    }

    async validateCSVImport() {
        const rows = this.expectedCsvContent.getContent();
        // hide the pg-upload component now
        this.switchDisplayUploadCsvComponent();
        const csvArrayLength = rows.length;
        const labels: string[] = rows.shift().split(';');
        let rowIsValid: boolean;
        let line = 2;
        // product field regex
        const productRegexSku = new RegExp('^product_([0-9]+)_sku$');
        const productRegexQuantity = new RegExp('^product_([0-9]+)_quantity$');
        const productRegexUnitPrice = new RegExp('^product_([0-9]+)_unit_price$');
        const productRegexUnitPriceCurrrency = new RegExp('^product_([0-9]+)_unit_price_currency$');
        const isoDateRegex = new RegExp('^[0-9]{4}(-[0-9]{2}){2}T[0-9]{2}(\:[0-9]{2}){2}(\.[0-9]{3})?Z$');
        rows.forEach(async record => {
            // parse each column and check if the values are valid
            const cols: any[] = record.split(';');
            let i = 0;
            const row = {};
            rowIsValid = true;
            labels.forEach((label) => {
                if (rowIsValid) {
                    switch (label) {
                        case 'order_id':
                            if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : order_id ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'invoice_id':
                            // nothing to check as it is not mandatory
                            break;
                        case 'url':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_firstname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_lastname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_address':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_zipcode':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_city':
                            // nothing to check as it is not mandatory
                            break;
                        case 'customer_country':
                            // if set, must be iso3 country
                            if (cols[i].length > 0 && (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0)) {
                                this._notification.displayMessage(`Line ${line} : customer_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'buyer_firstname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'buyer_lastname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'buyer_address':
                            // nothing to check as it is not mandatory
                            break;
                        case 'buyer_zipcode':
                            // nothing to check as it is not mandatory
                            break;
                        case 'buyer_city':
                            // nothing to check as it is not mandatory
                            break;
                        case 'buyer_country':
                            // if set, must be iso3 country
                            if (cols[i].length > 0 && (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0)) {
                                this._notification.displayMessage(`Line ${line} : buyer_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'agent_firstname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'agent_lastname':
                            // nothing to check as it is not mandatory
                            break;
                        case 'agent_address':
                            // nothing to check as it is not mandatory
                            break;
                        case 'agent_zipcode':
                            // nothing to check as it is not mandatory
                            break;
                        case 'agent_city':
                            // nothing to check as it is not mandatory
                            break;
                        case 'agent_country':
                            // if set, must be iso3 country
                            if (cols[i].length > 0 && (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0)) {
                                this._notification.displayMessage(`Line ${line} : agent_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'order_payment_type':
                            // nothing to check as it is not mandatory
                            break;
                        case 'order_payment_date':
                            if (cols[i].length > 0) {
                                if (isoDateRegex.test(cols[i])) {
                                    const colDate = new Date(cols[i]);
                                    cols[i] = colDate.getTime();
                                } else {
                                    this._notification.displayMessage(`Line ${line} : order_payment_date ${this.messages.mustBeDate}`, 'danger');
                                    rowIsValid = false;
                                }
                            }
                            break;
                        case 'exporting_carrier':
                            // nothing to check as it is not mandatory
                            break;
                        case 'shipping_carrier':
                            // nothing to check as it is not mandatory
                            break;
                        case 'category_of_item':
                            // nothing to check as it is not mandatory
                            break;
                        case 'payment_type':
                            // nothing to check as it is not mandatory
                            break;
                        case 'incoterm':
                            // nothing to check as it is not mandatory
                            break;
                        case 'order_statut':
                            if (cols[i].length === 0) {
                                this._notification.displayMessage(`Line ${line} : order_statut ${this.messages.atLeastOneCharacter}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'order_date_hour':
                            if (cols[i].length > 0) {
                                if (isoDateRegex.test(cols[i])) {
                                    const colDate = new Date(cols[i]);
                                    cols[i] = colDate.getTime();
                                } else {
                                    this._notification.displayMessage(`Line ${line} : order_date_hour ${this.messages.mustBeDate}`, 'danger');
                                    rowIsValid = false;
                                }
                            } else {
                                this._notification.displayMessage(`Line ${line} : order_date_hour ${this.messages.isMandatory}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'departure_country':
                            if (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(`Line ${line} : departure_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'arrival_country':
                            if (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(`Line ${line} : arrival_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'amount_products':
                            // wrong number
                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'amount_shipping':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        /*case 'amount_duty':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'amount_vat':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;
                        case 'amount_specialtaxes':
                            if (cols[i].length === 0) {
                                // nothing to do as it is not a mandatory field
                            } else if (isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            } else {
                                cols[i] = parseFloat(cols[i]).toFixed(2);
                            }
                            break;*/
                        case 'currency':
                            // the currency is wrong
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : currency ${this.messages.mustBeISO4217}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            }
                            break;
                        default:
                            if (
                                !productRegexSku.test(label) &&
                                !productRegexQuantity.test(label) &&
                                !productRegexUnitPrice.test(label) &&
                                !productRegexUnitPriceCurrrency.test(label)
                            ) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.fieldNotValid}`,
                                    'danger'
                                );
                                rowIsValid = false;

                            // right product label
                            } else {
                                // check that all product's fields are here
                                const pn = label.split('_')[1];
                                if (
                                    !labels.includes(`product_${pn}_sku`) ||
                                    !labels.includes(`product_${pn}_quantity`) ||
                                    !labels.includes(`product_${pn}_unit_price`) ||
                                    !labels.includes(`product_${pn}_unit_price_currency`)
                                ) {
                                    this._notification.displayMessage(
                                        `Line ${line} : product_${pn} ${this.messages.productNotFull}`,
                                        'danger'
                                    );
                                    rowIsValid = false;
                                } else {
                                    switch (label) {
                                        case `product_${pn}_sku`:
                                            if (cols[i].length === 0) {
                                                this._notification.displayMessage(`Line ${line} : ${label} ${this.messages.atLeastOneCharacter}`, 'danger');
                                                rowIsValid = false;
                                            }
                                            break;
                                        case `product_${pn}_quantity`:
                                            // wrong number
                                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                                this._notification.displayMessage(
                                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                                    'danger'
                                                );
                                                rowIsValid = false;
                                            } else {
                                                cols[i] = parseInt(cols[i], 10).toFixed(0);
                                            }
                                            break;
                                        case `product_${pn}_unit_price`:
                                            // wrong number
                                            if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) <= 0) {
                                                this._notification.displayMessage(
                                                    `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                                    'danger'
                                                );
                                                rowIsValid = false;
                                            } else {
                                                cols[i] = parseFloat(cols[i]).toFixed(2);
                                            }
                                            break;
                                        case `product_${pn}_unit_price_currency`:
                                            // the currency is wrong
                                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                                this._notification.displayMessage(
                                                    `Line ${line} : product_${pn}_unit_price_currency ${this.messages.mustBeISO4217}`,
                                                    'danger'
                                                );
                                                rowIsValid = false;
                                            }
                                            break;
                                        default:
                                            // we shall never go here
                                            break;
                                    }
                                }
                            }
                            break;
                    }
                }
                // append label and value in row ONLY IF value is not null
                if (cols[i] !== null) {
                    row[label] = cols[i];
                }
                i += 1;
            });
            if (rowIsValid) {
                // valid the row
                await this.onValidRow(row);
            }
            if (line === csvArrayLength) {
                this.expectedCsvContent = null;
            }
            line += 1;
        });
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv() {
        if (this.selectedRows.length > 0) {
            const csvResponse = this.selectedRows;
            csvResponse.forEach(row => {
                if (this.rowsDetails && this.rowsDetails[0] && this.rowsDetails[0][row.order_id]) {
                    for (let i = 0; i < this.rowsDetails[0][row.order_id].length; i++) {
                        row[`product_${i + 1}_sku`] = this.rowsDetails[0][row.order_id][i].sku;
                        row[`product_${i + 1}_quantity`] = this.rowsDetails[0][row.order_id][i].quantity;
                        row[`product_${i + 1}_unit_price`] = this.rowsDetails[0][row.order_id][i].unit_price;
                        row[`product_${i + 1}_unit_price_currency`] = this.rowsDetails[0][row.order_id][i].unit_price_currency;
                        row[`product_${i + 1}_amount_duty`] = this.rowsDetails[0][row.order_id][i].amount_duty;
                        row[`product_${i + 1}_amount_vat`] = this.rowsDetails[0][row.order_id][i].amount_vat;
                        row[`product_${i + 1}_amount_specialtaxes`] = this.rowsDetails[0][row.order_id][i].amount_specialtaxes;
                    }
                }
                delete row.products;
            });
            const csvFileName = 'my_sales';
            return this.csvService.downloadFile(csvResponse, csvFileName);
        }
    }

    onClaimSelectedRows() {
        this.requestSelectedRowsSubject.next();
    }
    onReceiveSelectedRows(rowsIds: string[]) {
        this.selectedRows = this.rows.filter(r => rowsIds.includes(r.order_id));
        switch (this.selectedRowsNextAction) {
            case PRODUCTS_SELECTED_ROWS_ACTIONS.DOWNLOAD_CSV:
                if (this.selectedRows.length > 0) {
                    this.downloadCsv();
                }
                break;
            case PRODUCTS_SELECTED_ROWS_ACTIONS.EXECUTE_ACTION:
                if (this.selectedRows.length > 0) {
                    this.onExecuteAction();
                } else {
                    this.reinitializeActionVars();
                }
                break;
            default:
                break;
        }
    }

    openModal(template: TemplateRef<any>, type: string, id?: string) {
        if (this.rows) {
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            if (type === 'addrow') {
                this.initRowForm(null);
            } else if (type !== 'userFieldsMissing' && type !== 'landedCostMissing') {
                const foundRow = this.rows.find(r => r.order_id === id);
                if (foundRow) {
                    if (
                        type === 'editrow' ||
                        type === 'displayrow' ||
                        type === 'removeproducts'
                    ) {
                        if (type === 'removeproducts') {
                            this.initRowForm(foundRow, true);
                        } else {
                            this.initRowForm(foundRow);
                        }
                    } else if (type === 'orderFieldsMissing') {
                        this.missingOrderFieldsForAction = true;
                        this.initRowForm(foundRow);
                    } else {
                        this.orderToDeleteId = id;
                    }
                }
            }
            this.modalRef = this.modalService.show(template, config);
        }
    }

    private reinitializeActionVars(): void {
        this.actionInProgress = false;
        this.processProductsToRemove = undefined;
        this.docsToDownload = {};
        this.selectedRowsNextAction = undefined;
        this.ordersWithErrors = undefined;
        this.missingOrderFieldsForAction = false;
        this.initialProductsNumber = undefined;
    }

    closeModal(freeAction = false) {
        this.formIsInvalid = false;
        this.closingModal = true;
        this.isEditing = false;
        if (freeAction) {
            this.reinitializeActionVars();
        }
        this.displayRowOnly = false;
        this.modalRef.hide();
    }

    onDisplayRow(orderId: string) {
        this.displayRowOnly = true;
        this.openModal(this.editrow, 'displayrow', orderId);
    }
    onEditRow(orderId: string) {
        this.displayRowOnly = false;
        this.openModal(this.editrow, 'editrow', orderId);
    }
    onDeleteRow(orderId: string) {
        this.openModal(this.confirmDeleteRow, 'deleterow', orderId);
    }
    onDeleteRows(selectedRows: string[]) {
        if (this.ordersToDelete.length === 0) {
            this.ordersToDelete = selectedRows;
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            this.modalRef = this.modalService.show(this.confirmDeleteRows, config);
        }
    }
    onCancelDeleteRows() {
        this.ordersToDelete = [];
        this.closeModal();
    }

    async onValidRowDeletion() {
        this.closeModal();
        await this.deleteOrder(this.orderToDeleteId);
        this.orderToDeleteId = null;
        this.ordersHaveBeenDeleted();
    }
    // delete orders 10 by 10
    async onValidRowsDeletion() {
        this.closeModal();

        let allSelectedOrdersDeleted = true;
        const ordersIds: string[][] = [];
        ordersIds.push([]);
        this.ordersToDelete.forEach(orderId => {
            if (ordersIds[ordersIds.length - 1].length === 10) {
                ordersIds.push([]);
            }
            ordersIds[ordersIds.length - 1].push(orderId);
        });

        for (let i = 0; i < ordersIds.length; i++) {
            const promises = await Promise.all(ordersIds[i].map(o => this.deleteOrder(o)));
            if (promises.includes(false)) {
                allSelectedOrdersDeleted = false;
            }
        }

        this.ordersHaveBeenDeleted();

        if (! allSelectedOrdersDeleted) {
            this._notification.displayMessage(this.messages.someRowsNotDeleted, 'warning');
        } else {
            this._notification.displayMessage(this.messages.rowsDeleted, 'success');
        }
    }

    initRowForm(row: any, removingProductsProcess = false) {
        // edit row
        if (row) {
            this.isEditing = true;
            this.rowForm = this.formBuilder.group({
                order_id: [row.order_id, Validators.required],
                invoice_id: [row.invoice_id],
                order_date_hour: [row.order_date_hour, Validators.required],
                url: [row.url, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
                customer_firstname: [row.customer_firstname],
                customer_lastname: [row.customer_lastname],
                customer_address: [row.customer_address],
                customer_zipcode: [row.customer_zipcode],
                customer_city: [row.customer_city],
                customer_country: [row.customer_country],
                buyer_firstname: [row.buyer_firstname],
                buyer_lastname: [row.buyer_lastname],
                buyer_address: [row.buyer_address],
                buyer_zipcode: [row.buyer_zipcode],
                buyer_city: [row.buyer_city],
                buyer_country: [row.buyer_country],
                agent_firstname: [row.agent_firstname],
                agent_lastname: [row.agent_lastname],
                agent_address: [row.agent_address],
                agent_zipcode: [row.agent_zipcode],
                agent_city: [row.agent_city],
                agent_country: [row.agent_country],
                departure_country: [row.departure_country],
                arrival_country: [row.arrival_country, Validators.required],
                exporting_carrier: [row.exporting_carrier],
                shipping_carrier: [row.shipping_carrier],
                amount_products: [row.amount_products],
                amount_shipping: [row.amount_shipping, [Validators.required, Validators.min(0)]],
                amount_shipping_duty: [row.amount_shipping_duty],
                amount_shipping_vat: [row.amount_shipping_vat],
                amount_shipping_specialtaxes: [row.amount_shipping_specialtaxes],
                amount_duty: [row.amount_duty],
                amount_vat: [row.amount_vat],
                amount_specialtaxes: [row.amount_specialtaxes],
                currency: [row.currency, Validators.required],
                order_statut: [row.order_statut, Validators.required],
                order_payment_type: [row.order_payment_type],
                order_payment_date: [row.order_payment_date],
                category_of_item: [this.categoryOfItemsInAvailableTypes(row.category_of_item) ? row.category_of_item : 'Other'],
                other_category_of_item: [
                    this.categoryOfItemsInAvailableTypes(row.category_of_item) ? null : row.category_of_item,
                    this.categoryOfItemsInAvailableTypes(row.category_of_item) ? [] : [Validators.required]
                ],
                payment_type: [row.payment_type],
                incoterm: [row.incoterm],
                incoterm_delivery: [row.incoterm_delivery],
                products: this.formBuilder.array([])
            });
            this.rowsDetails[0][row.order_id].forEach(p => {
                this.onAddProduct(<OrderProduct> p);
            });
            if (removingProductsProcess) {
                this.initialProductsNumber = this.rowsDetails[0][row.order_id].length;
            }

            if (this.missingOrderFieldsForAction) {
                this.rowForm.get('invoice_id').setValidators([Validators.required]);
                this.rowForm.get('customer_firstname').setValidators([Validators.required]);
                this.rowForm.get('customer_lastname').setValidators([Validators.required]);
                this.rowForm.get('customer_address').setValidators([Validators.required]);
                this.rowForm.get('customer_country').setValidators([Validators.required]);
                this.rowForm.get('customer_city').setValidators([Validators.required]);
                this.rowForm.get('customer_zipcode').setValidators([Validators.required]);
                /*this.rowForm.get('buyer_firstname').setValidators([Validators.required]);
                this.rowForm.get('buyer_lastname').setValidators([Validators.required]);
                this.rowForm.get('buyer_zipcode').setValidators([Validators.required]);
                this.rowForm.get('buyer_city').setValidators([Validators.required]);
                this.rowForm.get('buyer_country').setValidators([Validators.required]);
                this.rowForm.get('buyer_address').setValidators([Validators.required]);
                /*this.rowForm.get('agent_firstname').setValidators([Validators.required]);
                this.rowForm.get('agent_lastname').setValidators([Validators.required]);
                this.rowForm.get('agent_zipcode').setValidators([Validators.required]);
                this.rowForm.get('agent_city').setValidators([Validators.required]);
                this.rowForm.get('agent_country').setValidators([Validators.required]);
                this.rowForm.get('agent_address').setValidators([Validators.required]);*/
                this.rowForm.get('shipping_carrier').setValidators([Validators.required]);
                this.rowForm.get('category_of_item').setValidators([Validators.required]);
            }

        // new row
        } else {
            this.rowForm = this.formBuilder.group({
                order_id: ['', Validators.required],
                invoice_id: [''],
                order_date_hour: [new Date(), Validators.required],
                url: [''],
                customer_firstname: [''],
                customer_lastname: [''],
                customer_address: [''],
                customer_zipcode: [''],
                customer_city: [''],
                customer_country: [''],
                buyer_firstname: [''],
                buyer_lastname: [''],
                buyer_address: [''],
                buyer_zipcode: [''],
                buyer_city: [''],
                buyer_country: [''],
                agent_firstname: [''],
                agent_lastname: [''],
                agent_address: [''],
                agent_zipcode: [''],
                agent_city: [''],
                agent_country: [''],
                departure_country: [''],
                arrival_country: ['', Validators.required],
                exporting_carrier: [''],
                shipping_carrier: [''],
                amount_products: [undefined],
                amount_shipping: [0, [Validators.required, Validators.min(0)]],
                amount_shipping_duty: [undefined],
                amount_shipping_vat: [undefined],
                amount_shipping_specialtaxes: [undefined],
                amount_duty: [undefined],
                amount_vat: [undefined],
                amount_specialtaxes: [undefined],
                currency: ['', Validators.required],
                order_statut: ['', Validators.required],
                order_payment_type: [''],
                order_payment_date: [new Date()],
                category_of_item: [''],
                other_category_of_item: [null],
                payment_type: [''],
                incoterm: [''],
                incoterm_delivery: [''],
                products: this.formBuilder.array([])
            });
            this.onAddProduct(null);
        }

        this.subscription = this.rowForm.get('category_of_item').valueChanges.subscribe(value => {
            if (value === 'Other') {
                this.rowForm.get('other_category_of_item').setValidators([Validators.required]);
                this.rowForm.get('other_category_of_item').updateValueAndValidity();
            } else {
                this.rowForm.get('other_category_of_item').clearValidators();
                this.rowForm.get('other_category_of_item').updateValueAndValidity();
            }
        });
    }

    categoryOfItemsInAvailableTypes(categoryOfItem: string = null): boolean {
        const coi = categoryOfItem ? categoryOfItem : (this.rowForm ? this.rowForm.get('category_of_item').value : null);
        if (coi === '' || (coi !== 'Other' && this.categoryOfItemsTypes.includes(coi))) {
            return true;
        } else {
            return false;
        }
    }

    onDeleteProduct(i: number) {
        this.productsForm.removeAt(i);
    }

    onAddProduct(product: OrderProduct) {
        this.productsForm.push(this.formBuilder.group({
            sku: [product ? product.sku : '', Validators.required],
            quantity: [product ? product.quantity : 1, [Validators.required, Validators.min(1)]],
            unit_price: [product ? product.unit_price : 0, [Validators.required, Validators.min(0.01)]],
            unit_price_currency: [product ? product.unit_price_currency : '', Validators.required],
            amount_duty: [product ? product.amount_duty : undefined, []],
            amount_vat: [product ? product.amount_vat : undefined, []],
            amount_specialtaxes: [product ? product.amount_specialtaxes : undefined, []],
        }));
    }

    get productsForm(): FormArray {
        return this.rowForm.get('products') as FormArray;
    }

    async onValidRow(csvRow: any = null) {
        this.unsubscribe();
        // row to add from CSV
        // csvRow is as {'order_id': '12345', ...}
        if (csvRow !== null) {
            const products: OrderProduct[] = [];
            const productUniqueKeys = new Set(Object.keys(csvRow).filter(k => k.startsWith('product_')).map(k => k.substring(0, k.indexOf('_', 8))));
            productUniqueKeys.forEach(p => {
                products.push(
                    Builder(OrderProduct)
                    .sku(csvRow[`${p}_sku`])
                    .quantity(parseInt(csvRow[`${p}_quantity`], 10))
                    .unit_price(parseFloat(csvRow[`${p}_unit_price`]))
                    .unit_price_currency(csvRow[`${p}_unit_price_currency`])
                    .build()
                );
            });

            const customerOrder = Builder(CustomerOrder)
                                .order_id(csvRow.order_id)
                                .invoice_id(csvRow.invoice_id)
                                .url(csvRow.url)
                                .order_date_hour(new Date(csvRow.order_date_hour).getTime())
                                .customer_firstname(csvRow.customer_firstname)
                                .customer_lastname(csvRow.customer_lastname)
                                .customer_address(csvRow.customer_address)
                                .customer_zipcode(csvRow.customer_zipcode)
                                .customer_city(csvRow.customer_city)
                                .customer_country(csvRow.customer_country && csvRow.customer_country.length === 3 ? csvRow.customer_country : undefined)
                                // .customer_documents([])
                                .buyer_firstname(csvRow.buyer_firstname)
                                .buyer_lastname(csvRow.buyer_lastname)
                                .buyer_address(csvRow.buyer_address)
                                .buyer_zipcode(csvRow.buyer_zipcode)
                                .buyer_city(csvRow.buyer_city)
                                .buyer_country(csvRow.buyer_country && csvRow.buyer_country.length === 3 ? csvRow.buyer_country : undefined)
                                .agent_firstname(csvRow.agent_firstname)
                                .agent_lastname(csvRow.agent_lastname)
                                .agent_address(csvRow.agent_address)
                                .agent_zipcode(csvRow.agent_zipcode)
                                .agent_city(csvRow.agent_city)
                                .agent_country(csvRow.agent_country && csvRow.agent_country.length === 3 ? csvRow.agent_country : undefined)
                                .departure_country(csvRow.departure_country && csvRow.departure_country.length === 3 ? csvRow.departure_country : undefined)
                                .arrival_country(csvRow.arrival_country && csvRow.arrival_country.length === 3 ? csvRow.arrival_country : undefined)
                                .products(products)
                                .exporting_carrier(csvRow.exporting_carrier)
                                .shipping_carrier(csvRow.shipping_carrier)
                                /*.amount_products(parseFloat(csvRow.amount_products))
                                .amount_duty(!isNaN(csvRow.amount_duty) ? parseFloat(csvRow.amount_duty) : undefined)
                                .amount_vat(!isNaN(csvRow.amount_vat) ? parseFloat(csvRow.amount_vat) : undefined)
                                .amount_specialtaxes(!isNaN(csvRow.amount_specialtaxes) ? parseFloat(csvRow.amount_specialtaxes) : undefined)
                                */
                                .amount_shipping(!isNaN(csvRow.amount_shipping) ? parseFloat(csvRow.amount_shipping) : 0)
                                .currency(csvRow.currency)
                                .order_statut(csvRow.order_statut)
                                .order_payment_type(csvRow.order_payment_type)
                                .order_payment_date(new Date(csvRow.order_payment_date).getTime())
                                .category_of_item(csvRow.category_of_item)
                                .payment_type(csvRow.payment_type)
                                .incoterm(csvRow.incoterm)
                                .incoterm_delivery(csvRow.incoterm_delivery)
                                .build();

            const rowIndex = this.rows.findIndex(r => r.order_id === customerOrder.order_id);
            // PUT values
            if (rowIndex >= 0) {
                if (! await this.putOrder(customerOrder)) {
                    return;
                }

            // POST values
            } else {
                if (! await this.postOrder(customerOrder)) {
                    return;
                }
            }
            const order = this.orders.find(o => o.order_id === customerOrder.order_id);
            if (order) {
                // update row
                if (rowIndex >= 0) {
                    this.rows[rowIndex] = order.toRow();
                // add new row
                } else {
                    // insert at index 0
                    this.rows.splice(0, 0, order.toRow());
                }
                this.rowsDetails[0][order.order_id] = order.products;
                this.rowsDetails[1][order.order_id] = this.buildDocumentPdfRowDetail(order.customer_documents);
            }
            // refresh the table
            this.rows = [...this.rows];

        // row to add from form
        } else {
            if (this.rowForm.invalid) {
                this.formIsInvalid = true;
                return;
            }
            const formValue = this.rowForm.value;

            for (let i = 0; i < formValue.products.length; i++) {
                formValue.products[i].unit_price = parseFloat(formValue.products[i].unit_price);
                formValue.products[i].amount_duty = undefined;
                formValue.products[i].amount_vat = undefined;
                formValue.products[i].amount_specialtaxes = undefined;
            }

            const customerOrder = Builder(CustomerOrder)
                                .order_id(formValue.order_id)
                                .invoice_id(formValue.invoice_id)
                                .url(formValue.url)
                                .order_date_hour(new Date(formValue.order_date_hour).getTime())
                                .customer_firstname(formValue.customer_firstname)
                                .customer_lastname(formValue.customer_lastname)
                                .customer_address(formValue.customer_address)
                                .customer_zipcode(formValue.customer_zipcode)
                                .customer_city(formValue.customer_city)
                                .customer_country(formValue.customer_country && formValue.customer_country.length === 3 ? formValue.customer_country : undefined)
                                // .customer_documents([])
                                .buyer_firstname(formValue.buyer_firstname)
                                .buyer_lastname(formValue.buyer_lastname)
                                .buyer_address(formValue.buyer_address)
                                .buyer_zipcode(formValue.buyer_zipcode)
                                .buyer_city(formValue.buyer_city)
                                .buyer_country(formValue.buyer_country && formValue.buyer_country.length === 3 ? formValue.buyer_country : undefined)
                                .agent_firstname(formValue.agent_firstname)
                                .agent_lastname(formValue.agent_lastname)
                                .agent_address(formValue.agent_address)
                                .agent_zipcode(formValue.agent_zipcode)
                                .agent_city(formValue.agent_city)
                                .agent_country(formValue.agent_country && formValue.agent_country.length === 3 ? formValue.agent_country : undefined)
                                .departure_country(formValue.departure_country && formValue.departure_country.length === 3 ? formValue.departure_country : undefined)
                                .arrival_country(formValue.arrival_country && formValue.arrival_country.length === 3 ? formValue.arrival_country : undefined)
                                .products(formValue.products)
                                .exporting_carrier(formValue.exporting_carrier)
                                .shipping_carrier(formValue.shipping_carrier)
                                /*.amount_products(parseFloat(formValue.amount_products))
                                .amount_duty(!isNaN(formValue.amount_duty) ? parseFloat(formValue.amount_duty) : undefined)
                                .amount_vat(!isNaN(formValue.amount_vat) ? parseFloat(formValue.amount_vat) : undefined)
                                .amount_specialtaxes(!isNaN(formValue.amount_specialtaxes) ? parseFloat(formValue.amount_specialtaxes) : undefined)
                                */
                                .amount_shipping(!isNaN(formValue.amount_shipping) ? parseFloat(formValue.amount_shipping) : undefined)
                                .currency(formValue.currency)
                                .order_statut(formValue.order_statut)
                                .order_payment_type(formValue.order_payment_type)
                                .order_payment_date(new Date(formValue.order_payment_date).getTime())
                                .category_of_item(this.categoryOfItemsInAvailableTypes(formValue.category_of_item) ? formValue.category_of_item : formValue.other_category_of_item)
                                .payment_type(formValue.payment_type)
                                .incoterm(formValue.incoterm)
                                .incoterm_delivery(formValue.incoterm_delivery)
                                .build();

            const rowIndex = this.rows.findIndex(r => r.order_id === customerOrder.order_id);
            if (this.isEditing && rowIndex >= 0) {
                if (! await this.putOrder(customerOrder)) {
                    this.closeModal(true);
                    return;
                }
            } else {
                if (! await this.postOrder(customerOrder)) {
                    this.closeModal();
                    return;
                }
            }
            const order = this.orders.find(o => o.order_id === customerOrder.order_id);
            if (order) {
                // update row
                if (rowIndex >= 0) {
                    this.rows[rowIndex] = order.toRow();
                // add new row
                } else {
                    // insert at index 0
                    this.rows.splice(0, 0, order.toRow());
                }
                this.rowsDetails[0][order.order_id] = order.products;
                this.rowsDetails[1][order.order_id] = this.buildDocumentPdfRowDetail(order.customer_documents);
            }
            // refresh the table
            this.rows = [...this.rows];

            this.closeModal();

            // the user was trying to call a process and some required fields were missing?
            // We must set then the missing fields for other orders and if there is no more, try to do the action once again
            if (this.missingOrderFieldsForAction) {
                // fill next order with missing fields
                const nextOrderToFillIdIndex = this.ordersWithErrors.ordersWithMissingRequiredFields.findIndex(id => id === customerOrder.order_id) + 1;
                if (nextOrderToFillIdIndex > 0 && nextOrderToFillIdIndex < this.ordersWithErrors.ordersWithMissingRequiredFields.length) {
                    this.openModal(this.editrow, 'orderFieldsMissing', this.ordersWithErrors.ordersWithMissingRequiredFields[nextOrderToFillIdIndex]);

                // all missing fields are set now, retry the pending action
                } else {
                    this.onExecuteAction();
                }
            }
        }
    }

    // used when removing products and generating credit note
    differentProductsSize(): boolean {
        if (this.rowForm) {
            const rowFormProductsLength = this.productsForm.length;
            return rowFormProductsLength !== this.initialProductsNumber;
        }
        return false;
    }

    // valid products to delete from the form
    onValidProductsToDelete() {
        if (! this.differentProductsSize()) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.rowForm.value;
        const order_id = formValue.order_id;
        const remainingProductsSkus = formValue.products.map(p => p.sku);
        const row = this.rows.find(r => r.order_id === order_id);
        // it shall always be the case, we use this condition for the pipeline
        if (row) {
            if (this.rowsDetails && this.rowsDetails.length > 1 && this.rowsDetails[0][row.order_id]) {
                this.processProductsToRemove[order_id] = this.rowsDetails[0][row.order_id].map(p => p.sku).filter(sku => ! remainingProductsSkus.includes(sku));
            }
        }

        // append products to remove in the list
        const productsToRemoveOrderIdsAlreadySet = Object.keys(this.processProductsToRemove);
        const productsToRemoveOrderIds = this.selectedRows.map(r => r.order_id);
        const remainingProductsToRemoveOrderIds = productsToRemoveOrderIds.filter(id => ! productsToRemoveOrderIdsAlreadySet.includes(id));

        // we are still preparing products to remove
        if (remainingProductsToRemoveOrderIds.length > 0) {
            this.closeModal();
            this.openModal(this.removeproducts, 'removeproducts', remainingProductsToRemoveOrderIds[0]);

        // we just prepared the last products to remove, we can now do the process
        } else {
            this.closeModal();
            this.executeRemoveProductsAndGenerateCreditNoteOnceSet();
        }
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    // post orders, returns true on success, false otherwise
    async postOrder(customerOrder: CustomerOrder): Promise<boolean> {
        try {
            const response = <CustomerOrder> await this.http.post(`https://api.${environment.baseUrl}/v1/customer/orders`, customerOrder).toPromise();

            const newOrder = plainToClass(CustomerOrder, response);
            this.orders.push(newOrder);

            // get currency if missing
            if (
                customerOrder.currency &&
                customerOrder.currency.length === 3 &&
                this.currencies.findIndex(c => c.value.toUpperCase() === customerOrder.currency.toUpperCase())
            ) {
                await this.managersService.getMissingCurrencies([customerOrder.currency]);
            }
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // put order, returns true on success, false otherwise
    async putOrder(customerOrder: CustomerOrder): Promise<boolean> {
        try {
            // get the row and compare products and shipping amount
            const indexOrder = this.orders.findIndex(o => o.order_id === customerOrder.order_id);
            const index = this.rows.findIndex(r => r.order_id === customerOrder.order_id);
            const newSkus = customerOrder.products.map(p => p.sku);
            const removedProducts = this.orders[indexOrder].products.map(p => p.sku).filter(sku => ! newSkus.includes(sku));
            if (indexOrder >= 0 && this.orders[indexOrder].landed_cost_timestamp && index >= 0) {
                // products have been removed? we must call, if landed cost was done, process to remove products
                if (removedProducts.length > 0) {
                    this.processProductsToRemove = {};
                    this.processProductsToRemove[customerOrder.order_id] = this.orders[indexOrder].products
                                                                        .map(p => p.sku)
                                                                        .filter(sku => ! newSkus.includes(sku));
                    const request = this.buildProcessRequests(null, 1);
                    const res = await this.callProcess(request[0], CustomerOrdersServiceEnum.REMOVE_PRODUCTS_GENERATE_CREDIT_NOTE, false);
                    if (res) {
                        this.prepareDocsToDownload(res[0]);
                    }
                }
            }

            const body = {...customerOrder};
            delete body.order_id;
            const response = <CustomerOrder> await this.http.put(`https://api.${environment.baseUrl}/v1/customer/orders/${customerOrder.order_id}`, body).toPromise();
            // must execute landed cost?
            if (indexOrder >= 0 && this.orders[indexOrder].landed_cost_timestamp) {
                const oldSkus = this.orders[indexOrder].products.map(p => p.sku);
                // contains new skus
                const newProducts = newSkus.filter(sku => ! oldSkus.includes(sku));
                // contains modified products
                const modifiedProducts = this.orders[indexOrder].products.filter(product => { // products modified
                    const p = customerOrder.products.find(prod => prod.sku === product.sku && ! removedProducts.includes(prod.sku));
                    if (p) {
                        return product.quantity !== p.quantity ||
                                product.unit_price !== p.unit_price ||
                                product.unit_price_currency !== p.unit_price_currency;
                    }
                    return false;
                });
                if (
                    removedProducts.length > 0 // removed products (need to re-execute Landed Cost as shipping amount has been updated after removing products)
                    ||
                    newProducts.length > 0 // new products added
                    ||
                    modifiedProducts.length > 0 // modified products
                    ||
                    customerOrder.amount_shipping !== this.orders[indexOrder].amount_shipping // amount shipping modified
                ) {
                    const request = this.buildProcessRequests([this.orders[indexOrder].order_id], 1);
                    const res = await this.callProcess(request[0], CustomerOrdersServiceEnum.LANDED_COST, false);
                    if (res) {
                        const updatedOrder = plainToClass(CustomerOrder, (res[0] as CustomerOrderProcessResponse).customerOrder);
                        // update local order
                        if (indexOrder >= 0) {
                            this.orders[indexOrder] = updatedOrder;
                        }
                        // generate new docs right after Landed Cost if products have been added
                        if (newProducts.length > 0) {
                            const processRes = await this.callProcess(request[0], CustomerOrdersServiceEnum.GENERATE_DOCS);
                            if (processRes) {
                                this.prepareDocsToDownload(processRes[0]);
                            }
                        }
                    }
                // only products were deleted, no product added nor modified
                } else {
                    const updatedOrder = plainToClass(CustomerOrder, response);
                    // replace local order stored
                    if (indexOrder >= 0) {
                        this.orders[indexOrder] = updatedOrder;
                    }
                }
            } else {
                const updatedOrder = plainToClass(CustomerOrder, response);
                // replace local order stored
                if (indexOrder >= 0) {
                    this.orders[indexOrder] = updatedOrder;
                }
            }

            // dowload all generated docs if there are any
            this.downloadGeneratedDocs();

            // get currency if missing
            if (
                customerOrder.currency &&
                customerOrder.currency.length === 3 &&
                this.currencies.findIndex(c => c.value.toUpperCase() === customerOrder.currency.toUpperCase())
            ) {
                await this.managersService.getMissingCurrencies([customerOrder.currency]);
            }

            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // get orders, returns true on success, false otherwise
    async getOrders(): Promise<CustomerOrder[]> {
        const timestamps = this.getTimestampStartEnd();
        try {
            if (timestamps) {
                return (
                    <CustomerOrder[]> await this.http.get(
                        `https://api.${environment.baseUrl}/v1/customer/orders?timestamp_from=${timestamps.start}&timestamp_to=${timestamps.end}`
                    ).toPromise()
                );
            } else {
                return (
                    <CustomerOrder[]> await this.http.get(
                        `https://api.${environment.baseUrl}/v1/customer/orders`
                    ).toPromise()
                );
            }
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    private getTimestampStartEnd(): { start: number; end: number; } {
        if (this.selectedDateInterval === ORDERS_DATES_INTERVAL.ALL) {
            return null;
        }
        const currendDate = new Date();
        const timestamps: { start: number; end: number; } = {start: 0, end: currendDate.getTime()};
        currendDate.setHours(0);
        currendDate.setMinutes(0);
        currendDate.setSeconds(0);
        currendDate.setMilliseconds(0);
        switch (this.selectedDateInterval) {
            case ORDERS_DATES_INTERVAL.LAST_THIRTY_DAYS:
                currendDate.setDate(currendDate.getDate() - 30);
                timestamps.start = currendDate.getTime();
                break;
            case ORDERS_DATES_INTERVAL.CURRENT_MONTH:
                currendDate.setDate(1);
                timestamps.start = currendDate.getTime();
                break;
            case ORDERS_DATES_INTERVAL.CURRENT_YEAR:
                currendDate.setDate(1);
                currendDate.setMonth(0);
                timestamps.start = currendDate.getTime();
                break;
            case ORDERS_DATES_INTERVAL.LAST_NINETY_DAYS:
                currendDate.setDate(currendDate.getDate() - 90);
                timestamps.start = currendDate.getTime();
                break;

            default:
                currendDate.setDate(currendDate.getDate() - 30);
                timestamps.start = currendDate.getTime();
                break;
        }
        return timestamps;
    }

    // delete order, returns true on success, false otherwise
    async deleteOrder(order_id: string): Promise<boolean> {
        try {
            await this.http.delete(`https://api.${environment.baseUrl}/v1/customer/orders/${order_id}`).toPromise();
            const index = this.rows.findIndex(r => r.order_id === order_id);
            this.rows.splice(index, 1);
            delete this.rowsDetails[0][order_id];
            delete this.rowsDetails[1][order_id];
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    async onDeleteAssociatedDocumentFromOrder(order_id: string, document_id: string) {
        try {
            const order = await this.http.delete(
                `https://api.${environment.baseUrl}/v1/customer/orders/${order_id}/document/${document_id}`
            ).toPromise() as CustomerOrder;
            this.rowsDetails[1][order.order_id] = this.buildDocumentPdfRowDetail(order.customer_documents);
            this._notification.displayMessage(this.translate.instant('ordersManager.docAssociatedDeleted'), 'success');
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(
                this._notification.buildTransiteoErrorMessage(errorResponse.error, errorResponse.statusText),
                'danger',
                10000
            );
        }
    }

    async onValidAssociateDocumentPdfToOrder() {
        if (this.importedDocumentForOrderContent !== '') {
            try {
                const order = await this.http.post(
                    `https://api.${environment.baseUrl}/v1/customer/orders/${this.orderToWhichAssociateDocuments}/document`,
                    { content: this.importedDocumentForOrderContent, filename: this.importedDocumentFilename }
                ).toPromise() as CustomerOrder;
                this.rowsDetails[1][order.order_id] = this.buildDocumentPdfRowDetail(order.customer_documents);
                this._notification.displayMessage(this.translate.instant('ordersManager.docAssociated'), 'success');
            } catch (errorResponse) {
                console.log('ERROR : ' + JSON.stringify(errorResponse));
                this._notification.displayMessage(
                    this._notification.buildTransiteoErrorMessage(errorResponse.error, errorResponse.statusText),
                    'danger',
                    10000
                );
            }
            this.importedDocumentFilename = undefined;
            this.importedDocumentForOrderContent = '';
        }
    }

    associateDocumentPdfToOrder(order_id: string) {
        this.switchDisplayUploadDocumentComponent(order_id);
        this.orderToWhichAssociateDocuments = order_id;
        this.importedDocumentFilename = undefined;
        this.importedDocumentForOrderContent = '';
    }

    onRowDetailCellClicked(info: { rowId: string; columnName: string; columnContentId: string; }) {
        switch (info.columnName) {
            case 'is_deletable':
                this.onDeleteAssociatedDocumentFromOrder(info.rowId, info.columnContentId);
                break;

            case 'document_link':
                this.downloadPdf('customerDocumentOrder', info.columnContentId);
                break;

            default:
                console.log('Unknown action');
                break;
        }
    }

    async downloadGeneratedDocs() {
        const urls: string[] = [];
        const filenames: string[] = [];
        const docsTypes = Object.keys(this.docsToDownload);
        let firstOrderId: string;

        // extract all docs url, and build the filename
        docsTypes.forEach(docType => {
            for (let i = 0; i < this.docsToDownload[docType].length; i++) {
                if (! firstOrderId) {
                    firstOrderId = this.docsToDownload[docType][i].order_id;
                }
                urls.push(this.docsToDownload[docType][i].signed_url.substring(0, this.docsToDownload[docType][i].signed_url.indexOf('?Expires=')));
                filenames.push(`${this.docsToDownload[docType][i].order_id}_${docType}_${(i + 1).toString()}.pdf`);
            }
        });

        // download the docs
        if (urls.length > 0) {
            await this.zipService.downloadFile(urls, filenames, (
                filenames.findIndex(f => ! f.startsWith(`${firstOrderId}_`)) === -1
                ?
                `documents_${firstOrderId}`
                :
                'documents'
            ));
        }

        this.docsToDownload = {};
    }

    onRedirectToUserSettings() {
        this.closeModal();
        this.router.navigate(['/settings/business']);
    }

    prepareAutocompleteCustomer(controlName: string, fieldName: string) {
        const control = this.rowForm.get(controlName);
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsCustomers(), fieldName)),
        );
    }
    prepareAutocompleteProduct(index: number) {
        const control = (this.productsForm.at(index) as FormGroup).get('sku');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), 'sku')),
        );
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            if (filterField === 'sku') {
                return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
            }
            let i = 0;
            if (filterField === 'email') {
                i = 1;
            } else if (filterField === 'website') {
                i = 2;
            }
            return optionSplitted[i].toLowerCase().includes(filterValue);
        });
    }

    onCustomerClicked(option: string) {
        // website is the sort key
        const website = option.split(', ')[2];
        const rightCustomer = this.managersService.getCustomers().find(c => c.website === website);
        if (rightCustomer) {
            this.rowForm.get('url').setValue(rightCustomer.website);
            this.rowForm.get('customer_firstname').setValue(rightCustomer.firstname);
            this.rowForm.get('customer_lastname').setValue(rightCustomer.lastname);
            this.rowForm.get('customer_address').setValue(rightCustomer.address);
            this.rowForm.get('customer_zipcode').setValue(rightCustomer.zipCode);
            this.rowForm.get('customer_city').setValue(rightCustomer.city);
            this.rowForm.get('customer_country').setValue(rightCustomer.country);
            this.rowForm.get('customer_lastname').setValue(rightCustomer.lastname);
        }
    }
    onProductClicked(index: number, option: string) {
        // sku is the sort key
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            (this.productsForm.at(index) as FormGroup).get('sku').setValue(rightProduct.sku);
            if (rightProduct['unit_price']) {
                (this.productsForm.at(index) as FormGroup).get('unit_price').setValue(rightProduct['unit_price']);
            }
            if (rightProduct['currency_unit_price']) {
                (this.productsForm.at(index) as FormGroup).get('unit_price_currency').setValue(rightProduct['currency_unit_price']);
            }
        }
    }

    ordersHaveBeenDeleted() {
        this.deletionSubject.next();
    }

    onDisplayedRowsChanged(ids: string[]) {
        this.displayedRows = ids;
        const dRows = this.rows.filter(r => ids.includes(r.order_id));
        const turnoverByCurrency = {};
        dRows.forEach(r => {
            if (r.currency && this.currencies.findIndex(c => c.value.toUpperCase() === r.currency.toUpperCase()) >= 0) {
                if (! turnoverByCurrency[r.currency.toUpperCase()]) {
                    turnoverByCurrency[r.currency] = 0;
                }
                turnoverByCurrency[r.currency] += r.amount_products ? r.amount_products : 0;
                turnoverByCurrency[r.currency] += r.amount_shipping ? r.amount_shipping : 0;
                turnoverByCurrency[r.currency] += r.amount_vat ? r.amount_vat : 0;
                turnoverByCurrency[r.currency] += r.amount_duty ? r.amount_duty : 0;
                turnoverByCurrency[r.currency] += r.amount_specialtaxes ? r.amount_specialtaxes : 0;
            }
        });
        const keys = Object.keys(turnoverByCurrency);
        if (keys.length === 0) {
            this.turnover = 0;
            const currency = this.currencies.find(c => c.value === 'EUR');
            this.turnoverCurrency = currency ? currency.label.substring(0, currency.label.indexOf(' (')) : 'EUR';
        } else {
            this.turnover = this.managersService.getAmountWithConvertedCurrencies(turnoverByCurrency, keys[0]);
            const currency = this.currencies.find(c => c.value === keys[0]);
            this.turnoverCurrency = currency ? currency.label.substring(0, currency.label.indexOf(' (')) : keys[0];
        }

        this.sentOrders = dRows.filter(r => r.order_statut === 'SENT').length;
        this.finishedOrders = dRows.filter(r => r.order_statut === 'PAID' || r.order_statut === 'FINISHED').length;
    }
}
