import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../../models/User';
import {InternationalShipmentRequest, InternationalShipmentResponse, TransportWithProducts} from '../../../../models/InternationalShipment';
import {TransportEnum} from '../shipment.component';
import {InternationalShipmentRequestProduct} from '../../../../models/Product';
import * as moment from 'moment';

@Component({
  selector: 'app-shipment-result',
  templateUrl: './shipment-result.component.html',
  styleUrls: ['./shipment-result.component.scss']
})
export class ShipmentResultComponent implements OnInit {
  @Output() getShipment: EventEmitter<FormGroup> = new EventEmitter();
  @Output() callNextStep: EventEmitter<FormGroup> = new EventEmitter();
  preferenceForm: FormGroup;
  @Input()
  internationalShipmentRequest: InternationalShipmentRequest;
  @Input()
  user: User;
  @Input()
  internationalShipmentResponse: InternationalShipmentResponse;

  @Input()
  receiver: {pro: string, country:  {value: string, label: string}, district:  {value: string, label: string}};
  @Input()
  sender: {pro: string, country:  {value: string, label: string}, district:  {value: string, label: string}};

  transport_option = {
    CHEAPER: 'cheaper',
    FASTER: 'faster',
    CLEANER: 'cleaner'
  };
  transport_type = TransportEnum;
  constructor() { }


  async ngOnInit() {
    if (!this.preferenceForm) {
      this.preferenceForm = new FormGroup({
        option: new FormControl(this.transport_option.CHEAPER, [Validators.required]),
        insurance: new FormControl('true', [Validators.required])
      });
    }
    // await this.updateShipment();
    this.preferenceForm.get('option').valueChanges.subscribe(async option => {
      await this.updateShipment();
    });
  }

  newSearch() {
    this.callNextStep.emit();
  }
  async updateShipment() {
    await this.getShipment.emit(this.preferenceForm);
  }
  getArrivalDate() {
    const index = this.internationalShipmentResponse.shipments.length - 1;
    return this.internationalShipmentResponse.shipments[index].arrival_date;
  }
  getContainerParams(transport: TransportWithProducts) {
    return {
      var_products: this.getProductsResults(transport.products),
      departure_date: this.toDate(this.internationalShipmentRequest.departure_date),
      from_district: this.sender.district.label, from_country: this.sender.country.label,
      to_district: this.receiver.district.label, to_country: this.receiver.country.label,
      var_model: transport.infos.model,
      var_quantity: transport.infos.quantity,
      var_transit: this.internationalShipmentRequest.transit,
      shipment_option: this.internationalShipmentRequest.shipment_option, delivery_option: this.internationalShipmentRequest.delivery_option,
      delivery_date_1: this.getArrivalDate(), delivery_date_2: this.getArrivalDate(),
    };
  }
  getParcelParams(transport: TransportWithProducts) {
    return {
      var_products: this.getProductsResults(transport.products),
      departure_date: this.toDate(this.internationalShipmentRequest.departure_date),
      from_district: this.sender.district.label, from_country: this.sender.country.label,
      to_district: this.receiver.district.label, to_country: this.receiver.country.label,
      var_height: transport.infos.dimensions.height,
      var_width: transport.infos.dimensions.width,
      var_length: transport.infos.dimensions.length,
      var_quantity: transport.infos.quantity,
      var_transit: this.internationalShipmentRequest.transit,
      shipment_option: this.internationalShipmentRequest.shipment_option, delivery_option: this.internationalShipmentRequest.delivery_option,
      delivery_date_1: this.getArrivalDate(), delivery_date_2: this.getArrivalDate(),
    };
  }
  getPalletParams(transport: TransportWithProducts) {
    return {
      var_products: this.getProductsResults(transport.products),
      departure_date: this.toDate(this.internationalShipmentRequest.departure_date),
      from_district: this.sender.district.label, from_country: this.sender.country.label,
      to_district: this.receiver.district.label, to_country: this.receiver.country.label,
      weight_volume: transport.infos.weight,
      weight_unit: transport.infos.weight_unit,
      var_quantity: transport.infos.quantity,
      var_transit: this.internationalShipmentRequest.transit,
      shipment_option: this.internationalShipmentRequest.shipment_option, delivery_option: this.internationalShipmentRequest.delivery_option,
      delivery_date_1: this.getArrivalDate(), delivery_date_2: this.getArrivalDate(),
    };
  }
  getProductsResults(products: InternationalShipmentRequestProduct[]): string {
    return products.reduce((a, b) => `${a} ${b.quantity} ${b.identification.value}`, '');
  }

  toDate(timestamp) {
    return moment(timestamp).utc().format('DD/MM/YYYY');
  }

}
