import {Injectable} from '@angular/core';
import * as json2csv from 'json2csv'; // convert json file to csv
import {saveAs} from 'file-saver'; // save the file

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  Json2csvParser = json2csv.Parser;
  constructor() { }

  public downloadFile(data: any, filename: string) {
    const csvData = this.convertToCSV(data);
    const file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    return saveAs(file, `${filename}.csv`);
  }

  public convertToCSV(objArray: any, fields?) {
    const json2csvParser = new this.Json2csvParser({ opts: fields });
    return json2csvParser.parse(objArray);
  }

  public flattenObjectToBuildCsv(prefix: string, data: any): {[key: string]: any} {
    if (Array.isArray(data)) {
      return this.buildCsvFromJsonHandleArray(prefix, data);
    } else {
      return this.buildCsvFromJsonHandleObject(prefix, data);
    }
  }

  private buildCsvFromJsonHandleObject(prefix: string, obj: {[key: string]: any}): {[key: string]: any} {
    const res: {[key: string]: any} = {};
    const keys = Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== undefined);
    keys.forEach(key => {
      if (Array.isArray(obj[key])) {
        const item = this.buildCsvFromJsonHandleArray(`${prefix}${key}_`, obj[key]);
        const itemKeys = Object.keys(item);
        itemKeys.forEach(iKey => {
          res[iKey] = item[iKey];
        });
      } else if (typeof obj[key] === 'object') {
        const item = this.buildCsvFromJsonHandleObject(`${prefix}${key}_`, obj[key]);
        const itemKeys = Object.keys(item);
        itemKeys.forEach(iKey => {
          res[iKey] = item[iKey];
        });
      } else {
        res[`${prefix}${key}`] = obj[key];
      }
    });

    return res;
  }
  private buildCsvFromJsonHandleArray(prefix: string, arr: {[key: string]: any}[]): {[key: string]: any} {
    const res: {[key: string]: any} = {};
    for (let i = 0; i < arr.length; i++) {
      const a = arr[i];
      if (Array.isArray(a)) {
        const item = this.buildCsvFromJsonHandleArray(`${prefix}${i + 1}_`, a);
        const itemKeys = Object.keys(item);
        itemKeys.forEach(iKey => {
          res[iKey] = item[iKey];
        });
      } else if (typeof a === 'object') {
        const item = this.buildCsvFromJsonHandleObject(`${prefix}${i + 1}_`, a);
        const itemKeys = Object.keys(item);
        itemKeys.forEach(iKey => {
          res[iKey] = item[iKey];
        });
      } else {
        res[`${prefix}${i + 1}`] = a;
      }
    }

    return res;
  }

}
