import { Component, OnInit,Input,HostBinding } from '@angular/core';

@Component({
  selector: 'pg-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss'],
  host:{
    '[class]' : '_classMap'
  }
})
export class MenuIconComponent implements OnInit {
  _classMap: string;
  @Input() IconType: string;
  @Input() IconName: string;
  @Input() AddedClass: string;
  @Input() routerLink: string;
  @Input() queryParams: string;
  @Input() title: string;
  @Input()
  set menuPin(value: boolean) {
    if (!value) {
      if (!this._classMap.includes(' text-left ') ) {
        this._classMap = this._classMap +  ' text-left ';
      }
      if (this._classMap.includes(' text-center ') ) {
        this._classMap = this._classMap.replace(' text-center ', '');
      }
      if (window.innerWidth < 1025 && window.innerWidth > 990) {
        if (!this._classMap.includes(' p-l-15 ') ) {
          this._classMap = this._classMap + ' p-l-15 ';
        }
      }
    } else {
      if (this._classMap.includes(' text-left ') ) {
        this._classMap = this._classMap.replace(' text-left ', '');
      }
      if (!this._classMap.includes(' text-center ') ) {
        this._classMap = this._classMap +  ' text-center ';
      }
      if (window.innerWidth < 1025 && window.innerWidth > 990) {
        if (this._classMap.includes(' p-l-15 ') ) {
          this._classMap = this._classMap.replace(' p-l-15 ', '');
        }
      }
    }
  }

  @Input()
  set ExtraClass(value: string) {
    if (value !== undefined) {
        this._classMap = this._classMap +  value;
    }
  }

  constructor() {
    this._classMap = 'icon-thumbnail ';
  }

  ngOnInit() {
    if (this.title) {
      this._classMap = this._classMap +  ' m-t-18 no-link ';
    }
  }

}
