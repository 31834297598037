import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Recipient, Shipper } from '../../../../models/Shipping';
import { isBoolean } from 'util';
import { Builder } from 'builder-pattern';

@Component({
  selector: 'app-shipping-step-expedition',
  templateUrl: './step-expedition.component.html',
  styleUrls: ['./step-expedition.component.scss']
})
export class ShippingStepExpeditionComponent implements OnInit {

    @Input()
    shipper: Shipper;
    @Input()
    recipient: Recipient;
    @Input()
    countries: {value: string, label: string, iso2: string}[];
    @Input()
    booleanChoices: {value: string, label: string}[];
    @Output()
    setData: EventEmitter<{shipper: Shipper; recipient: Recipient}> = new EventEmitter<{shipper: Shipper; recipient: Recipient}>();

    expeditionForm: FormGroup;
    formIsInvalid = false;

    constructor() {
        // first initialization to avoid 'undefined' issues
        this.expeditionForm = new FormGroup({
            shipperCountry: new FormControl('', [Validators.required]),
            shipperPostalCode: new FormControl('', [Validators.required]),
            shipperCity: new FormControl('', [Validators.required]),
            recipientCountry: new FormControl('', [Validators.required]),
            recipientPostalCode: new FormControl('', [Validators.required]),
            recipientCity: new FormControl('', [Validators.required]),
            recipientIsACompany: new FormControl(false, [Validators.required]),
        });
    }

    ngOnInit() {
        this.initializeForm();
    }

    initializeForm() {
        // first initialization to avoid 'undefined' issues
        this.expeditionForm = new FormGroup({
            shipperCountry: new FormControl(this.shipper && this.shipper.country ? this.shipper.country : '', [Validators.required]),
            shipperPostalCode: new FormControl(this.shipper && this.shipper.postal_code ? this.shipper.postal_code : '', [Validators.required]),
            shipperCity: new FormControl(this.shipper && this.shipper.city ? this.shipper.city : '', [Validators.required]),
            recipientCountry: new FormControl(this.recipient && this.recipient.country ? this.recipient.country : '', [Validators.required]),
            recipientPostalCode: new FormControl(this.recipient && this.recipient.postal_code ? this.recipient.postal_code : '', [Validators.required]),
            recipientCity: new FormControl(this.recipient && this.recipient.city ? this.recipient.city : '', [Validators.required]),
            recipientIsACompany: new FormControl(this.recipient && isBoolean(this.recipient.is_a_company) ? this.recipient.is_a_company : false, [Validators.required]),
        });
    }

    nextStep() {
        if (this.expeditionForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const values = this.expeditionForm.value;
        const res: {shipper: Shipper; recipient: Recipient} = {shipper: null, recipient: null};
        res.shipper = Builder(Shipper)
                    .country(values.shipperCountry)
                    .postal_code(values.shipperPostalCode)
                    .city(values.shipperCity)
                    .build();
        res.recipient = Builder(Recipient)
                        .country(values.recipientCountry)
                        .postal_code(values.recipientPostalCode)
                        .city(values.recipientCity)
                        .is_a_company(values.recipientIsACompany)
                        .build();
        this.formIsInvalid = false;
        this.setData.emit(res);
    }
}
