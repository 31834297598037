import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmbedWebsiteComponent } from './embed-website.component';

@NgModule({
  imports     : [ CommonModule ],
  declarations: [ EmbedWebsiteComponent ],
  exports     : [ EmbedWebsiteComponent ]
})
export class EmbedWebsiteModule {
}
