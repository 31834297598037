import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {RequestRequiredFields} from '../connected/duty-calculation/interfaces/request-required-fields.interface';
import {ProductsRequiredFields} from '../connected/duty-calculation/interfaces/products-required-fields.interface';
import {TaxRequest, TaxResponse} from '../../models/DutyCalculation';
import { RequiredFields } from '../connected/duty-calculation/interfaces/required-fields.interface';

@Injectable({
  providedIn: 'root'
})
export class DutyCalculationService {

  dutyCalculationUrl: string;
  dutyCalculationRequiredFieldsUrl: string;

  constructor(private http: HttpClient) {
    this.dutyCalculationUrl = `https://api.${environment.baseUrl}/v1/taxsrv/dutyCalculation`;
    this.dutyCalculationRequiredFieldsUrl = `https://api.${environment.baseUrl}/v1/taxsrv/dutyCalculationRequiredFields`;
  }

  askRequiredFields(data: RequestRequiredFields[]): Promise<ProductsRequiredFields[]> {
    return this.http.post<ProductsRequiredFields[]>(this.dutyCalculationRequiredFieldsUrl, {requests: data}).toPromise();
  }

  dutyCalculation(data: TaxRequest): Promise<TaxResponse | TaxResponse[]> {
    return this.http.post<TaxResponse>(this.dutyCalculationUrl, data).toPromise();
  }
}
