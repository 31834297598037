export class ShipmentResponse {
    tracking_number: string;
    shipments: Array<NationalShipment>;
}

export class NationalShipment {
    id: string;
    logo_url: string;
    name: string;
    features: Array<string>;
    shipping_cost: number;
    currency_shipping_cost: string;
    departure_date: string;
    arrival_date: string;
    from_address: ShipmentAddressWithIso2;
    to_address: ShipmentAddressWithIso2;
    step: ShipmentStep = ShipmentStep.NATIONAL;
}

export class ShipmentRequest {
    transports: Array<Transport>;
    departure_date: string;
    lang: string;
    currency: string;
    metric_system: string;

    first_mile_picking: boolean;
    first_mile_deposite: boolean;
    last_mile_home_delivery: boolean;
    last_mile_store_delivery: boolean;
    shipment_option: ShipmentOption;
    delivery_option: string;

    sender: ShipmentSender;
    receiver: ShipmentReceiver;
}

export class NationalShipmentRequest extends ShipmentRequest {}

export abstract class ShipmentsWithShipper extends NationalShipment {
    shippersWithPriceWithTracking: Array<{}>;
}

export class ShipmentResponseWithShipper extends ShipmentResponse {
    shipments: Array<ShipmentsWithShipper>;
}

export class ShipmentPayment {
    tracking_number: string;
    mail: string;
    shipmentRequest: ShipmentRequest;
    shipmentResponse: ShipmentResponseWithShipper;
    invoiceId: string;
    isInternational: boolean;
    labels: Array<{}>;
}

export enum ShipmentOption {
    FAST = 'fast',
    CHEAP = 'cheap'
}

export enum ShipmentTypeEnum {
    CARRIER = 'CARRIER',
    FREIGHT_FORWARDER = 'FREIGHT_FORWARDER'
}

export class Transport {
    infos: TransportInfo;
}

export enum TransportEnum {
    PARCEL = 'PARCEL',
    PALLET = 'PALLET',
    CONTAINER = 'CONTAINER',
}

export class TransportInfo {
    type: TransportEnum;
    dimensions: ShipmentDimension;
    model: string;
    option: string;
    incoterm: string;
    weight: number;
    weight_unit: string;
    quantity: number;
}
export class ShipmentDimension {
    width: number;
    length: number;
    height: number;
}

export class ShipmentInfosRequest {
    tracking_number: number;
    sender: ShipmentSender;
    receiver: ShipmentReceiver;
    terms: boolean;
    insurance: boolean;
}

export class ShipmentAddress {
    pro: boolean;
    vat_id_company: string;
    first_name: string;
    last_name: string;
    company_name: string;
    email: string;
    phone: string;
    address_line1: string;
    address_line2: string;
    postal_code: string;
    city: string;
    district: string;
    country: string;
    latitude: number;
    longitude: number;
}

export class ShipmentAddressWithIso2 extends ShipmentAddress {
    countryIso2: string;
}

export class ShipmentReceiver extends ShipmentAddress {
    activity_id: string;
}

export class ShipmentSender extends ShipmentAddress {
    annual_revenue: number;
    currency_annual_revenue: string;
    eori: string;
    id_company: string;
}


export enum TransitEnum {
    AIR = 'air',
    SEA = 'sea',
    ROAD = 'road'
}

export enum ShipmentStep {
    NATIONAL = 'national',
    TRANSIT = 'transit'
}
