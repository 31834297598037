import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.userService.getUser();
    if (user) {
      if ( user.isSuperAdmin() ) {
        return true;
      }
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}
