import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { User } from '../../../models/User';
import { UserService } from '../../services/user.service';
import { Utils } from '../../utils/utils';
import { CsvService } from '../../services/csv.service';
import { pgTabComponent } from '../../components/tabs/tab.component';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AiImprovementRequest, AI_CLASSIFY_COUNTRY_TYPE, HsCodeFinderEnum, HsCodeFinderIdentification, HsCodeFinderRequest, HsCodeFinderRequests, HsCodeFinderResult } from '../../../models/HsCodeFinder';
import { Builder } from 'builder-pattern';
import { ActivatedRoute } from '@angular/router';
import { CustomerProduct, CUSTOMER_PRODUCT_TYPE_ENUM, FormatCsv } from '../../../models/Managers';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { StringifyOptions } from 'querystring';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ManagersService } from '../../services/managers.service';
import { normalize } from 'normalize-diacritics';
import * as RecordRTC from 'recordrtc';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CsvFieldToSet } from '../../components/csv-mapper/csv-mapper.component';

enum HS_STEP_ENUM {
    SEARCH = 1,
    RESULTS = 2,
}

@Component({
    selector: 'app-hs-code-finder',
    templateUrl: './hs-code-finder.component.html',
    styleUrls: ['./hs-code-finder.component.scss'],
})
export class HsCodeFinderComponent implements OnInit, OnDestroy {
    HS_STEP_ENUM = HS_STEP_ENUM;
    @ViewChild(pgTabComponent, { static: false }) child: pgTabComponent;
    @ViewChild('pop_hs_code', { static: false }) pop_hs_code;
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    countries: Array<{ value: string; label: string }> = [];
    hsCodeFinderForm: FormGroup;
    formIsInvalid = false;
    current_step: number;
    lang: string;
    user: User;
    hsCodeFinderRequest: HsCodeFinderRequest;
    hsCodeFinderResult: HsCodeFinderResult[];
    resultTimestamp: number;
    selectedCountry: string;
    expectedCsvContent: FormatCsv;
    importedImageContent = '';
    importedInvoiceContent = '';
    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    types = [];

    filteredOptions: Observable<string[]> = new Observable<string[]>();

    to = null;
    from = null;

    countryType = 'import';

    reviewHscodeForm: FormGroup;
    modalRef: BsModalRef;

    addProductForm: FormGroup;
    unit_weight_types: Array<any>;
    booleanChoices: {value: string, label: string}[];

    displayCsvMapper: Subject<void> = new Subject<void>();
    csvModelFields: CsvFieldToSet[] = [];
    externalCsvFields: string[] = [];

    // audio vars
    audioUrl: string;
    audioBase64: string;
    recordingAudio = false;
    audioMaxTime = 6000;
    audioSeconds = 0;
    audioTime = 0;
    private recordAudio: any;
    private audioRecordTimeout: any;
    private recordAudioSecondsPassedInterval: any;

    // max simultaneous requests to send in 1 request
    private readonly maxSimultaneousRequests;

    private readonly hsCodeFinderUrlV1: string;
    private readonly hsCodeFinderUrlV2: string;
    private readonly hsCodeFinderUrlV3: string;
    private readonly hsCodeFinderUrlV4: string;
    private readonly aiImprovementHscodeReviewUrl: string;
    private readonly uploadImageUrl: string;
    private readonly uploadInvoicePdf: string;
    private readonly uploadAudioUrl: string;
    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private _notification: MessageService,
        private userService: UserService,
        private modalService: BsModalService,
        private csvService: CsvService,
        private translate: TranslateService,
        private managersService: ManagersService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer
    ) {
        this.maxSimultaneousRequests = 50;
        this.hsCodeFinderUrlV1 = `https://api.${environment.baseUrl}/v1/taxsrv/hscodefinder`;
        this.hsCodeFinderUrlV2 = `https://api.${environment.baseUrl}/v2/taxsrv/hscodefinder`;
        this.hsCodeFinderUrlV3 = `https://api.${environment.baseUrl}/v3/taxsrv/hscodefinder`;
        this.hsCodeFinderUrlV4 = `https://api.${environment.baseUrl}/v4.1/taxsrv/hscodefinder`;
        this.aiImprovementHscodeReviewUrl = `https://api.${environment.baseUrl}/v1/ai/improvement`;
        this.uploadImageUrl = `https://api.${environment.baseUrl}/v1/import/image`;
        this.uploadInvoicePdf = `https://api.${environment.baseUrl}/v1/import/invoicePdf`;
        this.uploadAudioUrl = `https://api.${environment.baseUrl}/v1/import/audio`;
        this.current_step = HS_STEP_ENUM.SEARCH;
        this.initForm();
    }
    async ngOnInit() {
        await this.initUserPrefs();
        // get the type (import / export) and reinitialize all
        this.route.paramMap.subscribe(params => {
            this.from = null;
            this.to = null;
            this.initForm();
            this.countryType = params.get('type');
            this.initTranslation();
            this.newSearch();
        });
        this.managersService.prepareProducts();
    }

    ngOnDestroy(): void {
        this.clearTimeoutAudio();
    }

    openModal(template: TemplateRef<any>, type: string, hsCodeFinderResult: HsCodeFinderResult) {
        this.formIsInvalid = false;
        const config = { class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false } as ModalOptions;

        if (type === 'reviewhscode') {
            this.initReviewHscodeForm();
        } else if (type === 'addproduct') {
            this.prepareProductForm(hsCodeFinderResult);
        }
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.formIsInvalid = false;
        if (this.modalRef) {
            this.modalRef.hide();
            this.modalRef = null;
        }
    }

    async onValidModalContent() {
        if (this.reviewHscodeForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const aiImprovementRequest = Builder(AiImprovementRequest)
            .review_hscode(this.reviewHscodeForm.value.review_hscode)
            .timestamp(this.resultTimestamp)
            .build();
        await this.callAiImprovement(aiImprovementRequest);
        this.closeModal();
    }

    initReviewHscodeForm() {
        this.reviewHscodeForm = new FormGroup({
            review_hscode: new FormControl(null, [Validators.required])
        });
    }

    initForm() {
        this.hsCodeFinderForm = new FormGroup({
            product: new FormGroup({
                identification: new FormGroup({
                    value: new FormControl('', [Validators.required]),
                    type: new FormControl(this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT ? 'BTOC' : 'FRET', [Validators.required]),
                }),
            }),
            from_country: new FormControl(this.from, [Validators.required]),
            to_country: new FormControl(this.to, [Validators.required]),
            ai_score: new FormControl(false, []),
            multi_results: new FormControl(1, [Validators.min(1)]),
            hsCodeFinderSearch: new FormControl('TEXTUEL'),
        });

        this.hsCodeFinderForm.get('hsCodeFinderSearch').valueChanges.subscribe(() => {
            this.formIsInvalid = false;
        });
    }

    // control integer number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    formIsValid(): boolean {
        const type = ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value;
        const value = ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['value'].value;
        if (
            type && value.length > 0 && (
                (type === 'HSCODE' && this.from && this.to) ||
                (type !== 'HSCODE' && ((this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT && this.to) || (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT && this.from)))
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    initTranslation() {
        this.translate.get('messages').subscribe((messages) => {
            this.updateLangMessages(messages);
        });
        this.translate.get('hsCodeFinder.countries').subscribe((hs17Countries) => {
            this.translate.get('countries').subscribe((allCountries) => {
                this.countries = allCountries.filter(c => hs17Countries.includes(c.value)).sort(Utils.compareByLabel);
                this.hsCodeFinderForm.get('to_country').updateValueAndValidity();
            });
        });
        this.translate.get('unitTypes').toPromise().then(unit_types => {
            this.unit_weight_types = unit_types.weight;
        });
        this.translate.get('hsCodeFinder').subscribe((hsCodeFinder) => {
            if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT) {
                this.types = hsCodeFinder.typesExport;
            } else {
                this.types = hsCodeFinder.typesImport;
            }
        });
        this.translate.get('profile.boolean_choice').toPromise().then((categories) => {
            this.booleanChoices = categories;
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.unit_weight_types = event.translations['unitTypes']['weight'];
            const messages = event.translations['messages'];
            this.updateLangMessages(messages);
            const hs17Countries = event.translations['hsCodeFinder']['countries'];
            this.countries = event.translations['countries'].filter(c => hs17Countries.includes(c.value)).sort(Utils.compareByLabel);
            this.hsCodeFinderForm.get('to_country').updateValueAndValidity();
            if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT) {
                this.types = event.translations['hsCodeFinder'].typesExport;
            } else {
                this.types = event.translations['hsCodeFinder'].typesImport;
            }
            this.booleanChoices = event.translations.profile.boolean_choice;
        });
    }
    updateLangMessages(messages) {
        this.unknown_error = messages.notification.unknown_error;
        this.emptyCSV = messages.notification.unknown_error;
        this.wrongCSV = messages.notification.unknown_error;
        this.lang = this.user.lang ? this.user.getLangIso2() : messages.current_lang;
    }
    private async initUserPrefs() {
        let user = this.userService.getUser();
        if (!user) {
            user = await this.authService.reconnect();
        }
        this.user = user;
    }

    switch() {
        const tempFrom = this.from;
        const tempTo = this.to;
        this.from = tempTo;
        this.to = tempFrom;
    }

    getNested(nested: string) {
        return this.hsCodeFinderForm.get(nested);
    }

    async searchHsCodeFinder(): Promise<void> {
        if (! this.formIsValid()) {
            this.formIsInvalid = true;
            return;
        }
        this.hsCodeFinderRequest = this.hsCodeFinderForm.value as HsCodeFinderRequest;
        const originalIdentificationType = this.hsCodeFinderRequest.product.identification.type;
        if (
            this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BARCODE
            ||
            this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FRET
            ||
            this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FOOD
            ||
            this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOC
            ||
            this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOB
        ) {
            this.hsCodeFinderRequest.product.identification.type = HsCodeFinderEnum.TEXT;
        }
        if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT && this.hsCodeFinderRequest.product.identification.type !== HsCodeFinderEnum.HSCODE) {
            this.hsCodeFinderRequest.from_country = undefined;
        }
        if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT && this.hsCodeFinderRequest.product.identification.type !== HsCodeFinderEnum.HSCODE) {
            this.hsCodeFinderRequest.to_country = this.hsCodeFinderRequest.from_country;
        }
        this.hsCodeFinderRequest.lang = this.lang;
        this.selectedCountry = this.findCountryLabel(
            this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT ? this.hsCodeFinderRequest.to_country : this.hsCodeFinderRequest.from_country
        );
        switch (originalIdentificationType) {
            case HsCodeFinderEnum.FRET:
                await this.callHsCodeFinderV4(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;

            case HsCodeFinderEnum.TEXT:
                await this.callHsCodeFinderV3(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;

            case HsCodeFinderEnum.FOOD:
                await this.callHsCodeFinderV3(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;

            case HsCodeFinderEnum.SKU:
                await this.callHsCodeFinderV1(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;

            case HsCodeFinderEnum.INVOICE_URL:
                await this.searchFromImportedInvoice(this.hsCodeFinderRequest.product.identification.value);
                break;

            case HsCodeFinderEnum.BTOB:
                await this.callHsCodeFinderV2(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;
            case HsCodeFinderEnum.BTOC:
                await this.callHsCodeFinderV4(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;

            default:
                await this.callHsCodeFinderV1(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                    const response = hsCodeFinderResponse.response;
                    this.resultTimestamp = hsCodeFinderResponse.timestamp;
                    if (hsCodeFinderResponse.code !== 200) {
                        this._notification.displayMessage(response, 'danger');
                        return;
                    }
                    this.hsCodeFinderResult = response as HsCodeFinderResult[];
                    if (this.child) {
                        this.child.setLabel(1);
                    }
                    this.current_step = HS_STEP_ENUM.RESULTS;
                });
                break;
        }
    }
    async callAiImprovement(aiImprovementRequest: AiImprovementRequest): Promise<any> {
        return this.http
            .post(this.aiImprovementHscodeReviewUrl, aiImprovementRequest)
            .toPromise()
            .then((response: any) => {
                this._notification.displayMessage(this.translate.instant('hsCodeFinder.results.reviewHscode.success'), 'success');
                return { code: 200, request: aiImprovementRequest, response: response.result };
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(this.unknown_error, 'error');
                return { code: errorResponse.status, request: aiImprovementRequest };
            });
    }
    async callHsCodeFinderV1(hsCodeFinderRequest: HsCodeFinderRequest | HsCodeFinderRequests): Promise<any> {
        return this.http
            .post(this.hsCodeFinderUrlV1, hsCodeFinderRequest)
            .toPromise()
            .then((response: any) => {
                return { code: 200, request: hsCodeFinderRequest, response: Array.isArray(response) ? (Array.isArray(response[0]) ? response.map(res => res.map(r => r.result)) : response.map(r => r.result)) : [response.result], timestamp: response.timestamp };
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                return { code: errorResponse.status, request: hsCodeFinderRequest, response: transiteoError };
            });
    }
    async callHsCodeFinderV2(hsCodeFinderRequest: HsCodeFinderRequest): Promise<any> {
        return this.http
            .post(this.hsCodeFinderUrlV2, hsCodeFinderRequest)
            .toPromise()
            .then((response: any) => {
                return { code: 200, request: hsCodeFinderRequest, response: [response.result], timestamp: response.timestamp };
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                return { code: errorResponse.status, request: hsCodeFinderRequest, response: transiteoError };
            });
    }
    async callHsCodeFinderV3(hsCodeFinderRequest: HsCodeFinderRequest): Promise<any> {
        return this.http
            .post(this.hsCodeFinderUrlV3, hsCodeFinderRequest)
            .toPromise()
            .then((response: any) => {
                return { code: 200, request: hsCodeFinderRequest, response: [response[0].result], timestamp: response[0].timestamp };
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                return { code: errorResponse.status, request: hsCodeFinderRequest, response: transiteoError };
            });
    }
    async callHsCodeFinderV4(hsCodeFinderRequest: HsCodeFinderRequest | HsCodeFinderRequests): Promise<any> {
        return this.http
            .post(this.hsCodeFinderUrlV4, hsCodeFinderRequest)
            .toPromise()
            .then((response: any) => {
                return { code: 200, request: hsCodeFinderRequest, response: Array.isArray(response) ? (Array.isArray(response[0]) ? response.map(res => res.map(r => r.result)) : response.map(r => r.result)) : [response.result], timestamp: response.timestamp };
            })
            .catch((errorResponse) => {
                const transiteoError = this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error);
                return { code: errorResponse.status, request: hsCodeFinderRequest, response: transiteoError };
            });
    }
    // upload the image, get back the URL
    async uploadImage(imageContent: string): Promise<string> {
        try {
            const res = await this.http.post(this.uploadImageUrl, { content: imageContent }).toPromise() as { url: string; };
            return res.url;
        } catch (errorResponse) {
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    // upload the invoice, get back the products texts
    async uploadInvoice(pdfContent: string): Promise<string[]> {
        try {
            const res = await this.http.post(this.uploadInvoicePdf, { content: pdfContent }).toPromise() as string[];
            return res;
        } catch (errorResponse) {
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    // upload the audio, get back the text
    async uploadAudio(audioContent: string): Promise<string> {
        try {
            const res = await this.http.post(this.uploadAudioUrl, { content: audioContent, lang: this.translate.currentLang }).toPromise() as {text: string};
            return res.text;
        } catch (errorResponse) {
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
    toCountryIsSet(): boolean {
        const value = this.hsCodeFinderForm.value;
        return value && value.to_country && value.to_country.length > 0;
    }
    get f() {
        return this.hsCodeFinderForm.controls;
    }

    generateCsv() {
        const hsCodeFinderRequest = this.hsCodeFinderForm.value as HsCodeFinderRequest;
        const flattenRequest = this.csvService.flattenObjectToBuildCsv('request_', hsCodeFinderRequest);
        const flattenResponse = this.csvService.flattenObjectToBuildCsv('response_', this.hsCodeFinderResult);
        const wholeData = flattenRequest;
        const keys = Object.keys(flattenResponse);
        keys.forEach(key => {
            wholeData[key] = flattenResponse[key];
        });
        this.csvService.downloadFile(wholeData, 'hsCodeFinder_' + (new Date()).getTime());
    }
    buildHsCodeFinderCsv(request: HsCodeFinderRequest, result: HsCodeFinderResult[]): any[] {
        const mappedUserCsv = this.expectedCsvContent ? this.expectedCsvContent.mappedFields : null;
        if (mappedUserCsv) {
            let headerType = 'type';
            let headerProduct = 'name';
            let headerToCountry = 'to_country';
            let headerFromCountry = 'from_country';
            let found = mappedUserCsv.find(m => m.id === 'product' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerProduct = found.externalField;
            }
            found = mappedUserCsv.find(m => m.id === 'to_country' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerToCountry = found.externalField;
            }
            found = mappedUserCsv.find(m => m.id === 'type' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerType = found.externalField;
            }
            found = mappedUserCsv.find(m => m.id === 'from_country' && m.externalField && m.externalField.length > 0);
            if (found) {
                headerFromCountry = found.externalField;
            }
            return result.map(res => {
                const row: any = {
                    code: res.hs_code,
                    [headerType]: request.product.identification.type,
                    [headerProduct]: request.product.identification.value,
                    lang: this.lang,
                    [headerFromCountry]: request.from_country,
                    hs_version: res.hs_version,
                    hs_code: res.hs_code,
                    designation: res.designation,
                    statut: res.product_statut
                };
                if (request.ai_score) {
                    row.ai_score = res.ai_score;
                }
                if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT) {
                    row[headerToCountry] = request.to_country;
                }
                return row;
            });
        }
        return result.map(res => {
            const row: any = {
                code: res.hs_code,
                lang: this.lang,
                hs_version: res.hs_version,
                hs_code: res.hs_code,
                designation: res.designation,
                statut: res.product_statut
            };
            if (request.ai_score) {
                row.ai_score = res.ai_score;
            }
            return row;
        });
    }
    findCountryLabel(iso: string) {
        const findCountry = iso && iso.length === 3 ? this.countries.find((country) => country.value === iso) : null;
        return findCountry ? findCountry.label : iso;
    }

    addDot(value: string) {
        let withDot = '';
        for (let i = 0; i < value.length; i++) {
            if (i === 2 || i === 4 || i === 6) {
                withDot += '.';
            }
            withDot += value[i];
        }
        return withDot;
    }

    statutResult(res: HsCodeFinderResult) {
        if (res.product_statut.toLowerCase().startsWith('a')) {
            return 'authorized';
        } else if (res.product_statut.toLowerCase().startsWith('r')) {
            return 'restricted';
        } else if (res.product_statut.toLowerCase().startsWith('p')) {
            return 'prohibited';
        }
    }

    newSearch() {
        this.initForm();
        if (this.audioTime > 0) {
            this.reinitializeAudioVars();
        }
        this.current_step = HS_STEP_ENUM.SEARCH;
        if (this.child) {
            this.child.setLabel(0);
        }
    }

    async copyToClipboard(data) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', data);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.pop_hs_code.show();
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.pop_hs_code.hide();
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            // get expected csv Format
            this.expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = this.expectedCsvContent.toExpectedCsvContent();
            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
                // remove first line csv;
                const headerLine = csvRecordsArray.shift();
                const headerLineLength = headerLine.split(';').length;

                // check for wrong lines
                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    // not same columns number
                    if (headerLineLength !== line.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
                        return true;
                    }
                    return false;
                });
                if (wrongLines.length > 0) {
                    this.removeFile(file);
                    return;
                }

                // not right columns names? use csv-mapper component
                if (
                    (
                        this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT &&
                        (
                            headerLineLength < 3 ||
                            headerLineLength > 6 ||
                            (
                                headerLineLength === 3 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'type']) ||
                                (
                                    headerLineLength === 4 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'from_country', 'type']) &&
                                    headerLineLength === 4 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'ai_score', 'type']) &&
                                    headerLineLength === 4 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'multi_results', 'type'])
                                ) ||
                                (
                                    headerLineLength === 5 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'from_country', 'multi_results', 'type']) &&
                                    headerLineLength === 5 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'from_country', 'ai_score', 'type']) &&
                                    headerLineLength === 5 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'ai_score', 'multi_results', 'type'])
                                ) ||
                                headerLineLength === 6 && !this.expectedCsvContent.headerContains(['product', 'to_country', 'from_country', 'ai_score', 'multi_results', 'type'])
                            )
                        )
                    )
                    ||
                    (
                        this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT &&
                        (
                            headerLineLength < 3 ||
                            headerLineLength > 5 ||
                            (
                                headerLineLength === 3 && !this.expectedCsvContent.headerContains(['product', 'from_country', 'type']) ||
                                (
                                    headerLineLength === 4 && !this.expectedCsvContent.headerContains(['product', 'from_country', 'ai_score', 'type']) &&
                                    headerLineLength === 4 && !this.expectedCsvContent.headerContains(['product', 'from_country', 'multi_results', 'type'])
                                ) ||
                                headerLineLength === 5 && !this.expectedCsvContent.headerContains(['product', 'from_country', 'ai_score', 'multi_results', 'type'])
                            )
                        )
                    )
                ) {
                    this.externalCsvFields = this.expectedCsvContent.getHeaders();
                    this.csvModelFields = this.buildCsvModelFields();
                    this.onDisplayCsvMapper();
                    /*this._notification.displayMessage(`Line 0 : ${this.wrongCSV} : "${headerLine}"`, 'danger');
                    this.removeFile(file);*/
                    return;
                }
            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }
    buildCsvModelFields(): CsvFieldToSet[] {
        const modelFields: CsvFieldToSet[] = [];
        let expectedCsvFields;
        if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT) {
            expectedCsvFields = {
                type: {
                    isRequired: true
                },
                product: {
                    isRequired: true
                },
                to_country: {
                    isRequired: true
                },
                from_country: {
                    isRequired: false
                },
                ai_score: {
                    isRequired: false
                },
                multi_results: {
                    isRequired: false
                }
            };
        } else {
            expectedCsvFields = {
                type: {
                    isRequired: true
                },
                product: {
                    isRequired: true
                },
                from_country: {
                    isRequired: true
                },
                ai_score: {
                    isRequired: false
                },
                multi_results: {
                    isRequired: false
                }
            };
        }
        const keys = Object.keys(expectedCsvFields);

        keys.forEach(f => {
            modelFields.push(
                Builder(CsvFieldToSet)
                .id(f)
                .externalField('')
                .isRequired(expectedCsvFields[f].isRequired)
                .label(this.translate.instant(`csvMapper.fieldsLabels.${f}`))
                .build()
            );
        });
        return modelFields;
    }
    onDisplayCsvMapper() {
        this.displayCsvMapper.next();
    }
    onCsvExternalMapped(mappedFields: CsvFieldToSet[]) {
        if (! mappedFields) {
            this.expectedCsvContent = null;
        } else {
            this.expectedCsvContent.modifyHeadersWithMappedFields(mappedFields);
        }
    }
    beforeUploadImage = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file as any);
        this.cleanFiles(file);
        reader.onloadend = async (e) => {
            this.importedImageContent = reader.result as string;
        };
        return true;
    }
    beforeUploadInvoice = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file as any);
        this.cleanFiles(file);
        reader.onloadend = async (e) => {
            this.importedInvoiceContent = reader.result as string;
        };
        return true;
    }
    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.expectedCsvContent = null;
        this.importedImageContent = '';
        this.importedInvoiceContent = '';
        return false;
    }
    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.expectedCsvContent = null;
        this.importedImageContent = '';
        this.importedInvoiceContent = '';
    }
    async searchFromCSV() {
        const requests: HsCodeFinderRequests[] = [Builder(HsCodeFinderRequests).hsCodeFinderRequests([]).build()];
        const rows = this.expectedCsvContent.getContent();
        const headers = rows.shift().split(';');
        const aiScoreIndex = headers.findIndex(h => h === 'ai_score');
        const multiResultsIndex = headers.findIndex(h => h === 'multi_results');
        rows.forEach((record) => {
            const line = record.split(';');
            let hsCodeFinderRequest: HsCodeFinderRequest;
            if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.IMPORT) {
                let type = line[headers.findIndex(h => h === 'type')].toUpperCase();
                if (!['TEXT', 'HSCODE', 'SKU', 'CATEGORY', 'IMAGE_URL'].includes(type)) {
                    type = 'TEXT';
                }
                const identification = Builder(HsCodeFinderIdentification)
                    .type(type as HsCodeFinderEnum)
                    .value(line[headers.findIndex(h => h === 'product')])
                    .build();
                hsCodeFinderRequest = Builder(HsCodeFinderRequest)
                    .lang(this.lang)
                    .product({ identification: identification })
                    .to_country(line[headers.findIndex(h => h === 'to_country')])
                    .from_country(line[headers.findIndex(h => h === 'from_country')])
                    .ai_score(aiScoreIndex < 0 || ['f', 'false', '0'].includes(line[aiScoreIndex].toLowerCase()) ? false : true)
                    .multi_results(multiResultsIndex < 0 || isNaN(parseInt(line[multiResultsIndex])) || parseInt(line[multiResultsIndex]) < 1 ? 1 : parseInt(line[multiResultsIndex]))
                    .build();
            } else {
                let type = line[headers.findIndex(h => h === 'type')].toUpperCase();
                if (!['TEXT', 'IMAGE_URL'].includes(type)) {
                    type = 'TEXT';
                }
                const identification = Builder(HsCodeFinderIdentification)
                    .type(type as HsCodeFinderEnum)
                    .value(line[headers.findIndex(h => h === 'product')])
                    .build();
                hsCodeFinderRequest = Builder(HsCodeFinderRequest)
                    .lang(this.lang)
                    .product({ identification: identification })
                    .from_country(line[headers.findIndex(h => h === 'from_country')])
                    .ai_score(aiScoreIndex < 0 || ['f', 'false', '0'].includes(line[aiScoreIndex].toLowerCase()) ? false : true)
                    .multi_results(multiResultsIndex < 0 || isNaN(parseInt(line[multiResultsIndex])) || parseInt(line[multiResultsIndex]) < 1 ? 1 : parseInt(line[multiResultsIndex]))
                    .build();
            }

            // do requests maxSimultaneousRequests by maxSimultaneousRequests
            if (requests[requests.length - 1].hsCodeFinderRequests.length >= this.maxSimultaneousRequests) {
                requests.push(Builder(HsCodeFinderRequests).hsCodeFinderRequests([]).build());
            }
            requests[requests.length - 1].hsCodeFinderRequests.push(hsCodeFinderRequest);
        });

        let csvBuilder = [];
        for (let i = 0; i < requests.length; i++) {
            await this.callAndHandleRequest(requests, i, csvBuilder);
        }
        const mappedUserCsv = this.expectedCsvContent ? this.expectedCsvContent.mappedFields : null;
        let multiResultsHeader = 'multi_results';
        if (mappedUserCsv) {
            const found = mappedUserCsv.find(m => m.id === 'multi_results' && m.externalField && m.externalField.length > 0);
            if (found) {
                multiResultsHeader = found.externalField;
            }
        }
        csvBuilder = this.expectedCsvContent.mergeOriginalCsvWithResponse(csvBuilder, multiResultsHeader, [multiResultsHeader]);
        if (csvBuilder.length > 0) {
            return this.csvService.downloadFile(csvBuilder, `hsCodeFinder_search_${new Date().getTime()}`);
        }
    }
    private async callAndHandleRequest(requests: HsCodeFinderRequests[], i: number, csvBuilder: any[], errorCount = 0) {
        const res = await this.callHsCodeFinderV4(requests[i]);

        // server unexpected error, try once again a bit later, 3 times maximum
        if (res.code >= 500 && errorCount < 2) {
            await this.delay(2);
            await this.callAndHandleRequest(requests, i, csvBuilder, errorCount + 1);

        } else if (res.code !== 200) {
            // At least one text cannot be handled in the request. We must divide the request in requests of 1 product
            if (res.code === 404 && requests[i].hsCodeFinderRequests.length > 1) {
                for (let j = 0; j < requests[i].hsCodeFinderRequests.length; j++) {
                    const singleRequestResult = await this.callHsCodeFinderV4(requests[i].hsCodeFinderRequests[j]);
                    if (singleRequestResult.code !== 200) {
                        this._notification.displayMessage(singleRequestResult.response, 'warning');
                        const newCsvRows = this.buildHsCodeFinderCsv(requests[i].hsCodeFinderRequests[j], [Builder(HsCodeFinderResult).build()]);
                        newCsvRows.forEach(row => {
                            csvBuilder.push(row);
                        });
                    } else {
                        this._notification.displayMessage(
                            this.translate.instant(
                                'hsCodeFinder.csvForm.success',
                                {
                                    done: i * this.maxSimultaneousRequests + j + 1,
                                    total: (requests.length - 1) * this.maxSimultaneousRequests + requests[requests.length - 1].hsCodeFinderRequests.length
                                }
                            ),
                            'success',
                            2000
                        );
                        const newCsvRows = this.buildHsCodeFinderCsv(requests[i].hsCodeFinderRequests[j], Array.isArray(singleRequestResult.response) ? singleRequestResult.response : [singleRequestResult.response]);
                        newCsvRows.forEach(row => {
                            csvBuilder.push(row);
                        });
                    }
                }
            } else {
                this._notification.displayMessage(res.response, 'warning');
                for (let j = 0; j < requests[i].hsCodeFinderRequests.length; j++) {
                    const newCsvRows = this.buildHsCodeFinderCsv(requests[i].hsCodeFinderRequests[j], [Builder(HsCodeFinderResult).build()]);
                    newCsvRows.forEach(row => {
                        csvBuilder.push(row);
                    });
                }
            }
        } else {
            this._notification.displayMessage(
                this.translate.instant(
                    'hsCodeFinder.csvForm.success',
                    {
                        done: i < requests.length - 1 ?
                            (i + 1) * this.maxSimultaneousRequests :
                            (requests.length - 1) * this.maxSimultaneousRequests + requests[requests.length - 1].hsCodeFinderRequests.length,
                        total: (requests.length - 1) * this.maxSimultaneousRequests + requests[requests.length - 1].hsCodeFinderRequests.length
                    }
                ),
                'success',
                5000
            );
            for (let j = 0; j < requests[i].hsCodeFinderRequests.length; j++) {
                const newCsvRows = this.buildHsCodeFinderCsv(requests[i].hsCodeFinderRequests[j], Array.isArray(res.response[j]) ? res.response[j] : [res.response[j]]);
                newCsvRows.forEach(row => {
                    csvBuilder.push(row);
                });
            }
        }
    }
    isActualStep(step: number) {
        return step === this.current_step;
    }

    async searchFromImportedImage() {
        if (! this.toCountryIsSet()) {
            this.formIsInvalid = true;
            return;
        }
        const url = await this.uploadImage(this.importedImageContent);
        if (url) {
            this.hsCodeFinderRequest = this.hsCodeFinderForm.value as HsCodeFinderRequest;
            if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT) {
                this.hsCodeFinderRequest.from_country = this.hsCodeFinderRequest.to_country;
                delete this.hsCodeFinderRequest.to_country;
            }
            this.hsCodeFinderRequest.product.identification.value = url;
            this.hsCodeFinderRequest.product.identification.type = HsCodeFinderEnum.IMAGE_URL;

            await this.callHsCodeFinderV4(this.hsCodeFinderRequest).then((hsCodeFinderResponse) => {
                const response = hsCodeFinderResponse.response;
                this.resultTimestamp = hsCodeFinderResponse.timestamp;
                if (hsCodeFinderResponse.code !== 200) {
                    this._notification.displayMessage(response, 'danger');
                    return;
                }
                this.hsCodeFinderResult = response as HsCodeFinderResult[];
                if (this.child) {
                    this.child.setLabel(1);
                }
                this.current_step = HS_STEP_ENUM.RESULTS;
            });
        }
    }

    async searchFromImportedInvoice(invoiceUrl?: string) {
        if (! this.toCountryIsSet()) {
            this.formIsInvalid = true;
            return;
        }
        const texts = await this.uploadInvoice(invoiceUrl ? invoiceUrl : this.importedInvoiceContent);
        if (texts && texts.length > 0) {
            const to_country = this.hsCodeFinderForm.value.to_country;
            const ai_score = this.hsCodeFinderForm.value.ai_score;
            const multi_results = this.hsCodeFinderForm.value.multi_results ? this.hsCodeFinderForm.value.multi_results : 1;
            const simulatedCsvContent: string[] = [];

            if (this.countryType === AI_CLASSIFY_COUNTRY_TYPE.EXPORT) {
                simulatedCsvContent.push('type;product;from_country;ai_score;multi_results');
            } else {
                simulatedCsvContent.push('type;product;to_country;ai_score;multi_results');
            }
            texts.forEach(text => {
                simulatedCsvContent.push(`${HsCodeFinderEnum.TEXT};${text};${to_country};${ai_score};${multi_results}`);
            });
            this.expectedCsvContent = new FormatCsv(simulatedCsvContent);
            await this.searchFromCSV();
        }
    }

    async searchFromAudioRecorded() {
        if (! this.toCountryIsSet()) {
            this.formIsInvalid = true;
            return;
        }
        const text = await this.uploadAudio(this.audioBase64);
        if (text && text.length > 0) {
            this.hsCodeFinderForm.get('product.identification.type').setValue(HsCodeFinderEnum.BTOC);
            this.hsCodeFinderForm.get('product.identification.value').setValue(text);
            this.searchHsCodeFinder();
        }
    }

    requestTypeIsText(): boolean {
        return this.hsCodeFinderRequest &&
            this.hsCodeFinderRequest.product &&
            this.hsCodeFinderRequest.product.identification &&
            (
                this.hsCodeFinderRequest.product.identification.type === 'TEXT'
                ||
                this.hsCodeFinderRequest.product.identification.type === 'BTOC'
                ||
                this.hsCodeFinderRequest.product.identification.type === 'BTOB'
                ||
                this.hsCodeFinderRequest.product.identification.type === 'BARCODE'
            );
    }

    getPersonalEcoTax(hsCodeFinderResult: HsCodeFinderResult): { modul: string; from: string; to: string; code: string; price: string; }[] {
        const res: { modul: string; from: string; to: string; code: string; price: string; }[] = [];
        if (hsCodeFinderResult && hsCodeFinderResult.ecoTax && hsCodeFinderResult.ecoTax.personal) {
            hsCodeFinderResult.ecoTax.personal.forEach(ecotax => {
                const moduls = Object.keys(ecotax);
                moduls.forEach(modul => {
                    res.push({
                        modul: modul.replace('_', ' '),
                        from: ecotax[modul].range[0] + ' kg',
                        to: ecotax[modul].range.length === 2 ?
                            ` ${this.translate.instant('hsCodeFinder.results.ecoTaxUntil')} ${ecotax[modul].range[1]} kg` :
                            '',
                        code: ecotax[modul].code,
                        price: ecotax[modul].price.toFixed(2)
                    });
                });
            });
        }
        return res;
    }

    getProEcoTax(hsCodeFinderResult: HsCodeFinderResult): { modul: string; code: string; price: string; }[] {
        const res: { modul: string; code: string; price: string; }[] = [];
        if (hsCodeFinderResult && hsCodeFinderResult.ecoTax && hsCodeFinderResult.ecoTax.personal) {
            hsCodeFinderResult.ecoTax.personal.forEach(ecotax => {
                const moduls = Object.keys(ecotax);
                moduls.forEach(modul => {
                    res.push({
                        modul: modul.replace('_', ' '),
                        code: ecotax[modul].code,
                        price: ecotax[modul].price.toFixed(2)
                    });
                });
            });
        }
        return res;
    }

    prepareAutocompleteProduct() {
        const control = ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['value'];
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), 'sku')),
        );
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        if (value !== null && value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            return wholeValues.filter(option => {
                const optionSplitted = option.split(', ');
                if (filterField === 'sku') {
                    return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
                }
                let i = 0;
                if (filterField === 'email') {
                    i = 1;
                } else if (filterField === 'website') {
                    i = 2;
                }
                return optionSplitted[i].toLowerCase().includes(filterValue);
            });
        }
    }

    onProductClicked(option: string) {
        // sku is the sort key
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['value'].setValue(rightProduct.sku);
        }
    }

    prepareProductForm(hsCodeFinderResult: HsCodeFinderResult) {
        this.addProductForm = new FormGroup({
            type: new FormControl(CUSTOMER_PRODUCT_TYPE_ENUM.SKU, [Validators.required]),
            productID: new FormControl(
                (
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.TEXT
                        ||
                        this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FRET
                        ||
                        this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BARCODE
                        ||
                        this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FOOD
                        ||
                        this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOB
                        ||
                        this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOC
                        ?
                        this.hsCodeFinderRequest.product.identification.value
                        :
                        hsCodeFinderResult.splitted_designation.national_designation
                )
                ,
                [Validators.required]
            ),
            sku: new FormControl('', []),
            hscode: new FormControl(hsCodeFinderResult.hs_code, [Validators.required]),
            to_country: new FormControl(this.hsCodeFinderRequest.to_country, [Validators.required]),
            weight: new FormControl('', [Validators.required]),
            weight_unit: new FormControl('', [Validators.required])
        });
    }

    // the result can be added in the catalog? Only TEXT / BTOB / BTOC / HSCODE / IMAGE_URL can be added in catalog
    resultCanBeAddedInCatalog(hsCodeFinderResult: HsCodeFinderResult): boolean {
        if (this.hsCodeFinderRequest && this.hsCodeFinderRequest.product && this.hsCodeFinderRequest.product.identification) {
            if (
                hsCodeFinderResult.hs_code &&
                (
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.TEXT ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FRET ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BARCODE ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FOOD ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOB ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOC ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.HSCODE ||
                    this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.IMAGE_URL
                )
            ) {
                return true;
            }
        }
        return false;
    }

    async onValidProduct() {
        if (this.addProductForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.addProductForm.value;

        const product = Builder(CustomerProduct)
            .type(formValue.type)
            .sku(formValue.sku ? formValue.sku : undefined)
            .value(formValue.productID)
            .weight(parseFloat(formValue.weight))
            .weight_unit(formValue.weight_unit)
            .hscodefinder_hs_code(formValue.hscode)
            .to_country(formValue.to_country)
            .build();

        // post new product
        await this.postProduct(product);
        this.closeModal();
    }

    textCannotBeModified(): boolean {
        return this.hsCodeFinderRequest && this.hsCodeFinderRequest.product && this.hsCodeFinderRequest.product.identification &&
            (
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.TEXT
                ||
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BARCODE
                ||
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FRET
                ||
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.FOOD
                ||
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOB
                ||
                this.hsCodeFinderRequest.product.identification.type === HsCodeFinderEnum.BTOC
            );
    }

    // post product
    async postProduct(product: CustomerProduct): Promise<void> {
        // if SKU is not set, we must set 1
        if (!product.sku || product.sku.length <= 1) {
            // 10 first value capitalized characters + 10 first timestamp value (until seconds)
            product.sku = (await normalize(product.value.replace(/\s/g, ''))).substring(0, 10).toUpperCase()
                +
                (new Date()).getTime().toString().substring(0, 10);
        }
        if (product.hscodefinder_hs_code) {
            product.hscodefinder_hs_code = product.hscodefinder_hs_code.toString();
        }

        if (typeof product.sku === 'number') {
            product.sku = (product.sku as number).toString();
        }
        try {
            const productAdded = await this.http.post(`https://api.${environment.baseUrl}/v1/customer/products/`, product).toPromise();
            // add new product in managersService, it must be used to search a SKU
            this.managersService.addProduct(productAdded as CustomerProduct);
            this.managersService.prepareProducts();
            this._notification.displayMessage(this.translate.instant('hsCodeFinder.results.productAdded'), 'success');
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(
                product.value + ' : ' +
                this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error),
                'danger'
            );
        }
    }

    sanitize(url: string): SafeUrl {
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    // Start recording
    onStartAudioRecord(): void {
        this.audioUrl = null;
        this.audioBase64 = null;
        this.recordingAudio = true;
        this.audioSeconds = 0;
        this.audioTime = 0;
        const mediaConstraints = {
            video: false,
            audio: true
        };
        navigator.mediaDevices.getUserMedia(mediaConstraints).then(this.successCallback.bind(this), this.errorCallback.bind(this));
    }

    onPlayAudio() {
        this.clearTimeoutAudio();
        const audio = document.getElementById('audioController') as HTMLAudioElement;
        audio.play();
        this.audioSeconds = 0;
        this.recordAudioSecondsPassedInterval = setInterval(() => {
            this.incrementAudioSecondsPassed(100);
        }, 100);
        this.audioRecordTimeout = setTimeout(() => {
            this.clearTimeoutAudio();
        }, this.audioTime);
    }

    // Will be called automatically.
    successCallback(stream): void {
        const options = {
            mimeType: 'audio/wav',
            numberOfAudioChannels: 1,
            audioBitsPerSecond: 128000,
            sampleRate: 48000,
            bufferSize: 16384,
        };
        // Start Actuall Recording
        const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
        this.recordAudio = new StereoAudioRecorder(stream, options);
        this.recordAudio.record();
        this.recordAudioSecondsPassedInterval = setInterval(() => {
            this.incrementAudioSecondsPassed(100);
        }, 100);
        this.audioRecordTimeout = setTimeout(() => {
            this.onStopAudioRecord();
        }, this.audioMaxTime);
    }

    // stop audio recording
    onStopAudioRecord() {
        this.clearTimeoutAudio();
        this.audioTime = this.audioSeconds;
        this.audioRecordTimeout = null;
        this.recordingAudio = false;
        this.recordAudio.stop(this.processRecording.bind(this));
    }

    clearTimeoutAudio() {
        try {
            if (this.audioRecordTimeout) {
                clearTimeout(this.audioRecordTimeout);
            }
            if (this.recordAudioSecondsPassedInterval) {
                clearInterval(this.recordAudioSecondsPassedInterval);
            }
        } catch (error) {
            console.log('Unable to clear audio timeout');
        }
    }

    incrementAudioSecondsPassed(incr: number) {
        this.audioSeconds += incr;
    }

    // recording audio success
    processRecording(blob): void {
        this.audioUrl = URL.createObjectURL(blob);

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            this.audioBase64 = reader.result as string;
        };
    }

    // error while recording
    errorCallback(error) {
        this._notification.displayMessage(
            this._notification.buildTransiteoErrorMessage(this.translate.instant('hsCodeFinder.audioForm.recordError'), this.unknown_error),
            'error'
        );
    }

    private reinitializeAudioVars() {
        this.audioBase64 = null;
        this.audioUrl = null;
        this.audioTime = 0;
        this.audioSeconds = 0;
    }

    getValueLabelTranslation(): string {
        if (this.isText()) {
            return 'product';
        }
        if (this.isBarcode()) {
            return 'barcode';
        }
        if (this.isFood()) {
            return 'food';
        }
        if (this.isHsCode()) {
            return 'hscode';
        }
        if (this.isSku()) {
            return 'sku';
        }
        if (this.isImageUrl()) {
            return 'imageUrl';
        }
        if (this.isInvoiceUrl()) {
            return 'invoiceUrl';
        }
        return 'product';
    }
    getRightTranslationPlaceholder(): string {
        if (this.isText()) {
            return 'typeDescriptionPlaceHolder';
        }
        if (this.isBarcode()) {
            return 'typeBarcodePlaceHolder';
        }
        if (this.isFood()) {
            return 'typeFoodPlaceHolder';
        }
        if (this.isHsCode()) {
            return 'typeHscodePlaceHolder';
        }
        if (this.isSku()) {
            return 'typeSkuPlaceHolder';
        }
        if (this.isImageUrl()) {
            return 'typeImageURLPlaceHolder';
        }
        if (this.isInvoiceUrl()) {
            return 'typeInvoiceURLPlaceHolder';
        }
        return 'typeDescriptionPlaceHolder';
    }
    isText(): boolean {
        const type = ( ( this.f['product'] as FormGroup ).controls['identification'] as FormGroup ).controls['type'].value;
        return [HsCodeFinderEnum.TEXT, HsCodeFinderEnum.BTOC, HsCodeFinderEnum.BTOB, HsCodeFinderEnum.FRET].includes(type);
    }
    isBarcode(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.BARCODE;
    }
    isFood(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.FOOD;
    }
    isHsCode(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.HSCODE;
    }
    isSku(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.SKU;
    }
    isImageUrl(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.IMAGE_URL;
    }
    isInvoiceUrl(): boolean {
        return ((this.f['product'] as FormGroup).controls['identification'] as FormGroup).controls['type'].value === HsCodeFinderEnum.INVOICE_URL;
    }

    changeType(event) {
        if (this.isImageUrl() || this.isInvoiceUrl()) {
            this.f.product.get('identification').get('value').setValidators([Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]);
        } else {
            this.f.product.get('identification').get('value').setValidators([Validators.required]);
        }
        this.f.product.get('identification').get('value').updateValueAndValidity();
    }

    private async delay(waitSeconds): Promise<void> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, waitSeconds * 1000);
        });
    }
}
