import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TransitFees, DutyTransport, RequestProduct, RequestProductAllCurrencies, ShipmentTypeEnum, TaxRequest, ECOMMERCE_REQUEST_TYPE_ENUM, SHIPMENT_REQUEST_TYPE_ENUM} from '../../../../models/DutyCalculation';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Builder} from 'builder-pattern';
import { INCOTERM_ENUM } from '../../../../models/User';
import { Subscription } from 'rxjs';
const transports = require( '../../../../../assets/data/transports.json');
@Component({
  selector: 'app-step-transport',
  templateUrl: './step-transport.component.html',
  styleUrls: ['./step-transport.component.scss']
})
export class StepTransportComponent implements OnInit, OnDestroy {

    @Input()
    taxRequest: TaxRequest;
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    setData: EventEmitter<TaxRequest> = new EventEmitter<TaxRequest>();
    @Output()
    previousStep: EventEmitter<void> = new EventEmitter<void>();

    products: RequestProductAllCurrencies[][] = [];
    transportForm: FormGroup;
    formIsInvalid = false;
    transportsInitialized = false;

    typeFees: Array<{ label: string, value: string }> = [];
    currencies: Array<{label: string, value: string, disabled: boolean}> = [];
    transports: Array<{label: string, value: string}> = [];
    shipmentTypes: Array<{label: string, value: string, disabled: boolean}> = [];
    booleanChoices: {value: string, label: string}[] = [];

    incoterms: string[] = [];

    subscriptions: Subscription;

    constructor(private translate: TranslateService) {
        this.transports = transports;
        this.incoterms = Object.keys(INCOTERM_ENUM).map(inc => INCOTERM_ENUM[inc]);
    }

    async ngOnInit() {
        this.initForm();
        this.initAllStreams();
        await this.initTranslation();
        this.transportsInitialized = true;
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    initForm(fromConstructor = false) {
        this.transportForm = new FormGroup({
            shipmentType: new FormControl(
                ! fromConstructor && this.taxRequest.shipment_type ? this.taxRequest.shipment_type : SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE,
                [Validators.required]
            ),
            calcTypeTransporter: new FormControl(
                ! fromConstructor && this.taxRequest.transport ? true : (! fromConstructor && this.taxRequest.transit_fees ? false : true)
            ),
            incoterm: new FormControl(! fromConstructor && this.taxRequest.incoterm ? this.taxRequest.incoterm : INCOTERM_ENUM.CIF),
            globalShipPrice: new FormControl(
                !fromConstructor && this.taxRequest.global_ship_price ?
                this.taxRequest.global_ship_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyGlobalShipPrice: new FormControl(
                !fromConstructor && this.taxRequest.currency_global_ship_price ?
                this.taxRequest.currency_global_ship_price :
                null,
                Validators.required
            ),
            globalPackagingPrice: new FormControl(
                !fromConstructor && this.taxRequest.global_packaging_price ?
                this.taxRequest.global_packaging_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyGlobalPackagingPrice: new FormControl(
                !fromConstructor && this.taxRequest.currency_global_packaging_price ?
                this.taxRequest.currency_global_packaging_price :
                null,
                Validators.required
            ),
            globalInsurancePrice: new FormControl(
                !fromConstructor && this.taxRequest.global_insurance_price ?
                this.taxRequest.global_insurance_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyGlobalInsurancePrice: new FormControl(
                !fromConstructor && this.taxRequest.currency_global_insurance_price ?
                this.taxRequest.currency_global_insurance_price :
                null,
                Validators.required
            ),
            transporter: new FormControl(
                !fromConstructor && this.taxRequest.transport ?
                this.getTransportForForm(this.taxRequest.transport) :
                null,
                Validators.required
            ),
            transitFees: this.buildTransitFeesForm(
                !fromConstructor && this.taxRequest.transit_fees ?
                this.taxRequest.transit_fees :
                null
            ),
            streams: new FormArray([])
        });
    }

    initAllStreams() {
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        this.products = [];
        if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE) {
            this.taxRequest.products.forEach(product => {
                let iProducts = this.products.findIndex(p => {
                    return p[0].to_country === product.to_country &&
                        p[0].to_district === product.to_district &&
                        p[0].from_country === product.from_country;
                });
                if (iProducts === -1) {
                    iProducts = this.products.length;
                    this.products.push([]);
                }
                this.products[iProducts].push(product);
            });
            for (let i = 0; i < this.products.length; i++) {
                this.streams.push(this.buildStreamForm(this.products[i][0].from_country, this.products[i][0].to_country, this.products[i][0].to_district));
                this.addAllProducts(i, this.products[i], true);
            }
        } else {
            this.products.push(this.taxRequest.products);
            this.streams.push(this.buildStreamForm(this.taxRequest.from_country, this.taxRequest.to_country, this.taxRequest.to_district));
            this.addAllProducts(0, this.taxRequest.products, true);
            const currentStreamProduct = this.getStreamProducts(0);
            this.subscriptions = new Subscription();
            for (let i = 0; i < currentStreamProduct.length; i++) {
                this.subscriptions.add(
                    currentStreamProduct.at(i).get('incoterm').valueChanges.subscribe(val => {
                        for (let j = 0; j < currentStreamProduct.length; j++) {
                            if (j !== i) {
                                currentStreamProduct.at(j).get('incoterm').setValue(val, {emitEvent: false});
                            }
                        }
                    })
                );
            }
        }
    }

    buildStreamForm(fromCountry: string, toCountry: string, toDistrict: string): FormGroup {
        return new FormGroup({
          fromCountry: new FormControl(fromCountry),
          toCountry: new FormControl(toCountry),
          toDistrict: new FormControl(toDistrict),
          products: new FormArray([])
        });
    }

    get streams(): FormArray {
        return this.transportForm.get('streams') as FormArray;
    }

    getStreamProducts(i: number): FormArray {
        return this.streams.at(i).get('products') as FormArray;
    }

    async initTranslation() {
        await this.translate.get('currency_name').subscribe(currencies => {
            this.currencies = currencies;
        });
        await this.translate.get('dutyCalculation').subscribe(dutyCalculation => {
            this.updateDutyTranslation(dutyCalculation);
        });
        this.translate.get('profile.boolean_choice').toPromise().then((boolChoices) => {
            this.booleanChoices = boolChoices;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateDutyTranslation(event.translations['dutyCalculation']);
            this.currencies = event.translations['currency_name'];
            this.booleanChoices = event.translations.profile.boolean_choice;
        });
    }

    getShipPriceLabel(streamIndex?: number, productIndex?: number): string {
        const includedTax = streamIndex !== undefined ? this.products[streamIndex][productIndex].included_tax : this.taxRequest.included_tax;
        return includedTax ? 'shipPrice_vat' : 'shipPrice';
    }
    getPackagingPriceLabel(streamIndex?: number, productIndex?: number): string {
        const includedTax = streamIndex !== undefined ? this.products[streamIndex][productIndex].included_tax : this.taxRequest.included_tax;
        return includedTax ? 'packagingPrice_vat' : 'packagingPrice';
    }
    getInsurancePriceLabel(streamIndex?: number, productIndex?: number): string {
        const includedTax = streamIndex !== undefined ? this.products[streamIndex][productIndex].included_tax : this.taxRequest.included_tax;
        return includedTax ? 'insurancePrice_vat' : 'insurancePrice';
    }

    private updateDutyTranslation(dutyCalculation) {
        const steps = dutyCalculation.steps;
        this.typeFees = steps.transport ? steps.transport.typeFees : [];
        this.shipmentTypes = steps.transport ? steps.transport.shipmentType : [];

        const products = this.getStreamProducts(0).value;
        if (this.streams.length > 1 || (products.length > 1 && products.map(p => p.incoterm).filter(p => p === products[0].incoterm).length !== products.length)) {
            this.shipmentTypes.find(st => st.value === SHIPMENT_REQUEST_TYPE_ENUM.GLOBAL).disabled = true;
        }
    }

    addAllProducts(streamIndex: number, products: RequestProductAllCurrencies[], init = false) {
        for (let i = 0; i < products.length; i++) {
            this.getStreamProducts(streamIndex).push(this.buildProductForm(products[i], init));
        }
    }

    buildProductForm(product: RequestProductAllCurrencies, init = false): FormGroup {
        const productForm = new FormGroup({
            id: new FormControl(product.identification.value, Validators.required),
            calcTypeTransporter: new FormControl(init || (init && product.transport) ? true : false),
            incoterm: new FormControl(init && product.incoterm ? product.incoterm : ''),
            unitShipPrice: new FormControl(
                init && product.unit_ship_price ?
                product.unit_ship_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyUnitShipPrice: new FormControl(
                init && product && product.currency_unit_ship_price ?
                product.currency_unit_ship_price :
                null,
                Validators.required
            ),
            unitPackagingPrice: new FormControl(
                init && product.unit_packaging_price ?
                product.unit_packaging_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyUnitPackagingPrice: new FormControl(
                init && product && product.currency_unit_packaging_price ?
                product.currency_unit_packaging_price :
                null,
                Validators.required
            ),
            unitInsurancePrice: new FormControl(
                init && product.unit_insurance_price ?
                product.unit_insurance_price :
                null,
                [Validators.min(0.01), Validators.required]
            ),
            currencyUnitInsurancePrice: new FormControl(
                init && product && product.currency_unit_insurance_price ?
                product.currency_unit_insurance_price :
                null,
                Validators.required
            ),
            transporter: new FormControl(
                init && product.transport ?
                this.getTransportForForm(product.transport) :
                null,
                Validators.required
            ),
            transitFees: this.buildTransitFeesForm(
                init && product.transit_fees ?
                product.transit_fees :
                null
            )
        });
        return productForm;
    }

    getTransportForForm(transport): string {
        if (transport.type === ShipmentTypeEnum.FREIGHT_FORWARDER) {
            return 'f_' + transport.id;
        } else if (transport.type === ShipmentTypeEnum.CARRIER) {
            return 'c_' + transport.id;
        }
        return '';
    }

    buildTransitFeesForm(transitFees: TransitFees = null): FormGroup {
        const transitFeesForm = new FormGroup({
            type: new FormControl(transitFees ? (transitFees.amount ? 'amount' : 'percentage') : '', Validators.required),
            currency: new FormControl(transitFees ? transitFees.currency : '', Validators.required),
            fees: new FormControl(transitFees ? (transitFees.amount ? transitFees.amount : transitFees.percentage) : '', [Validators.min(0.01), Validators.required]),
            transportFeesType: new FormControl(
                (transitFees ?
                    (transitFees.on_global ? 'all' :
                    (transitFees.on_products_price ? 'productPrice' : 'shippingProductPrice')) : 'all'
                )
            )
        });
        return transitFeesForm;
    }

    isGlobal(): boolean {
        return this.transportForm.controls['shipmentType'].value === SHIPMENT_REQUEST_TYPE_ENUM.GLOBAL;
    }

    isTransporterGlobal(): boolean {
        return this.transportForm.controls['calcTypeTransporter'].value === true;
    }

    isTransporterStream(i: number, j: number): boolean {
        const foundproduct = this.getStreamProducts(j).at(i) as FormGroup;
        return foundproduct.get('calcTypeTransporter').value === true && foundproduct.controls['transporter'] !== undefined;
    }

    isTransitFeesStream(i: number, j: number): boolean {
        const foundproduct = this.getStreamProducts(j).at(i) as FormGroup;
        return foundproduct.get('calcTypeTransporter').value === false && foundproduct.controls['transitFees'] !== undefined;
    }

    valid(): boolean {
        if (this.isGlobal()) {
            if (! this.validShippingPackaginginsurancePrices(this.transportForm, true)) {
                return false;
            }
            if (this.transportForm.controls['calcTypeTransporter'].value === true) {
                if (! this.validTransporterForm(this.transportForm)) {
                    return false;
                }
            } else {
                if (! this.validTransitFeesForm(this.transportForm)) {
                    return false;
                }
            }
        } else {
            for (let j = 0; j < this.streams.length; j++) {
                const streamProducts = this.getStreamProducts(j);
                for (let i = 0; i < streamProducts.length; i++) {
                    const productForm = streamProducts.at(i) as FormGroup;
                    if (! this.validShippingPackaginginsurancePrices(productForm)) {
                        return false;
                    }
                    if (productForm.controls['calcTypeTransporter'].value === true) {
                        if (! this.validTransporterForm(productForm)) {
                            return false;
                        }
                    } else {
                        if (! this.validTransitFeesForm(productForm)) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    validShippingPackaginginsurancePrices(form: FormGroup, global = false): boolean {
        const values = form.value;
        if (global) {
            return this.validPriceAndCurrency(values.globalShipPrice, values.currencyGlobalShipPrice)
                &&
                (
                    (! values.globalPackagingPrice && ! values.currencyGlobalPackagingPrice)
                    ||
                    (
                        this.validPriceAndCurrency(values.globalPackagingPrice, values.currencyGlobalPackagingPrice)
                    )
                )
                &&
                (
                    (! values.globalInsurancePrice && ! values.currencyGlobalInsurancePrice)
                    ||
                    (
                        this.validPriceAndCurrency(values.globalInsurancePrice, values.currencyGlobalInsurancePrice)
                    )
                );
        }
        return this.validPriceAndCurrency(values.unitShipPrice, values.currencyUnitShipPrice)
            &&
            (
                (! values.unitPackagingPrice && ! values.currencyUnitPackagingPrice)
                ||
                (
                    this.validPriceAndCurrency(values.unitPackagingPrice, values.currencyUnitPackagingPrice)
                )
            )
            &&
            (
                (! values.unitInsurancePrice && ! values.currencyUnitInsurancePrice)
                ||
                (
                    this.validPriceAndCurrency(values.unitInsurancePrice, values.currencyUnitInsurancePrice)
                )
            );
    }

    validPriceAndCurrency(price: number, currency: string): boolean {
        return price !== null && !isNaN(price) && price.toString() !== '' && price >= 0 && currency && currency.length === 3;
    }

    validTransporterForm(form: FormGroup): boolean {
        const value = form.controls['transporter'].value;
        if (value && value.length > 0) {
            return true;
        }
        return false;
    }

    validTransitFeesForm(form: FormGroup): boolean {
        return (form.controls['transitFees'] as FormGroup).valid;
    }

    nextStep() {
        if (! this.valid()) {
            this.formIsInvalid = true;
            return;
        }
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        const values = this.transportForm.value;
        this.taxRequest.shipment_type = values.shipmentType;

        // delete any previous entered data to avoid any issue
        this.taxRequest.global_ship_price = undefined;
        this.taxRequest.currency_global_ship_price = undefined;
        this.taxRequest.global_packaging_price = undefined;
        this.taxRequest.currency_global_packaging_price = undefined;
        this.taxRequest.global_insurance_price = undefined;
        this.taxRequest.currency_global_insurance_price = undefined;
        this.taxRequest.transport = undefined;
        this.taxRequest.transit_fees = undefined;

        if (this.isGlobal()) {
            this.taxRequest.global_ship_price = values.globalShipPrice;
            this.taxRequest.currency_global_ship_price = values.currencyGlobalShipPrice;
            this.taxRequest.global_packaging_price = values.globalPackagingPrice;
            this.taxRequest.currency_global_packaging_price = values.currencyGlobalPackagingPrice;
            this.taxRequest.global_insurance_price = values.globalInsurancePrice;
            this.taxRequest.currency_global_insurance_price = values.currencyGlobalInsurancePrice;
            this.taxRequest.incoterm = values.incoterm && values.incoterm !== '' ? values.incoterm : undefined;
            // known transporter
            if (values.calcTypeTransporter === true) {
                this.taxRequest.transport = this.getTransportFromString(values.transporter);

            // unknown transporter
            } else {
                const transitFees = Builder(TransitFees)
                    .on_global(values.transitFees.transportFeesType === 'all')
                    .on_products_price(values.transitFees.transportFeesType === 'productPrice')
                    .on_shipping_products_price(values.transitFees.transportFeesType === 'shippingProductPrice')
                    .currency(values.transitFees.currency);
                if ( values.transitFees.type === 'amount' ) {
                    transitFees.amount(values.transitFees.fees);
                } else {
                    transitFees.percentage(values.transitFees.fees);
                }
                this.taxRequest.transit_fees = transitFees.build();
            }
        } else {
            this.taxRequest.products = [];
            let foundStream = null;
            // ESHOP
            if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP) {
                foundStream = values.streams.find(s => {
                    return s.fromCountry === this.taxRequest.from_country &&
                        s.toCountry === this.taxRequest.to_country &&
                        s.toDistrict === this.taxRequest.to_district;
                });
            }
            // parse streams
            this.products.forEach(products => {
                // from_country / to_country & to_district are different for each stream for Marketplace
                if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE) {
                    foundStream = values.streams.find(s => {
                        return s.fromCountry === products[0].from_country &&
                            s.toCountry === products[0].to_country &&
                            s.toDistrict === products[0].to_district;
                    });
                }
                for (let i = 0; i < products.length; i++) {
                    // reinit values
                    products[i].unit_ship_price = undefined;
                    products[i].unit_packaging_price = undefined;
                    products[i].unit_insurance_price = undefined;
                    products[i].group_shipping_price = undefined;
                    products[i].group_packaging_price = undefined;
                    products[i].group_insurance_price = undefined;
                    products[i].currency_unit_ship_price = undefined;
                    products[i].currency_unit_packaging_price = undefined;
                    products[i].currency_unit_insurance_price = undefined;
                    products[i].transport = undefined;
                    products[i].transit_fees = undefined;

                    switch (values.shipmentType) {
                        case SHIPMENT_REQUEST_TYPE_ENUM.ARTICLE:
                            products[i].unit_ship_price = foundStream.products[i].unitShipPrice;
                            products[i].unit_packaging_price = foundStream.products[i].unitPackagingPrice;
                            products[i].unit_insurance_price = foundStream.products[i].unitInsurancePrice;
                            break;
                        case SHIPMENT_REQUEST_TYPE_ENUM.GROUP:
                            products[i].group_shipping_price = foundStream.products[i].unitShipPrice;
                            products[i].group_packaging_price = foundStream.products[i].unitPackagingPrice;
                            products[i].group_insurance_price = foundStream.products[i].unitInsurancePrice;
                            break;
                        default:
                            products[i].unit_ship_price = foundStream.products[i].unitShipPrice;
                            products[i].unit_packaging_price = foundStream.products[i].unitPackagingPrice;
                            products[i].unit_insurance_price = foundStream.products[i].unitInsurancePrice;
                            break;
                    }
                    products[i].currency_unit_ship_price = foundStream.products[i].currencyUnitShipPrice;
                    products[i].currency_unit_packaging_price = foundStream.products[i].currencyUnitPackagingPrice;
                    products[i].currency_unit_insurance_price = foundStream.products[i].currencyUnitInsurancePrice;
                    if (foundStream.products[i].calcTypeTransporter === true) {
                        products[i].transport = this.getTransportFromString(foundStream.products[i].transporter);
                    } else {
                        const transitFees = Builder(TransitFees)
                            .on_global(foundStream.products[i].transitFees.transportFeesType === 'all')
                            .on_products_price(foundStream.products[i].transitFees.transportFeesType === 'productPrice')
                            .on_shipping_products_price(foundStream.products[i].transitFees.transportFeesType === 'shippingProductPrice')
                            .currency(foundStream.products[i].transitFees.currency);
                        if (foundStream.products[i].transitFees.type === 'amount') {
                            transitFees.amount(foundStream.products[i].transitFees.fees);
                        } else {
                            transitFees.percentage(foundStream.products[i].transitFees.fees);
                        }
                        products[i].transit_fees = transitFees.build();
                    }
                    products[i].incoterm = foundStream.products[i].incoterm && foundStream.products[i].incoterm !== '' ? foundStream.products[i].incoterm : undefined;
                    this.taxRequest.products.push(products[i]);
                }
            });
            // we must set incoterm in root request if eshop
            if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP) {
                this.taxRequest.incoterm = this.taxRequest.products[0].incoterm;
            }
        }
        this.formIsInvalid = false;
        this.setData.emit(this.taxRequest);
    }

    goBack() {
        this.previousStep.emit();
    }

    private getTransportFromString(transport): DutyTransport {
        if (transport) {
            const t_split = transport.split('_');
            return {
                type: t_split[0] === 'f' ? ShipmentTypeEnum.FREIGHT_FORWARDER : ShipmentTypeEnum.CARRIER,
                id: t_split[1]
            } as DutyTransport;
        }
        return null;
    }

    getCountry(streamIndex: number, formName: string): string {
        const value = this.streams.at(streamIndex).get(formName).value;
        const country = this.countries.find(c => c.value === value);
        return country ? country.label : value;
    }
}
