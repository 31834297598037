import { Component, EventEmitter, OnInit, Output } from '@angular/core';
@Component({
    selector: 'app-register-step-welcome',
    templateUrl: './step_welcome.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepWelcomeComponent implements OnInit {
    @Output()
    nextStepCallback: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    nextStep() {
        this.nextStepCallback.emit();
    }
}
