import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { REGISTER_STEP, REGISTER_STEP_FINAL_BUTTONS, StepAccountForm, StepCarriersForm, StepCustomsclassificationDefaultsForm, StepDestinationForm, StepExpeditionForm, StepTaxmanagerForm, UserSettings } from '../../../../models/Register';
import { MessageService } from '../../../components/message/message.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { ApiDataService, CountryLabels } from '../../../services/api-data.service';
const us_states = require('../../../../../assets/data/US_states.json');

@Component({
    selector: 'app-register-step',
    templateUrl: './register_step.component.html',
    styleUrls: ['./register_step.component.scss']
})
export class RegisterStepComponent implements OnInit {
    registerStep: REGISTER_STEP;
    userSettings: UserSettings;

    modalOpened = false;

    countries: Array<{ value: string, label: string, iso2: string }> = [];
    districts: Array<{ value: string, label: string }> = [];
    us_states: Array<{ value: string, label: string }> = [];
    currencies: Array<{}>;

    types = [];

    alreadyProcessing = false;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private translate: TranslateService,
        private _notification: MessageService,
        private router: Router,
        private apiDataService: ApiDataService
    ) {
        this.us_states = us_states;
        this.registerStep = REGISTER_STEP.WELCOME;
        this.userSettings = new UserSettings();
    }

    ngOnInit() {
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }

    modalIsOpened(state: boolean) {
        this.modalOpened = state;
    }

    isWelcome() {
        return this.registerStep === REGISTER_STEP.WELCOME;
    }
    isAccountParam() {
        return this.registerStep === REGISTER_STEP.ACCOUNT_PARAM;
    }
    stepAccountParam() {
        return this.registerStep >= REGISTER_STEP.ACCOUNT_PARAM;
    }
    isExpeditionParam() {
        return this.registerStep === REGISTER_STEP.EXPEDITION_PARAM;
    }
    stepExpeditionParam() {
        return this.registerStep >= REGISTER_STEP.EXPEDITION_PARAM;
    }
    isDestinationParam() {
        return this.registerStep === REGISTER_STEP.DESTINATION_PARAM;
    }
    stepDestinationParam() {
        return this.registerStep >= REGISTER_STEP.DESTINATION_PARAM;
    }
    isTaxManagerParam() {
        return this.registerStep === REGISTER_STEP.TAX_MANAGER_PARAM;
    }
    stepTaxManagerParam() {
        return this.registerStep >= REGISTER_STEP.TAX_MANAGER_PARAM;
    }
    isAIClassify() {
        return this.registerStep === REGISTER_STEP.AI_CLASSIFY;
    }
    stepAIClassify() {
        return this.registerStep >= REGISTER_STEP.AI_CLASSIFY;
    }
    isTransportationParam() {
        return this.registerStep === REGISTER_STEP.TRANSPORTATION_PARAM;
    }
    stepTransportationParam() {
        return this.registerStep >= REGISTER_STEP.TRANSPORTATION_PARAM;
    }
    isFinal() {
        return this.registerStep === REGISTER_STEP.FINAL;
    }

    // handle callbacks from steps
    nextStepFromWelcome() {
        this.registerStep = REGISTER_STEP.ACCOUNT_PARAM;
    }
    nextStepFromAccount(account: StepAccountForm) {
        console.log(account);
        this.userSettings.setStepAccountFormContent(account);
        console.log(this.userSettings);
        this.registerStep = REGISTER_STEP.EXPEDITION_PARAM;
    }
    nextStepFromExpedition(expedition: StepExpeditionForm) {
        this.userSettings.setStepExpeditionFormContent(expedition);
        this.registerStep = REGISTER_STEP.DESTINATION_PARAM;
    }
    nextStepFromDestination(destination: StepDestinationForm) {
        this.userSettings.setStepDestinationFormContent(destination);
        this.registerStep = REGISTER_STEP.TAX_MANAGER_PARAM;
    }
    nextStepFromTaxManager(taxmanager: StepTaxmanagerForm) {
        this.userSettings.setStepTaxmanagerFormContent(taxmanager);
        this.registerStep = REGISTER_STEP.AI_CLASSIFY;
    }
    nextStepFromAiClassify(customsClassificationDefaults: StepCustomsclassificationDefaultsForm) {
        this.userSettings.setStepCustomsclassificationDefaultsFormContent(customsClassificationDefaults);
        this.registerStep = REGISTER_STEP.TRANSPORTATION_PARAM;
    }
    nextStepFromTransportation(carriers: StepCarriersForm) {
        this.userSettings.setStepCarriersFormContent(carriers);
        this.registerStep = REGISTER_STEP.FINAL;
    }
    nextStepFromFinal(finalStepButton: REGISTER_STEP_FINAL_BUTTONS) {
        if (!this.alreadyProcessing) {
            this.alreadyProcessing = true;
            switch (finalStepButton) {
                case REGISTER_STEP_FINAL_BUTTONS.MODIFY:
                    this.validSettings().then(async result => {
                        if (result) {
                            await this.userService.initTransiteoUser();
                            this.router.navigate(['settings']);
                        }
                        this.alreadyProcessing = false;
                    });
                    break;
                case REGISTER_STEP_FINAL_BUTTONS.CHOOSE_PLAN:
                    this.validSettings().then(async result => {
                        if (result) {
                            await this.userService.initTransiteoUser();
                            this.router.navigate(['plans']);
                        }
                        this.alreadyProcessing = false;
                    });
                    break;
                case REGISTER_STEP_FINAL_BUTTONS.CHOOSE_PLAN_LATER:
                    this.validSettings().then(async result => {
                        if (result) {
                            await this.userService.initTransiteoUser();
                            this.router.navigate(['dashboard']);
                        }
                        this.alreadyProcessing = false;
                    });
                    break;
                default:
                    break;
            }
        }
    }

    // returns boolean : settings are successfully saved or not?
    async validSettings(): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/user/registrationSettings`, this.userSettings.toJSON()).toPromise()
            .then((response: any) => {
                resolve(true);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }
}
