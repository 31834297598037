import { Builder } from "builder-pattern";

export interface Account {
    lastname: string;
    firstname: string;
    phone: string;
    currency: string;
    email: string;
    password: string;
    type: ApiAccount[] | WebAccount[] | CmsAccount;
}

export interface ApiAccount {
    api_service: string;
    type: ApiTypeEnum;
    calls_day: number;
}

export interface WebAccount {
    api_service: string;
    user_number: number;
    calls_month: number;
}

export interface CmsAccount {
    default_htscode?: string;
    origin_country: string;
    from_country: string;
    products_currency: string;
    domain: string;
}

export enum ApiTypeEnum {
    REALTIME = 'REALTIME',
    SPOT = 'SPOT'
}


///////////////////////////////////////////////
// Step register classes, interfaces & enums //
///////////////////////////////////////////////

export enum REGISTER_PART {
    REGISTER_MAIL = 0,
    REGISTER_STEP = 1
}

export enum REGISTER_STEP {
    WELCOME = 0,
    ACCOUNT_PARAM = 1,
    EXPEDITION_PARAM = 2,
    DESTINATION_PARAM = 3,
    TAX_MANAGER_PARAM = 4,
    AI_CLASSIFY = 5,
    TRANSPORTATION_PARAM = 6,
    FINAL = 7
}

export enum REGISTER_STEP_FINAL_BUTTONS {
    MODIFY = 0,
    CHOOSE_PLAN = 1,
    CHOOSE_PLAN_LATER = 2
}

export class StepAccountForm {
    company_name: string;
    domain_name: string;
    integration_method: string;
}
export class StepExpeditionForm {
    country: string;
    address: string;
    postal_code: string;
    city: string;
    state: string;
}
export class StepDestinationForm {
    countries: string[];
}
export interface TaxCountry {
    tax_id: string;
    eori: string;
    uk_rules?: boolean;
}
export class StepTaxmanagerForm {
    countries: {[key: string]: TaxCountry};
}
export class StepCustomsclassificationDefaultsForm {
    product_category: string;
    hs_code: string;
    product_description: string;
    origin_country: string;
}
export class StepCarriersForm {
    ups_payment_account_number: string;
    dhl_payment_account_number: string;
    dhl_online_store_id: string;
    dhl_production_password: string;
    fedex_account_number: string;
    fedex_meter_number: string;
    fedex_authentication_key: string;
    fedex_api_password: string;
}
export class UserSettings {
    // STEP 1
    account_company_name: string;
    account_domain_name: string;
    account_integration_method: string;

    // STEP 2
    expedition_country: string;
    expedition_address: string;
    expedition_postal_code: string;
    expedition_city: string;
    expedition_state: string;

    // STEP 3
    destination_countries: string[];

    // STEP 4
    taxmanager_countries: {[key: string]: TaxCountry};

    // STEP 5
    customsclassification_default_product_category: string;
    customsclassification_default_hs_code: string;
    customsclassification_default_product_description: string;
    customsclassification_default_origin_country: string;

    // STEP 6
    carriers_ups_payment_account_number: string;
    carriers_dhl_payment_account_number: string;
    carriers_dhl_online_store_id: string;
    carriers_dhl_production_password: string;
    carriers_fedex_account_number: string;
    carriers_fedex_meter_number: string;
    carriers_fedex_authentication_key: string;
    carriers_fedex_api_password: string;

    toJSON() {
        return {
            account_company_name: this.account_company_name,
            account_domain_name: this.account_domain_name,
            account_integration_method: this.account_integration_method,
            expedition_country: this.expedition_country,
            expedition_address: this.expedition_address,
            expedition_postal_code: this.expedition_postal_code,
            expedition_city: this.expedition_city,
            expedition_state: this.expedition_state,
            destination_countries: this.destination_countries ? this.destination_countries : [],
            taxmanager_countries: this.taxmanager_countries ? this.taxmanager_countries : {},
            customsclassification_default_product_category: this.customsclassification_default_product_category,
            customsclassification_default_hs_code: this.customsclassification_default_hs_code,
            customsclassification_default_product_description: this.customsclassification_default_product_description,
            customsclassification_default_origin_country: this.customsclassification_default_origin_country,
            carriers_ups_payment_account_number: this.carriers_ups_payment_account_number,
            carriers_dhl_payment_account_number: this.carriers_dhl_payment_account_number,
            carriers_dhl_online_store_id: this.carriers_dhl_online_store_id,
            carriers_dhl_production_password: this.carriers_dhl_production_password,
            carriers_fedex_account_number: this.carriers_fedex_account_number,
            carriers_fedex_meter_number: this.carriers_fedex_meter_number,
            carriers_fedex_authentication_key: this.carriers_fedex_authentication_key,
            carriers_fedex_api_password: this.carriers_fedex_api_password
        };
    }

    setStepAccountFormContent(account: StepAccountForm): void {
        this.account_company_name = account.company_name;
        this.account_domain_name = account.domain_name;
        this.account_integration_method = account.integration_method;
    }

    setStepExpeditionFormContent(expedition: StepExpeditionForm): void {
        this.expedition_country = expedition.country;
        this.expedition_address = expedition.address;
        this.expedition_postal_code = expedition.postal_code;
        this.expedition_state = expedition.state;
        this.expedition_city = expedition.city;
    }

    setStepDestinationFormContent(destination: StepDestinationForm): void {
        this.destination_countries = destination.countries;
    }

    setStepTaxmanagerFormContent(taxmanager: StepTaxmanagerForm): void {
        this.taxmanager_countries = taxmanager.countries;
    }

    setStepCustomsclassificationDefaultsFormContent(customsClassificationDefault: StepCustomsclassificationDefaultsForm): void {
        this.customsclassification_default_origin_country = customsClassificationDefault.origin_country;
        this.customsclassification_default_hs_code = customsClassificationDefault.hs_code;
        this.customsclassification_default_product_category = customsClassificationDefault.product_category;
        this.customsclassification_default_product_description = customsClassificationDefault.product_description;
    }

    setStepCarriersFormContent(carriers: StepCarriersForm): void {
        this.carriers_ups_payment_account_number = carriers.ups_payment_account_number;
        this.carriers_dhl_online_store_id = carriers.dhl_online_store_id;
        this.carriers_dhl_payment_account_number = carriers.dhl_payment_account_number;
        this.carriers_dhl_production_password = carriers.dhl_production_password;
        this.carriers_fedex_account_number = carriers.fedex_account_number;
        this.carriers_fedex_api_password = carriers.fedex_api_password;
        this.carriers_fedex_authentication_key = carriers.fedex_authentication_key;
        this.carriers_fedex_meter_number = carriers.fedex_meter_number;
    }

    getStepAccountFormContent(): StepAccountForm {
        return Builder(StepAccountForm)
            .company_name(this.account_company_name)
            .domain_name(this.account_domain_name)
            .integration_method(this.account_integration_method)
            .build();
    }

    getStepExpeditionFormContent(): StepExpeditionForm {
        return Builder(StepExpeditionForm)
            .country(this.expedition_country)
            .address(this.expedition_address)
            .postal_code(this.expedition_postal_code)
            .state(this.expedition_state)
            .city(this.expedition_city)
            .build();
    }

    getStepDestinationFormContent(): StepDestinationForm {
        return Builder(StepDestinationForm)
            .countries(this.destination_countries ? this.destination_countries : [])
            .build();
    }

    getStepTaxmanagerFormContent(): StepTaxmanagerForm {
        return Builder(StepTaxmanagerForm)
            .countries(this.taxmanager_countries ? this.taxmanager_countries : {})
            .build();
    }

    getStepCustomsclassificationDefaultsFormContent(): StepCustomsclassificationDefaultsForm {
        return Builder(StepCustomsclassificationDefaultsForm)
            .origin_country(this.customsclassification_default_origin_country)
            .hs_code(this.customsclassification_default_hs_code)
            .product_category(this.customsclassification_default_product_category)
            .product_description(this.customsclassification_default_product_description)
            .build();
    }

    getStepCarriersFormContent(): StepCarriersForm {
        return Builder(StepCarriersForm)
            .ups_payment_account_number(this.carriers_ups_payment_account_number)
            .dhl_online_store_id(this.carriers_dhl_online_store_id)
            .dhl_payment_account_number(this.carriers_dhl_payment_account_number)
            .dhl_production_password(this.carriers_dhl_production_password)
            .fedex_account_number(this.carriers_fedex_account_number)
            .fedex_api_password(this.carriers_fedex_api_password)
            .fedex_authentication_key(this.carriers_fedex_authentication_key)
            .fedex_meter_number(this.carriers_fedex_meter_number)
            .build();
    }
}
