export interface ProductIdentification {
    type: any;
    value: string;
}

export enum ProductIdentificationEnum {
    FRONT= 'FRONT',
    BARCODE= 'BARCODE',
    HS17 = 'HS17',
    TEXT = 'TEXT'
}

export class RequestProductIdentification implements ProductIdentification {
    type: string;
    value: string;
}



export interface InternationalShipmentRequestProduct extends RequestProductIdentification {
    identification: RequestProductIdentification;
    price: number;
    currency_price: string;
    quantity: number;
}

export interface CategoryAttribute {
    category_code: string;
    custom_attribute_code: string;
}

export enum BARCODE_ENUM {
    ASIN= 'asin',
    EAN= 'ean',
    UPC= 'upc',
    ISBN= 'isbn'
}
