import {RequestProductIdentification} from './Product';
import {Builder} from 'builder-pattern';
import {plainToClass} from 'class-transformer';
import { INCOTERM_ENUM } from './User';

export class RespExtraFees {
    percentage: number;
    amount: number;
    currency: string;
}

export class SimplifiedTaxResponse {
    duty_amount: number;
    vat_amount: number;
    ecoTax_amount: number;
    special_taxes_amount: number;
    extra_fees: RespExtraFees;
    global: DutyFee;
    timestamp: number;
}

export class TaxResponse extends SimplifiedTaxResponse {
    products: ResponseProduct[];
    shipping_global: ShippingGlobal;
    packaging_global: ShippingGlobal;
    insurance_global: ShippingGlobal;
    transit_fees_global: DutyFee;
    incoterm: INCOTERM_ENUM;
    extra_fees: RespExtraFees;
    static buildDutyCsvResponse(taxRequest: TaxRequest, taxResponse: TaxResponse) {
        const senderCsvInfo = {
            'lang' : taxRequest.lang,
            'from_country' : taxRequest.from_country,
            'from_district' : taxRequest.from_district,
            'to_country' : taxRequest.to_country,
            'to_district' : taxRequest.to_district,
        };
        const csvReqProducts = plainToClass(RequestProduct, taxRequest.products)
            .map(rp => rp.toCsvRequestProduct())
            .reduce((a, b) => CsvRequestProduct.sumRequestProduct(a, b), null);
        const ship_infos = {
            'shipment_type' : taxRequest.shipment_type,
            'global_ship_price' : taxRequest.global_ship_price,
            'currency_global_ship_price' : taxRequest.currency_global_ship_price,
            'sender_pro' : taxRequest.sender.pro, 'sender_revenue_country_annual' : taxRequest.sender.revenue_country_annual,
            'sender_currency_revenue_country_annual' : taxRequest.sender.currency_revenue_country_annual,
            'receiver_pro' : taxRequest.receiver.pro, 'receiver_activity_id' : taxRequest.receiver.activity_id,
        };
        const csvResProducts = plainToClass(ResponseProduct, taxResponse.products)
            .map(rp => rp.toCsvProduct())
            .reduce((a, b) => CsvProductResponse.sum(a, b), null);
        let ship_global, globalTransport;
        if (taxRequest.shipment_type === 'GLOBAL') {
            globalTransport = {
                'transport_type' : taxRequest.transport.type, 'transport_id' : taxRequest.transport.id,
            };
            const vatGlobal = concatGlobalTaxes(plainToClass(TaxGlobal, taxResponse.shipping_global.vat));
            const spGlobal = concatGlobalTaxes(plainToClass(TaxGlobal, taxResponse.shipping_global.special_taxes));
            ship_global = {
                'shipping_global_duty_label' : taxResponse.shipping_global.duty.label,
                'shipping_global_duty_amount' : taxResponse.shipping_global.duty.amount,
                'shipping_global_vat_label' : vatGlobal.label,
                'shipping_global_vat_amount' : vatGlobal.vat_amount,
                'shipping_global_special_taxes_label' : spGlobal.label,
                'shipping_global_special_taxes_amount' : spGlobal.amount,
                'transit_fees_global_amount' : taxResponse.transit_fees_global ? taxResponse.transit_fees_global.amount : null,
                'global_amount': taxResponse.global ? taxResponse.global.amount : null
            };
        } else {
            globalTransport = {'transport_type' : '', 'transport_id' : ''};
            ship_global = {
                'shipping_global_duty_label': '',
                'shipping_global_duty_amount': '',
                'shipping_global_vat_label': '',
                'shipping_global_vat_amount': null,
                'shipping_global_special_taxes_label': '',
                'shipping_global_special_taxes_amount': null,
                'transit_fees_global_amount': null,
                'global_amount': null
            };
        }
        return Object.assign({}, senderCsvInfo, csvResProducts.toJSON(), ship_infos, globalTransport, csvReqProducts.toJSON(), ship_global);
    }
}
export class ResponseProduct {
    identification: RequestProductIdentification;
    amount_duty_and_tax: number;
    amount_ecoTax: number;
    amount_exclusive: number;
    amount_inclusive_vat: number;
    amount_total: number;
    product_co2: number;
    product_shipping_co2: number;
    duty: Tax;
    special_taxes: Tax[];
    vat: Tax[];
    salestax: Tax[];
    transit_fees: DutyFee;
    product_statut: string;

    // NOT USED ANYMORE, OLD WAY TO BUILD A RESPONSE CSV
    toCsvProduct() {
        const vat: Tax = concatTaxes(this.vat);
        const spTaxes = concatTaxes(this.special_taxes);
        return Builder(CsvProductResponse)
            .products_duty_label(this.duty.label)
            .products_duty_percentage(this.duty.percentage)
            .products_duty_product_taxes_amount(this.duty.product_taxes_amount)
            .products_duty_shipping_taxes_amount(this.duty.shipping_taxes_amount)
            .products_vat_label(vat.label)
            .products_vat_percentage(vat.percentage)
            .products_vat_product_taxes_amount(vat.product_taxes_amount)
            .products_vat_shipping_taxes_amount(vat.shipping_taxes_amount)
            .products_special_taxes_label(spTaxes.label)
            .products_special_taxes_percentage(spTaxes.percentage)
            .products_special_taxes_product_taxes_amount(spTaxes.product_taxes_amount)
            .products_special_taxes_shipping_taxes_amount(spTaxes.shipping_taxes_amount)
            .products_transit_fees_amount(this.transit_fees ? this.transit_fees.amount : 0)
            .build();
    }
}

export const concatTaxes = (taxes: Tax[]) => {
    if (taxes.length === 0) {
        return Builder(Tax)
            .label('')
            .message('')
            .product_taxes_amount(0)
            .shipping_taxes_amount(0)
            .vat_taxes_amount(0)
            .percentage(0)
            .build();
    }
    return taxes.reduce((a, b) => {
        return Builder(Tax)
            .label(concatIfDifferent(a.label, b.label))
            .message(concatIfDifferent(a.message, b.message))
            .product_taxes_amount(sum(a.product_taxes_amount, b.product_taxes_amount))
            .shipping_taxes_amount(sum(a.shipping_taxes_amount, b.shipping_taxes_amount))
            .vat_taxes_amount(sum(a.vat_taxes_amount, b.vat_taxes_amount))
            .percentage(sum(a.percentage, b.percentage))
            .build();
    });
};

export const concatGlobalTaxes = (taxes: TaxGlobal[]) => {
    if (!taxes || taxes.length === 0) {
        return Builder(TaxGlobal)
            .label('')
            .vat_amount(0)
            .message('')
            .amount(0)
            .build();
    }
    return taxes.reduce((a, b) => {
        return Builder(TaxGlobal)
            .label(concatIfDifferent(a.label, b.label))
            .vat_amount(sum(a.vat_amount, b.vat_amount))
            .message(concatIfDifferent(a.message, b.message))
            .amount(sum(a.amount, b.amount))
            .build();
    });
};

const sum = (a: number, b: number) => {
    return (a || 0) + (b || 0);
};

export class CsvProductResponse {
    products_duty_label: string;
    products_duty_percentage: number;
    products_duty_product_taxes_amount: number;
    products_duty_shipping_taxes_amount: number;
    products_vat_label: string;
    products_vat_percentage: number;
    products_vat_product_taxes_amount: number;
    products_vat_shipping_taxes_amount: number;
    products_special_taxes_label: string;
    products_special_taxes_percentage: number;
    products_special_taxes_product_taxes_amount: number;
    products_special_taxes_shipping_taxes_amount: number;
    products_transit_fees_amount: number;

    static sum(a: CsvProductResponse, b: CsvProductResponse) {
        if (!a) {
            return b;
        }
        return Builder(CsvProductResponse)
            .products_duty_label(concatIfDifferent(a.products_duty_label, b.products_duty_label))
            .products_duty_percentage(sum(a.products_duty_percentage, b.products_duty_percentage))
            .products_duty_product_taxes_amount(sum(a.products_duty_product_taxes_amount, b.products_duty_product_taxes_amount))
            .products_duty_shipping_taxes_amount(sum(a.products_duty_shipping_taxes_amount, b.products_duty_shipping_taxes_amount))
            .products_vat_label(concatIfDifferent(a.products_duty_label, b.products_duty_label))
            .products_vat_percentage(sum(a.products_vat_percentage, b.products_vat_percentage))
            .products_vat_product_taxes_amount(sum(a.products_vat_product_taxes_amount, b.products_vat_product_taxes_amount))
            .products_vat_shipping_taxes_amount(sum(a.products_vat_shipping_taxes_amount, b.products_vat_shipping_taxes_amount))
            .products_special_taxes_label(concatIfDifferent(a.products_special_taxes_label, b.products_special_taxes_label))
            .products_special_taxes_percentage(sum(a.products_special_taxes_percentage, b.products_special_taxes_percentage))
            .products_special_taxes_product_taxes_amount(sum(a.products_special_taxes_product_taxes_amount, b.products_special_taxes_product_taxes_amount))
            .products_special_taxes_shipping_taxes_amount(sum(a.products_special_taxes_shipping_taxes_amount, b.products_special_taxes_shipping_taxes_amount))
            .products_transit_fees_amount(sum(a.products_transit_fees_amount, b.products_transit_fees_amount))
            .build();
    }
    toJSON() {
        return {
            products_duty_label: this.products_duty_label,
            products_duty_percentage: this.products_duty_percentage,
            products_duty_product_taxes_amount: this.products_duty_product_taxes_amount,
            products_duty_shipping_taxes_amount: this.products_duty_shipping_taxes_amount ? this.products_duty_shipping_taxes_amount : null,
            products_vat_label: this.products_vat_label,
            products_vat_percentage: this.products_vat_percentage,
            products_vat_product_taxes_amount: this.products_vat_product_taxes_amount,
            products_vat_shipping_taxes_amount: this.products_vat_shipping_taxes_amount,
            products_special_taxes_label: this.products_special_taxes_label,
            products_special_taxes_percentage: this.products_special_taxes_percentage,
            products_special_taxes_product_taxes_amount: this.products_special_taxes_product_taxes_amount,
            products_special_taxes_shipping_taxes_amount: this.products_special_taxes_shipping_taxes_amount,
            products_transit_fees_amount: this.products_transit_fees_amount
        };
    }
}


export class Tax {
    label: string;
    percentage: number;
    product_taxes_amount: number;
    shipping_taxes_amount: number;
    packaging_taxes_amount: number;
    insurance_taxes_amount: number;
    message: string;
    agreement: string;
    private _vat_product_taxes_amount;
    private _vat_shipping_taxes_amount;
    private _vat_packaging_taxes_amount;
    private _vat_insurance_taxes_amount;
    private _vat_taxes_amount;
    get vat_product_taxes_amount(): number {
        return this._vat_product_taxes_amount ? Number((Math.ceil(this._vat_product_taxes_amount * 100) / 100).toFixed(2)) : 0;
    }
    get vat_shipping_taxes_amount(): number {
        return this._vat_shipping_taxes_amount ? Number((Math.ceil(this._vat_shipping_taxes_amount * 100) / 100).toFixed(2)) : 0;
    }
    get vat_packaging_taxes_amount(): number {
        return this._vat_packaging_taxes_amount ? Number((Math.ceil(this._vat_packaging_taxes_amount * 100) / 100).toFixed(2)) : 0;
    }
    get vat_insurance_taxes_amount(): number {
        return this._vat_insurance_taxes_amount ? Number((Math.ceil(this._vat_insurance_taxes_amount * 100) / 100).toFixed(2)) : 0;
    }
    get vat_taxes_amount(): number {
        return this._vat_taxes_amount ? Number((Math.ceil(this._vat_taxes_amount * 100) / 100).toFixed(2)) : 0;
    }
    set vat_product_taxes_amount(value: number) {
        this._vat_product_taxes_amount = value;
    }
    set vat_shipping_taxes_amount(value: number) {
        this._vat_shipping_taxes_amount = value;
    }
    set vat_packaging_taxes_amount(value: number) {
        this._vat_packaging_taxes_amount = value;
    }
    set vat_insurance_taxes_amount(value: number) {
        this._vat_insurance_taxes_amount = value;
    }
    set vat_taxes_amount(value: number) {
        this._vat_taxes_amount = value;
    }
}

export class ShippingGlobal {
    duty: TaxGlobal;
    vat: TaxGlobal[];
    salestax: TaxGlobal[];
    special_taxes: TaxGlobal[];
}

export class TaxGlobal {
    private _amount: number;
    private _vat_amount: number;
    percentage: number;
    label: string;
    message: string;
    get amount(): number {
        return this._amount ? Number(this._amount.toFixed(2)) : null;
    }
    set amount(value: number) {
        this._amount = value;
    }
    get vat_amount(): number {
        return this._vat_amount ? Number(this._vat_amount.toFixed(2)) : null;
    }
    set vat_amount(value: number) {
        this._vat_amount = value;
    }
    constructor(label: string, amount: number, message = '') {
        this.label = label;
        this._amount = amount;
        this.message = message;
    }
}

export class AllAmounts {
    amount_exclusive: number; // ht
    amount_inclusive_vat: number; // ttc
    amount_total: number; // ttc + duty + special taxes + ecoTax (if any)
    amount_duty_and_tax: number; // duty + vat + special taxes
    percentage_duty_and_tax: number; // total_tax / amount_exclusive
}

export class DutyFee extends AllAmounts {
    _amount: number;
    get amount(): number {
        return this._amount ? Number(this._amount.toFixed(2)) : null;
    }
}

export class TaxResponseGlobal extends DutyFee {
    products_co2: number;
    products_shipping_co2: number;
    amount_ecoTax: number;
}

export class RequestProduct {
    identification: RequestProductIdentification;
    origin_country: string;
    weight: number;
    weight_unit: string;
    unit: number;
    unit_type: string;
    transport: DutyTransport;
    quantity: number;
    unit_price: number;
    unit_ship_price: number;
    currency_unit_price: string;
    unit_packaging_price: number;
    unit_insurance_price: number;
    group_shipping_price: number;
    group_packaging_price: number;
    group_insurance_price: number;
    from_country: string;
    from_district: string;
    to_country: string;
    to_district: string;
    incoterm?: INCOTERM_ENUM;
    included_tax?: boolean;
    sender: TaxRequestSender;
    transit_fees: TransitFees;
    toJSON() {
        return {
            identification: this.identification,
            weight: Number(this.weight),
            weight_unit: this.weight_unit,
            quantity: this.quantity ? Number(this.quantity) : null,
            unit_price: this.unit_price ? Number(this.unit_price) : null,
            currency_unit_price: this.currency_unit_price,
            unit_ship_price: this.unit_ship_price ? Number(this.unit_ship_price) : null,
            transport:  this.transport,
            unit: this.unit ? Number(this.unit) : null,
            unit_type: this.unit_type,
            origin_country: this.origin_country ? this.origin_country : undefined,
            unit_packaging_price: this.unit_packaging_price,
            unit_insurance_price: this.unit_insurance_price,
            group_shipping_price: this.group_shipping_price,
            group_packaging_price: this.group_packaging_price,
            group_insurance_price: this.group_insurance_price,
            from_country: this.from_country,
            from_district: this.from_district,
            to_country: this.to_country,
            to_district: this.to_district,
            incoterm: this.incoterm,
            included_tax: this.included_tax,
            sender: this.sender,
            transit_fees: this.transit_fees
        };
    }
    constructor(delivery_feature: string, identification: RequestProductIdentification, weight: number, weight_unit: string, unit: number,
                unit_type: string, quantity: number, unit_price: number, currency_unit_price: string, unit_ship_price: number,
                currency_unit_ship_price: string, transport: DutyTransport, origin_country?: string) {
        this.identification = identification;
        this.weight = weight;
        this.weight_unit = weight_unit;
        this.unit = unit;
        this.unit_type = unit_type;
        this.quantity = quantity;
        this.unit_price = unit_price;
        this.currency_unit_price = currency_unit_price;
        this.unit_ship_price = unit_ship_price;
        this.transport = transport;
        this.origin_country = origin_country;
    }
    toCsvRequestProduct(): CsvRequestProduct {
        return Builder(CsvRequestProduct)
            .products_value(this.identification.value)
            .products_transport_type(this.transport ? this.transport.type : null)
            .products_transport_id(this.transport ? this.transport.id : null)
            .products_weight(this.weight)
            .products_weight_unit(this.weight_unit)
            .products_quantity(this.quantity)
            .products_unit_price(this.unit_price)
            .products_currency_unit_price(this.currency_unit_price)
            .products_unit_ship_price(this.unit_ship_price)
            .products_unit(this.unit)
            .products_unit_types(this.unit_type)
            .build();
    }
}

export class RequestProductAllCurrencies extends RequestProduct {
    currency_unit_ship_price: string;
    currency_unit_insurance_price: string;
    currency_unit_packaging_price: string;
}

export class CsvRequestProduct {
    products_value: string;
    products_transport_type: string;
    products_transport_id: string;
    products_weight: number;
    products_weight_unit: string;
    products_quantity: number;
    products_unit_price: number;
    products_currency_unit_price: string;
    products_unit_ship_price: number;
    products_currency_unit_ship_price: string;
    products_unit: number;
    products_unit_types: string;
    products_origin_country: string;

    static sumRequestProduct = (rp1: CsvRequestProduct, rp2: CsvRequestProduct) => {
        if (!rp1) {
            return rp2;
        }

        return Builder(CsvRequestProduct)
            .products_value(concatIfDifferent(rp1.products_value, rp2.products_value))
            .products_transport_type(concatIfDifferent(rp1.products_transport_type, rp2.products_transport_type))
            .products_transport_id(concatIfDifferent(rp1.products_transport_id, rp2.products_transport_id))
            .products_weight(rp1.products_weight + rp2.products_weight)
            .products_weight_unit(concatIfDifferent(rp1.products_weight_unit, rp2.products_weight_unit))
            .products_quantity(rp1.products_quantity + rp2.products_quantity)
            .products_unit_price(rp1.products_unit_price + rp2.products_unit_price)
            .products_currency_unit_price(concatIfDifferent(rp1.products_currency_unit_price, rp2.products_currency_unit_price))
            .products_unit_ship_price(sum(rp1.products_unit_ship_price, rp2.products_unit_ship_price))
            .products_currency_unit_ship_price(concatIfDifferent(rp1.products_currency_unit_ship_price, rp2.products_currency_unit_ship_price))
            .products_unit(sum(rp1.products_unit, rp2.products_unit))
            .products_unit_types(concatIfDifferent(rp1.products_unit_types, rp2.products_unit_types))
            .products_origin_country(concatIfDifferent(rp1.products_origin_country, rp2.products_origin_country))
            .build();
    }

    toJSON() {
        return {
            products_value: this.products_value,
            products_transport_type: this.products_transport_type ? this.products_transport_type : '',
            products_transport_id: this.products_transport_id ? this.products_transport_id : '',
            products_weight: this.products_weight,
            products_weight_unit: this.products_weight_unit,
            products_quantity: this.products_quantity,
            products_unit_price: this.products_unit_price,
            products_currency_unit_price: this.products_currency_unit_price,
            products_unit_ship_price: this.products_unit_ship_price ? this.products_unit_ship_price : null,
            products_currency_unit_ship_price: this.products_currency_unit_ship_price ? this.products_currency_unit_ship_price : null,
            products_unit: this.products_unit ? this.products_unit : null,
            products_unit_types: this.products_unit_types ? this.products_unit_types : null,
            products_origin_country: this.products_origin_country ? this.products_origin_country : null
        };
    }
}

const concatIfDifferent = (v1: string, v2: string) => {
    v1 = v1 ? v1 : '';
    v2 = v2 ? v2 : '';
    return v1.includes(v2) ? v1 : `${v1} , ${v2}`;
};


export interface DutyTransport {
    type: ShipmentTypeEnum;
    id: string;
}

export enum ShipmentTypeEnum {
    CARRIER = 'CARRIER',
    FREIGHT_FORWARDER = 'FREIGHT_FORWARDER'
}

export enum LANG_ENUM {
    FR = 'fr',
    EN = 'en',
    ES = 'es',
    AR = 'ar',
    RU = 'ru',
    HI = 'hi',
    CN = 'cn',
    PT = 'pt'
}

export enum WEIGHT_UNIT {
    T = 't',
    KG = 'kg',
    G = 'g',
    TON = 't',
    LB = 'lb',
    OZ = 'oz'
}

export enum SHIPMENT_REQUEST_TYPE_ENUM {
    GLOBAL = 'GLOBAL',
    GROUP = 'GROUP',
    ARTICLE = 'ARTICLE'
}

export enum ECOMMERCE_REQUEST_TYPE_ENUM {
    ESHOP = 'ESHOP',
    MARKETPLACE = 'MARKETPLACE'
}

export class TaxRequest {
    shipment_type: SHIPMENT_REQUEST_TYPE_ENUM;
    ecommerce_type?: ECOMMERCE_REQUEST_TYPE_ENUM;
    products: RequestProductAllCurrencies[];
    from_country: string;
    from_district: string;
    to_country: string;
    to_district: string;
    incoterm?: INCOTERM_ENUM;
    global_ship_price: number;
    global_packaging_price: number;
    global_insurance_price: number;
    currency_global_ship_price: string;
    currency_global_packaging_price: string;
    currency_global_insurance_price: string;
    included_tax?: boolean;
    sender: TaxRequestSender;
    receiver: TaxRequestReceiver;
    transport?: DutyTransport;
    transit_fees?: TransitFees;
    extra_fees?: number;
    lang?: string;
}

/*export class TaxRequest {
    shipment_type: string;
    products: RequestProduct[];
    lang: string;
    from_country: string;

    from_district: string;
    to_country: string;
    to_district: string;
    global_ship_price: number;
    currency_global_ship_price: string;

    sender: TaxRequestSender;
    receiver: TaxRequestReceiver;
    transport?: DutyTransport;
    transit_fees?: TransitFees;
}*/

export class DutyTransport {
    type: ShipmentTypeEnum;
    id: string;
}

export class TransitFees {
    percentage: number;
    amount: number;
    currency: string;
    on_products_price: boolean;
    on_shipping_products_price: boolean;
    on_global: boolean;
}


export class TaxRequestSender {
    pro: boolean;
    revenue_country_annual: number;
    currency_revenue_country_annual: string;
}

export class TaxRequestReceiver {
    pro: boolean;
    activity_id: string;
}

