import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';

export class CsvFieldToSet {
    id: string;
    externalField?: string; // not used for arrays
    label: string;
    fields?: CsvFieldToSet[][]; // used only for arrays
    isRequired?: boolean; // not used for arrays
}

@Component({
    selector: 'csv-mapper',
    templateUrl: './csv-mapper.component.html',
    styleUrls: ['./csv-mapper.component.scss']
})
export class CsvMapperComponent implements OnInit, OnDestroy {
    @ViewChild('csvmapper', { static: false }) csvmapper: TemplateRef<any>;

    @Input()
    customTitle?: string;
    @Input()
    freeEntryFields: boolean = false;
    @Input()
    displayModal: Observable<void>;
    @Input()
    modelFields: CsvFieldToSet[] = [];
    @Input()
    externalCsvFields: string[] = [];
    @Output()
    validateFields: EventEmitter<CsvFieldToSet[]> = new EventEmitter<CsvFieldToSet[]>();

    modalForm: FormGroup;
    formIsInvalid = false;
    modalRef: BsModalRef = null;

    private subscriptions: Subscription;

    constructor(
        private modalService: BsModalService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        // for the pipeline
        if (this.displayModal) {
            this.subscriptions = this.displayModal.subscribe(() => {
                const interval = setInterval(() => {
                    // let the time to modelFields to be filled
                    if (this.modelFields.length > 0) {
                        clearInterval(interval);
                        this.initModalForm();
                        this.openModal();
                    }
                }, 150);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.subscriptions = null;
    }

    getFormArray(id: string): FormArray {
        return this.modalForm.get(id) as FormArray;
    }

    getArrayFormGroup(arrayId: string, index: number): FormGroup {
        return this.getFormArray(arrayId).at(index) as FormGroup;
    }

    getArrayFormGroupModels(arrayId: string): CsvFieldToSet[] {
        const foundArrayField = this.modelFields.find(f => f.id === arrayId);
        if (foundArrayField && foundArrayField.fields && foundArrayField.fields.length > 0) {
            return foundArrayField.fields[0];
        }
        return [];
    }

    initModalForm(): void {
        this.modalForm = new FormGroup({});
        this.modelFields.forEach(field => {
            if (field.fields) {
                this.addArrayInForm(field);
            } else {
                this.addFieldInFormGroup(field);
            }
        });
    }

    addFieldInFormGroup(field: CsvFieldToSet, fg: FormGroup = this.modalForm, arrayId?: string) {
        let external: string;
        if (! arrayId) {
            external = this.externalCsvFields.find(e => e === field.id);
        } else {
            external = this.externalCsvFields.find(e => e === `${arrayId}_1_${field.id}`);
        }
        if (field.isRequired) {
            fg.addControl(
                field.id,
                new FormControl(external ? external : (field.externalField && field.externalField !== '' ? field.externalField : null), [Validators.required])
            );
        } else {
            fg.addControl(
                field.id,
                new FormControl(external ? external : (field.externalField && field.externalField ? field.externalField : null), [])
            );
        }
    }

    addArrayInForm(fieldArray: CsvFieldToSet) {
        this.modalForm.addControl(fieldArray.id, new FormArray([]));
        fieldArray.fields.forEach(f => {
            this.addFieldsInFormArray(fieldArray.id, f);
        });
    }
    addFieldsInFormArray(arrayId: string, fields: CsvFieldToSet[]) {
        const array = this.getFormArray(arrayId);
        const fg = new FormGroup({});
        fields.forEach(field => {
            this.addFieldInFormGroup(field, fg, arrayId);
        });
        array.push(fg);
    }

    // on click
    onAddFieldsInFormArray(arrayId: string) {
        const arrayFields = this.modelFields.find(f => f.id === arrayId);
        if (arrayFields && arrayFields.fields && arrayFields.fields.length > 0) {
            const emptyFields = [...arrayFields.fields[0]];
            this.addFieldsInFormArray(arrayId, emptyFields);
        }
    }
    // on click
    onRemoveFieldsInFormArray(arrayId: string, index: number) {
        this.getFormArray(arrayId).removeAt(index);
    }

    openModal() {
        const config = { class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false } as ModalOptions;
        this.modalRef = this.modalService.show(this.csvmapper, config);
    }

    closeModal(emit = true) {
        this.formIsInvalid = false;
        if (this.modalRef) {
            this.modalRef.hide();
            this.modalRef = null;
        }
        if (emit) {
            this.validateFields.emit(null);
        }
    }

    onValidMapping() {
        if (this.modalForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const newModelFields: CsvFieldToSet[] = [];
        const value = this.modalForm.value;

        const keys = Object.keys(value);
        keys.forEach(fieldId => {
            // array
            if (Array.isArray(value[fieldId])) {
                for (let i = 0; i < value[fieldId].length; i++) {
                    const arrKeys = Object.keys(value[fieldId][i]);
                    arrKeys.forEach(arrFieldId => {
                        newModelFields.push(
                            Builder(CsvFieldToSet)
                            .id(`${fieldId}_${i + 1}_${arrFieldId}`)
                            .externalField(value[fieldId][i][arrFieldId])
                            .build()
                        );
                    });
                }

            // field
            } else {
                newModelFields.push(
                    Builder(CsvFieldToSet)
                    .id(fieldId)
                    .externalField(value[fieldId])
                    .build()
                );
            }
        });

        this.validateFields.emit(newModelFields);
        this.closeModal(false);
    }
}
