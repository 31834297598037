import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StepDestinationForm } from '../../../../../../models/Register';

const countriesPerContinent = require('../../../../../../../assets/data/countriesPerContinent.json');

@Component({
    selector: 'app-register-step-destination',
    templateUrl: './step_destination.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepDestinationComponent implements OnInit {
    @Input()
    formContent: StepDestinationForm;
    @Input()
    fromCountry: string;
    @Input()
    countries: { value: string, label: string, iso2: string }[] = [];
    @Output()
    nextStepCallback: EventEmitter<StepDestinationForm> = new EventEmitter<StepDestinationForm>();

    continentsWithCountries: {
        AS: string[];
        EU: string[];
        AF: string[];
        NA: string[];
        SA: string[];
        OC: string[];
        AN: string[];
    } = {AS: [], EU: [], AF: [], NA: [], SA: [], OC: [], AN: []};

    continents = ['AS', 'EU', 'AF', 'NA', 'SA', 'OC', 'AN'];

    formContentReturn: StepDestinationForm;

    constructor(private translate: TranslateService) {}

    ngOnInit() {
        this.formContentReturn = {...this.formContent};
        this.continentsWithCountries = {...countriesPerContinent} as {
            AS: string[];
            EU: string[];
            AF: string[];
            NA: string[];
            SA: string[];
            OC: string[];
            AN: string[];
        };

        this.sortEveryCountry();
    }

    sortEveryCountry(): void {
        for (let i = 0; i < this.continents.length; i++) {
            // sort the list
            this.continentsWithCountries[this.continents[i]].sort((a, b) => {
                const countryA = this.countries.find(c => c.value === a);
                const countryB = this.countries.find(c => c.value === b);
                const countryALabel = countryA ? countryA.label : 'ZZZZZZZZZZ';
                const countryBLabel = countryB ? countryB.label : 'ZZZZZZZZZZ';
                return countryALabel.localeCompare(countryBLabel, this.translate.currentLang, {ignorePunctuation: true});
            });
        }
    }

    getCountryIso2(country): string {
        const countryFound = this.countries.find(c => c.value === country);
        return countryFound && countryFound.iso2 ? countryFound.iso2.toLowerCase() : '';
    }

    getCountryLabel(country): string {
        const countryFound = this.countries.find(c => c.value === country);
        return countryFound ? countryFound.label : '';
    }

    allContinentCountriesSelected(continent): boolean {
        return this.continentsWithCountries[continent]
                .filter(c => this.formContentReturn.countries.includes(c)).length === this.continentsWithCountries[continent].length;
    }

    allCountriesSelected(): boolean {
        const wholeCountriesLength = this.continentsWithCountries.AF.length
                                    +
                                    this.continentsWithCountries.EU.length
                                    +
                                    this.continentsWithCountries.AS.length
                                    +
                                    this.continentsWithCountries.NA.length
                                    +
                                    this.continentsWithCountries.SA.length
                                    +
                                    this.continentsWithCountries.OC.length
                                    +
                                    this.continentsWithCountries.AN.length;

        return this.formContentReturn.countries.length === wholeCountriesLength;
    }

    onAllCountriesClicked(): void {
        if (!this.allCountriesSelected()) {
            this.formContentReturn.countries = [];
            this.onContinentClick('AF');
            this.onContinentClick('AS');
            this.onContinentClick('EU');
            this.onContinentClick('NA');
            this.onContinentClick('SA');
            this.onContinentClick('OC');
            this.onContinentClick('AN');
        } else {
            this.formContentReturn.countries = [];
        }
    }

    onContinentClick(iso2: string): void {
        // all countries are selected? unselect all
        if (this.allContinentCountriesSelected(iso2)) {
            this.formContentReturn.countries = this.formContentReturn.countries.filter(c => !this.continentsWithCountries[iso2].includes(c));

        // add non selected countries in selected countries list
        } else {
            this.continentsWithCountries[iso2].forEach(country => {
                if (!this.formContentReturn.countries.includes(country)) {
                    this.formContentReturn.countries.push(country);
                }
            });
        }
    }

    // add / remove a country from the selected list
    onCountryClick(iso3: string): void {
        if (!this.formContentReturn.countries.includes(iso3)) {
            this.formContentReturn.countries.push(iso3);
        } else {
            this.formContentReturn.countries.splice(this.formContentReturn.countries.indexOf(iso3), 1);
        }
    }

    nextStep() {
        this.nextStepCallback.emit(this.formContentReturn);
    }
}
