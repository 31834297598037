import {Component, OnInit} from '@angular/core';
import {AuthService} from './@pages/auth/auth.service';
import {Gtag} from 'angular-gtag';
import {TranslateService} from '@ngx-translate/core';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Transiteo!';
  constructor(private authService: AuthService, gtag: Gtag, translate: TranslateService) {
    // redirect url to main url in order to avoid CORS issues
    const url = window.location.href;
    if (! url.startsWith(environment.webappUrl)) {
      window.location.replace(environment.webappUrl);
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  async ngOnInit() {
    await this.authService.subscribe();
  }


}
