import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    paragraph: string;
    @Input()
    leftPosition = false;

    showTooltip = false;

    constructor() { }

    ngOnInit() {
    }

    onToggleShowTooltip() {
        this.showTooltip = ! this.showTooltip;
    }

}
