import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortLabel',
})
export class SortLabelPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (!value) {
            return;
        }
        return value.sort((a, b) => a.label.localeCompare(b.label));
    }
}
