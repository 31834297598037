import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { CustomerCustomer, ORDER_TYPE_CUSTOMER } from '../../../models/Managers';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderAmountsData, ProductQuantityData } from '../../../models/Monitoring';
import { Subject, Subscription } from 'rxjs';
import { Utils } from '../../utils/utils';
import { ApiDataService } from '../../services/api-data.service';
import { CsvService } from '../../services/csv.service';
const us_states = require('../../../../assets/data/US_states.json');

@Component({
    selector: 'app-customers-manager',
    templateUrl: './customers-manager.component.html',
    styleUrls: ['./customers-manager.component.scss'],
})
export class CustomerManagerComponent implements OnInit, OnDestroy {
    @ViewChild('confirmDeleteRow', { static: false }) confirmDeleteRow: TemplateRef<any>;
    @ViewChild('confirmDeleteRows', { static: false }) confirmDeleteRows: TemplateRef<any>;
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;

    countries: Array<{ value: string, label: string, iso2: string }> = [];
    districts: Array<{ value: string, label: string }> = [];
    us_states: Array<{ value: string, label: string }> = [];
    currencies: { value: string, label: string, disabled: boolean }[] = [];
    genders: {value: string, label: string}[] = [];
    booleanChoices: {value: string, label: string}[];

    dataType: string;
    modalRef: BsModalRef;
    rowForm: FormGroup;
    formIsInvalid = false;
    isEditing = false;
    closingModal = false;

    // table rows
    rows: any[] = [];
    customers: CustomerCustomer[] = [];

    columnsData: string[] = [];

    // customer to delete
    customerToDeleteWebsite: string = null;

    unknown_error: string;
    columnsLabels: any;

    // phone
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates];
    separateDialCode = false;

    // custom table necessary vars
    displayRowOnly = false;
    rowsToDelete: string[] = [];
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];
    deletionSubject: Subject<void> = new Subject<void>();
    requestSelectedRowsSubject: Subject<void> = new Subject<void>();

    private subscription: Subscription;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private csvService: CsvService,
        private formBuilder: FormBuilder,
        private apiDataService: ApiDataService
    ) {
        this.us_states = us_states;
    }

    ngOnInit() {
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        this.initTranslation();
        this.setColumnsCustomer();
        this.retrieveData();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubscription();
    }

    unsubscribeSubscription() {
        if (this.subscription) {
            try {
                this.subscription.unsubscribe();
            } catch (error) {
                // just set subscription to null
                this.subscription = null;
            }
        }
    }

    // is there any row to display?
    emptyRows(): boolean {
        if (this.rows === null || this.rows && this.rows.length === 0) {
            return true;
        }
        return false;
    }

    async retrieveData() {
        this.customers = await this.getCustomers();
        if (this.customers !== null) {
            this.customers.forEach((customer) => {
                const customerCustomer = this.buildCustomer(customer);
                this.rows.push(customerCustomer.toRow());
            });
            this.rows = [...this.rows];
        }
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
        });
        this.translate.get('customersManager').toPromise().then(customersManager => {
            this.columnsLabels = customersManager.inputForm;
        });
        this.translate.get('genders').subscribe((genders) => {
            this.genders = genders;
        });
        this.translate.get('currency_name').subscribe((currencies) => {
            this.currencies = currencies.sort(Utils.compareByLabel);
        });
        this.translate.get('profile.boolean_choice').toPromise().then((categories) => {
            this.booleanChoices = categories;
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;

            this.columnsLabels = event.translations['customersManager']['inputForm'];
            this.genders = event.translations['genders'] ? event.translations['genders'] : this.genders;
            this.booleanChoices = event.translations.boolean_choice;
            this.currencies = event.translations['currency_name'] ? event.translations['currency_name'].sort(Utils.compareByLabel) : this.currencies;
            this.setCountries();
        });
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return '';
        }
    }

    openModal(template: TemplateRef<any>, type: string, id?: string) {
        if (this.rows) {
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            if (type === 'addrow') {
                this.initRowForm(null);
            } else {
                const foundRow = this.rows.find(r => r.website === id);
                if (foundRow) {
                    if (type === 'editrow' || type === 'displayrow') {
                        this.initRowForm(foundRow);
                    } else {
                        this.customerToDeleteWebsite = id;
                    }
                }
            }
            this.modalRef = this.modalService.show(template, config);
        }
    }

    closeModal() {
        this.formIsInvalid = false;
        this.closingModal = true;
        this.isEditing = false;
        this.displayRowOnly = false;
        this.modalRef.hide();
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    initRowForm(row: any) {
        // edit row
        if (row) {
            const rightCustomer = this.customers.find(c => c.website === row.website);
            console.log(rightCustomer);
            this.isEditing = true;
            this.rowForm = this.formBuilder.group({
                customerMail: [rightCustomer.customerMail, [Validators.required, Validators.email]],
                website: [rightCustomer.website, [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
                firstname: [rightCustomer.firstname, [Validators.required, Validators.minLength(1)]],
                lastname: [rightCustomer.lastname, [Validators.required, Validators.minLength(1)]],
                gender: [rightCustomer.gender, [Validators.required, Validators.minLength(1)]],
                age: [rightCustomer.age, [Validators.required, Validators.min(1)]],
                phoneNumber: [rightCustomer.phoneNumber, [Validators.required, Validators.minLength(1)]],
                country: [rightCustomer.country, [Validators.required, Validators.minLength(1)]],
                state: ['', [Validators.required, Validators.minLength(1)]],
                zipCode: [rightCustomer.zipCode, [Validators.required, Validators.minLength(1)]],
                address: [rightCustomer.address, [Validators.required, Validators.minLength(1)]],
                city: [rightCustomer.city, [Validators.required, Validators.minLength(1)]],
                pro: [rightCustomer.pro, Validators.required],
                companyName: [rightCustomer.companyName],
                companyId: [rightCustomer.companyId],
                companyTaxId: [rightCustomer.companyTaxId],
                paidOrders: this.formBuilder.array([]),
                awaitingOrders: this.formBuilder.array([]),
                canceledOrders: this.formBuilder.array([]),
                buyedProducts: this.formBuilder.array([])
            });
            rightCustomer.paidOrders.forEach(po => {
                this.onAddOrder(po, ORDER_TYPE_CUSTOMER.PAID);
            });
            rightCustomer.awaitingOrders.forEach(ao => {
                this.onAddOrder(ao, ORDER_TYPE_CUSTOMER.AWAITING);
            });
            rightCustomer.canceledOrders.forEach(co => {
                this.onAddOrder(co, ORDER_TYPE_CUSTOMER.CANCELED);
            });
            rightCustomer.buyedProducts.forEach(bp => {
                this.onAddProduct(bp);
            });
            if (rightCustomer.country) {
                this.setDistricts(rightCustomer.country).then(() => {
                    if (rightCustomer.state) {
                        this.rowForm.get('state').setValue(rightCustomer.state);
                    }
                });
            }

        // new row
        } else {
            this.rowForm = this.formBuilder.group({
                customerMail: ['', [Validators.required, Validators.email]],
                website: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
                firstname: ['', [Validators.required, Validators.minLength(1)]],
                lastname: ['', [Validators.required, Validators.minLength(1)]],
                gender: ['', [Validators.required, Validators.minLength(1)]],
                age: ['', [Validators.required, Validators.min(1)]],
                phoneNumber: ['', [Validators.required, Validators.minLength(1)]],
                zipCode: ['', [Validators.required, Validators.minLength(1)]],
                address: ['', [Validators.required, Validators.minLength(1)]],
                city: ['', [Validators.required, Validators.minLength(1)]],
                state: ['', [Validators.required, Validators.minLength(1)]],
                country: ['', [Validators.required, Validators.minLength(1)]],
                pro: [false, Validators.required],
                companyName: [''],
                companyId: [''],
                companyTaxId: [''],
                paidOrders: this.formBuilder.array([]),
                awaitingOrders: this.formBuilder.array([]),
                canceledOrders: this.formBuilder.array([]),
                buyedProducts: this.formBuilder.array([])
            });
        }
        this.subscription = this.rowForm.get('country').valueChanges.subscribe(iso3 => {
            this.setDistricts(iso3);
        });
    }

    // add/delete product in/from buyedProducts list
    onDeleteProduct(i: number) {
        this.buyedProductsForm.removeAt(i);
    }
    onAddProduct(product: ProductQuantityData) {
        this.buyedProductsForm.push(this.formBuilder.group({
            productId: [product ? product.productId : '', [Validators.required, Validators.minLength(1)]],
            productQuantity: [product ? product.productQuantity : 1, [Validators.required, Validators.min(1)]],
            productPrice: [product ? product.productPrice : 0, [Validators.required, Validators.min(0.01)]],
            productCurrency: [product ? product.productCurrency : 'EUR', [Validators.required, Validators.minLength(1)]],
        }));
    }
    get buyedProductsForm(): FormArray {
        return this.rowForm.get('buyedProducts') as FormArray;
    }

    // add/delete order in/from paid / awaiting / canceled orders
    onDeleteOrder(i: number, type: ORDER_TYPE_CUSTOMER) {
        (this.getOrdersForm(type)).removeAt(i);
    }
    onAddOrder(order: OrderAmountsData, type: ORDER_TYPE_CUSTOMER) {
        (this.getOrdersForm(type)).push(this.formBuilder.group({
            orderId: [order ? order.orderId : '', [Validators.required, Validators.minLength(1)]],
            orderDutyAmount: [order ? order.orderDutyAmount : 0, [Validators.required, Validators.min(0)]],
            orderVatAmount: [order ? order.orderVatAmount : 0, [Validators.required, Validators.min(0)]],
            orderSpecialTaxesAmount: [order ? order.orderSpecialTaxesAmount : 0, [Validators.required, Validators.min(0)]],
            orderTotalAmount: [order ? order.orderTotalAmount : 0, [Validators.required, Validators.min(0)]],
            orderCurrency: [order ? order.orderCurrency : 'EUR', [Validators.required, Validators.minLength(1)]],
        }));
    }
    getOrdersForm(type: ORDER_TYPE_CUSTOMER): FormArray {
        return this.rowForm.get(type) as FormArray;
    }

    editedCustomerIsPro(): boolean {
        const formValue = this.rowForm ? this.rowForm.value : null;
        return formValue && formValue.pro;
    }

    // if pro, some other fields must be set
    companyPartIsRight(): boolean {
        const formValue = this.rowForm.value;
        if (formValue.pro) {
            if (
                ! formValue.companyName
                ||
                formValue.companyName.length === 0
                ||
                ! formValue.companyId
                ||
                formValue.companyId.length === 0
                ||
                ! formValue.companyTaxId
                ||
                formValue.companyTaxId.length === 0
            ) {
                return false;
            }
        }
        return true;
    }

    async onValidRow() {
        if (this.rowForm.invalid || ! this.companyPartIsRight()) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.rowForm.value;
        const buyedProducts = formValue.buyedProducts as ProductQuantityData[];
        const paidOrders = formValue.paidOrders as OrderAmountsData[];
        const awaitingOrders = formValue.awaitingOrders as OrderAmountsData[];
        const canceledOrders = formValue.canceledOrders as OrderAmountsData[];
        
        for (let i = 0; i < buyedProducts.length; i++) {
            buyedProducts[i].productPrice = parseFloat(buyedProducts[i].productPrice.toString());
        }
        for (let i = 0; i < paidOrders.length; i++) {
            paidOrders[i].orderDutyAmount = parseFloat(paidOrders[i].orderDutyAmount.toString());
            paidOrders[i].orderSpecialTaxesAmount = parseFloat(paidOrders[i].orderSpecialTaxesAmount.toString());
            paidOrders[i].orderVatAmount = parseFloat(paidOrders[i].orderVatAmount.toString());
            paidOrders[i].orderTotalAmount = parseFloat(paidOrders[i].orderTotalAmount.toString());
        }
        for (let i = 0; i < awaitingOrders.length; i++) {
            awaitingOrders[i].orderDutyAmount = parseFloat(awaitingOrders[i].orderDutyAmount.toString());
            awaitingOrders[i].orderSpecialTaxesAmount = parseFloat(awaitingOrders[i].orderSpecialTaxesAmount.toString());
            awaitingOrders[i].orderVatAmount = parseFloat(awaitingOrders[i].orderVatAmount.toString());
            awaitingOrders[i].orderTotalAmount = parseFloat(awaitingOrders[i].orderTotalAmount.toString());
        }
        for (let i = 0; i < canceledOrders.length; i++) {
            canceledOrders[i].orderDutyAmount = parseFloat(canceledOrders[i].orderDutyAmount.toString());
            canceledOrders[i].orderSpecialTaxesAmount = parseFloat(canceledOrders[i].orderSpecialTaxesAmount.toString());
            canceledOrders[i].orderVatAmount = parseFloat(canceledOrders[i].orderVatAmount.toString());
            canceledOrders[i].orderTotalAmount = parseFloat(canceledOrders[i].orderTotalAmount.toString());
        }

        const customer = Builder(CustomerCustomer)
                        .customerMail(formValue.customerMail)
                        .website(formValue.website)
                        .firstname(formValue.firstname)
                        .lastname(formValue.lastname)
                        .gender(formValue.gender)
                        .age(formValue.age)
                        .phoneNumber(formValue.phoneNumber.e164Number)
                        .zipCode(formValue.zipCode)
                        .address(formValue.address)
                        .city(formValue.city)
                        .state(formValue.state)
                        .country(formValue.country)
                        .pro(formValue.pro)
                        .companyName(formValue.companyName)
                        .companyId(formValue.companyId)
                        .companyTaxId(formValue.companyTaxId)
                        .paidOrders(paidOrders)
                        .awaitingOrders(awaitingOrders)
                        .canceledOrders(canceledOrders)
                        .buyedProducts(buyedProducts)
                        .build();

        const rowIndex = this.rows.findIndex(r => r.website === customer.website);
        if (rowIndex >= 0) {
            const customerIndex = this.customers.findIndex(c => c.website === customer.website);
            this.customers[customerIndex] = customer;
            this.rows[rowIndex] = customer.toRow();
            await this.putCustomer(customer);
        } else {
            await this.postCustomer(customer);
        }
        // refresh the table
        this.rows = [...this.rows];

        this.closeModal();
        this.unsubscribeSubscription();
    }

    // CUSTOMERS FUNCTIONS
    buildCustomer(customer: CustomerCustomer): CustomerCustomer {
        return Builder(CustomerCustomer)
            .customerMail(customer.customerMail)
            .website(customer.website)
            .firstname(customer.firstname)
            .lastname(customer.lastname)
            .gender(customer.gender)
            .age(customer.age)
            .phoneNumber(customer.phoneNumber)
            .zipCode(customer.zipCode)
            .address(customer.address)
            .city(customer.city)
            .state(customer.state)
            .country(customer.country)
            .companyName(customer.companyName)
            .companyId(customer.companyId)
            .companyTaxId(customer.companyTaxId)
            .pro(customer.pro)
            .paidOrders(customer.paidOrders)
            .awaitingOrders(customer.awaitingOrders)
            .canceledOrders(customer.canceledOrders)
            .buyedProducts(customer.buyedProducts)
            .build();
    }
    setColumnsCustomer() {
        this.columnsData = [
            'customerMail',
            'website',
            'firstname',
            'lastname',
            'gender',
            'age',
            'phoneNumber',
            'zipCode',
            'address',
            'city',
            'state',
            'country',
            'companyName',
            'companyId',
            'companyTaxId',
            'pro',
            'paidOrders',
            'awaitingOrders',
            'canceledOrders',
            'buyedProducts'
        ];
    }

    // delete customer, returns true on success, false otherwise
    async deleteCustomer(customerWebsite: string): Promise<boolean> {
        this.closeModal();
        this.customerToDeleteWebsite = null;
        try {
            const response = await this.http.delete(`https://api.${environment.baseUrl}/v1/customer/customers?website=${encodeURIComponent(customerWebsite)}`).toPromise();
            const index = this.rows.findIndex(r => r.website === customerWebsite);
            this.rows.splice(index, 1);
            this.rows = [...this.rows];
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    async getCustomers(): Promise<CustomerCustomer[]> {
        try {
            return (<CustomerCustomer[]> await this.http.get(
                `https://api.${environment.baseUrl}/v1/customer/customers`
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    // post customers, returns true on success, false otherwise
    async postCustomer(customerCustomer: CustomerCustomer): Promise<boolean> {
        try {
            await this.http.post(`https://api.${environment.baseUrl}/v1/customer/customers`, customerCustomer).toPromise();
            this.customers.push(customerCustomer);
            this.rows.push(customerCustomer.toRow());
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // put customer, returns true on success, false otherwise
    async putCustomer(customerCustomer: CustomerCustomer): Promise<boolean> {
        try {
            await this.http.put(`https://api.${environment.baseUrl}/v1/customer/customers?website=${encodeURIComponent(customerCustomer.website)}`, customerCustomer).toPromise();
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ? c[`label_${this.translate.currentLang}`] : c[`label_en`]
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
            this.initFormatColumnsValues();
        } catch (error) {
            console.error(error);
        }
    }

    async setDistricts(countryIso3: string) {
        this.rowForm.get('state').reset();
        if (countryIso3 === 'USA') {
            this.districts = us_states;
        } else {
            this.districts = (
                await this.http.get(`https://api.${environment.baseUrl}/v2/data/districts?iso3=${countryIso3}`).toPromise()
            ) as { value: string, label: string }[];
        }

        // sort the list
        this.districts.sort((a, b) => {
            return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
        });
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv(rowsIds: string[]) {
        if (rowsIds.length > 0) {
            const csvResponse = this.rows.filter(r => rowsIds.includes(r.website));
            const csvFileName = 'my_customers';
            return this.csvService.downloadFile(csvResponse, csvFileName);
        }
    }

    rowsHaveBeenDeleted() {
        this.deletionSubject.next();
    }

    onDownloadSelectedRowsAsCsv() {
        this.requestSelectedRowsSubject.next();
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['country'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }

        this.formatColumnsValues = formatValues;
    }

    onDisplayRow(rowId: string) {
        this.displayRowOnly = true;
        this.openModal(this.editrow, 'displayrow', rowId);
    }
    onEditRow(rowId: string) {
        this.displayRowOnly = false;
        this.openModal(this.editrow, 'editrow', rowId);
    }
    onDeleteRow(rowId: string) {
        this.openModal(this.confirmDeleteRow, 'deleterow', rowId);
    }
    onDeleteRows(selectedRows: string[]) {
        if (this.rowsToDelete.length === 0) {
            this.rowsToDelete = selectedRows;
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            this.modalRef = this.modalService.show(this.confirmDeleteRows, config);
        }
    }
    onCancelDeleteRows() {
        this.rowsToDelete = [];
        this.closeModal();
    }
    async onValidRowDeletion() {
        this.closeModal();
        await this.deleteCustomer(this.customerToDeleteWebsite);
        this.customerToDeleteWebsite = null;
        this.rowsHaveBeenDeleted();
    }
    // delete rows 10 by 10
    async onValidRowsDeletion() {
        this.closeModal();

        let allSelectedOrdersDeleted = true;
        const rowsIds: string[][] = [];
        rowsIds.push([]);
        this.rowsToDelete.forEach(orderId => {
            if (rowsIds[rowsIds.length - 1].length === 10) {
                rowsIds.push([]);
            }
            rowsIds[rowsIds.length - 1].push(orderId);
        });

        for (let i = 0; i < rowsIds.length; i++) {
            const promises = await Promise.all(rowsIds[i].map(r => this.deleteCustomer(r)));
            if (promises.includes(false)) {
                allSelectedOrdersDeleted = false;
            }
        }

        this.rowsHaveBeenDeleted();

        if (! allSelectedOrdersDeleted) {
            this._notification.displayMessage(this.translate.instant('messages.notification.someRowsNotDeleted'), 'warning');
        } else {
            this._notification.displayMessage(this.translate.instant('messages.notification.rowsDeleted'), 'success');
        }
    }
}
