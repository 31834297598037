import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UploadFile } from "../../../components/upload/interface";
import { pgUploadComponent } from "../../../components/upload/upload.component";
import { CsvService } from "../../../services/csv.service";
import { environment } from "../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "../../../components/message/message.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-ai-classify-documents",
  templateUrl: "./ai-classify-documents.component.html",
  styleUrls: ["./ai-classify-documents.component.scss"],
})
export class AiClassifyDocumentsComponent implements OnInit, OnDestroy {
  @ViewChild(pgUploadComponent, { static: false })
  pgUploadComponent: pgUploadComponent;
  importedInvoiceContent = '';
  unknown_error = '';
  fileName = '';

  private subscription: Subscription;
  private readonly uploadInvoicePdf: string;

  constructor(
    private csvService: CsvService,
    private http: HttpClient,
    private _notification: MessageService,
    private translate: TranslateService
  ) {
    this.uploadInvoicePdf = `https://api.${environment.baseUrl}/v1/import/invoicePdf`;
  }

  ngOnInit(): void {
    this.initTranslation();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initTranslation() {
    this.translate
      .get('messages')
      .toPromise()
      .then((messages) => {
        this.unknown_error = messages.notification.unknown_error;
      });

    this.subscription = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        this.unknown_error =
          event.translations.messages.notification.unknown_error;
      }
    );
  }

  beforeUploadInvoice = async (file: UploadFile, _: UploadFile[]) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file as any);
    this.cleanFiles(file);
    reader.onloadend = async (e) => {
      this.fileName = file.name.replace('.pdf', '');
      this.importedInvoiceContent = reader.result as string;
    };
    return true;
  };
  removeFile(file: UploadFile) {
    this.pgUploadComponent.onRemove(file);
    this.importedInvoiceContent = '';
    this.fileName = '';
    return false;
  }
  cleanFiles(except: UploadFile) {
    this.pgUploadComponent.FileList.forEach((file) => {
      if (except && except.uid !== file.uid) {
        this.pgUploadComponent.onRemove(file);
      }
    });
    this.importedInvoiceContent = '';
    this.fileName = '';
  }

  async searchFromImportedInvoice(): Promise<void> {
    const rawData = await this.uploadInvoiceGetRawData(this.importedInvoiceContent);

    if (rawData) {
      const formattedGoogleDocumentAIData: {
        type: string;
        content: string;
        confidence: string;
      }[] = [];
      rawData.forEach((data) => {
        if (data.properties && data.properties.length > 0) {
          data.properties.forEach((property) => {
            const dataToAppend: {
              type: string;
              content: string;
              confidence: string;
            } = {
              type: property.type,
              content: property.mentionText.replace(/\n/g, ' '),
              confidence: `${(property.confidence * 100).toFixed(2)}%`,
            };
            formattedGoogleDocumentAIData.push(dataToAppend);
          });
        } else {
          const dataToAppend: {
            type: string;
            content: string;
            confidence: string;
          } = {
            type: data.type,
            content: data.mentionText.replace(/\n/g, ' '),
            confidence: `${(data.confidence * 100).toFixed(2)}%`,
          };
          formattedGoogleDocumentAIData.push(dataToAppend);
        }
      });
      this.buildAndDownloadCsv(formattedGoogleDocumentAIData);
    }
  }

  buildAndDownloadCsv(data: any): void {
    this.csvService.downloadFile(
      data,
      `ai-classify-documents_${this.fileName}`
    );
  }

  // upload the invoice, get back raw response from Google Document AI
  async uploadInvoiceGetRawData(pdfContent: string): Promise<any[]> {
    try {
      const res = (await this.http
        .post(this.uploadInvoicePdf, { content: pdfContent, getRawData: true })
        .toPromise()) as any[];
      return res;
    } catch (errorResponse) {
      this._notification.displayMessage(
        this._notification.buildTransiteoErrorMessage(
          errorResponse.error,
          this.unknown_error
        ),
        'danger'
      );
      return null;
    }
  }
}
