import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsMilliseconds',
})
export class SecondsMillisecondsPipe implements PipeTransform {
    transform(value: number): string {
        const seconds: number = Math.floor(value / 1000);
        const milliseconds: number = (value - seconds * 1000);
        return seconds + 's' + (milliseconds === 0 ? '00' + milliseconds : milliseconds < 100 ? '0' + milliseconds : milliseconds) + 'ms';
     }
}
