import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from '../../../../models/User';
import { ManagersService } from '../../../services/managers.service';

@Component({
    selector: 'app-certificate-of-origin',
    templateUrl: './certificate-of-origin.component.html',
    styleUrls: ['./certificate-of-origin.component.scss']
})
export class CertificateOfOriginComponent implements OnInit, OnDestroy {
    @Input()
    countries: { label: string; iso2: string; value: string; }[] = [];
    @Input()
    user: User;
    @Output()
    setData: EventEmitter<any> = new EventEmitter<any>();
    pdfForm: FormGroup;
    formIsInvalid = false;

    filteredOptions: Observable<string[]> = new Observable<string[]>();

    private subscriptions: Subscription;

    constructor(
        private managersService: ManagersService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.managersService.prepareCustomers();
        this.managersService.prepareProducts();
        this.pdfForm = this.formBuilder.group({
            authority_signature_city: [null],
            authority_signature_date: [null],
            certification: [null],
            coo_id: [null],
            date: [new Date(), [Validators.required]],
            invoice_id: [null, [Validators.required]],
            mean_of_transports: [null],
            officiel_use: [null],
            origin_country: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.customs_classification.default_origin_country : null, [Validators.required]],
            product_brand: [null, [Validators.required]],
            product_id: [null, [Validators.required]],
            product_origin: [null, [Validators.required]],
            product_package_id: [null],
            product_quantity: [1, [Validators.required]],
            product_title: [null, [Validators.required]],
            receiver_address: [null, [Validators.required]],
            receiver_city: [null, [Validators.required]],
            receiver_country: [null, [Validators.required]],
            receiver_name: [null, [Validators.required]],
            receiver_zipcode: [null, [Validators.required]],
            route: [null],
            seller_address: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.address : null, [Validators.required]],
            seller_city: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.city : null, [Validators.required]],
            seller_country: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.country : null, [Validators.required]],
            seller_name: [this.user && this.user.ecommerceProperties ? `${this.user.ecommerceProperties.account.firstname} ${this.user.ecommerceProperties.account.lastname}` : null, [Validators.required]],
            seller_zipcode: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.expedition.postal_code : null, [Validators.required]],
            signature: [this.user && this.user.ecommerceProperties ? this.user.ecommerceProperties.signature : null, [Validators.required]],
            signature_authority: [null],
            to_country: [null, [Validators.required]],
        });
        this.subscriptions = new Subscription();
        // those fields are used by multiple fields, we must update the value
        this.subscriptions.add(
            this.pdfForm.get('seller_city').valueChanges.subscribe(val => this.pdfForm.get('seller_city').setValue(val, {emitEvent: false}))
        );
        this.subscriptions.add(
            this.pdfForm.get('origin_country').valueChanges.subscribe(val => this.pdfForm.get('origin_country').setValue(val, {emitEvent: false}))
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    isRequired(field: string): boolean {
        if (this.pdfForm) {
            const validator = this.pdfForm.get(field).validator ? this.pdfForm.get(field).validator({} as AbstractControl) : null;
            if (validator && validator.required) {
                return true;
            }
        }
    }

    filterKey(event) {
        return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    prepareAutocompleteCustomer(controlName: string, fieldName: string) {
        const control = this.pdfForm.get(controlName);
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsCustomers(), fieldName)),
        );
    }
    prepareAutocompleteProduct(controlName: string, fieldName: string) {
        const control = this.pdfForm.get(controlName);
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), fieldName)),
        );
    }
    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        const filterValue = value.toLowerCase();
        return wholeValues.filter(option => {
            const optionSplitted = option.split(', ');
            if (filterField === 'sku') {
                return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
            }
            let i = 0;
            if (filterField === 'email') {
                i = 1;
            } else if (filterField === 'website') {
                i = 2;
            }
            return optionSplitted[i].toLowerCase().includes(filterValue);
        });
    }
    onCustomerClicked(option: string) {
        // website is the sort key
        const website = option.split(', ')[2];
        const rightCustomer = this.managersService.getCustomers().find(c => c.website === website);
        if (rightCustomer) {
            this.pdfForm.get('receiver_name').setValue(`${rightCustomer.firstname} ${rightCustomer.lastname}`);
            this.pdfForm.get('receiver_address').setValue(rightCustomer.address);
            this.pdfForm.get('receiver_zipcode').setValue(rightCustomer.zipCode);
            this.pdfForm.get('receiver_city').setValue(rightCustomer.city);
            this.pdfForm.get('receiver_country').setValue(rightCustomer.country);
            this.pdfForm.get('to_country').setValue(rightCustomer.country);
        }
    }
    onProductClicked(option: string) {
        // sku is the sort key
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            this.pdfForm.get('product_id').setValue(rightProduct.sku);
            this.pdfForm.get('product_title').setValue(rightProduct.value);
        }
    }

    async onValidPdfForm() {
        if (this.pdfForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formValue = this.pdfForm.value;
        this.setData.emit(formValue);
    }
}
