import { Injectable } from '@angular/core';

import { AmplifyService } from 'aws-amplify-angular';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { awsconfig } from '../../../aws-export';
import { MessageService } from '../components/message/message.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ClientMetaData } from '@aws-amplify/auth/src/types/index';
import { TranslateService } from '@ngx-translate/core';
// import { ApiAccount, CmsAccount, WebAccount } from '../../models/Register';
// import { USING_STATUT_ENUM } from '../../models/User';

Amplify.configure(awsconfig);
@Injectable({
    providedIn: 'root',
})

export class AuthService {
    isLoggedIn: boolean;
    // store the URL so we can redirect after logging in
    redirectUrl: string;
    queryParams: any;
    other_messages;
    constructor(private amplifyService: AmplifyService,
        private userService: UserService,
        private router: Router,
        private _notification: MessageService,
        private translate: TranslateService) {
        this.translate.get('messages').subscribe(messages => {
            this.other_messages = messages;
        });
    }
    async login(username: string, password: string, newPassword?: string): Promise<any> {
        await Auth.signIn(username, password).then(async (user: CognitoUser | any) => {
            if (user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                console.warn('SMS_MFA AND SOFTWARE_TOKEN_MFA NOT IMPLEMENTED');
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                if (newPassword) {
                    const { requiredAttributes } = user.challengeParam;
                    Auth.completeNewPassword(user, newPassword, requiredAttributes).then(async userLogged => {
                        this.userService.setCognitoUser(userLogged);
                        this.isLoggedIn = true;
                        return await this.userService.initTransiteoUser(false);
                    }).catch(e => {
                        console.error(e);
                        const message = this.other_messages.notification.unknown_error;
                        throw new Error(message);
                    });
                } else {
                    throw new Error(this.other_messages.notification.PasswordResetRequiredException);
                }
            } else if (user.challengeName === 'MFA_SETUP') {
                console.warn('MFA_SETUP NOT IMPLEMENTED');
            } else {
                this.userService.setCognitoUser(user);
                this.isLoggedIn = true;
                return await this.userService.initTransiteoUser(true);
            }
        }).catch(err => {
            let message;
            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                message = this.other_messages.notification.UserNotConfirmedException;
            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
                message = this.other_messages.notification.PasswordResetRequiredException;
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                message = this.other_messages.notification.NotAuthorizedException;
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                message = this.other_messages.notification.UserNotConfirmedException;
            } else {
                console.error(err);
                message = this.other_messages.notification.unknown_error;
            }
            throw new Error(message);
        });
    }
    async logout() {
        try {
            await Auth.signOut();
            this.isLoggedIn = false;
            this.userService.setCognitoUser(null);
            this.userService.setUser(null);
        } catch (err) {
            console.error(err);
        }
    }
    signUp = async (
        firstname: string,
        lastname: string,
        email: string,
        lang: string,
        // currency: string,
        password: string,
        integration_method: string,
        // phone: string,
        // using_type: USING_STATUT_ENUM,
        // using_type_content: CmsAccount // | ApiAccount[] | WebAccount[]
    ) => {
        this.isLoggedIn = false;
        const clientMetadata: ClientMetaData = {
            integration_method
        };
        await Auth.signUp({
            username: email,
            password: password,
            attributes: {
                name: firstname,
                family_name: lastname,
                email: email,

                // phone_number: phone,
                'custom:company_name': '',
                'custom:company_activity': '',
                'custom:currency': 'EUR',
                'custom:lang': lang
            },
            clientMetadata: clientMetadata
        })
            .then(data => data)
            .catch(err => {
                console.error(err);
                throw err;
            });
    }

    getCognitoUser = async () => {
        return await Auth.currentSession();
    }

    getCodeFromUserInput = () => {
        return 'TO_CHANGE';
    }

    getInfoFromUserInput = () => {
        return {
            newPassword: 'newPassword',
            email: 'email',
            phone_number: 'phone_number'
        };
    }

    subscribe = async () => {
        await this.amplifyService.authStateChange$
            .subscribe(async authState => {
                this.isLoggedIn = authState.state === 'signedIn';
                if (!authState.user) {
                    this.userService.setCognitoUser(null);
                    this.userService.setUser(null);
                    this.isLoggedIn = false;
                } else {
                    const user = authState.user;
                    this.userService.setCognitoUser(user);
                    const usr = await this.userService.getUserPromise();
                    // check at least one parameter
                    if (
                        !usr.ecommerceProperties.account
                        ||
                        (
                            (usr.ecommerceProperties.account.integration_method !== 'API' && usr.ecommerceProperties.account.integration_method !== 'CHROME EXTENSION' && usr.ecommerceProperties.account.integration_method !== 'SAAS')
                            &&
                            !usr.ecommerceProperties.account.domain_name
                        )
                    ) {
                        await this.router.navigate(['register']);
                    } else {
                        if (!this.redirectUrl || (this.redirectUrl && this.redirectUrl.includes('login'))) {
                            await this.router.navigate(['dashboard']);
                        } else {
                            await this.router.navigate([this.redirectUrl], { queryParams: this.queryParams });
                        }
                    }
                }
            });
    }
    async reconnect() {
        return await this.userService.initTransiteoUser(false)
            .catch(async error => {
                this._notification.displayMessage(this.other_messages.notification.disconnectException + error.message, 'danger');
                await new Promise(resolve => setTimeout(resolve, 5000));
                await this.logout().then(() => this.router.navigate(['/login']));
                return null;
            });
    }
    async changePassword(user: CognitoUser, oldPassword: string, newPassword: string) {
        const clientMetadata: ClientMetaData = { lang: this.translate.currentLang.toUpperCase() };
        return await Auth.changePassword(user, oldPassword, newPassword, clientMetadata);
    }
    resetPassword = (email: string, isWhiteLabel = false) => {
        const clientMetadata: ClientMetaData = { lang: this.translate.currentLang.toUpperCase(), isWhiteLabel: isWhiteLabel.toString() };
        return Auth.forgotPassword(email, clientMetadata);
    }
    confirmResetPassword = async (email: string, password: string, code: string) => {
        const clientMetadata: ClientMetaData = { lang: this.translate.currentLang.toUpperCase() };
        return await Auth.forgotPasswordSubmit(email, code, password, clientMetadata);
    }
}
