import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { REGISTER_STEP_FINAL_BUTTONS } from '../../../../../../models/Register';
@Component({
    selector: 'app-register-step-final',
    templateUrl: './step_final.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepFinalComponent implements OnInit {
    @Output()
    nextStepCallback: EventEmitter<REGISTER_STEP_FINAL_BUTTONS> = new EventEmitter<REGISTER_STEP_FINAL_BUTTONS>();

    constructor() {}

    ngOnInit() {}

    validModifyParameters() {
        this.nextStep(REGISTER_STEP_FINAL_BUTTONS.MODIFY);
    }

    validWithPlan() {
        this.nextStep(REGISTER_STEP_FINAL_BUTTONS.CHOOSE_PLAN);
    }

    validWithoutPlan() {
        this.nextStep(REGISTER_STEP_FINAL_BUTTONS.CHOOSE_PLAN_LATER);
    }

    nextStep(action: REGISTER_STEP_FINAL_BUTTONS) {
        this.nextStepCallback.emit(action);
    }
}
