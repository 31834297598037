import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../components/message/message.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Builder } from 'builder-pattern';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AllUserCustomers, AllUserOrders, AllUserProducts, MonitoringCustomerCustomer, MonitoringCustomerOrder, MonitoringCustomerProduct, MONITORING_DATA_TYPE } from '../../../../models/Monitoring';
import { User } from '../../../../models/User';
import { UserService } from '../../../services/user.service';
import { Utils } from '../../../utils/utils';
@Component({
    selector: 'app-monitoring-data',
    templateUrl: './monitoring-data.component.html',
    styleUrls: ['./monitoring-data.component.scss'],
})
export class MonitoringDataComponent implements OnInit, OnDestroy {
    @ViewChild('myTable', { static: false }) table: any;

    dataType: string;

    // to request the API in order to get the next data
    nextIndex: any;

    // table rows
    rows: any[] = [];
    columnsData: string[] = [];

    unknown_error: string;
    columnsLabels: any;

    title = '';

    columnId = '';
    countries: Array<any> = [];
    orderStatus: Array<any> = [];
    currencies: Array<any> = [];

    private user: User;
    private subscription: Subscription;
    private isRetrievingData = false;

    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    // ONLY ORDERS
    // expanded rows contents
    productsDetails: {[key: string]: {sku: string; quantity: number; unit_price: number; unit_price_currency: string; }[]};
    productsDetailsColumns: string[] = [];
    productsDetailsColumnsLabels: { [key: string]: string } = {};

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private userService: UserService
    ) {
        this.productsDetails = {};
    }

    async ngOnInit() {
        this.user = await this.userService.getUserPromise();
        this.subscription = this.route.paramMap.subscribe(async params => {
            this.reinitializeVars();
            this.dataType = params.get('type');

            this.initTranslation();
            await this.retrieveData();
            switch (this.dataType) {
                case MONITORING_DATA_TYPE.ORDERS:
                    this.setColumnsOrder();
                    this.columnId = 'order_id';
                    break;
                case MONITORING_DATA_TYPE.PRODUCTS:
                    this.columnId = 'sku';
                    break;
                case MONITORING_DATA_TYPE.CUSTOMERS:
                    this.setColumnsCustomer();
                    this.columnId = 'website';
                    break;

                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            try {
                this.subscription.unsubscribe();
                this.subscription = null;
            } catch (error) {
                this.subscription = null;
            }
        }
    }

    reinitializeVars() {
        this.nextIndex = undefined;
        this.rows = [];
        this.columnsData = [];
        this.columnsLabels = {};
        this.productsDetails = {};
        this.productsDetailsColumns = [];
        this.productsDetailsColumnsLabels = {};
        this.formatColumnsValues = [];
        this.isRetrievingData = false;
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        switch (this.dataType) {
            case MONITORING_DATA_TYPE.ORDERS:
                // prepare format dates
                formatValues.push({
                    columns: ['order_date_hour', 'order_payment_date', 'order_update_statut'],
                    type: 'Date'
                });

                // prepare format currencies
                if (this.currencies) {
                    formatValues.push({
                        columns: ['currency'],
                        type: this.currencies as { value: string; label: string; }[]
                    });
                }

                // prepare format countries
                if (this.countries) {
                    formatValues.push({
                        columns: ['customer_country', 'buyer_country', 'agent_country', 'arrival_country', 'departure_country'],
                        type: this.countries as { value: string; label: string; iso2: string; }[]
                    });
                }

                // prepare format order status
                if (this.orderStatus) {
                    formatValues.push({
                        columns: ['order_statut'],
                        type: this.orderStatus as { value: string; label: string; }[]
                    });
                }
                break;

            case MONITORING_DATA_TYPE.PRODUCTS:
                // prepare format countries
                if (this.countries) {
                    formatValues.push({
                        columns: ['from_country', 'to_country', 'origin_country'],
                        type: this.countries as { value: string; label: string; iso2: string; }[]
                    });
                }

                // prepare format currencies
                if (this.currencies) {
                    formatValues.push({
                        columns: [
                            'margin_currency',
                            'currency_cost_price',
                            'threshold_tax_currency',
                            'currency_minimis',
                            'ecoTax_price_currency',
                            'currency_ship_price',
                            'currency_unit_price',
                            'currency_revenue_country_annual',
                            'currency_cost_price',
                            'purchase_currency_unit_price',
                            'sale_currency_unit_price'
                        ],
                        type: this.currencies as { value: string; label: string; }[]
                    });
                }

                this.formatColumnsValues = formatValues;
                break;
            case MONITORING_DATA_TYPE.CUSTOMERS:
                // prepare format countries
                if (this.countries) {
                    formatValues.push({
                        columns: ['country'],
                        type: this.countries as { value: string; label: string; iso2: string; }[]
                    });
                }
                break;

            default:
                break;
        }

        this.formatColumnsValues = formatValues;
    }

    async retrieveData() {
        this.isRetrievingData = true;
        switch (this.dataType) {
            case MONITORING_DATA_TYPE.ORDERS:
                const orders: AllUserOrders = await this.getOrders();
                if (orders !== null) {
                    this.nextIndex = orders.nextIndex;
                    orders.data.forEach((order) => {
                        const customerOrder = this.buildOrder(order);
                        this.rows.push(customerOrder.toRow());
                        this.productsDetails[customerOrder.order_id] = customerOrder.products;
                    });
                    this.rows = [...this.rows];
                }
                break;

            case MONITORING_DATA_TYPE.PRODUCTS:
                const products: AllUserProducts = await this.getProducts();
                if (products !== null) {
                    this.nextIndex = products.nextIndex;
                    products.data.forEach((product) => {
                        const customerProduct = this.buildProduct(product);
                        this.rows.push(customerProduct.toRow());
                    });
                    this.rows = [...this.rows];
                    this.setColumnsProduct();
                }
                break;

            case MONITORING_DATA_TYPE.CUSTOMERS:
                const customers: AllUserCustomers = await this.getCustomers();
                if (customers !== null) {
                    this.nextIndex = customers.nextIndex;
                    customers.data.forEach((customer) => {
                        const customerCustomer = this.buildCustomer(customer);
                        this.rows.push(customerCustomer.toRow());
                    });
                    this.rows = [...this.rows];
                }
                break;

            default:
                break;
        }
        this.isRetrievingData = false;
    }

    // shall we display the table?
    emptyRows(): boolean {
        if (this.rows === null || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').toPromise().then((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            switch (this.dataType) {
                case MONITORING_DATA_TYPE.ORDERS:
                    // get currencies
                    this.translate.get('currency_name').toPromise().then(currencies => {
                        this.currencies = currencies;
                        this.initFormatColumnsValues();
                    });

                    // get countries
                    this.translate.get('countries').toPromise().then((all_countries) => {
                        this.countries = all_countries.sort(Utils.compareByLabel);
                        this.initFormatColumnsValues();
                    });
                    this.translate.get('ordersManager').toPromise().then(ordersManager => {
                        this.orderStatus = ordersManager.order_status;
                        this.productsDetailsColumnsLabels = ordersManager.inputForm.product;
                        this.productsDetailsColumns = Object.keys(this.productsDetailsColumnsLabels);
                        this.columnsLabels = ordersManager.inputForm;
                        this.columnsLabels['mail'] = ordersManager.monitoring.mail;
                        this.columnsLabels['company_name'] = ordersManager.monitoring.company_name;
                        this.title = ordersManager.monitoring.title;
                        this.initFormatColumnsValues();
                    });
                    break;

                case MONITORING_DATA_TYPE.PRODUCTS:
                    // get currencies
                    this.translate.get('currency_name').toPromise().then(currencies => {
                        this.currencies = currencies;
                        this.initFormatColumnsValues();
                    });

                    // get countries
                    this.translate.get('countries').toPromise().then((all_countries) => {
                        this.countries = all_countries.sort(Utils.compareByLabel);
                        this.initFormatColumnsValues();
                    });
                    this.translate.get('productsManager').toPromise().then(productsManager => {
                        this.columnsLabels = productsManager.inputForm;
                        const calculationResultsKeys = Object.keys(productsManager.calculationResults);
                        calculationResultsKeys.forEach(k => {
                            this.columnsLabels[k] = productsManager.calculationResults[k];
                        });
                        this.columnsLabels['mail'] = productsManager.monitoring.mail;
                        this.columnsLabels['company_name'] = productsManager.monitoring.company_name;
                        this.title = productsManager.monitoring.title;
                    });
                    break;

                case MONITORING_DATA_TYPE.CUSTOMERS:
                    // get countries
                    this.translate.get('countries').toPromise().then((all_countries) => {
                        this.countries = all_countries.sort(Utils.compareByLabel);
                        this.initFormatColumnsValues();
                    });
                    this.translate.get('customersManager').toPromise().then(customersManager => {
                        this.columnsLabels = customersManager.inputForm;
                        this.columnsLabels['mail'] = customersManager.monitoring.mail;
                        this.columnsLabels['user_company_name'] = customersManager.monitoring.user_company_name;
                        this.title = customersManager.monitoring.title;
                    });
                    break;

                default:
                    break;
            }
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;

            switch (this.dataType) {
                case MONITORING_DATA_TYPE.ORDERS:
                    this.productsDetailsColumnsLabels = event.translations.ordersManager.inputForm.product;
                    this.columnsLabels = event.translations['ordersManager']['inputForm'];
                    this.columnsLabels['mail'] = event.translations['ordersManager'].monitoring.mail;
                    this.columnsLabels['company_name'] = event.translations['ordersManager'].monitoring.company_name;
                    this.title = event.translations['ordersManager'].monitoring.title;
                    this.countries = event.translations['countries'].sort(Utils.compareByLabel);
                    this.currencies = event.translations['currency_name'];
                    this.orderStatus = event.translations.ordersManager.order_status;
                    break;

                case MONITORING_DATA_TYPE.PRODUCTS:
                    this.columnsLabels = event.translations['productsManager']['inputForm'];
                    const calculationResultsKeys = Object.keys(event.translations['productsManager'].calculationResults);
                    calculationResultsKeys.forEach(k => {
                        this.columnsLabels[k] = event.translations['productsManager'].calculationResults[k];
                    });
                    this.columnsLabels['mail'] = event.translations['productsManager'].monitoring.mail;
                    this.columnsLabels['company_name'] = event.translations['productsManager'].monitoring.company_name;
                    this.title = event.translations['productsManager'].monitoring.title;
                    this.countries = event.translations['countries'].sort(Utils.compareByLabel);
                    this.currencies = event.translations['currency_name'];
                    break;

                case MONITORING_DATA_TYPE.CUSTOMERS:
                    this.columnsLabels = event.translations['customersManager']['inputForm'];
                    this.columnsLabels['mail'] = event.translations['customersManager'].monitoring.mail;
                    this.columnsLabels['user_company_name'] = event.translations['customersManager'].monitoring.user_company_name;
                    this.title = event.translations['customersManager'].monitoring.title;
                    this.countries = event.translations['countries'].sort(Utils.compareByLabel);
                    break;

                default:
                    break;
            }
            this.initFormatColumnsValues();
        });
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return col;
        }
    }

    onScrollToEnd() {
        if (!this.isRetrievingData && this.nextIndex !== undefined) {
            this.retrieveData();
        }
    }

    getNoRowtranslationText(): string {
        switch (this.dataType) {
            case MONITORING_DATA_TYPE.ORDERS:
                return 'ordersManager.noRow';
            case MONITORING_DATA_TYPE.PRODUCTS:
                return 'productsManager.noRow';
            case MONITORING_DATA_TYPE.CUSTOMERS:
                return 'customersManager.noRow';
            default:
                return '';
        }
    }

    // ORDERS FUNCTIONS
    buildOrder(order: MonitoringCustomerOrder): MonitoringCustomerOrder {
        const found = this.user.superAdminUsersMinimalData.find(u => u.email === order.mail);
        return Builder(MonitoringCustomerOrder)
            .mail(order.mail)
            .company_name(found && found.company_name ? found.company_name : '')
            .order_id(order.order_id)
            .invoice_id(order.invoice_id)
            .url(order.url)
            .order_date_hour(new Date(order.order_date_hour))
            .customer_firstname(order.customer_firstname)
            .customer_lastname(order.customer_lastname)
            .customer_address(order.customer_address)
            .customer_zipcode(order.customer_zipcode)
            .customer_city(order.customer_city)
            .customer_country(order.customer_country)
            .buyer_firstname(order.buyer_firstname)
            .buyer_lastname(order.buyer_lastname)
            .buyer_address(order.buyer_address)
            .buyer_zipcode(order.buyer_zipcode)
            .buyer_city(order.buyer_city)
            .buyer_country(order.buyer_country)
            .agent_firstname(order.agent_firstname)
            .agent_lastname(order.agent_lastname)
            .agent_address(order.agent_address)
            .agent_zipcode(order.agent_zipcode)
            .agent_city(order.agent_city)
            .agent_country(order.agent_country)
            .departure_country(order.departure_country)
            .arrival_country(order.arrival_country)
            .products(order.products)
            .exporting_carrier(order.exporting_carrier)
            .shipping_carrier(order.shipping_carrier)
            .amount_products(order.amount_products)
            .amount_shipping(order.amount_shipping)
            .amount_shipping_duty(order.amount_shipping_duty)
            .amount_shipping_vat(order.amount_shipping_vat)
            .amount_shipping_specialtaxes(order.amount_shipping_specialtaxes)
            .amount_duty(order.amount_duty)
            .amount_vat(order.amount_vat)
            .amount_specialtaxes(order.amount_specialtaxes)
            .currency(order.currency)
            .order_statut(order.order_statut)
            .order_update_statut(new Date(order.order_update_statut))
            .order_payment_type(order.order_payment_type)
            .order_payment_date(new Date(order.order_payment_date))
            .category_of_item(order.category_of_item)
            .payment_type(order.payment_type)
            .build();
    }
    setColumnsOrder() {
        this.columnsData = [
            'mail',
            'company_name',
            'order_id',
            'invoice_id',
            'amount_products',
            'amount_shipping',
            'amount_shipping_duty',
            'amount_shipping_vat',
            'amount_shipping_specialtaxes',
            'amount_duty',
            'amount_vat',
            'amount_specialtaxes',
            'currency',
            'url',
            'order_date_hour',
            'customer_firstname',
            'customer_lastname',
            'customer_address',
            'customer_zipcode',
            'customer_city',
            'customer_country',
            'buyer_firstname',
            'buyer_lastname',
            'buyer_address',
            'buyer_zipcode',
            'buyer_city',
            'buyer_country',
            'agent_firstname',
            'agent_lastname',
            'agent_address',
            'agent_zipcode',
            'agent_city',
            'agent_country',
            'order_payment_type',
            'order_payment_date',
            'departure_country',
            'arrival_country',
            'exporting_carrier',
            'shipping_carrier',
            'category_of_item',
            'payment_type',
            'order_statut',
            'order_update_statut'
        ];
    }
    dataTypeIsOrder(): boolean {
        return this.dataType === MONITORING_DATA_TYPE.ORDERS;
    }
    async getOrders(): Promise<AllUserOrders> {
        try {
            return (<AllUserOrders> await this.http.post(
                `https://api.${environment.baseUrl}/v1/monitoring/users/getOrders`,
                {nextIndex: this.nextIndex}
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    // PRODUCTS FUNCTIONS
    buildProduct(product: MonitoringCustomerProduct): MonitoringCustomerProduct {
        const found = this.user.superAdminUsersMinimalData.find(u => u.email === product.mail);
        const ignoredProductKeys = [
            'mail',
            'company_name',
            'id',
            'type',
            'productID',
            'value',
            'weight',
            'weight_unit',
            'creationDate',
            'hscodefinder',
            'dutyCalculation'
        ];
        const productKeys = Object.keys(product).filter(k => ! ignoredProductKeys.includes(k));
        const returnProduct = Builder(MonitoringCustomerProduct)
            .mail(product.mail)
            .company_name(found && found.company_name ? found.company_name : '')
            .sku(product.sku)
            .value(product.value)
            .weight(product.weight)
            .weight_unit(product.weight_unit)
            .build();

        productKeys.forEach(k => {
            returnProduct[k] = product[k];
        });

        return returnProduct;
    }
    setColumnsProduct() {
        this.columnsData = [
            'mail',
            'company_name',
            'sku',
            'categories_id',
            'productID',
            'weight',
            'weightUnit'
        ];

        this.rows.forEach(row => {
            const keys = Object.keys(row);
            keys.forEach(key => {
                if (! this.columnsData.includes(key)) {
                    this.columnsData.push(key);
                }
            });
        });

        this.columnsData.forEach(cd => {
            if (! this.columnsLabels[cd]) {
                this.columnsLabels[cd] = cd;
            }
        });

    }
    dataTypeIsProduct(): boolean {
        return this.dataType === MONITORING_DATA_TYPE.PRODUCTS;
    }
    async getProducts(): Promise<AllUserProducts> {
        try {
            return (<AllUserProducts> await this.http.post(
                `https://api.${environment.baseUrl}/v1/monitoring/users/getProducts`,
                {nextIndex: this.nextIndex}
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    // CUSTOMERS FUNCTIONS
    buildCustomer(customer: MonitoringCustomerCustomer): MonitoringCustomerCustomer {
        const found = this.user.superAdminUsersMinimalData.find(u => u.email === customer.mail);
        return Builder(MonitoringCustomerCustomer)
            .mail(customer.mail)
            .user_company_name(found && found.company_name ? found.company_name : '')
            .customerMail(customer.customerMail)
            .website(customer.website)
            .firstname(customer.firstname)
            .lastname(customer.lastname)
            .gender(customer.gender)
            .age(customer.age)
            .phoneNumber(customer.phoneNumber)
            .zipCode(customer.zipCode)
            .address(customer.address)
            .city(customer.city)
            .state(customer.state)
            .country(customer.country)
            .companyName(customer.companyName)
            .companyId(customer.companyId)
            .companyTaxId(customer.companyTaxId)
            .pro(customer.pro)
            .paidOrders(customer.paidOrders)
            .awaitingOrders(customer.awaitingOrders)
            .canceledOrders(customer.canceledOrders)
            .buyedProducts(customer.buyedProducts)
            .build();
    }
    setColumnsCustomer() {
        this.columnsData = [
            'mail',
            'user_company_name',
            'customerMail',
            'website',
            'firstname',
            'lastname',
            'gender',
            'age',
            'phoneNumber',
            'zipCode',
            'address',
            'city',
            'state',
            'country',
            'companyName',
            'companyId',
            'companyTaxId',
            'pro',
            'paidOrders',
            'awaitingOrders',
            'canceledOrders',
            'buyedProducts'
        ];
    }
    dataTypeIsCustomer(): boolean {
        return this.dataType === MONITORING_DATA_TYPE.CUSTOMERS;
    }
    async getCustomers(): Promise<AllUserCustomers> {
        try {
            return (<AllUserCustomers> await this.http.post(
                `https://api.${environment.baseUrl}/v1/monitoring/users/getCustomers`,
                {nextIndex: this.nextIndex}
            ).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }
}

