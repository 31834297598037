import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customs-clearance',
  templateUrl: './customs-clearance.component.html',
  styleUrls: ['./customs-clearance.component.scss']
})
export class CustomsClearanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
