import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FilterTableField } from '../filter-table/filter-table.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

enum SORTABLE_OPTIONS {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING'
}

@Component({
    selector: 'custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, OnDestroy {
    @ViewChild('myTable', { static: false }) table: any;
    // mandatory vars
    @Input()
    rows: { [key: string]: any }[] = [];
    @Input()
    columnsToDisplay: string[] = [];
    @Input()
    columnsLabels: { [key: string]: string } = {};
    @Input()
    canceledRowsIds: string[] = [];
    @Input()
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];
    @Input()
    filterFields: FilterTableField[] = [];
    @Input()
    rowsHaveBeenDeleted: Observable<void>; // used in case deleted rows were selected, then we must unselect it
    @Input()
    requestSelectedRows: Observable<void>; // used in case the user want to download selected rows as CSV
    @Input()
    rowColumnId: string; // row column ID, used to send back to the parent container the rows IDs of the selected rows, or the row to edit ID for example

    // rows details
    @Input()
    rowsDetails: { [key: string]: { [key: string]: any }[] } | { [key: string]: { [key: string]: any }[] }[]; // rows details, to display in expanded row
    @Input()
    rowsDetailsColumnsToDisplay: string[] | string[][] = [];
    @Input()
    rowsDetailsColumnsLabels: { [key: string]: string } | { [key: string]: string }[];
    @Input()
    rowsDetailsClickableColumns: { columnName: string; columnId: string; }[] = [];

    // columns options
    @Input()
    sortable = true; // columns can be sorted
    @Input()
    resizable = true; // columns can be resized
    @Input()
    freezable = true; // columns can be freezed / pinned on the left

    // rows actions
    @Input()
    customButton: string; // font awesome icon of the new button
    @Input()
    selectable = false; // row can be selected (to delete / edit multiple rows)
    @Input()
    displayable = false; // row displayable in a modal (associated to output "displayRow")
    @Input()
    editable = false; // row editable in a modal (associated to output "editRow")
    @Input()
    deletable = false; // row deletable (associated to output "deleteRow")
    @Input()
    selectedDate: string; // add a date icon on header which will be used to select a date, current selected date is the one specified here
    @Input()
    selectedDates: string[]; // add a date icon on header which will be used to select date start and date end

    // pagination options
    @Input()
    hidePaginationArrows = false; // hide pagination on the bottom of the table

    // outputs
    @Output()
    sendCustomButtonAction: EventEmitter<string> = new EventEmitter<string>(); // emit row ID of the row for which we must do the custom action
    @Output()
    // tslint:disable-next-line: max-line-length
    sendCustomButtonDetailedRowAction: EventEmitter<{ rowId: string; columnName: string; columnContentId: string; }> = new EventEmitter<{ rowId: string; columnName: string; columnContentId: string; }>(); // emit row ID, detailed column name and the first column content (used as detailed row's row ID) for which the action must be done
    @Output()
    displayRow: EventEmitter<string> = new EventEmitter<string>(); // emit row ID of the row to display
    @Output()
    editRow: EventEmitter<string> = new EventEmitter<string>(); // emit row ID of the row to edit
    @Output()
    deleteRow: EventEmitter<string> = new EventEmitter<string>(); // emit row ID of the row to delete
    @Output()
    editSelectedRows: EventEmitter<string[]> = new EventEmitter<string[]>(); // emit rows IDs of the rows to edit
    @Output()
    deleteSelectedRows: EventEmitter<string[]> = new EventEmitter<string[]>(); // emit rows IDs of the rows to delete
    @Output()
    sendSelectedRows: EventEmitter<string[]> = new EventEmitter<string[]>(); // emit rows IDs of the selected rows
    @Output()
    sendDisplayedRows: EventEmitter<string[]> = new EventEmitter<string[]>(); // emit rows IDs of the displayed rows
    @Output()
    scrollEnd: EventEmitter<void> = new EventEmitter<void>(); // we scrolled to the bottom of the array
    @Output()
    sendSelectedDate: EventEmitter<string> = new EventEmitter<string>(); // emit the selected date, must be defined if "selectedDate" input is set
    @Output()
    sendSelectedDates: EventEmitter<string[]> = new EventEmitter<string[]>(); // emit the selected dates, must be defined if "selectedDates" input is set

    modalRef: BsModalRef;
    tempFrozenColumn = '';
    frozenColumn: string;
    sortedHeader: string;
    sortType: string;
    expandedRowId: string;
    selectedRows: string[] = [];
    selectAllMarginLeft = 0;
    currentPage = 1;
    columnToResizeId: string;

    selectDateForm: FormGroup;

    private subscriptions: Subscription;

    constructor(
        private modalService: BsModalService,
    ) { }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        if (this.rowsHaveBeenDeleted) {
            this.subscriptions.add(
                this.rowsHaveBeenDeleted.subscribe(() => {
                    const allRowsIds = this.rows.map(r => r[this.rowColumnId]);
                    setTimeout(() => {
                        this.selectedRows = this.selectedRows.filter(sr => allRowsIds.includes(sr));
                    }, 200);
                })
            );
        }
        if (this.requestSelectedRows) {
            this.subscriptions.add(
                this.requestSelectedRows.subscribe(() => {
                    this.sendSelectedRows.emit(this.selectedRows);
                })
            );
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    onTableScroll(event) {
        this.currentPage = Math.floor((event.target.scrollTop + event.target.clientHeight) / event.target.clientHeight);
        if (event.target.scrollHeight - 5 < event.target.scrollTop + event.target.clientHeight) {
            this.scrollEnd.emit();
        }
    }

    getColumnValue(col: string, colValue: any): string {
        const formattedColumn = this.formatColumnsValues.find(fc => fc.columns.includes(col));
        if (! formattedColumn) {
            if (colValue === undefined || colValue === null) {
                return '';
            }
            return colValue;
        }
        if (formattedColumn.type === 'Date') {
            return this.formatDate(colValue);
        }
        if (formattedColumn.type === 'boolToCheck') {
            return this.getIconToDisplayFromBoolValue(colValue);
        }

        if (colValue === undefined || colValue === null) {
            return '';
        }
        const value = (formattedColumn.type as { label: string; value: string; iso2?: string; }[])
            .find(t => t.value === colValue || t.iso2 === colValue);

        return value ? value.label : colValue;
    }

    private formatDate(date: Date): string {
        if (! date) {
            return '';
        }
        return (date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString())
            +
            '/'
            +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString())
            +
            '/'
            +
            date.getFullYear()
            +
            ' '
            +
            (date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString())
            +
            ':'
            +
            (date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString())
            +
            ':'
            +
            (date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds().toString());
    }
    private getIconToDisplayFromBoolValue(colValue: boolean | string | number | null | undefined): string {
        if (typeof colValue === 'string') {
            if (colValue.length > 0 && colValue.toLowerCase() !== 'false') {
                return '<span><i class="fas fa-check"></i></span>';
            }
        // boolean true or number !== 0
        } else if (colValue) {
            return '<span><i class="fas fa-check"></i></span>';
        }
        return '<span><i class="fas fa-times"></i></span>';
    }

    getPagesNumbers(): string[] {
        const tableContainerHtmlElement = document.getElementById('custom-table');
        const pages = parseInt((tableContainerHtmlElement.scrollHeight / tableContainerHtmlElement.clientHeight).toFixed(0), 10);
        const numbers: string[] = [];
        if (this.currentPage > pages) {
            this.currentPage = pages;
        }

        if (this.currentPage < 3) {
            for (let i = 0; i < pages && i < 4; i++) {
                numbers.push((i + 1).toString());
            }
        } else if (this.currentPage === pages) {
            for (let i = this.currentPage - 3; i < pages + 1 && numbers.length < 4; i++) {
                numbers.push(i.toString());
            }
        } else {
            for (let i = this.currentPage - 2; i < pages + 1 && numbers.length < 4; i++) {
                numbers.push(i.toString());
            }
        }

        return numbers;
    }

    // change view depending on pagination
    onPaginationClick(pagination: string) {
        const pageInt = parseInt(pagination, 10);
        const tableContainerHtmlElement = document.getElementById('custom-table');
        const pages = parseInt((tableContainerHtmlElement.scrollHeight / tableContainerHtmlElement.clientHeight).toFixed(0), 10);
        if (pageInt <= pages) {
            this.currentPage = pageInt;
            tableContainerHtmlElement.scrollTo({top: tableContainerHtmlElement.clientHeight * this.currentPage - tableContainerHtmlElement.clientHeight});
        }
    }

    onChangeCurrentPage(step: number) {
        if (this.currentPage + step > 0) {
            this.onPaginationClick((this.currentPage + step).toString());
        }
    }

    openModal(template: TemplateRef<any>, type: string): void {
        const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
        if (type === 'pinColumn') {
            this.tempFrozenColumn = '';
        } else if (type === 'selectDate') {
            this.selectDateForm = new FormGroup({
                date: new FormControl((new Date(this.selectedDate)).getTime(), [Validators.required])
            });
        } else if (type === 'selectDates') {
            this.selectDateForm = new FormGroup({
                dateStart: new FormControl((new Date(this.selectedDates[0])).getTime(), [Validators.required]),
                dateEnd: new FormControl((new Date(this.selectedDates[1])).getTime(), [Validators.required])
            });
        }
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.modalRef.hide();
    }

    getColLabel(col: string): string {
        if (this.columnsLabels && this.columnsLabels[col]) {
            return this.columnsLabels[col];
        } else {
            return col;
        }
    }

    getFormattedSelectedDate(date?: string): string {
        if (date) {
            const splittedDate = date.split('-');
            return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
        }
        if (this.selectedDate) {
            const splittedDate = this.selectedDate.split('-');
            return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
        }
        return '';
    }

    onValidFrozenColumn() {
        this.frozenColumn = this.tempFrozenColumn;
        this.tempFrozenColumn = '';
        this.closeModal();
    }
    onValidSelectedDate() {
        if (this.selectDateForm) {
            const date = (new Date(this.selectDateForm.value.date));
            this.sendSelectedDate.emit(`${date.getFullYear()}-${date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1}-${date.getDate() < 10 ? '0'+date.getDate() : date.getDate()}`);
            this.closeModal();
        }
    }
    onValidSelectedDates() {
        if (this.selectDateForm) {
            const dateStart = (new Date(this.selectDateForm.value.dateStart));
            const dateEnd = (new Date(this.selectDateForm.value.dateEnd));
            this.sendSelectedDates.emit([
                `${dateStart.getFullYear()}-${dateStart.getMonth()+1 < 10 ? '0'+(dateStart.getMonth()+1) : dateStart.getMonth()+1}-${dateStart.getDate() < 10 ? '0'+dateStart.getDate() : dateStart.getDate()}`,
                `${dateEnd.getFullYear()}-${dateEnd.getMonth()+1 < 10 ? '0'+(dateEnd.getMonth()+1) : dateEnd.getMonth()+1}-${dateEnd.getDate() < 10 ? '0'+dateEnd.getDate() : dateEnd.getDate()}`
            ]);
            this.closeModal();
        }
    }

    getColumnsToDisplay(): string[] {
        if (this.columnsToDisplay) {
            return this.columnsToDisplay.filter(c => c !== this.frozenColumn);
        }
        return [];
    }

    rowsIsSetAndFilled(): boolean {
        return this.rows ? (this.rows.length > 0 ? true : false) : false;
    }

    getFilteredSortedRows(): { [key: string]: any }[] {
        let filteredSortedRows = this.rows ? this.rows.slice() : [];
        if (this.filterFields) {
            const filters = this.filterFields.filter(f => f.value !== null && f.value !== undefined);

            // use filters
            filters.forEach(filter => {
                filteredSortedRows = filteredSortedRows.filter(r => {
                    if (
                        r[filter.id.replace('__min', '').replace('__max', '')] === null ||
                        r[filter.id.replace('__min', '').replace('__max', '')] === undefined
                    ) {
                        return false;
                    }

                    // date start OR value min
                    if (filter.id.endsWith('__min')) {
                        const id = filter.id.substring(0, filter.id.indexOf('__min'));
                        return r[id] >= filter.value;
                    }
                    // date end OR value max
                    if (filter.id.endsWith('__max')) {
                        const id = filter.id.substring(0, filter.id.indexOf('__max'));
                        return r[id] <= filter.value;
                    }

                    // item in list
                    if (Array.isArray(filter.value)) {
                        for (let i = 0; i < filter.value.length; i++) {
                            if (r[filter.id].toString().toLowerCase().includes(filter.value[i].toString().toLowerCase())) {
                                return true;
                            }
                        }
                    }

                    // item contains or equal
                    return r[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase());
                });
            });
        }

        // nothing to sort
        if (! this.sortedHeader || ! this.sortType) {
            this.sendDisplayedRows.emit(filteredSortedRows.map(r => r[this.rowColumnId]));
            return filteredSortedRows;
        }
        filteredSortedRows.sort((a, b) => {
            if (this.sortType === SORTABLE_OPTIONS.ASCENDING) {
                return this.sortRows(a[this.sortedHeader], b[this.sortedHeader]);
            }
            return this.sortRows(b[this.sortedHeader], a[this.sortedHeader]);
        });
        this.sendDisplayedRows.emit(filteredSortedRows.map(r => r[this.rowColumnId]));
        return filteredSortedRows;
    }

    private sortRows(a: any, b: any): number {
        if (a === null || a === undefined) {
            return -1;
        }
        if (b === null || b === undefined) {
            return 1;
        }
        if (! isNaN(a) && ! isNaN(b)) {
            return a - b;
        }
        if (a.toString() <= b.toString()) {
            return -1;
        } else {
            return 1;
        }
    }

    onCheckRow(rowId: string) {
        const index = this.selectedRows.findIndex(r => r === rowId);
        if (index > -1) {
            this.selectedRows.splice(index, 1);
        } else {
            this.selectedRows.push(rowId);
        }
    }
    onCheckAllRows() {
        if (this.selectedRows.length !== this.rows.length) {
            this.selectedRows = this.rows.map(r => r[this.rowColumnId]);
        } else {
            this.selectedRows = [];
        }
    }

    multipleDetailsColumnsArrays(): boolean {
        return Array.isArray(this.rowsDetails);
    }

    onHeaderClick(header: string) {
        if (this.sortable) {
            if (this.sortedHeader === header) {
                switch (this.sortType) {
                    case SORTABLE_OPTIONS.ASCENDING:
                        this.sortType = SORTABLE_OPTIONS.DESCENDING;
                        break;
                    case SORTABLE_OPTIONS.DESCENDING:
                        this.sortType = null;
                        this.sortedHeader = null;
                        break;
                    default:
                        this.sortType = SORTABLE_OPTIONS.ASCENDING;
                        break;
                }
            } else {
                this.sortedHeader = header;
                this.sortType = SORTABLE_OPTIONS.ASCENDING;
            }
        }
    }

    getEmptyDetailedRowsToSet(index: number): number[] {
        if (this.multipleDetailsColumnsArrays()) {
            const remaining: number[] = [];
            const row = this.rows.find(r => r[this.rowColumnId] === this.expandedRowId);
            let currentRowsDetails = this.rowsDetails[index][row[this.rowColumnId]];
            const currentTableMaxRows = currentRowsDetails.length;
            for (let i = 0; i < (this.rowsDetails as { [key: string]: { [key: string]: any }[] }[]).length; i++) {
                currentRowsDetails = (this.rowsDetails as { [key: string]: { [key: string]: any }[] }[])[i][row[this.rowColumnId]];
                if (i !== index && currentTableMaxRows < currentRowsDetails.length) {
                    for (let j = remaining.length; j < currentRowsDetails.length; j++) {
                        remaining.push(j + 1);
                    }
                }
            }
            return remaining;
        }
        return [];
    }

    onExpandRow(rowId: string) {
        if (this.expandedRowId !== rowId) {
            this.expandedRowId = rowId;
        } else {
            this.expandedRowId = null;
        }
    }

    isSubheaderClickable(header: string): boolean {
        return this.rowsDetailsClickableColumns.findIndex(c => c.columnName === header) >= 0;
    }

    onMouseDown(headerId: string) {
        this.columnToResizeId = headerId;
    }
    @HostListener('mousemove', ['$event'])
    onMouseMove(e) {
        if (this.resizable && this.columnToResizeId) {
            const headerHtmlElement = document.getElementById(this.columnToResizeId);
            const newPositionX = headerHtmlElement.offsetWidth + (
                e.clientX -
                headerHtmlElement.getBoundingClientRect().right
            );
            if (newPositionX >= 100 && newPositionX <= 500) {
                headerHtmlElement.style.width = `${newPositionX}px`;
                for (let i = 0; i < this.rows.length; i++) {
                    const bodyHtmlElement = document.getElementById(`${this.columnToResizeId}_${i}`);
                    bodyHtmlElement.style.width = `${newPositionX}px`;
                }
            }
        }
    }
    @HostListener('mouseup', ['$event'])
    onMouseUp(e) {
        if (this.resizable && this.columnToResizeId) {
            this.columnToResizeId = null;
        }
    }

    getSelectAllMarginLeft(): number {
        if (this.selectable && this.selectAllMarginLeft === 0) {
            const selectAllHtmlElement = document.getElementById('select-all-bullet');
            const selectHtmlElement = document.getElementById('table-select-bullet-container');

            const tableBulletLeftPosition = selectHtmlElement.getBoundingClientRect().left;
            const selectAllBulletLeftPosition = selectAllHtmlElement.getBoundingClientRect().left;

            this.selectAllMarginLeft = tableBulletLeftPosition - selectAllBulletLeftPosition;
        }
        return this.selectAllMarginLeft;
    }

    getSelectedRowsNumber(): number {
        if (! this.selectedRows || this.selectedRows.length === 0) {
            return 0;
        }
        const rowsIds = this.rows.map(r => r[this.rowColumnId]);
        let i = 0;
        while (i < this.selectedRows.length) {
            if (rowsIds.includes(this.selectedRows[i])) {
                i += 1;
            } else {
                this.selectedRows.splice(i, 1);
            }
        }
        return this.selectedRows.length;
    }

    // All outputs functions
    onCustomButtonClicked(rowId: string) {
        this.sendCustomButtonAction.emit(rowId);
    }
    onCustomButtonDetailedRowClicked(rowId: string, columnName: string, currentRowDetail: { [key: string]: any } ) {
        const rightClickableCol = this.rowsDetailsClickableColumns.find(c => c.columnName === columnName);
        if (rightClickableCol) {
            this.sendCustomButtonDetailedRowAction.emit({ rowId, columnName, columnContentId: currentRowDetail[rightClickableCol.columnId] });
        }
    }
    onDisplayRowClicked(rowId: string) {
        this.displayRow.emit(rowId);
    }
    onEditRowClicked(rowId: string) {
        if (! this.canceledRowsIds.includes(rowId)) {
            this.editRow.emit(rowId);
        }
    }
    onDeleteRowClicked(rowId: string) {
        this.deleteRow.emit(rowId);
    }
    onSendSelectedRows() {
        this.sendSelectedRows.emit(this.selectedRows);
    }
    onEditSelectedRows() {
        this.editSelectedRows.emit(this.selectedRows);
    }
    onDeleteSelectedRows() {
        this.deleteSelectedRows.emit(this.selectedRows);
    }
}
