import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {PLAN_ENUM, User} from '../../../models/User';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-key-integration',
  templateUrl: './key-integration.component.html',
  styleUrls: ['./key-integration.component.scss']
})
export class KeyIntegrationComponent implements OnInit {

  refresh_token: string;
  display_refresh_token: string;
  id_token: string;
  display_id_token: string;
  client_id: string;
  display_client_id: string;
  user: User;
  lang: string;
  allow_token: boolean;
  isLoading: boolean;
  constructor(private authService: AuthService,
              private userService: UserService,
              private translate: TranslateService) {
    this.client_id = environment.userPoolWebClientId;
    this.display_client_id = this.client_id.substr(0, 20) + '...';
  }

  @ViewChild('pop_refresh_token', {static: false}) pop_refresh_token;
  @ViewChild('pop_id_token', {static: false}) pop_id_token;
  @ViewChild('pop_client_id', {static: false}) pop_client_id;
  async ngOnInit() {
    await this.initUserPrefs();
    await this.refreshTokens();
    this.translate.get('messages').subscribe(messages => {
      this.lang = this.user.lang ? this.user.lang.substr(0, 2) : messages.current_lang;
    });

  }
  async refreshTokens() {
    if (this.allow_token) {
      this.isLoading = true;
      await this.authService.getCognitoUser().then(user => {
        this.refresh_token = user.getRefreshToken().getToken();
        this.id_token = user.getIdToken().getJwtToken();
      });
      this.display_refresh_token = this.refresh_token.substr(0, 20) + '...';
      this.display_id_token = this.id_token.substr(this.id_token.length - 20, this.id_token.length) + '...';
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }
  private async initUserPrefs() {
    let user = this.userService.getUser();
    if (!user) {
      user = await this.authService.reconnect();
    }
    this.user = user;
    this.allow_token = user.plan === PLAN_ENUM.COSMOS || user.plan === PLAN_ENUM.ROCKET || user.plan === PLAN_ENUM.INFINITE;
  }

  async copyToClipboard(token, type: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (token));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    if (type === 'refresh_token') {
      this.pop_refresh_token.show();
    } else {
      this.pop_client_id.show();
    }
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.pop_refresh_token.hide();
    this.pop_client_id.hide();
  }

}
