import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Child, ChildWithConsumptions} from '../../models/User';
import {RequestChild, RequestPutChild} from '../connected/multi-users/interfaces/request-child';
import {ResponseManageChild} from '../connected/multi-users/interfaces/response-manage-child';

@Injectable({
  providedIn: 'root'
})
export class ManageChildService {

  manageChildUrl: string;

  constructor(private http: HttpClient) {
    this.manageChildUrl = `https://api.${environment.baseUrl}/v1/user/manageChildCredits`;
  }

  async getChildren(): Promise<Array<ChildWithConsumptions>> {
    return this.http.get<Array<ChildWithConsumptions>>(this.manageChildUrl).toPromise();
  }

  async createChild(child: RequestChild): Promise<ResponseManageChild> {
    return this.http.post<ResponseManageChild>(this.manageChildUrl, child).toPromise();
  }

  async updateChild(id: string, childUpdate: RequestPutChild): Promise<ResponseManageChild> {
    return this.http.put<ResponseManageChild>(this.manageChildUrl + '/' + id, childUpdate).toPromise();
  }

  async deleteChild(id: string): Promise<ResponseManageChild> {
    return this.http.delete<ResponseManageChild>(this.manageChildUrl + '/' + id).toPromise();
  }

}
