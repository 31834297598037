import { Country } from './interfaces/country';

export class CountryHelper {
    static apiResponseToCountry(apiResponse: any): Country {
        return {
            iso2: this.undefinedToNull(apiResponse.iso2),
            iso3: this.undefinedToNull(apiResponse.iso3),
            reporter: this.undefinedToNull(apiResponse.reporter),
            label_fr: this.undefinedToNull(apiResponse.label_fr),
            label_en: this.undefinedToNull(apiResponse.label_en),
            label_es: this.undefinedToNull(apiResponse.label_es),
            label_cn: this.undefinedToNull(apiResponse.label_cn),
            label_ru: this.undefinedToNull(apiResponse.label_ru),
            label_ar: this.undefinedToNull(apiResponse.label_ar),
            label_pt: this.undefinedToNull(apiResponse.label_pt),
            label_hi: this.undefinedToNull(apiResponse.label_hi),
            global: {
                iso3: this.undefinedToNull(apiResponse.iso3),
                capital: this.undefinedToNull(apiResponse.capital),
                regionName: this.undefinedToNull(apiResponse.regionName),
                googleMapUrl: this.undefinedToNull(apiResponse.googleMapUrl),
                flagUrl: this.undefinedToNull(apiResponse.flagUrl),
                officialLanguages: this.undefinedToNull(apiResponse.officialLanguages),
                businessLanguages: this.undefinedToNull(apiResponse.businessLanguages),
                population: this.undefinedToNull(apiResponse.population),
                currency: this.undefinedToNull(apiResponse.currency),
                currencyCaracter: this.undefinedToNull(apiResponse.currencyCaracter),
            },
            commerce: {
                goodsExport: this.undefinedToNull(apiResponse.goodsExport),
                goodsImport: this.undefinedToNull(apiResponse.goodsImport),
                cifThresholdDuty: this.undefinedToNull(apiResponse.cifThresholdDuty),
                cifThresholdVat: this.undefinedToNull(apiResponse.cifThresholdVat),
                vatThresholdEu: this.undefinedToNull(apiResponse.vatThresholdEu),
                revenueThresholdVat: this.undefinedToNull(apiResponse.revenueThresholdVat),
            },
            internet: {
                internetUserPart: this.undefinedToNull(apiResponse.internetUserPart),
                internetUsers: this.undefinedToNull(apiResponse.internetUsers),
                internetUsersEvolution: this.undefinedToNull(apiResponse.internetUsersEvolution),
                webTraficPartForComputer: this.undefinedToNull(apiResponse.webTraficPartForComputer),
                webTraficPartForMobilePhone: this.undefinedToNull(apiResponse.webTraficPartForMobilePhone),
                internetOnceByDayUsersPart: this.undefinedToNull(apiResponse.internetOnceByDayUsersPart),
                videoStreamingOnceByDayUsersPart: this.undefinedToNull(apiResponse.videoStreamingOnceByDayUsersPart),
            },
            market: {
                bankAccountPartInPopulation: this.undefinedToNull(apiResponse.bankAccountPartInPopulation),
                debitCardPartInPopulation: this.undefinedToNull(apiResponse.debitCardPartInPopulation),
                ecommerceConsumers: this.undefinedToNull(apiResponse.ecommerceConsumers),
                ecommerceMarketValueAnnual: this.undefinedToNull(apiResponse.ecommerceMarketValueAnnual),
                ecommerceMarketEvolution: this.undefinedToNull(apiResponse.ecommerceMarketEvolution),
                digitalPaymentPopulationPart: this.undefinedToNull(apiResponse.digitalPaymentPopulationPart),
                eshopperEvolution: this.undefinedToNull(apiResponse.eshopperEvolution),
                eshopperPart: this.undefinedToNull(apiResponse.eshopperPart),
                fashionBeautyEshopsGrowth: this.undefinedToNull(apiResponse.fashionBeautyEshopsGrowth),
                fashionBeautyEshopsRevenue: this.undefinedToNull(apiResponse.fashionBeautyEshopsRevenue),
                foodEshopsGrowth: this.undefinedToNull(apiResponse.foodEshopsGrowth),
                foodEshopsRevenue: this.undefinedToNull(apiResponse.foodEshopsRevenue),
                highTechEshopsGrowth: this.undefinedToNull(apiResponse.highTechEshopsGrowth),
                highTechEshopsRevenue: this.undefinedToNull(apiResponse.highTechEshopsRevenue),
                homeEshopsGrowth: this.undefinedToNull(apiResponse.homeEshopsGrowth),
                homeEshopsRevenue: this.undefinedToNull(apiResponse.homeEshopsRevenue),
                musicEshopsGrowth: this.undefinedToNull(apiResponse.musicEshopsGrowth),
                musicEshopsRevenue: this.undefinedToNull(apiResponse.musicEshopsRevenue),
                toysEshopsGrowth: this.undefinedToNull(apiResponse.toysEshopsGrowth),
                toysEshopsRevenue: this.undefinedToNull(apiResponse.toysEshopsRevenue),
                travelEshopsGrowth: this.undefinedToNull(apiResponse.travelEshopsGrowth),
                travelEshopsRevenue: this.undefinedToNull(apiResponse.travelEshopsRevenue),
                videoGamesEshopsGrowth: this.undefinedToNull(apiResponse.videoGamesEshopsGrowth),
                videoGamesEshopsRevenue: this.undefinedToNull(apiResponse.videoGamesEshopsRevenue),
            },
            logistic: {
                parcelExportNumber: this.undefinedToNull(apiResponse.parcelExportNumber),
                parcelImportNumber: this.undefinedToNull(apiResponse.parcelImportNumber),
            },
        };
    }

    static undefinedToNull(value: any): any | null {
        if (value === undefined) {
            return null;
        } else {
            return value;
        }
    }

    static countryToPlainObject(country: Country): Object {
        return {
            iso2: country.iso2,
            is3: country.iso3,
            reporter: country.reporter,
            label_fr: country.label_fr,
            label_en: country.label_en,
            label_es: country.label_es,
            label_cn: country.label_cn,
            label_ru: country.label_ru,
            label_ar: country.label_ar,
            label_pt: country.label_pt,
            label_hi: country.label_hi,
            capital: country.global.capital,
            regionName: country.global.regionName,
            googleMapUrl: country.global.googleMapUrl,
            flagUrl: country.global.flagUrl,
            officialLanguages: country.global.officialLanguages,
            businessLanguages: country.global.businessLanguages,
            population: country.global.population,
            currency: country.global.currency,
            currencyCaracter: country.global.currencyCaracter,
            goodsExport: country.commerce.goodsExport,
            goodsImport: country.commerce.goodsImport,
            cifThresholdDuty: country.commerce.cifThresholdDuty,
            cifThresholdVat: country.commerce.cifThresholdVat,
            vatThresholdEu: country.commerce.vatThresholdEu,
            revenueThresholdVat: country.commerce.revenueThresholdVat,
            internetUserPart: country.internet.internetUserPart,
            internetUsers: country.internet.internetUsers,
            internetUsersEvolution: country.internet.internetUsersEvolution,
            webTraficPartForComputer: country.internet.webTraficPartForComputer,
            webTraficPartForMobilePhone: country.internet.webTraficPartForMobilePhone,
            internetOnceByDayUsersPart: country.internet.internetOnceByDayUsersPart,
            videoStreamingOnceByDayUsersPart: country.internet.videoStreamingOnceByDayUsersPart,
            parcelExportNumber: country.logistic.parcelExportNumber,
            parcelImportNumber: country.logistic.parcelImportNumber,
            bankAccountPartInPopulation: country.market.bankAccountPartInPopulation,
            debitCardPartInPopulation: country.market.debitCardPartInPopulation,
            ecommerceConsumers: country.market.ecommerceConsumers,
            ecommerceMarketValueAnnual: country.market.ecommerceMarketValueAnnual,
            ecommerceMarketEvolution: country.market.ecommerceMarketEvolution,
            digitalPaymentPopulationPart: country.market.digitalPaymentPopulationPart,
            eshopperEvolution: country.market.eshopperEvolution,
            eshopperPart: country.market.eshopperPart,
            fashionBeautyEshopsGrowth: country.market.fashionBeautyEshopsGrowth,
            fashionBeautyEshopsRevenue: country.market.fashionBeautyEshopsRevenue,
            foodEshopsGrowth: country.market.foodEshopsGrowth,
            foodEshopsRevenue: country.market.foodEshopsRevenue,
            highTechEshopsGrowth: country.market.highTechEshopsGrowth,
            highTechEshopsRevenue: country.market.highTechEshopsRevenue,
            homeEshopsGrowth: country.market.homeEshopsGrowth,
            homeEshopsRevenue: country.market.homeEshopsRevenue,
            musicEshopsGrowth: country.market.musicEshopsGrowth,
            musicEshopsRevenue: country.market.musicEshopsRevenue,
            toysEshopsGrowth: country.market.toysEshopsGrowth,
            toysEshopsRevenue: country.market.toysEshopsRevenue,
            travelEshopsGrowth: country.market.travelEshopsGrowth,
            travelEshopsRevenue: country.market.travelEshopsRevenue,
            videoGamesEshopsGrowth: country.market.videoGamesEshopsGrowth,
            videoGamesEshopsRevenue: country.market.videoGamesEshopsRevenue,
        };
    }

    /** Create Country with empty value: "" for string, 0 for number */
    static createCountry(): Country {
        return {
            iso2: '',
            iso3: '',
            reporter: '',
            label_fr: '',
            label_en: '',
            label_es: '',
            label_cn: '',
            label_ru: '',
            label_ar: '',
            label_pt: '',
            label_hi: '',
            global: {
                iso3: '',
                capital: '',
                regionName: '',
                googleMapUrl: '',
                flagUrl: '',
                officialLanguages: '',
                businessLanguages: '',
                population: 0,
                currency: '',
                currencyCaracter: '',
            },
            commerce: {
                goodsExport: 0,
                goodsImport: 0,
                cifThresholdDuty: 0,
                cifThresholdVat: 0,
                vatThresholdEu: 0,
                revenueThresholdVat: 0,
            },
            internet: {
                internetUserPart: '',
                internetUsers: 0,
                internetUsersEvolution: '',
                webTraficPartForComputer: '',
                webTraficPartForMobilePhone: '',
                internetOnceByDayUsersPart: '',
                videoStreamingOnceByDayUsersPart: '',
            },
            market: {
                bankAccountPartInPopulation: '',
                debitCardPartInPopulation: '',
                ecommerceConsumers: 0,
                ecommerceMarketValueAnnual: 0,
                ecommerceMarketEvolution: '',
                digitalPaymentPopulationPart: '',
                eshopperEvolution: '',
                eshopperPart: '',
                fashionBeautyEshopsGrowth: '',
                fashionBeautyEshopsRevenue: 0,
                foodEshopsGrowth: '',
                foodEshopsRevenue: 0,
                highTechEshopsGrowth: '',
                highTechEshopsRevenue: 0,
                homeEshopsGrowth: '',
                homeEshopsRevenue: 0,
                musicEshopsGrowth: '',
                musicEshopsRevenue: 0,
                toysEshopsGrowth: '',
                toysEshopsRevenue: 0,
                travelEshopsGrowth: '',
                travelEshopsRevenue: 0,
                videoGamesEshopsGrowth: '',
                videoGamesEshopsRevenue: 0,
            },
            logistic: {
                parcelExportNumber: 0,
                parcelImportNumber: 0,
            },
        };
    }

    static resetCountry(country: Country): void {
        country.iso2 = null;
        country.iso3 = null;
        country.reporter = null;
        country.label_fr = null;
        country.label_en = null;
        country.label_es = null;
        country.label_cn = null;
        country.label_ru = null;
        country.label_ar = null;
        country.label_pt = null;
        country.label_hi = null;
        country.global.iso3 = null;
        country.global.capital = null;
        country.global.regionName = null;
        country.global.googleMapUrl = null;
        country.global.flagUrl = null;
        country.global.officialLanguages = null;
        country.global.businessLanguages = null;
        country.global.population = null;
        country.global.currency = null;
        country.global.currencyCaracter = null;
        country.commerce.goodsExport = null;
        country.commerce.goodsImport = null;
        country.commerce.cifThresholdDuty = null;
        country.commerce.cifThresholdVat = null;
        country.commerce.vatThresholdEu = null;
        country.commerce.revenueThresholdVat = null;
        country.internet.internetUserPart = null;
        country.internet.internetUsers = null;
        country.internet.internetUsersEvolution = null;
        country.internet.webTraficPartForComputer = null;
        country.internet.webTraficPartForMobilePhone = null;
        country.internet.internetOnceByDayUsersPart = null;
        country.internet.videoStreamingOnceByDayUsersPart = null;
        country.market.bankAccountPartInPopulation = null;
        country.market.debitCardPartInPopulation = null;
        country.market.ecommerceConsumers = null;
        country.market.ecommerceMarketValueAnnual = null;
        country.market.ecommerceMarketEvolution = null;
        country.market.digitalPaymentPopulationPart = null;
        country.market.eshopperEvolution = null;
        country.market.eshopperPart = null;
        country.market.fashionBeautyEshopsGrowth = null;
        country.market.fashionBeautyEshopsRevenue = null;
        country.market.foodEshopsGrowth = null;
        country.market.foodEshopsRevenue = null;
        country.market.highTechEshopsGrowth = null;
        country.market.highTechEshopsRevenue = null;
        country.market.homeEshopsGrowth = null;
        country.market.homeEshopsRevenue = null;
        country.market.musicEshopsGrowth = null;
        country.market.musicEshopsRevenue = null;
        country.market.toysEshopsGrowth = null;
        country.market.toysEshopsRevenue = null;
        country.market.travelEshopsGrowth = null;
        country.market.travelEshopsRevenue = null;
        country.market.videoGamesEshopsGrowth = null;
        country.market.videoGamesEshopsRevenue = null;
        country.logistic.parcelExportNumber = null;
        country.logistic.parcelImportNumber = null;
    }
}
