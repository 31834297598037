import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {NavigationExtras, Router} from '@angular/router';
import {SharedData} from '../../Common/SharedData';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../components/message/message.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage: string;
  resetForm: FormGroup;
  password_reset: string;
  formIsInvalid = false;
  isWhiteLabel = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private data: SharedData,
    private translate: TranslateService,
    private _notification: MessageService
  ) {
    // retrieve URL path
    // if url contains '/passwordForgotten' then it's a white label
    this.isWhiteLabel = this.router.url.includes('/passwordForgotten');
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  async ngOnInit() {
    if (this.authService.isLoggedIn) {
      await this.router.navigate(['/dashboard']);
    }
    this.translate.get('messages').subscribe(messages => {
      this.password_reset = messages.password_reset;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.password_reset = messages.password_reset;
    });
  }

  async reset() {
    if (this.resetForm.invalid) {
        this.formIsInvalid = true;
        return;
    }
    await this.authService.resetPassword(this.resetForm.value.email, this.isWhiteLabel).then(user => {
      const paramsExtra: NavigationExtras = {queryParams: {email : this.resetForm.value.email}};
      this.router.navigate([this.isWhiteLabel ? '/resetPassword' : '/resetConfirm'], paramsExtra).then(() => {
        this.data.successMessage = this.password_reset;
      });
    }).catch(error => {
        this._notification.displayMessage(error.message, 'danger');
    });
  }
}
