import {HttpParams} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../components/message/message.service';
import {ToolsService} from '../../../services/tools.service';

@Component({
    selector: 'app-convertor',
    templateUrl: './convertor.component.html',
    styleUrls: ['./convertor.component.scss']
})
export class ConvertorComponent implements OnInit {

    currencies: Array<{}>;

    readonly DEFAULT_CURRENCY_FROM = 'EUR';
    readonly DEFAULT_CURRENCY_TO = 'USD';

    currencyForm: FormGroup;
    amountTo: number;
    amountFrom: number;
    toCurrency: string;
    fromCurrency: string;

    @Input() additionalClasses: string;

    constructor(private _notification: MessageService,
                private translate: TranslateService,
                private toolsService: ToolsService) {
        this.toCurrency = this.DEFAULT_CURRENCY_TO;
        this.fromCurrency = this.DEFAULT_CURRENCY_FROM;
        this.currencyForm = new FormGroup({
            inputFrom: new FormControl(this.amountFrom, [Validators.required]),
            selectFromCurrency: new FormControl(this.fromCurrency, [Validators.required]),
            inputTo: new FormControl({value: this.amountTo, disabled: true}, []),
            selectToCurrency: new FormControl(this.toCurrency, [Validators.required])
        });
    }

    ngOnInit() {
        this.initTranslation();
    }

    async initTranslation() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currencies = event.translations['toolBox'].convertor.currencies;
        });
        await this.translate.get('toolBox').toPromise().then(toolbox => {
            this.currencies = toolbox.convertor.currencies;
        });
    }

    switch() {
        const from = this.fromCurrency;
        const to = this.toCurrency;
        this.fromCurrency = to;
        this.toCurrency = from;
    }

    async changeCurrency(event, origin: string) {
        if (event) {
            if (this.currencyForm.valid) {
                const formValue = this.currencyForm.value;
                let amount = 0;
                let from: string;
                let to: string;
                switch (origin) {
                    case 'FROM':
                        amount = formValue.inputFrom;
                        from = formValue.selectFromCurrency;
                        to = formValue.selectToCurrency;
                        break;
                    case 'TO':
                        amount = formValue.inputTo;
                        from = formValue.selectToCurrency;
                        to = formValue.selectFromCurrency;
                        break;
                }
                if (amount > 0) {
                    const responseCurrency = await this.callCurrencyAPI(amount, from, to);
                    switch (origin) {
                        case 'FROM':
                            this.amountTo = responseCurrency.result;
                            break;
                        case 'TO':
                            this.amountFrom = responseCurrency.result;
                            break;
                    }
                }
            }
        }
    }

    async submit() {
        if (this.currencyForm.valid) {
            const formValue = this.currencyForm.value;
            const response = await this.callCurrencyAPI(formValue.inputFrom, formValue.selectFromCurrency, formValue.selectToCurrency);
            this.amountTo = response.result;
        }
    }

    async callCurrencyAPI(amount: number, fromCurrency: string, toCurrency: string) {
        const params = new HttpParams().set('fromCurrency', fromCurrency).set('toCurrency', toCurrency).set('amount', amount.toString());
        return this.toolsService.convert(params)
            .then(data => data)
            .catch(e => {
                return Promise.reject();
            });
    }

}
