import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {TransportEnum} from '../shipment.component';
import {Utils} from '../../../utils/utils';
import {pgSelectComponent} from '../../../components/select/select.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {User} from '../../../../models/User';
import {TransitEnum} from '../../../../models/Shipment';
const districts = require( '../../../../../assets/data/districtsGroupByIsoCountry.json');
const us_states = require( '../../../../../assets/data/US_states.json');
const transports_types = require( '../../../../../assets/data/transports_types.json');

@Component({
  selector: 'app-shipment-info',
  templateUrl: './shipment-info.component.html',
  styleUrls: ['./shipment-info.component.scss']
})
export class ShipmentInfoComponent implements OnInit {
  @Output() callNextStep: EventEmitter<FormGroup> = new EventEmitter();
  @Input()
  infoForm: FormGroup;
  @Input()
  user: User;
  transport_type = TransportEnum;
  transit_enum = TransitEnum;
  pallet_types = transports_types.PALLET;
  container_types = transports_types.CONTAINER;
  @ViewChild('fromDistrictComponent', {static: false}) fromDistrictComponent: pgSelectComponent;
  @ViewChild('toDistrictComponent', {static: false}) toDistrictComponent: pgSelectComponent;

  @ViewChild('fromStateComponent', {static: false}) fromStateComponent: pgSelectComponent;
  @ViewChild('toStateComponent', {static: false}) toStateComponent: pgSelectComponent;
  @Input()
  fromDistricts: Array<{}>;
  toDistricts: Array<{}>;
  countries: Array<{value: string, label: string}>;
  us_states: Array<{}>;
  currency: Array<{}>;
  shipment_options = [
    {'value': 'picking', 'label': 'picking'},
    {'value': 'deposit', 'label': 'deposit'}
  ];
  delivery_options = [
    {'value': 'delivery1', 'label': 'delivery1'},
    {'value': 'delivery2', 'label': 'delivery2'}
  ];
  weight_units: Array<{}>;
  other_units: Array<{}>;
  constructor(private translate: TranslateService) {
    this.us_states = us_states;
    this.currency = [];
    this.fromDistricts = [];
    this.toDistricts = [];
    this.countries = [];
    this.weight_units = [];
    this.other_units = [];
  }

  ngOnInit() {
    this.translate.get('countries').subscribe((countries) => {
      this.countries = countries.sort(Utils.compareByLabel);
    });
    this.translate.get('currency_name').subscribe(currencies => {
      this.currency = currencies;
    });
    this.translate.get('shipment').subscribe(shipment => {
      this.shipment_options = shipment.info.form.shipment_options;
      this.delivery_options = shipment.info.form.delivery_options;
    });

    this.translate.get('unitTypes').subscribe(unit_types => {
      this.weight_units = unit_types.weight;
      this.other_units = unit_types.other;
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.shipment_options = event.translations.shipment.info.form.shipment_options;
      this.delivery_options = event.translations.shipment.info.form.delivery_options;
      this.currency = event.translations.currency_name;
      this.countries = event.translations.countries.sort(Utils.compareByLabel);
      this.weight_units = event.translations.unit_types ? event.translations.unit_types.weight : this.weight_units;
      this.other_units = event.translations.unit_types ? event.translations.unit_types.other : this.other_units;
    });
    this.infoForm = new FormGroup({
      sender: new FormGroup({
        pro: new FormControl('true', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        district: new FormControl('', [Validators.required]),
      }),
      receiver: new FormGroup({
        pro: new FormControl('true', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        district: new FormControl('', [Validators.required]),
      }),
      transports: new FormArray([]),
      transit: new FormControl(TransitEnum.SEA, [Validators.required]),
      departure_date: new FormControl('', [Validators.required]),
      shipment_option: new FormControl('', [Validators.required]),
      delivery_option: new FormControl('', [Validators.required])
    });
    this.addShipment();
    this.subscribeCountriesChange();
  }

  subscribeCountriesChange() {
    const from_district = this.infoForm.get('sender.district');
    const to_district = this.infoForm.get('receiver.district');
    const from_country = this.infoForm.get('sender.country');
    const to_country = this.infoForm.get('receiver.country');
    this.fromDistricts = this.getDistricts(from_country.value);
    this.toDistricts = this.getDistricts(to_country.value);
    from_country.valueChanges.subscribe(fromCountryOption => {
      const fromCountry = fromCountryOption.value;
      from_district.setValue('');
      this.fromDistrictComponent.clearSelect();
      this.fromDistricts = fromCountry === 'USA' ? us_states : this.getDistricts(fromCountry);
      if (this.fromDistricts.length > 0) {
        from_district.setValidators([Validators.required]);
        this.fromDistrictComponent.setDisabledState(false);
      } else {
        from_district.setValidators(null);
        from_district.setErrors(null);
        this.fromDistrictComponent.setDisabledState(true);
      }
      from_district.updateValueAndValidity();
    });
    to_country.valueChanges.subscribe(toCountryOption => {
      const toCountry = toCountryOption.value;
      to_district.setValue('');
      this.toDistrictComponent.clearSelect();
      this.toDistricts = toCountry === 'USA' ? us_states : this.getDistricts(toCountry);
      if (this.toDistricts.length > 0) {
        to_district.setValidators([Validators.required]);
        this.toDistrictComponent.setDisabledState(false);
      } else {
        to_district.setValidators(null);
        to_district.setErrors(null);
        this.toDistrictComponent.setDisabledState(true);
      }
      to_district.updateValueAndValidity();
    });
  }
  getDistricts(country: string) {
    return country && country !== 'USA' && districts[country] ?
        (districts[country].length < 500 ?
                districts[country].sort(Utils.compareByLabel) : districts[country].slice(0, 500).sort(Utils.compareByLabel)
        ) : [];
  }
  buildTransportForm = () => {
    const transportForm: FormGroup = new FormGroup({
      products: new FormArray([]),
      infos: new FormGroup({
        type: new FormControl(TransportEnum.PARCEL),
        dimensions: new FormGroup({
          width: new FormControl(''),
          length: new FormControl(''),
          height: new FormControl(''),
        }),
        incoterm: new FormControl(''),
        weight: new FormControl('', [Validators.required]),
        weight_unit: new FormControl('', [Validators.required]),
        unit: new FormControl(),
        unit_type: new FormControl(),
        model: new FormControl(''),
        quantity: new FormControl('', [Validators.required])
      }),
    });
    this.addProduct(transportForm.controls.products as FormArray);
    transportForm.get('infos.type').valueChanges.subscribe(type => {
      this.updateTransportValueAndValidity(transportForm, type);
    });
    this.updateTransportValueAndValidity(transportForm, TransportEnum.PARCEL);
    return transportForm;
  }
  updateTransportValueAndValidity(transportForm: FormGroup, type: string) {
    const dimensions = transportForm.get('infos.dimensions');
    const incoterm = transportForm.get('infos.incoterm');
    const weight = transportForm.get('infos.weight');
    const weight_unit = transportForm.get('infos.weight_unit');
    const model = transportForm.get('infos.model');
    switch (type) {
      case TransportEnum.PARCEL:
        dimensions.setValidators([Validators.required]);
        incoterm.setValidators(null);
        weight.setValidators(null);
        weight_unit.setValidators(null);
        model.setValidators(null);
        break;
      case TransportEnum.PALLET:
        dimensions.setValidators(null);
        model.setValidators(null);
        break;
      case TransportEnum.CONTAINER:
        dimensions.setValidators(null);
        model.setValidators([Validators.required]);
        incoterm.setValidators([Validators.required]);
    }
    dimensions.updateValueAndValidity();
    incoterm.updateValueAndValidity();
    weight.updateValueAndValidity();
    model.updateValueAndValidity();
  }

  buildProductForm = () => {
    return new FormGroup({
      identification: new FormGroup({
        value: new FormControl('', [Validators.required]),
        type: new FormControl('FRONT', [Validators.required]),
      }),
      quantity: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      currency_price: new FormControl('', [Validators.required]),
    });
  }

  configFormSubmit() {
    this.callNextStep.emit(this.infoForm);
  }

  get f() { return this.infoForm.controls; }
  getNested_Exp(nested: string) {return this.infoForm.get(nested); }
  get transports() { return this.f.transports as FormArray; }
  get p() { return this.f.products as FormArray; }
  getShipmentProductControls(shipment) {return shipment.get('products').controls; }
  getShipmentProductArray(shipment): FormArray {return shipment.controls.products as FormArray; }
  addProduct = (formArray: FormArray) => {
    formArray.push(this.buildProductForm());
  }
  deleteProduct = (formControls, index) => {
    formControls.removeAt(index);
  }

  addShipment = () => {
    this.transports.push(this.buildTransportForm());
  }
  deleteShipment = (index) => {
    this.transports.removeAt(index);
  }
}
