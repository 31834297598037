import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SharedData} from '../../Common/SharedData';
import {MessageService} from '../../components/message/message.service';
import {AuthService} from '../auth.service';

@Component({
    selector: 'app-confirm-password',
    templateUrl: './confirm-password.component.html',
    styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

    confirmPasswordForm: FormGroup;
    special_chars = '^$*.[]{}()?"!@#%&/ \\,><\':;|_~`';
    fieldTextType: boolean;
    formIsInvalid = false;

    constructor(private authService: AuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private _notification: MessageService,
                private translate: TranslateService,
                private data: SharedData) {
        this.confirmPasswordForm = new FormGroup({
            tempPassword: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8),
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'), Validators.pattern(/[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/)])
        });
    }

    password_reset_success: string;

    async ngOnInit() {
        if (this.authService.isLoggedIn) {
            await this.router.navigate(['/dashboard']);
        }
        this.activatedRoute.queryParams.subscribe(params => {
            this.confirmPasswordForm.get('email').setValue(params['email']);
        });
        this.translate.get('messages').subscribe(messages => {
            this.password_reset_success = messages.notification.password_reset_success;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.password_reset_success = messages.notification.password_reset_success;
        });
    }

    get f() {
        return this.confirmPasswordForm.controls;
    }

    async confirmPassword() {
        if (this.confirmPasswordForm.invalid) {
            this.formIsInvalid = true;
            return;
        }
        const formParams = this.confirmPasswordForm.value;
        await this.authService.login(formParams.email, formParams.tempPassword, formParams.password)
            .then(async () => await this.router.navigate(['/login']).then(() => this.data.successMessage = this.password_reset_success))
            .catch(e => this._notification.displayMessage(e.message, 'danger'));
    }

    enoughChar(password: string) {
        return password.length >= 8;
    }

    anyUpperLetter(password: string) {
        const regex = RegExp('(?=.*[A-Z])');
        return regex.test(password);
    }

    anyNumber(password: string) {
        const regex = RegExp('(?=.*[0-9])');
        return regex.test(password);
    }

    anySpecialChar(password: string) {
        const regex_symbols = /[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/;
        const regex = RegExp(regex_symbols);
        return regex.test(password);
    }

    anyLowerLetter(password: string) {
        const regex = RegExp('(?=.*[a-z])');
        return regex.test(password);
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }
}
