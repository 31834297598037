import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../../../environments/environment';
import { StepCustomsclassificationDefaultsForm } from '../../../../../../models/Register';
import { MessageService } from '../../../../../components/message/message.service';
@Component({
    selector: 'app-register-step-aiclassify',
    templateUrl: './step_aiclassify.component.html',
    styleUrls: ['../../register_step.component.scss']
})
export class RegisterStepAiclassifyComponent implements OnInit {
    @Input()
    formContent: StepCustomsclassificationDefaultsForm;
    @Input()
    countries: { value: string, label: string, iso2: string }[] = [];
    @Output()
    nextStepCallback: EventEmitter<StepCustomsclassificationDefaultsForm> = new EventEmitter<StepCustomsclassificationDefaultsForm>();

    registerForm: FormGroup;
    formIsInvalid = false;

    categories: string[] = [];

    checkingHscode = false;

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.registerForm = new FormGroup({
            hs_code: new FormControl(
                this.formContent.hs_code ? this.formContent.hs_code : '',
                [
                    Validators.required
                ]
            ),
            origin_country: new FormControl(
                this.formContent.origin_country ? this.formContent.origin_country : '',
                [
                    Validators.required
                ]
            ),
            product_category: new FormControl(
                this.formContent.product_category ? this.formContent.product_category : '',
                [
                    Validators.required
                ]
            ),
            product_description: new FormControl(
                this.formContent.product_description ? this.formContent.product_description : '',
                [
                    Validators.required
                ]
            )
        });

        this.translate.get('account.customs_categories').toPromise().then((categories) => {
            this.categories = Object.keys(categories);
        });
    }

    async nextStep(validData = true) {
        if (! this.checkingHscode) {
            if (this.registerForm.invalid) {
                this.formIsInvalid = true;
                return;
            }
            if (validData) {
                this.checkingHscode = true;
                // we must check that the hs code is a right hs code for the origin country
                if (this.registerForm.valid && await this.checkHscode(this.registerForm.get('origin_country').value, this.registerForm.get('hs_code').value)) {
                    this.nextStepCallback.emit(this.registerForm.value as StepCustomsclassificationDefaultsForm);
                }
                this.checkingHscode = false;
            } else {
                this.nextStepCallback.emit(this.formContent);
            }
        }
    }

    // verify that the entered HS Code is a right HS Code for the specified origin_country
    async checkHscode(origin_country: string, hscode: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/verifyhscode`, {origin_country, hscode}).toPromise()
            .then(async (response: any) => {
                if (!response.isRightHscode) {
                    this._notification.displayMessage(await this.translate.get('account.wrong_SH').toPromise(), 'warning');
                }
                resolve(response.isRightHscode);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }
}
