import {BARCODE_ENUM, ProductIdentification} from './Product';

export enum MODULS_NUMBERS {
    'neutral' = 0,
    'modul_1' = 1,
    'modul_2' = 2,
    'modul_3' = 3,
    'modul_4' = 4
}

// pro ecoTax with modul / minimal data
export class EcoTaxMinimalData {
    price: number;
    currency: string;
}

// pro ecoTax neutral
export class EcoTaxSingleProNeutralData extends EcoTaxMinimalData {
    code: string;
}

// personal ecoTax
export class EcoTaxSinglePersonalData extends EcoTaxSingleProNeutralData {
    range: number[];
}

export class EcoTaxProData {
    neutral: EcoTaxSingleProNeutralData;
    modul_1?: EcoTaxMinimalData;
    modul_2?: EcoTaxMinimalData;
    modul_3?: EcoTaxMinimalData;
}

export class EcoTaxPersonalData {
    neutral: EcoTaxSinglePersonalData;
    modul_1?: EcoTaxSinglePersonalData;
    modul_2?: EcoTaxSinglePersonalData;
    modul_3?: EcoTaxSinglePersonalData;
}

export class HsCodeFinderResult {
    ai_score: number;
    hs_version: string;
    hs_code: string;
    hs_code_key?: string; // CHE key
    designation: string;
    splitted_designation: {
        chapter_designation: string;
        heading_designation: string;
        subheading_designation: string;
        national_designation: string;
    };
    un_code: string[];
    co2_product_kg: number;
    ecoTax: {
        personal: EcoTaxPersonalData[],
        pro: EcoTaxProData[]
    };
    product_statut: string;
}

export class UnCodeResult {
    result: {
        un_code: string[];
        designation_un_code: string[];
    };
    timestamp: number;
}

export class WeightDimensions {
    min: number;
    max: number;
    median: number;
    unit: string;
}

export class WeightDimensionsResult {
    result: {
        weight: WeightDimensions;
        width: WeightDimensions;
        height: WeightDimensions;
        length: WeightDimensions;
    };
    timestamp: number;
}

// REP finder
export class RepSectorContent {
    code: string;
    designation: string;
    eco_organizations: string[];
}
export class EcoTaxRepFinderResult {
    REP_sectors: RepSectorContent[];
}

export enum AiClassifyEnum {
    TEXT = 'TEXT',
    SKU = 'SKU',
    HSCODE = 'HSCODE',
    CATEGORY = 'CATEGORY'
}

export class AiClassifyIdentification implements ProductIdentification {
    type: AiClassifyEnum;
    value: string;
}

export class AiProductPricingRequest {
    product: {
        identification: AiClassifyIdentification;
    };
    to_country: string;
    lang?: string;
}

export class AiProductPricingRequests {
    requests: AiProductPricingRequest[];
}

export class AiProductPricingResult {
    product_price_min: number;
    product_price_currency: string;
    shop_url: string;
    picture_url: string;
    timestamp: number;
}

export class AiClassifyRequest extends AiProductPricingRequest {
    to_country: string;
}

export class EcoCodeFinderRequest {
    title: string;
    weight: number;
    weight_unit: string;
}

export class EcoCodeFinderRequests {
    ecoCodeFinderRequests: EcoCodeFinderRequest[];
}

export class GreenProductRequest {
    product: {
        identification: AiClassifyIdentification;
        weight: number;
        weight_unit: string;
    };
    from_country: string;
    to_country: string;
}

export class GreenProductRequests {
    requests: GreenProductRequest[];
}

export class GreenProductResult extends GreenProductRequest {
    co2_product: number;
    timestamp: number;
}

export enum AI_CLASSIFY_COUNTRY_TYPE {
    EXPORT = 'export',
    IMPORT = 'import'
}

export enum HsCodeFinderEnum {
    'FRONT'= 'FRONT',
    'FRET' = 'FRET',
    'FOOD' = 'FOOD',
    'BARCODE' = 'BARCODE',
    'TEXT' = 'TEXT',
    'BTOC' = 'BTOC',
    'BTOB' = 'BTOB',
    'SKU' = 'SKU',
    'HSCODE' = 'HSCODE',
    'CATEGORY' = 'CATEGORY',
    'XRAY_IMAGE_URL' = 'XRAY_IMAGE_URL',
    'IMAGE_URL' = 'IMAGE_URL',
    'INVOICE_URL' = 'INVOICE_URL'
}

export class HsCodeFinderIdentification implements ProductIdentification {
    type: HsCodeFinderEnum;
    value: string;
}

export class HsCodeFinderRequest {
    product: {identification: HsCodeFinderIdentification};
    to_country: string;
    from_country: string;
    ai_score?: boolean;
    multi_results?: number;
    lang: string;
}

export class HsCodeFinderRequests {
    hsCodeFinderRequests: HsCodeFinderRequest[];
}

export class AiClassifyRequests {
    requests: AiClassifyRequest[];
}

export class AiImprovementRequest {
    review_hscode: string;
    timestamp: number;
}

/*
* AiClassifyV4
*/
export class AiClassifyV4Request {
    product: {
        identification: AiClassifyIdentification;
    };
    from_country: string;
    to_country: string;
    lang?: string;
};
export class AiClassifyV4SingleResponse {
    ntm_code: string;
    regulation_title: string;
    ntm_description: string;
    ntm_example?: string;
    measure_description: string;
    official_regulation_document_link: string;
    implementation_date: string;
};
export class AiClassifyV4Response {
    results: AiClassifyV4SingleResponse[];
    timestamp: number;
};