import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MessageService } from '../../components/message/message.service';
import { Utils } from '../../utils/utils';
import { UploadFile } from '../../components/upload/interface';
import { pgUploadComponent } from '../../components/upload/upload.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AnnualRevenue, CustomerCountry, FormatCsv } from '../../../models/Managers';
import { Builder } from 'builder-pattern';
import { CsvService } from '../../services/csv.service';
import { Subject } from 'rxjs';

const countriesPerContinent = require('../../../../assets/data/countriesPerContinent.json');

@Component({
    selector: 'app-country-manager',
    templateUrl: './country-manager.component.html',
    styleUrls: ['./country-manager.component.scss'],
})
export class CountryManagerComponent implements OnInit {
    @ViewChild(pgUploadComponent, { static: false }) pgUploadComponent: pgUploadComponent;
    @ViewChild('editrow', { static: false }) editrow: TemplateRef<any>;

    rowForm: FormGroup;
    formIsInvalid = false;

    closingModal = true;

    // display the pg-upload component
    displayUploadCsvComponent = false;

    rows: any[] = [];
    selectedRows: any[] = [];
    annualRevenueYears: string[] = [];
    columnsOrder: string[] = [];
    columnsLabels: any;

    countries: Array<any> = [];
    currencies: Array<any> = [];
    statutes: Array<any> = [];
    ukRulesAvailable: Array<any> = [];
    showWidget: any[] = [];

    modalRef: BsModalRef;

    csvArray: string[] = [];
    unknown_error: string;
    emptyCSV: string;
    wrongCSV: string;
    messages: any;

    addCountry: string = null;

    continentsWithCountries: {
        AS: string[];
        EU: string[];
        AF: string[];
        NA: string[];
        SA: string[];
        OC: string[];
        AN: string[];
    } = {AS: [], EU: [], AF: [], NA: [], SA: [], OC: [], AN: []};

    displayRowOnly = false;
    requestSelectedRowsSubject: Subject<void> = new Subject<void>();
    formatColumnsValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

    constructor(
        private http: HttpClient,
        private _notification: MessageService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private csvService: CsvService,
        private formBuilder: FormBuilder
    ) {
        this.rowForm = null;
        this.rows = null;
        const currentYear = (new Date()).getFullYear();
        this.annualRevenueYears = [
            (currentYear - 1).toString(),
            currentYear.toString()
        ];
        this.continentsWithCountries = {...countriesPerContinent} as {
            AS: string[];
            EU: string[];
            AF: string[];
            NA: string[];
            SA: string[];
            OC: string[];
            AN: string[];
        };
    }

    async ngOnInit() {
        this.initTranslation();
        this.setColumnsOrder();
        await this.retrieveCountries();
        this.setColumnsOrder();
        this.fillAnnualRevenuesCountriesPercentage();
    }

    // show / hide pg-upload component
    switchDisplayUploadCsvComponent() {
        this.displayUploadCsvComponent = !this.displayUploadCsvComponent;
    }

    // are the rows empty?
    emptyRows(): boolean {
        if (this.rows === null || this.rows.length === 0) {
            return true;
        }
        return false;
    }

    async retrieveCountries() {
        const countries: any[] = await this.getCountries();
        if (countries !== null) {
            this.rows = [];
            countries.forEach((country) => {
                const customerCountry = Builder(CustomerCountry)
                                        .iso3_country(country.iso3Country)
                                        .statut(country.statut)
                                        .currency(country.currency)
                                        .tax_id(country.tax_id)
                                        .eori(country.eori)
                                        .uk_rules(country.uk_rules)
                                        .show_widget(country.show_widget)
                                        .origin_tax(country.origin_tax)
                                        .annual_revenue(country.annual_revenue)
                                        .locals_taxes(country.locals_taxes)
                                        .special_taxes(country.special_taxes)
                                        .duties(country.duties)
                                        .build();

                this.rows.push(customerCountry.toRow());

                // add years in annualRevenueYears if required
                const keys = Object.keys(country.annual_revenue);
                keys.forEach(k => {
                    if (!this.annualRevenueYears.includes(k)) {
                        const whereToInsert = this.annualRevenueYears.filter(ary => ary < k).length;
                        this.annualRevenueYears.splice(whereToInsert, 0, k);
                    }
                });
            });
        }
    }

    private fillAnnualRevenuesCountriesPercentage() {
        this.annualRevenueYears.forEach(year => {
            if (this.rows) {
                let totalAnnualRevenue = this.rows.map(r => r[`annual_revenue_${year}`] ? r[`annual_revenue_${year}`] : 0).reduce((a, b) => a + b, 0);
                if (totalAnnualRevenue === 0) {
                    totalAnnualRevenue = 1;
                }
                this.rows.forEach(row => {
                    row[`annual_revenue_percentage_${year}`] = (
                        ((row[`annual_revenue_${year}`] ? row[`annual_revenue_${year}`] : 0) / totalAnnualRevenue) * 100
                    ).toFixed(2);
                });
            }
        });
    }

    initTranslation() {
        // get error messages
        this.translate.get('messages').subscribe((messages) => {
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.countriesManager;
        });

        // get countries
        this.translate.get('countries').subscribe((all_countries) => {
            this.countries = all_countries.sort(Utils.compareByLabel);
            this.initFormatColumnsValues();
        });

        // get currencies
        this.translate.get('currency_name').subscribe(currencies => {
            this.currencies = currencies;
            this.initFormatColumnsValues();
        });

        this.translate.get('countriesManager').subscribe((countriesManager) => {
            this.statutes = countriesManager.statut;
            this.ukRulesAvailable = countriesManager.availableUkRules;
            this.showWidget = countriesManager.showWidget;
            this.initFormatColumnsValues();
            this.setColumnsLabels();
        });

        // handle update language
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.unknown_error = messages.notification.unknown_error;
            this.emptyCSV = messages.notification.emptyCSV;
            this.wrongCSV = messages.notification.wrongCSV;
            this.messages = messages.notification.countriesManager;

            this.countries = event.translations['countries'].sort(Utils.compareByLabel);
            this.currencies = event.translations['currency_name'];

            this.statutes = event.translations.countriesManager.statut;
            this.ukRulesAvailable = event.translations.countriesManager.availableUkRules;
            this.showWidget = event.translations.countriesManager.showWidget;

            this.setColumnsLabels();
            this.initFormatColumnsValues();
        });
    }

    setColumnsLabels() {
        const inputForm: { [key: string]: string; } = this.translate.instant('countriesManager.inputForm');
        const labels: { [key: string]: string; } = {};
        this.columnsOrder.forEach(col => {
            const key = this.getColTranslationKey(col);
            if (inputForm[key]) {
                labels[col] = inputForm[key];

            // it must be annual value
            } else {
                labels[key] = this.getAnnualValueLabel(col);
            }
        });

        this.columnsLabels = labels;
    }

    getAnnualValueLabel(col: string): string {
        const colPrefix = col.substring(0, col.lastIndexOf('_'));
        const colYear = col.substring(col.lastIndexOf('_') + 1, col.length);
        return this.translate.instant('countriesManager.inputForm.' + this.getColPrefixForTranslation(colPrefix), {year: colYear});
    }

    getColPrefixForTranslation(prefix: string): string {
        switch (prefix) {
            case 'annual_revenue':
                return 'revenueCountryAnnual';
            case 'locals_taxes':
                return 'localsTaxesCountryAnnual';
            case 'special_taxes':
                return 'specialTaxesCountryAnnual';
            case 'duties':
                return 'dutiesCountryAnnual';
            case 'annual_revenue_percentage':
                return 'percentageRevenueCountryAnnual';
            default:
                return '';
        }
    }

    setColumnsOrder() {
        this.columnsOrder = ['iso3_country', 'statut'];
        if (this.annualRevenueYears) {
            this.columnsOrder = this.columnsOrder.concat(this.annualRevenueYears.map(ary => 'annual_revenue_' + ary));
            this.columnsOrder = this.columnsOrder.concat(this.annualRevenueYears.map(ary => 'locals_taxes_' + ary));
            this.columnsOrder = this.columnsOrder.concat(this.annualRevenueYears.map(ary => 'special_taxes_' + ary));
            this.columnsOrder = this.columnsOrder.concat(this.annualRevenueYears.map(ary => 'duties_' + ary));
            this.columnsOrder = this.columnsOrder.concat(this.annualRevenueYears.map(ary => 'annual_revenue_percentage_' + ary));
            this.columnsOrder = this.columnsOrder.concat(['currency', 'tax_id', 'eori', 'origin_tax', 'uk_rules', 'show_widget']);
        }
        this.setColumnsLabels();
    }

    getRemainingCountries(): any[] {
        if (this.rows !== null && this.countries.length > 0) {
            if (this.rows.length > 0) {
                const iso3Countries = this.rows.map(r => r.iso3_country);
                return this.countries.filter(c => !iso3Countries.includes(c.value));
            } else {
                return this.countries;
            }
        } else {
            return [];
        }
    }

    getColumnMarginLeft(prop: string): number {
        const propIndex: number = this.columnsOrder.findIndex(col => col === prop);
        return (-10 + (propIndex / this.columnsOrder.length) * 15);
    }

    csvFileChanged(event) {
        if (event.file.status === 'removed') {
            this.csvArray = [];
        }
    }

    beforeUpload = async (file: UploadFile, _: UploadFile[]) => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file as any);
        this.cleanFiles(file);
        reader.onload = async (e) => {
            const csv: string = reader.result as string;
            let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
            if (csvRecordsArray[csvRecordsArray.length - 1] === '') {
                csvRecordsArray.pop();
            }
            // get expected csv Format
            const expectedCsvContent = new FormatCsv(csvRecordsArray);
            csvRecordsArray = expectedCsvContent.toExpectedCsvContent();
            if (csvRecordsArray.length > 1 && csvRecordsArray[1]) {
                const firstLine: string[] = csvRecordsArray[0].split(';');
                const currentYear = (new Date()).getFullYear();
                const arrayOfMandatoryFields = [
                    'iso3_country',
                    'statut',
                    'currency',
                    'tax_id',
                    'uk_rules',
                    'show_widget',
                    'eori',
                    'origin_tax',
                    `annual_revenue_${(currentYear - 1).toString()}`,
                    `annual_revenue_${currentYear.toString()}`,
                    `locals_taxes_${(currentYear - 1).toString()}`,
                    `locals_taxes_${currentYear.toString()}`,
                    `special_taxes_${(currentYear - 1).toString()}`,
                    `special_taxes_${currentYear.toString()}`,
                    `duties_${(currentYear - 1).toString()}`,
                    `duties_${currentYear.toString()}`
                ];
                for (let i = 0; i < arrayOfMandatoryFields.length; i++) {
                    if (!firstLine.includes(arrayOfMandatoryFields[i])) {
                        this._notification.displayMessage(`Line 1 : ${arrayOfMandatoryFields[i]} ${this.messages.isMandatory}`, 'danger');
                        return true;
                    }
                }

                const wrongLines = csvRecordsArray.filter((record, index) => {
                    const line = record.split(';');
                    if (line.length !== firstLine.length) {
                        this._notification.displayMessage(`Line ${index + 1} : ${this.wrongCSV} : "${line}"`, 'danger');
                        return true;
                    }
                    return false;
                });
                if (wrongLines.length === 0) {
                    this.csvArray = csvRecordsArray;
                } else {
                    this.removeFile(file);
                }
            } else {
                this._notification.displayMessage(this.emptyCSV, 'warning');
                this.removeFile(file);
            }
        };
        return true;
    }

    removeFile(file: UploadFile) {
        this.pgUploadComponent.onRemove(file);
        this.csvArray = [];
        return false;
    }

    cleanFiles(except: UploadFile) {
        this.pgUploadComponent.FileList.forEach((file) => {
            if (except && except.uid !== file.uid) {
                this.pgUploadComponent.onRemove(file);
            }
        });
        this.csvArray = [];
    }

    async validateCSVImport() {
        // hide the pg-upload component now
        this.switchDisplayUploadCsvComponent();
        const csvArrayLength = this.csvArray.length;
        const labels: string[] = this.csvArray.shift().split(';');
        let rowIsValid: boolean;
        const currentYear = (new Date()).getFullYear();
        let line = 2;
        // annual revenue regex
        const regex = new RegExp('^(annual_revenue|locals_taxes|special_taxes|duties)_([0-9]{4})$');
        this.csvArray.forEach(async record => {
            // parse each column and check if the values are valid
            const cols: string[] = record.split(';');
            let i = 0;
            const row = {};
            rowIsValid = true;
            labels.forEach((label) => {
                if (rowIsValid) {
                    switch (label) {
                        case 'iso3_country':
                            if (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(`Line ${line} : iso3_country ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'currency':
                            // the currency is wrong
                            if (this.currencies.findIndex((col) => col.value === cols[i]) < 0) {
                                this._notification.displayMessage(
                                    `Line ${line} : currency ${this.messages.mustBeISO4217}`,
                                    'danger'
                                );
                                rowIsValid = false;
                            }
                            break;
                        case 'statut':
                            if (cols[i] !== 'true' && cols[i] !== 'false') {
                                this._notification.displayMessage(`Line ${line} : statut ${this.messages.mustBeTrueFalse}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'tax_id':
                            // nothing to de here as it could be empty
                            break;
                        case 'uk_rules':
                            if (cols[i].length > 0 && cols[i] !== 'true' && cols[i] !== 'false') {
                                this._notification.displayMessage(`Line ${line} : uk_rules ${this.messages.mustBeTrueFalse}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'show_widget':
                            if (cols[i].length > 0 && cols[i] !== 'true' && cols[i] !== 'false') {
                                this._notification.displayMessage(`Line ${line} : show_widget ${this.messages.mustBeTrueFalse}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        case 'eori':
                            // nothing to de here as it could be empty
                            break;
                        case 'origin_tax':
                            if (cols[i].length > 0 && (cols[i].length !== 3 || this.countries.findIndex((col) => col.value === cols[i]) < 0)) {
                                this._notification.displayMessage(`Line ${line} : origin_tax ${this.messages.mustBeISO3}`, 'danger');
                                rowIsValid = false;
                            }
                            break;
                        default:
                            // label does not match annual revenue regex
                            if (!regex.test(label)) {
                                this._notification.displayMessage(
                                    `Line ${line} : ${label} ${this.messages.fieldNotValid}`,
                                    'danger'
                                );
                                rowIsValid = false;

                            // label matches annual revenue regex
                            } else {
                                // wrong number
                                if (cols[i].length === 0 || isNaN(cols[i] as any) || parseFloat(cols[i]) < 0) {
                                    this._notification.displayMessage(
                                        `Line ${line} : ${label} ${this.messages.mustBeNumber}`,
                                        'danger'
                                    );
                                    rowIsValid = false;
                                } else {
                                    cols[i] = parseFloat(cols[i]).toFixed(2);
                                }
                            }
                            break;
                    }
                }
                // append label and value in row ONLY IF value is not null
                if (cols[i] !== null) {
                    row[label] = cols[i];
                }
                i += 1;
            });
            if (rowIsValid) {
                // valid the row
                await this.onValidRow(row);
            }
            if (line === csvArrayLength) {
                this.csvArray = [];
            }
            line += 1;
        });
    }

    // create a CSV file with selected lines and send it back to the user
    downloadCsv(rowsIds: string[]) {
        if (rowsIds.length > 0) {
            const csvResponse = this.rows.filter(r => rowsIds.includes(r.iso3_country));
            const csvFileName = 'my_taxes';
            return this.csvService.downloadFile(csvResponse, csvFileName);
        }
    }

    openModal(template: TemplateRef<any>, type: string, id: string) {
        if (this.rows) {
            this.closingModal = false;
            const config = {class: 'modal-lg', animated: false, backdrop: 'static', keyboard: false} as ModalOptions;
            const foundRow = this.rows.find(r => r.iso3_country === id);
            if (foundRow) {
                if (type === 'editrow' || type === 'displayrow') {
                    this.initRowForm(foundRow);
                }
            }
            this.modalRef = this.modalService.show(template, config);
        }
    }

    closeModal() {
        this.closingModal = true;
        this.displayRowOnly = false;
        this.modalRef.hide();
    }

    initRowForm(row: any) {
        this.rowForm = this.formBuilder.group({
            iso3_country: [row.iso3_country, Validators.required],
            statut: [row.statut, Validators.required],
            currency: [row.currency, Validators.required],
            tax_id: [row.tax_id, []],
            eori: [row.eori, []],
            origin_tax: [row.origin_tax, []],
            uk_rules: [row.uk_rules, []],
            show_widget: [row.show_widget, []]
        });

        // append each annual_revenue control
        this.annualRevenueYears.forEach(y => {
            this.rowForm.addControl(
                `annual_revenue_${y}`,
                new FormControl(row[`annual_revenue_${y}`] ? row[`annual_revenue_${y}`] : 0, [Validators.min(0), Validators.required])
            );
            this.rowForm.addControl(
                `locals_taxes_${y}`,
                new FormControl(row[`locals_taxes_${y}`] ? row[`locals_taxes_${y}`] : 0, [Validators.min(0), Validators.required])
            );
            this.rowForm.addControl(
                `special_taxes_${y}`,
                new FormControl(row[`special_taxes_${y}`] ? row[`special_taxes_${y}`] : 0, [Validators.min(0), Validators.required])
            );
            this.rowForm.addControl(
                `duties_${y}`,
                new FormControl(row[`duties_${y}`] ? row[`duties_${y}`] : 0, [Validators.min(0), Validators.required])
            );
        });
    }

    rowInvalid(): boolean {
        return ! this.rowForm
                ||
                this.rowForm.invalid
                ||
                (this.countryIsEu() && this.taxIdIsDefined() && ! this.rowForm.get('origin_tax').value);
    }

    async onValidRow(csvRow: any = null) {
        // row to add from CSV
        // csvRow is as {'iso3Country': 'FRA', ...}
        if (csvRow !== null) {
            let years = (Object.keys(csvRow)).filter(k => k.startsWith('annual_revenue_')).map(k => k.substring(k.lastIndexOf('_') + 1));
            const annualRevenue: AnnualRevenue = new AnnualRevenue();
            years.forEach(y => {
                annualRevenue[y] = parseFloat(csvRow[`annual_revenue_${y.toString()}`]);
            });

            years = (Object.keys(csvRow)).filter(k => k.startsWith('locals_taxes_')).map(k => k.substring(k.lastIndexOf('_') + 1));
            const localsTaxes: AnnualRevenue = new AnnualRevenue();
            years.forEach(y => {
                localsTaxes[y] = parseFloat(csvRow[`locals_taxes_${y.toString()}`]);
            });

            years = (Object.keys(csvRow)).filter(k => k.startsWith('special_taxes_')).map(k => k.substring(k.lastIndexOf('_') + 1));
            const specialTaxes: AnnualRevenue = new AnnualRevenue();
            years.forEach(y => {
                specialTaxes[y] = parseFloat(csvRow[`special_taxes_${y.toString()}`]);
            });

            years = (Object.keys(csvRow)).filter(k => k.startsWith('duties_')).map(k => k.substring(k.lastIndexOf('_') + 1));
            const duties: AnnualRevenue = new AnnualRevenue();
            years.forEach(y => {
                duties[y] = parseFloat(csvRow[`duties_${y.toString()}`]);
            });

            if (csvRow.origin_tax && csvRow.tax_id && this.continentsWithCountries.EU.includes(csvRow.origin_tax.toUpperCase())) {
                const country = this.getEuCountries().find(c => c.value === csvRow.origin_tax);
                if (country && ! (await this.checkTaxId(country.iso2, csvRow.tax_id))) {
                    return;
                }
            }

            const customerCountry = Builder(CustomerCountry)
                                .iso3_country(csvRow.iso3_country)
                                .statut(csvRow.statut === 'true' ? true : false)
                                .currency(csvRow.currency)
                                .tax_id(csvRow.tax_id ? csvRow.tax_id : undefined)
                                .uk_rules(csvRow.uk_rules === 'true')
                                .show_widget(csvRow.show_widget === 'true')
                                .eori(csvRow.eori ? csvRow.eori : undefined)
                                .origin_tax(csvRow.origin_tax ? csvRow.origin_tax : undefined)
                                .annual_revenue(annualRevenue)
                                .locals_taxes(localsTaxes)
                                .special_taxes(specialTaxes)
                                .duties(duties)
                                .build();

            const countryIndex: number = this.rows.findIndex(row => row.iso3_country === csvRow.iso3_country);
            // PUT values
            if (countryIndex >= 0) {
                if (await this.putCountry(customerCountry)) {
                    this.rows[countryIndex] = customerCountry.toRow();
                    // refresh the table
                    this.rows = [...this.rows];
                }

            // POST values
            } else {
                if (await this.postCountry(customerCountry)) {
                    this.rows.push(customerCountry.toRow());
                    // refresh the table
                    this.rows = [...this.rows];
                }
            }

        // row to add from form
        } else {
            if (this.rowInvalid()) {
                this.formIsInvalid = true;
                return;
            }
            const formValue = this.rowForm.value;

            if (formValue.origin_tax && formValue.tax_id && this.continentsWithCountries.EU.includes(formValue.origin_tax.toUpperCase())) {
                const country = this.getEuCountries().find(c => c.value === formValue.origin_tax);
                if (country && ! (await this.checkTaxId(country.iso2, formValue.tax_id))) {
                    this.closeModal();
                    return;
                }
            }

            const annual_revenue = {};
            const locals_taxes = {};
            const special_taxes = {};
            const duties = {};
            this.annualRevenueYears.forEach(y => {
                annual_revenue[y] = parseFloat(formValue[`annual_revenue_${y}`].toString());
                locals_taxes[y] = parseFloat(formValue[`locals_taxes_${y}`].toString());
                special_taxes[y] = parseFloat(formValue[`special_taxes_${y}`].toString());
                duties[y] = parseFloat(formValue[`duties_${y}`].toString());
            });

            const customerCountry = Builder(CustomerCountry)
                        .iso3_country(formValue.iso3_country)
                        .statut(formValue.statut)
                        .annual_revenue(annual_revenue)
                        .locals_taxes(locals_taxes)
                        .special_taxes(special_taxes)
                        .duties(duties)
                        .currency(formValue.currency)
                        .tax_id(formValue.tax_id ? formValue.tax_id : undefined)
                        .eori(formValue.eori ? formValue.eori : undefined)
                        .origin_tax(formValue.origin_tax ? formValue.origin_tax : undefined)
                        .uk_rules(formValue.uk_rules !== undefined && formValue.uk_rules !== null ? formValue.uk_rules : false)
                        .show_widget(formValue.show_widget !== undefined && formValue.show_widget !== null ? formValue.show_widget : true)
                        .build();

            this.rows[this.rows.findIndex(r => r.iso3_country === customerCountry.iso3_country)] = customerCountry.toRow();
            // refresh the table
            this.rows = [...this.rows];

            await this.putCountry(customerCountry);

            this.closeModal();
        }

        this.fillAnnualRevenuesCountriesPercentage();
        this.formIsInvalid = false;
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    // post countries, returns true on success, false otherwise
    async postCountry(customerCountry: CustomerCountry): Promise<boolean> {
        try {
            await this.http.post(`https://api.${environment.baseUrl}/v1/customer/countries`, customerCountry).toPromise();
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // post countries with default values
    async postCountryWithDefaults(iso3_country: string) {
        try {
            const annualRevenue: AnnualRevenue = new AnnualRevenue();
            const localsTaxes: AnnualRevenue = new AnnualRevenue();
            const specialTaxes: AnnualRevenue = new AnnualRevenue();
            const duties: AnnualRevenue = new AnnualRevenue();
            this.annualRevenueYears.forEach(y => {
                annualRevenue[y.toString()] = 0;
                localsTaxes[y.toString()] = 0;
                specialTaxes[y.toString()] = 0;
                duties[y.toString()] = 0;
            });

            const customerCountry = Builder(CustomerCountry)
                                    .iso3_country(iso3_country)
                                    .statut(true)
                                    .currency('EUR')
                                    .tax_id(null)
                                    .eori(null)
                                    .origin_tax(null)
                                    .uk_rules(false)
                                    .show_widget(true)
                                    .annual_revenue(annualRevenue)
                                    .locals_taxes(localsTaxes)
                                    .special_taxes(specialTaxes)
                                    .duties(duties)
                                    .build();

            await this.http.post(`https://api.${environment.baseUrl}/v1/customer/countries`, customerCountry).toPromise();
            this.rows.push(customerCountry.toRow());
            this.rows = [...this.rows];
            this.fillAnnualRevenuesCountriesPercentage();
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
        }
        this.addCountry = null;
    }

    // put country, returns true on success, false otherwise
    async putCountry(customerCountry: CustomerCountry): Promise<boolean> {
        try {
            await this.http.put(`https://api.${environment.baseUrl}/v1/customer/countries`, customerCountry).toPromise();
            return true;
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return false;
        }
    }

    // get countries, returns true on success, false otherwise
    async getCountries(): Promise<CustomerCountry[]> {
        try {
            return (<CustomerCountry[]> await this.http.get(`https://api.${environment.baseUrl}/v1/customer/countries`).toPromise());
        } catch (errorResponse) {
            console.log('ERROR : ' + JSON.stringify(errorResponse));
            this._notification.displayMessage(this._notification.buildTransiteoErrorMessage(errorResponse.error, this.unknown_error), 'danger');
            return null;
        }
    }

    onSelect({ selected }) {
        this.selectedRows.splice(0, this.selectedRows.length);
        this.selectedRows.push(...selected);
    }

    getStatutLabel(value: boolean): string {
        if (this.statutes) {
            return this.statutes.find(s => s.value === value).label;
        } else {
            return value.toString();
        }
    }

    getUkRulesLabel(value: boolean): string {
        if (this.ukRulesAvailable) {
            const found = this.ukRulesAvailable.find(s => s.value === value);
            return found ? found.label : value.toString();
        } else {
            return value.toString();
        }
    }

    getShowWidgetLabel(value: boolean): string {
        if (this.showWidget) {
            const found = this.showWidget.find(s => s.value === value);
            return found ? found.label : value.toString();
        } else {
            return value.toString();
        }
    }

    getCountryLabel(value: string): string {
        if (this.countries && this.countries.length > 0) {
            const found = this.countries.find(s => s.value === value);
            if (found) {
                return found.label;
            }
        }
        return value;
    }

    getRowFormCountryLabel(): string {
        const isoCountry = this.rowForm.get('iso3_country').value;
        if (this.countries && this.countries.length > 0) {
            const found = this.countries.find(s => s.value === isoCountry);
            if (found) {
                return found.label;
            }
        }
        return isoCountry;
    }

    taxIdIsDefined(): boolean {
        const taxId = this.rowForm.get('tax_id').value;
        return taxId !== undefined && taxId !== null && taxId.length > 0
    }

    countryIsEu(): boolean {
        const iso3 = this.rowForm.get('iso3_country').value;
        return this.continentsWithCountries.EU.includes(iso3);
    }

    countryIsUk(): boolean {
        const iso3 = this.rowForm.get('iso3_country').value;
        return iso3 === 'GBR';
    }

    getEuCountries(): any[] {
        if (this.countries && this.countries.length > 0) {
            return this.countries.filter(c => this.continentsWithCountries.EU.includes(c.value));
        }
        return [];
    }

    // verify that the entered taxId is a right taxId for the specified origin_country
    async checkTaxId(country_of_origin: string, vat_id: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post(`https://api.${environment.baseUrl}/v1/taxsrv/verifyVatId`, {country_of_origin, vat_id}).toPromise()
            .then(async (response: any) => {
                if (! response.valid) {
                    this._notification.displayMessage(await this.translate.get('account.wrong_tax_id').toPromise(), 'warning');
                }
                resolve(response.valid);
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(`${errorResponse.code} : ${errorResponse.message}`, 'danger');
                resolve(false);
            });
        });
    }

    getColTranslationKey(col: string): string {
        switch (col) {
            case 'iso3_country':
                return 'country';
            case 'tax_id':
                return 'vatId';
            default:
                return col;
        }
    }

    onDownloadSelectedRowsAsCsv() {
        this.requestSelectedRowsSubject.next();
    }

    initFormatColumnsValues() {
        const formatValues: { columns: string[]; type: string | { label: string; value: string; iso2?: string; }[]; }[] = [];

        // prepare format countries
        if (this.countries) {
            formatValues.push({
                columns: ['iso3_country', 'origin_tax'],
                type: this.countries as { value: string; label: string; iso2: string; }[]
            });
        }
        // prepare format currency
        if (this.currencies) {
            formatValues.push({
                columns: ['currency'],
                type: this.currencies as { value: string; label: string; }[]
            });
        }
        // prepare format statut
        if (this.statutes) {
            formatValues.push({
                columns: ['statut'],
                type: this.statutes as { value: string; label: string; }[]
            });
        }
        // prepare format uk_rules
        if (this.ukRulesAvailable) {
            formatValues.push({
                columns: ['uk_rules'],
                type: this.ukRulesAvailable as { value: string; label: string; }[]
            });
        }
        // prepare format show_widget
        if (this.showWidget) {
            formatValues.push({
                columns: ['show_widget'],
                type: this.showWidget as { value: string; label: string; }[]
            });
        }

        this.formatColumnsValues = formatValues;
    }

    onDisplayRow(rowId: string) {
        this.displayRowOnly = true;
        this.openModal(this.editrow, 'displayrow', rowId);
    }
    onEditRow(rowId: string) {
        this.displayRowOnly = false;
        this.openModal(this.editrow, 'editrow', rowId);
    }
}
