import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import {Product} from '../interfaces/product.interface';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HsCodeFinderEnum, HsCodeFinderIdentification, HsCodeFinderRequest, HsCodeFinderResult } from '../../../../models/HsCodeFinder';
import { MessageService } from '../../../components/message/message.service';
import { Builder } from 'builder-pattern';
import { RequestRequiredFields } from '../interfaces/request-required-fields.interface';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ManagersService } from '../../../services/managers.service';

@Component({
    selector: 'app-step-product',
    templateUrl: './step-product.component.html',
    styleUrls: ['./step-product.component.scss']
})
export class StepProductComponent implements OnInit {
    @Input()
    requestRequiredFields: RequestRequiredFields[];
    @Input()
    countries: Array<{value: string, label: string, iso2: string}> = [];
    @Output()
    setData: EventEmitter<{
        requestsRequiredFields: RequestRequiredFields[],
        includedTax: boolean[][]
    }> = new EventEmitter<{
        requestsRequiredFields: RequestRequiredFields[],
        includedTax: boolean[][]
    }>();
    @Output()
    previousStep: EventEmitter<void> = new EventEmitter<void>();

    productForm: FormGroup;
    formIsInvalid = false;
    currencies: Array<{value: string, label: string, disabled?: boolean}>;
    booleanChoices: {value: string, label: string}[] = [];
    types = [];

    textPlaceholder: string;
    hscodePlaceholder: string;
    skuPlaceholder: string;

    private hsCodeFinderUrl: string;
    filteredOptions: Observable<string[]> = new Observable<string[]>();

    constructor(
        private translate: TranslateService,
        private http: HttpClient,
        private _notification: MessageService,
        private managersService: ManagersService,
    ) {
        this.hsCodeFinderUrl = `https://api.${environment.baseUrl}/v1/taxsrv/hscodefinder`;
        this.productForm = new FormGroup({
            streams: new FormArray([])
        });
    }

    async ngOnInit() {
        this.managersService.prepareProducts();
        await this.translate.get('currency_name').toPromise().then(currencies => {
            this.currencies = currencies;
        });
        await this.translate.get('dutyCalculation').toPromise().then(dutyCalculation => {
            this.updateDutyTranslation(dutyCalculation);
        });
        await this.translate.get('profile.boolean_choice').toPromise().then((boolChoices) => {
            this.booleanChoices = boolChoices;
        });
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currencies = event.translations['currency_name'];
            this.updateDutyTranslation(event.translations['dutyCalculation']);
            this.booleanChoices = event.translations.profile.boolean_choice;
        });
        if (this.requestRequiredFields && this.requestRequiredFields.length > 0) {
            for (let i = 0; i < this.requestRequiredFields.length; i++) {
                this.addStream(i);
            }
        }
    }

    private updateDutyTranslation(dutyCalculation) {
        const steps = dutyCalculation.steps;
        if (steps && steps.product) {
            this.types = steps.product.types;
            if (steps.product.placeholder) {
                this.textPlaceholder = steps.product.placeholder.productText;
                this.hscodePlaceholder = steps.product.placeholder.productHscode;
                this.skuPlaceholder = steps.product.placeholder.productSku;
            }
        }
    }

    getProductValuePlaceholder(i: number, j: number) {
        if ((this.getStreamProducts(j).at(i) as FormGroup)) {
            switch ((this.getStreamProducts(j).at(i) as FormGroup).get('identification.type').value) {
                case 'HSCODE':
                    return this.hscodePlaceholder;
                case 'TEXT':
                    return this.textPlaceholder;
                case 'SKU':
                    return this.skuPlaceholder;
                default:
                    return 'PRODUCT';
            }
        }
        return '';
    }

    async classifyText(i: number, streamIndex: number): Promise<void> {
        const text = this.getStreamProducts(streamIndex).at(i).get('identification').get('value').value;
        if (text && text.length > 0) {
            const hscodeFinderResponse = await this.callHsCodeFinder(
                Builder(HsCodeFinderRequest)
                .to_country(this.streams.at(streamIndex).get('toCountry').value)
                .product({
                    identification: Builder(HsCodeFinderIdentification)
                                    .type(HsCodeFinderEnum.TEXT)
                                    .value(text)
                                    .build()
                })
                .build()
            );
            this.getStreamProducts(streamIndex).at(i).get('identification').get('generatedHscode').setValue(hscodeFinderResponse.hs_code);
        }
    }

    async callHsCodeFinder(hsCodeFinderRequest: HsCodeFinderRequest): Promise<HsCodeFinderResult> {
        return this.http
            .post(this.hsCodeFinderUrl, hsCodeFinderRequest)
            .toPromise()
            .then((response: {result: HsCodeFinderResult}) => {
                return response.result;
            })
            .catch((errorResponse) => {
                this._notification.displayMessage(
                    this._notification.buildTransiteoErrorMessage(errorResponse.error, 'Unable to retrieve a Hscode'),
                    'danger'
                );
                return null;
            });
    }

    allHscodesAreValid(): boolean {
        for (let j = 0; j < this.streams.length; j++) {
            const prods = this.getStreamProducts(j);
            for (let i = 0; i < prods.length; i++) {
                const prod = prods.at(i).value;
                if (
                    prod.identification.type === 'TEXT' &&
                    (
                        ! prod.identification.generatedHscode ||
                        prod.identification.generatedHscode.length === 0
                    )
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    productTypeIsText(i: number, j: number): boolean {
        const prods = this.getStreamProducts(j);
        if (prods && i < prods.length && prods.at(i) && prods.at(i).get('identification') && prods.at(i).get('identification').get('type').value === 'TEXT') {
            return true;
        }
        return false;
    }

    buildStreamForm(stream: RequestRequiredFields): FormGroup {
        return new FormGroup({
            fromCountry: new FormControl(stream.from_country),
            toCountry: new FormControl(stream.to_country),
            toDistrict: new FormControl(stream.to_district),
            products: new FormArray([])
        });
    }
    productTypeIsSku(i: number, j: number): boolean {
        const prods = this.getStreamProducts(j);
        if (prods && i < prods.length && prods.at(i) && prods.at(i).get('identification') && prods.at(i).get('identification').get('type').value === 'SKU') {
            return true;
        }
        return false;
    }

    prepareAutocompleteProduct(streamIndex: number, index: number) {
        const control = this.getStreamProducts(streamIndex).at(index).get('identification.value');
        this.filteredOptions = control.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, this.managersService.getOptionsProducts(), 'sku')),
        );
    }

    // filter used for autocompletion
    private _filter(value: string, wholeValues: string[], filterField: string): string[] {
        if (value !== null && value !== undefined) {
            const filterValue = value.toString().toLowerCase();
            return wholeValues.filter(option => {
                const optionSplitted = option.split(', ');
                if (filterField === 'sku') {
                    return optionSplitted[0].toLowerCase().includes(filterValue) || optionSplitted[1].toLowerCase().includes(filterValue);
                }
                let i = 0;
                if (filterField === 'email') {
                    i = 1;
                } else if (filterField === 'website') {
                    i = 2;
                }
                return optionSplitted[i].toLowerCase().includes(filterValue);
            });
        }
    }

    onProductClicked(streamIndex: number, index: number, option: string) {
        // sku is the sort key
        const sku = option.split(', ')[0];
        const rightProduct = this.managersService.getProducts().find(p => p.sku === sku);
        if (rightProduct) {
            this.getStreamProducts(streamIndex).at(index).get('identification.value').setValue(rightProduct.sku);
        }
    }

    buildProductForm(product: Product = null): FormGroup {
        return new FormGroup( {
            identification: new FormGroup({
                type: new FormControl(product ? product.identification.type : 'HSCODE', [Validators.required]),
                value: new FormControl(product ? product.identification.value : '', [Validators.required]),
                generatedHscode: new FormControl('', [])
            }),
            included_tax: new FormControl(false, [Validators.required]),
            quantity: new FormControl(product ? product.quantity : '', [Validators.min(1), Validators.required]),
            unit_price: new FormControl(product ? product.unit_price : '', [Validators.min(0.01), Validators.required]),
            currency_unit_price: new FormControl(product ? product.currency_unit_price : '', [Validators.required]),
            origin_country: new FormControl(product ? product.origin_country : null, [])
        });
    }

    // control number fields entry
    filterKey(event) {
        return event.keyCode === 8 ||
        event.keyCode === 46 ? true : !isNaN(Number(event.key));
    }

    get streams(): FormArray {
        return this.productForm.get('streams') as FormArray;
    }

    getStreamProducts(i: number): FormArray {
        return this.streams.at(i).get('products') as FormArray;
    }

    addStream(streamIndex: number) {
        this.streams.push(this.buildStreamForm(this.requestRequiredFields[streamIndex]));
        this.requestRequiredFields[streamIndex].products.forEach(product => {
            this.addProduct(product, streamIndex);
        });
        if (this.getStreamProducts(streamIndex).length === 0) {
            this.addProduct(null, streamIndex);
        }
    }

    addProduct(product: Product = null, streamIndex: number) {
        this.getStreamProducts(streamIndex).push(this.buildProductForm(product));
    }

    deleteProduct(index: number, streamIndex: number) {
        this.getStreamProducts(streamIndex).removeAt(index);
    }

    getCountry(streamIndex: number, formName: string): string {
        const value = this.streams.at(streamIndex).get(formName).value;
        const country = this.countries.find(c => c.value === value);
        return country ? country.label : value;
    }

    getUnitPriceLabel(streamIndex: number, productIndex: number): string {
        const includedTax = this.getStreamProducts(streamIndex).at(productIndex).get('included_tax').value;
        return includedTax ? 'unit_price_vat' : 'unit_price';
    }

    nextStep() {
        if (! this.productForm.valid || ! this.allHscodesAreValid()) {
            this.formIsInvalid = true;
            return;
        }
        const includedTax: boolean[][] = [];
        for (let j = 0; j < this.streams.length; j++) {
            includedTax.push([]);
            const stream = this.streams.at(j).value;
            const requiredField = this.requestRequiredFields.find(rrf => {
                return rrf.from_country === stream.fromCountry &&
                    rrf.to_country === stream.toCountry &&
                    rrf.to_district === stream.toDistrict;
            });
            const prods = this.getStreamProducts(j);
            requiredField.products = [];
            for (let i = 0; i < prods.length; i++) {
                const p = prods.at(i).value;
                if (p.identification.generatedHscode) {
                    p.identification.type = HsCodeFinderEnum.HSCODE;
                    p.identification.value = (p as any).identification.generatedHscode;
                    delete (p as any).identification.generatedHscode;
                }
                requiredField.products.push(p as Product);
                includedTax[j].push(typeof p.included_tax === 'boolean' ? p.included_tax : false);
            }
        }
        this.formIsInvalid = false;
        this.setData.emit({
            requestsRequiredFields: this.requestRequiredFields,
            includedTax
        });
    }

    goBack() {
        this.previousStep.emit();
    }
}
