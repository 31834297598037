import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './auth.service';

import {pgSelectfx} from '../components/cs-select/select.module';
import {pgSelectModule} from '../components/select/select.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap';
import {RegisterStepComponent} from './register/register_step/register_step.component';
import {RegisterStepWelcomeComponent} from './register/register_step/steps/welcome/step_welcome.component';
import {RegisterStepAccountComponent} from './register/register_step/steps/account/step_account.component';
import { RegisterStepExpeditionComponent } from './register/register_step/steps/expedition/step_expedition.component';
import { RegisterStepDestinationComponent } from './register/register_step/steps/destination/step_destination.component';
import { RegisterStepTaxmanagerComponent } from './register/register_step/steps/taxmanager/step_taxmanager.component';
import { RegisterStepAiclassifyComponent } from './register/register_step/steps/aiclassify/step_aiclassify.component';
import { RegisterStepTransportationComponent } from './register/register_step/steps/transportation/step_transportation.component';
import { RegisterStepFinalComponent } from './register/register_step/steps/final/step_final.component';
import { EmbedWebsiteModule } from '../components/embed-website/embed-website.module';


@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RegisterStepComponent,
    RegisterStepWelcomeComponent,
    RegisterStepAccountComponent,
    RegisterStepExpeditionComponent,
    RegisterStepDestinationComponent,
    RegisterStepTaxmanagerComponent,
    RegisterStepAiclassifyComponent,
    RegisterStepTransportationComponent,
    RegisterStepFinalComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    pgSelectfx,
    FormsModule,
    pgSelectModule,
    TranslateModule,
    NgxIntlTelInputModule,
    EmbedWebsiteModule
  ],
  providers: [
    AuthService,
  ]
})
export class AuthModule { }
