import {Routes} from '@angular/router';
// Layouts
import {SimplyWhiteLayoutComponent} from './@pages/connected';
import {DashboardComponent} from './@pages/connected/dashboard/dashboard.component';
import {PageNotFoundComponent} from './@pages/Common/page-not-found/page-not-found.component';
import {AuthGuard} from './@pages/auth/auth.guard';
import {BlankComponent} from './@pages/Common/blank/blank.component';
import {HsCodeFinderComponent} from './@pages/connected/hs-code-finder/hs-code-finder.component';
import {ProductManagerComponent} from './@pages/connected/product-manager/product-manager.component';
import {CountryManagerComponent} from './@pages/connected/country-manager/country-manager.component';
import { PaymentManagerComponent } from './@pages/connected/payment-manager/payment-manager.component';
import {DutyCalculationComponent} from './@pages/connected/duty-calculation/duty-calculation.component';
import {UserSettingsComponent} from './@pages/connected/user-settings/user-settings.component';
import { WelcomeComponent } from './@pages/connected/welcome/welcome.component';
import { OrderManagerComponent } from './@pages/connected/order-manager/order-manager.component';
import { MyDocumentsComponent } from './@pages/connected/my-documents/my-documents.component';
import {ProductSearchComponent} from './@pages/connected/product-search/product-search.component';
import {HistoryComponent} from './@pages/connected/history/history.component';
import {PlanComponent} from './@pages/connected/plan/plan.component';
import {LangComponent} from './@pages/connected/lang/lang.component';
import {SecurityComponent} from './@pages/connected/security/security.component';
import {SubscriptionComponent} from './@pages/connected/subscription/subscription.component';
import {ApiKeyComponent} from './@pages/connected/api-key/api-key.component';
import {CardCountriesComponent} from './@pages/connected/card-countries/card-countries.component';
import {KeyIntegrationComponent} from './@pages/connected/key-integration/key-integration.component';
import {IntegrationComponent} from './@pages/connected/integration/integration.component';
// import {ShipmentComponent} from './@pages/connected/shipment/shipment.component';
import { MultiUsersComponent } from './@pages/connected/multi-users/multi-users.component';
import {AdminGuardService} from './@pages/services/admin-guard.service';
import {DeleteComponent} from './@pages/connected/delete/delete.component';
import { SuperAdminGuardService } from './@pages/services/super-admin-guard.service';
import { MonitoringDataComponent } from './@pages/connected/monitoring/monitoring-data/monitoring-data.component';
import { MonitoringUsersComponent } from './@pages/connected/monitoring/monitoring-users/monitoring-users.component';
import { CustomerManagerComponent } from './@pages/connected/customers-manager/customers-manager.component';
import { UnCodeComponent } from './@pages/connected/ai-classify/un-code/un-code.component';
import { Co2Component } from './@pages/connected/ai-classify/co2/co2.component';
import { CheaperPriceComponent } from './@pages/connected/ai-classify/cheaper-price/cheaper-price.component';
import { WeightDimensionsComponent } from './@pages/connected/ai-classify/weight-dimensions/weight-dimensions.component';
import { CategoriesManagerComponent } from './@pages/connected/categories-manager/categories-manager.component';
import { ShippingComponent } from './@pages/connected/shipping/shipping.component';
import { DocumentationGuideComponent } from './@pages/connected/documentation-guide/documentation-guide.component';
import { ExportDocumentsComponent } from './@pages/connected/export-documents/export-documents.component';
import { TradeTariffRatesComponent } from './@pages/connected/trade-tariff-rates/trade-tariff-rates.component';
import { AiClassifyDocumentsComponent } from './@pages/connected/ai-classify/ai-classify-documents/ai-classify-documents.component';
import { RepFinderComponent } from './@pages/connected/ai-classify/rep-finder/rep-finder.component';
import { CustomsClearanceComponent } from './@pages/connected/customs-clearance/customs-clearance.component';
import { ImportExportRequirementsComponent } from './@pages/connected/import-export-requirements/import-export-requirements.component';
import { LabelingComponent } from './@pages/connected/labeling/labeling.component';
// import { ShipmentManagerComponent } from './@pages/connected/shipment-manager/shipment-manager.component';


export const AppRoutes: Routes = [

  {
    path: '',
    component: SimplyWhiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
      path: 'dashboard',
      component: DashboardComponent
      },
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'countries',
        component: CardCountriesComponent
      },
      {
        path: 'aiClassify/unCode',
        component: UnCodeComponent
      },
      {
        path: 'aiClassify/co2',
        component: Co2Component
      },
      {
        path: 'aiClassify/cheaperPrice',
        component: CheaperPriceComponent
      },
      {
        path: 'aiClassify/weightDimensions',
        component: WeightDimensionsComponent
      },
      {
        path: 'aiClassify/documents',
        component: AiClassifyDocumentsComponent
      },
      {
        path: 'aiClassify/repFinder',
        component: RepFinderComponent
      },
      {
        path: 'hsCodeFinder/:type',
        component: HsCodeFinderComponent
      },
      {
        path: 'productManager',
        component: ProductManagerComponent
      },
      {
        path: 'customerManager',
        component: CustomerManagerComponent
      },
      {
        path: 'countryManager',
        component: CountryManagerComponent
      },
      {
        path: 'paymentManager',
        component: PaymentManagerComponent
      },
      /*{
        path: 'shipmentManager',
        component: ShipmentManagerComponent
      },*/
      {
        path: 'dutyCalculation',
        component: DutyCalculationComponent
      },
      {
        path: 'tradeTariffRates',
        component: TradeTariffRatesComponent
      },
      {
        path: 'shippingSimulator',
        component: ShippingComponent
      },
      {
        path: 'productSearch',
        component: ProductSearchComponent
      },
      {
        path: 'history',
        component: HistoryComponent
      },
      {
        path: 'labeling',
        component: LabelingComponent
      },
      {
        path: 'settings/:page',
        component: UserSettingsComponent
      },
      {
        path: 'settings-welcome',
        component: WelcomeComponent
      },
      {
        path: 'orderManager',
        component: OrderManagerComponent
      },
      {
        path: 'categoriesManager',
        component: CategoriesManagerComponent
      },
      {
        path: 'myDocuments',
        component: MyDocumentsComponent
      },
      {
        path: 'customsclearance',
        component: CustomsClearanceComponent
      },
      {
        path: 'importExportRequirements',
        component: ImportExportRequirementsComponent
      },
      {
        path: 'exportDocs/:type',
        component: ExportDocumentsComponent
      },
      {
        path: 'monitoring/data/:type',
        component: MonitoringDataComponent,
        canActivate: [SuperAdminGuardService]
      },
      {
        path: 'monitoring/users',
        component: MonitoringUsersComponent,
        canActivate: [SuperAdminGuardService]
      },
      {
        path: 'plans',
        component: PlanComponent,
        canActivate: [AdminGuardService]
      },
      {
        path: 'lang',
        component: LangComponent
      },
      {
        path: 'security',
        component: SecurityComponent
      },
      {
        path: 'apiKey/:token',
        component: ApiKeyComponent
      },
      {
        path: 'intKey',
        component: KeyIntegrationComponent,
        canActivate: [AdminGuardService]
      },
      {
        path: 'integrate',
        component: IntegrationComponent
      },
      {
        path: 'documentation-guide/:urlType',
        component: DocumentationGuideComponent
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [AdminGuardService]
      },
      /*{
        path: 'shipment',
        component: ShipmentComponent
      },*/
      {
        path: 'users',
        component: MultiUsersComponent,
        canActivate: [AdminGuardService]
      },
      {
        path: 'delete',
        component: DeleteComponent,
        canActivate: [AdminGuardService]
      }
    ],
    data: {
        breadcrumb: 'Home'
    },
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: BlankComponent,
    children: [{
      path: '',
      component: PageNotFoundComponent
    }]
  }
];
