import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MessageService} from '../../components/message/message.service';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../services/user.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  editPasswordForm: boolean;
  passwordForm: FormGroup;
  fieldTextType: boolean;
  fieldTextTypeConfirm: boolean;
  password_reset_success: string;
  special_chars = '^$*.[]{}()?"!@#%&/ \\,><\':;|_~`';
  constructor(private _notification: MessageService,
              private authService: AuthService,
              private userService: UserService,
              private translate: TranslateService) {
    this.editPasswordForm = false;
    this.passwordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8),
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'), Validators.pattern(/[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), this.matchValues('newPassword')]),
    });
  }

  public matchValues(
      matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
          ? null
          : { isMatching: false };
    };
  }

  ngOnInit() {
    this.translate.get('messages').subscribe(messages => {
      this.password_reset_success = messages.notification.password_reset_success;
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      const messages = event.translations['messages'];
      this.password_reset_success = messages.notification.password_reset_success;
    });
  }
  async updatePassword() {
    this.editPasswordForm = false;
    const passwords = this.passwordForm.value;
    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }
    await this.authService.changePassword(this.userService.getCognitoUser(),
        passwords.currentPassword, passwords.newPassword)
        .then(() => this._notification.displayMessage(this.password_reset_success, 'success'))
        .catch(error => this._notification.displayMessage(error.message, 'danger'));
  }
  get f() { return this.passwordForm.controls; }

  enoughChar(password: string) {
    return password.length >= 8 ;
  }
  anyUpperLetter(password: string) {
    const regex = RegExp('(?=.*[A-Z])');
    return regex.test(password) ;
  }
  anyNumber(password: string) {
    const regex = RegExp('(?=.*[0-9])');
    return regex.test(password) ;
  }
  anySpecialChar(password: string) {
    const regex_symbols = /[!$%^&*()_|~=`{}\[\]:\/;<>?,.@#"\\']/;
    const regex = RegExp(regex_symbols);
    return regex.test(password) ;
  }
  anyLowerLetter(password: string) {
    const regex = RegExp('(?=.*[a-z])');
    return regex.test(password) ;
  }
  checkPassword() {
    return this.passwordForm.get('newPassword').value === this.passwordForm.get('confirmPassword').value;
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }


}
