import {Injectable} from '@angular/core';

@Injectable()
export class SharedData {

    public storage: any;
    public successMessage: string;
    public errorMessage: string;

    public constructor() { }

}
