import {Component, OnInit, ViewChild} from '@angular/core';
import {ECOMMERCE_REQUEST_TYPE_ENUM, RequestProduct, RequestProductAllCurrencies, SHIPMENT_REQUEST_TYPE_ENUM, TaxRequest, TaxResponse} from '../../../models/DutyCalculation';
import {AuthService} from '../../auth/auth.service';
import {MessageService} from '../../components/message/message.service';
import {INCOTERM_ENUM, User} from '../../../models/User';
import { environment } from '../../../../environments/environment';
import {UserService} from '../../services/user.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {pgTabComponent} from '../../components/tabs/tab.component';
import {Origin} from './interfaces/origin.interface';
import {RequestRequiredFields, RequestsRequiredFields} from './interfaces/request-required-fields.interface';
import {Product} from './interfaces/product.interface';
import {DutyCalculationService} from '../../services/duty-calculation.service';
import {ProductsRequiredFields, ProductsRequiredFieldsWithFromToCountries} from './interfaces/products-required-fields.interface';
import {Builder} from 'builder-pattern';
import {RequestProductIdentification} from '../../../models/Product';
import { HttpClient } from '@angular/common/http';
import { ApiDataService } from '../../services/api-data.service';

export enum DUTY_STEP_ENUM {
    ORIGIN_DESTINATION = 0,
    PRODUCT = 1,
    OPTIONS = 2,
    TRANSPORT = 3,
    SENDER_RECIPIENT = 4,
    RESULT = 5
}
@Component({
    selector: 'app-duty-calculation',
    templateUrl: './duty-calculation.component.html',
    styleUrls: ['./duty-calculation.component.scss']
})
export class DutyCalculationComponent implements OnInit {
    @ViewChild(pgTabComponent, {static: false}) child: pgTabComponent;
    DUTY_STEP_ENUM = DUTY_STEP_ENUM;
    user: User;
    currentStep: number;
    unknown_error: string;

    requestRequiredFields: RequestRequiredFields[];
    requiredFields: ProductsRequiredFieldsWithFromToCountries[];
    taxRequest: TaxRequest;
    taxResponse: TaxResponse[];

    countries: Array<{value: string, label: string, iso2: string}> = [];

    constructor(
        private authService: AuthService,
        private _notification: MessageService,
        private userService: UserService,
        private translate: TranslateService,
        private dutyCalculationService: DutyCalculationService,
        private apiDataService: ApiDataService
    ) {
        this.requestRequiredFields = [];
        this.addRequiredFieldsRequest();
    }

    async ngOnInit() {
        this.apiDataService.getCountries().then(() => {
            this.setCountries();
        });
        await this.initUserPrefs();
        await this.updateTranslation();
        this.currentStep = DUTY_STEP_ENUM.ORIGIN_DESTINATION;
        this.taxRequest = new TaxRequest();
        this.taxRequest.lang = this.user.lang;
    }

    async setCountries() {
        try {
            this.countries = this.apiDataService.getCountriesInstant().map(c => {
                return {
                    value: c.value,
                    iso2: c.iso2,
                    label: c[`label_${this.translate.currentLang}`] ?
                            c[`label_${this.translate.currentLang}`] + ' (' + c.value + ')' :
                            c[`label_en`] + ' (' + c.value + ')'
                };
            });
            this.countries.sort((a, b) => {
                return a.label.localeCompare(b.label, this.translate.currentLang, {ignorePunctuation: true});
            });
        } catch (error) {
            console.error(error);
        }
    }

    addRequiredFieldsRequest() {
        this.requestRequiredFields.push({
            products: [],
            lang: null,
            from_country: null,
            to_country: null,
            to_district: null
        });
    }

    private async updateTranslation() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            const messages = event.translations['messages'];
            this.updateMessagesLanguage(messages);
            this.setCountries();
        });
        await this.translate.get('messages').toPromise().then(messages => {
            this.updateMessagesLanguage(messages);
        });
    }
    private updateMessagesLanguage(messages) {
        this.unknown_error = messages.notification.unknown_error;
    }
    private async initUserPrefs() {
        let user = this.userService.getUser();
        if (!user) {
            user = await this.authService.reconnect();
        }
        this.user = user;
        this.requestRequiredFields[0].lang = this.user.lang;
    }

    /**
     * From the step Origin.<br/>
     * Set {@link Origin} in {@link RequestRequiredFields}.<br/>
     * Call {@link DutyCalculationComponent.setStep} with {@link DUTY_STEP_ENUM.PRODUCT} as param to go to the next step.
     * @param origin {@link Origin}
     */
    fromOrigin(origins: Origin[]) {
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        // remove deleted required fields requests
        while (origins.length < this.requestRequiredFields.length) {
            this.requestRequiredFields.pop();
        }
        // add missing required fields requests
        while (this.requestRequiredFields.length < origins.length) {
            this.addRequiredFieldsRequest();
            this.requestRequiredFields[this.requestRequiredFields.length - 1].lang = this.taxRequest.lang;
        }
        if (origins.length > 1) {
            // ecommerce_type = MARKETPLACE
            this.taxRequest.ecommerce_type = ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE;
            for (let i = 0; i < origins.length; i++) {
                this.requestRequiredFields[i].from_country = origins[i].from_country;
                this.requestRequiredFields[i].to_country = origins[i].to_country;
                this.requestRequiredFields[i].to_district = origins[i].to_district;
            }
        } else {
            // ecommerce_type = ESHOP
            this.taxRequest.ecommerce_type = ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP;
            this.requestRequiredFields[0].from_country = origins[0].from_country;
            this.requestRequiredFields[0].to_country = origins[0].to_country;
            this.requestRequiredFields[0].to_district = origins[0].to_district;
            this.taxRequest.from_country = origins[0].from_country;
            this.taxRequest.to_country = origins[0].to_country;
            this.taxRequest.to_district = origins[0].to_district;
        }
        this.setStep(DUTY_STEP_ENUM.PRODUCT);
    }

    /**
     * From the step Product.<br/>
     * Set {@link Product}[] in {@link RequestRequiredFields}.<br/>
     * Call intermediate request to get required fields in the step Options.<br/>
     * Call {@link DutyCalculationComponent.setStep} with {@link DUTY_STEP_ENUM.OPTIONS} as param to go to the next step.
     * @param products {@link Product}[]
     */
    fromProduct(data: {
        requestsRequiredFields: RequestRequiredFields[],
        includedTax: boolean[][]
    }) {
        // for Gitlab pipeline
        if (! this.taxRequest) {
            return;
        }
        data.requestsRequiredFields.forEach(req => {
            const i = this.requestRequiredFields.findIndex(r => {
                return r.from_country === req.from_country &&
                        r.to_country === req.to_country &&
                        r.to_district === req.to_district;
            });
            if (i >= 0) {
                this.requestRequiredFields[i].products = req.products;
            }
        });
        this.dutyCalculationService.askRequiredFields(this.requestRequiredFields)
            .then( response => {
                this.requiredFields = [];
                this.taxRequest.products = [];
                for (let i = 0; i < this.requestRequiredFields.length; i++) {
                    const req = this.requestRequiredFields[i];
                    const reqFieldsResponse = response[i];
                    this.requiredFields.push(
                        {
                            from_country: req.from_country,
                            to_country: req.to_country,
                            to_district: req.to_district,
                            products: reqFieldsResponse.products
                        }
                    );
                    let j = 0;
                    req.products.forEach(product => {
                        const identification: RequestProductIdentification = Builder(RequestProductIdentification)
                            .type(product.identification.type)
                            .value(product.identification.value)
                            .build();
                        const requestProduct: RequestProductAllCurrencies = Builder(RequestProductAllCurrencies)
                            .identification(identification)
                            .unit_price(product.unit_price)
                            .currency_unit_price(product.currency_unit_price)
                            .quantity(product.quantity)
                            .origin_country(product.origin_country)
                            .from_country(req.from_country)
                            .to_country(req.to_country)
                            .to_district(req.to_district)
                            .included_tax(data.includedTax[i][j] ? data.includedTax[i][j] : false)
                            .build();
                        this.taxRequest.products.push(requestProduct);
                        j += 1;
                    });
                }

                let i = 0;
                let ecommerceTypeChanged = false;
                while (i < this.taxRequest.products.length - 1 && ! ecommerceTypeChanged) {
                    const product = this.taxRequest.products[i];
                    const remainingProducts = this.taxRequest.products.slice(i + 1);
                    const foundProduct = remainingProducts.findIndex(p => {
                        return p.from_country === product.from_country &&
                            p.to_country === product.to_country &&
                            p.to_district === product.to_district &&
                            p.included_tax === product.included_tax;
                    });
                    if (foundProduct < 0) {
                        this.taxRequest.ecommerce_type = ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE;
                        ecommerceTypeChanged = true;
                    }
                    i += 1;
                }
                if (! ecommerceTypeChanged) {
                    this.taxRequest.ecommerce_type = ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP;
                }

                // go in Options page only if there is more than 1 product OR if to_country is Swiss OR there are required units
                if (
                    this.weightIsMandatory() ||
                    this.requiredFields.findIndex(r => r.products.findIndex(prod => prod.requiredFields.units.length > 0) >= 0) >= 0
                ) {
                    this.setStep(DUTY_STEP_ENUM.OPTIONS);
                } else {
                    this.taxRequest.products.forEach(product => {
                        product.weight = 1;
                        product.weight_unit = 'kg';
                    });
                    this.setStep(DUTY_STEP_ENUM.TRANSPORT);
                }
            })
            .catch( e => {
                console.error(e);
                this._notification.displayMessage(e.error.message, 'danger');
            });
    }

    fromOptions(taxRequestOptions: TaxRequest) {
        this.taxRequest = taxRequestOptions;
        this.setStep(DUTY_STEP_ENUM.TRANSPORT);
    }

    fromTransport(taxRequestTransport: TaxRequest) {
        this.taxRequest = taxRequestTransport;
        // change ESHOP to MARKETPLACE if there are different incoterms
        if (this.taxRequest.ecommerce_type === ECOMMERCE_REQUEST_TYPE_ENUM.ESHOP) {
            if (
                this.taxRequest.shipment_type !== SHIPMENT_REQUEST_TYPE_ENUM.GLOBAL &&
                this.taxRequest.products.map(p => p.incoterm).filter(p => p === this.taxRequest.products[0].incoterm).length !== this.taxRequest.products.length
            ) {
                this.taxRequest.ecommerce_type = ECOMMERCE_REQUEST_TYPE_ENUM.MARKETPLACE;
            }
        }
        this.setStep(DUTY_STEP_ENUM.SENDER_RECIPIENT);
    }

    fromSender(taxRequestSender: TaxRequest) {
        this.taxRequest = taxRequestSender;
        this.dutyCalculationService.dutyCalculation(this.taxRequest)
        .then( taxReponse => {
            if (Array.isArray(taxReponse)) {
                this.taxResponse = taxReponse;
            } else {
                this.taxResponse = [taxReponse as TaxResponse];
            }

            const requestProductsStreams: RequestProductAllCurrencies[][] = [];
            if (this.taxRequest && this.taxRequest.products) {
                this.taxRequest.products.forEach(product => {
                    let iProducts = requestProductsStreams.findIndex(p => {
                        return p[0].to_country === product.to_country &&
                            p[0].to_district === product.to_district &&
                            p[0].from_country === product.from_country &&
                            p[0].included_tax === product.included_tax &&
                            p[0].incoterm === product.incoterm;
                    });
                    if (iProducts === -1) {
                        iProducts = requestProductsStreams.length;
                        requestProductsStreams.push([]);
                    }
                    requestProductsStreams[iProducts].push(product);
                });
            }

            const tempTaxResponse: TaxResponse[] = [];
            const streamsPositions: { from_country: string; to_country: string; to_district: string; }[] = [];
            for (let i = 0; i < requestProductsStreams.length; i++) {
                const index = streamsPositions.findIndex(sp => {
                    return sp.from_country === requestProductsStreams[i][0].from_country &&
                        sp.to_country === requestProductsStreams[i][0].to_country &&
                        sp.to_district === requestProductsStreams[i][0].to_district;
                });
                if (index >= 0) {
                    // duty amount
                    tempTaxResponse[index].duty_amount = this.getSum(tempTaxResponse[index].duty_amount, this.taxResponse[i].duty_amount);
                    // ecotax
                    tempTaxResponse[index].ecoTax_amount = this.getSum(tempTaxResponse[index].ecoTax_amount, this.taxResponse[i].ecoTax_amount);
                    // specialtax
                    tempTaxResponse[index].special_taxes_amount = this.getSum(tempTaxResponse[index].special_taxes_amount, this.taxResponse[i].special_taxes_amount);
                    // vat
                    tempTaxResponse[index].vat_amount = this.getSum(tempTaxResponse[index].vat_amount, this.taxResponse[i].vat_amount);
                    // extra_fees
                    if (tempTaxResponse[index].extra_fees) {
                        tempTaxResponse[index].extra_fees.amount = this.getSum(tempTaxResponse[index].extra_fees.amount, this.taxResponse[i].extra_fees.amount);
                    }
                    // global amount
                    tempTaxResponse[index].global._amount = this.getSum(tempTaxResponse[index].global.amount, this.taxResponse[i].global.amount);
                    // global amount_duty_and_tax
                    tempTaxResponse[index].global.amount_duty_and_tax = this.getSum(tempTaxResponse[index].global.amount_duty_and_tax, this.taxResponse[i].global.amount_duty_and_tax);
                    // global amount_exclusive
                    tempTaxResponse[index].global.amount_exclusive = this.getSum(tempTaxResponse[index].global.amount_exclusive, this.taxResponse[i].global.amount_exclusive);
                    // global amount_inclusive_vat
                    tempTaxResponse[index].global.amount_inclusive_vat = this.getSum(tempTaxResponse[index].global.amount_inclusive_vat, this.taxResponse[i].global.amount_inclusive_vat);
                    // global amount_total
                    tempTaxResponse[index].global.amount_total = this.getSum(tempTaxResponse[index].global.amount_total, this.taxResponse[i].global.amount_total);
                    // products
                    tempTaxResponse[index].products = tempTaxResponse[index].products.concat(this.taxResponse[i].products);
                } else {
                    streamsPositions.push({
                        from_country: requestProductsStreams[i][0].from_country,
                        to_country: requestProductsStreams[i][0].to_country,
                        to_district: requestProductsStreams[i][0].to_district,
                    });
                    tempTaxResponse.push(this.taxResponse[streamsPositions.length - 1]);
                }
            }
            this.taxResponse = tempTaxResponse;

            this.setStep(DUTY_STEP_ENUM.RESULT);
        })
        .catch(e => {
            this._notification.displayMessage(e.error.message, 'danger');
        });
    }

    private getSum(a: number, b: number): number {
        return a ? a + (b ? b : 0 ) : b;
    }

    // go back from one of those steps : PRODUCT, OPTIONS, TRANSPORT or SENDER_RECIPIENT
    goBack() {
        if (this.currentStep === DUTY_STEP_ENUM.SENDER_RECIPIENT) {
            this.setStep(DUTY_STEP_ENUM.TRANSPORT);
        } else if (this.currentStep === DUTY_STEP_ENUM.TRANSPORT) {
            // go in Options page only if there is more than 1 product OR if to_country is Swiss
            if (this.requestRequiredFields.find(rrf => rrf.products.length > 1) || this.requestRequiredFields.find(rrf => rrf.to_country === 'CHE')) {
                this.setStep(DUTY_STEP_ENUM.OPTIONS);
            } else {
                this.setStep(DUTY_STEP_ENUM.PRODUCT);
            }
        } else if (this.currentStep === DUTY_STEP_ENUM.OPTIONS) {
            this.setStep(DUTY_STEP_ENUM.PRODUCT);
        } else if (this.currentStep === DUTY_STEP_ENUM.PRODUCT) {
            this.setStep(DUTY_STEP_ENUM.ORIGIN_DESTINATION);
        }
    }

    newSearch() {
        this.requestRequiredFields = [];
        this.requiredFields = undefined;
        this.addRequiredFieldsRequest();

        this.taxRequest = new TaxRequest();
        this.taxRequest.lang = this.user.lang;
        this.requestRequiredFields[0].lang = this.user.lang;
        this.taxResponse = null;
        this.setStep(DUTY_STEP_ENUM.ORIGIN_DESTINATION);
    }

    setStep(step: DUTY_STEP_ENUM) {
        if ( this.currentStep !== step ) {
            this.currentStep = step;
            this.child.setLabel(this.currentStep);
        }
    }

    isRightStep(step: DUTY_STEP_ENUM) {
        return step === this.currentStep;
    }

    isDisabledStep(step: DUTY_STEP_ENUM) {
        return step !== this.currentStep;
    }

    weightIsMandatory(): boolean {
        if (this.requestRequiredFields.find(r => r.to_country === 'CHE')) {
            return true;
        }
        let i = 0;
        while (i < this.taxRequest.products.length - 1) {
            const product = this.taxRequest.products[i];
            const remainingProducts = this.taxRequest.products.slice(i + 1);
            if (
                remainingProducts.find(p => {
                    return p.from_country === product.from_country &&
                        p.to_country === product.to_country &&
                        p.to_district === product.to_district &&
                        p.included_tax === product.included_tax;
                })
            ) {
                return true;
            }
            i += 1;
        }
        return false;
    }
}
