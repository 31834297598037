import { Builder } from "builder-pattern";

export const TRIAL_PLAN = 'TRIAL';
export const CHROME_EXTENSION_PLAN = 'CHROME_EXTENSION';

export enum PLAN_ENUM {
    START = 'START',
    ROCKET = 'ROCKET',
    COSMOS = 'COSMOS',
    INFINITE = 'INFINITE'
}

export enum PLAN_V2_ENUM {
    API = 'API',
    'E-COMMERCE' = 'E-COMMERCE',
    SAAS = 'SAAS'
}

export enum ALL_PLAN_TYPE_ENUM {
    FREE = 'free',
    PRE_PAYMENT = 'pre-payment',
    PAY_AS_YOU_GO = 'pay-as-you-go',
}

export enum USER_STATUT_ENUM {
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    CHILD = 'CHILD'
}

export enum USING_STATUT_ENUM {
    WEBPLATFORM = 'WEBPLATFORM',
    API = 'API',
    ECOMMERCE = 'ECOMMERCE'
}

export interface TopDestination {
    country: string;
    percentage: number;
}

export interface EcommerceHomePageData {
    turnover: number;
    turnover_currency: string;
    current_month_orders: number;
    top_destinations: TopDestination[];
}

export class MonitoringUsersMinimalData {
    email: string;
    firstname: string;
    lastname: string;
    company_name: string;
    domain_name: string;
    todayOrders: number;
    todayRequests: number;
}

export class ItemsCounts {
    customers_count: number;
    products_count: number;
    orders_count: number;
    categories_count: number;
}

export class  User {
    gender: string;
    firstname: string;
    lastname: string;
    job: string;
    email: string;
    phone_number: string;
    company_name: string;
    rgpd_validation: string;
    plan: string;
    plan_type: ALL_PLAN_TYPE_ENUM;
    address: string;
    zip: string;
    city: string;
    country: string;
    indicatif_telephonique: string;
    lang: string;
    devise: string;
    siret: string;
    tva: string;
    service_utilises: boolean;
    requests: number;
    activity: string;
    createDate: number;
    currency: string;
    company: Company;
    user_statut: string;
    is_grand_child?: boolean;
    using_statut: USING_STATUT_ENUM;
    parent_brand_logo_url?: string;
    is_akanea?: boolean;
    consumptions: UserConsumption[];
    ecommerceProperties: UserSettings;
    ecommerceHomePageData?: EcommerceHomePageData;
    superAdminUsersMinimalData?: MonitoringUsersMinimalData[]; // for super admins
    superAdminUsersSumCounts?: ItemsCounts; // for super admins

    isTrial() {
        if (this.plan) {
            return this.plan.toUpperCase() === TRIAL_PLAN;
        } else {
            return false;
        }
    }
    isChromeExtension() {
        if (this.plan) {
            return this.plan.toUpperCase() === CHROME_EXTENSION_PLAN;
        } else {
            return false;
        }
    }
    getPlan() {
        return this.plan ? this.plan.toUpperCase() : TRIAL_PLAN;
    }
    getPlanType() {
        return this.plan_type ? this.plan_type.toLowerCase() : ALL_PLAN_TYPE_ENUM.FREE;
    }
    getApiPlanSetupDone(): boolean {
        return this.ecommerceProperties ? this.ecommerceProperties.api_setup_plan_done : false;
    }
    getCanChangePlanType(): boolean {
        return this.ecommerceProperties ? this.ecommerceProperties.can_change_plan_type : false;
    }
    getLangIso2() {
        return this.lang.substr(0, 2);
    }
    isGrandChild() {
        if (this.isChild() && (this.is_grand_child || this.consumptions.length > 0 && this.consumptions[0].is_grand_child)) {
            return true;
        }
    }
    isChild() {
        if (this.user_statut === USER_STATUT_ENUM.CHILD) {
            return true;
        } else {
            return false;
        }
    }
    isAdmin() {
        if (this.user_statut === USER_STATUT_ENUM.ADMIN || this.user_statut === USER_STATUT_ENUM.SUPER_ADMIN) {
            return true;
        } else {
            return false;
        }
    }
    isSuperAdmin() {
        if (this.user_statut === USER_STATUT_ENUM.SUPER_ADMIN) {
            return true;
        } else {
            return false;
        }
    }

    isAkaneaChild() {
        return !!this.is_akanea;
    }

    planIsEcommerce(): boolean {
        return this.plan ? this.plan === PLAN_V2_ENUM['E-COMMERCE'] : false;
    }
    planIsApi(): boolean {
        return this.plan ? this.plan === PLAN_V2_ENUM.API : false;
    }
    planIsSaas(): boolean {
        return this.plan ? this.plan.startsWith(PLAN_V2_ENUM.SAAS) : false;
    }
    planIsNewSaas(): boolean {
        if (
            this.plan &&
            (
                this.plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.START}` ||
                this.plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.ROCKET}` ||
                this.plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.COSMOS}` ||
                this.plan === `${PLAN_V2_ENUM.SAAS}-${PLAN_ENUM.INFINITE}`
            )
        ) {
            return true;
        }
        return false;
    }
    planTypeIsPayAsYouGo(): boolean {
        return this.plan_type === ALL_PLAN_TYPE_ENUM.PAY_AS_YOU_GO;
    }
    planTypeIsPrePayment(): boolean {
        return this.plan_type === ALL_PLAN_TYPE_ENUM.PRE_PAYMENT;
    }

    // OLD WAYS
    isWebPlatform(): boolean {
        if (!this.using_statut) {
            return true;
        }
        return this.using_statut === USING_STATUT_ENUM.WEBPLATFORM;
    }
    isEcommerce(): boolean {
        if (!this.using_statut) {
            return false;
        }
        return this.using_statut === USING_STATUT_ENUM.ECOMMERCE;
    }
    isApi(): boolean {
        if (!this.using_statut) {
            return false;
        }
        return this.using_statut === USING_STATUT_ENUM.API;
    }

    getAllowedChildren(): number {
        if (this.consumptions && this.consumptions[0]) {
            const maxChildrenAllowed = (this.consumptions[0].childrenAllowed ? this.consumptions[0].childrenAllowed : 0)
                                    +
                                    (this.consumptions[0].extraChildrenAllowed ? this.consumptions[0].extraChildrenAllowed : 0);
            return maxChildrenAllowed;
        }
        return 0;
    }

    getRemainingChildren(): number {
        if (this.consumptions && this.consumptions[0] && this.consumptions[0].relation && this.consumptions[0].relation.children) {
            return this.getAllowedChildren() - this.consumptions[0].relation.children.length;
        }
        return 0;
    }

    getAllowedEndpointsWithRemaining(): { [key: string]: number; } {
        const res: { [key: string]: number; } = {};
        if (this.consumptions && this.consumptions[0]) {
            const endpoints = Object.keys(this.consumptions[0].currentPeriod.credits_consumption);
            endpoints.forEach(endpoint => {
                let totalLeft = this.consumptions[0].currentPeriod.credits_consumption[endpoint].left;
                if (
                    this.consumptions[0].override_plan &&
                    this.consumptions[0].override_plan.requests &&
                    this.consumptions[0].override_plan.requests[endpoint]
                ) {
                    totalLeft += this.consumptions[0].override_plan.requests[endpoint];
                }
                if (
                    totalLeft > 0 ||
                    (
                        this.consumptions[0].relation &&
                        this.consumptions[0].relation.children &&
                        this.consumptions[0].relation.children.findIndex(c => c.allow_req_credits && c.allow_req_credits[endpoint]) >= 0
                    )
                ) {
                    res[endpoint] = totalLeft;
                }
            });
        }
        return res;
    }

    technicalSupportSubscribed(): boolean {
        const currentTime = (new Date()).getTime();
        if (this.consumptions && this.consumptions[0]) {
            return this.consumptions[0].technicalSupport && this.consumptions[0].technicalSupport.end && this.consumptions[0].technicalSupport.end > currentTime;
        }
        return false;
    }
}

export class Company {
    company_name: string;
    activity: string;
    siret: string;
    tax_number: string;
    legal_form: string;
}

export class UserConsumption {
    currentPeriod: Consumption;
    today: Consumption;
    override_plan: { requests: { [key: string]: number; }; };
    end: number;
    alltogether: Consumption;
    is_grand_child: boolean;
    plan: string;
    extraCredits: Consumption;
    bonusCredits: number;
    bonusCreditsConsumption: Consumption;
    relation: Relation;
    user_statut: string;
    childrenAllowed?: number; // Only for API plans
    extraChildrenAllowed?: number; // Only for API plans, set by a Super Admin
    isDisabled?: boolean; // to know if the child account is disabled or not
    technicalSupport?: TechnicalSupport; // Only for E-COMMERCE plans
}
export class Consumption {
    count: number;
    left: number;
    start: number;
    end: number;
    credits_consumption?: { [key: string]: { count: number; left: number; }; };
}

export class TechnicalSupport {
    remaining_hours: number; // remaining technical support in hours
    start: number; // when the user has subscribed?
    update: number; // when the remaining hours has been renewed the last time?
    resetEnd: number; // when shall we reset the remaining hours?
    end: number; // when the subscription ends?
    period: string; // 3M
}

export class Relation {
    children: Child[];
    admin: Admin;
}

export class Admin {
    email: string;
    allow_credits: number;
    allow_req_credits?: { [key: string]: number; };
}
export class Child extends Admin {
    creationDate: number | Date;
    renewDate: number | Date;
    id: string;
    used: number;
    firstname: string;
    lastname: string;
}
export class ChildWithConsumptions extends Child {
    total_current_month?: number;
    credits_consumption: { [key: string]: { count: number; left: number; }; };
    today_credits_consumption: { [key: string]: { count: number; }; };
    total_credits_consumption: { [key: string]: { count: number; }; };
    allow_req_periodic_credits?: { [key: string]: number; };
    override_plan?: { requests?: { [key: string]: number; } };
}

export enum INCOTERM_ENUM {
    CIF = 'CIF',
    FOB = 'FOB'
}

export class UserDefaultValues {
    default_hscode: string;
    default_origin_country: string;
    default_from_country: string;
    products_currency: string;
}

// Users from Dynamo Users
export class EcommerceUser extends UserDefaultValues {
    email: string;
    credit_card_verified: boolean;
    domain: string;
}

export class CarriersCredentials {
    ups: {
        payment_account_number: string;
    };
    dhl: {
        payment_account_number: string;
        online_store_id: string;
        production_password: string;
    };
    fedex: {
        account_number: string;
        meter_number: string;
        authentication_key: string;
        api_password: string;
    };
}

export class UserSettings {
    mail: string;
    apiKey: string;
    storeID: string;
    stripe_currency: string;
    api_setup_plan_done: boolean;
    can_change_plan_type: boolean;
    credit_card_verified: boolean;
    products_auto_update?: boolean; // auto update button in catalog > products page for E-COMMERCE plans
    stripe_connected_account_info: {
        id: string;
        default_currency: string;
        details_submitted: boolean;
    };
    international_checkout_account_info: {
        logo_url: string;
        color_theme: string;
        redirection_url: string;
    };
    account: {
        firstname: string;
        lastname: string;
        phone_number: string;
        siret: string;
        company_id: string;
        company_tax_id: string
        company_name: string;
        domain_name: string;
        integration_method: string;
        pro: boolean;
        age: number;
        gender: string;
    };
    expedition: {
        country: string;
        state: string;
        city: string;
        postal_code: string;
        address: string;
    };
    customs_classification: {
        default_product_category: string;
        default_hs_code: string;
        default_product_description: string;
        default_origin_country: string;
    };
    carriers: CarriersCredentials;
    duty_calculation: {
        tax_included: boolean;
        extra_fees: number;
    };
    signature: string;
    seller_logo: string;
    customer_service_info: string;
    reverse_logistic_terms: string;
    other_seller_informations: string;
    brand_name: string;
    brand_logo_url: string;
    air_port_of_lading: string;
    incoterm: INCOTERM_ENUM;
    term_of_sale: string;

    constructor(mail: string) {
        this.mail = mail;
        this.api_setup_plan_done = false;
        this.can_change_plan_type = false;
        this.stripe_currency = '';
        this.account = {
            firstname: '',
            lastname: '',
            phone_number: '',
            siret: '',
            company_id: '',
            company_tax_id: '',
            company_name: '',
            domain_name: '',
            integration_method: '',
            pro: null,
            age: null,
            gender: ''
        };
        this.expedition = {
            country: '',
            state: '',
            city: '',
            postal_code: '',
            address: ''
        };
        this.customs_classification = {
            default_product_category: '',
            default_hs_code: '',
            default_product_description: '',
            default_origin_country: ''
        };
        this.carriers = Builder(CarriersCredentials)
                        .fedex({
                            account_number: '',
                            meter_number: '',
                            authentication_key: '',
                            api_password: ''
                        })
                        .dhl({
                            payment_account_number: '',
                            online_store_id: '',
                            production_password: ''
                        })
                        .ups({
                            payment_account_number: ''
                        })
                        .build();
        this.duty_calculation = {
            tax_included: null,
            extra_fees: null
        };
        this.signature = '';
        this.seller_logo = '';
        this.customer_service_info = '';
        this.reverse_logistic_terms = '';
        this.other_seller_informations = '';
        this.brand_name = '';
        this.brand_logo_url = '';
        this.air_port_of_lading = '';
        this.incoterm = null;
        this.term_of_sale = '';
    }
}
